import React from 'react'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DateLabel from 'components/common/DateLabel'
import Label from 'components/common/Label'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import RateReviewIcon from '@material-ui/icons/RateReview'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import HelpIcon from '@material-ui/icons/Help'
import IconWithText from 'components/common/IconWithText'
import { RoomCheckListApplicationState } from 'utils/enum'
import { Grid } from '@material-ui/core'
import { handleOpenPage } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(() => ({
  arrangementImg: {
    position: 'relative',
    top: 3,
    width: 30,
    height: 'auto',
    alignItems: 'center'
  }
}))

const columns = [
  { id: 'application_id', label: 'ID', minWidth: 100, width: 100 },
  {
    id: 'application_state_id',
    label: '状態',
    align: 'center',
    minWidth: 60,
    width: 60
  },
  {
    id: 'application_start_date',
    label: '申請日時',
    minWidth: 120,
    width: 120
  },
  { id: 'applicant_name', label: '入居者名', minWidth: 170 },
  { id: 'building_name', label: '物件名', minWidth: 170 },
  { id: 'defect', label: '不備', minWidth: 100, width: 100 },
  { id: 'arrangement', label: '手配', minWidth: 70, width: 70 },
  { id: 'assigned_user', label: '担当者', minWidth: 170 }
]

function CellWithStatus({ status }) {
  switch (status) {
    case RoomCheckListApplicationState.open.code:
      return (
        <IconWithText
          text={RoomCheckListApplicationState.open.value}
          icon={<NewReleasesIcon />}
          color={'c_red'}
        />
      )
    case RoomCheckListApplicationState.inProgress.code:
      return (
        <IconWithText
          text={RoomCheckListApplicationState.inProgress.value}
          icon={<RateReviewIcon />}
          color={'c_yellow'}
        />
      )
    case RoomCheckListApplicationState.complete.code:
      return (
        <IconWithText
          text={RoomCheckListApplicationState.complete.value}
          icon={<BeenhereIcon />}
          color={'c_thirdly'}
        />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithDefect({ json }) {
  let parseValue
  try {
    parseValue = JSON.parse(json)
  } catch (e) {
    return { e }
  }
  if (!parseValue || Object.keys(parseValue).length === 0) {
    return <></>
  }
  for (let value of parseValue) {
    if (value.body) {
      for (let data of value.body) {
        if (data.content || (data.images && data.images.length > 0)) {
          return <Label color={`blue`} text={`あり`} />
        }
      }
    }
  }
  return <></>
}

function List(props) {
  const history = useHistory()
  const classes = useStyles()
  const isTextSelected = useIsTextSelected()

  const { searchCondition } = props

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/application/roomCheckList/detail/${id}`,
      e,
      history,
      searchCondition
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.roomCheckListApplicationList.length
            ? props.roomCheckListApplicationList.map(row => {
                return (
                  <TableRow
                    onClick={e => navigateToDetailPage(e, row.application.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.application.id}
                  >
                    <TableCell>{row.application.id}</TableCell>
                    <TableCell>
                      <CellWithStatus
                        status={row.application.application_state_id}
                      />
                    </TableCell>
                    <TableCell>
                      <DateLabel timestamp={row.application.created_at} />
                    </TableCell>
                    <TableCell>{row.mst_kokyaku_nky.KKY_NAME}</TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item>{row.mst_tateya.TTY_NAME}</Grid>
                        <Grid item>{row.mst_heya.HYA_ROOM_NO}</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <CellWithDefect json={row.application.content} />
                    </TableCell>
                    <TableCell>
                      {row.is_arranged &&
                        row.arrangement_status !== 'CANCEL_OR_RELEASE' && (
                          <img
                            className={classes.arrangementImg}
                            src={`${process.env.PUBLIC_URL}/wrench-check.svg`}
                            alt={'wrenchCheck'}
                          />
                        )}
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        {row.assigned_user_group &&
                          getFormattedNameByStatus(
                            row.assigned_user_group.name,
                            row.assigned_user_group.status
                          )}
                      </Grid>
                      <Grid item>
                        {row.assigned_user &&
                          getFormattedNameByStatus(
                            row.assigned_user.name,
                            row.assigned_user.status
                          )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default List
