import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormFooter from './FormFooter'
import Dropdown from 'components/common/Form/Dropdown'
import { ThreadState } from 'utils/enum'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setLoadData } from 'actions/Actions'
import Label from 'components/common/Label'
import { fromCode } from 'utils/enum.utils'
import { PAGE_LIMIT } from 'utils/const'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(() => ({
  left: {
    flex: 1,
    display: 'flex'
  },
  statusContent: {
    fontWeight: '600',
    fontSize: '16px'
  }
}))

const CustomerHistoryMessageForm = props => {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const {
    handleClose,
    setLoadData,
    customerHistoryThread,
    fetchCustomerHistoryThreadList,
    offset,
    customerId
  } = props

  const [companyUserMap, setCompanyUserMap] = useState(null)
  const [companyGroupList, setCompanyGroupList] = useState(null)
  const [assignedCompanyUserList, setAssignedCompanyUserList] = useState(null)
  const [
    selectedAssignedCompanyGroup,
    setSelectedAssignedCompanyGroup
  ] = useState(null)
  const [
    selectedAssignedCompanyUser,
    setSelectedAssignedCompanyUser
  ] = useState(null)
  const [selectedThreadState, setSelectedThreadState] = useState(null)
  const [selectedThreadStateObject, setSelectedThreadStateObject] = useState({})

  const [
    validateSelectedAssignedCompanyGroup,
    setValidateSelectedAssignedCompanyGroup
  ] = useState({})
  const [
    validateSelectedAssignedCompanyUser,
    setValidateSelectedAssignedCompanyUser
  ] = useState({})

  const handleChangeAssignedCompanyGroup = event => {
    setSelectedAssignedCompanyGroup(event.target.value)
    setAssignedCompanyUserList(companyUserMap.get(event.target.value))
    setSelectedAssignedCompanyUser(0)
  }
  const handleChangeAssignedCompanyUser = event => {
    if (event.target.value != '') {
      setSelectedAssignedCompanyUser(event.target.value)
    }
  }
  const handleChangeStatus = event => {
    setSelectedThreadState(event.target.value)
    setSelectedThreadStateObject(fromCode(ThreadState, event.target.value))
  }

  const [selectedData, setSelectedData] = useState({})

  const threadStates = [
    {
      value: ThreadState.open.code,
      label: ThreadState.open.value
    },
    {
      value: ThreadState.inProgress.code,
      label: ThreadState.inProgress.value
    },
    {
      value: ThreadState.pending.code,
      label: ThreadState.pending.value
    },
    {
      value: ThreadState.complete.code,
      label: ThreadState.complete.value
    }
  ]

  const statusLabel = status => {
    switch (status) {
      case 'CHAT':
        return <Label color="blue" text="チャット" />
      case 'APPLICATION':
        return <Label color="blue" text="申請" />
      case 'TEL':
        return <Label color="blue" text="電話" />
      case 'MAIL':
        return <Label color="blue" text="メール" />
      case 'DELIVERY':
        return <Label color="blue" text="郵送" />
      default:
        return <Label color="blue" text="その他" />
    }
  }

  const fetchCompanyGroupList = async (offset, limit) => {
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyGroupItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          const companyGroupItem = {
            value: item.id,
            label: item.name
          }
          companyGroupItems.push(companyGroupItem)
        }
      }
      setCompanyGroupList(companyGroupItems)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const userMap = new Map()
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          const companyUserItem = {
            value: item.company_user.id,
            label: item.company_user.name
          }
          companyUserItems.push(companyUserItem)
          if (!userMap.has(item.company_group.id)) {
            userMap.set(item.company_group.id, [companyUserItem])
          } else {
            const companyUserItemArray = userMap.get(item.company_group.id)
            companyUserItemArray.push(companyUserItem)
          }
        }
      }
      setCompanyUserMap(userMap)
    }
  }

  const fetchCustomerHistoryThread = () => {
    setSelectedAssignedCompanyGroup(
      customerHistoryThread.updated_user.company_group_id
    )
    setSelectedAssignedCompanyUser(customerHistoryThread.updated_user.id)
    setSelectedThreadState(customerHistoryThread.status)
    setSelectedThreadStateObject(
      fromCode(ThreadState, customerHistoryThread.status)
    )
    setSelectedData({
      other_activity_id: customerHistoryThread.original_data_id,
      group_id: customerHistoryThread.updated_user.company_group_id,
      company_user_id: customerHistoryThread.updated_user.id,
      other_activity_state: fromCode(ThreadState, customerHistoryThread.status)
        .key
    })
  }

  useEffect(() => {
    fetchCompanyGroupList()
    fetchCompanyUserList()
    fetchCustomerHistoryThread()
  }, [props])

  useEffect(() => {
    if (companyUserMap == null) return
    if (customerHistoryThread != null) {
      setAssignedCompanyUserList(
        companyUserMap.get(customerHistoryThread.updated_user.company_group_id)
      )
    }
  }, [companyUserMap])

  const handleSubmit = async event => {
    event.preventDefault()
    setLoadData(true)
    if (validateForm()) {
      setLoadData(false)
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    const params = {}

    params.other_activity_id = customerHistoryThread.original_data_id

    if (selectedAssignedCompanyGroup) {
      params.group_id = selectedAssignedCompanyGroup
    }
    if (selectedAssignedCompanyUser) {
      params.company_user_id = selectedAssignedCompanyUser
    }
    if (selectedThreadStateObject) {
      params.other_activity_state = selectedThreadStateObject.key
    }

    if (JSON.stringify(selectedData) === JSON.stringify(params)) {
      setLoadData(false)
      props.enqueueSnackbar('変更がありません', {
        variant: 'warning'
      })
      return
    }

    let result
    result = await MutationHelper('updateOtherCustomerActivity', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      await customerHistoryThread.id
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
      const isSaving = true
      fetchCustomerHistoryThreadList(customerId, offset, PAGE_LIMIT)
      handleClose(event, isSaving)
    }
    setLoadData(false)
  }

  const validateForm = () => {
    let isError = false

    if (!selectedAssignedCompanyGroup) {
      setValidateSelectedAssignedCompanyGroup({
        hasError: true,
        message: '必須です'
      })
      isError = true
    } else {
      setValidateSelectedAssignedCompanyGroup({})
    }
    if (!selectedAssignedCompanyUser) {
      setValidateSelectedAssignedCompanyUser({
        hasError: true,
        message: '必須です'
      })
      isError = true
    } else {
      setValidateSelectedAssignedCompanyUser({})
    }
    return isError
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={2} className={classes.statusContent}>
            カテゴリ
          </Grid>
          <Grid item xs={2} className={classes.statusContent}>
            {statusLabel(customerHistoryThread.type)}
          </Grid>
          <Grid item xs={4} className={classes.statusContent}>
            {customerHistoryThread.large_category.name}
          </Grid>
          <Grid item xs={4} className={classes.statusContent}>
            {customerHistoryThread.medium_category.name}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6} className={classes.left}>
            <Dropdown
              required={true}
              name="assigned_company_group"
              label={'グループを選択'}
              onChange={handleChangeAssignedCompanyGroup}
              defaultValue={selectedAssignedCompanyGroup}
              value={selectedAssignedCompanyGroup}
              items={companyGroupList}
              hasError={validateSelectedAssignedCompanyGroup.hasError}
              errorMessage={validateSelectedAssignedCompanyGroup.message}
              disabled={isRestrictedRole}
            />
          </Grid>
          <Grid item xs={6} className={classes.left}>
            <Dropdown
              required={true}
              name="assigned_company_user"
              label={'担当者を選択'}
              onChange={handleChangeAssignedCompanyUser}
              defaultValue={selectedAssignedCompanyUser}
              value={selectedAssignedCompanyUser}
              items={assignedCompanyUserList}
              hasError={validateSelectedAssignedCompanyUser.hasError}
              errorMessage={validateSelectedAssignedCompanyUser.message}
              disabled={isRestrictedRole}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6} className={classes.left}>
            <Dropdown
              required={true}
              label="ステータス"
              name="status"
              onChange={handleChangeStatus}
              defaultValue={selectedThreadState}
              value={selectedThreadState}
              items={threadStates}
              disabled={isRestrictedRole}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.footerWrapper}>
        <FormFooter onClose={handleClose} />
      </DialogActions>
    </form>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(CustomerHistoryMessageForm)))
