import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import UserImage from './UserImage'
import { formatTime, formatYYYYMMDDW } from 'utils/date.utils'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import ChatImage from './ChatImage'
import ChatMovie from './ChatMovie'
import ChatPdf from './ChatPdf'
import MessageCompleteDetail from './MessageCompleteDetail'
import MessageConfirmDetail from './MessageConfirmDetail'
import { useCustomerOrOwner } from '../../../hooks/useCustomerOrOwner'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f5f5f5',
    maxHeight: 'inherit',
    maxWidth: 'inherit'
  },
  table: {
    maxWidth: 'inherit',
    tableLayout: 'fixed'
  },
  root: {
    textAlign: 'right',
    fontSize: '14px',
    fontFamily: 'Hiragino Sans',
    marginTop: '25px',
    marginLeft: '31px',
    marginBottom: '25px',
    marginRight: '31px',
    overflowWrap: 'break-word'
  },
  wrapperDateLabel: {
    textAlign: 'center'
  },
  dateLabel: {
    display: 'inline-block',
    padding: '0.3em 0.5em',
    fontSize: '10px',
    textDecoration: 'none',
    minWidth: '65px',
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_gray.main,
    borderRadius: '12px'
  },
  leftItem: {
    width: '100%',
    margin: '10px 0',
    overflow: 'hidden'
  },
  leftFaceIcon: {
    float: 'left',
    marginRight: '-50px',
    marginTop: '12px',
    width: '40px'
  },
  leftFaceIconImg: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%'
  },
  leftChatting: {
    width: '100%',
    textAlign: 'left',
    position: 'relative'
  },
  leftName: {
    marginLeft: '45px',
    fontSize: '10px',
    height: '14px',
    color: '#72809D'
  },
  leftSays: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    margin: '-5px 0 0 50px',
    padding: '13px',
    maxWidth: '80%',
    borderRadius: '12px',
    background: '#FFFFFF',
    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '3px',
      left: '-19px',
      border: '8px solid transparent',
      borderRight: '18px solid #FFFFFF',
      webkitTransform: 'rotate(35deg)',
      transform: 'rotate(35deg)'
    },
    '&:hover > p': {
      color: '#0F8CF0'
    },
    '&:hover ~ .toolTip': {
      visibility: 'visible'
    }
  },
  leftSaysText: {
    margin: 0,
    padding: 0
  },
  leftToolTip: {
    visibility: 'hidden',
    position: 'relative',
    padding: '10px',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#FFFFFF',
    borderRadius: '5px',
    background: '#35425B',
    width: '120px',
    height: '30px',
    textAlign: 'center'
  },
  leftImage: {
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'inline-block',
    position: 'relative',
    margin: '-5px -15px -5px 50px',
    padding: '13px 0px 13px 13px',
    maxWidth: '80%'
  },
  leftPdf: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'inline-block',
    position: 'relative',
    margin: '-5px 0 0 50px',
    padding: '13px',
    maxWidth: '80%',
    borderRadius: '12px',
    background: '#FFFFFF',
    fontSize: '15px',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '3px',
      left: '-19px',
      border: '8px solid transparent',
      borderRight: '18px solid #FFFFFF',
      webkitTransform: 'rotate(35deg)',
      transform: 'rotate(35deg)'
    },
    '&:hover': {
      color: '#0F8CF0'
    }
  },
  leftSaysSideContent: {
    display: 'inline-block',
    marginLeft: '5px',
    verticalAlign: 'bottom'
  },
  leftTime: {
    marginBottom: '1px',
    fontSize: '10px',
    color: '#72809D'
  },

  rightChatting: {
    width: '100%',
    textAlign: 'right'
  },
  rightName: {
    fontSize: '10px',
    color: '#72809D'
  },
  rightItem: {
    width: '100%',
    margin: '10px 0',
    overflow: 'hidden'
  },
  rightSays: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'inline-block',
    position: 'relative',
    margin: '-5px 10px 0 0',
    padding: '13px',
    maxWidth: '80%',
    borderRadius: '12px',
    background: '#A7E521',
    fontSize: '15px',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '3px',
      right: '-19px',
      border: '8px solid transparent',
      borderLeft: '18px solid #A7E521',
      webkitTransform: 'rotate(-35deg)',
      transform: 'rotate(-35deg)'
    },
    '&:hover': {
      color: '#0F8CF0'
    },
    '&:hover ~ .toolTip': {
      visibility: 'visible'
    }
  },
  rightSaysText: {
    margin: 0,
    padding: 0
  },
  rightToolTip: {
    visibility: 'hidden',
    position: 'relative',
    padding: '10px',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#FFFFFF',
    borderRadius: '5px',
    background: '#35425B',
    width: '120px',
    height: '30px',
    textAlign: 'center',
    margin: '0 0 0 auto'
  },
  rightImage: {
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'inline-block',
    position: 'relative',
    margin: '-5px -10px -15px 0',
    padding: '13px',
    maxWidth: '80%',
    borderRadius: '12px',
    fontSize: '15px'
  },
  rightPdf: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'inline-block',
    position: 'relative',
    margin: '-5px 10px 0 0',
    padding: '13px',
    maxWidth: '80%',
    borderRadius: '12px',
    background: '#A7E521',
    fontSize: '15px',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '3px',
      right: '-19px',
      border: '8px solid transparent',
      borderLeft: '18px solid #A7E521',
      webkitTransform: 'rotate(-35deg)',
      transform: 'rotate(-35deg)'
    },
    '&:hover': {
      color: '#0F8CF0'
    }
  },
  rightSaysSideContent: {
    display: 'inline-block',
    marginRight: '5px',
    verticalAlign: 'bottom'
  },
  rightReading: {
    fontSize: '10px',
    color: '#72809D'
  },
  rightTime: {
    marginBlockStart: '0',
    marginBottom: '1px',
    fontSize: '10px',
    color: '#72809D'
  },
  rightTrashBox: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginRight: '10px',
    '& svg': {
      verticalAlign: 'bottom',
      width: '1.2em',
      height: 'auto',
      color: theme.palette['c_gray'].main
    }
  },
  messageWithButton: {
    display: 'inline-block',
    width: '80%',
    marginTop: '-15px'
  }
}))
const createCompanyUserMap = companyUserList => {
  const companyUserMap = new Map()
  if (companyUserList && companyUserList.length > 0) {
    for (let companyUser of companyUserList) {
      companyUserMap.set(companyUser.company_user.id, companyUser)
    }
    return companyUserMap
  }
  return companyUserMap
}

const MessageDetail = props => {
  const classes = useStyles()
  const [customerMap, setCustomerMap] = useState(new Map())
  const [owner, setOwner] = useState()
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()
  const thread = props && props.thread ? props.thread : null
  const messageAndAttachmentList =
    props && props.messageAndAttachmentList
      ? props.messageAndAttachmentList
      : null
  const companyUserMap =
    props && props.companyUserList
      ? createCompanyUserMap(props.companyUserList)
      : new Map()
  let nowDate = new Date()

  const fetchCustomerList = async (offset, limit) => {
    if (!messageAndAttachmentList || !messageAndAttachmentList.length > 0) {
      return
    }

    const customerIds = []
    for (let messageAndAttachment of messageAndAttachmentList) {
      if (
        messageAndAttachment &&
        messageAndAttachment.message &&
        messageAndAttachment.message.customer_id &&
        !customerIds.includes(messageAndAttachment.message.customer_id)
      ) {
        customerIds.push(messageAndAttachment.message.customer_id)
      }
    }
    if (customerIds.length <= 0) {
      return
    }
    let result = await QueryHelper('getCustomerList', {
      filter: { id: { contains: customerIds } },
      offset: offset,
      limit: limit
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('カスタマー一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const map = new Map()
      for (let value of result.data) {
        map.set(value.customer.id, value)
      }
      setCustomerMap(map)
    }
  }

  const fetchOwner = async (offset, limit) => {
    if (!messageAndAttachmentList || !messageAndAttachmentList.length > 0) {
      return
    }
    let result = await QueryHelper('getOwner', {
      filter: { id: { eq: thread.customer_id } },
      offset: offset,
      limit: limit
    })
    if (result.error) {
      props.enqueueSnackbar('オーナー情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setOwner(result)
    }
  }

  const fetchData = async () => {
    await Promise.all(
      isOwnerPath
        ? [fetchOwner(0, undefined)]
        : [fetchCustomerList(0, undefined)]
    )
    // chatを画面下部までスクロールさせる fetch後ではなにと描画後にならないためここでやる
    let target = document.getElementById('auto-scroll-under')
    if (target) {
      target.scrollIntoView(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [setCustomerMap, setOwner, messageAndAttachmentList])

  const handleDelete = (threadId, messageId) => async event => {
    if (!window.confirm('削除します。よろしいですか？')) {
      return
    }
    event.preventDefault()

    const params = {
      id: messageId,
      status: 3,
      thread_id: threadId
    }

    // ownerの場合はthreadのcustomer_idがowner_idになる
    if (isOwnerPath) {
      params.owner_id = thread.customer_id
    }

    const result = await MutationHelper('updateMessage', { input: params })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('削除できませんでした', {
        variant: 'error'
      })
    } else {
      await props.fetchMessageAndAttachmentList(threadId)
      props.enqueueSnackbar('削除しました', {
        variant: 'success'
      })
    }
  }

  const getDateLabel = dateStr => {
    const date = new Date(dateStr)
    if (
      nowDate.getFullYear() !== date.getFullYear() ||
      nowDate.getMonth() !== date.getMonth() ||
      nowDate.getDate() !== date.getDate()
    ) {
      nowDate = date
      return (
        <div className={classes.wrapperDateLabel}>
          <span className={classes.dateLabel}>{formatYYYYMMDDW(nowDate)}</span>
        </div>
      )
    }
    return <></>
  }

  const getRecipientName = customerId => {
    if (isOwnerPath && owner && owner.owner.name) {
      return <>{owner.owner.name}</>
    }
    if (customerMap.has(customerId)) {
      return <>{customerMap.get(customerId).mstkokyaku.KKY_NAME}</>
    }
    return <></>
  }

  const getCompanyUserName = companyUserId => {
    if (companyUserMap.has(companyUserId)) {
      const companyUser = companyUserMap.get(companyUserId)
      if (companyUser.company_user.display_name) {
        return <>{companyUser.company_user.display_name}</>
      }
      return <>{companyUser.company_user.name}</>
    }
    return <></>
  }

  const getUserImage = customerId => {
    let attachment
    if (
      isOwnerPath &&
      owner &&
      owner.app_user_and_attachment &&
      owner.app_user_and_attachment.attachment
    ) {
      attachment = owner.app_user_and_attachment.attachment
    }
    if (customerMap.has(customerId)) {
      attachment = customerMap.get(customerId).attachment
    }
    return attachment ? (
      <UserImage attachment={attachment} width={'30px'} height={'30px'} />
    ) : null
  }

  const CellWithLeftContent = (row, fetchMessageAndAttachmentList) => {
    if (row.message.status === 3) {
      return (
        <div className={classes.leftSays}>
          <p className={classes.leftSaysText}>削除されました</p>
        </div>
      )
    } else if (row.message.message_type_id === 1) {
      return (
        <>
          <div className={classes.leftSays} onClick={clipboardCopy}>
            <p className={classes.leftSaysText}>{row.message.body}</p>
          </div>
        </>
      )
    } else if (row.message.message_type_id === 2) {
      return (
        <div className={classes.leftImage}>
          <ChatImage
            attachment={row.attachment}
            threadId={row.message.thread_id}
            messageId={row.message.id}
            fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
          />
        </div>
      )
    } else if (row.message.message_type_id === 3) {
      return (
        <div className={classes.leftImage}>
          <ChatMovie
            attachment={row.attachment}
            threadId={row.message.thread_id}
            messageId={row.message.id}
            fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
          />
        </div>
      )
    } else if (row.message.message_type_id === 7) {
      return (
        <div className={classes.leftPdf}>
          <p className={classes.leftSaysText}>
            <ChatPdf
              attachment={row.attachment}
              threadId={row.message.thread_id}
              messageId={row.message.id}
              fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
              messageFrom={'left'}
            />
          </p>
        </div>
      )
    }
    return <></>
  }

  const CellWithRightContent = (row, fetchMessageAndAttachmentList) => {
    if (row.message.status === 3) {
      return (
        <div className={classes.rightSays}>
          <p className={classes.rightSaysText}>削除されました</p>
        </div>
      )
    } else if (row.message.message_type_id === 1) {
      return (
        <div className={classes.rightSays} onClick={clipboardCopy}>
          <p className={classes.rightSaysText}>{row.message.body}</p>
        </div>
      )
    } else if (row.message.message_type_id === 2) {
      return (
        <div className={classes.rightImage}>
          <ChatImage
            attachment={row.attachment}
            fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
          />
        </div>
      )
    } else if (row.message.message_type_id === 3) {
      return (
        <div className={classes.rightImage}>
          <ChatMovie
            attachment={row.attachment}
            threadId={row.message.thread_id}
            messageId={row.message.id}
            fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
          />
        </div>
      )
    } else if (row.message.message_type_id === 3) {
      return (
        <div className={classes.rightImage}>
          <ChatMovie
            attachment={row.attachment}
            threadId={row.message.thread_id}
            messageId={row.message.id}
            fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
          />
        </div>
      )
    } else if (row.message.message_type_id === 7) {
      return (
        <div className={classes.rightPdf}>
          <p className={classes.rightSaysText}>
            <ChatPdf
              attachment={row.attachment}
              threadId={row.message.thread_id}
              messageId={row.message.id}
              fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
              messageFrom={'right'}
            />
          </p>
        </div>
      )
    } else if (
      row.message.message_type_id === 8 ||
      row.message.message_type_id === 9
    ) {
      return (
        <div className={classes.messageWithButton}>
          <MessageConfirmDetail message={row.message} />
        </div>
      )
    }
    return <></>
  }

  const clipboardCopy = event => {
    console.log(window.clipboardData)
    if (window.clipboardData) {
      window.clipboardData.setData('Text', event.target.firstChild.textContent)
      props.enqueueSnackbar('クリップボードにコピーしました。', {
        variant: 'success'
      })
    } else {
      const copyFrom = document.createElement('textarea')
      copyFrom.textContent = event.target.firstChild.textContent
      const bodyElm = document.getElementsByTagName('body')[0]
      bodyElm.appendChild(copyFrom)
      copyFrom.select()
      const retVal = document.execCommand('copy')
      bodyElm.removeChild(copyFrom)
      if (retVal) {
        props.enqueueSnackbar('クリップボードにコピーしました。', {
          variant: 'success'
        })
      } else {
        props.enqueueSnackbar('クリップボードへのコピーに失敗しました。', {
          variant: 'error'
        })
      }
    }
  }

  return (
    <>
      {messageAndAttachmentList && messageAndAttachmentList.length > 0 && (
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
            id={'auto-scroll-under'}
          >
            <TableBody>
              {messageAndAttachmentList.map((row, index) => {
                return (
                  <div className={classes.root} key={index}>
                    {row.message && (
                      <>
                        {getDateLabel(row.message.created_at)}
                        {row.message.message_type_id === 4 ||
                        row.message.message_type_id === 5 ||
                        row.message.message_type_id === 6 ? (
                          <div className={classes.rightItem}>
                            <div className={classes.rightChatting}>
                              <p className={classes.rightName}>
                                {companyUserMap &&
                                  companyUserMap.size > 0 &&
                                  getCompanyUserName(
                                    row.message.company_user_id
                                  )}
                              </p>
                              <div className={classes.rightSaysSideContent}>
                                {row.message && row.message.is_read ? (
                                  <p className={classes.rightTime}>既読</p>
                                ) : (
                                  <p className={classes.rightTime}>未読</p>
                                )}
                                <p className={classes.rightTime}>
                                  {formatTime(row.message.created_at)}
                                </p>
                              </div>
                              <div className={classes.messageWithButton}>
                                <MessageCompleteDetail
                                  thread={thread}
                                  message={row.message}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {row.message.customer_id && (
                              <div className={classes.leftItem}>
                                <div className={classes.leftFaceIcon}>
                                  {getUserImage(row.message.customer_id)}
                                </div>
                                <div className={classes.leftChatting}>
                                  <p className={classes.leftName}>
                                    {getRecipientName(row.message.customer_id)}
                                  </p>
                                  {CellWithLeftContent(
                                    row,
                                    props.fetchMessageAndAttachmentList
                                  )}
                                  <div className={classes.leftSaysSideContent}>
                                    <span className={classes.leftTime}>
                                      {formatTime(row.message.created_at)}
                                    </span>
                                  </div>
                                  <div
                                    className={'toolTip ' + classes.leftToolTip}
                                  >
                                    クリックでコピー
                                  </div>
                                </div>
                              </div>
                            )}
                            {row.message.company_user_id &&
                              row.message.message_type_id !== 4 && (
                                <div className={classes.rightItem}>
                                  <div className={classes.rightChatting}>
                                    <p className={classes.rightName}>
                                      {companyUserMap &&
                                        companyUserMap.size > 0 &&
                                        getCompanyUserName(
                                          row.message.company_user_id
                                        )}
                                    </p>
                                    <div className={classes.rightTrashBox}>
                                      {row.message && !row.message.is_read && (
                                        <DeleteIcon
                                          onClick={handleDelete(
                                            row.message.thread_id,
                                            row.message.id
                                          )}
                                        />
                                      )}
                                    </div>
                                    <div
                                      className={classes.rightSaysSideContent}
                                    >
                                      {row.message && row.message.is_read ? (
                                        <p className={classes.rightTime}>
                                          既読
                                        </p>
                                      ) : (
                                        <p className={classes.rightTime}>
                                          未読
                                        </p>
                                      )}
                                      <p className={classes.rightTime}>
                                        {formatTime(row.message.created_at)}
                                      </p>
                                    </div>
                                    {CellWithRightContent(
                                      row,
                                      props.fetchMessageAndAttachmentList
                                    )}
                                    <div
                                      className={
                                        'toolTip ' + classes.rightToolTip
                                      }
                                    >
                                      クリックでコピー
                                    </div>
                                  </div>
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default withSnackbar(withRouter(MessageDetail))
