import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeSearchModalDialog } from 'actions/Actions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(() => ({
  formContent: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  descriptionText: {
    marginLeft: '14px'
  },
  formFooter: {
    margin: '8px 16px'
  }
}))

const SearchFormModalDialog = props => {
  const classes = useStyles()

  const { content, footer, postData } = props

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.closeSearchModalDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <form onSubmit={postData}>
          <Divider />
          <DialogContent className={classes.formContent}>
            {content}
          </DialogContent>
          <Divider />
          <DialogActions className={classes.formFooter}>{footer}</DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.searchModalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormModalDialog)
