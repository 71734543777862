import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {
  fullAddress,
  fullFloor,
  formatDate,
  parseDate,
  getKnrDiv,
  getKnrkeitaiDiv
} from 'utils/building.utils'

const useStyles = makeStyles(() => ({
  columnBase: {
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName: {
    width: '18%'
  },
  columnValue1: {
    width: '82%'
  },
  columnValue2: {
    width: '32%'
  }
}))

const BuildingDetail = props => {
  const classes = useStyles()

  const { building, knrDivList, knrkeitaiDivList } = props

  const rows =
    building && building.mst_tateya
      ? [
          {
            label: '建物番号',
            value: building.mst_tateya.TTY_KNR_NO,
            colSpan: 3
          },
          { label: '建物名', value: building.mst_tateya.TTY_NAME, colSpan: 3 },
          {
            label: '建物名フリガナ',
            value: building.mst_tateya.TTY_NAME_KANA,
            colSpan: 3
          },
          {
            label: '住所',
            value: fullAddress(building.mst_tateya),
            colSpan: 3
          },
          {
            label1: '建物種別',
            value1: building.mst_tateya.TTY_TYS_NAME,
            label2: '構造',
            value2: building.mst_tateya.TTY_TYK_NAME
          },
          {
            label1: '築年月',
            value1: formatDate(
              parseDate(building.mst_tateya.TTY_COMPLETION_DATE),
              'yyyy/MM'
            ),
            label2: '階数',
            value2: fullFloor(building.mst_tateya)
          },
          {
            label1: '建物種別詳細',
            value1: building.mst_tateya.TTY_STS_NAME,
            label2: '管理区分',
            value2: getKnrDiv(building.mst_tateya, knrDivList)
          },
          {
            label1: '管理形態',
            value1: getKnrkeitaiDiv(building.mst_tateya, knrkeitaiDivList),
            label2: '総戸数',
            value2: building.mst_tateya.TTY_TOTAL_COUNT
          },
          {
            label1: '管理パターン',
            value1: building.mst_tateya.TTY_KNRPATTERN_NAME,
            label2: '処理グループ',
            value2: building.mst_tateya.TTY_GRP_NAME
          },
          {
            label1: '建物取扱店舗',
            value1: building.mst_tateya.TTY_TNP_NAME,
            label2: '担当社員1',
            value2: building.mst_tateya.TTY_KNR_TANTO_SHN_NAME
          },
          {
            label1: '担当社員2',
            value1: building.mst_tateya.TTY_BSH_TANTO_SHN_NAME,
            label2: '担当社員3',
            value2: building.mst_tateya.TTY_GET_TANTO_SHN_NAME
          },
          {
            label1: '公共(電気)連絡先名',
            value1: building.mst_tateya.TTY_PUBLIC_ELC_KKY_NAME,
            label2: '公共(電気)TEL',
            value2: building.mst_tateya.TTY_PUBLIC_ELC_KKY_TEL
          },
          {
            label1: '公共(飲料水)連絡先名',
            value1: building.mst_tateya.TTY_PUBLIC_WAT_KKY_NAME,
            label2: '公共(飲料水)TEL',
            value2: building.mst_tateya.TTY_PUBLIC_WAT_KKY_TEL
          },
          {
            label1: '公共(排水)連絡先名',
            value1: building.mst_tateya.TTY_PUBLIC_DRA_KKY_NAME,
            label2: '公共(排水)TEL',
            value2: building.mst_tateya.TTY_PUBLIC_DRA_KKY_TEL
          },
          {
            label1: '公共(ガス)連絡先名',
            value1: building.mst_tateya.TTY_PUBLIC_GUS_KKY_NAME,
            label2: '公共(ガス)TEL',
            value2: building.mst_tateya.TTY_PUBLIC_GUS_KKY_TEL
          },
          {
            label1: '公共(給湯)連絡先名',
            value1: building.mst_tateya.TTY_PUBLIC_HOTW_KKY_NAME,
            label2: '公共(給湯)TEL',
            value2: building.mst_tateya.TTY_PUBLIC_HOTW_KKY_TEL
          }
        ]
      : []

  const renderSingleRow = ({ label, value, colSpan }) => (
    <TableRow>
      <TableCell className={`${classes.columnBase} ${classes.columnName}`}>
        {label}
      </TableCell>
      <TableCell
        className={`${classes.columnBase} ${classes.columnValue1}`}
        colSpan={colSpan}
      >
        {value}
      </TableCell>
    </TableRow>
  )

  const renderDoubleRow = ({ label1, value1, label2, value2 }) => (
    <TableRow>
      <TableCell className={`${classes.columnBase} ${classes.columnName}`}>
        {label1}
      </TableCell>
      <TableCell className={`${classes.columnBase} ${classes.columnValue2}`}>
        {value1}
      </TableCell>
      <TableCell className={`${classes.columnBase} ${classes.columnName}`}>
        {label2}
      </TableCell>
      <TableCell className={`${classes.columnBase} ${classes.columnValue2}`}>
        {value2}
      </TableCell>
    </TableRow>
  )

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          {building && building.mst_tateya && (
            <>
              {rows.map(row =>
                row.label ? renderSingleRow(row) : renderDoubleRow(row)
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BuildingDetail
