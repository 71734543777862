import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import awsconfig from 'aws-exports'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Router from 'containers/Router'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import { SnackbarProvider } from 'notistack'
import { history, store } from 'store'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn:
      'https://a87020ea13874b97a04ba3ac8da8784e@o935617.ingest.sentry.io/5354189',
    release:
      'totono-mng-web@' +
      process.env.REACT_APP_ENV +
      '#' +
      process.env.REACT_APP_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0, // Be sure to lower this in production
    maxBreadcrumbs: 50
  })
}

Amplify.configure({
  ...awsconfig,
  Analytics: { disabled: true }
})

const theme = createMuiTheme({
  props: {
    MuiFilledInput: {
      disableUnderline: true
    }
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#f7f8fa',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
      }
    },
    MuiDivider: {
      root: {
        marginTop: 1
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '700px'
      }
    },
    MuiTableCell: {
      root: {
        height: '64px',
        fontFamily: 'Hiragino Sans',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#2c3f58'
      },
      stickyHeader: {
        fontFamily: 'Hiragino Sans',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#72809d',
        backgroundColor: '#fafbfd'
      }
    }
  },
  palette: {
    primary: grey,
    c_secondary: { main: '#0f8cef' },
    c_info: {
      main: '#778698',
      lighter: '#edf1f5'
    },
    c_alert: '#ff3e1d',
    c_primary: {
      main: '#696cff',
      lighter: '#a7a9fb',
      lightest: '#dfe0ff'
    },
    c_purple: {
      light: '#8789ff',
      main: '#696cff',
      dark: '#494bb2',
      contrastText: '#fff'
    },
    c_light_gray: {
      light: '#f7f9fb',
      main: '#f6f8fa',
      dark: '#acadaf',
      contrastText: '#000'
    },
    c_light_blue: {
      lightest: '#57aff5',
      lighter: '#3fa3f3',
      light: '#2798f2',
      main: '#0f8cf0',
      dark: '#0c70c0',
      contrastText: '#fff'
    },
    c_blue: {
      lightest: '#6fbaf6',
      lighter: '#3fa3f3',
      light: '#3fa3f3',
      main: '#0f8cf0',
      dark: '#0a62a8',
      contrastText: '#fff'
    },
    c_gray: {
      light: '#929eac',
      main: '#778698',
      dark: '#535d6a',
      contrastText: '#fff'
    },
    c_black: {
      light: '#333333',
      main: '#000000',
      dark: '#000000',
      contrastText: '#fff'
    },
    c_red: {
      light: '#ff644a',
      main: '#ff3e1d',
      dark: '#b22b14',
      contrastText: '#fff'
    },
    c_thirdly: {
      light: '#2C3F58',
      main: '#2C3F58',
      dark: '#2C3F58',
      contrastText: '#fff'
    },
    c_white: {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#E2E2E2',
      contrastText: '#000000'
    },
    c_green: {
      light: '#beec5c',
      main: '#A7E521',
      dark: '#87bc16',
      contrastText: '#000000'
    },
    c_background: {
      gray: '#f5f5f5',
      white: '#FFFFFF'
    },
    c_button: {
      main: '#1976d2'
    },
    c_yellow: {
      main: '#FAAF38'
    },
    c_blue2: {
      main: '#0B69B4'
    },
    c_lite_blue: {
      main: '#EDF1F5'
    }
  },
  typography: {
    fontFamily: 'Hiragino Sans W6, Hiragino Sans W3'
  }
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      autoHideDuration={2000}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router />
        </ConnectedRouter>
      </Provider>
    </SnackbarProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
)
