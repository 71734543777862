import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog, setLoadData } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { MutationHelper } from 'utils/api.utils'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormFooter from './FormFooter'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  left: {
    flex: 1,
    display: 'flex'
  },
  textRight: {
    textAlign: 'right'
  },
  body: {},

  center: {
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  formControl: {
    width: '100%'
  },
  centerGray: {
    textAlign: 'center',
    color: 'gray'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '15px 20px'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function CancellationDateFormContent(props) {
  const classes = useStyles()

  function formatDate(date, format) {
    if (!date) {
      format = ''
    } else {
      format = format.replace(/yyyy/g, date.getFullYear())
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
      format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
      format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
      format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))
    }
    return format
  }

  function parseDate(dateStr) {
    return new Date(dateStr).toLocaleString({ timeZone: 'Asia/Tokyo' })
  }

  const content =
    props.application && props.application.content
      ? parseJson(props.application.content)
      : {}

  const [cancellationDates, setCancellationDates] = useState({})

  const orgCancellationDesiredDate =
    content &&
    content.cancellation &&
    content.cancellation.cancellationDates &&
    content.cancellation.cancellationDates.cancellationDesiredDate
      ? parseDate(
          content.cancellation.cancellationDates.cancellationDesiredDate
        )
      : null
  const [cancellationDesiredDate, setCancellationDesiredDate] = React.useState(
    orgCancellationDesiredDate
  )
  const handleChangeCancellationDesiredDate = date => {
    setCancellationDesiredDate(date)
    setCancellationDates(
      Object.assign({}, cancellationDates, {
        cancellationDesiredDate: formatDate(date, 'yyyy-MM-dd')
      })
    )
  }
  const orgCancellationFixedDate =
    content &&
    content.cancellation &&
    content.cancellation.cancellationDates &&
    content.cancellation.cancellationDates.cancellationFixedDate
      ? parseDate(content.cancellation.cancellationDates.cancellationFixedDate)
      : null
  const [cancellationFixedDate, setCancellationFixedDate] = React.useState(
    orgCancellationFixedDate
  )
  const handleChangeCancellationFixedDate = date => {
    setCancellationFixedDate(date)
    setCancellationDates(
      Object.assign({}, cancellationDates, {
        cancellationFixedDate: formatDate(date, 'yyyy-MM-dd')
      })
    )
  }
  const orgDepartureDesiredDate =
    content &&
    content.cancellation &&
    content.cancellation.cancellationDates &&
    content.cancellation.cancellationDates.departureDesiredDate
      ? parseDate(content.cancellation.cancellationDates.departureDesiredDate)
      : null
  const [departureDesiredDate, setDepartureDesiredDate] = React.useState(
    orgDepartureDesiredDate
  )
  const handleChangeDepartureDesiredDate = date => {
    setDepartureDesiredDate(date)
    setCancellationDates(
      Object.assign({}, cancellationDates, {
        departureDesiredDate: formatDate(date, 'yyyy-MM-dd')
      })
    )
  }

  const orgDepartureFixedDate =
    content &&
    content.cancellation &&
    content.cancellation.cancellationDates &&
    content.cancellation.cancellationDates.departureFixedDate
      ? parseDate(content.cancellation.cancellationDates.departureFixedDate)
      : null
  const [departureFixedDate, setDepartureFixedDate] = React.useState(
    orgDepartureFixedDate
  )
  const handleChangeDepartureFixedDate = date => {
    setDepartureFixedDate(date)
    setCancellationDates(
      Object.assign({}, cancellationDates, {
        departureFixedDate: formatDate(date, 'yyyy-MM-dd')
      })
    )
  }

  const handleSubmit = async event => {
    event.preventDefault()
    props.closeModalDialog()
    props.setLoadData(true)
    const params = { id: props.application.id }
    if (Object.keys(cancellationDates).length > 0) {
      params['cancellationDates'] = cancellationDates
    }
    const result = await MutationHelper(
      'updateResidentCancellationApplication',
      { input: params }
    )
    console.log(result)
    if (result.error) {
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      await props.fetchResidentCancellationApplication(props.application.id)
      setCancellationDates({})
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
    props.setLoadData(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={4} xs>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="filled"
                format="yyyy-MM-dd"
                margin="normal"
                id="cancellationDesiredDate"
                label="解約希望日"
                value={cancellationDesiredDate}
                onChange={handleChangeCancellationDesiredDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="filled"
                format="yyyy-MM-dd"
                margin="normal"
                id="departureDesiredDate"
                label="退去希望日"
                value={departureDesiredDate}
                onChange={handleChangeDepartureDesiredDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container spacing={4} xs>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="filled"
                format="yyyy-MM-dd"
                margin="normal"
                id="cancellationFixedDate"
                label="解約日"
                value={cancellationFixedDate}
                onChange={handleChangeCancellationFixedDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="filled"
                format="yyyy-MM-dd"
                margin="normal"
                id="departureFixedDate"
                label="退去日"
                value={departureFixedDate}
                onChange={handleChangeDepartureFixedDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.footerWrapper}>
        <FormFooter />
      </DialogActions>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(CancellationDateFormContent)))
