import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog } from 'actions/Actions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useHighlightText } from 'hooks/useHighlightText'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTypography-h6': {
      height: '24px',
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  formContent: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  contentDescription: {
    flex: 1,
    display: 'flex',
    marginBottom: '20px',
    paddingLeft: '18px',
    paddingRight: '18px',
    borderRadius: '4px',
    backgroundColor: '#FEF7E0',
    alignItems: 'center'
  },
  descriptionIcon: {
    color: '#FFD422'
  },
  descriptionText: {
    marginLeft: '14px'
  },
  highlighted: {
    color: 'rgba(0, 0, 0, 0.70)'
  },
  formFooter: {
    marginTop: '8px',
    marginLeft: '16px',
    marginBottom: '8px',
    marginRight: '16px'
  },
  inactiveButton: {
    color: theme.palette.c_gray.contrastText,
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  },
  deleteButton: {
    color: theme.palette.c_red.contrastText,
    backgroundColor: theme.palette.c_red.main,
    '&:hover': {
      backgroundColor: theme.palette.c_red.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function FormModalDialog(props) {
  const classes = useStyles()

  const {
    open,
    title,
    description,
    content,
    footer,
    handleSubmit,
    handleClose,
    postData
  } = props

  const handleFormSubmit = event => {
    event.preventDefault()
    handleSubmit()
  }

  const highlightedDescription = useHighlightText(
    description,
    '停止',
    classes.highlighted
  )

  return (
    <>
      <Dialog
        open={postData ? props.isOpen : open}
        onClose={postData ? props.closeModalDialog : handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <form onSubmit={postData ? postData : handleFormSubmit}>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <Divider />
          <DialogContent className={classes.formContent}>
            {description != '' && (
              <DialogContentText className={classes.contentDescription}>
                <ReportProblemIcon className={classes.descriptionIcon} />
                <Typography className={classes.descriptionText}>
                  {highlightedDescription}
                </Typography>
              </DialogContentText>
            )}
            {content}
          </DialogContent>
          <Divider />
          {postData ? (
            <DialogActions className={classes.formFooter}>
              {footer}
            </DialogActions>
          ) : (
            <DialogActions className={classes.formFooter}>
              <Button
                onClick={handleClose}
                variant="contained"
                className={classes.secondaryButton}
              >
                キャンセル
              </Button>
              <Button
                type="submit"
                variant="contained"
                className={classes.inactiveButton}
              >
                停止
              </Button>
            </DialogActions>
          )}
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormModalDialog)
