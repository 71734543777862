import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextInput from 'components/common/Form/TextInput'
import Typography from '@material-ui/core/Typography'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: 'unset'
  },
  button: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_light_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_blue.dark
    }
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    fontWeight: 'bold',
    marginRight: 10
  },
  subContent: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#72809D',
    marginTop: 2
  }
}))

function AutoReplyTime(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { start, setStart, end, setEnd } = props
  const [validate, setValidate] = useState({})

  function isTime(timeStr) {
    return timeStr.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/) !== null
  }

  const handleChangeStart = event => {
    const value = event.target.value
    if (!value || value === '' || !isTime(value)) {
      setValidate(
        Object.assign({}, validate, { start: { message: '不正な値です' } })
      )
      return
    } else {
      delete validate['start']
    }
    setStart(value)
  }

  const handleChangeEnd = event => {
    const value = event.target.value
    if (!value || value === '' || !isTime(value)) {
      setValidate(
        Object.assign({}, validate, { end: { message: '不正な値です' } })
      )
      return
    } else {
      delete validate['end']
    }
    setEnd(value)
  }

  return (
    <>
      <Grid container xs={12} alignItems="left">
        <Typography variant="body1" className={classes.content}>
          自動返信時間
        </Typography>
        <Typography variant="body1" className={classes.subContent}>
          ※設定した時間には自動返信に切り替わります。
        </Typography>
      </Grid>
      <Grid container xs={12} alignItems="left">
        <Grid container xs={5} alignItems="left">
          <TextInput
            required
            id="start"
            name="start"
            label="開始時刻"
            type="time"
            variant="filled"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: 600
            }}
            defaultValue={start}
            onChange={handleChangeStart}
            error={validate && validate.start && validate.start.message}
            helperText={validate && validate.start && validate.start.message}
            disabled={!isUnrestrictedRole}
          />
        </Grid>
        <Grid container xs={1} alignItems="left" />
        <Grid container xs={5} alignItems="left">
          <TextInput
            required
            id="end"
            name="end"
            label="終了時刻"
            type="time"
            variant="filled"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: 600
            }}
            defaultValue={end}
            onChange={handleChangeEnd}
            error={validate && validate.end && validate.end.message}
            helperText={validate && validate.end && validate.end.message}
            disabled={!isUnrestrictedRole}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AutoReplyTime
