import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import List from './List'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pageId } = useParams()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [bulkDeliveryTemplates, setBulkDeliveryTemplates] = useState(
    initialState
  )
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : {}
  )
  const [isLoading, setIsLoading] = useState(false)
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )
  const handleChangePage = (event, newPage) => {
    var params = Object.assign({}, searchCondition, { pageNumber: newPage + 1 })
    setSearchCondition(params, setPageNumber(newPage + 1))
  }
  const handleCustomChangePage = newPage => {
    var params = Object.assign({}, searchCondition, { pageNumber: newPage })
    setSearchCondition(params, setPageNumber(newPage))
  }

  async function fetchBulkDeliveryTemplates(offset, limit) {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'setting.bulkDeliveryTemplate.title') {
          filter['title'] = {
            contains: searchCondition[key]
          }
        }
      }
    })

    if (orderBy && Object.keys(orderBy).length > 0) {
      filter.orderBy = [orderBy]
    }

    let result = await QueryHelper(
      'getBulkDeliveryTemplates',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('配信リスト一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBulkDeliveryTemplates(result)
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([fetchBulkDeliveryTemplates(offset, PAGE_LIMIT)])
    setIsLoading(false)
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `/setting/bulk-delivery/template/list/${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, searchCondition, setBulkDeliveryTemplates, orderBy])

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchCondition['orderBy'] = sortParam
    setPageNumber(1)
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs className={classes.tableTips}>
          <Button
            component={Link}
            to="/setting/bulk-delivery/template/new"
            variant="contained"
            startIcon={<AddIcon />}
            className={classes.button}
            disabled={!isUnrestrictedRole}
          >
            新規作成
          </Button>
        </Grid>
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={bulkDeliveryTemplates.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && bulkDeliveryTemplates ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              bulkDeliveryTemplates={bulkDeliveryTemplates.data}
              searchCondition={searchCondition}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={bulkDeliveryTemplates.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
    </div>
  )
}

export default withSnackbar(ListContent)
