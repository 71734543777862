import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeSearchSaveModalDialog } from 'actions/Actions'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

const useStyles = makeStyles(() => ({
  formPaper: {
    minWidth: '800px',
    display: 'flex'
  },
  formTitle: {
    backgroundColor: '#2C88D9'
  },
  formContent: {
    paddingTop: '20px',
    paddingBottom: '20px'
  }
}))

function SearchSaveModalDialog(props) {
  const classes = useStyles()
  const { content } = props

  return (
    <>
      <Dialog
        open={props.isSearchSaveOpen}
        onClose={props.closeSearchSaveModalDialog}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.formPaper }}
      >
        <DialogTitle className={classes.formTitle}>
          <font color="#FFFFFF">検索条件設定</font>
        </DialogTitle>
        <DialogContent className={classes.formContent}>{content}</DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isSearchSaveOpen: state.searchSaveModalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchSaveModalDialog)
