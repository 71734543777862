import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ThumbnailMovie from './ThumbnailMovie'

const useStyles = makeStyles(theme => ({
  movieContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(n+2)': {
      marginTop: 10
    }
  },
  movieRow: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 'auto',
    marginLeft: 20,
    fontWeight: 'bold',
    fontSize: '16px'
  }
}))

export default function RowWithMovie({
  attachment,
  movie,
  thumbnailImage,
  title,
  handleDelete,
  isDeleteButtonView = true,
  disabledIcon
}) {
  const classes = useStyles()

  return (
    <Grid container className={classes.movieContainer}>
      <Grid xs={11} item>
        <Grid container className={classes.movieRow}>
          <Grid item>
            <ThumbnailMovie
              attachment={attachment}
              movie={movie}
              thumbnailImage={thumbnailImage}
              title={title}
            />
          </Grid>
          <Grid item xs>
            <span className={classes.title} style={{}}>
              {title}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {isDeleteButtonView && (
        <Grid xs={1} item>
          <Grid container justify="flex-end">
            <Grid item zeroMinWidth>
              <IconButton
                aria-label="削除"
                color="primary"
                onClick={handleDelete}
                disabled={disabledIcon}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
