import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
// import { clearSearchCondition, changeSearchCondition } from 'actions/Actions';
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
// import { QueryHelper } from 'utils/api.utils';

// const useStyles = makeStyles(theme => ({
//   formControl: {
//     // margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

function SearchFormContent(props) {
  // const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextInput
          name="management.room.hya_room_no"
          label="部屋番号"
          defaultValue={
            props.searchCondition &&
            'management.room.hya_room_no' in props.searchCondition
              ? props.searchCondition['management.room.hya_room_no']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.room.tenant_id"
          label="入居者名"
          defaultValue={
            props.searchCondition &&
            'management.room.tenant_id' in props.searchCondition
              ? props.searchCondition['management.room.tenant_id']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.room.contractor_id"
          label="契約者名"
          defaultValue={
            props.searchCondition &&
            'management.room.contractor_id' in props.searchCondition
              ? props.searchCondition['management.room.contractor_id']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  return {
    conditions: state.searchCondition
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // clearSearchCondition: () => {
    //   dispatch(clearSearchCondition())
    // },
    // changeSearchCondition: (parameter) => {
    //   dispatch(changeSearchCondition(parameter))
    // },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SearchFormContent))
