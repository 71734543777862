import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import RootReducer from '../reducers/RootReducer'

//for Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const history = createBrowserHistory()

const configureStore = initialState => {
  const enhancer = composeEnhancers(applyMiddleware(routerMiddleware(history)))
  return createStore(RootReducer(history), initialState, enhancer)
}
export const store = configureStore({})
