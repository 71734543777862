import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import DomainIcon from '@material-ui/icons/Domain'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import SettingsCellIcon from '@material-ui/icons/SettingsCell'
import MobileOffIcon from '@material-ui/icons/MobileOff'
import SmsIcon from '@material-ui/icons/Sms'
import Label from 'components/common/Label'
import Loading from 'components/common/Loading'
import { QueryHelper } from 'utils/api.utils'
import { HyaTysCd } from 'utils/enum'
import { withSnackbar } from 'notistack'
import { useOwnerFunctionEnabled } from 'hooks/useOwnerFunctionEnabled'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  header: {
    padding: '16px 24px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '16px 16px'
  },
  columnName: {
    marginLeft: '8px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2C3F58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2C3F58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  divider: {
    marginLeft: '16px'
  }
}))

function Right(props) {
  const classes = useStyles()
  const { isOwnerFunctionEnabled } = useOwnerFunctionEnabled()
  const { role12 } = useRole()

  const { isLoading, building, buildingOwnerList } = props

  const [threadList, setThreadList] = useState(null)
  const [enabledAppUserCount, setEnabledAppUserCount] = useState(null)
  const [disabledAppUserCount, setDisabledAppUserCount] = useState(null)
  const [dwellingUnitCount, setDwellingUnitCount] = useState(null)
  const [otherDwellingUnitCount, setOtherDwellingUnitCount] = useState(null)
  const [totalCount, setTotalCount] = useState(null)
  const [isLoad, setIsLoad] = useState(false)

  async function fetchThreadList(buildingId) {
    const params = {
      thread_state_id: { eq: 1 },
      building_id: { eq: buildingId }
    }
    let result = await QueryHelper(
      'getThreadListBySearchCondition',
      {
        filter: params
      },
      false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('メッセージ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setThreadList(result)
    }
  }

  const fetchContractListByDisabledAppUser = async buildingId => {
    let disabledAppUserTotalCount = 0
    const params = {
      building_id: {
        eq: buildingId
      },
      is_use_app: {
        eq: false
      },
      kyk_status_div: {
        eq: [1, 2, 3]
      }
    }
    const result = await QueryHelper('getContractList2', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`入居者一覧を取得できませんでした。`)
    } else {
      if (result.paging) {
        disabledAppUserTotalCount += result.paging.count
      }
    }

    setDisabledAppUserCount(disabledAppUserTotalCount)
  }

  const fetchContractListByEnabledAppUser = async buildingId => {
    const params = {
      building_id: {
        eq: buildingId
      },
      is_use_app: {
        eq: true
      }
    }
    const result = await QueryHelper('getContractList2', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`入居者一覧を取得できませんでした。`)
    } else {
      if (result.paging) {
        setEnabledAppUserCount(result.paging.count)
      }
    }
  }

  const fetchRoomList = async buildingId => {
    const params = {
      building_id: {
        eq: buildingId
      }
    }
    const result = await QueryHelper('getRoomList2', { filter: params })
    if (!result.error) {
      let dwellingUnitCnt = 0
      let otherDwellingUnitCnt = 0
      for (let i of result.data) {
        if (i.mst_heya) {
          if (
            i.mst_heya.HYA_TYS_CD === HyaTysCd.house.code ||
            i.mst_heya.HYA_TYS_CD === HyaTysCd.financeCorporation.code
          ) {
            dwellingUnitCnt++
          } else {
            otherDwellingUnitCnt++
          }
        }
      }
      setDwellingUnitCount(dwellingUnitCnt)
      setOtherDwellingUnitCount(otherDwellingUnitCnt)
      setTotalCount(dwellingUnitCnt + otherDwellingUnitCnt)
    } else {
      props.enqueueSnackbar(`部屋一覧を取得できませんでした。`)
    }
  }

  useEffect(() => {
    if ('building' in building && building.building) {
      fetchData(building.building.id)
    }
  }, [building, setThreadList])

  const fetchData = async buildingId => {
    setIsLoad(true)
    await Promise.all([
      fetchThreadList(buildingId),
      fetchContractListByDisabledAppUser(buildingId),
      fetchContractListByEnabledAppUser(buildingId),
      fetchRoomList(buildingId)
    ])
    setIsLoad(false)
  }

  return (
    <>
      <div className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.title}>物件の状態</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Label color="blue" text="管理中" />
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <DomainIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>総戸数</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad && totalCount === null ? (
                <Loading isLoading={isLoad} />
              ) : (
                <>{totalCount !== null ? totalCount : ''}</>
              )}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography className={classes.columnName}>(住戸)</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad && dwellingUnitCount === null ? (
                <Loading isLoading={isLoad} />
              ) : (
                <>{dwellingUnitCount !== null ? dwellingUnitCount : ''}</>
              )}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography className={classes.columnName}>(その他)</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad && otherDwellingUnitCount === null ? (
                <Loading isLoading={isLoad} />
              ) : (
                <>
                  {otherDwellingUnitCount !== null
                    ? otherDwellingUnitCount
                    : ''}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <DomainIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>対象戸数</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoading && building ? (
                <Loading isLoading={isLoading} />
              ) : (
                <>
                  {'building' in building && building.building
                    ? building.building.room_cnt
                    : ''}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <AccessibilityNewIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>入居人数</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoading && building ? (
                <Loading isLoading={isLoading} />
              ) : (
                <>
                  {'building' in building && building.building
                    ? building.building.contract_cnt
                    : ''}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <SettingsCellIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>アプリ使用者</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad || enabledAppUserCount === null ? (
                <Loading isLoading={isLoad || enabledAppUserCount === null} />
              ) : (
                <>{enabledAppUserCount !== null ? enabledAppUserCount : ''}</>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <MobileOffIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>アプリ無効者</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad || disabledAppUserCount === null ? (
                <Loading isLoading={isLoad || disabledAppUserCount === null} />
              ) : (
                <>{disabledAppUserCount !== null ? disabledAppUserCount : ''}</>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <SmsIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>
              未対応メッセージ
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoading || isLoad || !threadList ? (
                <Loading isLoading={isLoading || isLoad || !threadList} />
              ) : (
                <>{threadList ? threadList.data.length : 0}</>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {isOwnerFunctionEnabled && role12() && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <AccessibilityNewIcon className={classes.icon} />
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.columnName}>
                  オーナー人数
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : (
                    <>{buildingOwnerList && buildingOwnerList.paging.count}</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      <Divider />
    </>
  )
}

export default withSnackbar(Right)
