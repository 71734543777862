import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { fromCode } from 'utils/enum.utils'
import {
  PetApplicationCategory,
  PetApplicationType,
  PetGender,
  PetSpay
} from 'utils/enum'
import RowWithFile from 'components/common/RowWithFile'
import { Storage } from 'aws-amplify'
import { formatYMD } from 'utils/date.utils'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function CellWithPetApplicationType({ typeId }) {
  const petApplicationType = fromCode(PetApplicationType, typeId)
  return <>{petApplicationType.value}</>
}

function CellWithPetApplicationCategory({ categoryId }) {
  const petApplicationCategory = fromCode(PetApplicationCategory, categoryId)
  return <>{petApplicationCategory.value}</>
}

function CellWithPetApplicationGender({ genderId }) {
  const petApplicationGender = fromCode(PetGender, genderId)
  return <>{petApplicationGender.value}</>
}

function CellWithPetApplicationSpayed({ spayedId }) {
  const petSpay = fromCode(PetSpay, spayedId)
  return <>{petSpay.value}</>
}

function getAttachment() {}

function formatDate(birthdate) {
  if (birthdate) {
    const date = new Date(birthdate)
    return formatYMD(date)
  }
  return ''
}

function PetApplicationDetail(props) {
  const classes = useStyles()
  const [images, setImages] = useState([])

  let { isLoading, content, attachments } = props

  async function getImage(atta) {
    const imageList = []
    for (let attachment of attachments) {
      const result = await Storage.get(attachment.key, {
        level: 'protected',
        identityId: attachment.company_id,
        expires: 60
      })
      const object = Object.assign({}, attachment, { body: result })
      imageList.push(object)
    }
    setImages(imageList)
  }

  useEffect(() => {
    if (attachments) {
      getImage()
    }
  }, [attachments, setImages])

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.root} colSpan="4">
              申請内容
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell className={classes.columnName2}>
                <Loading isLoading={isLoading} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow>
                <TableCell className={classes.columnName2}>区分</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'typeId' in content ? (
                    <CellWithPetApplicationType typeId={content.typeId} />
                  ) : null}
                </TableCell>
                <TableCell className={classes.columnName2}>種類</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'categoryId' in content ? (
                    <CellWithPetApplicationCategory
                      categoryId={content.categoryId}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.columnName2}>品種</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'breed' in content ? content.breed : null}
                </TableCell>
                <TableCell className={classes.columnName2}>性別</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'genderId' in content ? (
                    <CellWithPetApplicationGender genderId={content.genderId} />
                  ) : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.columnName2}>避妊/去勢</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'spayedId' in content ? (
                    <CellWithPetApplicationSpayed spayedId={content.spayedId} />
                  ) : null}
                </TableCell>
                <TableCell className={classes.columnName2}>年月日</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'birth' in content
                    ? formatDate(content.birth)
                    : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.columnName2}>毛色</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'coatColor' in content ? content.coatColor : null}
                </TableCell>
                <TableCell className={classes.columnName2}>体高</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'bodyHeight' in content
                    ? content.bodyHeight
                    : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.columnName2}>全身写真</TableCell>
                <TableCell className={classes.columnName2} colSpan="3">
                  {images.length > 0 &&
                    images.map(({ status, filename, body }, index) => {
                      return status == 1 ? (
                        <RowWithFile
                          key={index}
                          title={filename}
                          image={body}
                          isDeleteButtonView={false}
                          style={{ marginBottom: 10 }}
                        />
                      ) : (
                        <></>
                      )
                    })}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PetApplicationDetail
