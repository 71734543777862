import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './RichTextEditorStyles.css'
import { useFormContext } from 'react-hook-form'
import { ErrorMessages } from 'utils/errorMessages'

const useStyles = makeStyles(() => ({
  qlEditor: {
    textAlign: 'right'
  },
  qlToolbar: {
    textAlign: 'center'
  },
  textInput: {
    height: 200,
    '& .ql-snow .ql-tooltip': {
      position: 'absolute',
      transform: 'translate(30%,5px)'
    },
    '& .ql-container': {
      // フォントサイズを指定しないとdefaultValueの値が管理画面上ではデフォルトの13pxとなってしまうため
      fontSize: '15px'
    },
    '& .ql-tooltip': {
      // 最終行でリンク挿入欄が隠れてしまうため、他の要素よりも前面に表示する
      zIndex: 2
    }
  },
  errorMessage: {
    color: 'red',
    fontSize: 12,
    textAlign: 'left',
    margin: '0 14px 4px'
  },
  disabledTextField: {
    backgroundColor: '#e0e0e0',
    color: '#9e9e9e'
  }
}))

const ToolbarContainer = props => {
  return (
    <div id={`toolbar-container-${props.id}`}>
      <span className="ql-formats">
        <select className="ql-size" defaultValue="15px">
          <option value="12px">小さめ</option>
          <option value="15px">標準</option>
          <option value="18px">大きめ</option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-color">
          <option value="#E34AC2" />
          <option value="#A250D4" />
          <option value="#5056D4" />
          <option value="#65C963" />
          <option value="#F9A932" />
          <option value="#EE3C32" />
          <option value="#232323" />
          <option value="#812A6E" />
          <option value="#521E72" />
          <option value="#2A2D82" />
          <option value="#136211" />
          <option value="#98610F" />
          <option value="#822F2A" />
          <option value="#707070" />
          <option value="#C69BBD" />
          <option value="#B69BC7" />
          <option value="#9B9DC7" />
          <option value="#9CC79B" />
          <option value="#E6BE82" />
          <option value="#DCA29F" />
          <option value="#ACACAC" />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-underline"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-link"></button>
      </span>
    </div>
  )
}

const RichTextEditor = props => {
  const classes = useStyles()
  const {
    id,
    maxLength,
    placeholder,
    defaultValue,
    error,
    setCheckError,
    checkError,
    readOnly = false,
    handleChange,
    customErrorMessage,
    required = false
  } = props
  const { setValue } = useFormContext()
  const [editorValue, setEditorValue] = useState(defaultValue)

  var Size = Quill.import('attributors/style/size')
  Size.whitelist = ['12px', '15px', '18px']
  Quill.register(Size, true)

  const modules = { toolbar: `#toolbar-container-${id}` }

  const formats = ['size', 'color', 'bold', 'underline', 'link']

  const onChange = (content, delta, source, editor) => {
    // Quillの空の状態は<p><br></p>となるので、空の場合は空文字をセットする
    if (content === '<p><br></p>') {
      setEditorValue('')

      if (required) {
        setCheckError({ ...checkError, [id]: ErrorMessages.Required })
        handleChange(id, content)
      } else {
        setValue(id, '')
        setCheckError({ ...checkError, [id]: '' })
      }
    } else {
      setEditorValue(content)

      // useFormでの文字数チェックはタグも含めて文字数をチェックしてしまう為、ここでタグを除いた文字数をチェックする
      const text = editor.getText()
      if (text.length > maxLength) {
        setCheckError({
          ...checkError,
          [id]:
            customErrorMessage || ` ${maxLength}文字以内で入力してください。`
        })
      } else {
        setCheckError({ ...checkError, [id]: '' })
      }

      if (setValue) {
        setValue(id, content)
      }

      if (handleChange) {
        handleChange(id, content)
      }
    }
  }

  useEffect(() => {
    setEditorValue(defaultValue)
  }, [defaultValue])

  return (
    <div className={classes.qlEditor}>
      {error && <div className={classes.errorMessage}>{error}</div>}
      {checkError && checkError[id] && (
        <div className={classes.errorMessage}>{checkError[id]}</div>
      )}
      <ToolbarContainer id={id} />
      <ReactQuill
        className={`${classes.textInput} ${
          readOnly ? classes.disabledTextField : ''
        }`}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        preserveWhitespace
        value={editorValue}
        readOnly={readOnly}
      />
    </div>
  )
}

export default RichTextEditor
