export const PAGE_LIMIT = 15
export const BULK_DELIVERY_CSV_LIMIT = 3000000
export const STOCK_SIGNAGE_LIST = [
  // { value: 0, label: "指定なし" },
  { value: 1, label: '前株' },
  { value: 2, label: '後株' }
]
export const PREFECTURE_LIST = [
  // { value: 0, label: "指定なし" },
  { value: 1, label: '北海道' },
  { value: 2, label: '青森県' },
  { value: 3, label: '岩手県' },
  { value: 4, label: '宮城県' },
  { value: 5, label: '秋田県' },
  { value: 6, label: '山形県' },
  { value: 7, label: '福島県' },
  { value: 8, label: '茨城県' },
  { value: 9, label: '栃木県' },
  { value: 10, label: '群馬県' },
  { value: 11, label: '埼玉県' },
  { value: 12, label: '千葉県' },
  { value: 13, label: '東京都' },
  { value: 14, label: '神奈川県' },
  { value: 15, label: '新潟県' },
  { value: 16, label: '富山県' },
  { value: 17, label: '石川県' },
  { value: 18, label: '福井県' },
  { value: 19, label: '山梨県' },
  { value: 20, label: '長野県' },
  { value: 21, label: '岐阜県' },
  { value: 22, label: '静岡県' },
  { value: 23, label: '愛知県' },
  { value: 24, label: '三重県' },
  { value: 25, label: '滋賀県' },
  { value: 26, label: '京都府' },
  { value: 27, label: '大阪府' },
  { value: 28, label: '兵庫県' },
  { value: 29, label: '奈良県' },
  { value: 30, label: '和歌山県' },
  { value: 31, label: '鳥取県' },
  { value: 32, label: '島根県' },
  { value: 33, label: '岡山県' },
  { value: 34, label: '広島県' },
  { value: 35, label: '山口県' },
  { value: 36, label: '徳島県' },
  { value: 37, label: '香川県' },
  { value: 38, label: '愛媛県' },
  { value: 39, label: '高知県' },
  { value: 40, label: '福岡県' },
  { value: 41, label: '佐賀県' },
  { value: 42, label: '長崎県' },
  { value: 43, label: '熊本県' },
  { value: 44, label: '大分県' },
  { value: 45, label: '宮崎県' },
  { value: 46, label: '鹿児島県' },
  { value: 47, label: '沖縄' }
]
export const STATUS_LIST = [
  {
    value: 1,
    label: '利用中',
    color: 'blue',
    externalId: 5,
    externalColor: 'green'
  },
  { value: 2, label: '停止', color: 'gray' },
  { value: 3, label: '削除', color: 'gray' }
]
export const MANAGEMENT_LIST = [
  { value: 1, label: '有効' },
  { value: 0, label: '無効' }
]
export const LINK_DATA_STATE_LIST = [
  { value: 1, label: '処理待ち', color: 'blue' },
  { value: 2, label: '処理中', color: 'blue' },
  { value: 3, label: '完了', color: 'gray' },
  { value: 4, label: '失敗', color: 'red' },
  { value: 5, label: '異常終了', color: 'red' }
]
export const HYA_STATUS_LIST = [
  { value: 0, label: '空室', color: 'gray' },
  { value: 1, label: '予約', color: 'gray' },
  { value: 2, label: '申込中', color: 'gray' },
  { value: 3, label: '在室', color: 'blue' },
  { value: 4, label: '家主調整中', color: 'gray' },
  { value: 5, label: '中止', color: 'gray' }
]

export const WEEK_LIST = ['日', '月', '火', '水', '木', '金', '土']

export const HYA_HYA_SYSTEM_DIV_LIST = [
  { value: 1, label: '住居' },
  { value: 2, label: '事業' },
  { value: 3, label: '駐車場' },
  { value: 4, label: '区画外（電柱、自動販売機、看板など）' }
]

export const BULK_DELIVERY_TYPE_LIST = [
  { value: 0, label: '', key: '' },
  { value: 1, label: 'CSV', key: 'CSV' },
  { value: 2, label: '検索', key: 'SEARCH' }
]

export const BULK_DELIVERY_STATUS_LIST = [
  { value: 0, label: '', key: '' },
  { value: 1, label: '送信済', key: 'SENT' },
  { value: 2, label: '下書き', key: 'DRAFT' },
  { value: 3, label: '予約', key: 'RESERVE' },
  { value: 4, label: '作成中', key: 'CREATING' }
]
