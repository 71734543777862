import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Cache } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { setSignin, deleteSearchConditions } from 'actions/Actions'

const useStyles = makeStyles(() => ({
  logo: {
    width: 300,
    height: 'auto',
    marginTop: '150px',
    marginBottom: '50px'
  },
  img: {
    width: 100,
    height: 'auto',
    marginBottom: '50px'
  },
  mainMessage: {
    fontSize: '36px',
    marginBottom: '10px',
    color: '#237868'
  },
  subMessage: {
    fontSize: '24px',
    marginBottom: '50px'
  }
}))

function ErrorScreen(props) {
  const history = useHistory()
  const classes = useStyles()
  const mainMessage = props.location.state.mainMessage || ''
  const subMessage = props.location.state.subMessage || ''

  const handleSignOut = () => {
    setSignin(false)
    localStorage.removeItem('totono-advertiser-session')
    sessionStorage.removeItem('totono-advertiser-session')
    deleteSearchConditions()
    Cache.clear()
    history.push('/authorize/signin')
  }

  history.listen((location, action) => {
    if (action === 'POP') {
      history.push('/authorize/signin')
    }
  })

  return (
    <Grid container justify="center" alignItems="center" direction="column">
      <Grid item xs={12}>
        <img
          className={classes.logo}
          src={`${process.env.PUBLIC_URL}/totono_logo.png`}
          alt={'totono'}
        />
      </Grid>
      <Grid item xs={12}>
        <img
          className={classes.img}
          src={`${process.env.PUBLIC_URL}/exclamation_mark.png`}
          alt={'totono'}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.mainMessage}>{mainMessage}</div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.subMessage}>{subMessage}</div>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleSignOut} variant="outlined">
          ログアウト
        </Button>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = () => {}

const mapDispatchToProps = dispatch => {
  return {
    setSignin: signin => dispatch(setSignin(signin)),
    deleteSearchConditions: () => {
      dispatch(deleteSearchConditions())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ErrorScreen))
