import React from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withSnackbar } from 'notistack'
import { Grid, TableFooter } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import TimerIcon from '@material-ui/icons/Timer'
import DraftsIcon from '@material-ui/icons/Drafts'
import EventNoteIcon from '@material-ui/icons/EventNote'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import IconWithText from 'components/common/IconWithText'
import HelpIcon from '@material-ui/icons/Help'
import { BulletinBoardState, BulletinBoardType } from 'utils/enum'
import { formatYYYYMMDD } from 'utils/date.utils'
import { makeStyles } from '@material-ui/core/styles'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const useStyles = makeStyles(theme => ({
  gridLink: {
    cursor: 'pointer'
  }
}))

const columns = [
  { id: 'is_important', label: '重要', minWidth: 62 },
  { id: 'status', label: '状態', minWidth: 54 },
  { id: 'title', label: 'タイトル', minWidth: 373 },
  { id: 'bulletin_board_type_id', label: 'カテゴリ', minWidth: 132 },
  { id: 'display', label: '掲載期間', minWidth: 125 }
]

function CellWithStatus({ status, displayStartAt, displayEndAt }) {
  switch (status) {
    case 2:
      return (
        <IconWithText
          text={BulletinBoardState.draft.value}
          icon={<DraftsIcon />}
          color={'c_thirdly'}
        />
      )
    case 1:
      if (!displayStartAt && !displayEndAt) {
        return (
          <IconWithText
            text={BulletinBoardState.draft.value}
            icon={<DraftsIcon />}
            color={'c_thirdly'}
          />
        )
      }
      const startDate = new Date(displayStartAt)
      const endDate = new Date(displayEndAt)
      const now = new Date()
      now.setHours(0)
      now.setMinutes(0)
      now.setSeconds(0)
      now.setMilliseconds(0)
      if (now.getTime() < startDate.getTime()) {
        return (
          <IconWithText
            text={BulletinBoardState.reservation.value}
            icon={<TimerIcon />}
            color={'c_thirdly'}
          />
        )
      }
      if (now.getTime() > endDate.getTime()) {
        return (
          <IconWithText
            text={BulletinBoardState.complete.value}
            icon={<BeenhereIcon />}
            color={'c_thirdly'}
          />
        )
      }
      if (
        now.getTime() >= startDate.getTime() &&
        now.getTime() <= endDate.getTime()
      ) {
        return (
          <IconWithText
            text={BulletinBoardState.posting.value}
            icon={<EventNoteIcon />}
            color={'c_blue'}
          />
        )
      }
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithBulletinBoardStateId({ bulletinBoardStateId }) {
  switch (bulletinBoardStateId) {
    case BulletinBoardState.draft.code:
      return (
        <IconWithText
          text={BulletinBoardState.draft.value}
          icon={<DraftsIcon />}
          color={'c_thirdly'}
        />
      )
    case BulletinBoardState.reservation.code:
      return (
        <IconWithText
          text={BulletinBoardState.reservation.value}
          icon={<TimerIcon />}
          color={'c_thirdly'}
        />
      )
    case BulletinBoardState.posting.code:
      return (
        <IconWithText
          text={BulletinBoardState.posting.value}
          icon={<EventNoteIcon />}
          color={'c_blue'}
        />
      )
    case BulletinBoardState.complete.code:
      return (
        <IconWithText
          text={BulletinBoardState.complete.value}
          icon={<BeenhereIcon />}
          color={'c_thirdly'}
        />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithImportant({ isImportant, status }) {
  const color =
    status === BulletinBoardState.posting.code ? 'c_blue' : 'c_thirdly'
  if (isImportant) {
    return <IconWithText text={'重要'} icon={<BookmarkIcon />} color={color} />
  } else {
    return <></>
  }
}

function CellWithType({ typeId }) {
  switch (typeId) {
    case BulletinBoardType.notify.code:
      return <>{BulletinBoardType.notify.value}</>
    case BulletinBoardType.facility.code:
      return <>{BulletinBoardType.facility.value}</>
    case BulletinBoardType.campaign.code:
      return <>{BulletinBoardType.campaign.value}</>
    case BulletinBoardType.resident.code:
      return <>{BulletinBoardType.resident.value}</>
    case BulletinBoardType.contract.code:
      return <>{BulletinBoardType.contract.value}</>
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithDisplayDate({ displayStartAt, displayEndAt }) {
  if (displayStartAt && displayEndAt) {
    const startDate = new Date(displayStartAt)
    const endDate = new Date(displayEndAt)
    return (
      <>
        {formatYYYYMMDD(startDate)}~<br />
        {formatYYYYMMDD(endDate)}
      </>
    )
  } else if (displayStartAt) {
    const startDate = new Date(displayStartAt)
    return <>{formatYYYYMMDD(startDate)}</>
  } else if (displayEndAt) {
    const endDate = new Date(displayEndAt)
    return <>{formatYYYYMMDD(endDate)}</>
  } else {
    return <></>
  }
}

function BulletinBoardList(props) {
  const history = useHistory()
  const classes = useStyles()
  const isTextSelected = useIsTextSelected()

  const { building, bulletinBoardList, paging } = props

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(`/bulletin-board/edit/${id}`, e, history)
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bulletinBoardList.length
            ? bulletinBoardList.map(row => {
                return (
                  <TableRow
                    onClick={e =>
                      navigateToDetailPage(e, row.bulletin_board.id)
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.bulletin_board.id}
                  >
                    <TableCell>
                      <CellWithImportant
                        isImportant={row.bulletin_board.is_important}
                        status={row.bulletin_board.bulletin_board_type_id}
                      />
                    </TableCell>
                    <TableCell>
                      {' '}
                      <CellWithStatus
                        status={row.bulletin_board.status}
                        displayStartAt={row.bulletin_board.display_start_at}
                        displayEndAt={row.bulletin_board.display_end_at}
                      />
                    </TableCell>
                    <TableCell>{row.bulletin_board.title}</TableCell>
                    <TableCell>
                      <CellWithType
                        typeId={row.bulletin_board.bulletin_board_type_id}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithDisplayDate
                        displayStartAt={row.bulletin_board.display_start_at}
                        displayEndAt={row.bulletin_board.display_end_at}
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
        {paging.count > 0 && (
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={5}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  onClick={() =>
                    props.history.push({
                      pathname: `/bulletin-board/list/1`,
                      state: {
                        'bulletinBoard.building_id': JSON.stringify([
                          {
                            id:
                              'building' in building && building.building
                                ? building.building.id
                                : 0,
                            TTY_NAME:
                              'mst_tateya' in building && building.mst_tateya
                                ? building.mst_tateya.TTY_NAME
                                : ''
                          }
                        ])
                      }
                    })
                  }
                  className={classes.gridLink}
                >
                  <Grid item>
                    <ArrowForwardIcon style={{ color: '#696CFF' }} />
                  </Grid>
                  <Grid
                    item
                    style={{
                      fontFamily: 'Hiragino Sans',
                      fontWeight: 600,
                      color: '#696CFF',
                      fontSize: '14px',
                      lineHeight: '21px'
                    }}
                  >
                    すべて見る
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  )
}

export default withSnackbar(withRouter(BulletinBoardList))
