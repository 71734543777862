import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { connect } from 'react-redux'
import { openModalDialog } from 'actions/Actions'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    height: '68px',
    alignItems: 'center'
  },
  left: {
    flex: 1,
    display: 'flex',
    marginLeft: '24px'
  },
  body: {},
  right: {
    marginLeft: '16px',
    marginRight: '32px'
  },
  primaryButton: {
    color: theme.palette.c_blue.contrastText,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  tertiaryButton: {
    color: theme.palette.c_gray.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

function Footer(props) {
  const classes = useStyles()
  const { role1, role4 } = useRole()
  const isUnrestrictedRole1 = role1()
  const isUnrestrictedRole4 = role4()

  const { companyUser } = props

  let selectedUserId = companyUser.company_user && companyUser.company_user.id
  const item = sessionStorage.getItem('totono-advertiser-session')
    ? sessionStorage.getItem('totono-advertiser-session')
    : localStorage.getItem('totono-advertiser-session')
  const data = JSON.parse(item)
  const currentUserId = data.user_id

  const handleClick = async () => {
    props.openModalDialog()
  }

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {'company_user' in companyUser && (
          <Button
            className={classes.tertiaryButton}
            startIcon={<DeleteIcon />}
            onClick={handleClick}
            disabled={
              (companyUser &&
                companyUser.company_user &&
                companyUser.company_user.is_administrator) ||
              !isUnrestrictedRole1
            }
          >
            削除
          </Button>
        )}
      </div>
      <div className={classes.body}>
        {'company_user' in companyUser && (
          <Button
            variant="contained"
            className={classes.secondaryButton}
            onClick={props.handleCancel}
            disabled={
              selectedUserId !== currentUserId &&
              !(
                (isUnrestrictedRole4 &&
                  companyUser.company_group !== null &&
                  companyUser.company_group !== undefined &&
                  companyUser.company_group.role_id === 6) ||
                isUnrestrictedRole1
              )
            }
          >
            キャンセル
          </Button>
        )}
      </div>
      <div className={classes.right}>
        <Button
          variant="contained"
          type="submit"
          className={classes.primaryButton}
          disabled={
            selectedUserId !== currentUserId &&
            !(
              (isUnrestrictedRole4 &&
                companyUser.company_group !== null &&
                companyUser.company_group !== undefined &&
                companyUser.company_group.role_id === 6) ||
              isUnrestrictedRole1
            )
          }
        >
          保存
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    openModalDialog: () => {
      dispatch(openModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
