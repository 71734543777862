import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import UseOwnerLabel from 'components/common/UseOwnerLabel'
import { handleOpenPage } from 'actions/Actions'
import { formatYYYYMMDD } from 'utils/date.utils'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const useStyles = makeStyles(() => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

const columns = [
  { id: 'status', label: '状態', minWidth: 80, hasSort: false },
  { id: 'id', label: 'オーナーID', minWidth: 100, hasSort: true },
  { id: 'name', label: 'オーナー名', minWidth: 100, hasSort: false },
  { id: 'name_kana', label: 'フリガナ', minWidth: 100, hasSort: false },
  {
    id: 'login_id',
    label: 'ログインID(メールアドレス)',
    minWidth: 100,
    hasSort: false
  },
  {
    id: 'registration_buildings',
    label: '物件名',
    minWidth: 100,
    hasSort: false
  },
  {
    id: 'created_at',
    label: '作成日',
    minWidth: 100,
    hasSort: true
  },
  {
    id: 'last_login_at',
    label: '最終ログイン日',
    minWidth: 100,
    hasSort: true
  }
]

const List = props => {
  const { ownerList } = props
  const classes = useStyles()
  const history = useHistory()
  const isTextSelected = useIsTextSelected()
  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  const navigateToDetailPage = (e, ownerId, buildingId) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/owner/detail/${ownerId}/building/${buildingId}`,
      e,
      history
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          {columns.map(column => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                width: column.width
              }}
              sortDirection={
                column.hasSort && orderBy === column.id ? order : false
              }
            >
              {column.hasSort ? (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  className={classes.tableSort}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{column.label}</>
              )}
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {ownerList.length
            ? ownerList.map(row => {
                return (
                  <TableRow
                    style={{ height: 80, maxHeight: 80 }}
                    onClick={e =>
                      navigateToDetailPage(
                        e,
                        row.owner.id,
                        row.owner_building_and_building_and_tty.building.id
                      )
                    }
                    hover
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell>
                      <UseOwnerLabel
                        is_usage={
                          row.owner_building_and_building_and_tty.owner_building
                            .is_usage
                        }
                      />
                    </TableCell>
                    <TableCell>{row.owner.id}</TableCell>
                    <TableCell>{row.owner.name}</TableCell>
                    <TableCell>{row.owner.name_kana}</TableCell>
                    <TableCell>{row.app_user.username}</TableCell>
                    <TableCell>
                      {
                        row.owner_building_and_building_and_tty.mst_tateya
                          .TTY_NAME
                      }
                    </TableCell>
                    <TableCell>
                      {row.owner.created_at &&
                        formatYYYYMMDD(new Date(row.owner.created_at))}
                    </TableCell>
                    <TableCell>
                      {row.app_user.last_login_at &&
                        formatYYYYMMDD(new Date(row.app_user.last_login_at))}
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
