import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import { QueryHelper } from 'utils/api.utils'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Loading from 'components/common/Loading'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import IconButton from '@material-ui/core/IconButton'
import Label from 'components/common/Label'
import { formatYYYYMMDD, formatTime } from 'utils/date.utils'
import IconWithText from 'components/common/IconWithText'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import RateReviewIcon from '@material-ui/icons/RateReview'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import HelpIcon from '@material-ui/icons/Help'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import { ThreadState } from 'utils/enum'
import { handleOpenPageNewTab } from 'actions/Actions'
import TablePagination from '@material-ui/core/TablePagination'
import { useParams, useLocation } from 'react-router-dom'
import { PAGE_LIMIT } from 'utils/const'
import { useLoginInformation } from 'hooks/useLoginInformation'

const EXTERNAL_USER = 5

const useStyles = makeStyles(() => ({
  right: {
    justifyContent: 'flex-end',
    textAlign: 'end'
  },
  section: {
    width: '650px',
    padding: '15px 0 0 20px'
  },
  section2: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  text: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  },
  interval: {
    paddingRight: '10px'
  },
  horizontal: {
    display: 'flex',
    alignItems: 'center'
  },
  historyCategory: {
    padding: '10px 0 10px 0'
  },
  statusIcon: {
    marginLeft: 'auto'
  },
  updateInformation: {
    paddingTop: '5px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '18px',
    color: '#72809d'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

const getTitle = data => {
  let title = ''
  switch (data.type) {
    case 1:
    case 3:
      title = `${data.large_category.name}・${data.medium_category.name}`
      break
    case 2:
      title = data.application_type.name
      break
  }
  return title
}

const CustomerHistoryThreadBody = props => {
  const classes = useStyles()
  const customerHistoryThreadItem = props
  const statusLabel = status => {
    switch (status) {
      case 1:
        return <Label color="blue" text="チャット" />
      case 2:
        return <Label color="blue" text="申請" />
      default:
        return <Label color="blue" text="その他" />
    }
  }

  const cellWithStatus = status => {
    switch (status) {
      case ThreadState.open.code:
        return (
          <IconWithText
            text={ThreadState.open.value}
            icon={<NewReleasesIcon />}
            color={'c_red'}
          />
        )
      case ThreadState.inProgress.code:
        return (
          <IconWithText
            text={ThreadState.inProgress.value}
            icon={<RateReviewIcon />}
            color={'c_yellow'}
          />
        )
      case ThreadState.pending.code:
      case ThreadState.resolve.code:
        return (
          <IconWithText
            text={ThreadState.pending.value}
            icon={<BeenhereIcon />}
            color={'c_blue2'}
          />
        )
      case ThreadState.complete.code:
        return (
          <IconWithText
            text={ThreadState.complete.value}
            icon={<EmojiEmotionsIcon />}
            color={'c_thirdly'}
          />
        )
      default:
        return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
    }
  }

  return (
    <Grid className={classes.section}>
      <Grid item xs={12} container spacing={2} className={classes.row}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" className={classes.text}>
            <Grid className={classes.horizontal}>
              <Grid className={classes.interval}>
                {statusLabel(
                  customerHistoryThreadItem.customerHistoryThreadItem
                    .customer_history_thread.type
                )}
              </Grid>
              <Grid className={classes.interval}>
                初回 :{' '}
                {formatYYYYMMDD(
                  new Date(
                    customerHistoryThreadItem.customerHistoryThreadItem.customer_history_thread.created_at
                  )
                )}
              </Grid>
              <Grid>
                {formatTime(
                  new Date(
                    customerHistoryThreadItem.customerHistoryThreadItem.customer_history_thread.created_at
                  )
                )}
              </Grid>
            </Grid>
            <Grid className={classes.horizontal}>
              <Grid className={classes.historyCategory}>
                {getTitle(
                  customerHistoryThreadItem.customerHistoryThreadItem
                    .customer_history_thread
                )}
              </Grid>
              <Grid className={classes.statusIcon}>
                {customerHistoryThreadItem.customerHistoryThreadItem
                  .customer_history_thread
                  ? cellWithStatus(
                      customerHistoryThreadItem.customerHistoryThreadItem
                        .customer_history_thread.status
                    )
                  : null}
              </Grid>
            </Grid>
            <Grid
              className={`${classes.horizontal} ${classes.right} ${classes.updateInformation}`}
            >
              {customerHistoryThreadItem.customerHistoryThreadItem
                .customer_history_thread ? (
                <>
                  <Grid className={classes.interval}>
                    更新 :{' '}
                    {formatYYYYMMDD(
                      new Date(
                        customerHistoryThreadItem.customerHistoryThreadItem.customer_history_thread.updated_at
                      )
                    )}
                  </Grid>
                  <Grid className={classes.interval}>
                    {formatTime(
                      new Date(
                        customerHistoryThreadItem.customerHistoryThreadItem.customer_history_thread.updated_at
                      )
                    )}
                  </Grid>
                  <Grid className={classes.interval}>
                    {customerHistoryThreadItem.customerHistoryThreadItem
                      .customer_history_thread.updated_user &&
                      customerHistoryThreadItem.customerHistoryThreadItem
                        .customer_history_thread.updated_user.name}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  )
}

const CustomerHistory = props => {
  const classes = useStyles()
  const { pageId } = useParams()
  const location = useLocation()
  const application = props.application ? props.application : null
  const contract = props.contract ? props.contract : null
  const [customerHistoryThreads, setCustomerHistoryThreads] = useState(
    initialState
  )
  const [isLoading, setIsLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : {}
  )
  const { judgmentRoleId } = useLoginInformation()

  const handleChangePage = (event, newPage) => {
    var params = Object.assign({}, searchCondition, { pageNumber: newPage + 1 })
    setSearchCondition(params, setPageNumber(newPage + 1))
  }

  async function fetchCustomerHistoryThreadList(offset, limit) {
    if (!application || !application.customer_id) {
      return
    }
    const params = {
      customer_id: {
        eq: application.customer_id
      }
    }
    const result = await QueryHelper(
      'getCustomerHistoryThreadList',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      false
    )
    if (!result.error) {
      setCustomerHistoryThreads(result)
    } else {
      console.log(result.error)
      props.enqueueSnackbar(`CustomerHistoryThreadを取得できませんでした。`)
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchCustomerHistoryThreadList(offset, PAGE_LIMIT),
      props.fetchCustomer()
    ])
    setIsLoading(false)
  }

  const transitionCustomerHistory = () => {
    localStorage.setItem('display', 'customerHistory')
    handleOpenPageNewTab(`/management/contract/detail/${contract.id}`)
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    fetchData(offset)
  }, [pageNumber, application])

  return (
    <>
      <Grid className={classes.section2}>
        <Grid container spacing={4}>
          <Grid item xs={10} alignItems="center" spacing={2}>
            <Typography className={classes.title}>{'活動履歴'}</Typography>
          </Grid>
          {application &&
            application.customer_id &&
            judgmentRoleId() !== EXTERNAL_USER && (
              <IconButton
                aria-label="new"
                onClick={() => transitionCustomerHistory()}
              >
                <OpenInNewIcon />
              </IconButton>
            )}
        </Grid>
      </Grid>
      <Divider />
      {!isLoading &&
      customerHistoryThreads.data &&
      customerHistoryThreads.data.length > 0 ? (
        customerHistoryThreads.data.map((row, index) => (
          <>
            <CustomerHistoryThreadBody
              customerHistoryThreadItem={row}
              key={index}
            />
          </>
        ))
      ) : (
        <Loading isLoading={isLoading} />
      )}
      <Grid items align="right">
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={customerHistoryThreads.paging.count}
          rowsPerPage={PAGE_LIMIT}
          page={pageNumber - 1}
          onChangePage={handleChangePage}
        />
      </Grid>
    </>
  )
}

export default withSnackbar(CustomerHistory)
