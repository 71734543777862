import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { QueryHelper } from 'utils/api.utils'

export const useDisplayUserInformationHeader = (
  api,
  type,
  enqueueSnackbar,
  title,
  isOwnerPath
) => {
  const location = useLocation()
  const url = location.pathname
  const originalId = url.split('/').pop()

  const [buildingName, setBuildingName] = useState()
  const [roomNumber, setRoomNumber] = useState()
  const [customerName, setCustomerName] = useState()
  const [formatResidentInfo, setFormatResidentInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchCustomerIdAndBuildingIdAndRoomId = async id => {
    const params = {
      id: { eq: id }
    }

    const result = await QueryHelper(api, {
      filter: params,
      is_owner: isOwnerPath
    })
    if (!result.error) {
      if (type === 'thread') {
        isOwnerPath
          ? fetchOwner(result.thread.customer_id, result.thread.building_id)
          : fetchCustomer(result.thread.customer_id)
        fetchBuilding(result.thread.building_id)
        // ownerの場合はroom_idがないため
        !isOwnerPath && fetchRoom(result.thread.room_id)
      } else {
        fetchCustomer(result.application.customer_id)
        fetchBuilding(result.application.building_id)
        fetchRoom(result.application.room_id)
      }
    } else {
      setError(true)
    }
  }

  const fetchCustomer = async customerId => {
    const params = {
      id: {
        eq: customerId
      }
    }
    const result = await QueryHelper('getCustomerList', {
      filter: params
    })
    if (result.error) {
      setError(true)
    } else {
      setCustomerName(result.data[0].mstkokyaku.KKY_NAME)
    }
  }

  const fetchOwner = async ownerId => {
    const params = {
      id: {
        eq: ownerId
      }
    }
    const result = await QueryHelper('getOwner', {
      filter: params
    })
    if (result.error) {
      setError(true)
    } else {
      setCustomerName(result.owner.name)
    }
  }

  const fetchBuilding = async buildingId => {
    const params = {
      id: {
        eq: buildingId
      }
    }
    const result = await QueryHelper('getBuilding', {
      filter: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      setError(true)
    } else {
      setBuildingName(result.mst_tateya.TTY_NAME)
    }
  }

  const fetchRoom = async roomId => {
    const params = {
      id: {
        eq: roomId
      }
    }
    const result = await QueryHelper('getRoom', {
      filter: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      setError(true)
    } else {
      setRoomNumber(result.mst_heya.HYA_ROOM_NO)
    }
  }

  const outputApplicationUserInformation = async () => {
    setIsLoading(true)
    await fetchCustomerIdAndBuildingIdAndRoomId(originalId)
    setIsLoading(false)
  }

  useEffect(() => {
    if (error) {
      setFormatResidentInfo(title)
      setError(false)
      enqueueSnackbar('タイトルに表示する情報を取得できませんでした', {
        variant: 'error'
      })
      return
    }
    // オーナーの場合は部屋の名前は不要のため
    if (
      !originalId ||
      !buildingName ||
      (!isOwnerPath && !roomNumber) ||
      !customerName
    )
      return
    let formatAppId = `ID: ${originalId}`
    const residentInfoArray = [
      formatAppId,
      buildingName,
      roomNumber,
      customerName
    ]
    // 半角スペースだと画面に反映されないため
    setFormatResidentInfo(residentInfoArray.join('　'))
  }, [originalId, buildingName, roomNumber, customerName, error])

  return {
    formatResidentInfo,
    outputApplicationUserInformation,
    isLoading
  }
}
