import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Auth, Storage } from 'aws-amplify'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import ThumbnailMovie from 'components/common/ThumbnailMovie'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(n+2)': {
      marginTop: 10
    }
  },
  imageRow: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 'auto',
    marginLeft: 20,
    fontWeight: 'bold',
    fontSize: '16px'
  }
}))

function ChatMovie(props) {
  const classes = useStyles()
  const attachment = props.attachment ? props.attachment : null
  const [movie, setMovie] = useState(null)
  const [thumbnailImage, setThumbnailImage] = useState(null)

  useEffect(() => {
    if (attachment) {
      Auth.currentCredentials().then(async creds => {
        await Promise.all([
          fetchMovie(attachment),
          fetchThumbnailImage(attachment)
        ])
      })
    }
  }, [attachment, setMovie, setThumbnailImage])

  async function fetchMovie(attachment) {
    const key = attachment.key
    console.log(key)
    const result = await getStorage(key)
    console.log(result)
    const object = Object.assign({}, attachment, { body: result })
    setMovie(object)
  }

  async function getStorage(key) {
    return Storage.get(key, {
      level: 'protected',
      identityId: attachment.company_id,
      expires: 60
    })
  }

  async function fetchThumbnailImage(attachment) {
    const key = attachment.thumbnail_key
    console.log(key)
    const result = await getStorage(key)
    console.log(result)
    const object = Object.assign({}, attachment, { body: result })
    setThumbnailImage(object)
  }

  return (
    <>
      {movie && thumbnailImage && (
        <>
          <Grid container className={classes.imageContainer}>
            <Grid xs={11} item>
              <Grid container className={classes.imageRow}>
                <Grid item>
                  <ThumbnailMovie
                    attachment={attachment}
                    movie={movie.body}
                    thumbnailImage={thumbnailImage.body}
                    title={''}
                  />
                </Grid>
                <Grid item xs>
                  <span className={classes.title} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default withSnackbar(withRouter(ChatMovie))
