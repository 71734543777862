import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListContent from './ListContent'

const useStyles = makeStyles(theme => ({
  wrapBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  wrapContent: {
    flex: 1,
    display: 'flex',
    margin: '32px'
  }
}))

function ListScreen(props) {
  const classes = useStyles()

  return (
    <div className={classes.wrapBody}>
      <div className={classes.wrapContent}>
        <ListContent />
      </div>
    </div>
  )
}

export default ListScreen
