import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from './List'
import Button from '@material-ui/core/Button'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import Grid from '@material-ui/core/Grid'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { BulkDelivery } from 'utils/enum'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import CommonChip from 'components/common/CommonChip'
import { fromCode } from 'utils/enum.utils'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { Auth, Storage } from 'aws-amplify'
import {
  setTargetIdList,
  setCsvInformation,
  setMatchCsvValue,
  setCsvHeader,
  setFilterCsvInformation
} from 'actions/Actions'
import RadioButtons from 'components/common/RadioButtons'

const useStyles = makeStyles(theme => ({
  paperWrapper: {
    paddingBottom: '30px'
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  wrapper: {
    padding: '50px 20px 0px 10px'
  },
  selectContentWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  selectNumberWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '50px'
  },
  CsvButtonWrapper: {
    paddingRight: '30px'
  },
  ReturnButtonWrapper: {
    paddingRight: '30px'
  },
  button: {
    color: theme.palette.c_white.main
  },
  usageSituationButtonTypeBlue: {
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  usageSituationButtonTypeBarkBlue: {
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  usageSituationButtonTypeGray: {
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  },
  checkAllButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    },
    marginTop: '20px'
  }
}))
const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}
const buttonData = [
  { value: BulkDelivery.all.code, label: BulkDelivery.all.value },
  { value: BulkDelivery.appUser.code, label: BulkDelivery.appUser.value },
  { value: BulkDelivery.unused.code, label: BulkDelivery.unused.value },
  { value: BulkDelivery.disabled.code, label: BulkDelivery.disabled.value }
]
function ListContent(props) {
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()
  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'selectedStatus' in location.state
      ? location.state.selectedStatus
      : BulkDelivery.all.code
  )
  // TODO: 一括送信データ取り込み画面からReduxで渡された契約リストIDを初期値に入れる
  const [searchCondition, setSearchCondition] = useState({})
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )
  const [customerInformationList, setCustomerInformationList] = useState(
    initialState
  )
  const [isLoading, setIsLoading] = useState(false)
  const [totalKykIdList, setTotalKykIdList] = useState([])
  const [targetIdList, setTargetIdList] = useState([])
  const [totalTargetIdList, setTotalTargetIdList] = useState([])
  const [invalidTargetIdList, setInvalidTargetIdList] = useState([])
  const [removedCheckIdList, setRemovedCheckIdList] = useState([])
  const handleCustomChangePage = newPage => {
    setPageNumber(newPage)
  }
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1)
  }

  async function fetchContractList({ offset, limit }) {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'bulk_delivery.kyk_id_list') {
          filter['kyk_id_list'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'selectedStatus') {
          if (searchCondition[key] == 1) {
            filter['is_use_app'] = { eq: true }
          }
          if (searchCondition[key] == 2) {
            filter['is_usage'] = { eq: true }
            filter['is_management'] = { eq: true }
            filter['app_user_id'] = { isNull: 0 }
          }
          if (searchCondition[key] == 3) {
            filter['is_use_app'] = { eq: false }
          }
        }
      }
    })
    if (orderBy && Object.keys(orderBy).length > 0) {
      filter.orderBy = [orderBy]
      filter['kyk_status_div'] = { eq: [1, 2, 3, 4, 5, 99] }
    }
    filter['kyk_status_div'] = { eq: [1, 2, 3, 4, 5, 99] }

    // データ取り込み画面からreduxで保存された契約IDリストをセットする 保存されていなかった場合は全件表示
    if (props.matchContractIdList != undefined) {
      filter['kyk_id_list'] = {
        eq: props.matchContractIdList.matchContractIdList
      }
    }

    let result = await QueryHelper(
      'getContractList2',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('顧客一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCustomerInformationList(result)
      filterKykIdList(result)
    }
  }

  const fetchContractListId = async ({
    searchCondition,
    newSearchCondition,
    offset,
    limit
  }) => {
    let filter = {}

    if (newSearchCondition) {
      if (Object.keys(newSearchCondition).length != 0) {
        Object.keys(searchCondition).forEach(function(key) {
          if (searchCondition[key]) {
            if (key == 'bulk_delivery.kyk_id_list') {
              filter['kyk_id_list'] = {
                eq: searchCondition[key]
              }
            }
            if (key == 'selectedStatus') {
              if (searchCondition[key] == 1) {
                filter['is_use_app'] = { eq: true }
              }
              if (searchCondition[key] == 2) {
                filter['is_usage'] = { eq: true }
                filter['is_management'] = { eq: true }
                filter['app_user_id'] = { isNull: 0 }
              }
              if (searchCondition[key] == 3) {
                filter['is_use_app'] = { eq: false }
              }
            }
          }
        })
      }
    }

    filter['kyk_status_div'] = { eq: [1, 2, 3] }
    filter['is_usage'] = {
      eq: true
    }
    filter['is_management'] = {
      eq: true
    }
    // データ取り込み画面からreduxで保存された契約IDリストをセットする 保存されていなかった場合は全件表示
    if (props.matchContractIdList != undefined) {
      filter['kyk_id_list'] = {
        eq: props.matchContractIdList.matchContractIdList
      }
    }
    let result = await QueryHelper(
      'getContractIdList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )

    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('入居者ID一覧を取得できませんでした', {
        variant: 'error'
      })
    } else {
      let customerIdList = []
      result.id.map(row => customerIdList.push(row))
      setTargetIdList(customerIdList)
      // 一括チェックをした状態で中身が変更されたか確認するため
      setTotalTargetIdList(customerIdList)
    }
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchCondition, {
      selectedStatus: selectedStatus
    })
    if (selectedStatus) {
      searchCondition['bulikDelivery.thread_state'] = selectedStatus
    } else {
      delete searchCondition['selectedStatus']
      delete params['selectedStatus']
    }
    params['pageNumber'] = 1
    setSelectedStatus(selectedStatus, setPageNumber(1))
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchCondition['orderBy'] = sortParam
    searchCondition['pageNumber'] = 1
    setPageNumber(1)
  }

  const handleDeleteSearchConditions = target => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'selectedStatus': {
        if (searchCondition[target] != 0) {
          setSelectedStatus(BulkDelivery.all.code)
        }
        delete params[target]
        break
      }
      default: {
        break
      }
    }
    params['pageNumber'] = 1
    setSearchCondition(params, setPageNumber(1))
  }

  function isContainsMessageBySearchConditions() {
    const val = Object.keys(searchCondition).find(x =>
      x.match(/^bulikDelivery.+/)
    )
    if (val === undefined) {
      return Object.keys(searchCondition).indexOf('selectedStatus') !== -1
    }
    return true
  }

  const fetchData = async offset => {
    setIsLoading(true)
    let newSearchCondition = Object.assign({}, searchCondition)
    delete newSearchCondition['pageNumber']
    await Promise.all([
      fetchContractList({
        offset,
        limit: PAGE_LIMIT
      }),
      fetchContractListId({
        searchCondition: searchCondition,
        newSearchCondition: newSearchCondition,
        offset: 0,
        limit: 0
      })
    ])
    setIsLoading(false)
  }

  const csvDownLoad = async () => {
    let params = {}
    params['kyk_id_list'] = totalKykIdList
    params['target_id_list'] = targetIdList
    const csvData = await getCsv(0, totalKykIdList.length, params)
    if (csvData.error) {
      props.enqueueSnackbar('CSVのDLに失敗しました', {
        variant: 'error'
      })
      return
    }
    await downloadFile(csvData)
  }

  const filterKykIdList = result => {
    var filterList = []
    switch (searchCondition['selectedStatus']) {
      case undefined:
        filterList = result.data.filter(
          row =>
            row.customer.is_usage == true &&
            row.building.is_management == true &&
            row.contract.kyk_status_div != 4 &&
            row.contract.kyk_status_div != 5 &&
            row.contract.kyk_status_div != 99
        )
        break
      case 1:
        filterList = result.data.filter(
          row =>
            row.customer.is_usage == true &&
            row.contract.kyk_status_div != 4 &&
            row.contract.kyk_status_div != 5 &&
            row.contract.kyk_status_div != 99
        )
        break
      case 2:
        filterList = result.data.filter(
          row =>
            row.customer.is_usage == true &&
            row.building.is_management == true &&
            row.appUser == null &&
            row.contract.kyk_status_div != 4 &&
            row.contract.kyk_status_div != 5 &&
            row.contract.kyk_status_div != 99
        )
        break
      case 3:
        filterList = result.data.filter(
          row =>
            row.customer.is_usage == true &&
            row.building.is_management == true &&
            row.contract.kyk_status_div != 4 &&
            row.contract.kyk_status_div != 5 &&
            row.contract.kyk_status_div != 99
        )
        break
      default:
        props.enqueueSnackbar('想定外のエラーが発生しました', {
          variant: 'error'
        })
        break
    }
    let customerIdList = []
    filterList.map(row => customerIdList.push(row.knr_keiyaku.KYK_ID))
    setInvalidTargetIdList(customerIdList)
    if (removedCheckIdList.length != 0) {
      const filterCustomerIdList = targetIdList.filter(
        i => removedCheckIdList.indexOf(i) == -1
      )
      setTargetIdList(filterCustomerIdList)
    }
  }

  const changeTargetIdList = id => {
    if (targetIdList.indexOf(id) != -1) {
      setTargetIdList(targetIdList.filter(targetId => targetId != id))
    } else {
      setTargetIdList([...targetIdList, id])
    }
    // チャックを外したidを管理する
    if (removedCheckIdList.indexOf(id) != -1) {
      setRemovedCheckIdList(
        removedCheckIdList.filter(targetId => targetId != id)
      )
    } else {
      let a = [...removedCheckIdList, id]
      setRemovedCheckIdList(a)
    }
  }

  const checkAll = async () => {
    if (targetIdList.length === totalTargetIdList.length) {
      setTargetIdList([])
      // チャックを外したidを管理する
      setRemovedCheckIdList(targetIdList)
    } else {
      let newSearchCondition = Object.assign({}, searchCondition)
      delete newSearchCondition['pageNumber']
      // 検索条件があった場合
      if (Object.keys(newSearchCondition).length != 0) {
        setIsLoading(true)
        await fetchContractListId({
          searchCondition: searchCondition,
          newSearchCondition: newSearchCondition,
          offset: 0,
          limit: 0
        })
        setIsLoading(false)
        return
      } else {
        setTargetIdList(totalTargetIdList)
        setRemovedCheckIdList([])
      }
    }
  }

  async function getCsv(offset, limit, params) {
    let result = await QueryHelper(
      'getBulkDeliveryListCsv',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('顧客一覧情報を取得できませんでした', {
        variant: 'error'
      })
    }
    return result
  }

  async function downloadFile(csvData) {
    await Auth.currentCredentials().then(async () => {
      const key = csvData.key
      Storage.get(key, {
        level: 'protected',
        identityId: csvData.companyId,
        expires: 60
      })
        .then(result => {
          const link = document.createElement('a')
          link.href = result
          link.click()
        })
        .catch(err => {
          console.log('error: ', err)
        })
    })
  }

  const dispatchSendCsvInformation = () => {
    if (props.list != null) {
      let filterCsvInformation = ''
      let array = {}
      // 一列ずつ配列にする
      let importCsvInformationArrangement = props.list[
        'importCsvInformation'
      ].split('\n')
      filterCsvInformation += importCsvInformationArrangement[0] + '\n'
      for (let i = 1; i < importCsvInformationArrangement.length; i++) {
        // 抽出したキーを元に連想配列にする
        array[props.matchContractIdList.matchContractIdList[i - 1]] =
          importCsvInformationArrangement[i]
      }
      targetIdList.map(targetId => {
        if (targetId in array) {
          if (targetIdList[targetIdList.length - 1] == targetId) {
            filterCsvInformation += array[targetId]
          } else {
            filterCsvInformation += array[targetId] + '\n'
          }
        }
      })
      return filterCsvInformation
    }
  }

  const goToEditBulkDelivery = () => {
    if (targetIdList.length == 0) {
      props.enqueueSnackbar('配信先が設定されていません', {
        variant: 'warning'
      })
      return
    }
    props.setTargetIdList({
      targetIdList: targetIdList
    })
    const filterCsvInformation = dispatchSendCsvInformation()
    props.setFilterCsvInformation({
      filterCsvInformation: filterCsvInformation
    })
    history.push('/message/bulk-delivery/edit')
  }

  const goToImportBulkDelivery = () => {
    const result = window.confirm(
      `送信先の設定を中止にしますがよろしいですか？`
    )
    if (!result) return
    // 配信内容設定画面から戻るボタンを押して戻ってきた場合に対応
    props.setCsvInformation({
      filterCsvInformation: null
    })
    props.setMatchCsvValue({
      matchCsvValue: null
    })
    props.setTargetIdList({
      targetIdList: null
    })
    props.setCsvHeader({
      csvHeader: []
    })
    history.push('/message/bulk-delivery/import')
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `/message/bulk-delivery/select/${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [
    pageNumber,
    setCustomerInformationList,
    searchCondition,
    orderBy,
    setSelectedStatus
  ])

  useEffect(() => {
    if (props.matchContractIdList != undefined) {
      setTotalKykIdList(props.matchContractIdList.matchContractIdList)
    }
  }, [])

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      {searchCondition &&
        Object.keys(searchCondition).length > 0 &&
        isContainsMessageBySearchConditions() && (
          <Grid className={classes.paperWrapper}>
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'selectedStatus': {
                    if (
                      searchCondition[key] == 1 ||
                      searchCondition[key] == 2 ||
                      searchCondition[key] == 3
                    ) {
                      label =
                        'アプリ利用: ' +
                        fromCode(BulkDelivery, Number(searchCondition[key]))
                          .value
                    } else label = '状態: 想定外の値'
                    break
                  }
                  default: {
                    if (
                      searchCondition[key] != searchCondition['pageNumber'] &&
                      searchCondition[key] != searchCondition['orderBy']
                    ) {
                      label = '状態: 想定外の値'
                    }
                    break
                  }
                }
                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={() => handleDeleteSearchConditions(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          </Grid>
        )}
      <Grid className={classes.table}>
        <Grid container spacing={3}>
          <Grid item xs={6} className={classes.tableTips}>
            <RadioButtons
              current={selectedStatus}
              handleSelectedButton={handleSelectedButton}
              buttonsData={buttonData}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.wrapper}>
        <Grid className={classes.selectContentWrapper}>
          <Grid className={classes.selectNumberWrapper}>
            <Grid className={classes.selectNumber}>
              選択件数 : {targetIdList.length}件
            </Grid>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={customerInformationList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
        {!isLoading && customerInformationList ? (
          <Paper className={classes.root} style={{ marginTop: 20 }}>
            <div className={classes.tableWrapper}>
              <List
                handleChangeSort={handleChangeSort}
                changeTargetIdList={changeTargetIdList}
                orderBy={orderBy}
                customerInformationList={customerInformationList.data}
                targetIdList={targetIdList}
                invalidTargetIdList={invalidTargetIdList}
              />
            </div>
          </Paper>
        ) : (
          <Loading isLoading={isLoading} marginTop={20} />
        )}
        <Grid container>
          <Grid item xs={3}>
            <Button
              variant="contained"
              className={classes.checkAllButton}
              onClick={() => checkAll()}
            >
              一括チェック
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Pagination
              handlePageNumber={handleCustomChangePage}
              pageNumber={pageNumber}
              count={customerInformationList.paging.count}
              rowsPerPage={PAGE_LIMIT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.buttonWrapper}>
          <Grid className={classes.CsvButtonWrapper}>
            <Button
              variant="contained"
              className={`${classes.usageSituationButtonTypeBarkBlue} ${classes.button}`}
              onClick={csvDownLoad}
            >
              CSV出力
            </Button>
          </Grid>
          <Grid className={classes.ReturnButtonWrapper}>
            <Button
              variant="contained"
              onClick={() => goToImportBulkDelivery()}
              className={`${classes.usageSituationButtonTypeGray} ${classes.button}`}
            >
              戻る
            </Button>
          </Grid>
          <Grid className={classes.NextButtonWrapper}>
            <Button
              variant="contained"
              onClick={() => goToEditBulkDelivery()}
              className={`${classes.usageSituationButtonTypeBlue} ${classes.button}`}
            >
              次へ（内容設定）
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    matchContractIdList: state.matchContractIdList.list,
    list: state.csvInformation.list,
    value: state.matchCsvValue.value,
    targetIdList: state.targetIdList.list
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTargetIdList: parameter => {
      dispatch(setTargetIdList(parameter))
    },
    setCsvInformation: parameter => {
      dispatch(setCsvInformation(parameter))
    },
    setMatchCsvValue: parameter => {
      dispatch(setMatchCsvValue(parameter))
    },
    setCsvHeader: parameter => {
      dispatch(setCsvHeader(parameter))
    },
    setFilterCsvInformation: parameter => {
      dispatch(setFilterCsvInformation(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
