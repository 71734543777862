import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const circleStyle = makeStyles(theme => {
  const setFontColor = active => (active ? '#ffffff' : '#000000')
  const setBgColor = active => (active ? '#696bff' : '#ffffff')
  const setSize = size => {
    if (size === 'large') return 77
    if (size === 'medium') return 40
    if (size === 'small') return 20
    return 40
  }
  const setHoverBgColor = disabled => (disabled ? 'inherited' : '#d2d3ff')
  const setHoverCursor = disabled => (disabled ? 'inherited' : 'pointer')
  return {
    circle: {
      alignItems: 'center',
      color: props => setFontColor(props.active),
      backgroundColor: props => setBgColor(props.active),
      fontWeight: 'bold',
      fontSize: props => `${setSize(props.size) / 2}px`,
      lineHeight: props => `${setSize(props.size)}px`,
      height: props => setSize(props.size),
      width: props => setSize(props.size),
      borderRadius: '50%',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      overflow: 'hidden',
      position: 'relative',
      '&:hover': {
        backgroundColor: props => setHoverBgColor(props.disabled),
        cursor: props => setHoverCursor(props.disabled)
      },
      '& img': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '-webkit-transform': 'translate(-50%, -50%)',
        '-ms-transform': ' translate(-50%, -50%)',
        width: '100%',
        height: 'auto%'
      }
    }
  }
})

export default function Circle({
  size,
  disabled = false,
  active = false,
  children,
  ...props
}) {
  let classes = circleStyle({ size, active, disabled })
  return (
    <div className={classes.circle} {...props}>
      {children}
    </div>
  )
}
