import React from 'react'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import FaqAttachmentIcon from './FaqAttachmentIcon'
import ListAltIcon from '@material-ui/icons/ListAlt'
import DraftsIcon from '@material-ui/icons/Drafts'
import HelpIcon from '@material-ui/icons/Help'
import IconWithText from 'components/common/IconWithText'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const columns = [
  { id: 'faq_status', label: '状態', width: 80 },
  { id: 'large_category_name', label: '大カテゴリ', minWidth: 100 },
  { id: 'medium_category_name', label: '中カテゴリ', minWidth: 170 },
  { id: 'question', label: '質問', minWidth: 170 },
  { id: 'attachments', label: '添付ファイル', minWidth: 100 },
  { id: 'view_count', label: '参照数', minWidth: 50 }
]

function CellWithStatus({ status }) {
  switch (status) {
    case 1:
      return (
        <IconWithText text="掲載中" icon={<ListAltIcon />} color={'info'} />
      )
    case 2:
      return (
        <IconWithText text="未掲載" icon={<DraftsIcon />} color={'secondary'} />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function List(props) {
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const { searchCondition } = props

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    history.push({
      pathname: `/management/faq/edit/${id}`,
      state: searchCondition
    })
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.faqList.length
            ? props.faqList.map(row => {
                return (
                  <TableRow
                    onClick={e => navigateToDetailPage(e, row.faq.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.faq.id}
                  >
                    <TableCell>
                      <CellWithStatus status={row.faq.status} />
                    </TableCell>
                    <TableCell>
                      {row.large_category ? row.large_category.name : ''}
                    </TableCell>
                    <TableCell>
                      {row.medium_category ? row.medium_category.name : ''}
                    </TableCell>
                    <TableCell>{row.faq.question}</TableCell>
                    <TableCell>
                      <FaqAttachmentIcon
                        id={row.faq.id}
                        attachments={row.attachment}
                      />
                    </TableCell>
                    <TableCell>{0}</TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default List
