import React from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Left from './Left'
import Right from './Right'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    margin: '32px'
  },
  right: {
    width: '300px',
    backgroundColor: '#fff'
  },
  rightBody: {
    flex: 1,
    height: '100vh',
    backgroundColor: '#fff'
  }
}))

function DetailScreen(props) {
  const classes = useStyles()

  const { id } = useParams()

  return (
    <>
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.leftBody}>
            <Left />
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.rightBody}>
            <Right />
          </div>
        </div>
      </div>
    </>
  )
}

export default withSnackbar(DetailScreen)
