import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Loading from 'components/common/Loading'
import { formatYYYYMMDD } from 'utils/date.utils'
import Link from '@material-ui/core/Link'
import { handleOpenPageNewTab } from 'actions/Actions'
import { ContractKykStatusDiv } from 'utils/enum'
import Label from 'components/common/Label'
import { useLoginInformation } from 'hooks/useLoginInformation'
import { useCustomerOrOwner } from '../../hooks/useCustomerOrOwner'

const EXTERNAL_USER = 5

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    width: '350px',
    padding: '15px 0px 15px 20px'
  },
  section2: {
    padding: '15px 20px'
  },
  sectionWidth: {
    width: '350px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  text: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  },
  link: {
    color: '#696CFF',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px'
  }
}))

const buildFullAddress = mst_tateya => {
  let fullAddress =
    (mst_tateya.TTY_PRF_NAME || '') +
    ' ' +
    (mst_tateya.TTY_CTY_NAME || '') +
    ' ' +
    (mst_tateya.TTY_TWN_NAME || '')

  if (mst_tateya.TTY_ADDR_01) {
    fullAddress = fullAddress + ' ' + mst_tateya.TTY_ADDR_01
  }
  if (mst_tateya.TTY_ADDR_02) {
    fullAddress = fullAddress + ' ' + mst_tateya.TTY_ADDR_02
  }

  return fullAddress
}

function BuildingInfo(props) {
  const classes = useStyles()
  const application = props && props.application ? props.application : null
  const [building, setBuilding] = useState({})
  const [room, setRoom] = useState({})
  const [knrKeiyaku, setKnrKeiyaku] = useState({})
  const [contract, setContract] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { judgmentRoleId } = useLoginInformation()
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  const fetchBuilding = async () => {
    if (!application || !application.building_id) return

    const params = {
      id: {
        eq: application.building_id
      }
    }
    const result = await QueryHelper('getBuilding', { filter: params })
    if (result.error) {
      props.enqueueSnackbar('建物情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuilding(result.mst_tateya)
    }
  }

  const fetchContract = async () => {
    if (!application) {
      return
    }

    let params = {}
    if (application.contract_id) {
      params.id = { eq: application.contract_id }
    } else if (application.customer_id && application.room_id) {
      params.tenant_id = { eq: application.customer_id }
      params.room_id = { eq: application.room_id }
    }

    const result = await QueryHelper('getContract', { filter: params })
    if (result.error) {
      props.enqueueSnackbar('物件情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuilding(result.mst_tateya)
      setRoom(result.mst_heya)
      setKnrKeiyaku(result.knr_keiyaku)
      setContract(result.contract)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([isOwnerPath ? fetchBuilding() : fetchContract()])
    setIsLoading(false)
  }

  const KeiyakuStatusLabel = ({ kykStatusDiv }) => {
    if (kykStatusDiv == ContractKykStatusDiv.application.code) {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.application.value} />
      )
    } else if (kykStatusDiv == ContractKykStatusDiv.contract.code) {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
      )
    } else if (kykStatusDiv == ContractKykStatusDiv.cancellation.code) {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
      )
    } else if (kykStatusDiv == ContractKykStatusDiv.completed.code) {
      return (
        <Label color={'gray'} text={ContractKykStatusDiv.completed.value} />
      )
    } else if (kykStatusDiv == ContractKykStatusDiv.payOff.code) {
      return <Label color={'gray'} text={ContractKykStatusDiv.payOff.value} />
    } else {
      return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
    }
  }

  const StatusLabel = ({ status }) => {
    if (
      status == ContractKykStatusDiv.contract.code ||
      status == ContractKykStatusDiv.cancellation.code
    ) {
      return <Label color="blue" text="使用中" />
    }
    return <Label color="gray" text="空室" />
  }

  useEffect(() => {
    fetchData()
  }, [application, setBuilding, setRoom])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={9} alignItems="center" spacing={3}>
              <Typography className={classes.title}>{'物件情報'}</Typography>
            </Grid>
            {application &&
              application.building_id &&
              judgmentRoleId() !== EXTERNAL_USER && (
                <IconButton
                  aria-label="new"
                  onClick={() =>
                    handleOpenPageNewTab(
                      `/management/building/${application.building_id}`
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              )}
          </Grid>
        </div>
        <Divider />
        {!isLoading && application ? (
          <>
            <Grid className={`${classes.section2} ${classes.sectionWidth}`}>
              {!isOwnerPath && (
                <>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        部屋の状態
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} container>
                      <Typography variant="body2" className={classes.text}>
                        <StatusLabel status={contract.kyk_status_div} />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )}
              <Grid item xs={12} container spacing={2} className={classes.row}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" className={classes.text}>
                    物件名
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item xs={12}>
                    {judgmentRoleId() !== EXTERNAL_USER ? (
                      <Link
                        onClick={() =>
                          handleOpenPageNewTab(
                            `/management/building/${application.building_id}`
                          )
                        }
                        className={classes.link}
                      >
                        <Typography variant="body2" className={classes.text}>
                          {building && building.TTY_NAME && building.TTY_NAME}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography variant="body2" className={classes.text}>
                        {building && building.TTY_NAME && building.TTY_NAME}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              {!isOwnerPath && (
                <>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        部屋番号
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        {judgmentRoleId() !== EXTERNAL_USER ? (
                          <Link
                            onClick={() =>
                              handleOpenPageNewTab(
                                `/management/building/${application.building_id}/room/${application.room_id}`
                              )
                            }
                            className={classes.link}
                          >
                            <Typography
                              variant="body2"
                              className={classes.text}
                            >
                              {room && room.HYA_ROOM_NO && room.HYA_ROOM_NO}
                            </Typography>
                          </Link>
                        ) : (
                          <Typography variant="body2" className={classes.text}>
                            {room && room.HYA_ROOM_NO && room.HYA_ROOM_NO}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )}
              <Grid item xs={12} container spacing={2} className={classes.row}>
                <Grid item xs={12} sm={6} container>
                  <Typography variant="body2" className={classes.text}>
                    郵便番号
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.text}>
                      {building && building.TTY_ZIPCODE && building.TTY_ZIPCODE}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid item xs={12} container spacing={2} className={classes.row}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" className={classes.text}>
                    住所
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.text}>
                      {building && buildFullAddress(building)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              {!isOwnerPath && (
                <>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        建物取扱店舗
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        {building && building.TTY_TNP_NAME}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )}
            </Grid>
            {!isOwnerPath && (
              <>
                <Grid className={classes.section2}>
                  <Grid container spacing={4}>
                    <Grid item xs={10} alignItems="center" spacing={2}>
                      <Typography className={classes.title}>
                        {'契約情報'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={`${classes.section2} ${classes.sectionWidth}`}>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        契約状況
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku ? (
                            <KeiyakuStatusLabel
                              kykStatusDiv={knrKeiyaku.KYK_STATUS_DIV}
                            />
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        契約日（成約日）
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku && knrKeiyaku.KYK_KEIYAKU_DATE
                            ? formatYYYYMMDD(
                                new Date(knrKeiyaku.KYK_KEIYAKU_DATE)
                              )
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        入居日
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku && knrKeiyaku.KYK_NYUKYO_DATE
                            ? formatYYYYMMDD(
                                new Date(knrKeiyaku.KYK_NYUKYO_DATE)
                              )
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        契約開始日
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku && knrKeiyaku.KYK_KEIYAKU_STR_DATE
                            ? formatYYYYMMDD(
                                new Date(knrKeiyaku.KYK_KEIYAKU_STR_DATE)
                              )
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        契約終了日
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku && knrKeiyaku.KYK_KEIYAKU_END_DATE
                            ? formatYYYYMMDD(
                                new Date(knrKeiyaku.KYK_KEIYAKU_END_DATE)
                              )
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        更新期間
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku ? knrKeiyaku.KYK_KOSHIN_YR + '年' : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        定期借家
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {building && (
                            <>
                              {building.KYK_TEISYAKU_FLG ? (
                                <>あり</>
                              ) : (
                                <>なし</>
                              )}
                            </>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        自動更新
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku && (
                            <>
                              {knrKeiyaku.KYK_KOSHIN_AUTO_FLG ? (
                                <>あり</>
                              ) : (
                                <>なし</>
                              )}
                            </>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        更新通知
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku && knrKeiyaku.KYK_KOSHINYOKOKU_NUM}
                          {knrKeiyaku && knrKeiyaku.KYK_KOSHINYOKOKU_DIV_NAME}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" className={classes.text}>
                        退去予告期限
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {knrKeiyaku && knrKeiyaku.KYK_TAIKYOYOKOKU_NUM ? (
                            <>
                              {knrKeiyaku.KYK_TAIKYOYOKOKU_NUM}
                              {knrKeiyaku.KYK_TAIKYOYOKOKU_DIV_NAME}
                            </>
                          ) : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              </>
            )}
          </>
        ) : (
          <Grid item xs={12} container spacing={1} className={classes.row}>
            <Grid item xs={12} sm={12}>
              <Loading isLoading={isLoading} />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  )
}

export default withSnackbar(withRouter(BuildingInfo))
