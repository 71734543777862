import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { handleClear, setLoadData } from 'actions/Actions'
import Left from './Left'
import Right from './Right'
import Footer from './Footer'
import { withSnackbar } from 'notistack'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  leftBottom: {
    backgroundColor: '#fff'
  },
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function EditScreen(props) {
  const classes = useStyles()
  const { setLoadData } = props

  const [company, setCompany] = useState({})
  const [enableCompanyForm, setEnableCompanyForm] = useState(true)
  const [enableInquireForm, setEnableInquireForm] = useState(true)
  const [enableContactDetailForm, setEnableContactDetailForm] = useState(true)
  const [enablePrivacyPolicyForm, setEnablePrivacyPolicyForm] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [inputFormErrors, setInputFormErrors] = useState([])

  useEffect(() => {
    props.handleClear()
    fetchData()
  }, [setCompany])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([fetchCompany()])
    setIsLoading(false)
  }

  const fetchCompany = async () => {
    const result = await QueryHelper('getCompany', {})
    if (!result.error) {
      setCompany(result)
    } else {
      props.enqueueSnackbar(`会社情報を取得できませんでした。`)
    }
  }

  const handleCancel = async event => {
    event.preventDefault()
    props.handleClear()
    setCompany(Object.assign({}, company))
    setInputFormErrors([])
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (Object.keys(props.parameters).length > 0) {
      if (
        !enableCompanyForm ||
        !enableInquireForm ||
        !enableContactDetailForm ||
        !enablePrivacyPolicyForm
      ) {
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        return
      }
      setLoadData(true)

      const params = { ...props.parameters }
      if (params.contact_informations) {
        params.upsert_contact_info_input = params.contact_informations
          // お問い合わせ情報のis_enabledやall_day_receptionが全てfalseの場合は、そのお問い合わせ情報をリクエストから除外する
          .filter(item => {
            if (
              Object.values(item).every(value => typeof value === 'boolean')
            ) {
              return Object.values(item).some(value => value === true)
            }
            return true
          })
          // is_enabledが含まれていない場合、デフォルトでfalseを設定する
          .map(item => {
            if (!('is_enabled' in item)) {
              item.is_enabled = false
            }
            return item
          })
        delete params['contact_informations']
      }

      if (params.images) {
        const creds = await Auth.currentCredentials()
        const attachments = []
        for (let file of params.images) {
          if ('id' in file) {
            attachments.push(file)
            continue
          }
          const result = await fileupload(creds, 'images', file)
          attachments.push({
            attachment_type_id: 1,
            mime_type: file.type,
            filename: file.name,
            key: result.key
          })
        }
        delete params['images']
        params['attachments'] = attachments
      }
      if (params.business_hours_begin) {
        if (params.business_hours_end) {
          params['business_hours'] = JSON.stringify({
            begin: params.business_hours_begin,
            end: params.business_hours_end
          })
          delete params['business_hours_end']
        } else {
          const business_hours =
            'company' in company && company.company
              ? JSON.parse(company.company.business_hours)
              : {
                  begin: '00:00',
                  end: '00:00'
                }
          params['business_hours'] = JSON.stringify({
            begin: params.business_hours_begin,
            end: business_hours.end
          })
        }
        delete params['business_hours_begin']
      } else if (params.business_hours_end) {
        const business_hours =
          'company' in company && company.company
            ? JSON.parse(company.company.business_hours)
            : {
                begin: '00:00',
                end: '00:00'
              }
        params['business_hours'] = JSON.stringify({
          begin: business_hours.begin,
          end: params.business_hours_end
        })
        delete params['business_hours_end']
      }
      if (params.regular_holiday_display) {
        params['regular_holiday'] = JSON.stringify({
          display: params.regular_holiday_display
        })
        delete params['regular_holiday_display']
      }
      await putCompany(params)
      setLoadData(false)
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
      setLoadData(false)
    }
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    return result
  }

  const putCompany = async params => {
    setLoadData(true)
    const result = await MutationHelper('updateCompany', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('会社情報を更新できませんでした。', {
        variant: 'error'
      })
      setLoadData(false)
    } else {
      props.handleClear()
      fetchCompany()
      props.enqueueSnackbar('会社情報を更新しました。', {
        variant: 'success'
      })
      setLoadData(false)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.leftBody}>
              <Left
                isLoading={isLoading}
                company={company}
                setEnableCompanyForm={setEnableCompanyForm}
                setEnableInquireForm={setEnableInquireForm}
                setEnableContactDetailForm={setEnableContactDetailForm}
                setEnablePrivacyPolicyForm={setEnablePrivacyPolicyForm}
                setInputFormErrors={setInputFormErrors}
                inputFormErrors={inputFormErrors}
              />
            </div>
            <div className={classes.leftBottom}>
              <Footer handleCancel={handleCancel} />
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.rightBody}>
              <Right isLoading={isLoading} company={company} />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditScreen))
