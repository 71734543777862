import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import List from './List'
import Pagination from 'components/common/Pagination'
import RadioButtons from 'components/common/RadioButtons'
import { PAGE_LIMIT } from 'utils/const'
import { FaqManagementState, ScreenUrl } from 'utils/enum'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  closeSearchModalDialog,
  saveFaqSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import Loading from 'components/common/Loading'
import CommonChip from 'components/common/CommonChip'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  }
}))

const buttonsData = [
  { value: FaqManagementState.all.code, label: FaqManagementState.all.value },
  {
    value: FaqManagementState.draft.code,
    label: FaqManagementState.draft.value
  },
  {
    value: FaqManagementState.liested.code,
    label: FaqManagementState.liested.value
  }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'selectedStatus' in location.state
      ? location.state.selectedStatus
      : FaqManagementState.all.code
  )
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : props.faqSearchParams
  )

  const [faqList, setFaqList] = useState(initialState)
  const [parents, setParents] = useState(null)
  const [children, setChildren] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    searchCondition['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    searchCondition['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchCondition, {
      status: selectedStatus
    })
    if (!selectedStatus) {
      params['status'] = FaqManagementState.all.code
    }
    params['pageNumber'] = 1
    props.saveFaqSearchCondition(params)
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  async function fetchFaqList(offset, limit) {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'status') {
          filter['status'] = { eq: searchCondition[key] }
          setSelectedStatus(searchCondition[key])
        }
        if (key == 'management.faq.parent_id') {
          filter['parent_id'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.faq.category_id') {
          filter['category_id'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.faq.question') {
          filter['question'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'management.faq.attachment_id') {
          if (searchCondition[key] == 1) {
            filter['attachment_id'] = {
              eq: searchCondition[key]
            }
          }
          if (searchCondition[key] == 0) {
            filter['attachment_id'] = {
              ne: searchCondition[key]
            }
          }
        }
      }
    })
    let result = await QueryHelper(
      'getFaqList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('FAQ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setFaqList(result)
    }
  }

  async function fetchCategoryList() {
    const result = await QueryHelper(
      'getLargeCategoryAndMediumCategory',
      {},
      true
    )
    if (!result.error) {
      let parentsList = result.map(({ large_categories }) => ({
        value: large_categories.id,
        label: large_categories.name
      }))

      let childrenList = {}
      result.map(({ medium_categories }) => {
        medium_categories.map(({ id, parent_id, name }) => {
          if (!childrenList[parent_id]) {
            childrenList[parent_id] = [{ value: '', label: '' }]
          }
          childrenList[parent_id] = [
            ...childrenList[parent_id],
            { value: id, label: name }
          ]
        })
      })

      setParents(parentsList)
      setChildren(childrenList)
    } else {
      props.enqueueSnackbar('カテゴリ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {
      status: selectedStatus
    }
    for (let entry of formData.entries()) {
      if (entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    props.saveFaqSearchCondition(params)
    props.closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchFaqList(offset, PAGE_LIMIT),
      parents && children ? '' : fetchCategoryList()
    ])
    localStorage.clear()
    if (children != null) {
      localStorage.setItem('categoryChildren', JSON.stringify(children))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.managementFaqList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [
    pageNumber,
    selectedStatus,
    searchCondition,
    setFaqList,
    setParents,
    setChildren
  ])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.managementFaqList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'status': {
        setSelectedStatus(FaqManagementState.all.code)
        delete params[target]
        break
      }
      case 'management.faq.parent_id': {
        delete params[target]
        delete params['management.faq.category_id']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    props.saveFaqSearchCondition(params)
    setSearchCondition(params, setPageNumber(1))
  }

  function isContainsManagementFaqBySearchConditions() {
    const val = Object.keys(searchCondition).find(
      x =>
        x.match(/^management.faq.+/) ||
        (x === 'status' && searchCondition[x] != FaqManagementState.all.code)
    )
    return val !== null && val !== undefined
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsManagementFaqBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'status': {
                    if (
                      searchCondition[key] != 0 &&
                      searchCondition[key] != undefined
                    ) {
                      switch (searchCondition[key]) {
                        case FaqManagementState.draft.code: {
                          label = '状態: 下書き'
                          break
                        }
                        case FaqManagementState.liested.code: {
                          label = '状態: 掲載中'
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'management.faq.parent_id': {
                    if (
                      parents &&
                      searchCondition[key] != 0 &&
                      searchCondition[key] != ''
                    ) {
                      for (let parent of parents) {
                        if (parent.value === Number(searchCondition[key])) {
                          label = '大カテゴリ: ' + parent.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'management.faq.category_id': {
                    if (
                      children &&
                      searchCondition[key] != 0 &&
                      searchCondition[key] != '' &&
                      searchCondition['message.parent_category'] != 0 &&
                      searchCondition['message.parent_category'] != ''
                    ) {
                      for (let child of children[
                        searchCondition['management.faq.parent_id']
                      ]) {
                        if (child.value === Number(searchCondition[key])) {
                          label = '中カテゴリ: ' + child.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'management.faq.question': {
                    label = '質問内容: ' + searchCondition[key]
                    break
                  }
                  case 'management.faq.attachment_id': {
                    if (searchCondition[key] == 1) {
                      label = '添付: あり'
                    }
                    if (searchCondition[key] == 0) {
                      label = '添付: なし'
                    }
                    break
                  }
                  default: {
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips}>
          <Button
            onClick={e =>
              history.push({
                pathname: `/management/faq/new`,
                state: searchCondition
              })
            }
            variant="contained"
            startIcon={<AddIcon />}
            disableElevation
            className={classes.primaryButton}
            disabled={isRestrictedRole}
          >
            FAQを追加
          </Button>
        </Grid>
        <Grid item xs={5} className={classes.tableTips}>
          <RadioButtons
            current={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={faqList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && faqList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List faqList={faqList.data} searchCondition={searchCondition} />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={faqList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            parents={parents}
            children={children}
            searchCondition={searchCondition}
          />
        }
        footer={<SearchModalDialogFooter />}
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.managementFaqList}
            searchConditions={searchCondition}
            stateName={'status'}
            setSelectedStatus={setSelectedStatus}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    faqSearchParams: state.saveSearchCondition.faq
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    saveFaqSearchCondition: parameter => {
      dispatch(saveFaqSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
