import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import HelpIcon from '@material-ui/icons/Help'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import TimerIcon from '@material-ui/icons/Timer'
import DraftsIcon from '@material-ui/icons/Drafts'
import EventNoteIcon from '@material-ui/icons/EventNote'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import IconWithText from 'components/common/IconWithText'
import { BulletinBoardState, BulletinBoardType } from 'utils/enum'
import { formatYYYYMMDD } from 'utils/date.utils'
import DateLabel from 'components/common/DateLabel'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { useOwnerFunctionEnabled } from 'hooks/useOwnerFunctionEnabled'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  titleWrapper: {
    whiteSpace: 'nowrap',
    minWidth: 210,
    width: 210,
    maxWidth: 210,
    height: 80,
    maxHeight: 80
  },
  iconColor: {
    color: theme.palette.c_thirdly.main
  }
}))

export const ListColumns = (isOwnerFunctionEnabled, isRole12) => {
  const columns = [
    { id: 'status', label: '状態', width: 80, hasSort: false },
    { id: 'is_important', label: '重要', width: 80, hasSort: true },
    isOwnerFunctionEnabled && isRole12
      ? { id: 'with_owner', label: 'オーナー', width: 90, hasSort: false }
      : null,
    { id: 'title', label: 'タイトル', minWidth: 180, hasSort: true },
    {
      id: 'bulletin_board_type_id',
      label: '種別',
      minWidth: 80,
      hasSort: true
    },
    { id: 'display_start_at', label: '掲載開始', minWidth: 100, hasSort: true },
    { id: 'display_end_at', label: '掲載終了', minWidth: 100, hasSort: true },
    { id: 'is_assign', label: '配信先', minWidth: 80, hasSort: true },
    { id: 'updated_at', label: '更新時間', minWidth: 100, hasSort: true },
    { id: 'updated_by', label: '更新者', minWidth: 100, hasSort: true }
  ].filter(column => column !== null)

  return columns
}

function CellWithStatus({ status, displayStartAt, displayEndAt }) {
  switch (status) {
    case 2:
      return (
        <IconWithText
          text={BulletinBoardState.draft.value}
          icon={<DraftsIcon />}
          color={'c_thirdly'}
        />
      )
    case 1:
      if (!displayStartAt && !displayEndAt) {
        return (
          <IconWithText
            text={BulletinBoardState.draft.value}
            icon={<DraftsIcon />}
            color={'c_thirdly'}
          />
        )
      }
      const startDate = new Date(displayStartAt)
      const endDate = new Date(displayEndAt)
      const now = new Date()
      now.setHours(0)
      now.setMinutes(0)
      now.setSeconds(0)
      now.setMilliseconds(0)
      if (now.getTime() < startDate.getTime()) {
        return (
          <IconWithText
            text={BulletinBoardState.reservation.value}
            icon={<TimerIcon />}
            color={'c_thirdly'}
          />
        )
      }
      if (now.getTime() > endDate.getTime()) {
        return (
          <IconWithText
            text={BulletinBoardState.complete.value}
            icon={<BeenhereIcon />}
            color={'c_thirdly'}
          />
        )
      }
      if (
        now.getTime() >= startDate.getTime() &&
        now.getTime() <= endDate.getTime()
      ) {
        return (
          <IconWithText
            text={BulletinBoardState.posting.value}
            icon={<EventNoteIcon />}
            color={'c_blue'}
          />
        )
      }
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithTitle({ title }) {
  console.log(title.length)
  if (title.length < 12) {
    return <>{title}</>
  }
  const firstRow = title.slice(0, 12)
  const secondRow = title.slice(12)
  if (secondRow.length < 12) {
    return (
      <>
        {firstRow}
        <br /> {secondRow}
      </>
    )
  }
  const trimSecondRow = secondRow.slice(0, 12) + '...'
  return (
    <>
      {firstRow}
      <br />
      {trimSecondRow}
    </>
  )
}

function CellWithImportant({
  status,
  isImportant,
  displayStartAt,
  displayEndAt
}) {
  if (!isImportant) {
    return <></>
  }
  if (status !== 1 || (displayStartAt && !displayEndAt)) {
    return (
      <IconWithText text={'重要'} icon={<BookmarkIcon />} color={'c_thirdly'} />
    )
  }
  const startDate = new Date(displayStartAt)
  const endDate = new Date(displayEndAt)
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  now.setMilliseconds(0)

  if (
    now.getTime() >= startDate.getTime() &&
    now.getTime() <= endDate.getTime()
  ) {
    return (
      <IconWithText text={'重要'} icon={<BookmarkIcon />} color={'c_blue'} />
    )
  } else {
    return (
      <IconWithText text={'重要'} icon={<BookmarkIcon />} color={'c_thirdly'} />
    )
  }
}

function CellWithType({ typeId }) {
  switch (typeId) {
    case BulletinBoardType.notify.code:
      return <>{BulletinBoardType.notify.value}</>
    case BulletinBoardType.facility.code:
      return <>{BulletinBoardType.facility.value}</>
    case BulletinBoardType.campaign.code:
      return <>{BulletinBoardType.campaign.value}</>
    case BulletinBoardType.resident.code:
      return <>{BulletinBoardType.resident.value}</>
    case BulletinBoardType.contract.code:
      return <>{BulletinBoardType.contract.value}</>
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithDisplayDate({ displayDateAt }) {
  const displayDate = new Date(displayDateAt)
  return <>{formatYYYYMMDD(displayDate)}</>
}

function CellWithTarget({ isAssign, buildings }) {
  if (!isAssign) {
    return <>全件</>
  } else if (buildings && buildings.length > 0) {
    return <>{buildings.length}件</>
  } else {
    return <>0件</>
  }
}

function List(props) {
  const history = useHistory()
  const classes = useStyles()
  const isTextSelected = useIsTextSelected()
  const { role12 } = useRole()
  const { isOwnerFunctionEnabled } = useOwnerFunctionEnabled()
  const columns = ListColumns(isOwnerFunctionEnabled, role12())

  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(`/bulletin-board/edit/${id}`, e, history)
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
                sortDirection={
                  column.hasSort && orderBy === column.id ? order : false
                }
              >
                {column.hasSort ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    className={classes.tableSort}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <>{column.label}</>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.bulletinBoardList.length
            ? props.bulletinBoardList.map(row => {
                return (
                  <TableRow
                    style={{ height: 80, maxHeight: 80 }}
                    onClick={e =>
                      navigateToDetailPage(e, row.bulletin_board.id)
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.bulletin_board.id}
                  >
                    <TableCell>
                      <CellWithStatus
                        status={row.bulletin_board.status}
                        displayStartAt={row.bulletin_board.display_start_at}
                        displayEndAt={row.bulletin_board.display_end_at}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithImportant
                        isImportant={row.bulletin_board.is_important}
                        status={row.bulletin_board.status}
                        displayStartAt={row.bulletin_board.display_start_at}
                        displayEndAt={row.bulletin_board.display_end_at}
                      />
                    </TableCell>
                    {isOwnerFunctionEnabled && role12() && (
                      <TableCell>
                        {row.bulletin_board.with_owner && (
                          <FiberManualRecordIcon
                            fontSize="small"
                            className={classes.iconColor}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell className={classes.titleWrapper}>
                      <CellWithTitle title={row.bulletin_board.title} />
                    </TableCell>
                    <TableCell>
                      <CellWithType
                        typeId={row.bulletin_board.bulletin_board_type_id}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithDisplayDate
                        displayDateAt={row.bulletin_board.display_start_at}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithDisplayDate
                        displayDateAt={row.bulletin_board.display_end_at}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithTarget
                        isAssign={row.bulletin_board.is_assign}
                        buildings={row.building}
                      />
                    </TableCell>
                    <TableCell>
                      {' '}
                      <DateLabel timestamp={row.bulletin_board.updated_at} />
                    </TableCell>
                    <TableCell>
                      {row.updated_user &&
                        getFormattedNameByStatus(
                          row.updated_user.name,
                          row.updated_user.status
                        )}
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
