export const useLoginInformation = () => {
  let item = localStorage.getItem('totono-advertiser-session')
  if (!item) {
    item = sessionStorage.getItem('totono-advertiser-session')
  }
  if (!item) {
    return
  }
  const data = JSON.parse(item)
  const judgmentRoleId = () => {
    return data.role_id
  }

  return {
    judgmentRoleId
  }
}
