import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import Loading from 'components/common/Loading'
import { Typography } from '@material-ui/core'
import RadioButtons from 'components/common/RadioButtons'
import { OwnerState } from 'utils/enum'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { changeSearchCondition, closeSearchModalDialog } from 'actions/Actions'
import CommonChip from 'components/common/CommonChip'
import { fromCode } from 'utils/enum.utils'
import { QueryHelper } from 'utils/api.utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5)
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

const ListContent = props => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const { pageId } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [ownerList, setOwnerList] = useState(initialState)

  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const handleChangePage = (event, newPage) => {
    searchConditions['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = newPage => {
    searchConditions['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  const buttonsData = [
    { value: OwnerState.all.code, label: OwnerState.all.value },
    {
      value: OwnerState.enabled.code,
      label: OwnerState.enabled.value
    },
    { value: OwnerState.disabled.code, label: OwnerState.disabled.value }
  ]

  const [searchConditions, setSearchConditions] = useState(
    location.state !== undefined ? location.state : {}
  )
  const [searchConditionErrors, setSearchConditionErrors] = useState({})

  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'owner.state' in location.state
      ? location.state['owner.state']
      : OwnerState.all.code
  )

  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchConditions['orderBy'] = sortParam
    setPageNumber(1)
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchConditions, {
      'owner.state': selectedStatus
    })
    if (!selectedStatus) {
      delete params['owner.state']
    }
    setSearchConditions(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([fetchOwnerList(offset, PAGE_LIMIT)])
    setIsLoading(false)
  }

  const fetchOwnerList = async (offset, limit) => {
    const params = {}
    const created_at = []
    const last_login_at = []

    Object.keys(searchConditions).forEach(function(key) {
      if (searchConditions[key]) {
        switch (key) {
          case 'owner.id': {
            params.id = { eq: Number(searchConditions[key]) }
            break
          }
          case 'owner.name': {
            params.name = { contains: searchConditions[key] }
            break
          }
          case 'owner.name_kana': {
            params.name_kana = { contains: searchConditions[key] }
            break
          }
          case 'owner.state': {
            if (searchConditions[key] == OwnerState.enabled.code) {
              params.is_usage = { eq: true }
            } else if (searchConditions[key] == OwnerState.disabled.code) {
              params.is_usage = { eq: false }
            } else {
              params.is_usage = { eq: false }
            }
            break
          }
          case 'owner.username': {
            params.username = { contains: searchConditions[key] }
            break
          }
          case 'owner.created_at_from': {
            created_at.push(searchConditions[key])
            break
          }
          case 'owner.created_at_to': {
            created_at.push(searchConditions[key])
            break
          }
          case 'owner.last_login_at_from': {
            last_login_at.push(searchConditions[key])
            break
          }
          case 'owner.last_login_at_to': {
            last_login_at.push(searchConditions[key])
            break
          }
          default:
        }
      }
    })
    if (created_at.length > 0) {
      params.created_at = { between: created_at }
    }

    if (last_login_at.length > 0) {
      params.last_login_at = { between: last_login_at }
    }

    if (orderBy && Object.keys(orderBy).length > 0) {
      params.order_by = orderBy
    }

    const result = await QueryHelper(
      'getOwnerList',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )

    if (result.error) {
      props.enqueueSnackbar('オーナー情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setOwnerList(result)
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (const [key, value] of formData.entries()) {
      if (key === 'owner.state' && value !== '') {
        const parsedValue = JSON.parse(value)
        setSelectedStatus(parsedValue)
        params[key] = parsedValue
      } else if (value !== 0 && value !== '') {
        params[key] = value
      }
    }
    if (validateSearchCondition(params)) {
      return
    }
    props.changeSearchCondition(params, setPageNumber(1))
    props.closeSearchModalDialog()
    setSearchConditions(params)
  }

  const isContainsOwnerBySearchConditions = () => {
    return Object.keys(searchConditions).some(
      x => x.match(/^owner.+/) && searchConditions[x] != OwnerState.all.code
    )
  }

  const SearchConditionChips = () => {
    return (
      <Grid item xs={12}>
        {searchConditions &&
          Object.keys(searchConditions).length > 0 &&
          isContainsOwnerBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchConditions).map(key => {
                let label = ''

                switch (key) {
                  case 'owner.id': {
                    label = 'オーナーID: ' + searchConditions[key]
                    break
                  }
                  case 'owner.state': {
                    if (
                      searchConditions[key] != OwnerState.all.code &&
                      searchConditions[key] != undefined
                    ) {
                      label =
                        '状態: ' +
                        fromCode(OwnerState, Number(searchConditions[key]))
                          .value
                    }
                    break
                  }
                  case 'owner.name': {
                    label = 'オーナー名: ' + searchConditions[key]
                    break
                  }
                  case 'owner.name_kana': {
                    label = 'オーナー名(フリガナ): ' + searchConditions[key]
                    break
                  }
                  case 'owner.username': {
                    label =
                      'ログインID(メールアドレス): ' + searchConditions[key]
                    break
                  }
                  case 'owner.created_at_from': {
                    label = '作成日(From): ' + searchConditions[key]
                    break
                  }
                  case 'owner.created_at_to': {
                    label = '作成日(To): ' + searchConditions[key]
                    break
                  }
                  case 'owner.last_login_at_from': {
                    label = '最終ログイン日(From): ' + searchConditions[key]
                    break
                  }
                  case 'owner.last_login_at_to': {
                    label = '最終ログイン(To): ' + searchConditions[key]
                    break
                  }
                  default: {
                    break
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchConditions(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  const handleDeleteSearchConditions = (target, id = null) => () => {
    const params = JSON.parse(JSON.stringify(searchConditions))
    switch (target) {
      case 'owner.state': {
        if (searchConditions[target] != 0) {
          setSelectedStatus(OwnerState.all.code)
        }
        delete params[target]
        break
      }

      case 'owner.created_at_from':
      case 'owner.created_at_to': {
        delete params['owner.created_at_from']
        delete params['owner.created_at_to']
        break
      }

      case 'owner.last_login_at_from':
      case 'owner.last_login_at_to': {
        delete params['owner.last_login_at_from']
        delete params['owner.last_login_at_to']
        break
      }

      default: {
        delete params[target]
      }
    }
    params['pageNumber'] = 1
    setSearchConditions(params, setPageNumber(1))
  }

  const validateSearchCondition = params => {
    const valid = {}
    if (params['owner.created_at_from'] && !params['owner.created_at_to']) {
      valid['owner.created_at_to.message'] =
        '作成日(From)が指定されてる時は作成日(To)は必須です'
    }
    if (!params['owner.created_at_from'] && params['owner.created_at_to']) {
      valid['owner.created_at_from.message'] =
        '作成日(To)が指定されてる時は作成日(From)は必須です'
    }
    if (
      params['owner.last_login_at_from'] &&
      !params['owner.last_login_at_to']
    ) {
      valid['owner.last_login_at_to.message'] =
        '最終ログイン日(From)が指定されてる時は最終ログイン日(To)は必須です'
    }
    if (
      !params['owner.last_login_at_from'] &&
      params['owner.last_login_at_to']
    ) {
      valid['owner.last_login_at_from.message'] =
        '最終ログイン日(To)が指定されてる時は最終ログイン日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `/owner/list/${pageNumber}`,
      state: searchConditions
    })
    fetchData(offset)
  }, [pageNumber, setOwnerList, searchConditions, orderBy])

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <SearchConditionChips />
      <Grid item xs={6} className={classes.tableTips}>
        <RadioButtons
          current={selectedStatus}
          handleSelectedButton={handleSelectedButton}
          buttonsData={buttonsData}
        />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs className={classes.tableTips}>
          <Typography variant="body2">
            ※オーナー情報の追加・削除は建物リストから行なってください。
          </Typography>
        </Grid>
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={ownerList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && ownerList && ownerList.data ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              ownerList={ownerList.data}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={ownerList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            searchConditions={searchConditions}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
