import React, { useEffect, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import RowWithFile from 'components/common/RowWithFile'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

const TitleImage = props => {
  const attachment = props.attachment ? props.attachment : null
  const [titleImage, setTitleImage] = useState(attachment)

  useEffect(() => {
    if (attachment) {
      setTitleImage(attachment)
      Auth.currentCredentials().then(async creds => {
        if (!attachment.key || attachment.key === '') {
          return
        }
        const key = attachment.key
        Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            const object = Object.assign({}, attachment, { body: result })
            setTitleImage(object)
          })
          .catch(err => {
            console.error('error: ', err)
          })
      })
    }
  }, [attachment, setTitleImage])

  return (
    <>
      {titleImage && titleImage.body && (
        <RowWithFile
          image={titleImage.body}
          title={titleImage.filename}
          handleDelete={props.handleDelete}
          isDeleteButtonView={true}
          disabledIcon={props.disabledIcon}
        />
      )}
    </>
  )
}

export default withSnackbar(withRouter(TitleImage))
