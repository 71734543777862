import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DetailContent from './DetailContent'
import ContractListContent from './ContractListContent'
import RoomForm from './RoomForm'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left(props) {
  const classes = useStyles()

  const { room } = props
  // console.log(JSON.stringify(room));

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <DetailContent room={room} />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <ContractListContent room={room} />
      </div>
      <div className={classes.divider} />
      <div className={classes.bottom}>
        <RoomForm room={room} />
      </div>
    </div>
  )
}

export default Left
