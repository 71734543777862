import { BaseEnum } from './BaseEnum'

const UNKNOWN_ENUM = new BaseEnum(0, '不明')

export function fromCode(enums, code) {
  for (let [, value] of Object.entries(enums)) {
    if (value.code === code) {
      return value
    }
  }
  return UNKNOWN_ENUM
}
