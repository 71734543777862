import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import FormLayout from 'components/common/FormLayout'
import RowWithPdf from 'components/common/RowWithPdf'

import { Auth, Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'
import { FormHelperText } from '@material-ui/core'

const MAXIMUM_FILE_LENGTH = 1

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%',
    marginTop: 10
  },
  card: {
    width: 120,
    height: 80
  },
  cover: {
    width: 120,
    height: 80
  }
}))

function FileForm(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const [files, setFiles] = useState([])
  const [params, setParams] = useState([])

  const { isLoading, bookmark, errors } = props
  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const object = {
        status: 1,
        attachment_type_id: 3,
        mime_type: file.type,
        filename: file.name,
        body: reader.result
      }

      let copy1 = []
      Object.assign(copy1, files)
      copy1.push(object)
      let copy2 = []
      Object.assign(copy2, params)
      copy2.push(file)

      setFiles([...copy1])
      setParams([...copy2])
      const splicedParams = [
        ...copy2.filter(
          item =>
            item instanceof File ||
            (Object.keys(item).indexOf('status') !== -1 && item.status == 3)
        )
      ]
      props.handleChange({ files: [...splicedParams] })
    }
    reader.readAsDataURL(file)
  }

  const handleClick = async (attachment, event) => {
    event.preventDefault()
    if (attachment && attachment.key) {
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        await Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            fetch(result).then(response => {
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = attachment.filename
                a.click()
              })
            })
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }

  const handleDelete = index => {
    let copy1 = []
    Object.assign(copy1, files)
    let target1 = copy1[index]
    target1.status = 3
    copy1.splice(index, 1, target1)
    let copy2 = []
    Object.assign(copy2, params)
    let target2 = copy2[index]
    if (target2 instanceof File) {
      copy2.splice(index, 1, {})
    } else {
      target2.status = 3
      copy2.splice(index, 1, target2)
    }

    setFiles([...copy1])
    setParams([...copy2])
    const splicedParams = [
      ...copy2.filter(
        item =>
          item instanceof File ||
          (Object.keys(item).indexOf('status') !== -1 && item.status == 3)
      )
    ]
    props.handleChange({ files: [...splicedParams] })
  }

  useEffect(() => {
    if (0 !== Object.keys(bookmark) && bookmark.attachment) {
      const attachment = bookmark.attachment
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        console.log(key)
        Storage.get(key, {
          level: 'protected',
          identityId: creds.params.User.CompanyId,
          expires: 60
        })
          .then(result => {
            const object = Object.assign({}, attachment, { body: result })
            setFiles([object])
            setParams([object])
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    } else {
      setFiles([])
      setParams([])
    }
  }, [bookmark, setFiles, setParams])

  return (
    <FormLayout title="添付PDF">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {files.length > 0 &&
            files.map(({ status, filename, body }, index) => {
              return status == 1 ? (
                <RowWithPdf
                  key={index}
                  title={filename}
                  handleDelete={e => handleDelete(index)}
                  style={{ marginBottom: 10 }}
                  attachment={files[index]}
                  handleClick={handleClick}
                  disabledIcon={!isUnrestrictedRole}
                ></RowWithPdf>
              ) : (
                <></>
              )
            })}
          {files.filter(item => item.status == 1).length <
            MAXIMUM_FILE_LENGTH && (
            <Grid container>
              <Grid item xs={12}>
                <input
                  accept="application/pdf"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={handleChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <label
                htmlFor="contained-button-file"
                className={classes.labelButton}
              >
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                  disabled={!isUnrestrictedRole}
                >
                  PDF追加*
                </Button>
                {errors && errors.file_err && (
                  <FormHelperText error>{errors.file_err}</FormHelperText>
                )}
              </label>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileForm)
