import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CategoryForm from './CategoryForm'
import MessageTemplateForm from './MessageTemplateForm'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left(props) {
  const classes = useStyles()

  const {
    isLoading,
    messageTemplate,
    setEnableCategoryForm,
    setEnableMessageTemplateForm,
    isErrorCategory
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <CategoryForm
          isLoading={isLoading}
          messageTemplate={messageTemplate}
          setEnableForm={setEnableCategoryForm}
          isErrorCategory={isErrorCategory}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <MessageTemplateForm
          isLoading={isLoading}
          messageTemplate={messageTemplate}
          setEnableForm={setEnableMessageTemplateForm}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.bottom}></div>
    </div>
  )
}

export default Left
