import React from 'react'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import CancelIcon from '@material-ui/icons/Cancel'
import HelpIcon from '@material-ui/icons/Help'
import IconWithText from 'components/common/IconWithText'
import DateLabel from 'components/common/DateLabel'
import {
  AttendCompanyType,
  LegalPersonDiv,
  ResidentCancellationApplicationState
} from 'utils/enum'
import { fromCode } from 'utils/enum.utils'
import Label from 'components/common/Label'
import { Grid } from '@material-ui/core'
import { handleOpenPage } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(() => ({
  arrangementImg: {
    position: 'relative',
    top: 3,
    width: 30,
    height: 'auto',
    alignItems: 'center'
  }
}))

const columns = [
  { id: 'application_id', label: 'ID', minWidth: 100, width: 100 },
  { id: 'application_status', label: '状態', minWidth: 80, width: 80 },
  {
    id: 'application_start_date',
    label: '申請日時',
    minWidth: 120,
    width: 120
  },
  { id: 'applicant_name', label: '入居者名', minWidth: 170 },
  { id: 'applicant_name', label: '契約者名', minWidth: 170 },
  { id: 'building_name', label: '物件名', minWidth: 170 },
  {
    id: 'residentCancellation_application_type',
    label: '区分',
    minWidth: 60,
    width: 60
  },
  {
    id: 'residentCancellation_cancellation_desired_date',
    label: '解約希望日',
    minWidth: 50,
    width: 50
  },
  {
    id: 'residentCancellation_cancellation_fixed_date',
    label: '解約日',
    minWidth: 50,
    width: 50
  },
  {
    id: 'residentCancellation_departure_fixed_date',
    label: '退去日',
    minWidth: 50,
    width: 50
  },
  {
    id: 'residentCancellation_attend',
    label: '立会い',
    minWidth: 50,
    width: 50
  },
  { id: 'arrangement', label: '手配', minWidth: 70, width: 70 },
  { id: 'assigned_user', label: '担当者', minWidth: 170 }
]

function CellWithStatus({ status }) {
  switch (status) {
    case ResidentCancellationApplicationState.open.code:
      return (
        <IconWithText
          text={ResidentCancellationApplicationState.open.value}
          icon={<MoveToInboxIcon />}
          color={'error'}
        />
      )
    case ResidentCancellationApplicationState.inProgress.code:
    case ResidentCancellationApplicationState.adjust.code:
      return (
        <IconWithText
          text={ResidentCancellationApplicationState.inProgress.value}
          icon={<MenuBookIcon />}
          color={'c_thirdly'}
        />
      )
    case ResidentCancellationApplicationState.pending.code:
      return (
        <IconWithText
          text={ResidentCancellationApplicationState.pending.value}
          icon={<TimelapseIcon />}
          color={'c_thirdly'}
        />
      )
    case ResidentCancellationApplicationState.complete.code:
      return (
        <IconWithText
          text={ResidentCancellationApplicationState.complete.value}
          icon={<BeenhereIcon />}
          color={'c_thirdly'}
        />
      )
    case ResidentCancellationApplicationState.cancel.code:
      return (
        <IconWithText
          text={ResidentCancellationApplicationState.cancel.value}
          icon={<CancelIcon />}
          color={'c_thirdly'}
        />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithLegalPersonDiv({ legalDiv }) {
  const legalPersonDiv = fromCode(LegalPersonDiv, legalDiv)
  return <>{legalPersonDiv.value}</>
}

function parseDate(dateStr) {
  return new Date(dateStr).toLocaleString({ timeZone: 'Asia/Tokyo' })
}

function CellWithCancellationDesiredDate({ json }) {
  let parseValue
  try {
    parseValue = JSON.parse(json)
  } catch (e) {
    return { e }
  }
  if (
    parseValue.cancellation &&
    parseValue.cancellation.cancellationDates &&
    parseValue.cancellation.cancellationDates.cancellationDesiredDate
  ) {
    var date = new Date(
      parseDate(
        parseValue.cancellation.cancellationDates.cancellationDesiredDate
      )
    )
    var year = date.getFullYear()
    var monthday =
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '21px',
            color: '#2c3f58'
          }}
        >
          <span>{monthday}</span>
        </Grid>
        <Grid
          item
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '18px',
            color: '#72809d'
          }}
        >
          <span>{year}</span>
        </Grid>
      </Grid>
    )
  }
  return <></>
}

function CellWithDepartureFixedDate({ json }) {
  let parseValue
  try {
    parseValue = JSON.parse(json)
  } catch (e) {
    return { e }
  }
  if (
    parseValue.cancellation &&
    parseValue.cancellation.cancellationDates &&
    parseValue.cancellation.cancellationDates.departureFixedDate
  ) {
    var date = new Date(
      parseDate(parseValue.cancellation.cancellationDates.departureFixedDate)
    )
    var year = date.getFullYear()
    var monthday =
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '21px',
            color: '#2c3f58'
          }}
        >
          <span>{monthday}</span>
        </Grid>
        <Grid
          item
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '18px',
            color: '#72809d'
          }}
        >
          <span>{year}</span>
        </Grid>
      </Grid>
    )
  }
  return <></>
}

function CellWithCancellationFixedDate({ json }) {
  let parseValue
  try {
    parseValue = JSON.parse(json)
  } catch (e) {
    return { e }
  }
  if (
    parseValue.cancellation &&
    parseValue.cancellation.cancellationDates &&
    parseValue.cancellation.cancellationDates.cancellationFixedDate
  ) {
    var date = new Date(
      parseDate(parseValue.cancellation.cancellationDates.cancellationFixedDate)
    )
    var year = date.getFullYear()
    var monthday =
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '21px',
            color: '#2c3f58'
          }}
        >
          <span>{monthday}</span>
        </Grid>
        <Grid
          item
          style={{
            fontFamily: 'Hiragino Sans',
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '18px',
            color: '#72809d'
          }}
        >
          <span>{year}</span>
        </Grid>
      </Grid>
    )
  }
  return <></>
}

function CellWithAttendCompanyType({ json }) {
  let parseValue
  try {
    parseValue = JSON.parse(json)
  } catch (e) {
    return { e }
  }
  if (!parseValue.cancellation) {
    return <></>
  }
  if (
    !parseValue.cancellation.attendFlg &&
    parseValue.cancellation.attendFlg === 0
  ) {
    return (
      <>
        <Label color="gray" text="なし" />
      </>
    )
  }
  if (!parseValue.cancellation.attendInfo) {
    return <></>
  }
  const residentCancellationApplicationCategory = fromCode(
    AttendCompanyType,
    parseValue.cancellation.attendInfo.attendCompanyTypeId
  )
  return (
    <>
      <Label
        color="blue"
        text={residentCancellationApplicationCategory.value}
      />
    </>
  )
}

function List(props) {
  const history = useHistory()
  const classes = useStyles()
  const isTextSelected = useIsTextSelected()

  const { searchCondition } = props

  const navigateToDetailPage = (e, row) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/application/residentCancellation/detail/${row.application.id}`,
      e,
      history,
      searchCondition
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.residentCancellationApplicationList.length
            ? props.residentCancellationApplicationList.map(row => {
                return (
                  <TableRow
                    onClick={e => navigateToDetailPage(e, row)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.application.id}
                  >
                    <TableCell>{row.application.id}</TableCell>
                    <TableCell>
                      <CellWithStatus
                        status={row.application.application_state_id}
                      />
                    </TableCell>
                    <TableCell>
                      <DateLabel timestamp={row.application.created_at} />
                    </TableCell>
                    <TableCell>
                      {'mst_kokyaku_nky' in row && row.mst_kokyaku_nky
                        ? row.mst_kokyaku_nky.KKY_NAME
                        : ''}
                    </TableCell>
                    <TableCell>
                      {'mst_kokyaku_kys' in row && row.mst_kokyaku_kys
                        ? row.mst_kokyaku_kys.KKY_NAME
                        : ''}
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item>{row.mst_tateya.TTY_NAME}</Grid>
                        <Grid item>{row.mst_heya.HYA_ROOM_NO}</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <CellWithLegalPersonDiv
                        legalDiv={row.mst_kokyaku_nky.KKY_LEGALPERSON_DIV}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithCancellationDesiredDate
                        json={row.application.content}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithCancellationFixedDate
                        json={row.application.content}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithDepartureFixedDate
                        json={row.application.content}
                      />
                    </TableCell>
                    <TableCell>
                      <CellWithAttendCompanyType
                        json={row.application.content}
                      />
                    </TableCell>
                    <TableCell>
                      {row.is_arranged &&
                        row.arrangement_status !== 'CANCEL_OR_RELEASE' && (
                          <img
                            className={classes.arrangementImg}
                            src={`${process.env.PUBLIC_URL}/wrench-check.svg`}
                            alt={'wrenchCheck'}
                          />
                        )}
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        {row.assigned_user_group &&
                          getFormattedNameByStatus(
                            row.assigned_user_group.name,
                            row.assigned_user_group.status
                          )}
                      </Grid>
                      <Grid item>
                        {row.assigned_user &&
                          getFormattedNameByStatus(
                            row.assigned_user.name,
                            row.assigned_user.status
                          )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default List
