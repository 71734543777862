import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import { Typography } from '@material-ui/core'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'
import RichTextEditor from 'components/General/RichTextEditor'
import { Note } from 'utils/note'

const useStyles = makeStyles(() => ({
  typography: {
    marginLeft: '12px',
    height: '18px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#72809d',
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

function PackageForm(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const {
    isLoading,
    packageData,
    setEnableForm,
    checkError,
    setCheckError,
    handleChange
  } = props

  const [values, setValues] = useState({
    name: '',
    name_err: '',
    description: '',
    body: '',
    body_err: ''
  })

  const checkErr = objects => {
    if (objects.name_err || objects.body_err) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else if (name !== 'description') {
      newValues[name + '_err'] = '入力してください'
    }
    setValues(newValues)
    checkErr(newValues)
    handleChange({ [name]: value })
  }

  const handleRichTextChange = (id, content) => {
    handleChange({ [id]: content })
  }

  useEffect(() => {
    if ('package' in packageData && packageData.package) {
      const initializeValues = {
        name: packageData.package.name || '',
        name_err: packageData.package.name ? '' : '入力してください',
        description: packageData.package.description || '',
        body: packageData.package.body || '',
        body_err: packageData.package.body ? '' : '入力してください'
      }

      setValues(initializeValues)
      checkErr(initializeValues)
    }
  }, [packageData, setValues])

  useEffect(() => {
    const hasErrors = Object.values(checkError).some(error => error !== '')
    if (hasErrors) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }, [checkError, setEnableForm])

  return (
    <FormLayout title="サービス内容" subTitle={Note.RichTextLink}>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              error={values.name_err != '' ? true : false}
              helperText={values.name_err}
              required
              id="name"
              name="name"
              label="サービス名"
              fullWidth
              variant="filled"
              defaultValue={values.name}
              onChange={handleInputRequiredChange}
              inputProps={{ maxLength: 100 }}
              disabled={!isUnrestrictedRole}
            />
            <Typography className={classes.typography}>100文字以内</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="description"
              name="description"
              label="リード文"
              fullWidth
              variant="filled"
              defaultValue={values.description}
              onChange={handleInputRequiredChange}
              inputProps={{ maxLength: 100 }}
              disabled={!isUnrestrictedRole}
            />
            <Typography className={classes.typography}>100文字以内</Typography>
          </Grid>
          <Grid item xs={12}>
            <RichTextEditor
              id="body"
              maxLength={2000}
              placeholder="本文を入力"
              defaultValue={values.body}
              setCheckError={setCheckError}
              checkError={checkError}
              readOnly={!isUnrestrictedRole}
              handleChange={handleRichTextChange}
              required
            />
            <Typography className={classes.typography}>
              2,000文字以内
            </Typography>
          </Grid>
        </Grid>
      )}
    </FormLayout>
  )
}

export default PackageForm
