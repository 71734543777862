import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAdmin, setLoadData, setSignin } from 'actions/Actions'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as mutations from 'graphql/mutations'
import Amplify from 'aws-amplify'
import { withSnackbar } from 'notistack'
import { getDecryptedString } from 'utils/crypto.utils'
import { AuthIpAddress } from 'components/common/AuthIpAddress'
import { ErrorMessages } from 'utils/errorMessages'

var AWS = require('aws-sdk')
AWS.config.region = process.env.REACT_APP_AWS_REGION
var roleArn = process.env.REACT_APP_AWS_COGNITO_ROLE_ARN
var customHeaderName = process.env.REACT_APP_HEADER_WEB_IDENTITY_TOKEN

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function ProxySignin(props) {
  const history = useHistory()
  const query = useQuery()

  const refreshToken = params => {
    API.graphql(graphqlOperation(mutations.tokenRefresh, params))
      .then(response => {
        const data = response.data.tokenRefresh
        console.log(JSON.stringify(data))
        props.setAdmin(data.is_administrator)
        AWS.config.credentials = new AWS.WebIdentityCredentials({
          RoleArn: roleArn,
          WebIdentityToken: data.token,
          User: {
            Id: data.user_id,
            CompanyId: data.company_id
          },
          DurationSeconds: 43200
        })
        AWS.config.credentials.get(async err => {
          if (err) {
            console.log(JSON.stringify(err))
          } else {
            Amplify.configure({
              API: {
                graphql_headers: async () => ({
                  [customHeaderName]: data.token
                })
              }
            })
            // localStorage.setItem('totono-advertiser-session', JSON.stringify(data));
            sessionStorage.setItem(
              'totono-advertiser-session',
              JSON.stringify(data)
            )
            props.setSignin(true)
            const result = await AuthIpAddress(history)
            if (result) {
              history.push('/')
            } else if (result === false) {
              props.enqueueSnackbar(
                ErrorMessages.FailedToAuthenticateIPAddress,
                {
                  variant: 'error'
                }
              )
            }
          }
        })
      })
      .catch(err => {
        console.log(JSON.stringify(err))
        props.setLoadData(false)
      })
  }

  useEffect(() => {
    props.setLoadData(true)

    if (!query.get('iv') || !query.get('encrypted')) {
      props.setLoadData(false)
      history.push('/')
      return
    }

    let item
    try {
      item = getDecryptedString(
        {
          encryptedString: query.get('encrypted'),
          ivString: query.get('iv')
        },
        encryptionKey
      )
    } catch (e) {
      props.setLoadData(false)
      history.push('/')
      return
    }

    const data = JSON.parse(item)
    // localStorage.setItem('totono-advertiser-session', JSON.stringify(data));
    sessionStorage.setItem('totono-advertiser-session', JSON.stringify(data))

    AWS.config.credentials = new AWS.WebIdentityCredentials({
      RoleArn: roleArn,
      WebIdentityToken: data.token,
      User: {
        Id: data.user_id,
        CompanyId: data.company_id
      },
      DurationSeconds: 43200
    })
    AWS.config.credentials.get(async err => {
      if (err) {
        console.log(JSON.stringify(err))
        AWS.config.credentials = null
        // refreshする
        refreshToken(data)
        props.setLoadData(false)
      } else {
        if (AWS.config.credentials.needsRefresh()) {
          AWS.config.credentials.refresh(function(err) {
            if (err) {
              console.log(JSON.stringify(err))
            } else {
              // refreshする
              refreshToken(data)
            }
            props.setLoadData(false)
          })
        } else {
          Amplify.configure({
            API: {
              graphql_headers: async () => ({
                [customHeaderName]: data.token
              })
            }
          })
          props.setSignin(true)
          props.setLoadData(false)
          const result = await AuthIpAddress(history)
          if (result) {
            history.push('/')
          } else if (result === false) {
            props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
              variant: 'error'
            })
          }
        }
      }
    })
  }, [props])

  return <></>
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setSignin: isSignin => {
      dispatch(setSignin(isSignin))
    },
    setAdmin: isAdmin => {
      dispatch(setAdmin(isAdmin))
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProxySignin))
