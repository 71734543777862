import React from 'react'
import { useMemo } from 'react'

//文字をハイライトするカスタムフック
//引数は、表示したい文字列、ハイライトしたい文字列、ハイライトしたい文字列に適用するクラス名(色を指定するために使用)
export const useHighlightText = (text, target, classes) => {
  return useMemo(() => {
    return text.split(target).map((chunk, index) =>
      index === 0 ? (
        chunk
      ) : (
        <>
          <span className={classes}>{target}</span>
          {chunk}
        </>
      )
    )
  }, [text, target, classes])
}

export default useHighlightText
