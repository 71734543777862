import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MutationHelper } from 'utils/api.utils'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dropdown from 'components/common/Form/Dropdown'
import Divider from '@material-ui/core/Divider'
import FormLayout from 'components/common/FormLayout'
import { BulletinBoardStatus } from 'utils/enum'
import { fromCode } from 'utils/enum.utils'
import DateLabel from 'components/common/DateLabel'
import { withSnackbar } from 'notistack'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setLoadData } from 'actions/Actions'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  userName: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px'
  }
}))

function Right(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const handleChange = event => {
    if (!props.bulletinBoard || !props.bulletinBoard.id) {
      props.setBulletinBoardStatus(event.target.value)
      return
    }
    const bulletinBoardStatus = fromCode(
      BulletinBoardStatus,
      event.target.value
    )
    if (
      !window.confirm(
        `ステータスを${bulletinBoardStatus.value}にします。よろしいですか？`
      )
    ) {
      // キャンセル時は値を変えないためdefaultValueと同じ値をDropdownに渡す
      event.target.value = props.bulletinBoard.status
      return
    }
    event.preventDefault()
    updateBulletinBoard(props.bulletinBoard, event.target.value)
  }

  const updateBulletinBoard = async (bulletinBoard, bulletinBoardStatusId) => {
    props.setLoadData(true)
    const params = {
      id: bulletinBoard.id,
      status: bulletinBoardStatusId
    }
    const result = await MutationHelper('updateBulletinBoard', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      await props.fetchBulletinBoard(result.id)
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
    props.setLoadData(false)
  }

  return (
    <>
      <FormLayout title="記事の状態">
        {!props.isLoading ? (
          <Dropdown
            label="状態"
            name="status"
            onChange={handleChange}
            defaultValue={
              props.bulletinBoard && props.bulletinBoard.status
                ? props.bulletinBoard.status
                : 2
            }
            items={[
              {
                value: BulletinBoardStatus.draft.code,
                label: BulletinBoardStatus.draft.value
              },
              {
                value: BulletinBoardStatus.reservation.code,
                label: BulletinBoardStatus.reservation.value
              }
            ]}
            color="primary"
            disabled={isRestrictedRole}
          />
        ) : (
          <Loading isLoading={props.isLoading} />
        )}
      </FormLayout>
      <Divider style={{ height: 5 }} />
      <FormLayout title="更新情報">
        {props.isLoading ? (
          <Loading isLoading={props.isLoading} />
        ) : (
          <>
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">更新日時</Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                {props.bulletinBoard ? (
                  <DateLabel timestamp={props.bulletinBoard.updated_at} />
                ) : null}
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">更新者</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <Typography className={classes.userName} variant="body1">
                    {props.updatedUser &&
                      getFormattedNameByStatus(
                        props.updatedUser.name,
                        props.updatedUser.status
                      )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </FormLayout>
      <Divider />
    </>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(Right)))
