import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { Cache } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import RadioButtons from 'components/common/RadioButtons'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import {
  PetApplicationCategory,
  PetApplicationState,
  PetApplicationType,
  ScreenUrl
} from 'utils/enum'
import { connect } from 'react-redux'
import {
  closeSearchModalDialog,
  savePetSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import Loading from 'components/common/Loading'
import { fromCode } from 'utils/enum.utils'
import CommonChip from 'components/common/CommonChip'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

// prettier-ignore
const buttonsData = [
  {value: PetApplicationState.all.code, label: PetApplicationState.all.value},
  {value: PetApplicationState.open.code, label: PetApplicationState.open.value},
  {value: PetApplicationState.inProgress.code, label: PetApplicationState.inProgress.value},
  {value: PetApplicationState.complete.code, label: PetApplicationState.complete.value},
];

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'application_state_id' in location.state
      ? location.state.application_state_id
      : PetApplicationState.all.code
  )
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : props.petSearchParams
  )
  const [searchConditionErrors, setSearchConditionErrors] = useState({})

  const petApplicationListCache = Cache.getItem(
    'totono-advertiser-petApplication-list'
  )
  const { data, paging } = petApplicationListCache
    ? petApplicationListCache
    : initialState
  const [petApplicationList, setPetApplicationList] = useState(data)
  const [companyGroupList, setCompanyGroupList] = useState(null)
  const [fetchedCompanyGroupList, setFetchedCompanyGroupList] = useState(null)
  const [companyUserList, setCompanyUserList] = useState(null)
  const [fetchedCompanyUserList, setFetchedCompanyUserList] = useState(null)
  const [masterData, setMasterData] = useState({
    ttyKnrPatternList: [],
    ttyKnrTantoList: [],
    ttyTnpList: []
  })
  const [pagingInfo, setPagingInfo] = useState(paging)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    searchCondition['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    searchCondition['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchCondition, {
      application_state_id: selectedStatus
    })
    if (!selectedStatus) {
      params['application_state_id'] = PetApplicationState.all.code
    }
    params['pageNumber'] = 1
    props.savePetSearchCondition(params)
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  async function getPetApplicationList({ offset, limit }) {
    var filter = { application_type_id: { eq: 6 } }
    var created_at = []
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'application_state_id') {
          filter['application_state_id'] = { eq: searchCondition[key] }
          setSelectedStatus(searchCondition[key])
          return
        }
        if (key == 'application.pet.created_at_from') {
          if ('application.pet.created_at_from' in searchCondition) {
            created_at.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('申込日(To)を指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'application.pet.created_at_to') {
          if ('application.pet.created_at_to' in searchCondition) {
            created_at.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('申込日(From)を指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'application.pet.id') {
          filter['id'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.pet.customer_id') {
          filter['customer_id'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'application.pet.TTY_NAME') {
          filter['TTY_NAME'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'application.pet.typeId') {
          filter['typeId'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.pet.categoryId') {
          filter['categoryId'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.pet.is_arranged') {
          if (searchCondition[key] == 1) {
            filter.is_arranged = { eq: true }
          } else {
            filter.is_arranged = { ne: true }
          }
        }
        if (key == 'application.pet.company_group_id') {
          if (searchCondition[key] != 0) {
            filter.company_group_id = { eq: searchCondition[key] }
          }
        }
        if (key == 'application.pet.company_user_id') {
          if (searchCondition[key] != 0) {
            filter.company_user_id = { eq: searchCondition[key] }
          }
        }
        if (key == 'application.pet.memberFlag1') {
          filter['KYK_MEMBER_FLG'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.pet.memberFlag2') {
          filter['KYK_MEMBER_FLG_2'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.pet.knrPattern') {
          filter['TTY_KNRPATTERN_ID'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.pet.knrTanto') {
          filter['TTY_TANTO_SHN_ID'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.pet.ttyTnp') {
          filter['TTY_TNP_ID'] = {
            eq: searchCondition[key]
          }
        }
      }
    })
    if (created_at.length > 0) {
      filter['created_at'] = {
        between: created_at
      }
    }
    let result = await QueryHelper(
      'getPetApplicationList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('ペット飼育申請一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setPetApplicationList(result.data, setPagingInfo(result.paging))
    }
  }

  const fetchCompanyGroupList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyGroupItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.role_id != 5 && item.status === 1) {
            const companyGroupItem = {
              value: item.id,
              label: item.name
            }
            companyGroupItems.push(companyGroupItem)
          }
        }
      }
      setCompanyGroupList(companyGroupItems)
      setFetchedCompanyGroupList(result.data)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const userMap = new Map()
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.company_user.status === 1) {
            const companyUserItem = {
              value: item.company_user.id,
              label: item.company_user.name
            }
            companyUserItems.push(companyUserItem)
            if (!userMap.has(item.company_group.id)) {
              userMap.set(item.company_group.id, [companyUserItem])
            } else {
              const companyUserItemArray = userMap.get(item.company_group.id)
              companyUserItemArray.push(companyUserItem)
            }
          }
        }
      }
      setCompanyUserList(userMap)
      setFetchedCompanyUserList(result.data)
    }
  }

  const fetchTateyaIndividualMaster = async () => {
    const result = await QueryHelper('getTateyaIndividualMaster', {})

    if (result.error) {
      props.enqueueSnackbar('建物管理データを取得できませんでした', {
        variant: 'error'
      })
    } else {
      const defaultItems = [{ value: 0, label: '' }]

      const ttyKnrPatternList = [
        ...defaultItems,
        ...(result.KnrPattern || []).map(item => ({
          value: item.TTY_KNRPATTERN_ID,
          label: item.TTY_KNRPATTERN_NAME
        }))
      ]

      const ttyKnrTantoList = [
        ...defaultItems,
        ...(result.KnrTanto || []).map(item => ({
          value: item.TTY_TANTO_SHN_ID,
          label: item.TTY_TANTO_SHN_NAME
        }))
      ]

      const ttyTnpList = [
        ...defaultItems,
        ...(result.KnrTenpo || []).map(item => ({
          value: item.TTY_TNP_ID,
          label: item.TTY_TNP_NAME
        }))
      ]

      setMasterData({
        ttyKnrPatternList,
        ttyKnrTantoList,
        ttyTnpList
      })
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {
      application_state_id: selectedStatus
    }
    for (let entry of formData.entries()) {
      if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    if (validateSearchCondition(params)) {
      return
    }
    props.savePetSearchCondition(params)
    props.closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  function validateSearchCondition(params) {
    const valid = {}

    if (
      params['application.pet.created_at_from'] &&
      !params['application.pet.created_at_to']
    ) {
      valid['application.pet.created_at_to.message'] =
        '申請日(From)が指定されてる時は申請日(To)は必須です'
    }
    if (
      !params['application.pet.created_at_from'] &&
      params['application.pet.created_at_to']
    ) {
      valid['application.pet.created_at_from.message'] =
        '申請日(To)が指定されてる時は申請日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      getPetApplicationList({
        offset,
        applicationStat: selectedStatus,
        limit: PAGE_LIMIT
      }),
      companyGroupList ? '' : fetchCompanyGroupList(0, 0),
      companyUserList ? '' : fetchCompanyUserList(0, null),
      masterData.ttyKnrPatternList.length ||
      masterData.ttyKnrTantoList.length ||
      masterData.ttyTnpList.length
        ? ''
        : fetchTateyaIndividualMaster()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.applicationPetList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, selectedStatus, searchCondition])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.applicationPetList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'application_state_id': {
        setSelectedStatus(PetApplicationState.all.code)
        delete params[target]
        break
      }
      case 'application.pet.created_at_from': {
        delete params['application.pet.created_at_to']
        delete params['application.pet.created_at_from']
        break
      }
      case 'application.pet.created_at_to': {
        delete params['application.pet.created_at_to']
        delete params['application.pet.created_at_from']
        break
      }
      case 'application.pet.company_group_id': {
        delete params[target]
        delete params['application.pet.company_user_id']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    props.savePetSearchCondition(params)
    setSearchCondition(params, setPageNumber(1))
  }

  function isContainsApplicationPetBySearchConditions() {
    const val = Object.keys(searchCondition).find(
      x =>
        x.match(/^application.pet.+/) ||
        (x === 'application_state_id' &&
          searchCondition[x] != PetApplicationState.all.code)
    )
    return val !== null && val !== undefined
  }

  const getLabelFromSearchConditions = (key, list, labelPrefix) => {
    const value = Number(searchCondition[key])
    if (value && list) {
      const item = list.find(item => item.value === value)
      return item ? `${labelPrefix}: ${item.label}` : undefined
    }
    return undefined
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsApplicationPetBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'application_state_id': {
                    if (
                      searchCondition[key] &&
                      searchCondition[key] != PetApplicationState.all.code
                    ) {
                      label =
                        '状態: ' +
                        fromCode(
                          PetApplicationState,
                          Number(searchCondition[key])
                        ).value
                    }
                    break
                  }
                  case 'application.pet.created_at_from': {
                    label = '申請日(From): ' + searchCondition[key]
                    break
                  }
                  case 'application.pet.created_at_to': {
                    label = '申請日(To): ' + searchCondition[key]
                    break
                  }
                  case 'application.pet.id': {
                    label = 'ID: ' + searchCondition[key]
                    break
                  }
                  case 'application.pet.customer_id': {
                    label = '入居者名: ' + searchCondition[key]
                    break
                  }
                  case 'application.pet.TTY_NAME': {
                    label = '物件名: ' + searchCondition[key]
                    break
                  }
                  case 'application.pet.typeId': {
                    label =
                      '申請区分: ' +
                      fromCode(PetApplicationType, searchCondition[key]).value
                    break
                  }
                  case 'application.pet.categoryId': {
                    label =
                      '種類: ' +
                      fromCode(PetApplicationCategory, searchCondition[key])
                        .value
                    break
                  }
                  case 'application.pet.is_arranged': {
                    const content =
                      searchCondition[key] == 1
                        ? '案件手配のみ表示'
                        : '案件手配を含めない'
                    label = content
                    break
                  }
                  case 'application.pet.company_group_id': {
                    if (fetchedCompanyGroupList) {
                      for (let companyGroup of fetchedCompanyGroupList) {
                        if (companyGroup.id === Number(searchCondition[key])) {
                          const formattedName = getFormattedNameByStatus(
                            companyGroup.name,
                            companyGroup.status
                          )
                          label = '担当グループ: ' + formattedName
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'application.pet.company_user_id': {
                    if (fetchedCompanyUserList) {
                      for (let companyUser of fetchedCompanyUserList) {
                        if (
                          companyUser.company_user.id ===
                          Number(searchCondition[key])
                        ) {
                          const formattedName = getFormattedNameByStatus(
                            companyUser.company_user.name,
                            companyUser.company_user.status
                          )
                          label = '担当者: ' + formattedName
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'application.pet.memberFlag1': {
                    if (searchCondition[key]) {
                      label = '会員フラグ1: ON'
                    }
                    break
                  }
                  case 'application.pet.memberFlag2': {
                    if (searchCondition[key]) {
                      label = '会員フラグ2: ON'
                    }
                    break
                  }
                  case 'application.pet.knrPattern': {
                    const { ttyKnrPatternList } = masterData
                    label = getLabelFromSearchConditions(
                      key,
                      ttyKnrPatternList,
                      '管理パターン'
                    )
                    break
                  }

                  case 'application.pet.knrTanto': {
                    const { ttyKnrTantoList } = masterData
                    label = getLabelFromSearchConditions(
                      key,
                      ttyKnrTantoList,
                      '管理担当者'
                    )
                    break
                  }

                  case 'application.pet.ttyTnp': {
                    const { ttyTnpList } = masterData
                    label = getLabelFromSearchConditions(
                      key,
                      ttyTnpList,
                      '建物取扱店舗'
                    )
                    break
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips} />
        <Grid item xs={5} className={classes.tableTips}>
          <RadioButtons
            current={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={pagingInfo.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && petApplicationList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              petApplicationList={petApplicationList}
              searchCondition={searchCondition}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={pagingInfo.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        // title="物件の検索"
        // description="※削除すると、各所から表示されなくなります。"
        content={
          <SearchFormContent
            companyGroupList={companyGroupList}
            companyUserList={companyUserList}
            fetchedCompanyGroupList={fetchedCompanyGroupList}
            fetchedCompanyUserList={fetchedCompanyUserList}
            masterData={masterData}
            searchCondition={searchCondition}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.applicationPetList}
            searchConditions={searchCondition}
            stateName={'application_state_id'}
            setSelectedStatus={setSelectedStatus}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    petSearchParams: state.saveSearchCondition.pet
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    savePetSearchCondition: parameter => {
      dispatch(savePetSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
