import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import RadioButtons from 'components/common/RadioButtons'
import { PAGE_LIMIT } from 'utils/const'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  closeModalDialog,
  closeSearchModalDialog,
  handleClear,
  openModalDialog,
  saveSettingBuildingSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import FormModalDialog from './FormModalDialog'
import FormContent from './FormContent'
import FormFooter from './FormFooter'
import { withSnackbar } from 'notistack'
import { BuildingStatusEnum, ScreenUrl } from 'utils/enum'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import Loading from 'components/common/Loading'
import CommonChip from 'components/common/CommonChip'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

const buttonsData = [
  { value: -1, label: 'すべて' },
  { value: 1, label: '有効' },
  { value: 0, label: '無効' }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { pageId } = useParams()

  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'is_management' in location.state
      ? location.state.is_management
      : BuildingStatusEnum.all
  )
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined
      ? location.state
      : props.settingBuildingSearchParams
  )

  const [buildingList, setBuildingList] = useState(initialState)
  const [building, setBuilding] = useState({})
  const [knrDivList, setKnrDivList] = useState({})
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [tysList, setTysList] = useState(null)
  const [prefectureList, setPrefectureList] = useState(null)
  const [cityList, setCityList] = useState(null)
  const [masterData, setMasterData] = useState({
    ttyKnrPatternList: [],
    ttyKnrTantoList: [],
    ttyTnpList: []
  })

  console.log(masterData)

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    setPageNumber(newPage)
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchCondition, {
      is_management: selectedStatus
    })
    if (selectedStatus == BuildingStatusEnum.all) {
      params['is_management'] = BuildingStatusEnum.all
    }
    props.saveSettingBuildingSearchCondition(params)
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  async function fetchBuildingList(offset, limit) {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (key == 'is_management') {
        if (searchCondition[key] != BuildingStatusEnum.all) {
          filter['is_management'] = {
            eq: searchCondition[key]
          }
          setSelectedStatus(searchCondition[key])
        }
      }
      if (searchCondition[key]) {
        if (key == 'setting.building.TTY_NAME') {
          filter['TTY_NAME'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_ID') {
          filter['TTY_ID'] = {
            eq: Number(searchCondition[key])
          }
        }
        if (key == 'setting.building.TTY_TYS_CD') {
          filter['TTY_TYS_CD'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_PRF_CD') {
          filter['TTY_PRF_CD'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_CTY_CD') {
          filter['TTY_CTY_CD'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_KNR_DIV') {
          filter['TTY_KNR_DIV'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_KNRKEITAI_DIV') {
          filter['TTY_KNRKEITAI_DIV'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_KNRPATTERN_ID') {
          filter['TTY_KNRPATTERN_ID'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_KNR_TANTO_SHN_ID') {
          filter['TTY_TANTO_SHN_ID'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'setting.building.TTY_TNP_ID') {
          filter['TTY_TNP_ID'] = {
            eq: searchCondition[key]
          }
        }
      }
    })
    let result = await QueryHelper(
      'getBuildingList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('物件一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuildingList(result)
      await Promise.all([
        fetchMstTateyaKnrDivList(),
        fetchMstTateyaKnrkeitaiDivList()
      ])
    }
  }

  const fetchMstTateyaKnrDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKNRDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKNRDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knr_cd] = item.knr_name
      })
      setKnrDivList(l)
    }
  }

  const fetchMstTateyaKnrkeitaiDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKnrkeitaiDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knrkeitai_cd] = item.knrkeitai_name
      })
      setKnrkeitaiDivList(l)
    }
  }

  async function postData(event) {
    event.preventDefault()
    if (Object.keys(props.parameters).length > 0) {
      let params = Object.assign({}, props.parameters)
      params['TTY_ISP_TTY_ID'] = building.mst_tateya.TTY_ISP_TTY_ID
      const result = await MutationHelper('updateBuildingManagement', {
        input: params
      })
      if (result.error) {
        props.enqueueSnackbar('アプリ利用を更新できませんでした', {
          variant: 'error'
        })
      } else {
        const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
        fetchBuildingList(offset, PAGE_LIMIT)
        props.handleClear()
        props.closeModalDialog()
        props.enqueueSnackbar('アプリ利用を更新しました', {
          variant: 'success'
        })
      }
    } else {
      props.enqueueSnackbar('変更がありません', {
        variant: 'warning'
      })
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {
      is_management: selectedStatus
    }
    for (let entry of formData.entries()) {
      if (entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    props.saveSettingBuildingSearchCondition(params)
    props.closeSearchModalDialog()
    setSearchCondition(
      params,
      setSelectedStatus(BuildingStatusEnum.all),
      setPageNumber(1)
    )
  }

  async function fetchTysList() {
    await API.graphql(graphqlOperation(queries.getMSTTateyaTysList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaTysList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.tys_cd, label: item.tys_name })
          })
          setTysList(l)
        } else {
          props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchPrefectureList() {
    await API.graphql(graphqlOperation(queries.getPrefectureList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getPrefectureList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.prf_cd, label: item.name })
          })
          setPrefectureList(l)
        } else {
          props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchCityList(prfCd) {
    await API.graphql(
      graphqlOperation(queries.getCityList, {
        filter: { prf_cd: { eq: prfCd } }
      })
    )
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getCityList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.cty_cd, label: item.name })
          })
          setCityList(l)
        } else {
          props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  const fetchTateyaIndividualMaster = async () => {
    const result = await QueryHelper('getTateyaIndividualMaster', {})

    if (result.error) {
      props.enqueueSnackbar('建物管理データを取得できませんでした', {
        variant: 'error'
      })
    } else {
      const defaultItems = [{ value: '', label: '' }]

      const ttyKnrPatternList = [
        ...defaultItems,
        ...(result.KnrPattern || []).map(item => ({
          value: item.TTY_KNRPATTERN_ID,
          label: item.TTY_KNRPATTERN_NAME
        }))
      ]

      const ttyKnrTantoList = [
        ...defaultItems,
        ...(result.KnrTanto || []).map(item => ({
          value: item.TTY_TANTO_SHN_ID,
          label: item.TTY_TANTO_SHN_NAME
        }))
      ]

      const ttyTnpList = [
        ...defaultItems,
        ...(result.KnrTenpo || []).map(item => ({
          value: item.TTY_TNP_ID,
          label: item.TTY_TNP_NAME
        }))
      ]

      setMasterData({
        ttyKnrPatternList,
        ttyKnrTantoList,
        ttyTnpList
      })
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchBuildingList(offset, PAGE_LIMIT),
      tysList ? '' : fetchTysList(),
      prefectureList ? '' : fetchPrefectureList(),
      searchCondition && 'setting.building.TTY_PRF_CD' in searchCondition
        ? fetchCityList(searchCondition['setting.building.TTY_PRF_CD'])
        : '',
      masterData.ttyKnrPatternList.length ||
      masterData.ttyKnrTantoList.length ||
      masterData.ttyTnpList.length
        ? ''
        : fetchTateyaIndividualMaster()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.settingBuildingList}${pageNumber}`,
      state: searchCondition
    })
    props.saveSettingBuildingSearchCondition(searchCondition)
    fetchData(offset)
  }, [pageNumber, selectedStatus, setBuildingList, searchCondition])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.settingBuildingList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'is_management': {
        setSelectedStatus(BuildingStatusEnum.all)
        delete params[target]
        break
      }
      case 'setting.building.TTY_PRF_CD': {
        delete params[target]
        delete params['setting.building.TTY_CTY_CD']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    props.saveSettingBuildingSearchCondition(params)
    setSearchCondition(params, setPageNumber(1))
  }

  function isContainsSettingBuildingBySearchConditions() {
    const val = Object.keys(searchCondition).find(
      x =>
        x.match(/^setting.building.+/) ||
        (x === 'is_management' && searchCondition[x] != BuildingStatusEnum.all)
    )
    return val !== null && val !== undefined
  }

  const getLabelFromSearchConditions = (key, list, labelPrefix) => {
    const value = Number(searchCondition[key])
    if (value && list) {
      const item = list.find(item => item.value === value)
      return item ? `${labelPrefix}: ${item.label}` : undefined
    }
    return undefined
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsSettingBuildingBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'is_management': {
                    if (
                      selectedStatus != BuildingStatusEnum.all &&
                      selectedStatus != undefined
                    ) {
                      if (selectedStatus == BuildingStatusEnum.active) {
                        label = '状態: 有効'
                      } else if (
                        selectedStatus == BuildingStatusEnum.inactive
                      ) {
                        label = '状態: 無効'
                      }
                    }
                    break
                  }
                  case 'setting.building.TTY_NAME': {
                    label = '物件名: ' + searchCondition[key]
                    break
                  }
                  case 'setting.building.TTY_ID': {
                    label = 'ID: ' + searchCondition[key]
                    break
                  }
                  case 'setting.building.TTY_TYS_CD': {
                    if (tysList && tysList.length > 0) {
                      for (let tys of tysList) {
                        if (tys.value === Number(searchCondition[key])) {
                          label = '種別: ' + tys.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'setting.building.TTY_PRF_CD': {
                    if (prefectureList && prefectureList.length > 0) {
                      for (let pref of prefectureList) {
                        if (pref.value === Number(searchCondition[key])) {
                          label = '都道府県: ' + pref.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'setting.building.TTY_CTY_CD': {
                    if (cityList && cityList.length > 0) {
                      for (let city of cityList) {
                        if (city.value === Number(searchCondition[key])) {
                          label = '市区町村: ' + city.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'setting.building.TTY_KNR_DIV': {
                    label =
                      '管理区分: ' + knrDivList[Number(searchCondition[key])]
                    break
                  }
                  case 'setting.building.TTY_KNRKEITAI_DIV': {
                    label =
                      '管理形態: ' +
                      knrkeitaiDivList[Number(searchCondition[key])]
                    break
                  }
                  case 'setting.building.TTY_KNRPATTERN_ID': {
                    const { ttyKnrPatternList } = masterData
                    label = getLabelFromSearchConditions(
                      key,
                      ttyKnrPatternList,
                      '管理パターン'
                    )
                    break
                  }

                  case 'setting.building.TTY_KNR_TANTO_SHN_ID': {
                    const { ttyKnrTantoList } = masterData
                    label = getLabelFromSearchConditions(
                      key,
                      ttyKnrTantoList,
                      '管理担当者'
                    )
                    break
                  }

                  case 'setting.building.TTY_TNP_ID': {
                    const { ttyTnpList } = masterData
                    label = getLabelFromSearchConditions(
                      key,
                      ttyTnpList,
                      '建物取扱店舗'
                    )
                    break
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips}></Grid>
        <Grid item xs={5} className={classes.tableTips}>
          <RadioButtons
            current={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={buildingList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && buildingList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              buildingList={buildingList.data}
              setBuilding={setBuilding}
              knrDivList={knrDivList}
              knrkeitaiDivList={knrkeitaiDivList}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={buildingList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <FormModalDialog
        title="アプリ利用変更"
        description="※無効にすると、物件に住むすべての人がアプリを使用できなくなります。"
        content={<FormContent building={building} />}
        footer={<FormFooter />}
        postData={postData}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            searchCondition={searchCondition}
            masterData={masterData}
          />
        }
        footer={<SearchModalDialogFooter />}
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.settingBuildingList}
            searchConditions={searchCondition}
            stateName={'is_management'}
            setSelectedStatus={setSelectedStatus}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    settingBuildingSearchParams: state.saveSearchCondition.settingBuilding
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalDialog: () => {
      dispatch(openModalDialog())
    },
    handleClear: () => {
      dispatch(handleClear())
    },
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    saveSettingBuildingSearchCondition: parameter => {
      dispatch(saveSettingBuildingSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
