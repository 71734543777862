import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import FormLayout from 'components/common/FormLayout'
import RowWithPdf from 'components/common/RowWithPdf'
import { Auth, Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%',
    marginTop: 10
  }
}))

function BuildingFileForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const {
    attachment,
    maxFileLength,
    title,
    subTitle,
    setTitleError,
    titleError
  } = props
  const MAXIMUM_FILE_LENGTH = maxFileLength || 1

  const [files, setFiles] = useState([])
  const [params, setParams] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const attachmentType = {
    key: 'pdfs',
    name: 'PDF',
    accept: 'application/pdf'
  }

  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = () => {
      const object = {
        status: 1,
        attachment_type_id: 3,
        mime_type: file.type,
        filename: file.name,
        body: reader.result
      }

      let copy1 = []
      Object.assign(copy1, files)
      copy1.push(object)
      let copy2 = []
      Object.assign(copy2, params)
      copy2.push(file)

      setFiles([...copy1])
      setParams([...copy2])
    }
    reader.readAsDataURL(file)
  }

  const handleChangeTitle = (e, index) => {
    if (e.target.value.length === 0) {
      setTitleError(prevState => {
        const newState = [...prevState]
        newState[index] = '入力してください'
        return newState
      })
    } else if (e.target.value.length > 100) {
      setTitleError(prevState => {
        const newState = [...prevState]
        newState[index] = '100文字以内で入力してください'
        return newState
      })
    } else {
      setTitleError(prevState => {
        const newState = [...prevState]
        newState[index] = ''
        return newState
      })
    }
    let copy1 = []
    Object.assign(copy1, files)
    copy1[index].title = e.target.value
    let copy2 = []
    Object.assign(copy2, params)
    copy2[index].title = e.target.value

    setFiles([...copy1])
    setParams([...copy2])

    const splicedParams = [
      ...copy2.filter(
        item =>
          item instanceof File ||
          (Object.keys(item).indexOf('status') !== -1 && item.status === 3) ||
          Object.keys(item).indexOf('title') !== -1
      )
    ]

    props.handleChange({ [attachmentType.key]: [...splicedParams] })
  }

  const handleClick = async (attachment, event) => {
    event.preventDefault()
    if (attachment && attachment.key) {
      Auth.currentCredentials().then(async () => {
        const key = attachment.key
        await Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            fetch(result).then(response => {
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = attachment.filename
                a.click()
              })
            })
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }

  const handleDelete = index => {
    let copy1 = []
    Object.assign(copy1, files)
    let target1 = copy1[index]
    target1.status = 3
    copy1.splice(index, 1, target1)
    let copy2 = []
    Object.assign(copy2, params)
    let target2 = copy2[index]
    if (target2 instanceof File) {
      copy2.splice(index, 1, {})
    } else {
      target2.status = 3
      copy2.splice(index, 1, target2)
    }

    setFiles([...copy1])
    setParams([...copy2])

    const splicedParams = [
      ...copy2.filter(
        item =>
          item instanceof File ||
          (Object.keys(item).indexOf('status') !== -1 && item.status === 3)
      )
    ]
    props.handleChange({ [attachmentType.key]: [...splicedParams] })
  }

  useEffect(() => {
    if (attachment && attachment.length > 0) {
      ;(async () => {
        await fetchFile(attachment)
      })()
    }
    setIsLoading(false)
  }, [attachment, setFiles, setParams])

  const fetchFile = async attachments => {
    setFiles([])
    setParams([])
    if (attachments.length === 0) {
      return
    }
    const objects = []
    for (let attachment of attachments) {
      const key = attachment.key
      const result = await getStorage(key, attachment.company_id)
      const object = Object.assign({}, attachment, { body: result })
      objects.push(object)
    }
    setFiles(objects)
    setParams(objects)
  }

  const getStorage = async (key, companyId) => {
    return Storage.get(key, {
      level: 'protected',
      identityId: companyId,
      expires: 60
    })
  }

  return (
    <FormLayout title={title} subTitle={subTitle}>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {files.length > 0 &&
            files.map(({ status, filename, title }, index) => {
              return status === 1 ? (
                <Grid
                  container
                  className={classes.fileForm}
                  alignItems="center"
                >
                  <Grid item xs={5}>
                    <RowWithPdf
                      key={index}
                      title={filename}
                      handleDelete={() => handleDelete(index)}
                      style={{ marginBottom: 10 }}
                      attachment={files[index]}
                      handleClick={handleClick}
                      disabledIcon={isRestrictedRole}
                    ></RowWithPdf>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={6} className={classes.textField}>
                    <TextField
                      required
                      fullWidth
                      label="建物ドキュメント名"
                      variant="filled"
                      defaultValue={title}
                      onChange={e => handleChangeTitle(e, index)}
                      size="small"
                      error={titleError && titleError[index] ? true : false}
                      helperText={
                        titleError && titleError[index]
                          ? titleError[index]
                          : null
                      }
                      disabled={isRestrictedRole}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )
            })}
          {files.filter(item => item.status === 1).length <
            MAXIMUM_FILE_LENGTH && (
            <Grid container>
              <Grid item xs={12}>
                <input
                  accept={attachmentType.accept}
                  className={classes.input}
                  id={`contained-button-${attachmentType.key}`}
                  type="file"
                  onChange={handleChange}
                  disabled={isRestrictedRole}
                />
              </Grid>
              <label
                htmlFor={`contained-button-${attachmentType.key}`}
                className={classes.labelButton}
              >
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                  disabled={isRestrictedRole}
                >
                  {`${attachmentType.name}を追加`}
                </Button>
              </label>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingFileForm)
