import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import ReportIcon from '@material-ui/icons/Report'
import { useFormContext } from 'react-hook-form'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '10px 0'
  },
  useSettings: {
    marginTop: '10px'
  },
  useSettingsSwitch: {
    '& .Mui-checked': {
      color: theme.palette.c_blue.main
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.c_blue.main
    }
  },
  subMessage: {
    fontSize: '0.8rem',
    color: '#ff0000'
  },
  urlMessage: {
    fontSize: '0.8rem'
  }
}))

const ApplicationOptionsUseSettings = props => {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()
  const { title } = props
  const { register, setValue, watch, formState } = useFormContext()

  const handleChange = event => {
    setValue(event.target.name, event.target.value)
  }

  const handleChangeSwitch = event => {
    if (event.target.checked === true) {
      if (
        !window.confirm(
          `受付機能をONにすると、totonoで${title}申請を受付します。\nONにしますか？`
        )
      ) {
        return
      }
    } else {
      if (
        !window.confirm(
          `受付機能をOFFにすると、totonoで${title}申請の受付を停止します。\nOFFにしますか？`
        )
      ) {
        return
      }
    }
    setValue(event.target.name, event.target.checked)
  }

  const handleChangeCheck = event => {
    if (event.target.checked === true) {
      if (
        !window.confirm(
          `リンクの設定をONにすると、totonoで${title}申請の受付を停止します。\n※参照先URLに記載した設定がアプリに反映されます。\nONにしますか？`
        )
      ) {
        return
      }
    } else {
      if (
        !window.confirm(
          `リンクの設定をOFFにすると、totonoで${title}申請を受付します。\nOFFにしますか？`
        )
      ) {
        return
      }
    }
    setValue(event.target.name, event.target.checked)
  }

  return (
    <FormLayout title={`${title}申請受付の利用設定`}>
      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item xs={4}>
          <FormControlLabel
            className={classes.useSettings}
            control={
              <Switch
                className={classes.useSettingsSwitch}
                checked={watch('is_enabled')}
                onClick={handleChangeSwitch}
                name="is_enabled"
                disabled={!isUnrestrictedRole}
              />
            }
            label={`totonoで${title}申請受付する`}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.subMessage}>
            <ReportIcon color="secondary" className={classes.subMessage} />
            {watch('is_enabled') === true
              ? watch('is_checked_url') === true
                ? `現在、totonoで${title}申請を受付できない状態ですが、指定したURLへ遷移し${title}申請できる状態です。`
                : `現在、totonoで${title}申請を受付できる状態です。`
              : `現在、totonoで${title}申請を受付できない状態です。`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                style={{
                  color: '#2196f3'
                }}
                disabled={!watch('is_enabled') || !isUnrestrictedRole}
                name="is_checked_url"
                checked={watch('is_checked_url')}
                onClick={handleChangeCheck}
              />
            }
            label={
              <Typography className={classes.urlMessage}>
                {'WEBサイトリンクを設定する'}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            {...register('application_url')}
            disabled={!watch('is_enabled') || !watch('is_checked_url')}
            error={Boolean(formState.errors.application_url)}
            helperText={
              formState.errors.application_url &&
              formState.errors.application_url.message
            }
            id="application_url"
            name="application_url"
            label={
              <Typography className={classes.urlMessage}>
                {'WEBサイトへリンクさせる場合はこちらにURLを入力してください'}
              </Typography>
            }
            fullWidth
            variant="filled"
            defaultValue={watch('application_url')}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ApplicationOptionsUseSettings
