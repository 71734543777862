import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import { openSearchModalDialog, changeSearchCondition } from 'actions/Actions'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: '2px 4px',
    marginTop: '12px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function SearchTextField({ text, ...props }) {
  const classes = useStyles()

  const [inputValue, setInputValue] = useState('')

  const handleClick = async () => {
    props.openSearchModalDialog()
  }

  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={text}
        inputProps={{ 'aria-label': { text } }}
        onChange={event => {
          setInputValue(event.target.value)
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault()
            props.changeSearchCondition({
              'management.room.hya_room_no': inputValue
            })
          }
        }}
        value={inputValue}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="directions"
        onClick={handleClick}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Paper>
  )
}

const mapStateToProps = state => {
  return {
    conditions: state.searchCondition
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openSearchModalDialog: () => {
      dispatch(openSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchTextField)
