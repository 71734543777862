import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { handleClear, setLoadData } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import Left from './Left'
import Right from './Right'
import { withSnackbar } from 'notistack'
import { QueryHelper, MutationHelper } from 'utils/api.utils'
import { Auth, Storage } from 'aws-amplify'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import uuid from 'uuid/v4'
import FooterButtons from 'components/common/FooterButtons'
import { NoticeMessages } from 'utils/noticeMessages'
import { useOwnerFunctionEnabled } from 'hooks/useOwnerFunctionEnabled'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    margin: '32px'
  },
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: 5, page: 1 }
}

function DetailScreen(props) {
  const classes = useStyles()

  const { id } = useParams()
  const { isOwnerFunctionEnabled } = useOwnerFunctionEnabled()
  const { role12 } = useRole()

  const [building, setBuilding] = useState({})
  const [knrDivList, setKnrDivList] = useState({})
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState({})
  const [buildingOwnerList, setBuildingOwnerList] = useState(initialState)
  const [buildingAttachments, setBuildingAttachments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [titleError, setTitleError] = useState([])

  const fetchBuilding = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    const result = await QueryHelper('getBuilding', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`建物を取得できませんでした。`)
    } else {
      setBuilding(result)
    }
  }

  const fetchMstTateyaKnrDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKNRDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKNRDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knr_cd] = item.knr_name
      })
      setKnrDivList(l)
    }
  }

  const fetchMstTateyaKnrkeitaiDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKnrkeitaiDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knrkeitai_cd] = item.knrkeitai_name
      })
      setKnrkeitaiDivList(l)
    }
  }

  const fetchBuildingAttachments = async id => {
    const result = await QueryHelper('getBuildingAttachments', {
      buildingId: id
    })
    if (result.error) {
      props.enqueueSnackbar(`建物ドキュメント情報を取得できませんでした。`)
    } else {
      setBuildingAttachments(result.attachments)
    }
  }

  const fetchOwnerList = async buildingId => {
    const params = {
      building_id: {
        eq: buildingId
      }
    }

    const result = await QueryHelper('getOwnerListByBuilding', {
      filter: params
    })
    if (result.error) {
      props.enqueueSnackbar('オーナー情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuildingOwnerList(result)
    }
  }

  const fetchData = async buildingId => {
    setIsLoading(true)
    await Promise.all([
      fetchBuilding(buildingId),
      fetchMstTateyaKnrDivList(),
      fetchMstTateyaKnrkeitaiDivList(),
      fetchBuildingAttachments(buildingId)
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (id !== undefined && isOwnerFunctionEnabled && role12()) {
      fetchOwnerList(id)
    }
  }, [isOwnerFunctionEnabled])

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id)
    }
  }, [setBuilding])

  const handleSubmit = async event => {
    event.preventDefault()
    const { pdfs, remark } = props.parameters
    if ((!pdfs && !remark) || (pdfs && pdfs.length === 0)) {
      props.enqueueSnackbar(NoticeMessages.NoChange, {
        variant: 'warning'
      })
      return
    }

    putBuilding(pdfs, remark)
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    return result
  }

  const putBuilding = async (pdfs, remark) => {
    props.setLoadData(true)
    let attachments = []
    if (pdfs) {
      const creds = await Auth.currentCredentials()
      for (let file of pdfs) {
        if ('id' in file) {
          attachments.push(file)
          continue
        }
        const result = await fileupload(creds, 'pdfs', file)
        attachments.push({
          attachment_type_id: 3,
          mime_type: file.type,
          filename: file.name,
          key: result.key,
          title: file.title
        })
      }
    }
    const params = {
      id: id,
      attachments: attachments,
      remark: remark
    }
    const result = await MutationHelper('updateBuilding', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('建物詳細を更新できませんでした。', {
        variant: 'error'
      })
      props.setLoadData(false)
    } else {
      props.handleClear()
      props.enqueueSnackbar('建物詳細を更新しました。', {
        variant: 'success'
      })
      fetchData(id)
      props.setLoadData(false)
    }
  }

  const handleCancel = async event => {
    event.preventDefault()
    props.handleClear()
    if (!id) {
      setBuilding({})
    } else {
      fetchData(id)
    }
    setTitleError([])
  }

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.leftBody}>
            <Left
              isLoading={isLoading}
              building={building}
              knrDivList={knrDivList}
              knrkeitaiDivList={knrkeitaiDivList}
              buildingAttachments={buildingAttachments}
              setTitleError={setTitleError}
              titleError={titleError}
              buildingOwnerList={buildingOwnerList}
              fetchOwnerList={fetchOwnerList}
            />
          </div>
          <div style={{ backgroundColor: '#fff' }}>
            <FooterButtons handleCancel={handleCancel} />
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.rightBody}>
            <Right
              isLoading={isLoading}
              building={building}
              buildingOwnerList={buildingOwnerList}
            />
          </div>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DetailScreen))
