import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'
import Typography from '@material-ui/core/Typography'
import { ContactTimeType, ContactWayType } from 'utils/enum'
import Divider from '@material-ui/core/Divider'
import { ErrorMessages } from 'utils/errorMessages'
import { Regexes } from 'utils/regexes'

const useStyles = makeStyles(theme => ({
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '14px'
    }
  },
  typography: {
    marginLeft: '12px',
    height: '18px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#72809d',
    fontSize: '12px',
    lineHeight: '18px'
  },
  switchButton: {
    '& .Mui-checked': {
      color: theme.palette.c_blue.main
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.c_blue.main
    }
  },
  divider: {
    marginBottom: '10px'
  }
}))

const ContactDetails = props => {
  const classes = useStyles()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const chatTitle = 'chat_title'
  const url = 'url'
  const urlText = 'url_text'
  const phoneNumber = 'phone_number'
  const thirdElementIndex = 2
  const {
    isLoading,
    company,
    setEnableForm,
    handleChange,
    setInputFormErrors,
    inputFormErrors
  } = props

  const contactTimeList = [
    { value: 0, label: '' },
    {
      value: ContactTimeType.daytime.code,
      label: ContactTimeType.daytime.value
    },
    {
      value: ContactTimeType.nighttime.code,
      label: ContactTimeType.nighttime.value
    },
    {
      value: ContactTimeType.emergency.code,
      label: ContactTimeType.emergency.value
    },
    {
      value: ContactTimeType.contact.code,
      label: ContactTimeType.contact.value
    },
    {
      value: ContactTimeType.other.code,
      label: ContactTimeType.other.value
    }
  ]

  const contactWayList = [
    { value: 0, label: '' },
    { value: ContactWayType.chat.code, label: ContactWayType.chat.value },
    { value: ContactWayType.web.code, label: ContactWayType.web.value },
    { value: ContactWayType.phone.code, label: ContactWayType.phone.value }
  ]

  const [values, setValues] = useState([])

  const toCircledNumber = number => {
    const circledNumbers = ['①', '②', '③']
    return circledNumbers[number] || number
  }

  const checkErr = objects => {
    const hasError = objects.some(
      obj =>
        obj &&
        (obj.is_enabled_err ||
          obj.contact_label_id_err ||
          obj.title_err ||
          obj.contact_type_id_err ||
          obj.chat_title_err ||
          obj.url_err ||
          obj.phone_number_err ||
          obj.reception_start_time_err ||
          obj.reception_end_time_err)
    )
    if (hasError) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const isUrl = str => {
    const reg = new RegExp(Regexes.Url)
    return reg.test(str)
  }

  const isNumber = str => {
    var reg = new RegExp(Regexes.PhoneNumber)
    return reg.test(str)
  }

  const updateValues = (values, index, name, value) => {
    const newValues = [...values]
    const updatedValues = {
      ...newValues[index],
      [name]: value
    }

    if (name !== 'description') {
      Object.keys(updatedValues).forEach(key => {
        if (
          key !== 'description' &&
          (updatedValues[key] === '' || updatedValues[key] === 0)
        ) {
          delete updatedValues[key]
        }
      })
    }
    newValues[index] = updatedValues

    return newValues
  }

  const deleteProperties = (updatedValues, contactTypeId) => {
    const propertiesToDelete = []
    switch (contactTypeId) {
      case ContactWayType.chat.code:
        propertiesToDelete.push(phoneNumber, url, urlText)
        break
      case ContactWayType.phone.code:
        propertiesToDelete.push(chatTitle, url, urlText)
        break
      case ContactWayType.web.code:
        propertiesToDelete.push(chatTitle, phoneNumber)
        break
    }

    propertiesToDelete.forEach(prop => {
      delete updatedValues[prop]
    })

    return updatedValues
  }

  const validateData = data => {
    return data.map(item => {
      if (Object.keys(item).length == 0) {
        return {}
      }

      const requiredProperties = [
        'contact_label_id',
        'title',
        'contact_type_id'
      ]
      switch (item.contact_type_id) {
        case ContactWayType.chat.code:
          requiredProperties.push(chatTitle)
          break
        case ContactWayType.phone.code:
          requiredProperties.push(phoneNumber)
          break
        case ContactWayType.web.code:
          requiredProperties.push(url)
          break
      }
      if (
        !item.all_day_reception &&
        (!item.reception_start_time || !item.reception_end_time)
      ) {
        requiredProperties.push('reception_start_time', 'reception_end_time')
      }

      const missingProperties = requiredProperties.filter(
        prop =>
          !(prop in item) ||
          item[prop] === '' ||
          item[prop] === 0 ||
          item[prop] === null
      )
      const errorObject = {}

      missingProperties.forEach(prop => {
        errorObject[`${prop}_err`] = ErrorMessages.Required
      })

      if (item.url && !isUrl(item.url)) {
        errorObject['url_err'] = ErrorMessages.UrlError
      } else if (item.phone_number && !isNumber(item.phone_number)) {
        errorObject['phone_number_err'] = ErrorMessages.PhoneNumberInvalid
      }

      return errorObject
    })
  }

  const handleInputChange = (event, index) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    const newValues = updateValues(values, index, name, value)

    const updatedValues = newValues[index]

    deleteProperties(updatedValues, updatedValues.contact_type_id)

    const validData = newValues.map(item => {
      const newObj = {}
      Object.keys(item).forEach(key => {
        if (item[key] !== '' && item[key] !== 0 && item[key] !== false) {
          newObj[key] = item[key]
        }
      })

      return newObj
    })

    const errors = validateData(validData, ErrorMessages)

    setInputFormErrors(errors)
    checkErr(errors)

    setValues(newValues)
    handleChange({ contact_informations: newValues })
  }

  useEffect(() => {
    if (company) {
      const contactSectionValues = company.contact_information
        ? company.contact_information.map(contact => ({
            id: contact.id,
            is_enabled: contact.is_enabled,
            contact_label_id: contact.contact_label_id,
            title: contact.title,
            contact_type_id: contact.contact_type_id,
            chat_title: contact.chat_title,
            url: contact.url,
            url_text: contact.url_text,
            phone_number: contact.phone_number,
            reception_start_time: contact.reception_start_time,
            reception_end_time: contact.reception_end_time,
            all_day_reception: contact.all_day_reception,
            description: contact.description
          }))
        : []

      const emptyFormsCount = 3 - contactSectionValues.length
      for (let i = 0; i < emptyFormsCount; i++) {
        contactSectionValues.push({})
      }

      setValues(contactSectionValues)
    }
  }, [company, setValues])

  return (
    <FormLayout title="アプリに表示するお問合せ先設定">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {company && (
            <>
              {values.map((values, index) => (
                <React.Fragment key={index}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.section}
                    spacing={3}
                  >
                    <Typography className={classes.title}>
                      {`お問い合せ先詳細${toCircledNumber(index)}`}
                    </Typography>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className={classes.useSettings}
                        control={
                          <Switch
                            className={classes.switchButton}
                            checked={values.is_enabled || false}
                            onClick={event => handleInputChange(event, index)}
                            name="is_enabled"
                            disabled={!isUnrestrictedRole}
                          />
                        }
                        label="表示/非表示"
                        labelPlacement="start"
                        style={{ marginLeft: 0 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Dropdown
                        hasError={
                          inputFormErrors[index] &&
                          inputFormErrors[index].contact_label_id_err &&
                          inputFormErrors[index].contact_label_id_err !== ''
                            ? true
                            : false
                        }
                        errorMessage={
                          inputFormErrors[index]
                            ? inputFormErrors[index].contact_label_id_err
                            : ''
                        }
                        name="contact_label_id"
                        label="表示ラベル設定"
                        onChange={event => handleInputChange(event, index)}
                        defaultValue={values.contact_label_id}
                        items={contactTimeList}
                        disabled={!isUnrestrictedRole}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        error={
                          inputFormErrors[index] &&
                          inputFormErrors[index].title_err &&
                          inputFormErrors[index].title_err !== ''
                            ? true
                            : false
                        }
                        helperText={
                          inputFormErrors[index]
                            ? inputFormErrors[index].title_err
                            : ''
                        }
                        id="title"
                        name="title"
                        label="連絡先名"
                        fullWidth
                        variant="filled"
                        defaultValue={values.title}
                        onChange={event => handleInputChange(event, index)}
                        disabled={!isUnrestrictedRole}
                        inputProps={{ maxLength: 50 }}
                      />
                      <Typography className={classes.typography}>
                        50文字以内
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Dropdown
                        hasError={
                          inputFormErrors[index] &&
                          inputFormErrors[index].contact_type_id_err &&
                          inputFormErrors[index].contact_type_id_err !== ''
                            ? true
                            : false
                        }
                        errorMessage={
                          inputFormErrors[index]
                            ? inputFormErrors[index].contact_type_id_err
                            : ''
                        }
                        name="contact_type_id"
                        label="連絡種別"
                        onChange={event => handleInputChange(event, index)}
                        defaultValue={values.contact_type_id}
                        items={contactWayList}
                        disabled={!isUnrestrictedRole}
                      />
                    </Grid>
                    {(values.contact_type_id === 0 ||
                      !values.contact_type_id) && <Grid item xs={6} />}
                    {values.contact_type_id === ContactWayType.chat.code && (
                      <Grid item xs={12}>
                        <TextInput
                          error={
                            inputFormErrors[index] &&
                            inputFormErrors[index].chat_title_err &&
                            inputFormErrors[index].chat_title_err !== ''
                          }
                          helperText={
                            inputFormErrors[index]
                              ? inputFormErrors[index].chat_title_err
                              : ''
                          }
                          id="chat_title"
                          name="chat_title"
                          label="説明タイトル"
                          fullWidth
                          variant="filled"
                          defaultValue={values.chat_title}
                          onChange={event => handleInputChange(event, index)}
                          disabled={!isUnrestrictedRole}
                          inputProps={{ maxLength: 50 }}
                        />
                        <Typography className={classes.typography}>
                          50文字以内
                        </Typography>
                      </Grid>
                    )}
                    {values.contact_type_id === ContactWayType.web.code && (
                      <>
                        <Grid item xs={12}>
                          <TextInput
                            error={
                              inputFormErrors[index] &&
                              inputFormErrors[index].url_err &&
                              inputFormErrors[index].url_err !== ''
                                ? true
                                : false
                            }
                            helperText={
                              inputFormErrors[index]
                                ? inputFormErrors[index].url_err
                                : ''
                            }
                            id="url"
                            name="url"
                            label="参照先URL"
                            fullWidth
                            variant="filled"
                            defaultValue={values.url}
                            onChange={event => handleInputChange(event, index)}
                            disabled={!isUnrestrictedRole}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            id="url_text"
                            name="url_text"
                            label="URLに表示するテキスト"
                            fullWidth
                            variant="filled"
                            defaultValue={values.url_text}
                            onChange={event => handleInputChange(event, index)}
                            disabled={!isUnrestrictedRole}
                            inputProps={{ maxLength: 30 }}
                          />
                          <Typography className={classes.typography}>
                            30文字以内
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {values.contact_type_id === ContactWayType.phone.code && (
                      <Grid item xs={12}>
                        <TextInput
                          error={
                            inputFormErrors[index] &&
                            inputFormErrors[index].phone_number_err &&
                            inputFormErrors[index].phone_number_err !== ''
                          }
                          helperText={
                            inputFormErrors[index]
                              ? inputFormErrors[index].phone_number_err
                              : ''
                          }
                          id="phone_number"
                          name="phone_number"
                          label="電話番号"
                          fullWidth
                          variant="filled"
                          defaultValue={values.phone_number}
                          onChange={event => handleInputChange(event, index)}
                          disabled={!isUnrestrictedRole}
                        />
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <TextInput
                        error={
                          inputFormErrors[index] &&
                          inputFormErrors[index].reception_start_time_err &&
                          inputFormErrors[index].reception_start_time_err !== ''
                        }
                        helperText={
                          inputFormErrors[index]
                            ? inputFormErrors[index].reception_start_time_err
                            : ''
                        }
                        id="reception_start_time"
                        name="reception_start_time"
                        label="受付開始時間"
                        type="time"
                        fullWidth
                        variant="filled"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          step: 600
                        }}
                        defaultValue={values.reception_start_time}
                        onChange={event => handleInputChange(event, index)}
                        disabled={!isUnrestrictedRole}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput
                        error={
                          inputFormErrors[index] &&
                          inputFormErrors[index].reception_end_time_err &&
                          inputFormErrors[index].reception_end_time_err !== ''
                        }
                        helperText={
                          inputFormErrors[index]
                            ? inputFormErrors[index].reception_end_time_err
                            : ''
                        }
                        id="reception_end_time"
                        name="reception_end_time"
                        label="受付終了時間"
                        type="time"
                        fullWidth
                        variant="filled"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          step: 600
                        }}
                        defaultValue={values.reception_end_time}
                        onChange={event => handleInputChange(event, index)}
                        disabled={!isUnrestrictedRole}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        className={classes.checkbox}
                        control={
                          <Checkbox
                            style={{
                              color: '#494bb2'
                            }}
                            disabled={!isUnrestrictedRole}
                            name="all_day_reception"
                            checked={values.all_day_reception || false}
                            onClick={event => handleInputChange(event, index)}
                          />
                        }
                        label={
                          <Typography className={classes.checkbox}>
                            {'終日受付'}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        id="description"
                        name="description"
                        label="説明文"
                        fullWidth
                        variant="filled"
                        defaultValue={values.description}
                        onChange={event => handleInputChange(event, index)}
                        disabled={!isUnrestrictedRole}
                        inputProps={{ maxLength: 50 }}
                      />
                      <Typography className={classes.typography}>
                        50文字以内
                      </Typography>
                    </Grid>
                  </Grid>
                  {index !== thirdElementIndex && (
                    <Divider className={classes.divider} />
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails)
