import React from 'react'
import { MembersFlag } from 'utils/enum'
import Label from './Label'

const MembersLabel = ({ memberFlag }) => {
  const { member, nonMember } = MembersFlag
  const isMember = memberFlag === member.code
  const labelProps = {
    color: isMember ? 'blue' : 'red',
    text: isMember ? member.value : nonMember.value
  }

  return <Label {...labelProps} />
}

export default MembersLabel
