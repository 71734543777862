import React from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { STATUS_LIST } from 'utils/const'
import Label from 'components/common/Label'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const columns = [
  { id: 'company-user-status', label: '状態', minWidth: 50 },
  { id: 'company-user-name', label: '氏名', minWidth: 170 },
  { id: 'company-user-display_name', label: '表示名', minWidth: 170 },
  { id: 'company-user-company_group_id', label: '所属グループ', minWidth: 130 },
  { id: 'company-user-role_id', label: '権限', minWidth: 130 },
  { id: 'company-user-username', label: 'ログインID', minWidth: 130 },
  { id: 'company-user-mail_address', label: 'メールアドレス', minWidth: 130 }
]

function StatusLabel({ status, role_id }) {
  const found = STATUS_LIST.find(element => element.value == status)
  return (
    <Label
      color={role_id == found.externalId ? found.externalColor : found.color}
      text={found.label}
    />
  )
}

function List(props) {
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const { searchCondition } = props

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/setting/company-user/edit/${id}`,
      e,
      history,
      searchCondition
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.companyUserList.length
            ? props.companyUserList.map(row => {
                const role = props.roleList.filter(
                  v => v.id == row.company_group.role_id
                )
                return (
                  <TableRow
                    onClick={e => navigateToDetailPage(e, row.company_user.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.company_user.id}
                  >
                    <TableCell>
                      <StatusLabel
                        status={row.company_user.status}
                        role_id={row.company_group.role_id}
                      />
                    </TableCell>
                    <TableCell>{row.company_user.name}</TableCell>
                    <TableCell>{row.company_user.display_name}</TableCell>
                    <TableCell>{row.company_group.name}</TableCell>
                    <TableCell>{role.length > 0 ? role[0].name : ''}</TableCell>
                    <TableCell>{row.company_user.username}</TableCell>
                    <TableCell>{row.company_user.mail_address}</TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
