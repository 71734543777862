import FormModalDialog from './FormModalDialog'
import CustomerHistoryThreadForm from './CustomerHistoryThreadForm'
import React from 'react'

const CustomerHistoryThreadDialogForm = ({
  open,
  handleClose,
  contractId,
  thread,
  fetchCustomerHistoryThreadList
}) => {
  return (
    <FormModalDialog
      isOpen={open}
      handleClose={handleClose}
      title={'活動履歴を新規作成'}
      body={
        <CustomerHistoryThreadForm
          contractId={contractId}
          thread={thread}
          fetchCustomerHistoryThreadList={fetchCustomerHistoryThreadList}
          handleClose={handleClose}
        />
      }
    />
  )
}

export default CustomerHistoryThreadDialogForm
