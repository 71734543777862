import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import FormLayout from 'components/common/FormLayout'
import Dropdown from 'components/common/Form/Dropdown'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

function CategoryForm(props) {
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const { isLoading, messageTemplate, setEnableForm, isErrorCategory } = props
  const [parentCategoryList, setParentCategoryList] = useState([])
  const [childCategoryList, setChildCategoryList] = useState({ 0: [] })
  const [isLoading2, setIsLoading2] = useState(false)

  const [values, setValues] = useState({
    parent_id: '',
    parent_id_err: '',
    category_id: '',
    category_id_err: ''
  })

  const checkErr = objects => {
    if (objects.parent_id_err !== '' || objects.category_id_err !== '') {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const handleSelectRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { [name]: value, category_id: '' }
    if (value) {
      newValues[name + '_err'] = ''
      newValues.category_id_err = '選択してください'
    } else {
      newValues[name + '_err'] = '選択してください'
    }
    setValues(newValues)
    checkErr(newValues)
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '選択してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const fetchCategoryList = async () => {
    const result = await QueryHelper('getLargeCategoryAndMediumCategory')
    if (!result.error) {
      const parents = []
      const children = { 0: [] }
      for (let row1 of result) {
        parents.push({
          value: row1.large_categories.id,
          label: row1.large_categories.name
        })
        const elements = []
        for (let row2 of row1.medium_categories) {
          elements.push({
            value: row2.id,
            label: row2.name
          })
        }
        children[row1.large_categories.id] = elements
      }
      setParentCategoryList(parents)
      setChildCategoryList(children)
    } else {
      props.enqueueSnackbar(`カテゴリーを取得できませんでした。`)
    }
  }

  useEffect(() => {
    const initializeValues = {
      parent_id:
        'category' in messageTemplate ? messageTemplate.category.parent_id : '',
      parent_id_err:
        'category' in messageTemplate
          ? messageTemplate.category.parent_id
            ? ''
            : '選択してください'
          : '',
      category_id:
        'category' in messageTemplate ? messageTemplate.category.id : '',
      category_id_err:
        'category' in messageTemplate
          ? messageTemplate.category.id
            ? ''
            : '選択してください'
          : ''
    }
    if (isErrorCategory) {
      if (values.category_id == '') {
        initializeValues.parent_id_err = '選択してください'
      }
      if (values.category_id == '') {
        initializeValues.category_id_err = '選択してください'
      }
    }
    setValues(initializeValues)

    checkErr(initializeValues)
    if (parentCategoryList.length == 0 || childCategoryList.length == 0) {
      fetchData()
    }
  }, [
    messageTemplate,
    setValues,
    setParentCategoryList,
    setChildCategoryList,
    isErrorCategory
  ])

  const fetchData = async () => {
    setIsLoading2(true)
    await Promise.all([fetchCategoryList()])
    setIsLoading2(false)
  }

  return (
    <FormLayout title="カテゴリー">
      {isLoading || isLoading2 ? (
        <Loading isLoading={isLoading || isLoading2} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Dropdown
              hasError={values.parent_id_err != '' ? true : false}
              errorMessage={values.parent_id_err}
              required
              name="parent_id"
              label={'大カテゴリー'}
              onChange={handleSelectRequiredChange}
              defaultValue={values.parent_id}
              items={parentCategoryList}
              disabled={isRestrictedRole}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              hasError={values.category_id_err != '' ? true : false}
              errorMessage={values.category_id_err}
              required
              name="category_id"
              label={
                childCategoryList[values.parent_id]
                  ? '中カテゴリー'
                  : '大カテゴリを選択してください'
              }
              onChange={handleInputRequiredChange}
              defaultValue={values.category_id}
              disabled={
                !childCategoryList[values.parent_id] || isRestrictedRole
              }
              items={childCategoryList[values.parent_id]}
            />
          </Grid>
        </Grid>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CategoryForm))
