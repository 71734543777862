import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { handleChange, handleClear } from 'actions/Actions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Loading from 'components/common/Loading'
import { formatJST } from 'utils/date.utils'
import { Regexes } from 'utils/regexes'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& > .MuiDialog-paperWidthSm': {
      width: '800px',
      maxWidth: '800px'
    }
  },
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.lightest
    }
  },
  subButton: {
    color: theme.palette.c_black.main,
    backgroundColor: theme.palette.c_white.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.lightest
    }
  },
  wrapperButton: {
    justifyContent: 'flex-end',
    width: '700px'
  },
  dialogActions: {
    display: 'block'
  }
}))

function AppUserFormDialog(props) {
  console.log(props)
  const { customerId } = props
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [customer, setCustomer] = useState({})
  const [appUser, setAppUser] = useState({})

  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const [isShowPassword, setIsShowPassword] = useState(null)
  const handleChangeShowPassword = event => {
    setIsShowPassword(!isShowPassword)
  }
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(null)
  const handleChangeShowConfirmPassword = event => {
    setIsShowConfirmPassword(!isShowConfirmPassword)
  }

  const [validate, setValidate] = useState({})

  useEffect(() => {
    fetchData(customerId)
  }, [customerId])

  const fetchData = async customerId => {
    setIsLoading(true)
    await Promise.all([fetchCustomer(customerId)])
    setIsLoading(false)
  }

  const fetchCustomer = async customerId => {
    const params = {
      id: {
        eq: customerId
      }
    }
    const result = await QueryHelper('getCustomerList', { filter: params })
    if (result.error) {
      console.log(result.error)
      props.enqueueSnackbar(`ユーザ情報を取得できませんでした。`)
    } else {
      setCustomer(result.data[0].customer)
      setAppUser(result.data[0].appUser)
    }
  }

  useEffect(() => {
    props.handleClear()
  }, [props.handleClear])

  const handleSubmit = event => {
    event.preventDefault()
    if (appUser && !username && !password && !confirmPassword) {
      props.enqueueSnackbar('変更がありません', {
        variant: 'warning'
      })
      return
    }

    if (validateForm()) {
      props.enqueueSnackbar('入力内容に不備があります', {
        variant: 'warning'
      })
      return
    }

    if (!window.confirm(`ユーザのログイン情報を保存します。よろしいですか？`)) {
      return
    }

    const params = {}
    if (username) {
      params.username = username
    }
    if (password) {
      params.password = password
    }
    if (appUser && appUser.id) {
      params.id = appUser.id
      updateAppUerAccount(params)
    } else {
      params.customer_id = customer.id
      params.is_activation = true
      params.registered_at = formatJST(new Date())
      registerAppUerAccount(params)
    }
  }

  const updateAppUerAccount = async params => {
    const result = await MutationHelper('updateAppUserAccount', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('ユーザ情報を更新できませんでした。', {
        variant: 'error'
      })
    } else {
      props.handleClear()
      props.enqueueSnackbar('ユーザ情報を更新しました。', {
        variant: 'success'
      })
      props.fetchData()
      props.handleClose()
    }
  }

  const registerAppUerAccount = async params => {
    const result = await MutationHelper('registerAppUserAccount', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('ユーザ情報を作成できませんでした', {
        variant: 'error'
      })
    } else {
      props.handleClear()
      props.enqueueSnackbar('ユーザ情報を作成しました', {
        variant: 'success'
      })
      props.fetchData()
      props.handleClose()
    }
  }

  function validateForm() {
    const valid = {}
    // 新規登録時
    if (!appUser || !appUser.id) {
      if (!username) {
        valid.username = { message: '必須入力です' }
      }
      if (!password) {
        valid.password = { message: '必須入力です' }
      }
      if (!confirmPassword) {
        valid.confirmPassword = { message: '必須入力です' }
      }
    }
    if (username) {
      const reg = new RegExp(Regexes.EmailAddress)
      if (!reg.test(username)) {
        valid.username = { message: 'メールアドレス形式で入力してください' }
      }
    }
    if (password) {
      if (
        !password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.)([a-zA-Z\d]|.){8,50}$/
        )
      ) {
        valid.password = {
          message:
            '8文字以上で、英大文字、英小文字、数字を含むようにしてください'
        }
      }
      if (!confirmPassword) {
        valid.confirmPassword = { message: '必須入力です' }
      } else {
        if (password !== confirmPassword) {
          valid.confirmPassword = {
            message: 'パスワードとパスワード(確認用)の入力が一致しません'
          }
        } else if (
          !password.match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.)([a-zA-Z\d]|.){8,50}$/
          )
        ) {
          valid.confirmPassword = {
            message:
              '8文字以上で、英大文字、英小文字、数字を含むようにしてください'
          }
        }
      }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  const handleCancel = event => {
    props.handleClose()
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        {isLoading ? (
          <Loading isLoading={props.isLoading} />
        ) : (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">ログイン情報を編集</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    id="username"
                    name="username"
                    label="メールアドレス"
                    fullWidth
                    variant="filled"
                    defaultValue={appUser && appUser.username}
                    onChange={event => setUsername(event.target.value)}
                    error={
                      validate && validate.username && validate.username.message
                    }
                    helperText={
                      validate && validate.username && validate.username.message
                        ? validate.username.message
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="パスワード"
                    type={isShowPassword ? 'text ' : 'password'}
                    id="password"
                    onChange={event => setPassword(event.target.value)}
                    error={
                      validate && validate.password && validate.password.message
                    }
                    helperText={
                      validate && validate.password && validate.password.message
                        ? validate.password.message
                        : '8文字以上、英大文字・小文字、数字を1文字ずつ含む'
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleChangeShowPassword}
                          >
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    margin="normal"
                    fullWidth
                    name="confirmPassword"
                    label="パスワード"
                    type={isShowConfirmPassword ? 'text ' : 'password'}
                    id="password"
                    onChange={event => setConfirmPassword(event.target.value)}
                    error={
                      validate &&
                      validate.confirmPassword &&
                      validate.confirmPassword.message
                    }
                    helperText={
                      validate &&
                      validate.confirmPassword &&
                      validate.confirmPassword.message
                        ? validate.confirmPassword.message
                        : '確認用にもう一度同じパスワードを入力'
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleChangeShowConfirmPassword}
                          >
                            {isShowConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Grid container spacing={3} className={classes.wrapperButton}>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.subButton}
                    onClick={handleCancel}
                  >
                    キャンセル
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                  >
                    保存
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AppUserFormDialog))
