import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormLayout from './FormLayout'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  border: {
    marginTop: '12px'
  },
  message: {
    fontFamily: 'Hiragino Sans W3',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2C3F58',
    textAlign: 'left'
  }
}))

function CommonDialog(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const isOpen = props && props.isOpen ? props.isOpen : null
  const title = props && props.title ? props.title : null
  const msg = props && props.msg ? props.msg : null
  const doYes = props && props.doYes ? props.doYes : null
  const yesMsg = props && props.yesMsg ? props.yesMsg : null
  const doNo = props && props.doNo ? props.doNo : null
  const noMsg = props && props.noMsg ? props.noMsg : null

  useEffect(() => {
    if (props.isOpen) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isOpen, setOpen])

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNo()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <FormLayout title={title} />
        <div>
          <DialogContent className={classes.message}>{msg}</DialogContent>
        </div>
        <Divider className={classes.border} />
        <DialogActions>
          <Button onClick={doNo} color="primary">
            {noMsg ? noMsg : 'キャンセル'}
          </Button>
          <Button onClick={doYes} color="primary">
            {yesMsg ? yesMsg : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CommonDialog
