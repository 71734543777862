import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { formatYMD } from 'utils/date.utils'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  contentName: {
    height: '48px',
    width: '420px'
  },
  contentTitle: {
    height: '12px',
    width: '324.34px',
    color: '#72809D',
    backgroundColor: '#FAFBFD',
    fontFamily: 'Hiragino Sans',
    fontSize: '12px',
    lineHeight: '18px'
  },
  tabs: {
    color: '#2c3f58',
    width: '320px',
    height: '56px',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  tabTitle: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  }
}))
const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  )
}

function CellWithBirthDate({ birthdate }) {
  if (birthdate) {
    const date = new Date(birthdate)
    return <>{formatYMD(date)}</>
  }
  return <></>
}

function ResidentInfoChangeApplicationDetail({
  isLoading,
  application,
  applicant,
  attachment
}) {
  const classes = useStyles()
  const content =
    application && application.content ? parseJson(application.content) : {}
  const [labelName, setLabelName] = React.useState(0)

  const handleChangeLabel = (event, newValue) => {
    setLabelName(newValue)
  }

  return (
    <>
      <Tabs
        value={labelName}
        onChange={handleChangeLabel}
        aria-label="simple tabs example"
        className={classes.tabs}
      >
        <Tab label="申請前" {...a11yProps(0)} className={classes.tabTitle} />
        <Tab label="現状" {...a11yProps(1)} className={classes.tabTitle} />
      </Tabs>
      <TabPanel value={labelName} index={0}>
        <TableContainer component="Paper">
          <Table aria-label="spanning table">
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="2"
                  >
                    <Loading isLoading={isLoading} />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      入居者名
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      {content &&
                        content.before &&
                        content.before.resident &&
                        content.before.resident.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      フリガナ
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      {content &&
                        content.before &&
                        content.before.resident &&
                        content.before.resident.naneKana}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      生年月日
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      {content && content.before && content.before.resident && (
                        <CellWithBirthDate
                          birthdate={content.before.resident.birthDay}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      携帯電話番号
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      {content &&
                        content.before &&
                        content.before.resident &&
                        content.before.resident.tel}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      職業
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      {content &&
                        content.before &&
                        content.before.resident &&
                        content.before.resident.jobName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.contentTitle}
                      colSpan="2"
                    >
                      勤務先
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      社名
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      {content &&
                        content.before &&
                        content.before.company &&
                        content.before.company.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      電話番号
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      {content &&
                        content.before &&
                        content.before.company &&
                        content.before.company.tel}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={labelName} index={1}>
        <TableContainer component="Paper">
          <Table aria-label="spanning table">
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  入居者名
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  {applicant && applicant.KKY_NAME}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  フリガナ
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  {applicant && applicant.KKY_NAME_KANA}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  生年月日
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  {applicant && (
                    <CellWithBirthDate birthdate={applicant.KKY_BIRTHDAY} />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  携帯電話番号
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  {applicant && applicant.KKY_TEL_02}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  職業
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  {applicant && applicant.KKY_SKG_NAME}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.contentTitle}
                  colSpan="2"
                >
                  勤務先
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  社名
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  {applicant && applicant.KKY_WORKPLACE}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  電話番号
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="1"
                >
                  {applicant && applicant.KKY_WORKPLACE_TEL_01}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  )
}

export default ResidentInfoChangeApplicationDetail
