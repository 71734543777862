/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react'
import {
  ADMIN,
  BULLETIN_BOARD,
  BICYCLE_PARKING_STICKER,
  CHANGE_HEADER_BODY,
  CHANGE_SEARCH_CONDITION,
  CLEAR_SEARCH_CONDITION,
  CLOSE_MODAL_DIALOG,
  CLOSE_SEARCH_MODAL_DIALOG,
  CLOSE_SEARCH_SAVE_MODAL_DIALOG,
  HANDLE_CHANGE,
  HANDLE_CLEAR,
  MESSAGE,
  OWNER_MESSAGE,
  OPEN_MODAL_DIALOG,
  OPEN_SEARCH_MODAL_DIALOG,
  OPEN_SEARCH_SAVE_MODAL_DIALOG,
  RESIDENT_CANCELLATION,
  RESIDENT_INFO_CHANGE,
  ROOM_CHECK_LIST,
  SET_LOAD_DATA,
  SET_ROLE_LIST,
  SIGNED,
  SET_CSV_INFORMATION,
  SET_MATCH_CSV_VALUE,
  SET_TARGET_ID_LIST,
  SET_CSV_HEADER,
  SET_MATCH_CONTRACT_ID_LIST,
  SET_SELECT_DELIVERY_LIST_ID,
  SET_SELECT_CONTRACT_LIST,
  SET_FILTER_CSV_INFORMATION,
  SET_BULK_DELIVERY_DETAIL_LIST,
  SET_IMAGE_LIST,
  SET_PDF_LIST,
  COC2_USE_PARKING,
  PET,
  BUILDING,
  CONTRACT,
  FAQ,
  MESSAGE_TEMPLATE,
  COMPANY_USER,
  SETTING_BUILDING,
  DELETE_SEARCH_CONDITIONS
} from 'actions/ActionTypes'
import { PAGE_LIMIT } from 'utils/const'
import DefaultBody from 'components/common/Header/DefaultBody'

const initialSearchConditionState = {
  message: {},
  ownerMessage: {},
  bulletinBoard: {},
  residentInfoChange: {},
  residentCancellation: {},
  roomCheckList: {},
  bicycleParkingSticker: {},
  cOC2UseParking: {},
  pet: {},
  building: {},
  contract: {},
  faq: {},
  messageTemplate: {},
  companyUser: {},
  settingBuilding: {}
}

export const headerBody = (state = { component: <DefaultBody /> }, action) => {
  switch (action.type) {
    case CHANGE_HEADER_BODY:
      return Object.assign({}, state, { component: action.payload })
    default:
      return state
  }
}

export const authorize = (state = { isSignin: false }, action) => {
  switch (action.type) {
    case SIGNED:
      return Object.assign({}, state, { isSignin: action.payload })
    default:
      return state
  }
}

export const admin = (state = { isAdmin: false }, action) => {
  switch (action.type) {
    case ADMIN:
      return Object.assign({}, state, { isAdmin: action.payload })
    default:
      return state
  }
}

export const handle = (state = { parameters: {} }, action) => {
  switch (action.type) {
    case HANDLE_CHANGE:
      return {
        ...state,
        parameters: Object.assign(state.parameters, action.payload)
      }
    case HANDLE_CLEAR:
      return Object.assign({}, state, { parameters: {} })
    default:
      return state
  }
}

export const loadData = (state = { flag: false }, action) => {
  switch (action.type) {
    case SET_LOAD_DATA:
      return Object.assign({}, state, { flag: action.payload })
    default:
      return state
  }
}

export const modalDialog = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case OPEN_MODAL_DIALOG:
      return Object.assign({}, state, { isOpen: true })
    case CLOSE_MODAL_DIALOG:
      return Object.assign({}, state, { isOpen: false })
    default:
      return state
  }
}

export const roleData = (
  state = { data: [], paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 } },
  action
) => {
  switch (action.type) {
    case SET_ROLE_LIST:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export const searchCondition = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_SEARCH_CONDITION:
      return Object.assign({}, state, action.payload)
    case CLEAR_SEARCH_CONDITION:
      return {}
    default:
      return state
  }
}

export const searchModalDialog = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case OPEN_SEARCH_MODAL_DIALOG:
      return Object.assign({}, state, { isOpen: true })
    case CLOSE_SEARCH_MODAL_DIALOG:
      return Object.assign({}, state, { isOpen: false })
    default:
      return state
  }
}

export const searchSaveModalDialog = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case OPEN_SEARCH_SAVE_MODAL_DIALOG:
      return Object.assign({}, state, { isOpen: true })
    case CLOSE_SEARCH_SAVE_MODAL_DIALOG:
      return Object.assign({}, state, { isOpen: false })
    default:
      return state
  }
}

export const targetIdList = (list = {}, action) => {
  switch (action.type) {
    case SET_TARGET_ID_LIST:
      return Object.assign({}, list, { list: action.payload })
    default:
      return list
  }
}

export const csvInformation = (list = {}, action) => {
  switch (action.type) {
    case SET_CSV_INFORMATION:
      return Object.assign(list, { list: action.payload })
    default:
      return list
  }
}

export const matchCsvValue = (value = {}, action) => {
  switch (action.type) {
    case SET_MATCH_CSV_VALUE:
      return Object.assign(value, { value: action.payload })
    default:
      return value
  }
}

export const csvHeader = (value = {}, action) => {
  switch (action.type) {
    case SET_CSV_HEADER:
      return Object.assign(value, { value: action.payload })
    default:
      return value
  }
}

export const matchContractIdList = (list = {}, action) => {
  switch (action.type) {
    case SET_MATCH_CONTRACT_ID_LIST:
      return Object.assign(list, { list: action.payload })
    default:
      return list
  }
}

export const selectDeliveryListId = (id = {}, action) => {
  switch (action.type) {
    case SET_SELECT_DELIVERY_LIST_ID:
      return Object.assign(id, { id: action.payload })
    default:
      return id
  }
}

export const selectContractList = (list = {}, action) => {
  switch (action.type) {
    case SET_SELECT_CONTRACT_LIST:
      return Object.assign(list, { list: action.payload })
    default:
      return list
  }
}

export const filterCsvInformation = (list = {}, action) => {
  switch (action.type) {
    case SET_FILTER_CSV_INFORMATION:
      return Object.assign(list, { list: action.payload })
    default:
      return list
  }
}

export const bulkDeliveryDetailList = (list = {}, action) => {
  switch (action.type) {
    case SET_BULK_DELIVERY_DETAIL_LIST:
      return Object.assign(list, { list: action.payload })
    default:
      return list
  }
}

export const imageList = (list = {}, action) => {
  switch (action.type) {
    case SET_IMAGE_LIST:
      return Object.assign(list, { list: action.payload })
    default:
      return list
  }
}

export const pdfList = (list = {}, action) => {
  switch (action.type) {
    case SET_PDF_LIST:
      return Object.assign(list, { list: action.payload })
    default:
      return list
  }
}

export const saveSearchCondition = (
  state = initialSearchConditionState,
  action
) => {
  switch (action.type) {
    case MESSAGE:
      return { ...state, message: action.payload }
    case OWNER_MESSAGE:
      return { ...state, ownerMessage: action.payload }
    case BULLETIN_BOARD:
      return { ...state, bulletinBoard: action.payload }
    case RESIDENT_INFO_CHANGE:
      return { ...state, residentInfoChange: action.payload }
    case RESIDENT_CANCELLATION:
      return { ...state, residentCancellation: action.payload }
    case ROOM_CHECK_LIST:
      return { ...state, roomCheckList: action.payload }
    case BICYCLE_PARKING_STICKER:
      return { ...state, bicycleParkingSticker: action.payload }
    case COC2_USE_PARKING:
      return { ...state, cOC2UseParking: action.payload }
    case PET:
      return { ...state, pet: action.payload }
    case BUILDING:
      return { ...state, building: action.payload }
    case CONTRACT:
      return { ...state, contract: action.payload }
    case FAQ:
      return { ...state, faq: action.payload }
    case MESSAGE_TEMPLATE:
      return { ...state, messageTemplate: action.payload }
    case COMPANY_USER:
      return { ...state, companyUser: action.payload }
    case SETTING_BUILDING:
      return { ...state, settingBuilding: action.payload }
    case DELETE_SEARCH_CONDITIONS:
      return initialSearchConditionState
    default:
      return state
  }
}
