import React from 'react'
import { withSnackbar } from 'notistack'
import DetailLayout from 'components/common/DetailLayout'
import OwnerRegistration from './OwnerRegistration'

const OwnerListContent = props => {
  const { isLoading, building, buildingOwnerList, fetchOwnerList } = props

  return (
    <DetailLayout title="オーナー登録" isLoading={isLoading}>
      <OwnerRegistration
        buildingOwnerList={buildingOwnerList.data}
        building={building.building}
        fetchOwnerList={fetchOwnerList}
      />
    </DetailLayout>
  )
}

export default withSnackbar(OwnerListContent)
