import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import RowWithFile from 'components/common/RowWithFile'
import { Auth, Storage } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnHead: {
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    backgroundColor: '#FAFBFD'
  },
  columnName1: {
    width: '150px',
    color: '#72809D'
  },
  columnValue1: {
    width: '150px',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '200px',
    color: '#72809D'
  },
  columnValue2: {
    width: '200px',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName3: {
    color: '#72809D'
  },
  columnValue3: {
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  divider: {
    width: '100%',
    height: '24px',
    backgroundColor: theme.palette.c_background.gray
  }
}))

function CellWithImage({ imageMap, id }) {
  if (imageMap.has(id)) {
    const image = imageMap.get(id)
    return (
      <RowWithFile
        title={''}
        image={image.body}
        style={{ marginBottom: 10 }}
        isDeleteButtonView={false}
        thumbnailWidth={120}
        thumbnailHeight={90}
      />
    )
  }
  return <></>
}

function RoomCheckListApplicationDetail(props) {
  const classes = useStyles()
  const [imageMap, setImageMap] = useState(new Map())

  let { roomCheckListJsons, attachments } = props

  useEffect(() => {
    const getAttachments = async () => {
      if (attachments) {
        const attachmentMap = new Map()
        for (let attachment of attachments) {
          if (attachmentMap.has(attachment.id)) {
            continue
          }
          await Auth.currentCredentials().then(async creds => {
            const key = attachment.key
            await Storage.get(key, {
              level: 'protected',
              identityId: attachment.company_id,
              expires: 60
            })
              .then(result => {
                const object = Object.assign({}, attachment, { body: result })
                attachmentMap.set(`${attachment.id}`, object)
              })
              .catch(err => {
                console.log('error: ', err)
              })
          })
        }
        setImageMap(attachmentMap)
      }
    }
    getAttachments()
  }, [roomCheckListJsons, attachments, setImageMap])

  return (
    <>
      {roomCheckListJsons &&
        roomCheckListJsons.length > 0 &&
        roomCheckListJsons.map(row => (
          <>
            <TableContainer component="Paper">
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.root} colSpan="5">
                      {row.category}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.columnHead}>
                    <TableCell
                      align="left"
                      className={classes.columnName1}
                      colSpan="1"
                    >
                      箇所
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName2}
                      colSpan="1"
                    >
                      内容
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.columnName3}
                      colSpan="3"
                    >
                      添付
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.body &&
                    row.body.length &&
                    row.body.map(body => (
                      <TableRow>
                        <TableCell className={classes.columnValue1} colSpan="1">
                          {body.place}
                        </TableCell>
                        {/*<TableCell className={classes.columnName2} colSpan="1">{'content' in body && (body.content)}</TableCell>*/}
                        <TableCell className={classes.columnValue2} colSpan="1">
                          {body && 'content' in body ? body.content : null}
                        </TableCell>
                        <TableCell className={classes.columnValue3} colSpan="3">
                          <Grid container xs={12}>
                            {imageMap &&
                              imageMap.size > 0 &&
                              body.images &&
                              body.images.length > 0 &&
                              body.images.map(image => (
                                <Grid item xs={4}>
                                  <CellWithImage
                                    imageMap={imageMap}
                                    id={image.id}
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.divider} />
          </>
        ))}
    </>
  )
}

export default RoomCheckListApplicationDetail
