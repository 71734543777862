import React from 'react'
import Label from './Label'

const UseOwnerLabel = ({ is_usage }) => {
  const statusInfo = is_usage
    ? { label: '有効', color: 'blue' }
    : { label: '無効', color: 'gray' }
  return <Label color={statusInfo.color} text={statusInfo.label} />
}

export default UseOwnerLabel
