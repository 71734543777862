import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Left from './Left'
import { withSnackbar } from 'notistack'
import { QueryHelper } from 'utils/api.utils'
import RightColumn from '../../../components/rightColumn/rightColumn'
import { useCustomerOrOwner } from '../../../hooks/useCustomerOrOwner'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#F5F5F5',
    height: '100%'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: 'calc(100% - 300px)',
    maxWidth: 'calc(100% - 300px)'
  },
  leftBody: {
    flex: 1,
    marginTop: '1px',
    marginLeft: '1px',
    marginBottom: '1px',
    marginRight: '1px'
  },
  leftBottom: {
    backgroundColor: '#fff'
  },
  right: {
    width: '400px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

const DetailScreen = props => {
  const classes = useStyles()
  const importScreen = { kinds: 'messageDetails', display: 'messageDetails' }
  const { id } = useParams()
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()
  const [body, setBody] = useState('')
  const [thread, setThread] = useState({})
  const [messageAndAttachmentList, setMessageAndAttachmentList] = useState({})
  const [companyUserList, setCompanyUserList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [threadMemos, setThreadMemos] = useState([])

  const joinBody = joinText => {
    const msgTextArea = document.getElementById('body')
    const msg =
      msgTextArea.value.substr(0, msgTextArea.selectionStart) +
      joinText +
      msgTextArea.value.substr(msgTextArea.selectionStart)
    setBody(msg)
    msgTextArea.focus()
  }

  const fetchThread = async threadId => {
    const params = {}
    params['id'] = { eq: threadId }
    let result = await QueryHelper('getThread', {
      filter: params,
      is_owner: isOwnerPath
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('スレッド情報を取得できませんでした', {
        variant: 'error'
      })
      setThreadMemos([])
    } else {
      setThreadMemos(result.thread_memos)
      setThread(result.thread)
    }
  }

  const fetchMessageAndAttachmentList = async (threadId, offset, limit) => {
    const params = {
      thread_id: { eq: threadId },
      orderBy: [{ column: 'id', sorted: 'asc' }]
    }
    const result = await QueryHelper('getMessageList', {
      filter: params,
      offset: offset,
      limit: limit,
      is_owner: isOwnerPath
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('メッセージリストを取得できませんでした', {
        variant: 'error'
      })
    } else {
      setMessageAndAttachmentList(result.data)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    // 右カラムの表示で使用するためcontainsで削除を取得する
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper('getCompanyUserList', {
      filter: filter,
      offset: offset,
      limit: limit
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCompanyUserList(result.data)
    }
  }

  const fetchData = async threadId => {
    setIsLoading(true)
    await Promise.all([
      fetchThread(threadId),
      fetchMessageAndAttachmentList(threadId, 0, null),
      fetchCompanyUserList(0, null)
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id)
    }
  }, [id, setThread, setMessageAndAttachmentList, setCompanyUserList])

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div className={classes.leftBody}>
          <Left
            isLoading={isLoading}
            thread={thread}
            messageAndAttachmentList={messageAndAttachmentList}
            companyUserList={companyUserList}
            fetchThread={fetchThread}
            fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
            body={body}
            setBody={setBody}
          />
        </div>
        <div className={classes.leftBottom}></div>
      </div>
      <div className={classes.right}>
        <div className={classes.rightBody}>
          <RightColumn
            isLoading={isLoading}
            thread={thread}
            companyUserList={companyUserList}
            joinBody={joinBody}
            fetchThread={fetchThread}
            threadMemos={threadMemos}
            display={importScreen}
            fetchData={fetchData}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(DetailScreen)))
