import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import MoreIcon from '@material-ui/icons/More'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded'
import HelpIcon from '@material-ui/icons/Help'
import NotesRoundedIcon from '@material-ui/icons/NotesRounded'
import MessageInfo from './MessageInfo'
import ResidentTabs from './ResidentTabs'
import BuildingInfo from './BuildingInfo'
import FaqInfo from './FaqInfo'
import MessageTemplateInfo from './MessageTemplateInfo'
import Summary from './Summary'
import CustomerHistoryInfo from './CustomerHistoryInfo'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import TocIcon from '@material-ui/icons/Toc'
import { useCustomerOrOwner } from '../../hooks/useCustomerOrOwner'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    position: 'fixed',
    backgroundColor: '#fff',
    height: '100%',
    '& > .MuiTypography-root > .MuiBox-root': {
      padding: '0px'
    }
  },
  tabs: {
    width: '50px',
    borderRight: `1px solid ${theme.palette.divider}`,
    '& > .MuiTabs-scroller > span': {
      backgroundColor: theme.palette.c_blue.main
    },
    '& .Mui-selected': {
      color: theme.palette.c_blue.dark
    }
  },
  tab: {
    '& > svg': {
      color: theme.palette.c_gray.dark,
      '&:selected': {
        backgroundColor: theme.palette.c_purple.dark
      }
    },
    '& > .MuiTab-wrapper': {
      alignItems: 'normal'
    }
  },
  tabPanel: {
    width: '350px',
    maxHeight: '90%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  displayNone: {
    display: 'none'
  }
}))

const RightColumn = ({
  isLoading,
  thread,
  companyUserList,
  joinBody,
  fetchThread,
  threadMemos,
  residentInfoChangeApplication,
  fetchData,
  display,
  props
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [contract, setContract] = useState({})
  const [customer, setCustomer] = useState({})
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const fetchCustomer = async () => {
    const application =
      display.kinds == 'messageDetails'
        ? thread
        : residentInfoChangeApplication.application
    if (!application) {
      return
    }
    let result = await QueryHelper('getCustomerList', {
      filter: { id: { contains: application.customer_id } },
      offset: 0,
      limit: null
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('カスタマー一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      if (result.data[0] == null) return
      setCustomer(result.data[0])
      if (
        result.data[0].tenantContracts &&
        result.data[0].tenantContracts.length > 0
      ) {
        for (let customerData of result.data[0].tenantContracts) {
          if (
            customerData.building_id === application.building_id &&
            customerData.room_id === application.room_id
          ) {
            setContract(customerData)
          }
        }
      }
    }
  }

  const fetchOwner = async () => {
    if (!thread) return

    const result = await QueryHelper('getOwner', {
      filter: { id: { eq: thread.customer_id } },
      offset: 0,
      limit: null
    })
    if (result.error) {
      props.enqueueSnackbar('オーナー一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCustomer(result)
    }
  }

  const fetchCustomerData = async () => {
    await Promise.all([isOwnerPath ? fetchOwner() : fetchCustomer()])
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab icon={<MoreIcon />} {...a11yProps(0)} className={classes.tab} />
        <Tab icon={<TocIcon />} {...a11yProps(1)} className={classes.tab} />
        <Tab
          icon={<AccessibilityNewIcon />}
          {...a11yProps(2)}
          className={classes.tab}
        />
        <Tab
          icon={<ApartmentRoundedIcon />}
          {...a11yProps(3)}
          className={classes.tab}
        />
        {!isOwnerPath && (
          <Tab
            icon={<DynamicFeedIcon />}
            {...a11yProps(4)}
            className={classes.tab}
          />
        )}
        <Tab
          icon={<HelpIcon />}
          {...a11yProps(isOwnerPath ? 4 : 5)}
          className={
            display.kinds == 'messageDetails'
              ? `${classes.tab}`
              : `${classes.tab} ${classes.displayNone}`
          }
        />
        <Tab
          icon={<NotesRoundedIcon />}
          {...a11yProps(isOwnerPath ? 5 : 6)}
          className={
            display.kinds == 'messageDetails'
              ? `${classes.tab}`
              : `${classes.tab} ${classes.displayNone}`
          }
        />
      </Tabs>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <MessageInfo
          isLoading={isLoading}
          thread={thread}
          companyUserList={companyUserList}
          fetchThread={fetchThread}
          fetchData={fetchData}
          threadMemos={threadMemos}
          residentInfoChangeApplication={residentInfoChangeApplication}
          display={display}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <Summary
          isLoading={isLoading}
          display={display}
          application={
            display.kinds == 'messageDetails'
              ? thread
              : residentInfoChangeApplication
          }
          fetchCustomerData={fetchCustomerData}
          contract={contract}
          owner={customer}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={2}>
        <ResidentTabs
          application={
            display.kinds == 'messageDetails'
              ? thread
              : residentInfoChangeApplication.application
          }
          customer={customer}
          contract={contract}
          thread={thread}
          fetchCustomerData={fetchCustomerData}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={3}>
        <BuildingInfo
          application={
            display.kinds == 'messageDetails'
              ? thread
              : residentInfoChangeApplication.application
          }
        />
      </TabPanel>
      {!isOwnerPath && (
        <TabPanel className={classes.tabPanel} value={value} index={4}>
          <CustomerHistoryInfo
            application={
              display.kinds == 'messageDetails'
                ? thread
                : residentInfoChangeApplication.application
            }
            contract={contract}
            fetchCustomer={fetchCustomer}
          />
        </TabPanel>
      )}
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={isOwnerPath ? 4 : 5}
      >
        <FaqInfo thread={thread} joinBody={joinBody} />
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={isOwnerPath ? 5 : 6}
      >
        <MessageTemplateInfo thread={thread} joinBody={joinBody} />
      </TabPanel>
    </div>
  )
}

export default withSnackbar(RightColumn)
