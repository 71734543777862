import React, { useEffect, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import RowWithMovie from 'components/common/RowWithMovie'

function Movie(props) {
  const attachment = props.attachment ? props.attachment : null
  const [movie, setMovie] = useState(null)
  const [thumbnailImage, setThumbnailImage] = useState(null)

  useEffect(() => {
    if (attachment) {
      Auth.currentCredentials().then(async creds => {
        await Promise.all([
          fetchMovie(attachment),
          fetchThumbnailImage(attachment)
        ])
      })
    }
  }, [attachment, setMovie, setThumbnailImage])

  async function fetchMovie(attachment) {
    // keyがない時は動画アップロード前
    if (!attachment.key || attachment.key === '') {
      setMovie(attachment)
      return
    }
    const key = attachment.key
    console.log(key)
    const result = await getStorage(key)
    console.log(result)
    const object = Object.assign({}, attachment, { body: result })
    setMovie(object)
  }

  async function getStorage(key) {
    return Storage.get(key, {
      level: 'protected',
      identityId: attachment.company_id,
      expires: 60
    })
  }

  async function fetchThumbnailImage(attachment) {
    const key = attachment.thumbnail_key
    console.log(key)
    const result = await getStorage(key)
    console.log(result)
    const object = Object.assign({}, attachment, { body: result })
    setThumbnailImage(object)
  }

  return (
    <>
      {movie && thumbnailImage && (
        <RowWithMovie
          attachment={attachment}
          movie={movie.body}
          thumbnailImage={thumbnailImage.body}
          title={movie.filename}
          handleDelete={props.handleDelete}
          isDeleteButtonView={true}
          disabledIcon={props.disabledIcon}
        />
      )}
    </>
  )
}

export default withSnackbar(withRouter(Movie))
