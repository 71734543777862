import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { MutationHelper } from 'utils/api.utils'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import FormFooter from './FormFooter'
import { setLoadData } from 'actions/Actions'
import { connect } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'
import Dropdown from 'components/common/Form/Dropdown'
import { CustomerHistoryMessageCategory, ThreadState } from 'utils/enum'
import { fromCode } from 'utils/enum.utils'
import Typography from '@material-ui/core/Typography'
import { QueryHelper } from 'utils/api.utils'

const useStyles = makeStyles(theme => ({
  left: {
    flex: 1,
    display: 'flex'
  },
  errorMessage: {
    marginRight: 'auto',
    color: theme.palette.c_alert
  }
}))

const CustomerHistoryThreadForm = props => {
  const classes = useStyles()

  const { contractId, handleClose, setLoadData } = props

  const [displayDateTime, setDisplayDateTime] = useState(new Date())
  const [categoryId, setCategoryId] = useState(null)
  const [categoryName, setCategoryName] = useState({})
  const [selectedParentCategory, setSelectedParentCategory] = useState(null)
  const [selectedChildCategory, setSelectedChildCategory] = useState(null)
  const [parentCategoryList, setParentCategoryList] = useState(null)
  const [childCategoryList, setChildCategoryList] = useState(null)
  const [selectedThreadState, setSelectedThreadState] = useState(null)
  const [selectedThreadStateObject, setSelectedThreadStateObject] = useState({})
  const [body, setBody] = useState('')
  const [count, setCount] = useState(0)
  const history = useHistory()
  const [validateCategoryId, setValidateCategoryId] = useState({})
  const [
    validateSelectedParentCategory,
    setValidateSelectedParentCategory
  ] = useState({})
  const [
    validateSelectedChildCategory,
    setValidateSelectedChildCategory
  ] = useState({})
  const [
    validateSelectedThreadState,
    setValidateSelectedThreadState
  ] = useState({})
  const [validateBody, setValidateBody] = useState({})

  const handleChangeDisplayDateTime = date => {
    setDisplayDateTime(date)
  }
  const handleChangeCategory = event => {
    setCategoryId(event.target.value)
    setCategoryName(
      fromCode(CustomerHistoryMessageCategory, event.target.value)
    )
  }
  const handleChangeParentCategory = event => {
    setSelectedParentCategory(event.target.value)
    setSelectedChildCategory(0)
  }
  const handleChangeChildCategory = event => {
    setSelectedChildCategory(event.target.value)
  }
  const handleChangeStatus = event => {
    setSelectedThreadState(event.target.value)
    setSelectedThreadStateObject(fromCode(ThreadState, event.target.value))
  }
  const handleChangeBody = event => {
    setCount(event.target.value.length)
    setBody(event.target.value)
  }

  const customerHistoryMessageCategories = [
    {
      label: CustomerHistoryMessageCategory.tel.value,
      value: CustomerHistoryMessageCategory.tel.code
    },
    {
      label: CustomerHistoryMessageCategory.mail.value,
      value: CustomerHistoryMessageCategory.mail.code
    },
    {
      label: CustomerHistoryMessageCategory.delivery.value,
      value: CustomerHistoryMessageCategory.delivery.code
    },
    {
      label: CustomerHistoryMessageCategory.other.value,
      value: CustomerHistoryMessageCategory.other.code
    }
  ]

  const threadStates = [
    {
      value: ThreadState.open.code,
      label: ThreadState.open.value
    },
    {
      value: ThreadState.inProgress.code,
      label: ThreadState.inProgress.value
    },
    {
      value: ThreadState.pending.code,
      label: ThreadState.pending.value
    },
    {
      value: ThreadState.complete.code,
      label: ThreadState.complete.value
    }
  ]

  const fetchCategoryList = async () => {
    const result = await QueryHelper('getLargeCategoryAndMediumCategory')
    if (result.error) {
      props.enqueueSnackbar(`カテゴリーを取得できませんでした。`)
    } else {
      const parents = [{ value: 0, label: '' }]
      const children = { 0: [] }
      for (let row1 of result) {
        parents.push({
          value: row1.large_categories.id,
          label: row1.large_categories.name
        })
        const elements = [{ value: 0, label: '' }]
        for (let row2 of row1.medium_categories) {
          elements.push({
            value: row2.id,
            label: row2.name
          })
        }
        children[row1.large_categories.id] = elements
      }
      setParentCategoryList(parents)
      setChildCategoryList(children)
    }
  }

  useEffect(() => {
    if (props.thread) {
      const thread = props.thread
      if (thread.display_at) {
        const date = new Date(thread.display_at)
        setDisplayDateTime(date)
      }
    }
    fetchCategoryList()
  }, [props])

  const handleSubmit = async event => {
    event.preventDefault()
    setLoadData(true)
    if (validateForm()) {
      setLoadData(false)
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    const params = {}

    if (contractId) {
      params.contract_id = contractId
    }

    if (displayDateTime) {
      params.receipted_at = displayDateTime
    }
    if (categoryName) {
      params.other_activity_type = categoryName.key
    }
    if (selectedParentCategory) {
      if (selectedChildCategory) {
        params.category_id = selectedChildCategory
      }
    }
    if (selectedThreadStateObject) {
      params.other_activity_state = selectedThreadStateObject.key
    }
    if (body) {
      params.memo = body
    }

    let result
    result = await MutationHelper('createOtherCustomerActivity', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
      history.push(`/management/contract/detail/${contractId}`)
      localStorage.setItem('display', 'customerHistory')
      localStorage.setItem('pageNumber', 1)
      const isSaving = true
      handleClose(event, isSaving)
    }
  }
  setLoadData(false)

  const validateForm = () => {
    let isError = false

    if (!categoryId) {
      setValidateCategoryId({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateCategoryId({})
    }
    if (!selectedParentCategory) {
      setValidateSelectedParentCategory({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateSelectedParentCategory({})
    }
    if (!selectedChildCategory) {
      setValidateSelectedChildCategory({
        hasError: true,
        message: '必須です'
      })
      isError = true
    } else {
      setValidateSelectedChildCategory({})
    }
    if (!selectedThreadState) {
      setValidateSelectedThreadState({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateSelectedThreadState({})
    }
    if (body.length > 300) {
      setValidateBody({ hasError: true })
      isError = true
    } else {
      setValidateBody({})
    }
    return isError
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.left}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
              <KeyboardDateTimePicker
                fullWidth
                disableToolbar
                required
                format="MM/dd HH:mm"
                inputVariant="filled"
                label="受付日時"
                defaultValue={displayDateTime}
                value={displayDateTime}
                onChange={handleChangeDisplayDateTime}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              required={true}
              name="customerHistoryThread.type"
              label={'種別'}
              onChange={handleChangeCategory}
              defaultValue={categoryName.code}
              value={categoryName.code}
              items={customerHistoryMessageCategories}
              hasError={validateCategoryId.hasError}
              errorMessage={validateCategoryId.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              required={true}
              name="customerHistoryThread.parent_category"
              label={'大カテゴリ'}
              onChange={handleChangeParentCategory}
              defaultValue={selectedParentCategory}
              value={selectedParentCategory}
              items={parentCategoryList}
              hasError={validateSelectedParentCategory.hasError}
              errorMessage={validateSelectedParentCategory.message}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              required={true}
              name="customerHistoryThread.child_category"
              label={'中カテゴリ'}
              onChange={handleChangeChildCategory}
              defaultValue={selectedChildCategory}
              value={selectedChildCategory}
              items={
                selectedParentCategory != null
                  ? childCategoryList[selectedParentCategory]
                  : [{ value: '', label: '大カテゴリを選択してください' }]
              }
              hasError={validateSelectedChildCategory.hasError}
              errorMessage={validateSelectedChildCategory.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              required={true}
              label="状態"
              name="status"
              onChange={handleChangeStatus}
              defaultValue={selectedThreadStateObject.code}
              value={selectedThreadStateObject.code}
              items={threadStates}
              hasError={validateSelectedThreadState.hasError}
              errorMessage={validateSelectedThreadState.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.left}>
            <TextField
              id="body"
              name="内容"
              label="内容"
              fullWidth
              variant="filled"
              autoComplete="number"
              onChange={handleChangeBody}
              value={body}
              multiline
              rows={8}
              placeholder={
                'ここに備考メモを追加できます。備考メモの内容は入居者には表示されません。'
              }
              error={validateBody.hasError}
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid className={classes.errorMessage}>
            <Typography variant="body2">
              {count > 300
                ? '文字数制限 : 300文字以内で入力してください'
                : null}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{count}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">/300</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.footerWrapper}>
        <FormFooter onClose={handleClose} />
      </DialogActions>
    </form>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(CustomerHistoryThreadForm)))
