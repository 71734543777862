import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { handleOpenPageNewTab } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormLayout from 'components/common/FormLayout'
import DateLabel from 'components/common/DateLabel'
import Loading from 'components/common/Loading'
import { QueryHelper } from 'utils/api.utils'
import Link from '@material-ui/core/Link'
import { useLoginInformation } from 'hooks/useLoginInformation'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { useCustomerOrOwner } from '../../hooks/useCustomerOrOwner'

const EXTERNAL_USER = 5

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  text: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  },
  noReply: {
    color: theme.palette.error.main
  },
  link: {
    color: '#696CFF',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    },
    fontSize: '12px'
  },
  saveButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  cancelButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  },
  buttonPosition: {
    justifyContent: 'center'
  },
  errorMessage: {
    marginRight: 'auto',
    color: theme.palette.c_alert
  },
  positionRelative: {
    position: 'relative',
    marginBottom: '20px'
  },
  textArea: {
    width: '100%',
    paddingBottom: '10px',
    outline: '2px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '50px solid white',
    borderRadius: '4px',
    fontSize: '14px',
    overflowWrap: 'break-word'
  },
  memoDetail: {
    position: 'absolute',
    bottom: '1%',
    left: '1%',
    width: '100%'
  },
  dialogColor: {
    backgroundColor: '#fafbfd'
  },
  right: {
    marginLeft: 'auto'
  }
}))

const CellWithReply = ({ isReply }) => {
  const classes = useStyles()
  if (isReply) {
    return <span>返信済</span>
  }
  return <span className={classes.noReply}>未返信</span>
}

const Summary = props => {
  const { owner, display, application, contract, fetchCustomerData } = props
  const classes = useStyles()
  const content =
    display.kinds == 'messageDetails' ? application : application.application
  const applicationBuilding = application ? application : null
  const [customer, setCustomer] = useState({})
  const [building, setBuilding] = useState({})
  const [room, setRoom] = useState({})
  const [category, setCategoty] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { judgmentRoleId } = useLoginInformation()
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  const fetchCustomer = async () => {
    if (!content || !content.customer_id) {
      return
    }
    const params = {
      id: {
        contains: content.customer_id
      }
    }
    let result = await QueryHelper('getCustomerList', {
      filter: params,
      offset: 0,
      limit: null
    })
    if (result.error) {
      props.enqueueSnackbar('カスタマー一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCustomer(result.data[0])
    }
  }

  const fetchBuilding = async () => {
    if (!content || !content.building_id) return

    const params = {
      id: {
        eq: content.building_id
      }
    }
    const result = await QueryHelper('getBuilding', { filter: params })
    if (result.error) {
      props.enqueueSnackbar('建物情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuilding(result)
    }
  }

  const fetchRoom = async () => {
    if (!content || !content.room_id) return

    const params = {
      id: {
        eq: content.room_id
      }
    }

    const result = await QueryHelper('getRoom', { filter: params })
    if (result.error) {
      props.enqueueSnackbar('部屋情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setRoom(result)
    }
  }

  const fetchCategory = async () => {
    if (!content || !content.category_id) return

    const params = {
      id: {
        eq: content.category_id
      }
    }

    const result = await QueryHelper('getCategory', { filter: params })
    if (result.error) {
      props.enqueueSnackbar('カテゴリ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCategoty(result)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    const fetchCustomerPromise =
      display.kinds === 'messageDetails' && !isOwnerPath
        ? fetchCustomer()
        : null
    const roomPromise =
      display.kinds === 'messageDetails' && !isOwnerPath ? fetchRoom() : null
    await Promise.all([
      fetchCustomerPromise,
      fetchBuilding(),
      roomPromise,
      fetchCategory()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [content, setCustomer])

  useEffect(() => {
    if (content) {
      fetchCustomerData()
    }
  }, [content])

  return (
    <>
      <FormLayout title="概要">
        {!isLoading && content && application ? (
          <>
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" className={classes.text}>
                  {display.kinds == 'messageDetails'
                    ? 'メッセージID'
                    : '申請ID'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {content ? content.id : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {display.kinds == 'messageDetails' ? (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      返信状況
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {content ? (
                        <CellWithReply isReply={content.is_reply} />
                      ) : null}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </>
            ) : null}

            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {display.kinds == 'messageDetails' ? '初回日付' : '申請日時'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {content ? (
                    <DateLabel timestamp={content.created_at} />
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {display.kinds == 'messageDetails'
                    ? '最終返信日付'
                    : '最終更新日'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {content ? (
                    <DateLabel timestamp={content.updated_at} />
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {display.kinds == 'messageDetails' ? 'カテゴリ' : '更新者'}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {display.kinds == 'messageDetails'
                    ? category && category.parent
                      ? category.parent.name
                      : null
                    : application.updated_user &&
                      getFormattedNameByStatus(
                        application.updated_user.name,
                        application.updated_user.status
                      )}
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  {display.kinds == 'messageDetails'
                    ? category && category.category
                      ? category.category.name
                      : null
                    : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {isOwnerPath && owner && owner.owner ? (
              <Grid item xs={12} container spacing={2} className={classes.row}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" className={classes.text}>
                    {'オーナー名'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Link
                      onClick={() =>
                        handleOpenPageNewTab(
                          `/owner/detail/${owner.owner.id}/building/${content.building_id}`
                        )
                      }
                      className={classes.link}
                    >
                      <Typography variant="body2" className={classes.text}>
                        {owner.owner.name}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} container spacing={2} className={classes.row}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" className={classes.text}>
                    {'入居者名'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item xs={12}>
                    {judgmentRoleId() !== EXTERNAL_USER ? (
                      <Link
                        onClick={() =>
                          handleOpenPageNewTab(
                            `/management/contract/detail/${contract.id}`
                          )
                        }
                        className={classes.link}
                      >
                        <Typography variant="body2" className={classes.text}>
                          {display.kinds == 'messageDetails'
                            ? customer.mstkokyaku &&
                              customer.mstkokyaku.KKY_NAME
                            : applicationBuilding.mst_kokyaku_nky
                            ? applicationBuilding.mst_kokyaku_nky.KKY_NAME
                            : null}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography variant="body2" className={classes.text}>
                        {display.kinds == 'messageDetails'
                          ? customer.mstkokyaku && customer.mstkokyaku.KKY_NAME
                          : applicationBuilding.mst_kokyaku_nky
                          ? applicationBuilding.mst_kokyaku_nky.KKY_NAME
                          : null}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" className={classes.text}>
                  物件名
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  {judgmentRoleId() !== EXTERNAL_USER ? (
                    <Link
                      onClick={() =>
                        handleOpenPageNewTab(
                          `/management/building/${content.building_id}`
                        )
                      }
                      className={classes.link}
                    >
                      <Typography variant="body2" className={classes.text}>
                        {display.kinds == 'messageDetails'
                          ? building &&
                            building.mst_tateya &&
                            building.mst_tateya.TTY_NAME
                          : applicationBuilding.mst_tateya
                          ? applicationBuilding.mst_tateya.TTY_NAME
                          : null}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography variant="body2" className={classes.text}>
                      {display.kinds == 'messageDetails'
                        ? building &&
                          building.mst_tateya &&
                          building.mst_tateya.TTY_NAME
                        : applicationBuilding.mst_tateya
                        ? applicationBuilding.mst_tateya.TTY_NAME
                        : null}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {!isOwnerPath && (
              <Grid item xs={12} container spacing={2} className={classes.row}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" className={classes.text}>
                    部屋番号
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item xs={12}>
                    {judgmentRoleId() !== EXTERNAL_USER ? (
                      <Link
                        onClick={() =>
                          handleOpenPageNewTab(
                            `/management/building/${
                              display.kinds == 'messageDetails'
                                ? building.building.id
                                : content.building_id
                            }/room/${
                              display.kinds == 'messageDetails'
                                ? room.room.id
                                : content.room_id
                            }`
                          )
                        }
                        className={classes.link}
                      >
                        <Typography variant="body2" className={classes.text}>
                          {display.kinds == 'messageDetails'
                            ? room && room.mst_heya && room.mst_heya.HYA_ROOM_NO
                            : applicationBuilding.mst_heya
                            ? applicationBuilding.mst_heya.HYA_ROOM_NO
                            : null}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography variant="body2" className={classes.text}>
                        {display.kinds == 'messageDetails'
                          ? room && room.mst_heya && room.mst_heya.HYA_ROOM_NO
                          : applicationBuilding.mst_heya
                          ? applicationBuilding.mst_heya.HYA_ROOM_NO
                          : null}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12} container spacing={1} className={classes.row}>
            <Grid item xs={12} sm={12}>
              <Loading isLoading={props.isLoading || isLoading} />
            </Grid>
          </Grid>
        )}
      </FormLayout>
    </>
  )
}

export default withSnackbar(withRouter(Summary))
