import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTypography-h6': {
      height: '24px',
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  formContent: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  contentDescription: {
    flex: 1,
    display: 'flex',
    marginBottom: '20px',
    paddingLeft: '18px',
    paddingRight: '18px',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#FEF7E0',
    alignItems: 'center'
  },
  descriptionIcon: {
    color: '#FFD422'
  },
  descriptionText: {
    marginLeft: '14px'
  },
  formFooter: {
    marginTop: '8px',
    marginLeft: '16px',
    marginBottom: '8px',
    marginRight: '16px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function FormModalDialog(props) {
  const classes = useStyles()

  const {
    open,
    title,
    description,
    content,
    handleSubmit,
    handleCancel
  } = props

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <Divider />
        <DialogContent className={classes.formContent}>
          {description != '' && (
            <DialogContentText className={classes.contentDescription}>
              <ReportProblemIcon className={classes.descriptionIcon} />
              <Typography className={classes.descriptionText}>
                {description}
              </Typography>
            </DialogContentText>
          )}
          {content}
        </DialogContent>
        <Divider />
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={handleCancel}
            variant="contained"
            className={classes.secondaryButton}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={classes.primaryButton}
          >
            更新
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default FormModalDialog
