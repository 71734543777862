import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setLoadData } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import FileForm from './FileForm'
import Footer from './Footer'
import { Divider, Grid } from '@material-ui/core'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'
import { MutationHelper } from 'utils/api.utils'
import FormControl from '@material-ui/core/FormControl'
import Dropdown from 'components/common/Form/Dropdown'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff'
  }
}))

function Form(props) {
  const classes = useStyles()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()

  const [files, setFiles] = useState([])
  const [dataTypeList] = useState([
    { value: 1, label: '顧客' },
    { value: 2, label: '建物' },
    { value: 3, label: '部屋' },
    { value: 4, label: '契約' },
    { value: 5, label: '契約（月額賃料）' }
  ])
  const [dataType, setDataType] = useState(1)

  const handleSubmit = async event => {
    event.preventDefault()
    props.setLoadData(true)
    if (files.length > 0) {
      const params = {}
      if (files) {
        const creds = await Auth.currentCredentials()
        const attachments = []
        for (let file of files) {
          const result = await fileupload(creds, 'csvs', file)
          attachments.push({
            attachment_type_id: 4,
            mime_type: file.mime_type,
            filename: file.filename,
            key: result.key
          })
        }
        params['attachment'] = attachments[0]
      }
      params['data_link_type_id'] = 2
      params['data_link_state_id'] = 1
      params['data_type_id'] = dataType
      await postDataLinkHistory(params)
      props.setLoadData(false)
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
      props.setLoadData(false)
    }
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.filename.split('.')[1]
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file.body, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: file.mime_type
    })
    console.log(JSON.stringify(result))
    return result
  }

  const postDataLinkHistory = async params => {
    props.setLoadData(true)
    const result = await MutationHelper('createDataLinkHistory', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('ファイルを登録できませんでした。', {
        variant: 'error'
      })
    } else {
      setFiles([])
      setDataType(1)
      props.enqueueSnackbar('ファイルを登録しました。', {
        variant: 'success'
      })
      props.setLoadData(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <FileForm files={files} setFiles={setFiles} />
      <Divider />
      <Grid container style={{ margin: 16 }}>
        <Grid item xs={6}>
          <FormControl required fullWidth>
            <Dropdown
              required
              name="data_type_id"
              label="種別"
              defaultValue={1}
              items={dataTypeList}
              onChange={event => setDataType(event.target.value)}
              disabled={!isUnrestrictedRole}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider />
      <Footer />
    </form>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Form))
