import React, { useEffect, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import RowWithFile from 'components/common/RowWithFile'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

function ChatImage(props) {
  const attachment = props.attachment ? props.attachment : null
  const [image, setImage] = useState(null)

  const handleDelete = () => {}

  useEffect(() => {
    if (attachment) {
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        console.log(key)
        Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            console.log(result)
            const object = Object.assign({}, attachment, { body: result })
            setImage(object)
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }, [attachment, setImage])

  return (
    <>
      {image && (
        <>
          <RowWithFile
            image={image.body}
            title={image.title}
            handleDelete={handleDelete}
            isDeleteButtonView={false}
          />
        </>
      )}
    </>
  )
}

export default withSnackbar(withRouter(ChatImage))
