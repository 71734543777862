import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExistingOwner from './ExistingOwner'
import OwnerCreation from './OwnerCreation'

const useStyles = makeStyles(theme => ({
  section: {
    padding: '10px',
    backgroundColor: theme.palette.c_white.main
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

const FormContent = props => {
  const classes = useStyles()
  const { building, fetchOwnerList } = props

  return (
    <>
      <div className={classes.section}>
        <ExistingOwner building={building} fetchOwnerList={fetchOwnerList} />
      </div>
      <div className={classes.divider} />
      <div className={classes.section}>
        <OwnerCreation building={building} fetchOwnerList={fetchOwnerList} />
      </div>
    </>
  )
}

export default FormContent
