import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTableCell-root': {
      padding: '20px 20px 20px 20px'
    }
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  primaryButton: {
    color: theme.palette.c_blue.contrastText,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  }
}))

function Right(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const handleClick = (event, bookmark) => {
    props.setBookmark(bookmark)
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography className={classes.title}>しおり一覧</Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container justify="flex-end">
              <Grid item zeroMinWidth>
                {/* <Button>並べ替え</Button> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={event => handleClick(event, {})}
              variant="contained"
              startIcon={<AddIcon />}
              disableElevation
              className={classes.primaryButton}
              disabled={!isUnrestrictedRole}
            >
              しおりを追加
            </Button>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <Table stickyHeader aria-label="sticky table">
        <TableBody>
          {props.isLoading ? (
            <Loading isLoading={props.isLoading} marginTop={20} />
          ) : (
            <>
              {props.bookmarkList.length
                ? props.bookmarkList.map(row => {
                    return (
                      <TableRow
                        onClick={event => handleClick(event, row)}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.bookmark.id}
                      >
                        <TableCell>{row.bookmark.name}</TableCell>
                      </TableRow>
                    )
                  })
                : null}
            </>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default withSnackbar(Right)
