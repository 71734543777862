import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Divider from '@material-ui/core/Divider'
import FaqDetail from './FaqDetail'

const useStyles = makeStyles(theme => ({
  section: {
    width: '250px',
    height: '54px',
    padding: '15px 0px 15px 20px'
  },
  wrapperTitle: {
    width: '180px',
    padding: '15px 0px 15px 0px'
  },
  title: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  listItem: {
    paddingLeft: '0px',
    height: '70px'
  },
  listItemText: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    '& > .MuiListItemText-primary': {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '13px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      maxHeight: '3em'
    }
  }
}))

function createFaqDataList(faqList, mediumCategoryId) {
  const faqDataList = []
  for (let data of faqList) {
    if (mediumCategoryId === data.faq.category_id) {
      faqDataList.push(data)
    }
  }
  return faqDataList
}

function FaqList(props) {
  const classes = useStyles()
  const mediumCategory =
    props && props.mediumCategory ? props.mediumCategory : null
  const faqDataList =
    props && props.faqList && props.faqList.length > 0 && mediumCategory
      ? createFaqDataList(props.faqList, mediumCategory.id)
      : null
  const [faq, setFaq] = useState(null)

  return (
    <>
      <div className={classes.section}>
        <Grid container spacing={4}>
          {mediumCategory && (
            <>
              {faq ? (
                <>
                  <IconButton aria-label="new" onClick={() => setFaq(null)}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Grid alignItems="center" className={classes.wrapperTitle}>
                    <Typography className={classes.title}>
                      {faq.question}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <IconButton
                    aria-label="new"
                    onClick={() => props.handleSelectedMediumCategory(null)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Grid alignItems="center" className={classes.wrapperTitle}>
                    <Typography className={classes.title}>
                      {mediumCategory.name}
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </div>
      {faq ? (
        <FaqDetail faq={faq} joinBody={props.joinBody} />
      ) : (
        <>
          {faqDataList &&
            faqDataList.length > 0 &&
            faqDataList.map(data => (
              <>
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setFaq(data.faq)}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={data.faq.question}
                  />
                  <ListItemIcon>
                    <ArrowForwardIosIcon />
                  </ListItemIcon>
                </ListItem>
                <Divider />
              </>
            ))}
        </>
      )}
    </>
  )
}

export default withSnackbar(withRouter(FaqList))
