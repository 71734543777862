import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import { withSnackbar } from 'notistack'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import { handleClear } from 'actions/Actions'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()

  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [dataLinkHistoryList, setDataLinkHistoryList] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const { isLoad } = props

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    setPageNumber(newPage)
  }

  async function fetchDataLinkHistoryList(offset, limit) {
    let result = await QueryHelper(
      'getDataLinkHistoryList',
      {
        filter: { data_link_type_id: { contains: [7, 8] } },
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('データ連携履歴一覧を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setDataLinkHistoryList(result)
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([fetchDataLinkHistoryList(offset, PAGE_LIMIT)])
    setIsLoading(false)
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    fetchData(offset)
  }, [isLoad, pageNumber])

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs className={classes.tableTips}></Grid>
        <Grid item xs={6} className={classes.tableTips}></Grid>
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={dataLinkHistoryList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && dataLinkHistoryList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List dataLinkHistoryList={dataLinkHistoryList.data} />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={dataLinkHistoryList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    isLoad: state.loadData.flag
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
