import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import { Typography } from '@material-ui/core'
import Loading from 'components/common/Loading'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  typography: {
    marginLeft: '12px',
    height: '18px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#72809d',
    fontSize: '12px',
    lineHeight: '18px'
  },
  body: {
    width: '100%',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W6, Hiragino Sans W3',
    fontWeight: '400',
    lineHeight: '1.4em',
    border: 'none',
    backgroundColor: '#f7f8fa'
  },
  helperText: {
    marginLeft: '14px'
  },
  disabledTextField: {
    backgroundColor: '#e0e0e0',
    color: '#9e9e9e'
  }
}))

function MessageTemplateForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const { isLoading, messageTemplate, setEnableForm } = props

  const [values, setValues] = useState({
    title: '',
    title_err: '',
    body: '',
    body_err: ''
  })

  const checkErr = objects => {
    if (objects.title_err !== '' || objects.body_err !== '') {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    const initializeValues = {
      title:
        'message_template' in messageTemplate
          ? messageTemplate.message_template.title
          : '',
      title_err:
        'message_template' in messageTemplate
          ? messageTemplate.message_template.title
            ? ''
            : '入力してください'
          : '',
      body:
        'message_template' in messageTemplate
          ? messageTemplate.message_template.body
          : '',
      body_err:
        'message_template' in messageTemplate
          ? messageTemplate.message_template.body
            ? ''
            : '入力してください'
          : ''
    }
    setValues(initializeValues)
    checkErr(initializeValues)
  }, [messageTemplate, setValues])

  return (
    <FormLayout title="内容">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              error={values.title_err != '' ? true : false}
              helperText={values.title_err}
              required
              id="title"
              name="title"
              label="タイトル"
              fullWidth
              autoComplete="title"
              variant="filled"
              defaultValue={values.title}
              onChange={handleInputRequiredChange}
              inputProps={{ maxLength: 100 }}
              disabled={isRestrictedRole}
            />
            <Typography className={classes.typography}>100文字以内</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              required
              label={'本文'}
              id="body"
              name="body"
              fullWidth
              rowsMin="6"
              className={`${classes.body} ${isRestrictedRole &&
                classes.disabledTextField}`}
              defaultValue={values.body}
              value={values.body}
              onChange={handleInputRequiredChange}
              placeholder={'本文*'}
              maxLength={2000}
              disabled={isRestrictedRole}
            />
            <Typography className={classes.typography}>
              2,000文字以内
            </Typography>
          </Grid>
        </Grid>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageTemplateForm)
