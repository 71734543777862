import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import MoodIcon from '@material-ui/icons/Mood'
import CancelIcon from '@material-ui/icons/Cancel'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  complete: {
    textAlign: 'center',
    height: '114px',
    backgroundColor: '#FFFFFF'
  },
  completeText: {
    fontFamily: 'Hiragino Sans W3',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2C3F58',
    textAlign: 'center',
    paddingTop: '20px'
  },
  completeButtonItem: {
    justifyContent: 'center'
  },
  completeButton: {
    color: theme.palette.c_blue.main,
    backgroundColor: theme.palette.c_white.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.main
    }
  },
  selectedCompleteButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.main
    }
  }
}))
function MessageCompleteDetail(props) {
  const classes = useStyles()
  const message = props && props.message ? props.message : null

  function getCompleteYesButtonClassName(messageTypeId) {
    if (messageTypeId === 5) {
      return classes.selectedCompleteButton
    } else {
      return classes.completeButton
    }
  }

  function getCompleteNoButtonClassName(messageTypeId) {
    if (messageTypeId === 6) {
      return classes.selectedCompleteButton
    } else {
      return classes.completeButton
    }
  }

  return (
    <>
      {(message.message_type_id === 4 ||
        message.message_type_id === 5 ||
        message.message_type_id === 6) && (
        <div className={classes.complete}>
          <p className={classes.completeText}>お問い合わせは解決しましたか？</p>
          <Grid
            container
            alignItems={'center'}
            spacing={2}
            className={classes.completeButtonItem}
          >
            <Grid item alignItems={'center'} xs={6} sm={3}>
              <Button
                variant="contained"
                startIcon={<MoodIcon />}
                className={getCompleteYesButtonClassName(
                  message.message_type_id
                )}
                disabled={message.message_type_id === 4}
              >
                はい
              </Button>
            </Grid>
            <Grid item alignItems={'center'} xs={6} sm={3}>
              <Button
                variant="contained"
                startIcon={<CancelIcon />}
                className={getCompleteNoButtonClassName(
                  message.message_type_id
                )}
                disabled={message.message_type_id === 4}
              >
                いいえ
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default MessageCompleteDetail
