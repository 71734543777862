import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    marginLeft: '32px',
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  }
}))

function Body(props) {
  const classes = useStyles()

  return <Typography className={classes.root}> {props.title} </Typography>
}

export default Body
