import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

export default function TextInput({
  defaultValue = '',
  onChange = () => {},
  ...props
}) {
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  const handleChange = e => {
    setValue(e.target.value)
    onChange(e)
  }
  return <TextField {...props} value={value} onChange={handleChange} />
}
