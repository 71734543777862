import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import SearchTextField from './SearchTextField'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    marginLeft: '32px',
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  primaryButton: {
    marginLeft: '16px',
    marginRight: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  }
}))

function Body(props) {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.root}>{props.title}</Typography>
      <SearchTextField text="入居者名で検索" />
    </>
  )
}

export default Body
