import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog, setLoadData } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { MutationHelper } from 'utils/api.utils'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormFooter from './FormFooter'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { CancellationReason } from 'utils/enum'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  left: {
    flex: 1,
    display: 'flex'
  },
  textRight: {
    textAlign: 'right'
  },
  body: {},

  center: {
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  formControl: {
    width: '100%'
  },
  centerGray: {
    textAlign: 'center',
    color: 'gray'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '15px 20px'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function CancellationInfoFormContent(props) {
  const classes = useStyles()
  const content =
    props.application && props.application.content
      ? parseJson(props.application.content)
      : {}

  const [cancellationInfo, setCancellationInfo] = useState({})

  const orgReasonId =
    content && content.cancellationInfo && content.cancellationInfo.reasonId
      ? content.cancellationInfo.reasonId
      : null
  const [reasonId, setReasonId] = React.useState(orgReasonId)
  const handleChangeReasonId = event => {
    setReasonId(event.target.value)
    setCancellationInfo(
      Object.assign({}, cancellationInfo, {
        [event.target.name]: event.target.value
      })
    )
  }
  const orgTel =
    content && content.cancellationInfo && content.cancellationInfo.tel
      ? content.cancellationInfo.tel
      : null
  const [tel, setTel] = React.useState(orgTel)
  const orgAddress =
    content && content.cancellationInfo && content.cancellationInfo.address
      ? content.cancellationInfo.address
      : null
  const [address, setAddress] = React.useState(orgAddress)
  const orgOther =
    content && content.cancellationInfo && content.cancellationInfo.other
      ? content.cancellationInfo.other
      : null
  const [other, setOther] = React.useState(orgOther)
  const handleChange = event => {
    setCancellationInfo(
      Object.assign({}, cancellationInfo, {
        [event.target.name]: event.target.value
      })
    )
  }

  const handleSubmit = async event => {
    event.preventDefault()
    props.closeModalDialog()
    props.setLoadData(true)
    const params = { id: props.application.id }
    if (Object.keys(cancellationInfo).length > 0) {
      params['cancellationInfo'] = cancellationInfo
    }
    const result = await MutationHelper(
      'updateResidentCancellationApplication',
      { input: params }
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      await props.fetchResidentCancellationApplication(props.application.id)
      setCancellationInfo({})
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
    props.setLoadData(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={4} xs>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <FormControl
              required
              variant="filled"
              className={classes.formControl}
            >
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                解約理由
              </InputLabel>
              <Select
                labelId="reasonId"
                id="reasonId"
                name="reasonId"
                value={reasonId}
                onChange={handleChangeReasonId}
                className={classes.selectEmpty}
              >
                <MenuItem value={CancellationReason.employment.code}>
                  {CancellationReason.employment.value}
                </MenuItem>
                <MenuItem value={CancellationReason.retirement.code}>
                  {CancellationReason.retirement.value}
                </MenuItem>
                <MenuItem value={CancellationReason.transfer.code}>
                  {CancellationReason.transfer.value}
                </MenuItem>
                <MenuItem value={CancellationReason.changeJobs.code}>
                  {CancellationReason.changeJobs.value}
                </MenuItem>
                <MenuItem value={CancellationReason.admission.code}>
                  {CancellationReason.admission.value}
                </MenuItem>
                <MenuItem value={CancellationReason.graduate.code}>
                  {CancellationReason.graduate.value}
                </MenuItem>
                <MenuItem value={CancellationReason.moveCampus.code}>
                  {CancellationReason.moveCampus.value}
                </MenuItem>
                <MenuItem value={CancellationReason.returnToCountry.code}>
                  {CancellationReason.returnToCountry.value}
                </MenuItem>
                <MenuItem value={CancellationReason.marriage.code}>
                  {CancellationReason.marriage.value}
                </MenuItem>
                <MenuItem value={CancellationReason.divorce.code}>
                  {CancellationReason.divorce.value}
                </MenuItem>
                <MenuItem value={CancellationReason.addFamily.code}>
                  {CancellationReason.addFamily.value}
                </MenuItem>
                <MenuItem value={CancellationReason.subtractFamily.code}>
                  {CancellationReason.subtractFamily.value}
                </MenuItem>
                <MenuItem value={CancellationReason.parentsHome.code}>
                  {CancellationReason.parentsHome.value}
                </MenuItem>
                <MenuItem value={CancellationReason.buyingHouse.code}>
                  {CancellationReason.buyingHouse.value}
                </MenuItem>
                <MenuItem value={CancellationReason.changeOfPace.code}>
                  {CancellationReason.changeOfPace.value}
                </MenuItem>
                <MenuItem
                  value={CancellationReason.equipmentDissatisfaction.code}
                >
                  {CancellationReason.equipmentDissatisfaction.value}
                </MenuItem>
                <MenuItem
                  value={CancellationReason.environmentalDissatisfaction.code}
                >
                  {CancellationReason.environmentalDissatisfaction.value}
                </MenuItem>
                <MenuItem
                  value={CancellationReason.buildingDissatisfaction.code}
                >
                  {CancellationReason.buildingDissatisfaction.value}
                </MenuItem>
                <MenuItem
                  value={CancellationReason.otherResidentsDissatisfaction.code}
                >
                  {CancellationReason.otherResidentsDissatisfaction.value}
                </MenuItem>
                <MenuItem value={CancellationReason.otherDissatisfaction.code}>
                  {CancellationReason.otherDissatisfaction.value}
                </MenuItem>
                <MenuItem value={CancellationReason.refusal.code}>
                  {CancellationReason.refusal.value}
                </MenuItem>
                <MenuItem value={CancellationReason.other.code}>
                  {CancellationReason.other.value}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <TextField
              id="tel"
              name="tel"
              label="解約後連絡先"
              defaultValue={tel}
              fullWidth
              required
              variant="filled"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} xs>
          <Grid item xs={6} sm={12} spacing={4} className={classes.left}>
            <TextField
              id="address"
              name="address"
              label="転移先住所"
              defaultValue={address}
              fullWidth
              required
              variant="filled"
              onChange={handleChange}
              helperText="20文字以内"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} xs>
          <Grid item xs={6} sm={12} spacing={4} className={classes.left}>
            <TextField
              id="other"
              name="other"
              label="その他"
              defaultValue={other}
              fullWidth
              required
              variant="filled"
              onChange={handleChange}
              helperText="20文字以内"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.footerWrapper}>
        <FormFooter />
      </DialogActions>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CancellationInfoFormContent))
