import React from 'react'
import DetailLayout from 'components/common/DetailLayout'
import BuildingDetail from './BuildingDetail'

function BuildingDetailContent(props) {
  const { isLoading, building, knrDivList, knrkeitaiDivList } = props

  return (
    <DetailLayout
      title="建物詳細"
      children={
        <BuildingDetail
          building={building}
          knrDivList={knrDivList}
          knrkeitaiDivList={knrkeitaiDivList}
        />
      }
      isLoading={isLoading}
    />
  )
}

export default BuildingDetailContent
