import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { Grid } from '@material-ui/core'
import Label from 'components/common/Label'
import { AppUsage, ContractKykStatusDiv } from 'utils/enum'
import { formatYYYYMMDD } from 'utils/date.utils'
import UseUseLabel from 'components/common/UseUseLabel'

const useStyles = makeStyles(theme => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  checkbox: {
    color: theme.palette.c_purple.main
  },
  name: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  tableRowTypeDefault: {
    backgroundColor: theme.palette.c_background.gray
  }
}))

const columns = [
  { id: '', label: '', align: 'center', minWidth: 50 },
  { id: 'is_usage', label: 'アプリ', align: 'center', minWidth: 100 },
  {
    id: 'Contract_Kyk_Status_Div',
    align: 'center',
    label: '契約',
    minWidth: 100
  },
  {
    id: 'kky_knr_no',
    label: '顧客番号',
    align: 'center',
    minWidth: 100
  },
  { id: 'KYK_NKY_KKY_NAME', label: '入居者名', minWidth: 150 },
  { id: 'KYK_KYS_KKY_NAME', label: '契約者名', minWidth: 150 },
  { id: 'TTY_NAME', label: '契約物件', minWidth: 200 },
  { id: 'HYA_ROOM_NO', label: '部屋番号', minWidth: 100 },
  { id: 'KKY_TEL_02', label: '電話番号', minWidth: 150 },
  {
    id: 'KYK_KEIYAKU_STR_DATE',
    label: '契約日（成約日）',
    minWidth: 150
  },
  {
    id: 'registered_at',
    label: 'アプリ利用開始日',
    minWidth: 170,
    hasSort: true
  },
  {
    id: 'last_login_at',
    label: '最終ログイン日',
    minWidth: 170,
    hasSort: true
  }
]

function KeiyakuStatusLabel({ kykStatusDiv }) {
  if (kykStatusDiv == ContractKykStatusDiv.application.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.application.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.contract.code) {
    return <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.cancellation.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.completed.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.completed.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.payOff.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.payOff.value} />
  } else {
    return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
  }
}

function List(props) {
  const classes = useStyles()

  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => event => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{
              minWidth: column.minWidth,
              width: column.width
            }}
            sortDirection={
              column.hasSort && orderBy === column.id ? order : false
            }
          >
            {column.hasSort ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                className={classes.tableSort}
                onClick={handleSort(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{column.label}</>
            )}
          </TableCell>
        ))}
      </TableHead>
      <TableBody>
        {props.contractList.length
          ? props.contractList.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  className={
                    props.transmittableIdList.indexOf(row.contract.kyk_id) == -1
                      ? classes.tableRowTypeDefault
                      : null
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      value={row.contract.kyk_id}
                      checked={props.checkedIdList.has(row.contract.kyk_id)}
                      onChange={event =>
                        props.handleChange(event, row.contract.kyk_id)
                      }
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      style={{
                        color: '#494bb2'
                      }}
                      disabled={
                        props.transmittableIdList.indexOf(
                          row.contract.kyk_id
                        ) == -1
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {row.customer ? (
                      <UseUseLabel
                        is_usage={row.customer.is_usage}
                        is_management={row.building.is_management}
                        app_user_id={row.customer.app_user_id}
                        kykStatusDiv={row.contract.kyk_status_div}
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.contract ? (
                      <KeiyakuStatusLabel
                        kykStatusDiv={row.contract.kyk_status_div}
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.mst_kokyaku_kyk_nky ? (
                      row.mst_kokyaku_kyk_nky.KKY_KNR_NO
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <Grid item className={classes.name}>
                        <span {...props}>
                          {row.mst_kokyaku_kyk_nky.KKY_NAME}
                        </span>
                      </Grid>
                      <Grid item className={classes.nameKana}>
                        <span {...props}>
                          {row.mst_kokyaku_kyk_nky.KKY_NAME_KANA}
                        </span>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <Grid item className={classes.name}>
                        <span {...props}>
                          {row.mst_kokyaku_kyk_kys.KKY_NAME}
                        </span>
                      </Grid>
                      <Grid item className={classes.nameKana}>
                        <span {...props}>
                          {row.mst_kokyaku_kyk_kys.KKY_NAME_KANA}
                        </span>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>{row.mst_tateya.TTY_NAME}</TableCell>
                  <TableCell>{row.mst_heya.HYA_ROOM_NO}</TableCell>
                  <TableCell>
                    {row.mst_kokyaku_kyk_nky &&
                    row.mst_kokyaku_kyk_nky.KKY_TEL_02
                      ? row.mst_kokyaku_kyk_nky.KKY_TEL_02
                      : null}
                  </TableCell>
                  <TableCell>
                    {row.knr_keiyaku && row.knr_keiyaku.KYK_KEIYAKU_DATE
                      ? formatYYYYMMDD(
                          new Date(row.knr_keiyaku.KYK_KEIYAKU_DATE)
                        )
                      : null}
                  </TableCell>
                  <TableCell>
                    {row.appUser && row.appUser.registered_at
                      ? formatYYYYMMDD(new Date(row.appUser.registered_at))
                      : null}
                  </TableCell>
                  <TableCell>
                    {row.appUser && row.appUser.last_login_at
                      ? formatYYYYMMDD(new Date(row.appUser.last_login_at))
                      : null}
                  </TableCell>
                </TableRow>
              )
            })
          : null}
      </TableBody>
    </Table>
  )
}

export default List
