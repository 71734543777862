import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { TableContainer } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  setCsvInformation,
  setMatchCsvValue,
  setCsvHeader,
  setMatchContractIdList
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import FormControl from '@material-ui/core/FormControl'
import Encoding from 'encoding-japanese'
import { BULK_DELIVERY_CSV_LIMIT } from 'utils/const'
import { utilsCsv } from 'utils/csv.utils'
import LoadingWithBackground from 'components/common/LoadingWithBackground'

const useStyles = makeStyles(theme => ({
  wrapContent: {
    flex: 1,
    display: 'flex',
    margin: '32px'
  },
  section: {
    padding: '15px 20px'
  },
  csvButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_purple.dark,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.main
    },
    padding: '5px 60px',
    marginBottom: '20px'
  },
  dlButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_purple.dark,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.main
    }
  },
  cancelButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  },
  setButton: {
    marginLeft: '25px',
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  right: {
    marginLeft: 'auto'
  },
  container: {
    maxHeight: '400px'
  },
  tableBodyMin: {
    minWidth: '100px'
  },
  tableBodyMax: {
    minWidth: '180px'
  },
  success: {
    color: theme.palette.c_blue.main
  },
  error: {
    color: theme.palette.c_alert
  },
  contractId: {
    backgroundColor: '#B8C9D3',
    padding: 10,
    borderRadius: '5px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  input: {
    display: 'none'
  },
  marginRight: {
    marginRight: '20px'
  },
  select: {
    height: '40px',
    marginRight: '10px'
  },
  buttonWrapper: {
    display: 'flex'
  },
  csvButtonWrapper: {
    display: 'flex'
  },
  limitMessage: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#72809D',
    paddingLeft: '10px',
    paddingTop: '8px'
  }
}))

function ImportCsvData(props) {
  const classes = useStyles()
  const history = useHistory()

  const cancelButtonAlert = () => {
    if (!window.confirm('メッセージ一括配信を中止しますか？')) {
      return
    }
    history.push(`/message/bulk-delivery/list/1`)
  }

  const [file, setFile] = useState(null)
  const [header, setHeader] = useState(null)
  const [body, setBody] = useState(null)
  const [fileName, setFileName] = useState('選択されていません')
  const [count, setCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [dropdown, setDropdown] = useState(null)
  const [menuData, setMenuData] = useState()
  const [importCsvInformation, setImportCsvInformation] = useState(null)
  const [mappingData, setMappingData] = useState([])
  const [useMappingValue, setUseMappingValue] = useState(null)
  const [selectedMenuIndex, setSelectedMenuIndex] = useState()
  const [successCount, setSuccessCount] = useState(0)
  const [selectedItem, setSelectedItem] = useState('')
  const [loading, setLoading] = useState(false)

  const clearLoadedFile = () => {
    setFile(null)
    setBody(null)
    setHeader(null)
    setCount(0)
    setSuccessCount(0)
    setSelectedItem('')
    setFileName('選択されていません')
  }

  const parseCSV = loadedFile => {
    if (loadedFile == null) {
      return null
    }
    let tmp = []
    loadedFile = loadedFile.replace(/("[^"]*")+/g, match => {
      tmp.push(match.slice(1, -1))
      return '[TMP]'
    })

    const fileArray = loadedFile
      .split('\n')
      .filter(row => row !== '')
      .map(row => {
        return row.split(',').map(val => {
          if (val === '[TMP]' || val === '[TMP]\r') {
            return tmp.shift()
          } else {
            return val
          }
        })
      })

    if (loadedFile.length === 0) {
      return null
    }

    const numColumns = fileArray[0].length
    for (let i = 1; i < fileArray.length; i++) {
      if (fileArray[i].length !== numColumns) {
        clearLoadedFile()
        return null
      }
    }

    setErrorMessage(null)
    return fileArray
  }

  const handleChange = async e => {
    if (e.target.files.length === 0) {
      return
    }
    const chosenFile = e.target.files[0]
    if (chosenFile.size > BULK_DELIVERY_CSV_LIMIT) {
      setErrorMessage('3MBまでのCSVを取り込んでください')
      return
    }
    if (chosenFile.name.slice(-4) !== '.csv') {
      setErrorMessage('ファイル形式が異なります')
      return
    }
    setLoading(true)
    await Promise.all([importCsv(chosenFile), fetchContractIdList(0, 0)])
    setLoading(false)
  }

  const importCsv = chosenFile => {
    setSelectedItem('')
    clearLoadedFile()

    setFileName(chosenFile.name)

    const reader = new FileReader()
    reader.readAsArrayBuffer(chosenFile)
    reader.onload = e => {
      const loadedFile = new Uint8Array(reader.result)
      // データ型を判別
      const encoding = Encoding.detect(loadedFile)
      var unicodeArray = []
      // データ型がUTF-8、SJISの場合ifに入り、encodingする
      // データ型がUNICODEの場合elseに入り、SJISとして扱いencodingする
      if (encoding !== 'UNICODE') {
        unicodeArray = Encoding.convert(loadedFile, {
          to: 'UNICODE',
          from: encoding
        })
      } else {
        unicodeArray = Encoding.convert(loadedFile, {
          to: 'UNICODE',
          from: 'SJIS'
        })
      }
      var newFile = Encoding.codeToString(unicodeArray)
      setImportCsvInformation(newFile)
      const fileArray = parseCSV(newFile)
      if (fileArray) {
        if (fileArray.length > 10001) {
          setErrorMessage(
            '取込するCSVファイルは1万件以内で、ファイルサイズは3MB以下としてください。'
          )
          clearLoadedFile()
        } else {
          const dropdownObj = {}
          fileArray[0].forEach((title, index) => (dropdownObj[index] = title))
          setDropdown(dropdownObj)
          setFile(fileArray)
          setHeader(fileArray.shift())
          setBody(fileArray)
          setCount(fileArray.length)
        }
      } else {
        setErrorMessage('ファイルを取り込めませんでした')
        clearLoadedFile()
      }
    }
    setSuccessCount(0)
  }

  const handleClick = e => {
    if (file == null) {
      return
    }

    if (!window.confirm('ファイルを上書きしますか？')) {
      e.preventDefault()
      return
    }
  }

  const goToDestinationSettingSreen = () => {
    if (successCount == 0) {
      props.enqueueSnackbar('マッピング結果が0件のため確定できません', {
        variant: 'warning'
      })
      return
    }
    let formatMenuData = []
    props.setCsvInformation({
      importCsvInformation
    })
    props.setMatchCsvValue({
      matchCsvValue: useMappingValue
    })
    props.setCsvHeader({
      csvHeader: header
    })
    menuData.map(value => formatMenuData.push(utilsCsv(value)))
    props.setMatchContractIdList({
      matchContractIdList: formatMenuData
    })
    history.push('/message/bulk-delivery/select/:id')
  }

  const dropdownHandleChange = event => {
    const selectedMenuIndex = Object.keys(dropdown).find(
      key => dropdown[key] === event.target.value
    )
    const selectedMenuData = body.map(row => row[selectedMenuIndex])
    setMenuData(selectedMenuData)
    setUseMappingValue(event.target.value)
    setSelectedMenuIndex(selectedMenuIndex)
    setSelectedItem(event.target.value)

    let count = 0
    file.map(row => {
      const contractId = row[selectedMenuIndex]
      if (mappingData.find(value => value == contractId)) {
        count++
      }
    })
    setSuccessCount(count)
  }

  async function fetchContractIdList(offset, limit) {
    var filter = {}

    if (menuData != null) {
      var numberArray = menuData.map(Number)
      filter['kyk_id_list'] = { eq: numberArray }
    }

    let result = await QueryHelper(
      'getContractIdList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
    } else {
      setMappingData(result.id)
    }
  }
  let TempData = []

  const downloadCsv = async () => {
    if (!importCsvInformation) {
      props.enqueueSnackbar('CSVを登録してください', {
        variant: 'error'
      })
      return
    }

    header.push('結果')
    TempData.push(header)

    file.map(row => {
      const contractId = row[selectedMenuIndex]
      if (mappingData.find(value => value == contractId)) {
        row.push('成功')
      } else {
        row.push('失敗')
      }
      TempData.push(row)
      return TempData
    })

    const newTempData = []
    TempData.map(item => {
      const hasLF = item[item.length - 2].includes('\r')
      if (hasLF) {
        item[item.length - 2] = item[item.length - 2].replace('\r', '')
      }
      newTempData.push(item)
    })

    var str = ''
    var numberHeaderLines = Object.keys(newTempData[0]).length
    for (var i = 0; i < numberHeaderLines; i++) {
      if (i == 0) {
        str += '"' + newTempData[0][i] + '",'
      } else if (i == numberHeaderLines - 1) {
        str += newTempData[0][i] + '\n'
      } else {
        str += '"' + newTempData[0][i] + '",'
      }
    }
    var len_element = Object.keys(newTempData).length
    for (var i = 0; i < len_element - 1; i++) {
      var len_tempData = Object.keys(newTempData[i + 1]).length
      for (var j = 0; j < len_tempData; j++) {
        if (j == len_tempData - 1) {
          str += newTempData[i + 1][j] + '\n'
        } else {
          str += '"' + newTempData[i + 1][j] + '",'
        }
      }
    }

    var newStr = Encoding.stringToCode(str)
    var sjisData = Encoding.convert(newStr, 'sjis', 'unicode')
    var u8a = new Uint8Array(sjisData)
    var blob = new Blob([u8a], { type: 'text/csv' })
    var link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'tempdata.csv'
    link.click()
    header.pop()
    body.forEach(row => row.pop())
  }

  return (
    <div className={classes.wrapContent}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <div className={classes.section}>
              <Typography variant="h6" gutterBottom>
                CSVデータ取り込み
              </Typography>
              <div className={classes.csvButtonWrapper}>
                <input
                  accept="text/csv"
                  type="file"
                  id="contained-button-file"
                  onChange={handleChange}
                  onClick={e => {
                    e.target.value = null
                    handleClick(e)
                  }}
                  className={classes.input}
                />
                <label
                  htmlFor="contained-button-file"
                  className={classes.labelButtonSecond}
                >
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<AddIcon />}
                    className={classes.csvButton}
                  >
                    CSV登録
                  </Button>
                </label>
                <div className={classes.limitMessage}>
                  ※取込できるCSVファイルの上限件数は1万件で、ファイルサイズ上限は3MBです。
                </div>
              </div>
              {file && !errorMessage ? (
                <Typography variant="body2" className={classes.success}>
                  取り込み成功しました
                </Typography>
              ) : (
                <Typography variant="body2" className={classes.error}>
                  {errorMessage}
                </Typography>
              )}
            </div>
            {file && !errorMessage ? (
              <div>
                <Divider variant="middle" />
                <div className={classes.section}>
                  <Typography variant="body1" gutterBottom>
                    データ取り込み結果
                  </Typography>
                  <Grid container alignItems="center">
                    <Grid>
                      <Typography variant="body2">
                        ファイル名: {fileName}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton color="light_gray" aria-label="delete">
                        <DeleteIcon
                          fontSize="large"
                          onClick={clearLoadedFile}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Typography variant="body2">
                    レコード件数: {count}件
                  </Typography>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {file && !errorMessage ? (
              <div>
                <Divider variant="middle" />
                <div className={classes.section}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item>
                          <FormControl
                            variant="filled"
                            fullWidth
                            hiddenLabel={true}
                          >
                            <Select
                              onChange={dropdownHandleChange}
                              value={selectedItem}
                              className={classes.select}
                            >
                              {header
                                ? header.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            をtotono上の
                            <span className={classes.contractId}>契約ID</span>
                            とマッチさせる
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item className={classes.marginRight}>
                          データマッピング結果: {successCount}件（成功）/
                          {count}件 結果CSV
                        </Grid>
                        <Grid item>
                          <Button
                            component={Link}
                            variant="contained"
                            className={classes.dlButton}
                            onClick={() => downloadCsv()}
                          >
                            DL
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {file ? (
              <Typography variant="body2" className={classes.section}>
                [取込データ]
              </Typography>
            ) : (
              ''
            )}
            <Paper>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {header
                        ? header.map((head, index) => {
                            return <TableCell key={index}>{head}</TableCell>
                          })
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {body
                      ? body.map((row, index) => {
                          if (index < 5) {
                            return (
                              <TableRow key={index}>
                                {row.map((val, index) => {
                                  return (
                                    <TableCell
                                      className={
                                        val.length < 5
                                          ? `${classes.tableBodyMin}`
                                          : `${classes.tableBodyMax}`
                                      }
                                      key={index}
                                    >
                                      {val}
                                    </TableCell>
                                  )
                                })}
                              </TableRow>
                            )
                          } else {
                            return null
                          }
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {file && !errorMessage ? (
              <div
                className={`${classes.section} ${classes.buttonWrapper} ${classes.right}`}
              >
                <Button
                  className={`${classes.cancelButton} ${classes.right}`}
                  variant="contained"
                  onClick={cancelButtonAlert}
                >
                  キャンセル
                </Button>
                <Button
                  component={Link}
                  variant="contained"
                  className={classes.setButton}
                  onClick={() => goToDestinationSettingSreen()}
                >
                  確定
                </Button>
              </div>
            ) : (
              <div></div>
            )}
          </Paper>
        </Grid>
      </Grid>
      <LoadingWithBackground isLoading={loading} />
    </div>
  )
}
const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setCsvInformation: parameter => {
      dispatch(setCsvInformation(parameter))
    },
    setMatchCsvValue: parameter => {
      dispatch(setMatchCsvValue(parameter))
    },
    setCsvHeader: parameter => {
      dispatch(setCsvHeader(parameter))
    },
    setMatchContractIdList: parameter => {
      dispatch(setMatchContractIdList(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ImportCsvData))
