import React, { useEffect, useState } from 'react'
import { useHistory, useParams, withRouter } from 'react-router-dom'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { fromCode } from 'utils/enum.utils'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { AppIsUsage, ContractKykStatusDiv } from 'utils/enum'
import Label from 'components/common/Label'
import Dropdown from 'components/common/Form/Dropdown'
import FormLayout from 'components/common/FormLayout'
import { formatYYYYMMDD } from 'utils/date.utils'
import { withSnackbar } from 'notistack'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { setLoadData, handleOpenPage } from 'actions/Actions'
import UseUseLabel from 'components/common/UseUseLabel'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  header: {
    padding: '16px 24px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  divider: {
    marginLeft: '16px'
  },
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  label: {
    textAlign: 'end'
  },
  link: {
    color: '#696CFF',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px'
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  },
  columnValue1: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#696CFF',
    fontSize: '14px',
    lineHeight: '21px'
  },
  gridLink: {
    cursor: 'pointer'
  }
}))

function KeiyakuStatusLabel({ kykStatusDiv }) {
  if (kykStatusDiv == ContractKykStatusDiv.application.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.application.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.contract.code) {
    return <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.cancellation.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.completed.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.completed.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.payOff.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.payOff.value} />
  } else {
    return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
  }
}

function Right(props) {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const [knrKeiyaku, setKnrKeiyaku] = useState({})
  const [mstTateya, setMstTateya] = useState({})
  const [building, setBuilding] = useState({})
  const [mstHeya, setMstHeya] = useState({})
  const [room, setRoom] = useState({})
  const [customer, setCustomer] = useState({})
  const [contract, setContract] = useState({})
  const [appUser, setAppUser] = useState({})
  const [contractList, setContractList] = useState([])
  const [isUsage, setIsUsage] = useState(null)
  const [orgIsUsage, setOrgIsUsage] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [dropdownState, setDropdownState] = useState(false)
  const { role12 } = useRole()
  const isUnrestrictedRole = role12()

  useEffect(() => {
    // -1で発火された後に正しい値で上書きする
    setIsUsage(orgIsUsage)
  }, [isUsage])

  const handleChangeIsUsage = event => {
    const appIsUsage = fromCode(AppIsUsage, event.target.value)
    if (
      !window.confirm(
        `入居者が入居しているすべての物件が${appIsUsage.value}になります。`
      )
    ) {
      // 同値だとuseEffectが反応しないため、-1を入れる
      setIsUsage(-1)
      return
    }
    event.preventDefault()
    setOrgIsUsage(event.target.value)
    updateCustomer(customer, event.target.value)
  }

  const updateCustomer = async (customer, isUsage) => {
    props.setLoadData(true)
    const params = {
      id: customer.id,
      is_usage: isUsage === AppIsUsage.enabled.code
    }
    const result = await MutationHelper('updateCustomer', { input: params })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
    props.setLoadData(false)
  }

  const fetchContract = async customerId => {
    const params = {
      id: {
        eq: customerId
      }
    }
    const result = await QueryHelper('getContract2', { filter: params })
    if (!result.error) {
      setKnrKeiyaku(result.knr_keiyaku)
      setMstTateya(result.mst_tateya)
      setMstHeya(result.mst_heya)
      setCustomer(result.customer)
      setContract(result.contract)
      setAppUser(result.appUser)
      if (
        result.customer.is_usage &&
        result.contract.kyk_status_div !==
          ContractKykStatusDiv.completed.code &&
        result.contract.kyk_status_div !== ContractKykStatusDiv.payOff.code &&
        result.contract.kyk_status_div !== ContractKykStatusDiv.cancel.code
      ) {
        setIsUsage(1)
        setOrgIsUsage(1)
      } else {
        setIsUsage(0)
        setOrgIsUsage(0)
      }

      await Promise.all([
        fetchRoom(result.mst_heya.HYA_ID),
        fetchBuildings(result.mst_tateya.TTY_ISP_TTY_ID),
        fetchContractList(result.customer.id)
      ])
    } else {
      props.enqueueSnackbar(`Contractを取得できませんでした。`)
    }
  }

  const fetchRoom = async hyaId => {
    const params = {
      hya_id: {
        eq: hyaId
      }
    }
    const result = await QueryHelper('getRoom', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`部屋を取得できませんでした。`)
    } else {
      setRoom(result.room)
    }
  }

  const fetchBuildings = async ttyIspId => {
    const params = {
      tty_isp_tty_id: {
        eq: ttyIspId
      }
    }
    const result = await QueryHelper('getBuilding', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`建物を取得できませんでした。`)
    } else {
      setBuilding(result.building)
    }
  }

  const fetchContractList = async tenantId => {
    const params = {
      tenant_id: {
        eq: tenantId
      }
    }
    const result = await QueryHelper('getContractList2', { filter: params })
    if (!result.error) {
      setContractList(result.data)
    } else {
      props.enqueueSnackbar(`Contract一覧を取得できませんでした。`)
    }
  }

  const fetchData = async contractId => {
    setIsLoading(true)
    await Promise.all([fetchContract(contractId)])
    setIsLoading(false)
  }

  useEffect(() => {
    if (
      knrKeiyaku.KYK_STATUS_DIV === ContractKykStatusDiv.completed.code ||
      knrKeiyaku.KYK_STATUS_DIV === ContractKykStatusDiv.payOff.code ||
      knrKeiyaku.KYK_STATUS_DIV === ContractKykStatusDiv.cancel.code ||
      !isUnrestrictedRole
    ) {
      setDropdownState(true)
    }
  }, [knrKeiyaku])

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id)
    }
  }, [props])

  return (
    <>
      <div className={classes.header}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.title}>アプリの状態</Typography>
          </Grid>
          <Grid item xs={6} justify="flex-end" className={classes.label}>
            {isLoading ? (
              <Loading isLoading={isLoading} />
            ) : (
              <>
                {customer && (
                  <UseUseLabel
                    is_usage={customer.is_usage}
                    is_management={building.is_management}
                    app_user_id={customer.app_user_id}
                    kykStatusDiv={knrKeiyaku.KYK_STATUS_DIV}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} justify="flex-start">
            {isLoading ? (
              <Loading isLoading={isLoading} />
            ) : (
              <>
                <Dropdown
                  label="アプリの状態"
                  name="status"
                  onChange={handleChangeIsUsage}
                  defaultValue={isUsage}
                  disabled={dropdownState}
                  items={[
                    {
                      value: AppIsUsage.enabled.code,
                      label: AppIsUsage.enabled.value
                    },
                    {
                      value: AppIsUsage.disabled.code,
                      label: AppIsUsage.disabled.value
                    }
                  ]}
                  color="primary"
                />
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <Divider />

      <FormLayout title="入居者概要">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            <Grid container xs={12} spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  物件名
                </Typography>
              </Grid>
              {mstTateya ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.gridLink}
                  onClick={e =>
                    handleOpenPage(
                      `/management/building/${building.id}`,
                      e,
                      history
                    )
                  }
                >
                  <Typography align="right" className={classes.columnValue1}>
                    {mstTateya.TTY_NAME}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}></Grid>
              )}
            </Grid>
            <Divider />
            <Grid container xs={12} spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  部屋番号
                </Typography>
              </Grid>
              {mstHeya ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.gridLink}
                  onClick={e =>
                    handleOpenPage(
                      `/management/building/${building.id}/room/${room.id}`,
                      e,
                      history
                    )
                  }
                >
                  <Typography align="right" className={classes.columnValue1}>
                    {mstHeya.HYA_ROOM_NO}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}></Grid>
              )}
            </Grid>
            <Divider />
            <Grid container xs={12} spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  契約状況
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  align="right"
                  className={classes.content}
                >
                  {knrKeiyaku ? (
                    <KeiyakuStatusLabel
                      kykStatusDiv={knrKeiyaku.KYK_STATUS_DIV}
                    />
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container xs={12} spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  契約開始日
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  align="right"
                  className={classes.content}
                >
                  {knrKeiyaku && knrKeiyaku.KYK_KEIYAKU_STR_DATE
                    ? formatYYYYMMDD(new Date(knrKeiyaku.KYK_KEIYAKU_STR_DATE))
                    : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  アプリ利用開始日
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  align="right"
                  className={classes.content}
                >
                  {appUser && appUser.registered_at
                    ? formatYYYYMMDD(new Date(appUser.registered_at))
                    : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  最終ログイン日
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  align="right"
                  className={classes.content}
                >
                  {appUser && appUser.last_login_at
                    ? formatYYYYMMDD(new Date(appUser.last_login_at))
                    : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </>
        )}
      </FormLayout>
      <Divider />
      {contractList && contractList.length > 1 && (
        <>
          <FormLayout title="その他契約物件">
            {contractList.map(row => {
              if (
                row.building.id === building.id &&
                row.contract.room_id === contract.room_id
              ) {
                return <></>
              }
              return (
                <>
                  <Grid container xs={12} spacing={2} className={classes.row}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" className={classes.content}>
                        物件名
                      </Typography>
                    </Grid>
                    {row.mst_tateya ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.gridLink}
                        onClick={e =>
                          handleOpenPage(
                            `/management/building/${row.building.id}`,
                            e,
                            history
                          )
                        }
                      >
                        <Typography
                          align="right"
                          className={classes.columnValue1}
                        >
                          {row.mst_tateya.TTY_NAME}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6}></Grid>
                    )}
                  </Grid>
                  <Divider />
                  <Grid container xs={12} spacing={2} className={classes.row}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" className={classes.content}>
                        部屋番号
                      </Typography>
                    </Grid>
                    {row.mst_heya ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.gridLink}
                        onClick={e =>
                          handleOpenPage(
                            `/management/building/${row.building.id}/room/${row.contract.room_id}`,
                            e,
                            history
                          )
                        }
                      >
                        <Typography
                          align="right"
                          className={classes.columnValue1}
                        >
                          {row.mst_heya.HYA_ROOM_NO}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6}></Grid>
                    )}
                  </Grid>
                  <Divider />
                </>
              )
            })}
          </FormLayout>
          <Divider />
        </>
      )}
    </>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(Right)))
