import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import FormLayout from 'components/common/FormLayout'
import Dropdown from 'components/common/Form/Dropdown'
import { QueryHelper } from 'utils/api.utils'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

function CategoryForm({ isLoading, faq, ...props }) {
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const [children, setChildren] = useState([])
  const [parents, setParents] = useState([])
  const [selectedParent, setSelectedParent] = useState('')
  const [selectedChildren, setSelectedChildren] = useState('')
  const { large_category, medium_category } = faq
  const [isLoading2, setIsLoading2] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (large_category) setSelectedParent(large_category.id)
    if (medium_category) setSelectedChildren(medium_category.id)
  }, [large_category, medium_category])

  const fetchData = async () => {
    setIsLoading2(true)
    await Promise.all([getCategories()])
    setIsLoading2(false)
  }

  const getCategories = async () => {
    const result = await QueryHelper(
      'getLargeCategoryAndMediumCategory',
      {},
      true
    )
    if (!result.error) {
      let parentsList = result.map(({ large_categories }) => ({
        value: large_categories.id,
        label: large_categories.name
      }))

      let childrenList = {}
      result.map(({ medium_categories }) => {
        medium_categories.map(({ id, parent_id, name }) => {
          if (!childrenList[parent_id]) childrenList[parent_id] = []
          childrenList[parent_id] = [
            ...childrenList[parent_id],
            { value: id, label: name }
          ]
        })
      })

      setParents(parentsList)
      setChildren(childrenList)
    }
  }

  const handlechange = event =>
    props.handlechange({ [event.target.name]: event.target.value })

  const handleParent = event => {
    setSelectedParent(event.target.value, setSelectedChildren(''))
    props.handlechange({ category_id: null })
  }

  return (
    <FormLayout title="カテゴリー">
      {isLoading || isLoading2 ? (
        <Loading isLoading={isLoading || isLoading2} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Dropdown
                required
                name="category"
                label="大カテゴリー"
                items={parents}
                defaultValue={selectedParent}
                onChange={handleParent}
                disabled={isRestrictedRole}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                required
                name="category_id"
                label={
                  children[selectedParent]
                    ? '中カテゴリー'
                    : '大カテゴリを選択してください'
                }
                onChange={handlechange}
                defaultValue={selectedChildren}
                disabled={!children[selectedParent] || isRestrictedRole}
                items={children[selectedParent]}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormLayout>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    handlechange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(null, mapDispatchToProps)(CategoryForm)
