import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import { handleOpenPage } from 'actions/Actions'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { getKnrDiv, getKnrkeitaiDiv } from 'utils/building.utils'

const useStyles = makeStyles(() => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  name: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2C3F58'
  },
  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809D'
  }
}))

const columns = [
  { id: 'TTY_KNR_NO', label: '建物番号', minWidth: 68 },
  { id: 'TTY_NAME', label: '物件名', minWidth: 200 },
  { id: 'TTY_PRF_NAME', label: '所在地', minWidth: 250 },
  { id: 'TTY_TYS_NAME', label: '種別', minWidth: 68 },
  { id: 'TTY_KNR_DIV', label: '区分', minWidth: 88 },
  { id: 'TTY_KNR_KIND', label: '形態', minWidth: 68 },
  { id: 'TTY_KNRPATTERN_NAME', label: '管理パターン', minWidth: 100 },
  { id: 'TTY_TNP_NAME', label: '建物取扱店舗', minWidth: 100 },
  { id: 'TTY_TOTAL_COUNT', label: '総戸数', minWidth: 73 },
  {
    id: 'room_cnt',
    label: '対象戸数',
    minWidth: 55,
    maxWidth: 55,
    hasSort: true
  },
  {
    id: 'contract_cnt',
    label: '入居者数',
    minWidth: 55,
    maxWidth: 55,
    hasSort: true
  },
  {
    id: 'customer_cnt',
    label: '利用者',
    minWidth: 55,
    maxWidth: 55,
    hasSort: true
  },
  { id: 'attachment_cnt', label: 'ドキュメント', minWidth: 73 }
]

function List(props) {
  const history = useHistory()
  const classes = useStyles()
  const isTextSelected = useIsTextSelected()

  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => () => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(`/management/building/${id}`, e, history)
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  width: column.width
                }}
                sortDirection={
                  column.hasSort && orderBy === column.id ? order : false
                }
              >
                {column.hasSort ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    className={classes.tableSort}
                    onClick={handleSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <>{column.label}</>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.buildingList.length
            ? props.buildingList.map(row => {
                return (
                  <TableRow
                    onClick={e => navigateToDetailPage(e, row.building.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.building.id}
                  >
                    <TableCell className={classes.name}>
                      {row.mst_tateya.TTY_KNR_NO}
                    </TableCell>
                    <TableCell>
                      <Grid container xs={12} alignItems="left">
                        <Grid
                          item
                          xs={12}
                          className={classes.name}
                          alignItems="left"
                        >
                          {row.mst_tateya.TTY_NAME}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={classes.nameKana}
                          alignItems="left"
                        >
                          {row.mst_tateya.TTY_NAME_KANA}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.mst_tateya.TTY_PRF_NAME}{' '}
                      {row.mst_tateya.TTY_CTY_NAME}{' '}
                      {row.mst_tateya.TTY_TWN_NAME} {row.mst_tateya.TTY_ADDR_01}{' '}
                      {row.mst_tateya.TTY_ADDR_02}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.mst_tateya.TTY_TYS_NAME}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {getKnrDiv(row.mst_tateya, props.knrDivList)}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {getKnrkeitaiDiv(row.mst_tateya, props.knrkeitaiDivList)}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.mst_tateya.TTY_KNRPATTERN_NAME}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.mst_tateya.TTY_TNP_NAME}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.mst_tateya.TTY_TOTAL_COUNT}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.building.room_cnt}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.building.contract_cnt}
                    </TableCell>
                    <TableCell className={classes.name}>
                      {row.building.customer_cnt}
                    </TableCell>
                    <TableCell>
                      {row.attachments && row.attachments.length > 0 ? (
                        <Grid container alignItems="center">
                          <PictureAsPdfIcon key={`PDF`} />
                          {row.attachments.length}
                        </Grid>
                      ) : (
                        0
                      )}
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
