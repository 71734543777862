import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

const useStyle = makeStyles(theme => {
  return {
    button: {
      minWidth: '100px',
      backgroundColor: 'white',
      border: '1px solid #e5e5e5',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.c_primary.lightest
      }
    },
    active: {
      color: 'white',
      backgroundColor: theme.palette.c_primary.main
    }
  }
})

export default function RadioButtons({
  current,
  handleSelectedButton,
  buttonsData,
  ...props
}) {
  const classes = useStyle()
  return (
    <div style={{ textAlign: 'center' }} {...props}>
      <ButtonGroup aria-label="large button group">
        {buttonsData.map(({ label, value }) =>
          value === current ? (
            <Button
              className={`${classes.button} ${classes.active}`}
              key={value}
              styled={{ backgroundColor: '#abcdef' }}
            >
              {label}
            </Button>
          ) : (
            <Button
              className={`${classes.button} ${classes.alert}`}
              key={value}
              onClick={e => handleSelectedButton(value)}
            >
              {label}
            </Button>
          )
        )}
      </ButtonGroup>
    </div>
  )
}
