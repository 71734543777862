import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EditContent from './EditContent'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  }
}))

function EditScreen(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <EditContent />
      </div>
    </div>
  )
}

export default EditScreen
