import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import List from './List'
import Pagination from 'components/common/Pagination'
import { closeSearchSaveModalDialog } from 'actions/Actions'
import { withSnackbar } from 'notistack'
import { ScreenUrl } from 'utils/enum'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  primaryButton: {
    color: theme.palette.c_blue.contrastText,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const { pageId } = useParams()

  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined
      ? location.state
      : props.messageTemplateSearchParams
  )
  const [messageTemplateList, setMessageTemplateList] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    var params = Object.assign({}, searchCondition, { pageNumber: newPage + 1 })
    setSearchCondition(params, setPageNumber(newPage + 1))
  }

  const handleCustomChangePage = newPage => {
    var params = Object.assign({}, searchCondition, { pageNumber: newPage })
    setSearchCondition(params, setPageNumber(newPage))
  }

  async function fetchCompanyGroupList(offset, limit) {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'management.messageTemplate.title') {
          filter['title'] = {
            contains: searchCondition[key]
          }
        }
      }
    })
    let result = await QueryHelper(
      'getMessageTemplateList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('定型文一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setMessageTemplateList(result)
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([fetchCompanyGroupList(offset, PAGE_LIMIT)])
    setIsLoading(false)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.managementMessageTemplateList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, searchCondition, setMessageTemplateList])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.managementMessageTemplateList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs className={classes.tableTips}>
          <Button
            onClick={e =>
              history.push({
                pathname: `/management/message-template/new`,
                state: searchCondition
              })
            }
            variant="contained"
            startIcon={<AddIcon />}
            disableElevation
            className={classes.primaryButton}
            disabled={isRestrictedRole}
          >
            定型文を追加
          </Button>
        </Grid>
        <Grid item xs={5} className={classes.tableTips}></Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={messageTemplateList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && messageTemplateList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              messageTemplateList={messageTemplateList.data}
              searchCondition={searchCondition}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={messageTemplateList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.managementMessageTemplateList}
            searchConditions={searchCondition}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    messageTemplateSearchParams: state.saveSearchCondition.messageTemplate
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
