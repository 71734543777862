import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Dropdown from 'components/common/Form/Dropdown'
import FormLayout from 'components/common/FormLayout'
import Loading from 'components/common/Loading'
import {
  ThreadState,
  ResidentInfoChangeApplicationState,
  ResidentCancellationApplicationState,
  RoomCheckListApplicationState,
  BicycleParkingStickerApplicationState,
  CertificateOfConsentToUseParkingSpaceState,
  PetApplicationState,
  ProjectStatus
} from 'utils/enum'
import { fromCode } from 'utils/enum.utils'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { NotificationContext } from 'context/NotificationContext'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { formatYYYYMMDD, formatTime } from 'utils/date.utils'
import { useLoginInformation } from 'hooks/useLoginInformation'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { useCustomerOrOwner } from '../../hooks/useCustomerOrOwner'

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  text: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  },
  noReply: {
    color: theme.palette.error.main
  },
  link: {
    color: '#696CFF',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    },
    fontSize: '12px'
  },
  saveButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  cancelButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  },
  buttonPosition: {
    justifyContent: 'center'
  },
  matterButtonPosition: {
    justifyContent: 'right',
    padding: '0px 20px 20px 0px'
  },
  errorMessage: {
    marginRight: 'auto',
    color: theme.palette.c_alert
  },
  positionRelative: {
    position: 'relative',
    marginBottom: '20px'
  },
  textArea: {
    width: '100%',
    paddingBottom: '10px',
    outline: '2px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '50px solid white',
    borderRadius: '4px',
    fontSize: '14px',
    overflowWrap: 'break-word',
    padding: '0px 10px'
  },
  memoDetail: {
    position: 'absolute',
    bottom: '1%',
    left: '1%',
    width: '100%',
    padding: '0px 10px'
  },
  dialogColor: {
    backgroundColor: '#fafbfd'
  },
  right: {
    marginLeft: 'auto'
  },
  correspondenceArea: {
    padding: '10px 0px'
  },
  matterArrangementButton: {
    textAlign: 'center',
    padding: '15px 0px'
  },
  selectMenu: {
    backgroundColor: '#FFF'
  },
  matterModalSubTitle: {
    paddingBottom: '10px'
  },
  memoArea: {
    paddingTop: '10px'
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  disabledIconStyle: {
    color: theme.palette.c_gray.main,
    pointerEvents: 'none'
  }
}))

const projectStatusList = [
  { value: ProjectStatus.none.code, label: ProjectStatus.none.value },
  { value: ProjectStatus.order.code, label: ProjectStatus.order.value },
  {
    value: ProjectStatus.preparingSupported.code,
    label: ProjectStatus.preparingSupported.value
  },
  { value: ProjectStatus.supported.code, label: ProjectStatus.supported.value },
  {
    value: ProjectStatus.supportedComplete.code,
    label: ProjectStatus.supportedComplete.value
  },
  {
    value: ProjectStatus.suspension.code,
    label: ProjectStatus.suspension.value
  }
]

const createCompanyGroupItems = companyUserList => {
  const companyGroupIdSet = new Set()
  const companyGroupItems = []
  if (companyUserList && companyUserList.length > 0) {
    for (let item of companyUserList) {
      // 外部のグループと有効以外のグループを除外
      if (item.company_group.role_id === 5 || item.company_group.status != 1)
        continue
      if (!companyGroupIdSet.has(item.company_group.id)) {
        companyGroupIdSet.add(item.company_group.id)
        const companyGroupItem = {
          value: item.company_group.id,
          label: item.company_group.name
        }
        companyGroupItems.push(companyGroupItem)
      }
    }
  }
  return companyGroupItems
}

const createCompanyUserItems = companyUserList => {
  const companyUserItemMap = new Map()
  companyUserItemMap.set(0, ['担当グループを選択してください'])
  if (companyUserList && companyUserList.length > 0) {
    for (let item of companyUserList) {
      // 有効以外のユーザーを除外
      if (item.company_user.status != 1) continue
      const companyUserItem = {
        value: item.company_user.id,
        label: item.company_user.name
      }
      if (!companyUserItemMap.has(item.company_user.company_group_id)) {
        companyUserItemMap.set(item.company_user.company_group_id, [
          companyUserItem
        ])
      } else {
        const companyUserItemArray = companyUserItemMap.get(
          item.company_user.company_group_id
        )
        companyUserItemArray.push(companyUserItem)
      }
    }
  }
  return companyUserItemMap
}

const SupportedStatus = props => {
  const {
    companyGroupItems,
    companyGroupId,
    display,
    thread,
    residentInfoChangeApplication,
    fetchData,
    companyUserItems,
    companyUserId,
    deactivatedCompanyGroup,
    deactivatedCompanyUser,
    dispatchNotification,
    threadStateId,
    disabled,
    applicationStateId,
    enqueueSnackbar,
    orgThreadStateId,
    orgApplicationStateId,
    isOwnerPath
  } = props

  const { role35 } = useRole()
  const isRestrictedRole = role35()

  const handleChangeCompanyGroupId = async event => {
    let companyGroup
    for (let companyGroupItem of companyGroupItems) {
      if (companyGroupItem.value === event.target.value) {
        companyGroup = companyGroupItem
        break
      }
    }

    if (
      !window.confirm(
        `担当グループを${companyGroup.label}にします。よろしいですか？`
      )
    ) {
      // キャンセル時は値を変えないためdefaultValueと同じ値をDropdownに渡す
      event.target.value = companyGroupId
      return
    }

    const apiName = discriminationApi(display.display)
    event.preventDefault()
    let params = []
    if (display.kinds != 'apply') {
      params = {
        id: thread.id,
        [event.target.name]: companyGroup.value
      }
    } else {
      params = {
        id: residentInfoChangeApplication.application.id,
        [event.target.name]: companyGroup.value
      }
    }

    // ownerの場合はthreadのcustomer_idがowner_idになる
    if (isOwnerPath) {
      params.owner_id = thread.customer_id
    }

    let result = await MutationHelper(apiName, { input: params })
    if (result.error) {
      enqueueSnackbar('担当グループを更新できませんでした', {
        variant: 'error'
      })
    } else {
      enqueueSnackbar('担当グループを更新しました', {
        variant: 'success'
      })
      props.setDeactivatedCompanyGroup(null)
      props.setDeactivatedCompanyUser(null)
      if (display.kinds == 'apply') {
        fetchData(residentInfoChangeApplication.application.id)
      } else {
        fetchData(result.id)
      }
    }
  }

  const handleChangeCompanyUserId = async event => {
    let companyUser
    for (let companyUserItem of companyUserItems) {
      if (companyUserItem.value === event.target.value) {
        companyUser = companyUserItem
        break
      }
    }
    if (
      !window.confirm(`担当者を${companyUser.label}にします。よろしいですか？`)
    ) {
      // キャンセル時は値を変えないためdefaultValueと同じ値をDropdownに渡す
      event.target.value = companyUserId
      return
    }
    event.preventDefault()
    const apiName = discriminationApi(display.display)
    let params = {}
    if (display.kinds != 'apply') {
      params = {
        id: thread.id,
        assigned_by: companyUser.value
      }
    } else {
      params = {
        id: residentInfoChangeApplication.application.id,
        assigned_user: companyUser.value
      }
    }

    // ownerの場合はthreadのcustomer_idがowner_idになる
    if (isOwnerPath) {
      params.owner_id = thread.customer_id
    }

    let result = await MutationHelper(apiName, { input: params })
    if (result.error) {
      console.log(result)
      props.enqueueSnackbar('担当者を更新できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('担当者を更新しました', {
        variant: 'success'
      })
      props.setDeactivatedCompanyUser(null)
      if (display.kinds == 'apply') {
        fetchData(residentInfoChangeApplication.application.id)
      } else {
        fetchData(result.id)
      }
    }
    // ヘッダの通知部分更新用
    dispatchNotification({
      type: 'SET',
      payload: true
    })
  }

  const handleChangeState = async (event, type) => {
    const threadState = fromCode(type, event.target.value)
    if (
      !window.confirm(
        `ステータスを${threadState.value}にします。よろしいですか？`
      )
    ) {
      // キャンセル時は値を変えないためdefaultValueと同じ値をDropdownに渡す
      event.target.value =
        display.kinds != 'apply' ? orgThreadStateId : orgApplicationStateId
      return false
    }

    const apiName = discriminationApi(display.display)
    event.preventDefault()

    let params = {}
    if (display.kinds == 'apply') {
      params = {
        [event.target.name]: event.target.value,
        id: residentInfoChangeApplication.application.id
      }
    } else {
      params = {
        id: thread.id,
        thread_state_id: threadState.code
      }
    }

    // ownerの場合はthreadのcustomer_idがowner_idになる
    if (isOwnerPath) {
      params.owner_id = thread.customer_id
    }

    let result = await MutationHelper(apiName, { input: params })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('状態を更新できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('状態を更新しました', {
        variant: 'success'
      })

      if (display.kinds == 'apply') {
        fetchData(residentInfoChangeApplication.application.id)
      } else {
        fetchData(result.id)
      }
    }
    // ヘッダの通知部分更新用
    dispatchNotification({
      type: 'SET',
      payload: true
    })
  }

  const discriminationApi = name => {
    switch (name) {
      case 'messageDetails': {
        return 'updateThread'
      }
      case 'InformationDetails': {
        return 'updateResidentInfoChangeApplication'
      }
      case 'cancellationDetails': {
        return 'updateResidentCancellationApplication'
      }
      case 'checkListDetails': {
        return 'updateRoomCheckListApplication'
      }
      case 'bicycleParkingSticker': {
        return 'updateBicycleParkingStickerApplication'
      }
      case 'acceptanceCertificateDetails': {
        return 'updateCoc2UseParkingApplication'
      }
      case 'petBreedingDetails': {
        return 'updatePetApplication'
      }
      default: {
        return ''
      }
    }
  }

  // 外部利用者または閲覧権限でログインした際は、対応状況を変更できない
  switch (display.display) {
    case 'messageDetails':
      return (
        <>
          <Grid item xs={12}>
            {thread && thread.thread_state_id && (
              <Dropdown
                label="状態"
                name="status"
                onChange={e => handleChangeState(e, ThreadState)}
                defaultValue={threadStateId}
                disabled={isRestrictedRole}
                items={[
                  {
                    value: ThreadState.open.code,
                    label: ThreadState.open.value
                  },
                  {
                    value: ThreadState.inProgress.code,
                    label: ThreadState.inProgress.value
                  },
                  {
                    value: ThreadState.pending.code,
                    label: ThreadState.pending.value
                  },
                  {
                    value: ThreadState.complete.code,
                    label: ThreadState.complete.value
                  }
                ]}
                color="primary"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {companyGroupItems && companyGroupItems.length > 0 && (
              <Dropdown
                label="担当グループ"
                name="assigned_group_by"
                onChange={handleChangeCompanyGroupId}
                defaultValue={companyGroupId}
                items={companyGroupItems}
                deactivatedItem={deactivatedCompanyGroup}
                color="primary"
                disabled={disabled || isRestrictedRole}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {((companyUserItems && companyUserItems.length > 0) ||
              deactivatedCompanyUser) && (
              <Dropdown
                label="担当者"
                name="assigned_by"
                onChange={handleChangeCompanyUserId}
                defaultValue={companyUserId}
                items={companyUserItems}
                deactivatedItem={deactivatedCompanyUser}
                color="primary"
                disabled={disabled || !companyGroupId || isRestrictedRole}
              />
            )}
          </Grid>
        </>
      )
    case 'cancellationDetails':
      return (
        <>
          <Grid item xs={12}>
            <Dropdown
              label="状態"
              id="application_state_id"
              name="application_state_id"
              onChange={e =>
                handleChangeState(e, ResidentCancellationApplicationState)
              }
              defaultValue={applicationStateId}
              disabled={isRestrictedRole}
              items={[
                {
                  value: ResidentCancellationApplicationState.open.code,
                  label: ResidentCancellationApplicationState.open.value
                },
                {
                  value: ResidentCancellationApplicationState.inProgress.code,
                  label: ResidentCancellationApplicationState.inProgress.value
                },
                {
                  value: ResidentCancellationApplicationState.pending.code,
                  label: ResidentCancellationApplicationState.pending.value
                },
                {
                  value: ResidentCancellationApplicationState.complete.code,
                  label: ResidentCancellationApplicationState.complete.value
                },
                {
                  value: ResidentCancellationApplicationState.cancel.code,
                  label: ResidentCancellationApplicationState.cancel.value
                }
              ]}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            {companyGroupItems && companyGroupItems.length > 0 && (
              <Dropdown
                label="担当グループ"
                name="assigned_user_group"
                onChange={handleChangeCompanyGroupId}
                defaultValue={companyGroupId}
                items={companyGroupItems}
                deactivatedItem={deactivatedCompanyGroup}
                color="primary"
                disabled={
                  applicationStateId ==
                    ResidentCancellationApplicationState.complete.code ||
                  applicationStateId ==
                    ResidentCancellationApplicationState.cancel.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {((companyUserItems && companyUserItems.length > 0) ||
              deactivatedCompanyUser) && (
              <Dropdown
                label="担当者"
                name="assigned_by"
                onChange={handleChangeCompanyUserId}
                defaultValue={companyUserId}
                items={companyUserItems}
                deactivatedItem={deactivatedCompanyUser}
                color="primary"
                disabled={
                  !companyGroupId ||
                  applicationStateId ==
                    ResidentCancellationApplicationState.complete.code ||
                  applicationStateId ==
                    ResidentCancellationApplicationState.cancel.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
        </>
      )
    case 'checkListDetails': {
      return (
        <>
          <Grid item xs={12}>
            <Dropdown
              label="状態"
              name="application_state_id"
              onChange={e =>
                handleChangeState(e, RoomCheckListApplicationState)
              }
              defaultValue={applicationStateId}
              disabled={isRestrictedRole}
              items={[
                {
                  value: RoomCheckListApplicationState.open.code,
                  label: RoomCheckListApplicationState.open.value
                },
                {
                  value: RoomCheckListApplicationState.inProgress.code,
                  label: RoomCheckListApplicationState.inProgress.value
                },
                {
                  value: RoomCheckListApplicationState.complete.code,
                  label: RoomCheckListApplicationState.complete.value
                }
              ]}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            {companyGroupItems && companyGroupItems.length > 0 && (
              <Dropdown
                label="担当グループ"
                name="assigned_user_group"
                onChange={handleChangeCompanyGroupId}
                defaultValue={companyGroupId}
                items={companyGroupItems}
                deactivatedItem={deactivatedCompanyGroup}
                color="primary"
                disabled={
                  applicationStateId ==
                    RoomCheckListApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {((companyUserItems && companyUserItems.length > 0) ||
              deactivatedCompanyUser) && (
              <Dropdown
                label="担当者"
                name="assigned_by"
                onChange={handleChangeCompanyUserId}
                defaultValue={companyUserId}
                items={companyUserItems}
                deactivatedItem={deactivatedCompanyUser}
                color="primary"
                disabled={
                  !companyGroupId ||
                  applicationStateId ==
                    RoomCheckListApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
        </>
      )
    }
    case 'bicycleParkingSticker': {
      return (
        <>
          <Grid item xs={12}>
            <Dropdown
              label="状態"
              name="application_state_id"
              onChange={e =>
                handleChangeState(e, BicycleParkingStickerApplicationState)
              }
              defaultValue={applicationStateId}
              disabled={isRestrictedRole}
              items={[
                {
                  value: BicycleParkingStickerApplicationState.open.code,
                  label: BicycleParkingStickerApplicationState.open.value
                },
                {
                  value: BicycleParkingStickerApplicationState.inProgress.code,
                  label: BicycleParkingStickerApplicationState.inProgress.value
                },
                {
                  value: BicycleParkingStickerApplicationState.complete.code,
                  label: BicycleParkingStickerApplicationState.complete.value
                }
              ]}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            {companyGroupItems && companyGroupItems.length > 0 && (
              <Dropdown
                label="担当グループ"
                id="assigned_user_group"
                name="assigned_user_group"
                onChange={handleChangeCompanyGroupId}
                defaultValue={companyGroupId}
                items={companyGroupItems}
                deactivatedItem={deactivatedCompanyGroup}
                color="primary"
                disabled={
                  applicationStateId ==
                    BicycleParkingStickerApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {((companyUserItems && companyUserItems.length > 0) ||
              deactivatedCompanyUser) && (
              <Dropdown
                label="担当者"
                name="assigned_by"
                onChange={handleChangeCompanyUserId}
                defaultValue={companyUserId}
                items={companyUserItems}
                deactivatedItem={deactivatedCompanyUser}
                color="primary"
                disabled={
                  !companyGroupId ||
                  applicationStateId ==
                    BicycleParkingStickerApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
        </>
      )
    }
    case 'acceptanceCertificateDetails': {
      return (
        <>
          <Grid item xs={12}>
            <Dropdown
              label="状態"
              name="application_state_id"
              onChange={e =>
                handleChangeState(e, CertificateOfConsentToUseParkingSpaceState)
              }
              defaultValue={applicationStateId}
              disabled={isRestrictedRole}
              items={[
                {
                  value: CertificateOfConsentToUseParkingSpaceState.open.code,
                  label: CertificateOfConsentToUseParkingSpaceState.open.value
                },
                {
                  value:
                    CertificateOfConsentToUseParkingSpaceState.inProgress.code,
                  label:
                    CertificateOfConsentToUseParkingSpaceState.inProgress.value
                },
                {
                  value:
                    CertificateOfConsentToUseParkingSpaceState.complete.code,
                  label:
                    CertificateOfConsentToUseParkingSpaceState.complete.value
                }
              ]}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            {companyGroupItems && companyGroupItems.length > 0 && (
              <Dropdown
                label="担当グループ"
                name="assigned_user_group"
                onChange={handleChangeCompanyGroupId}
                defaultValue={companyGroupId}
                items={companyGroupItems}
                deactivatedItem={deactivatedCompanyGroup}
                color="primary"
                disabled={
                  applicationStateId ==
                    CertificateOfConsentToUseParkingSpaceState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {((companyUserItems && companyUserItems.length > 0) ||
              deactivatedCompanyUser) && (
              <Dropdown
                label="担当者"
                name="assigned_by"
                onChange={handleChangeCompanyUserId}
                defaultValue={companyUserId}
                items={companyUserItems}
                deactivatedItem={deactivatedCompanyUser}
                color="primary"
                disabled={
                  !companyGroupId ||
                  applicationStateId ==
                    CertificateOfConsentToUseParkingSpaceState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
        </>
      )
    }
    case 'petBreedingDetails': {
      return (
        <>
          <Grid item xs={12}>
            <Dropdown
              label="状態"
              name="application_state_id"
              onChange={e => handleChangeState(e, PetApplicationState)}
              defaultValue={applicationStateId}
              disabled={isRestrictedRole}
              items={[
                {
                  value: PetApplicationState.open.code,
                  label: PetApplicationState.open.value
                },
                {
                  value: PetApplicationState.inProgress.code,
                  label: PetApplicationState.inProgress.value
                },
                {
                  value: PetApplicationState.complete.code,
                  label: PetApplicationState.complete.value
                }
              ]}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            {companyGroupItems && companyGroupItems.length > 0 && (
              <Dropdown
                label="担当グループ"
                name="assigned_user_group"
                onChange={handleChangeCompanyGroupId}
                defaultValue={companyGroupId}
                items={companyGroupItems}
                deactivatedItem={deactivatedCompanyGroup}
                color="primary"
                disabled={
                  applicationStateId == PetApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {((companyUserItems && companyUserItems.length > 0) ||
              deactivatedCompanyUser) && (
              <Dropdown
                label="担当者"
                name="assigned_by"
                onChange={handleChangeCompanyUserId}
                defaultValue={companyUserId}
                items={companyUserItems}
                deactivatedItem={deactivatedCompanyUser}
                color="primary"
                disabled={
                  !companyGroupId ||
                  applicationStateId == PetApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
        </>
      )
    }
    case 'InformationDetails': {
      return (
        <>
          <Grid item xs={12}>
            <Dropdown
              label="状態"
              name="application_state_id"
              onChange={e =>
                handleChangeState(e, ResidentInfoChangeApplicationState)
              }
              defaultValue={applicationStateId}
              disabled={isRestrictedRole}
              items={[
                {
                  value: ResidentInfoChangeApplicationState.open.code,
                  label: ResidentInfoChangeApplicationState.open.value
                },
                {
                  value: ResidentInfoChangeApplicationState.inProgress.code,
                  label: ResidentInfoChangeApplicationState.inProgress.value
                },
                {
                  value: ResidentInfoChangeApplicationState.complete.code,
                  label: ResidentInfoChangeApplicationState.complete.value
                }
              ]}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            {companyGroupItems && companyGroupItems.length > 0 && (
              <Dropdown
                label="担当グループ"
                name="assigned_user_group"
                onChange={handleChangeCompanyGroupId}
                defaultValue={companyGroupId}
                items={companyGroupItems}
                deactivatedItem={deactivatedCompanyGroup}
                color="primary"
                disabled={
                  applicationStateId ==
                    ResidentInfoChangeApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {((companyUserItems && companyUserItems.length > 0) ||
              deactivatedCompanyUser) && (
              <Dropdown
                label="担当者"
                name="assigned_by"
                onChange={handleChangeCompanyUserId}
                defaultValue={companyUserId}
                items={companyUserItems}
                deactivatedItem={deactivatedCompanyUser}
                color="primary"
                disabled={
                  !companyGroupId ||
                  applicationStateId ==
                    ResidentInfoChangeApplicationState.complete.code ||
                  isRestrictedRole
                }
              />
            )}
          </Grid>
        </>
      )
    }
    default:
      return <></>
  }
}

const ProjectArrangementInformation = props => {
  const {
    setEditMemo,
    changeMemoInformation,
    companyGroupList,
    fetchedCompanyGroupList,
    fetchedCompanyUserList,
    companyUserMap,
    arrangementData,
    isLoading,
    formatSelectedProjectStatus,
    enqueueSnackbar,
    fetchArrangementDataWrapper,
    display,
    thread,
    residentInfoChangeApplication
  } = props

  const { role3, role35 } = useRole()
  const isRestrictedRole3 = role3()
  const isRestrictedRole35 = role35()

  const useStyles = makeStyles(theme => ({
    dropdown: {
      padding: '10px 0px'
    },
    positionRelative: {
      position: 'relative',
      marginBottom: '20px'
    },
    memoWrapper: {
      paddingTop: '20px'
    },
    textArea: {
      width: '100%',
      paddingBottom: '10px',
      outline: '2px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '50px solid white',
      borderRadius: '4px',
      fontSize: '14px',
      overflowWrap: 'break-word',
      padding: '0px 10px'
    },
    memoDetail: {
      position: 'absolute',
      bottom: '1%',
      left: '1%',
      width: '100%',
      padding: '0px 10px'
    },
    right: {
      marginLeft: 'auto'
    },
    disabledIconStyle: {
      color: theme.palette.c_gray.main,
      pointerEvents: 'none'
    }
  }))

  const classes = useStyles()
  const [assignedCompanyUserList, setAssignedCompanyUserList] = useState([])
  const [deactivatedCompanyGroup, setDeactivatedCompanyGroup] = useState(null)
  const [deactivatedCompanyUser, setDeactivatedCompanyUser] = useState(null)
  const [
    selectedAssignedCompanyUser,
    setSelectedAssignedCompanyUser
  ] = useState(0)
  const [
    selectedAssignedCompanyGroup,
    setSelectedAssignedCompanyGroup
  ] = useState(0)
  const [selectedProjectStatus, setSelectedProjectStatus] = useState(0)

  useEffect(() => {
    if (arrangementData.external_assigned_group_by == undefined) return
    setSelectedAssignedCompanyGroup(
      arrangementData.external_assigned_group_by.id
    )
    setSelectedAssignedCompanyUser(arrangementData.external_assigned_by.id)
    setSelectedProjectStatus(
      formatSelectedProjectStatus(arrangementData.arrangement_status)
    )
    setAssignedCompanyUserList(
      companyUserMap.size > 0 &&
        companyUserMap.get(arrangementData.external_assigned_group_by.id)
    )
  }, [arrangementData != null, companyUserMap])

  const handleChangeAssignedCompanyGroup = event => {
    let companyGroup
    for (let companyGroupItem of companyGroupList) {
      if (companyGroupItem.value === event.target.value) {
        companyGroup = companyGroupItem
        break
      }
    }
    if (
      !window.confirm(
        `担当グループを${companyGroup.label}にします。よろしいですか？`
      )
    ) {
      event.target.value = selectedAssignedCompanyGroup
      return
    }
    setSelectedAssignedCompanyUser(0)
    setAssignedCompanyUserList(companyUserMap.get(event.target.value))
    changeArrangementInformation(event)
  }

  const handleChangeAssignedCompanyUser = event => {
    if (event.target.value != '') {
      let companyUser
      for (let companyUserItem of assignedCompanyUserList) {
        if (companyUserItem.value === event.target.value) {
          companyUser = companyUserItem
          break
        }
      }
      if (
        !window.confirm(
          `担当者を${companyUser.label}にします。よろしいですか？`
        )
      ) {
        event.target.value = selectedAssignedCompanyUser
        return
      }
      changeArrangementInformation(event)
    }
  }

  const handleChangeProjectStatus = (event, type) => {
    if (event.target.value == '') return
    const arrangementState = fromCode(type, event.target.value)
    if (
      !window.confirm(
        `ステータスを${arrangementState.value}にします。よろしいですか？`
      )
    ) {
      event.target.value = selectedProjectStatus
      return
    }
    changeArrangementInformation(event)
  }

  const changeArrangementInformation = async event => {
    let params = {}
    let errorSnackbarContent = ''
    let successSnackbarContent = ''
    params['arrangement_id'] = arrangementData.id
    params['original_data_id'] =
      display.kinds == 'messageDetails'
        ? thread.id
        : residentInfoChangeApplication.application.id
    params['arrangement_type'] =
      props.display.kinds == 'messageDetails' ? 'CHAT' : 'APPLICATION'
    switch (event.target.name) {
      case 'external_assigned_group_by':
        errorSnackbarContent = '担当グループを更新できませんでした'
        successSnackbarContent = '担当グループを更新しました'
        params[event.target.name] = event.target.value
        break
      case 'external_assigned_by':
        errorSnackbarContent = '担当者を更新できませんでした'
        successSnackbarContent = '担当者を更新しました'
        params[event.target.name] = event.target.value
        break
      case 'arrangement_status':
        errorSnackbarContent = 'ステータスを更新できませんでした'
        successSnackbarContent = 'ステータスを更新しました'
        params[event.target.name] = props.formatSelectedProjectStatus(
          JSON.stringify(event.target.value)
        )
        break
    }
    let result = await MutationHelper('updateArrangement', { input: params })
    if (result.error) {
      console.log(result.error)
      enqueueSnackbar(errorSnackbarContent, {
        variant: 'error'
      })
    } else {
      enqueueSnackbar(successSnackbarContent, {
        variant: 'success'
      })
      if (event.target.name === 'external_assigned_group_by') {
        setDeactivatedCompanyGroup(null)
      }
      if (event.target.name === 'external_assigned_by') {
        setDeactivatedCompanyUser(null)
      }
    }
    fetchArrangementDataWrapper(
      display.kinds == 'messageDetails'
        ? thread.id
        : residentInfoChangeApplication.application.id
    )
  }

  useEffect(() => {
    if (fetchedCompanyGroupList && fetchedCompanyUserList) {
      const assignedGroup = fetchedCompanyGroupList.find(
        item => item.status === 3 && selectedAssignedCompanyGroup == item.id
      )
      const assignedUser = fetchedCompanyUserList.find(
        item =>
          item.company_user.status !== 1 &&
          selectedAssignedCompanyUser === item.company_user.id
      )
      setDeactivatedCompanyGroup(
        assignedGroup &&
          getFormattedNameByStatus(assignedGroup.name, assignedGroup.status)
      )
      setDeactivatedCompanyUser(
        assignedUser &&
          getFormattedNameByStatus(
            assignedUser.company_user.name,
            assignedUser.company_user.status
          )
      )
    }
  }, [
    fetchedCompanyGroupList,
    fetchedCompanyUserList,
    selectedAssignedCompanyGroup,
    selectedAssignedCompanyUser
  ])

  return (
    <>
      {!isLoading ? (
        <>
          <Grid className={classes.dropdown}>
            <Dropdown
              name="external_assigned_group_by"
              label={'外部利用者グループ'}
              onChange={handleChangeAssignedCompanyGroup}
              defaultValue={selectedAssignedCompanyGroup}
              value={selectedAssignedCompanyGroup}
              items={companyGroupList}
              disabled={isRestrictedRole35}
              deactivatedItem={deactivatedCompanyGroup}
            />
          </Grid>
          <Grid className={classes.dropdown}>
            <Dropdown
              name="external_assigned_by"
              label={'担当者'}
              onChange={handleChangeAssignedCompanyUser}
              defaultValue={selectedAssignedCompanyUser}
              value={selectedAssignedCompanyUser}
              items={assignedCompanyUserList}
              disabled={isRestrictedRole3}
              deactivatedItem={deactivatedCompanyUser}
            />
          </Grid>
          <Grid className={classes.dropdown}>
            <Dropdown
              name="arrangement_status"
              label={'案件ステータス'}
              onChange={e => handleChangeProjectStatus(e, ProjectStatus)}
              defaultValue={selectedProjectStatus}
              value={selectedProjectStatus}
              items={projectStatusList}
              disabled={isRestrictedRole3}
            />
          </Grid>
          <Grid className={classes.memoWrapper}>
            <Grid className={classes.positionRelative}>
              <Grid className={classes.textArea}>
                {arrangementData && arrangementData.body}
              </Grid>
              <Grid className={classes.memoDetail} alignItems="center">
                <Grid>
                  <Typography style={{ fontSize: 13 }}>
                    {arrangementData &&
                      arrangementData.body &&
                      formatYYYYMMDD(
                        new Date(arrangementData.arrangement_memo_updated_at)
                      )}
                    &nbsp;
                    {arrangementData &&
                      arrangementData.body &&
                      formatTime(
                        new Date(arrangementData.arrangement_memo_updated_at)
                      )}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid>
                    <Typography style={{ fontSize: 13 }}>
                      {arrangementData &&
                        arrangementData.body &&
                        getFormattedNameByStatus(
                          arrangementData.arrangement_memo_updated_user.name,
                          arrangementData.arrangement_memo_updated_user.status
                        )}
                    </Typography>
                  </Grid>
                  <Grid className={classes.right}>
                    <EditIcon
                      style={{ fontSize: 20 }}
                      className={isRestrictedRole3 && classes.disabledIconStyle}
                      onClick={() =>
                        setEditMemo(
                          arrangementData,
                          arrangementData.body
                            ? 'updateArrangementEdit'
                            : 'updateArrangementAdd'
                        )
                      }
                    />
                    <DeleteIcon
                      style={{ fontSize: 20 }}
                      className={isRestrictedRole3 && classes.disabledIconStyle}
                      onClick={() =>
                        changeMemoInformation(
                          arrangementData,
                          'deleteArrangement',
                          arrangementData.body ? arrangementData.body.length : 0
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Loading isLoading={isLoading} />
        </Grid>
      )}
    </>
  )
}

const RemarksMemoDialog = props => {
  const {
    onClose,
    open,
    changeMemoInformation,
    isDisabled,
    typeDialog,
    editMemoInformation,
    classes
  } = props

  const [value, setValue] = useState(null)
  const [count, setCount] = useState(0)

  const handleClose = () => {
    onClose()
  }

  const handleChangeValue = event => {
    setCount(event.target.value.length)
    setValue(event.target.value)
  }

  const CloseRemarksMemoDialog = () => {
    const result = window.confirm(
      `備考メモの内容が削除されますがよろしいですか？`
    )
    if (!result) return
    handleClose()
  }

  useEffect(() => {
    setValue(null)
    if (typeDialog == null || editMemoInformation == null) return
    if (typeDialog == 'updateArrangementEdit') {
      setCount(editMemoInformation.body.replace(/\s+/g, '').length)
    } else if (typeDialog == 'edit') {
      setCount(editMemoInformation.memo.replace(/\s+/g, '').length)
    }
  }, [])

  useEffect(() => {
    //入力フォームを初期化する
    setValue(null)
  }, [props])

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.dialogColor}>
        {typeDialog == 'edit' || typeDialog == 'updateArrangementEdit'
          ? '備考メモを編集'
          : '備考メモを追加'}
      </DialogTitle>
      <DialogContent className={classes.dialogColor}>
        <TextareaAutosize
          required
          label={'本文'}
          id="body"
          style={{ height: 200, width: 600, fontSize: '16px' }}
          autoComplete="body"
          rowsMin="6"
          className={classes.memo}
          defaultValue={
            editMemoInformation != null
              ? typeDialog == 'edit'
                ? editMemoInformation.memo
                : editMemoInformation.body
              : value
          }
          onChange={handleChangeValue}
          placeholder={
            'ここに備考メモ追加できます。備考メモの内容は入居者には表示されません。'
          }
        />
        <Grid container justify="flex-end">
          <Grid className={classes.errorMessage}>
            <Typography variant="body2">
              {count > 300
                ? '文字数制限 : 300文字以内で入力してください'
                : null}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">{count}</Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">/300</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.buttonPosition + ' ' + classes.dialogColor}
      >
        <Button
          className={classes.cancelButton}
          onClick={() => CloseRemarksMemoDialog()}
          disabled={isDisabled}
        >
          キャンセル
        </Button>
        <Button
          className={classes.saveButton}
          onClick={() => {
            if (typeDialog == null) return
            typeDialog == 'create'
              ? changeMemoInformation(value, 'create', count)
              : typeDialog == 'edit'
              ? changeMemoInformation(value, 'update', count)
              : changeMemoInformation(value, 'updateArrangement', count)
          }}
          disabled={isDisabled}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const MessageInfo = props => {
  const classes = useStyles()
  const {
    threadMemos,
    residentInfoChangeApplication,
    fetchData,
    display
  } = props
  const thread = props && props.thread ? props.thread : null
  const companyGroupItems =
    props && props.companyUserList
      ? createCompanyGroupItems(props.companyUserList)
      : null
  const companyUserItemMap =
    props && props.companyUserList
      ? createCompanyUserItems(props.companyUserList)
      : null
  let companyUserItems =
    companyUserItemMap &&
    companyUserItemMap.size > 0 &&
    display.display == 'messageDetails'
      ? companyUserItemMap.get(
          thread && thread.assigned_group_by ? thread.assigned_group_by : 0
        )
      : companyUserItemMap.get(
          residentInfoChangeApplication &&
            residentInfoChangeApplication.assigned_user_group
            ? residentInfoChangeApplication.assigned_user_group.id
            : 0
        )
  const [threadStateId, setThreadStateId] = useState(0)
  const [orgThreadStateId, setOrgThreadStateId] = useState(0)
  const [applicationStateId, setApplicationStateId] = useState(0)
  const [orgApplicationStateId, setOrgApplicationStateId] = useState(0)
  const [companyGroupId, setCompanyGroupId] = useState(
    thread ? thread.assigned_group_by : 0
  )
  const [companyUserId, setCompanyUserId] = useState(
    thread ? thread.assigned_by : 0
  )
  const [deactivatedCompanyGroup, setDeactivatedCompanyGroup] = useState(null)
  const [deactivatedCompanyUser, setDeactivatedCompanyUser] = useState(null)
  const disabled = thread ? thread.thread_state_id === 4 : false
  const [, dispatchNotification] = React.useContext(NotificationContext)
  const [remarksMemoState, setRemarksMemoState] = React.useState(false)
  const [matterModalState, setMatterModalState] = React.useState(false)
  const [typeDialog, setTypeDialog] = useState(null)
  const [editMemoInformation, setEditMemoInformation] = useState(null)
  const [memoId, setMemoId] = useState(null)
  const location = useLocation()
  const thread_id = location.pathname.replace(/[^0-9]/g, '')
  const [remarksMemoList, setRemarksMemoList] = useState([])
  const [arrangementData, setArrangementData] = useState(null)
  const [companyGroupList, setCompanyGroupList] = useState([])
  const [fetchedCompanyGroupList, setFetchedCompanyGroupList] = useState(null)
  const [fetchedCompanyUserList, setFetchedCompanyUserList] = useState(null)
  const [companyUserMap, setCompanyUserMap] = useState([])
  const [isArrangementLoading, setIsArrangementLoading] = useState(true)
  const { judgmentRoleId } = useLoginInformation()
  const roleId = judgmentRoleId()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const [isDisabled, setIsDisabled] = useState(false)
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  const handleOpen = status => {
    if (status == 'matter') {
      setMatterModalState(true)
    } else {
      setTypeDialog('create')
      setRemarksMemoState(true)
    }
  }
  const handleClose = state => {
    if (state == 'matter') {
      setMatterModalState(false)
    } else {
      setRemarksMemoState(false)
      setTypeDialog(null)
      setEditMemoInformation(null)
      setMemoId(null)
    }
  }

  const changeMemoInformation = async (value, type, count) => {
    var params = {}
    var apiName = ''
    var errorSnackbarContent = ''
    var successSnackbarContent = ''
    if (count != undefined) {
      if (count == 0) {
        props.enqueueSnackbar(
          '備考メモが登録されていないので削除ができません。',
          {
            variant: 'error'
          }
        )
        return
      }
      if (count > 300) {
        props.enqueueSnackbar('300文字以内で入力してください', {
          variant: 'error'
        })
        return
      }
    }
    switch (type) {
      case 'delete': {
        const resultConfirmation = window.confirm(
          `メモを削除しますがよろしいですか？`
        )
        if (!resultConfirmation) return
        params['id'] = value.id
        apiName =
          display.kinds == 'apply'
            ? 'deleteApplicationMemo'
            : 'deleteThreadMemo'
        errorSnackbarContent = 'メモを削除できませんでした'
        successSnackbarContent = 'メモを削除しました'
        break
      }
      case 'update': {
        params['id'] = memoId
        params[display.kinds == 'apply' ? 'memo' : 'body'] = value
        apiName =
          display.kinds == 'apply'
            ? 'updateApplicationMemo'
            : 'updateThreadMemo'
        errorSnackbarContent = 'メモを編集できませんでした'
        successSnackbarContent = 'メモを編集しました'
        break
      }
      case 'create': {
        if (remarksMemoList.length >= 20) {
          props.enqueueSnackbar('備考メモは20個までしか作成できません', {
            variant: 'error'
          })
          return
        }
        if (display.kinds == 'apply') {
          params['application_id'] =
            residentInfoChangeApplication.application.id
          params['memo'] = value
          apiName = 'createApplicationMemo'
        } else {
          params['thread_id'] = thread_id
          params['body'] = value
          apiName = 'createThreadMemo'
        }
        errorSnackbarContent = 'メモを追加できませんでした'
        successSnackbarContent = 'メモを追加しました'
        break
      }
      case 'updateArrangement': {
        params['arrangement_id'] = memoId
        params['body'] = value
        params['arrangement_type'] =
          display.kinds == 'apply' ? 'APPLICATION' : 'CHAT'
        params['original_data_id'] =
          display.kinds == 'apply'
            ? residentInfoChangeApplication.application.id
            : thread_id
        apiName = 'updateArrangementMemo'
        errorSnackbarContent = 'メモを編集できませんでした'
        successSnackbarContent = 'メモを編集しました'
        break
      }
      case 'deleteArrangement': {
        const resultArrangementConfirmation = window.confirm(
          `メモを削除しますがよろしいですか？`
        )
        if (!resultArrangementConfirmation) return
        params['arrangement_id'] = value.id
        apiName = 'deleteArrangementMemo'
        errorSnackbarContent = 'メモを削除できませんでした'
        successSnackbarContent = 'メモを削除しました'
        break
      }
      default: {
        errorSnackbarContent = '想定外のエラーが発生しました'
      }
    }

    // オーナーの場合はthreadのcustomer_idがowner_idになる
    if (isOwnerPath) {
      if (thread.customer_id) {
        params['owner_id'] = thread.customer_id
      } else {
        props.enqueueSnackbar('メモを追加できませんでした', {
          variant: 'error'
        })
        handleClose()
        setIsDisabled(false)
        return
      }
    }

    setIsDisabled(true)
    let result = await MutationHelper(apiName, { input: params })
    if (result.error) {
      props.enqueueSnackbar(errorSnackbarContent, {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar(successSnackbarContent, {
        variant: 'success'
      })
      if (type == 'updateArrangement' || type == 'deleteArrangement') {
        fetchArrangementData(
          display.kinds == 'apply'
            ? residentInfoChangeApplication.application.id
            : thread_id
        )
      }
      if (display.kinds == 'apply') {
        fetchApplicationMemosData()
      }
      if (display.kinds == 'messageDetails') {
        props.fetchThread(thread_id)
      }
    }
    handleClose()
    setIsDisabled(false)
  }

  const setEditMemo = (value, type) => {
    handleOpen()
    setTypeDialog(type)
    setEditMemoInformation(value)
    setMemoId(value.id)
  }

  const fetchApplicationMemosData = async () => {
    const params = {}
    params['application_id'] = residentInfoChangeApplication.application.id
    let result = await QueryHelper('getApplicationMemos', {
      filter: params
    })
    if (result.error) {
      console.log(result)
      props.enqueueSnackbar('備考メモ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setRemarksMemoList(result.data)
    }
  }

  const fetchCompanyGroupList = async (offset, limit) => {
    let filter = {}
    // 外部の利用者のみ取得
    filter['role_id'] = {
      eq: 5
    }
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper('getCompanyGroupList', {
      filter: filter,
      offset: offset,
      limit: limit
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyGroupItems = [{ value: 0, label: '' }]
      if (result.data != null && result.data.length > 0) {
        for (let item of result.data) {
          if (item.status === 1) {
            const companyGroupItem = {
              value: item.id,
              label: item.name
            }
            companyGroupItems.push(companyGroupItem)
          }
        }
      }
      setCompanyGroupList(companyGroupItems)
      setFetchedCompanyGroupList(result.data)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const userMap = new Map()
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.company_user.status === 1) {
            const companyUserItem = {
              value: item.company_user.id,
              label: item.company_user.name
            }
            if (!userMap.has(item.company_group.id)) {
              userMap.set(item.company_group.id, [companyUserItem])
            } else {
              const companyUserItemArray = userMap.get(item.company_group.id)
              companyUserItemArray.push(companyUserItem)
            }
          }
        }
      }
      setCompanyUserMap(userMap)
      setFetchedCompanyUserList(result.data)
    }
  }

  const fetchArrangementData = async dataId => {
    const params = {}
    params['original_data_id'] = dataId
    params['arrangement_type'] =
      display.kinds == 'messageDetails' ? 'CHAT' : 'APPLICATION'
    let result = await QueryHelper('getArrangement', {
      filter: params
    })
    // FIXME:IF文が適切でなくエラーを処理できないためバックエンドと共に修正が必要
    if (result == null || result) {
      // resultがnullの場合は、案件が手配されていない
      setArrangementData(result)
    } else if (result.error) {
      props.enqueueSnackbar('案件手配情報を取得できませんでした', {
        variant: 'error'
      })
    }
  }

  const fetchArrangementDataWrapper = async dataId => {
    if (!dataId) return
    await Promise.all([fetchArrangementData(dataId)])
    setIsArrangementLoading(false)
  }

  const formatSelectedProjectStatus = status => {
    switch (status) {
      case '1': {
        return 'ORDER'
      }
      case '2': {
        return 'PREPARATION'
      }
      case '3': {
        return 'CORRESPONDENCE'
      }
      case '4': {
        return 'COMPLETION'
      }
      case '5': {
        return 'CANCEL_OR_RELEASE'
      }
      case 'ORDER': {
        return '1'
      }
      case 'PREPARATION': {
        return '2'
      }
      case 'CORRESPONDENCE': {
        return '3'
      }
      case 'COMPLETION': {
        return '4'
      }
      case 'CANCEL_OR_RELEASE': {
        return '5'
      }
      default: {
        return ''
      }
    }
  }

  useEffect(() => {
    if (
      residentInfoChangeApplication == undefined ||
      residentInfoChangeApplication.application == undefined
    )
      return
    fetchArrangementDataWrapper(residentInfoChangeApplication.application.id)
    if (residentInfoChangeApplication.application.application_state_id) {
      if (display.display == 'cancellationDetails') {
        setOrgApplicationStateId(
          residentInfoChangeApplication.application.application_state_id ==
            ResidentCancellationApplicationState.adjust.code
            ? ResidentCancellationApplicationState.inProgress.code
            : residentInfoChangeApplication.application.application_state_id
        )
        setApplicationStateId(
          residentInfoChangeApplication.application.application_state_id ==
            ResidentCancellationApplicationState.adjust.code
            ? ResidentCancellationApplicationState.inProgress.code
            : residentInfoChangeApplication.application.application_state_id
        )
      } else {
        setOrgApplicationStateId(
          residentInfoChangeApplication.application.application_state_id
        )
        setApplicationStateId(
          residentInfoChangeApplication.application.application_state_id
        )
      }
    }

    setCompanyGroupId(
      residentInfoChangeApplication.assigned_user_group
        ? residentInfoChangeApplication.assigned_user_group.id
        : null
    )
    setCompanyUserId(
      residentInfoChangeApplication.assigned_user
        ? residentInfoChangeApplication.assigned_user.id
        : null
    )
    // 各種申請詳細にて有効以外のグループが担当の時
    if (
      residentInfoChangeApplication.assigned_user_group &&
      residentInfoChangeApplication.assigned_user_group.status === 3
    ) {
      setDeactivatedCompanyGroup(
        getFormattedNameByStatus(
          residentInfoChangeApplication.assigned_user_group.name,
          residentInfoChangeApplication.assigned_user_group.status
        )
      )
    }
    // 各種申請詳細にて有効以外のユーザーが担当の時
    if (
      residentInfoChangeApplication.assigned_user &&
      residentInfoChangeApplication.assigned_user.status !== 1
    ) {
      setDeactivatedCompanyUser(
        getFormattedNameByStatus(
          residentInfoChangeApplication.assigned_user.name,
          residentInfoChangeApplication.assigned_user.status
        )
      )
    }
  }, [residentInfoChangeApplication])

  useEffect(() => {
    if (thread) {
      if (thread.thread_state_id) {
        if (thread.thread_state_id == ThreadState.resolve.code) {
          // 解決済みのときは対応済みに寄せる
          setOrgThreadStateId(ThreadState.pending.code)
          setThreadStateId(ThreadState.pending.code)
        } else {
          setOrgThreadStateId(thread.thread_state_id)
          setThreadStateId(thread.thread_state_id)
        }
      }
      setCompanyGroupId(thread.assigned_group_by)
      setCompanyUserId(thread.assigned_by)
      setRemarksMemoList(threadMemos)
      fetchArrangementDataWrapper(thread.id)
    }
  }, [thread])

  // メッセージ詳細にて有効以外のグループ及びユーザーが担当の時
  useEffect(() => {
    if (
      thread &&
      Object.keys(thread).length != 0 &&
      props.companyUserList &&
      props.companyUserList.length > 0
    ) {
      const assignedGroup = props.companyUserList.find(
        item =>
          item.company_group.status === 3 &&
          item.company_group.id == thread.assigned_group_by
      )
      const assignedUser = props.companyUserList.find(
        item =>
          item.company_user.status != 1 &&
          item.company_user.id == thread.assigned_by
      )
      setDeactivatedCompanyGroup(
        assignedGroup &&
          getFormattedNameByStatus(
            assignedGroup.company_group.name,
            assignedGroup.company_group.status
          )
      )
      setDeactivatedCompanyUser(
        assignedUser &&
          getFormattedNameByStatus(
            assignedUser.company_user.name,
            assignedUser.company_user.status
          )
      )
    }
  }, [props.companyUserList, thread])

  useEffect(() => {
    if (display.kinds == 'apply') {
      if (residentInfoChangeApplication.application == undefined) return
      fetchApplicationMemosData()
    }
    fetchCompanyGroupList()
    fetchCompanyUserList()
  }, [residentInfoChangeApplication])

  const ArrangementDialog = props => {
    const {
      onClose,
      open,
      companyGroupList,
      companyUserMap,
      formatSelectedProjectStatus
    } = props

    const [value, setValue] = useState(null)
    const [count, setCount] = useState(0)
    const [assignedCompanyUserList, setAssignedCompanyUserList] = useState([])
    const [
      selectedAssignedCompanyUser,
      setSelectedAssignedCompanyUser
    ] = useState(0)
    const [
      selectedAssignedCompanyGroup,
      setSelectedAssignedCompanyGroup
    ] = useState(0)
    const [selectedProjectStatus, setSelectedProjectStatus] = useState(0)
    const [errorMessageList, setErrorMessageList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeAssignedCompanyGroup = event => {
      setSelectedAssignedCompanyUser(0)
      setSelectedAssignedCompanyGroup(JSON.stringify(event.target.value))
      setAssignedCompanyUserList(companyUserMap.get(event.target.value))
    }

    const handleChangeAssignedCompanyUser = event => {
      if (event.target.value != '') {
        setSelectedAssignedCompanyUser(JSON.stringify(event.target.value))
      }
    }

    const handleChangeProjectStatus = event => {
      setSelectedProjectStatus(0)
      if (event.target.value != '') {
        setSelectedProjectStatus(JSON.stringify(event.target.value))
      }
    }

    const handleChangeValue = event => {
      setCount(event.target.value.length)
      setValue(event.target.value)
    }

    const createArrangement = async () => {
      if (validationProposalForm()) {
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        return
      }
      const resultConfirmation = window.confirm(
        `案件を手配しますがよろしいですか？`
      )
      if (!resultConfirmation) return

      let params = {}
      params['original_data_id'] =
        display.kinds == 'messageDetails'
          ? thread.id
          : residentInfoChangeApplication.application.id
      params['arrangement_type'] =
        display.kinds == 'messageDetails' ? 'CHAT' : 'APPLICATION'
      params['external_assigned_group_by'] = selectedAssignedCompanyGroup
      params['external_assigned_by'] = selectedAssignedCompanyUser
      params['arrangement_status'] = formatSelectedProjectStatus(
        selectedProjectStatus
      )
      params['body'] = value
      params['url'] = window.location.href
      let result = await MutationHelper('createArrangement', { input: params })
      if (result.error) {
        console.log(result)
        props.enqueueSnackbar('案件手配ができませんでした', {
          variant: 'error'
        })
      } else {
        props.enqueueSnackbar('案件手配をしました', {
          variant: 'success'
        })
        onClose('matter')
        if (display.kinds == 'apply') {
          fetchArrangementDataWrapper(
            residentInfoChangeApplication.application.id
          )
        } else {
          fetchArrangementDataWrapper(thread.id)
        }
      }
    }

    const handleSubmit = async () => {
      setIsLoading(true)
      await createArrangement()
      setIsLoading(false)
    }

    const validationProposalForm = () => {
      let isError = false
      let assignedCompanyUser = false
      let assignedCompanyGroup = false
      let projectStatus = false
      if (selectedAssignedCompanyGroup == 0) {
        assignedCompanyGroup = true
        isError = true
      }
      if (selectedAssignedCompanyUser == 0) {
        assignedCompanyUser = true
        isError = true
      }
      if (selectedProjectStatus == 0) {
        projectStatus = true
        isError = true
      }
      if (count > 300) {
        isError = true
      }
      setErrorMessageList({
        assignedCompanyGroup,
        assignedCompanyUser,
        projectStatus
      })
      return isError
    }

    const CloseArrangementDialog = () => {
      const result = window.confirm(
        `案件手配内容が削除されますがよろしいですか？`
      )
      if (!result) return
      onClose('matter')
    }

    return (
      <Dialog open={open}>
        <Grid className={classes.loading}>
          <Loading isLoading={isLoading} />
        </Grid>
        <DialogTitle className={classes.dialogColor}>案件アレンジ</DialogTitle>
        <DialogContent className={classes.dialogColor}>
          <Grid className={classes.matterModalSubTitle}>
            設定する担当者にメール通知され、案件へのアクセスが可能になります。
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Dropdown
                name="外部利用者グループ"
                label={'外部利用者グループ'}
                backgroundColor={'#FFF'}
                onChange={handleChangeAssignedCompanyGroup}
                defaultValue={selectedAssignedCompanyGroup}
                value={selectedAssignedCompanyGroup}
                items={companyGroupList}
                hasError={errorMessageList['assignedCompanyGroup']}
                errorMessage={'必須です'}
                disabled={isRestrictedRole}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                name="担当者"
                label={'担当者'}
                backgroundColor={'#FFF'}
                onChange={handleChangeAssignedCompanyUser}
                defaultValue={selectedAssignedCompanyUser}
                value={selectedAssignedCompanyUser}
                items={assignedCompanyUserList}
                hasError={errorMessageList['assignedCompanyUser']}
                errorMessage={'必須です'}
                disabled={isRestrictedRole}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.correspondenceArea}>
            <Grid items xs={7}>
              <Dropdown
                name="案件ステータス"
                label={'案件ステータス'}
                backgroundColor={'#FFF'}
                onChange={handleChangeProjectStatus}
                defaultValue={selectedProjectStatus}
                value={selectedProjectStatus}
                items={projectStatusList}
                hasError={errorMessageList['projectStatus']}
                errorMessage={'必須です'}
                disabled={isRestrictedRole}
              />
            </Grid>
          </Grid>
          <Grid className={classes.memoArea}>
            <TextareaAutosize
              required
              label={'本文'}
              id="body"
              style={{ height: 200, width: 650, fontSize: '16px' }}
              autoComplete="body"
              rowsMin="6"
              className={classes.memo}
              defaultValue={
                editMemoInformation != null ? editMemoInformation.memo : value
              }
              onChange={handleChangeValue}
              placeholder={
                'ここに備考メモを追加できます。備考メモの内容は担当者に送られます。'
              }
            />
          </Grid>
          <Grid container justify="flex-end">
            <Grid className={classes.errorMessage}>
              <Typography variant="body2">
                {count > 300
                  ? '文字数制限 : 300文字以内で入力してください'
                  : null}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2">{count}</Typography>
            </Grid>
            <Grid>
              <Typography variant="body2">/300</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.matterButtonPosition + ' ' + classes.dialogColor}
        >
          <Button
            className={classes.cancelButton}
            onClick={() => CloseArrangementDialog()}
            size={'large'}
          >
            キャンセル
          </Button>
          <Button
            className={classes.saveButton}
            onClick={handleSubmit}
            size={'large'}
          >
            設定する
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const ProjectArrangementButton = props => {
    return (
      <Grid className={classes.matterArrangementButton}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => props.handleOpen('matter')}
          size="large"
          disabled={isRestrictedRole}
        >
          案件手配を開始
        </Button>
      </Grid>
    )
  }

  return (
    <>
      <FormLayout title="対応状況">
        <Grid container spacing={3} className={classes.correspondenceArea}>
          {!props.isLoading ? (
            <SupportedStatus
              companyGroupItems={companyGroupItems}
              companyGroupId={companyGroupId}
              display={display}
              thread={thread && thread}
              residentInfoChangeApplication={
                residentInfoChangeApplication && residentInfoChangeApplication
              }
              setDeactivatedCompanyGroup={setDeactivatedCompanyGroup}
              setDeactivatedCompanyUser={setDeactivatedCompanyUser}
              deactivatedCompanyGroup={deactivatedCompanyGroup}
              deactivatedCompanyUser={deactivatedCompanyUser}
              originalData={
                display.kinds == 'apply'
                  ? residentInfoChangeApplication.application &&
                    residentInfoChangeApplication.application
                  : thread && thread
              }
              fetchData={fetchData}
              companyUserItems={companyUserItems}
              companyUserId={companyUserId}
              dispatchNotification={dispatchNotification}
              threadStateId={threadStateId}
              disabled={disabled}
              applicationStateId={applicationStateId}
              enqueueSnackbar={props.enqueueSnackbar}
              orgThreadStateId={orgThreadStateId}
              orgApplicationStateId={orgApplicationStateId}
              roleId={roleId}
              isOwnerPath={isOwnerPath}
            />
          ) : (
            <Grid item xs={12}>
              <Loading isLoading={props.isLoading} />
            </Grid>
          )}
        </Grid>
      </FormLayout>

      <Divider />

      {/* オーナーとのチャットでは案件手配はできない */}
      {!isOwnerPath && (
        <>
          <FormLayout title="案件手配">
            {arrangementData == null ? (
              <ProjectArrangementButton handleOpen={handleOpen} />
            ) : (
              <ProjectArrangementInformation
                setEditMemo={setEditMemo}
                changeMemoInformation={changeMemoInformation}
                companyGroupList={companyGroupList}
                fetchedCompanyGroupList={fetchedCompanyGroupList}
                fetchedCompanyUserList={fetchedCompanyUserList}
                companyUserMap={companyUserMap}
                arrangementData={arrangementData}
                isLoading={isArrangementLoading}
                formatSelectedProjectStatus={formatSelectedProjectStatus}
                enqueueSnackbar={props.enqueueSnackbar}
                fetchArrangementDataWrapper={fetchArrangementDataWrapper}
                display={display}
                thread={thread && thread}
                residentInfoChangeApplication={
                  residentInfoChangeApplication && residentInfoChangeApplication
                }
                roleId={roleId}
              />
            )}
          </FormLayout>
          <Divider />
        </>
      )}

      <div className={classes.root}>
        <div className={classes.section}>
          <Grid container alignItems="center">
            <Grid>
              <Typography className={classes.title}>備考メモ</Typography>
            </Grid>
            <Grid className={classes.right}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                className={classes.button}
                onClick={() => handleOpen()}
                size="small"
                disabled={isRestrictedRole}
              >
                メモ
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <RemarksMemoDialog
            open={remarksMemoState}
            onClose={handleClose}
            changeMemoInformation={changeMemoInformation}
            isDisabled={isDisabled}
            typeDialog={typeDialog}
            editMemoInformation={editMemoInformation}
            classes={classes}
          />
          <ArrangementDialog
            open={matterModalState}
            onClose={handleClose}
            changeMemoInformation={changeMemoInformation}
            matterModalState={matterModalState}
            enqueueSnackbar={props.enqueueSnackbar}
            companyGroupList={companyGroupList}
            companyUserMap={companyUserMap}
            formatSelectedProjectStatus={formatSelectedProjectStatus}
          />
          {remarksMemoList.map((row, index) => (
            <div className={classes.positionRelative} key={index}>
              <Grid className={classes.textArea}>{row.memo}</Grid>
              <Grid className={classes.memoDetail} alignItems="center">
                <Grid>
                  <Typography style={{ fontSize: 13 }}>
                    {formatYYYYMMDD(new Date(row.updated_at))}&nbsp;
                    {formatTime(new Date(row.updated_at))}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid>
                    <Typography style={{ fontSize: 13 }}>
                      {row.updater.name &&
                        getFormattedNameByStatus(
                          row.updater.name,
                          row.updater.status
                        )}
                    </Typography>
                  </Grid>
                  <Grid className={classes.right}>
                    <EditIcon
                      style={{ fontSize: 20 }}
                      className={isRestrictedRole && classes.disabledIconStyle}
                      onClick={() => setEditMemo(row, 'edit')}
                    />
                    <DeleteIcon
                      style={{ fontSize: 20 }}
                      className={isRestrictedRole && classes.disabledIconStyle}
                      onClick={() => changeMemoInformation(row, 'delete')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = () => {}

const mapDispatchToProps = () => {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(MessageInfo)))
