import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Auth, Storage } from 'aws-amplify'

const useStyles = makeStyles(theme => {
  const setSize = size => (size ? size : '100%')
  return {
    faceIcon: {
      float: 'left',
      width: props => setSize(props.width),
      height: props => setSize(props.height),
      marginTop: props => (props.marginTop ? props.marginTop : '0'),
      marginBottom: props => (props.marginBottom ? props.marginBottom : '0'),
      marginRight: '12px'
    },
    faceIconImg: {
      width: '100%',
      height: '100%',
      borderRadius: '50%'
    }
  }
})

function UserImage(props) {
  const classes = useStyles(props)
  const attachment = props.attachment ? props.attachment : null
  const [images, setImages] = useState([])

  useEffect(() => {
    if (attachment) {
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        console.log(key)
        Storage.get(key, {
          level: 'protected',
          identityId: `${attachment.company_id}`,
          expires: 60
        })
          .then(result => {
            console.log(result)
            const object = Object.assign({}, attachment, { body: result })
            setImages([object])
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }, [attachment, setImages])

  return (
    <>
      {attachment ? (
        <div className={classes.faceIcon}>
          {images.length > 0 &&
            images.map(({ status, filename, body }, index) => {
              return status == 1 ? (
                <img
                  className={classes.faceIconImg}
                  alt={filename}
                  src={body}
                />
              ) : (
                <></>
              )
            })}
        </div>
      ) : (
        <div className={classes.faceIcon} />
      )}
    </>
  )
}

export default UserImage
