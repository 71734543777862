import React from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  button: {
    marginLeft: '16px',
    color: '#fff'
  }
}))

function Body(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={e =>
          history.push({
            pathname: `/management/faq/list/${
              location.state !== undefined ? location.state.pageNumber : 1
            }`,
            state: location.state
          })
        }
      >
        {/* <IconButton
        onClick={() => props.history.push(`/management/message-template`)}
        aria-label="back"
        className={classes.button}
      > */}
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>{props.title}</Typography>
    </>
  )
}

export default withRouter(Body)
