import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

function CommonChip({ label, onDelete }) {
  const classes = useStyles()
  return (
    <>
      {label && label !== '' && (
        <li>
          <Chip className={classes.chip} label={label} onDelete={onDelete} />
        </li>
      )}
    </>
  )
}

export default CommonChip
