import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Label from 'components/common/Label'
import { LINK_DATA_STATE_LIST } from 'utils/const'
import DateLabel from 'components/common/DateLabel'
import { Auth, Storage } from 'aws-amplify'
import { withSnackbar } from 'notistack'

const columns = [
  { id: 'data_link_state_id', label: '状態', minWidth: 197 },
  { id: 'attachment_id', label: 'アップロードファイル', minWidth: 568 },
  { id: 'created_at', label: '登録日時', minWidth: 281 }
]

function List(props) {
  const handleClick = async (attachment, event, state) => {
    event.preventDefault()
    // データ連携処理状況が異常終了：5の場合のみエラーダイアログを表示する
    if (state == 5) {
      props.enqueueSnackbar(
        <div>
          サーバーで意図しないエラーが発生しました。
          <br />
          FAQをご確認頂いても解決しない場合は、
          <br />
          サポートへお問合せいただきますようお願いいたします。
        </div>,
        {
          variant: 'error'
        }
      )
      return
    }

    if (attachment) {
      console.log(JSON.stringify(attachment))
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        await Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            console.log(JSON.stringify(result))
            fetch(result).then(response => {
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = attachment.filename
                a.click()
              })
            })
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }

  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                width: column.width
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.dataLinkHistoryList.length
          ? props.dataLinkHistoryList.map(row => {
              const state = LINK_DATA_STATE_LIST.find(
                item => item.value == row.data_link_history.data_link_state_id
              )
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.data_link_history.id}
                  onClick={e =>
                    handleClick(
                      row.error_attachment,
                      e,
                      row.data_link_history.data_link_state_id
                    )
                  }
                >
                  <TableCell>
                    <Label color={state.color} text={state.label} />
                  </TableCell>
                  <TableCell>
                    {row.attachment ? row.attachment.filename : ''}
                  </TableCell>
                  <TableCell>
                    {<DateLabel timestamp={row.data_link_history.created_at} />}
                  </TableCell>
                </TableRow>
              )
            })
          : null}
      </TableBody>
    </Table>
  )
}

export default withSnackbar(List)
