import Resizer from 'react-image-file-resizer'

// 最大ファイルサイズ 2MB
const MAXIMUM_FILE_SIZE = 1024 ** 2 * 2

export const resizeImage = (file, maxWidth, maxHeight) => {
  const getFileExtension = filename => {
    return filename.split('.').pop()
  }

  return new Promise(resolve => {
    if (file.size < MAXIMUM_FILE_SIZE) {
      resolve(file)
      return
    }
    const format = getFileExtension(file.name)
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      format,
      100,
      0,
      resizedImage => {
        resolve(resizedImage)
      },
      'file'
    )
  })
}
