import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'

function SearchFormContent(props) {
  const { parents, children, searchCondition } = props

  const [filterChildren, setFilterChildren] = useState(
    children && 'management.faq.parent_id' in searchCondition
      ? children[searchCondition['management.faq.parent_id']]
      : [
          {
            label: '大カテゴリを選択してください',
            value: ''
          }
        ]
  )
  const [attachment] = useState([
    { value: '', label: '' },
    { value: 1, label: '添付有り' },
    { value: 0, label: '添付無し' }
  ])

  const handleParent = event => {
    setFilterChildren(children[event.target.value])
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.faq.parent_id"
            label="大カテゴリ"
            defaultValue={
              'management.faq.parent_id' in searchCondition
                ? searchCondition['management.faq.parent_id']
                : ''
            }
            onChange={handleParent}
            items={parents}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.faq.category_id"
            label="中カテゴリ"
            defaultValue={
              'management.faq.category_id' in searchCondition
                ? searchCondition['management.faq.category_id']
                : ''
            }
            items={filterChildren}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.faq.question"
          label="質問内容"
          defaultValue={
            'management.faq.question' in searchCondition
              ? searchCondition['management.faq.question']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.faq.attachment_id"
            label="添付"
            defaultValue={
              'management.faq.attachment_id' in searchCondition
                ? searchCondition['management.faq.attachment_id']
                : ''
            }
            items={attachment}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default withSnackbar(SearchFormContent)
