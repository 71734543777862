import React from 'react'
import { useHistory } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import LinearProgress from '@material-ui/core/LinearProgress'
import DateLabel from 'components/common/DateLabel'
import { Grid } from '@material-ui/core'
import NewReleasesIcon from '@material-ui/icons/NewReleases'

const useStyles = makeStyles(theme => ({
  '.MuiDrawer-paper': {
    maxHeight: '600px'
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
  list: {
    width: '400px'
  },
  container: {
    maxHeight: 540,
    maxWidth: '100%'
  },
  large: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  medium: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  }
}))

export default function NoticeList({ toggleDrawer, items, isLoading }) {
  const history = useHistory()
  const classes = useStyles()

  const handleClick = (id, ownerId) => {
    toggleDrawer()
    const path = ownerId
      ? `/ownerMessage/detail/${id}`
      : `/message/detail/${id}`
    history.push(path)
  }

  return (
    <div className={classes.list} role="presentation">
      <Toolbar>
        <span style={{ marginRight: '10px', color: 'red' }}>
          <NewReleasesIcon />
        </span>
        <Typography className={classes.title} variant="subtitle1">
          未対応メッセージ
          <br />
          <Typography variant="caption" display="block" gutterBottom>
            最新{items.length}件
          </Typography>
        </Typography>

        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key={'createdAt'}
                style={{
                  width: '30%'
                }}
              >
                受信日時
              </TableCell>
              <TableCell
                key={'category'}
                style={{
                  width: '70%'
                }}
              >
                カテゴリ
              </TableCell>
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {items.map(row => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() =>
                      handleClick(
                        row.thread.id,
                        row.owner ? row.owner.id : null
                      )
                    }
                  >
                    <TableCell>
                      <DateLabel timestamp={row.thread.created_at} />
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item className={classes.large}>
                          <span>{row.largeCategory.name}</span>
                        </Grid>
                        <Grid item className={classes.medium}>
                          <span>
                            {row.mediumCategory && row.mediumCategory.name}
                          </span>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
        {isLoading && <LinearProgress style={{ width: '100%' }} />}
      </TableContainer>
    </div>
  )
}
