import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { handleChange, handleClear } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import { CompanyGroupRemark } from 'utils/enum'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  typography: {
    paddingLeft: '12px',
    height: '18px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#72809d',
    fontSize: '12px',
    lineHeight: '18px'
  },
  roleDescription: {
    color: theme.palette.c_red.main
  }
}))

function CompanyGroupForm(props) {
  const classes = useStyles()
  const [roleList, setRoleList] = useState([])
  const [selectedRoleDetail, setSelectedRoleDetail] = useState('')
  const { companyGroup, setEnableForm, isErrorRole } = props

  const [values, setValues] = useState({
    id: '',
    name: '',
    name_err: '',
    role_id: '',
    role_id_err: ''
  })

  const checkErr = objects => {
    if (objects.name_err || objects.role_id_err) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const handleSelectRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '選択してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })

    if (name === 'role_id') {
      const roleDetail = getRoleDetail(value)
      setSelectedRoleDetail(roleDetail)
    }
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const getRoleDetail = itemId => {
    switch (itemId) {
      case CompanyGroupRemark.adminUser.code:
        return CompanyGroupRemark.adminUser.value
      case CompanyGroupRemark.generalUser.code:
        return CompanyGroupRemark.generalUser.value
      case CompanyGroupRemark.readUser.code:
        return CompanyGroupRemark.readUser.value
      case CompanyGroupRemark.supportCenterAdminUser.code:
        return CompanyGroupRemark.supportCenterAdminUser.value
      case CompanyGroupRemark.externalUser.code:
        return CompanyGroupRemark.externalUser.value
      case CompanyGroupRemark.supportCenterGeneralUser.code:
        return CompanyGroupRemark.supportCenterGeneralUser.value
      default:
        return ''
    }
  }

  useEffect(() => {
    props.handleClear()
    const initializeValues = {
      id: 'id' in companyGroup && companyGroup.id ? companyGroup.id : '',
      name:
        'name' in companyGroup && companyGroup.name ? companyGroup.name : '',
      name_err:
        'name' in companyGroup && companyGroup.name
          ? companyGroup.name
            ? ''
            : '入力してください'
          : '',
      role_id:
        'role_id' in companyGroup && companyGroup.role_id
          ? companyGroup.role_id
          : '',
      role_id_err:
        'role_id' in companyGroup && companyGroup.role_id
          ? companyGroup.role_id
            ? ''
            : '選択してください'
          : ''
    }
    setValues(initializeValues)
    if (isErrorRole) {
      if (values.name != '') {
        initializeValues.name = values.name
        props.handleChange({ ['name']: values.name })
      }

      if (values.role_id == '') {
        initializeValues.role_id_err = '選択してください'
      }
    }
    checkErr(initializeValues)
    const l = []
    props.roleList.forEach(item => {
      l.push({
        value: item.id,
        label: item.name
      })
    })
    setRoleList(l)
  }, [companyGroup, setValues, setRoleList, isErrorRole])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextInput
          error={values.name_err != '' ? true : false}
          helperText={values.name_err}
          required
          id="name"
          name="name"
          label="グループ名"
          defaultValue={values.name}
          fullWidth
          variant="filled"
          inputProps={{ maxLength: 100 }}
          onChange={handleInputRequiredChange}
        />
        <Typography className={classes.typography}>100文字以内</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl required fullWidth>
          <Dropdown
            hasError={values.role_id_err != '' ? true : false}
            errorMessage={values.role_id_err}
            required
            name="role_id"
            label="権限"
            defaultValue={values.role_id}
            onChange={handleSelectRequiredChange}
            items={roleList}
          />
          <Typography
            className={`${classes.typography} ${classes.roleDescription}`}
          >
            {selectedRoleDetail}
          </Typography>
        </FormControl>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CompanyGroupForm))
