import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FormModalDialog from './FormModalDialog'
import FormFooter from './FormFooter'
import Dropdown from 'components/common/Form/Dropdown'
import Grid from '@material-ui/core/Grid'
import { QueryHelper, MutationHelper } from 'utils/api.utils'
import { MessageCategoryType, ThreadState } from 'utils/enum'
import { withSnackbar } from 'notistack'
import { useCustomerOrOwner } from 'hooks/useCustomerOrOwner'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: theme.palette.c_white.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  button: {
    marginLeft: '16px',
    color: theme.palette.c_white.main
  },
  primaryButton: {
    margin: '0 16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.text.disabled
    }
  }
}))

const Body = props => {
  const { title } = props
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [categoryList, setCategoryList] = useState()
  const [selectedCategory, setSelectedCategory] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [validateCategory, setValidateCategory] = useState({})
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCancel = async () => {
    setIsOpen(false)
  }

  const handleChangeCategory = e => {
    setSelectedCategory(e.target.value)
  }

  const createThread = async e => {
    e.preventDefault()

    if (validateForm()) {
      return
    }

    if (
      !window.confirm(
        'スレッドを作成すると削除が出来ません。\n※入居者アプリにもスレッドが表示されます。'
      )
    ) {
      return
    }

    const path = location.pathname
    const ownerId = parseInt(
      path.split('/owner/detail/')[1].split('/building/')[0]
    )
    const buildingId = parseInt(path.split('/building/')[1])

    const params = {}
    params.status = 1
    params.category_id = selectedCategory
    params.owner_id = ownerId
    params.building_id = buildingId
    params.thread_state_id = ThreadState.open.code
    params.is_reply = true

    const result = await MutationHelper('createThread', { input: params })
    if (result.error) {
      props.enqueueSnackbar('メッセージ作成に失敗しました', {
        variant: 'error'
      })
      return
    }
    history.push(`/ownerMessage/detail/${result.id}`)
  }

  const validateForm = () => {
    let isError = false
    if (!selectedCategory) {
      isError = true
      setValidateCategory({ hasError: true, message: '必須です' })
    } else {
      setValidateCategory({})
    }
    return isError
  }

  const fetchCategoryList = async () => {
    const categoryType = isOwnerPath
      ? MessageCategoryType.owner.code
      : MessageCategoryType.customer.code
    const result = await QueryHelper('getLargeCategoryAndMediumCategory', {
      category_type: categoryType
    })
    if (result.error) {
      props.enqueueSnackbar(`カテゴリーを取得できませんでした。`)
    } else {
      const categories = [{ value: 0, label: '' }]
      for (let item of result) {
        categories.push({
          value: item.large_categories.id,
          label: item.large_categories.name
        })
      }
      setCategoryList(categories)
    }
  }

  const fetchData = async () => {
    await fetchCategoryList()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>{title}</Typography>
      <Button
        variant="contained"
        className={classes.primaryButton}
        onClick={handleOpenModal}
      >
        メッセージ作成
      </Button>
      <FormModalDialog
        title={'スレッドを作成'}
        open={isOpen}
        handleCancel={handleCancel}
        content={
          <Grid container spacing={2} xs>
            <Grid item xs={12}>
              <Dropdown
                required={true}
                name="category"
                label={'カテゴリー'}
                onChange={handleChangeCategory}
                defaultValue={selectedCategory}
                value={selectedCategory}
                items={categoryList}
                hasError={validateCategory.hasError}
                errorMessage={validateCategory.message}
              />
            </Grid>
          </Grid>
        }
        footer={<FormFooter handleCancel={handleCancel} />}
        postData={createThread}
      />
    </>
  )
}

export default withSnackbar(Body)
