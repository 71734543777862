import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog } from 'actions/Actions'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  footerWrapper: {
    marginTop: '8px',
    marginLeft: '16px',
    marginBottom: '8px',
    marginRight: '16px'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function FormModalDialog(props) {
  const classes = useStyles()
  const { title, body } = props

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.closeModalDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <Divider />
        {body}
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FormModalDialog))
