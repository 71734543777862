import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import { OwnerState } from 'utils/enum'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dateMargin: {
    '& > .MuiFormControl-marginNormal ': {
      marginTop: '2px',
      marginBottom: '2px'
    }
  }
}))

const SearchFormContent = props => {
  const classes = useStyles()
  const { searchConditions, searchConditionErrors } = props

  const ownerStateList = [
    { value: 0, label: '' },
    {
      value: OwnerState.enabled.code,
      label: OwnerState.enabled.value
    },
    { value: OwnerState.disabled.code, label: OwnerState.disabled.value }
  ]

  const [selectedState, setSelectedState] = useState(
    Object.keys(searchConditions).length &&
      'owner.state' in searchConditions &&
      searchConditions['owner.state'] != ''
      ? JSON.parse(searchConditions['owner.state'])
      : 0
  )
  const [createdAtStrDateFrom, setCreatedAtStrDateFrom] = useState(
    Object.keys(searchConditions).length &&
      'owner.created_at_from' in searchConditions &&
      searchConditions['owner.created_at_from'] != ''
      ? searchConditions['owner.created_at_from']
      : null
  )

  const [createdAtStrDateTo, setCreatedAtStrDateTo] = useState(
    Object.keys(searchConditions).length &&
      'owner.created_at_to' in searchConditions &&
      searchConditions['owner.created_at_to'] != ''
      ? searchConditions['owner.created_at_to']
      : null
  )

  const [lastLoginAtFrom, setLastLoginAtFrom] = useState(
    Object.keys(searchConditions).length &&
      'owner.last_login_at_from' in searchConditions
      ? searchConditions['owner.last_login_at_from'] != ''
        ? searchConditions['owner.last_login_at_from']
        : null
      : null
  )

  const [lastLoginAtTo, setLastLoginAtTo] = useState(
    Object.keys(searchConditions).length &&
      'owner.last_login_at_to' in searchConditions
      ? searchConditions['owner.last_login_at_to'] != ''
        ? searchConditions['owner.last_login_at_to']
        : null
      : null
  )

  const handleDateChange = (date, setState) => {
    if (date == null) {
      setState(null)
      return
    }
    date.setHours(0, 0, 0, 0)
    setState(date)
  }

  const handleChangeState = event => {
    setSelectedState(JSON.stringify(event.target.value))
  }

  const handleChangeCreatedAtStrDateFrom = date => {
    handleDateChange(date, setCreatedAtStrDateFrom)
  }

  const handleChangeCreatedAtStrDateTo = date => {
    handleDateChange(date, setCreatedAtStrDateTo)
  }

  const handleChangeLastLoginAtFrom = date => {
    handleDateChange(date, setLastLoginAtFrom)
  }

  const handleChangeLastLoginAtTo = date => {
    handleDateChange(date, setLastLoginAtTo)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextInput
          name="owner.id"
          label="オーナーID"
          defaultValue={
            searchConditions && 'owner.id' in searchConditions
              ? searchConditions['owner.id']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          name="owner.state"
          label={'状態'}
          onChange={handleChangeState}
          defaultValue={selectedState}
          items={ownerStateList}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="owner.name"
          label="オーナー名"
          defaultValue={
            searchConditions && 'owner.name' in searchConditions
              ? searchConditions['owner.name']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="owner.name_kana"
          label="オーナー名(フリガナ)"
          defaultValue={
            searchConditions && 'owner.name_kana' in searchConditions
              ? searchConditions['owner.name_kana']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="owner.username"
          label="ログインID(メールアドレス)"
          defaultValue={
            searchConditions && 'owner.username' in searchConditions
              ? searchConditions['owner.username']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="owner.created_at_from"
            name="owner.created_at_from"
            label="作成日(From)"
            value={createdAtStrDateFrom}
            onChange={handleChangeCreatedAtStrDateFrom}
            error={searchConditionErrors['owner.created_at_from.message']}
            helperText={searchConditionErrors['owner.created_at_from.message']}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="owner.created_at_to"
            name="owner.created_at_to"
            label="作成日(To)"
            value={createdAtStrDateTo}
            onChange={handleChangeCreatedAtStrDateTo}
            error={searchConditionErrors['owner.created_at_to.message']}
            helperText={searchConditionErrors['owner.created_at_to.message']}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="owner.last_login_at_from"
            name="owner.last_login_at_from"
            label="最終ログイン日From"
            value={lastLoginAtFrom}
            onChange={handleChangeLastLoginAtFrom}
            error={searchConditionErrors['owner.last_login_at_from.message']}
            helperText={
              searchConditionErrors['owner.last_login_at_from.message']
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="owner.last_login_at_to"
            name="owner.last_login_at_to"
            label="最終ログイン日To"
            value={lastLoginAtTo}
            onChange={handleChangeLastLoginAtTo}
            error={searchConditionErrors['owner.last_login_at_to.message']}
            helperText={searchConditionErrors['owner.last_login_at_to.message']}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  return {
    conditions: state.searchCondition
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SearchFormContent))
