import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { FaqManagementState } from 'utils/enum'
import CategoryList from './CategoryList'
import FaqList from './FaqList'
import Loading from 'components/common/Loading'
import { useLoginInformation } from 'hooks/useLoginInformation'
import { MessageCategoryType } from 'utils/enum'

const EXTERNAL_USER = 5

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    width: '350px',
    height: '54px',
    padding: '15px 0px 15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  }
}))

const FaqInfo = props => {
  const classes = useStyles()

  const thread = props && props.thread ? props.thread : null
  const [categories, setCategories] = useState(null)
  const [selectedMediumCategory, setSelectedMediumCategory] = useState(null)
  const [faqList, setFaqList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { judgmentRoleId } = useLoginInformation()

  async function fetchFaqList(faqStatus, offset, limit) {
    if (!thread) {
      return
    }
    const params = { filter: { faqStatus, offset, limit } }
    const result = await QueryHelper('getFaqListBySearchCondition', params)
    if (result.error) {
      props.enqueueSnackbar('FAQ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setFaqList(result.data)
    }
  }

  const fetchLargeCategoriesAndMediumCategories = async () => {
    if (!thread) {
      return
    }
    const result = await QueryHelper('getLargeCategoryAndMediumCategory', {
      category_type: MessageCategoryType.customer.code
    })
    if (result.error) {
      props.enqueueSnackbar('カテゴリ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCategories(result)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([
      faqList ? '' : fetchFaqList(FaqManagementState.all.code, 0, undefined),
      categories ? '' : fetchLargeCategoriesAndMediumCategories()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [thread, setFaqList, setCategories])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={9} alignItems="center" spacing={3}>
              <Typography className={classes.title}>{'FAQリスト'}</Typography>
            </Grid>
            {judgmentRoleId() !== EXTERNAL_USER && (
              <IconButton
                aria-label="new"
                onClick={() =>
                  window.open(`${window.location.origin}/management/faq/list/1`)
                }
              >
                <OpenInNewIcon />
              </IconButton>
            )}
          </Grid>
        </div>
        <Divider />
        {isLoading ? (
          <Loading isLoading={isLoading} marginTop={10} />
        ) : (
          <>
            {selectedMediumCategory ? (
              <FaqList
                faqList={faqList}
                mediumCategory={selectedMediumCategory}
                handleSelectedMediumCategory={setSelectedMediumCategory}
                joinBody={props.joinBody}
              />
            ) : (
              <CategoryList
                categories={categories}
                handleSelectedMediumCategory={setSelectedMediumCategory}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default withSnackbar(withRouter(FaqInfo))
