import React from 'react'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { useRole } from 'hooks/useRole'

function Footer({ handleDelete, handleCancel, handleSave, ...props }) {
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        height: '68px',
        alignItems: 'center',
        paddingRight: 32,
        paddingLeft: 32,
        borderRight: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderLeft: 'solid 1px rgba(0, 0, 0, 0.12)'
      }}
    >
      <div style={{ flex: 1, display: 'flex' }}>
        <Button
          startIcon={<DeleteIcon color="disabled" />}
          onClick={handleDelete}
          disabled={isRestrictedRole}
        >
          削除
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={handleCancel}
          disabled={isRestrictedRole}
        >
          キャンセル
        </Button>
      </div>
      <div style={{ marginLeft: '16px' }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isRestrictedRole}
        >
          保存
        </Button>
      </div>
    </div>
  )
}

export default Footer
