import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import { Avatar, IconButton } from '@material-ui/core'
import { Auth, Storage } from 'aws-amplify'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  }
}))

function ImageForm(props) {
  const classes = useStyles()

  const [images, setImages] = useState([])
  const [params, setParams] = useState([])
  const [isLoading2, setIsLoading2] = useState(false)
  const { isLoading, companyUser } = props

  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const object = {
        status: 1,
        attachment_type_id: 1,
        mime_type: file.type,
        filename: file.name,
        body: reader.result
      }

      setImages([object])
      let splicedParams = params.filter(element => !(element instanceof File))
      splicedParams.push(file)
      setParams(splicedParams)
      props.handleChange({ images: [...splicedParams] })
    }
    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if ('attachment' in companyUser && companyUser.attachment) {
      setIsLoading2(true)
      const attachment = companyUser.attachment
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        console.log(key)
        Storage.get(key, {
          level: 'protected',
          identityId: creds.params.User.CompanyId,
          expires: 60
        })
          .then(result => {
            console.log(result)
            const object = Object.assign({}, attachment, { body: result })
            setImages([object])
            setParams([object])
            setIsLoading2(false)
          })
          .catch(err => {
            console.log('error: ', err)
            setIsLoading2(false)
          })
      })
    }
  }, [companyUser, setImages, setParams])

  return (
    <div style={{ position: 'relative' }}>
      {isLoading || isLoading2 ? (
        <Loading isLoding={isLoading || isLoading2} />
      ) : (
        <>
          <div
            style={{
              width: '144px',
              height: '144px',
              borderRadius: '50%',
              padding: '8px',
              backgroundColor: '#fff'
            }}
          >
            {images.map(({ status, body }, index) => {
              return (
                status == 1 && (
                  <Avatar
                    alt={companyUser.company_user ? companyUser.name : ''}
                    src={body}
                    style={{
                      width: '100%',
                      height: '100%',
                      color: '#fff',
                      backgroundColor: '#d7e1ea'
                    }}
                  />
                )
              )
            })}
            {images.length == 0 && (
              <Avatar
                style={{
                  width: '100%',
                  height: '100%',
                  color: '#fff',
                  backgroundColor: '#d7e1ea'
                }}
              />
            )}
          </div>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="contained-button-file">
            <IconButton
              component="span"
              style={{
                position: 'absolute',
                bottom: '0px',
                right: '0px',
                minWidth: '48px',
                width: '48px',
                height: '48px',
                borderRadius: '50%',
                backgroundColor: '#fff'
              }}
            >
              <PhotoCameraIcon
                style={{ width: '24px', height: '24px', color: '#778699' }}
              />
            </IconButton>
          </label>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageForm)
