import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { fromCode } from 'utils/enum.utils'
import { BicycleParkingStickerReason } from 'utils/enum'
import { Auth, Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function CellWithBicycleParkingStickerReason({ reasonId }) {
  const bicycleParkingStickerReason = fromCode(
    BicycleParkingStickerReason,
    reasonId
  )
  return <>{bicycleParkingStickerReason.value}</>
}

function BicycleParkingStickerApplicationDetail(props) {
  const classes = useStyles()
  const [images, setImages] = useState([])

  let { isLoading, content, attachment } = props

  useEffect(() => {
    if (attachment) {
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        console.log(key)
        Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            console.log(result)
            const object = Object.assign({}, attachment, { body: result })
            setImages([object])
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }, [attachment, setImages])
  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.root} colSpan="4">
              申請内容
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell className={classes.columnName2}>
                <Loading isLoading={isLoading} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow>
                <TableCell className={classes.columnName2}>台数</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'bicycleCount' in content && content.bicycleCount}
                </TableCell>
                <TableCell className={classes.columnName2}>申請理由</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'reasonId' in content ? (
                    <CellWithBicycleParkingStickerReason
                      reasonId={content.reasonId}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.columnName1}>理由詳細</TableCell>
                <TableCell className={classes.columnValue1} colSpan="3">
                  {content && 'reasonBody' in content && content.reasonBody}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BicycleParkingStickerApplicationDetail
