import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextInput from 'components/common/Form/TextInput'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(() => ({
  textInputPadding: {
    paddingBottom: '20px'
  }
}))

const SearchFormContent = props => {
  const classes = useStyles()

  const { searchConditions, buildingList } = props

  const [id, setId] = useState([])
  const [residentName, setResidentName] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState([])
  const [initialSelectedBuilding] = useState(
    Object.keys(searchConditions).length &&
      'building_name' in searchConditions &&
      searchConditions['building_name'] != ''
      ? JSON.parse(searchConditions['building_name'])
      : []
  )

  const handleChangeId = event => {
    setId(JSON.stringify(event.target.value))
  }

  const handleChangeResidentName = event => {
    setResidentName(JSON.stringify(event.target.value))
  }

  const handleChangeBuilding = (event, value) => {
    setSelectedBuilding(value.length > 0 ? JSON.stringify(value) : '')
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12} className={classes.textInputPadding}>
          <TextInput
            name="kyk_id"
            label="ID"
            value={id}
            defaultValue={
              'kyk_id' in searchConditions ? searchConditions['kyk_id'] : ''
            }
            fullWidth
            variant="filled"
            type="number"
            onChange={handleChangeId}
          />
        </Grid>
        <Grid item xs={12} className={classes.textInputPadding}>
          <TextInput
            name="resident_name"
            label="入居者名"
            defaultValue={
              'resident_name' in searchConditions
                ? searchConditions['resident_name']
                : ''
            }
            value={residentName}
            fullWidth
            variant="filled"
            onChange={handleChangeResidentName}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={buildingList}
            getOptionLabel={option => option.TTY_NAME}
            defaultValue={initialSelectedBuilding}
            filterSelectedOptions
            filterOptions={(options, state) => {
              return options.filter(
                option =>
                  option.TTY_NAME.indexOf(state.inputValue) !== -1 ||
                  option.TTY_NAME_KANA.indexOf(state.inputValue) !== -1
              )
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="filled"
                label="物件名"
                autoComplete="off"
              />
            )}
            onChange={handleChangeBuilding}
          />
          <input type="hidden" name="building_name" value={selectedBuilding} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchFormContent
