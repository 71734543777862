import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import { setLoadData } from 'actions/Actions'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import SendIcon from '@material-ui/icons/Send'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CommonDialog from 'components/common/CommonDialog'
import { MutationHelper } from 'utils/api.utils'
import UserImage from './UserImage'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FormHelperText from '@material-ui/core/FormHelperText'
import { NotificationContext } from 'context/NotificationContext'
import { useRole } from 'hooks/useRole'
import { useCustomerOrOwner } from '../../../hooks/useCustomerOrOwner'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  inputFieldWrapper: {
    backgroundColor: '#fff'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200,
    minHeight: 36,
    maxHeight: 36,
    backgroundColor: '#fff',
    '& > .MuiInputBase-formControl, .MuiInputBase-formControl > .MuiSelect-select': {
      minHeight: 36,
      maxHeight: 36
    },
    '& > .MuiInputBase-formControl > .MuiSelect-select': {
      lineHeight: '35px'
    }
  },
  companyUserName: {
    marginTop: '8px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_blue.main,
    backgroundColor: theme.palette.c_white.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  },
  input: {
    display: 'none'
  },
  fileUpload: {
    marginLeft: '10px'
  },
  chatBody: {
    width: '100%',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W6, Hiragino Sans W3',
    fontWeight: '400',
    lineHeight: '1.4em'
  }
}))

function getDefaultCompanyUserId() {
  const item = sessionStorage.getItem('totono-advertiser-session')
    ? sessionStorage.getItem('totono-advertiser-session')
    : localStorage.getItem('totono-advertiser-session')
  const data = JSON.parse(item)
  return data.user_id
}

function MessageDetailForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  const { setLoadData } = props
  const thread = props && props.thread ? props.thread : {}
  const threadId =
    props && props.thread && props.thread.id ? props.thread.id : {}
  const companyUserList =
    props && props.companyUserList ? props.companyUserList : {}
  const [filteredCompanyUserList, setfilteredCompanyUserList] = useState([])
  const defaultCompanyUserId = getDefaultCompanyUserId()
  const [companyUserId, setCompanyUserId] = useState(defaultCompanyUserId)
  const body = props && props.body
  const setBody = props && props.setBody
  const disabled = thread.thread_state_id === 4

  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false)

  const [validate, setValidate] = useState({})

  const [{}, dispatchNotification] = React.useContext(NotificationContext)

  const filterByCompanyGroup = () => {
    if (companyUserList.length > 0) {
      const loginUser = companyUserList.filter(
        companyUser => companyUser.company_user.id == defaultCompanyUserId
      )
      // CompanyUserは全てのStatusから取得しているのでStatus：1のみを使用するようにする
      if (loginUser[0].company_group.role_id === 5) {
        const groupUsers = companyUserList.filter(
          companyUser =>
            companyUser.company_user.company_group_id ==
              loginUser[0].company_user.company_group_id &&
            companyUser.company_group.status != 3 &&
            companyUser.company_user.status === 1
        )
        setfilteredCompanyUserList(groupUsers)
      } else {
        const activeCompanyUserList = companyUserList.filter(
          companyUser =>
            companyUser.company_group.status != 3 &&
            companyUser.company_user.status === 1
        )
        setfilteredCompanyUserList(activeCompanyUserList)
      }
    }
  }

  const handleChangeCompanyUserId = event => {
    setCompanyUserId(event.target.value)
  }

  const handleChangeBody = event => {
    setBody(event.target.value)
  }

  const handleUploadFile = async event => {
    if (!window.confirm('チャットにファイルを添付します。よろしいですか？')) {
      return
    }
    event.preventDefault()
    setLoadData(true)
    const file = event.target.files[0]

    let messageTypeId = 0
    let attachmentTypeId = 0
    let uploadTarget = ''
    if (file.type.indexOf('image') !== -1) {
      messageTypeId = 2
      attachmentTypeId = 1
      uploadTarget = 'images'
    } else if (file.type.indexOf('pdf') !== -1) {
      messageTypeId = 7
      attachmentTypeId = 3
      uploadTarget = 'pdfs'
    } else {
      messageTypeId = 3
      attachmentTypeId = 2
      uploadTarget = 'videos'
    }

    const creds = await Auth.currentCredentials()
    const result = await fileupload(creds, uploadTarget, file)
    const attachment = {
      attachment_type_id: attachmentTypeId,
      mime_type: file.type,
      filename: file.name,
      key: result.key
    }

    const messageParams = {
      status: 1,
      thread_id: threadId,
      company_user_id: companyUserId,
      message_type_id: messageTypeId,
      body: body,
      is_read: false,
      attachment: attachment
    }

    await createMessage(messageParams)

    // ヘッダの通知部分更新用
    dispatchNotification({
      type: 'SET',
      payload: true
    })

    await Promise.all([
      await props.fetchThread(threadId),
      await props.fetchMessageAndAttachmentList(threadId)
    ])
    document.getElementById('contained-button-file').value = null
    setLoadData(false)
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    return result
  }

  const createMessage = async params => {
    setLoadData(true)

    // ownerの場合はthreadのcustomer_idがowner_idになる
    if (isOwnerPath) {
      params.owner_id = thread.customer_id
    }

    const result = await MutationHelper('createMessage', { input: params })
    if (result.error) {
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      setBody('')
      props.enqueueSnackbar('送信しました', {
        variant: 'success'
      })
      setLoadData(false)
    }
  }

  const handleSubmitMessage = async event => {
    event.preventDefault()
    if (!body || body === '') {
      setValidate({
        body: { hasError: true, message: '空メッセージは送れません' }
      })
      return
    }
    setLoadData(true)
    const params = {
      status: 1,
      thread_id: threadId,
      company_user_id: companyUserId,
      message_type_id: 1,
      body: body,
      is_read: false
    }

    await createMessage(params)

    // ヘッダの通知部分更新用
    dispatchNotification({
      type: 'SET',
      payload: true
    })

    await Promise.all([
      await props.fetchThread(threadId),
      await props.fetchMessageAndAttachmentList(threadId)
    ])

    setLoadData(false)
  }

  const handleComplete = event => {
    setIsCompleteDialogOpen(true)
  }

  const handleCancelComplete = event => {
    setIsCompleteDialogOpen(false)
  }

  const doComplete = async event => {
    event.preventDefault()
    setLoadData(true)

    const messageParams = {
      status: 1,
      thread_id: threadId,
      company_user_id: companyUserId,
      message_type_id: 4,
      is_read: false
    }

    // ownerの場合はthreadのcustomer_idがowner_idになる
    if (isOwnerPath) {
      messageParams.owner_id = thread.customer_id
    }

    const result2 = await MutationHelper('createMessage', {
      input: messageParams
    })
    if (result2.error) {
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
      setIsCompleteDialogOpen(false)
      setLoadData(false)
      return
    } else {
      props.enqueueSnackbar('解決済みにしました', {
        variant: 'success'
      })
    }
    await Promise.all([
      await props.fetchThread(threadId),
      await props.fetchMessageAndAttachmentList(threadId)
    ])
    setIsCompleteDialogOpen(false)
    setLoadData(false)
  }

  useEffect(() => {
    filterByCompanyGroup()
  }, [])

  return (
    <form onSubmit={handleSubmitMessage}>
      <div className={classes.root}>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <div className={classes.inputFieldWrapper}>
              {/*<TextField*/}
              {/*  class*/}
              {/*  id="body"*/}
              {/*  name="body"*/}
              {/*  fullWidth*/}
              {/*  multiline*/}
              {/*  rows="4"*/}
              {/*  autoComplete="body"*/}
              {/*  variant="outlined"*/}
              {/*  defaultValue={body}*/}
              {/*  value={body}*/}
              {/*  onChange={handleChangeBody}*/}
              {/*  error={validate && validate.body && validate.body.hasError}*/}
              {/*  helperText={validate && validate.body && validate.body.message ? validate.body.message : ''}*/}
              {/*/>*/}
              <TextareaAutosize
                id="body"
                name="body"
                autoComplete="body"
                rowsMin="6"
                className={classes.chatBody}
                variant="outlined"
                defaultValue={body}
                value={body}
                onChange={handleChangeBody}
              />
              {validate && validate.body && validate.body.hasError && (
                <FormHelperText
                  error={validate && validate.body && validate.body.hasError}
                >
                  {validate.body.message}
                </FormHelperText>
              )}
            </div>
          </Grid>
        </Grid>
        <div style={{ height: 15 }} />
        <Grid container xs={12}>
          <Grid item xs={3}>
            {filteredCompanyUserList && filteredCompanyUserList.length > 0 && (
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <Select
                  name="company_user_id"
                  defaultValue={defaultCompanyUserId}
                  onChange={handleChangeCompanyUserId}
                >
                  {filteredCompanyUserList.map(row => {
                    return (
                      <MenuItem
                        value={row.company_user.id}
                        key={row.company_user.id}
                      >
                        <UserImage
                          attachment={row.attachment}
                          width={'25px'}
                          height={'25px'}
                          marginTop={'5px'}
                        />
                        {row.company_user.display_name
                          ? row.company_user.display_name
                          : row.company_user.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={2} className={classes.fileUpload}>
            <input
              accept="image/*,video/*,application/pdf"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleUploadFile}
              disabled={disabled}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                startIcon={<AttachFileIcon />}
                component="span"
                className={classes.secondaryButton}
                disabled={disabled}
              >
                ファイル
              </Button>
            </label>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              startIcon={<BeenhereIcon />}
              className={classes.secondaryButton}
              onClick={handleComplete}
              disabled={disabled || role3()}
            >
              解決
            </Button>
            <CommonDialog
              isOpen={isCompleteDialogOpen}
              title={'解決ボタンを送信'}
              msg={
                isOwnerPath
                  ? 'オーナーにお問い合わせが解決したかどうかの質問ボタンを送信します'
                  : '入居者にお問い合わせが解決したかどうかの質問ボタンを送信します'
              }
              doYes={doComplete}
              yesMsg={'送信'}
              doNo={handleCancelComplete}
            />
          </Grid>
          <Grid item xs={4} justify="flex-end" className={classes.textRight}>
            <Button
              type="submit"
              variant="contained"
              startIcon={<SendIcon />}
              className={classes.primaryButton}
              disabled={disabled || role3()}
            >
              送信
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(MessageDetailForm)))
