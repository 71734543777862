import { useLocation } from 'react-router-dom'

export const useCustomerOrOwner = () => {
  // URLからowner用の画面かどうかを判定するカスタムフック
  const useCheckOwnerPath = () => {
    const location = useLocation()
    const isOwnerPath = location.pathname.includes('owner')
    return isOwnerPath
  }

  // ストレージに保存するキーを変更するカスタムフック
  const useChangeStorageKey = () => {
    const isOwnerPath = useCheckOwnerPath()

    const prefix = isOwnerPath ? 'ownerMessage.' : 'message.'

    // storageKeyを保管する連想配列
    const storageKeyList = {
      pageNumber: `${prefix}page_number`,
      residentName: `${prefix}resident_name`,
      bulkDeliveryList: `${prefix}bulk_delivery_list`,
      threadState: `${prefix}thread_state`,
      replyState: `${prefix}reply_state`,
      id: `${prefix}id`,
      ttyName: `${prefix}TTY_NAME`,
      parentCategory: `${prefix}parent_category`,
      childCategory: `${prefix}child_category`,
      firstTransceiverState: `${prefix}first_transceiver_state`,
      createdAtStrDateFrom: `${prefix}created_at_str_date_from`,
      createdAtStrDateTo: `${prefix}created_at_str_date_to`,
      exchangedAtStrDateFrom: `${prefix}exchanged_at_str_date_from`,
      exchangedAtStrDateTo: `${prefix}exchanged_at_str_date_to`,
      assignedCompanyGroup: `${prefix}assigned_company_group`,
      assignedCompanyUser: `${prefix}assigned_company_user`,
      exchangedCompanyUser: `${prefix}exchanged_company_user`,
      bulkDeliveryListName: `${prefix}bulk_delivery_list_name`,
      isArranged: `${prefix}is_arranged`,
      isBulkDelivery: `${prefix}is_bulk_delivery`,
      createdAtStrDateFromMessage: `${prefix}created_at_str_date_from.message`,
      createdAtStrDateToMessage: `${prefix}created_at_str_date_to.message`,
      exchangedAtStrDateFromMessage: `${prefix}exchanged_at_str_date_from.message`,
      exchangedAtStrDateToMessage: `${prefix}exchanged_at_str_date_to.message`,
      memberFlag1: `${prefix}member_flag1`,
      memberFlag2: `${prefix}member_flag2`,
      knrPattern: `${prefix}knr_pattern`,
      knrTanto: `${prefix}knr_tanto`,
      ttyTnp: `${prefix}tty_tnp`
    }

    return storageKeyList
  }

  // 検索条件があるかどうかを判定するカスタムフック
  const useCheckSearchConditions = searchCondition => {
    const isOwnerPath = useCheckOwnerPath()
    const keyToCheck = isOwnerPath ? 'ownerMessage' : 'message'

    for (let key in searchCondition) {
      // page_numberがある場合は除外する
      if (key.includes('page_number')) continue
      if (key.includes(keyToCheck)) return true
    }
    return false
  }

  return {
    useCheckOwnerPath,
    useChangeStorageKey,
    useCheckSearchConditions
  }
}
