import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { connect } from 'react-redux'
import {
  openSearchModalDialog,
  saveMessageTemplateSearchCondition
} from 'actions/Actions'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '12px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function SearchTextField({ text, ...props }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(
      location.state !== undefined &&
        'management.messageTemplate.title' in location.state
        ? location.state['management.messageTemplate.title']
        : ''
    )
  }, [location])

  const handleSearch = event => {
    event.preventDefault()
    props.saveMessageTemplateSearchCondition(
      inputValue != '' ? { 'management.messageTemplate.title': inputValue } : {}
    )
    history.push({
      pathname: `/management/message-template/list/`,
      state:
        inputValue != ''
          ? { 'management.messageTemplate.title': inputValue }
          : {
              dummy: ''
            }
    })
  }
  return (
    <Paper component="form" className={classes.root}>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={handleSearch}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={text}
        inputProps={{ 'aria-label': { text } }}
        onChange={event => {
          setInputValue(event.target.value)
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleSearch(event)
          }
        }}
        value={inputValue}
      />
      {/* <IconButton className={classes.iconButton} aria-label="directions">
        <ExpandMoreIcon />
      </IconButton> */}
    </Paper>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    openSearchModalDialog: () => {
      dispatch(openSearchModalDialog())
    },
    saveMessageTemplateSearchCondition: parameter => {
      dispatch(saveMessageTemplateSearchCondition(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchTextField)
