import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
  return {
    loadingWrapper: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.5,
      zIndex: 1000
    },
    loading: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: props => (props.marginTop ? props.marginTop : '0'),
      marginBottom: props => (props.marginBottom ? props.marginBottom : '0')
    }
  }
})

function Loading(props) {
  const classes = useStyles(props)
  const { isLoading } = props

  return (
    <>
      {isLoading && (
        <div className={classes.loadingWrapper}>
          <div className={classes.loading}>
            <CircularProgress color="inherit" />
          </div>
        </div>
      )}
    </>
  )
}

export default Loading
