import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    height: '68px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  left: {
    flex: 1,
    display: 'flex'
  },
  right: {
    marginLeft: '16px',
    marginRight: '32px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function Buttons(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Button
          variant="contained"
          className={classes.secondaryButton}
          onClick={props.handleCancel}
        >
          キャンセル
        </Button>
      </div>
      <div className={classes.right}>
        <Button
          variant="contained"
          className={classes.primaryButton}
          onClick={props.handleSubmit}
        >
          保存
        </Button>
      </div>
    </div>
  )
}

export default Buttons
