import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessages } from 'utils/errorMessages'

export const useCompanyNotificationCategoryForm = () => {
  const schema = yup.object().shape({
    category: yup.array().of(
      yup.object().shape({
        mail_address1: yup
          .string()
          .email(ErrorMessages.IncorrectEmailAddressFormat)
          .max(254, ErrorMessages.EmailAddressExcessInput),
        mail_address2: yup
          .string()
          .email(ErrorMessages.IncorrectEmailAddressFormat)
          .max(254, ErrorMessages.EmailAddressExcessInput),
        mail_address3: yup
          .string()
          .email(ErrorMessages.IncorrectEmailAddressFormat)
          .max(254, ErrorMessages.EmailAddressExcessInput),
        mail_address4: yup
          .string()
          .email(ErrorMessages.IncorrectEmailAddressFormat)
          .max(254, ErrorMessages.EmailAddressExcessInput),
        mail_address5: yup
          .string()
          .email(ErrorMessages.IncorrectEmailAddressFormat)
          .max(254, ErrorMessages.EmailAddressExcessInput)
      })
    )
  })

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    reset,
    getValues,
    control,
    formState
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  return {
    register,
    handleSubmit,
    trigger,
    watch,
    reset,
    getValues,
    control,
    formState
  }
}
