import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  closeSearchModalDialog,
  handleClear,
  saveContractSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import { AppUsage, ScreenUrl } from 'utils/enum'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import Loading from 'components/common/Loading'
import CommonChip from 'components/common/CommonChip'
import { SearchConditionContext } from 'context/SearchConditionContext'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { pageId } = useParams()

  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : props.contractSearchParams
  )
  const [searchConditionErrors, setSearchConditionErrors] = useState({})
  const [contractList, setContractList] = useState(initialState)
  const [ttyTnpList, setTtyTnpList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )
  const [{}, dispatchSearchCondition] = React.useContext(SearchConditionContext)

  const handleChangePage = (event, newPage) => {
    searchCondition['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    searchCondition['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  async function fetchContractList(offset, limit) {
    var filter = {}
    var kyk_keiyaku_date = []
    var registeredAtToArr = []
    var lastLoginAtArr = []
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'management.contract.tenant_id') {
          filter['tenant_id'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'management.contract.kky_id') {
          filter['kky_id'] = {
            eq: Number(searchCondition[key])
          }
        }
        if (key == 'management.contract.kky_knr_no') {
          filter['kky_knr_no'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.contract.contractor_id') {
          filter['contractor_id'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'management.contract.TTY_NAME') {
          filter['TTY_NAME'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'management.contract.kky_tel_02') {
          filter['kky_tel_02'] = {
            eq: searchCondition[key]
          }
        }

        if (key == 'management.contract.kyk_keiyaku_date_from') {
          if ('management.contract.kyk_keiyaku_date_to' in searchCondition) {
            kyk_keiyaku_date.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('契約日Toを指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'management.contract.kyk_keiyaku_date_to') {
          if ('management.contract.kyk_keiyaku_date_from' in searchCondition) {
            kyk_keiyaku_date.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('契約日Fromを指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'management.contract.registered_at_from') {
          if ('management.contract.registered_at_to' in searchCondition) {
            registeredAtToArr.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('アプリ利用開始日Toを指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'management.contract.registered_at_to') {
          if ('management.contract.registered_at_from' in searchCondition) {
            registeredAtToArr.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('アプリ利用開始日Fromを指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'management.contract.last_login_at_from') {
          if ('management.contract.last_login_at_to' in searchCondition) {
            lastLoginAtArr.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('最終ログイン日Toを指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'management.contract.last_login_at_to') {
          if ('management.contract.last_login_at_from' in searchCondition) {
            lastLoginAtArr.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('最終ログイン日Fromを指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'management.contract.is_usage') {
          if (searchCondition[key] == 1) {
            filter['app_user_id'] = {
              isNull: 0
            }
            filter['is_management'] = {
              eq: true
            }
            filter['is_usage'] = {
              eq: true
            }
          } else if (searchCondition[key] == 2) {
            filter['is_use_app'] = {
              eq: true
            }
          } else {
            filter['is_use_app'] = {
              eq: false
            }
          }
        }
        if (key == 'management.contract.kyk_status_div') {
          filter['kyk_status_div'] = {
            eq: [1, 2, 3, 4, 5]
          }
        }
        if (key == 'management.contract.memberFlag1') {
          filter['KYK_MEMBER_FLG'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.contract.memberFlag2') {
          filter['KYK_MEMBER_FLG_2'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.contract.tty_tnp') {
          filter['TTY_TNP_ID'] = {
            eq: searchCondition[key]
          }
        }
      }
    })
    if (kyk_keiyaku_date.length > 0) {
      filter['kyk_keiyaku_date'] = {
        between: kyk_keiyaku_date
      }
    }
    if (registeredAtToArr.length > 0) {
      filter['registered_at'] = {
        between: registeredAtToArr
      }
    }
    if (lastLoginAtArr.length > 0) {
      filter['last_login_at'] = {
        between: lastLoginAtArr
      }
    }
    if (!filter['kyk_status_div']) {
      filter['kyk_status_div'] = {
        eq: [1, 2, 3]
      }
    }
    if (orderBy && Object.keys(orderBy).length > 0) {
      filter.orderBy = [orderBy]
    }

    // CSVDL用に検索条件保存
    dispatchSearchCondition({
      type: 'SET',
      payload: { contractSearchCondition: filter }
    })
    let result = await QueryHelper(
      'getContractList2',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('入居者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setContractList(initialState)
      setContractList(result)
    }
  }

  const fetchTateyaIndividualMaster = async () => {
    const result = await QueryHelper('getTateyaIndividualMaster', {})
    if (result.error) {
      props.enqueueSnackbar('建物管理データを取得できませんでした', {
        variant: 'error'
      })
    } else {
      const defaultItems = [{ value: '', label: '' }]
      const mappedTtyTnpItems = (result.KnrTenpo || []).map(item => ({
        value: item.TTY_TNP_ID,
        label: item.TTY_TNP_NAME
      }))
      setTtyTnpList([...defaultItems, ...mappedTtyTnpItems])
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (let entry of formData.entries()) {
      if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    if (validateSearchCondition(params)) {
      return
    }
    props.saveContractSearchCondition(params)
    props.closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(params, setPageNumber(1), setOrderBy({}))
  }

  function validateSearchCondition(params) {
    const valid = {}

    if (
      params['management.contract.kyk_keiyaku_date_from'] &&
      !params['management.contract.kyk_keiyaku_date_to']
    ) {
      valid['management.contract.kyk_keiyaku_date_to.message'] =
        '契約日(From)が指定されてる時は契約日(To)は必須です'
    }
    if (
      !params['management.contract.kyk_keiyaku_date_from'] &&
      params['management.contract.kyk_keiyaku_date_to']
    ) {
      valid['management.contract.kyk_keiyaku_date_from.message'] =
        '契約日(To)が指定されてる時は契約日(From)は必須です'
    }
    if (
      params['management.contract.registered_at_from'] &&
      !params['management.contract.registered_at_to']
    ) {
      valid['management.contract.registered_at_to.message'] =
        'アプリ利用開始日(From)が指定されてる時はアプリ利用開始日(To)は必須です'
    }
    if (
      !params['management.contract.registered_at_from'] &&
      params['management.contract.registered_at_to']
    ) {
      valid['management.contract.registered_at_from.message'] =
        'アプリ利用開始日(To)が指定されてる時はアプリ利用開始日(From)は必須です'
    }
    if (
      params['management.contract.last_login_at_from'] &&
      !params['management.contract.last_login_at_to']
    ) {
      valid['management.contract.last_login_at_to.message'] =
        '最終ログイン日(From)が指定されてる時は最終ログイン日(To)は必須です'
    }
    if (
      !params['management.contract.last_login_at_from'] &&
      params['management.contract.last_login_at_to']
    ) {
      valid['management.contract.last_login_at_from.message'] =
        '最終ログイン日(To)が指定されてる時は最終ログイン日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchContractList(offset, PAGE_LIMIT),
      fetchTateyaIndividualMaster()
    ])
    setIsLoading(false)
  }

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchCondition['orderBy'] = sortParam
    searchCondition['pageNumber'] = 1
    setPageNumber(1)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.managementContractList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, setContractList, searchCondition, orderBy])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.managementContractList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'management.contract.building_id': {
        const buildings = JSON.parse(searchCondition[target])
        const trimBuildings = buildings.filter(building => building.id !== id)
        if (trimBuildings.length > 0) {
          params[target] = JSON.stringify(trimBuildings)
        } else {
          delete params[target]
        }
        break
      }
      case 'management.contract.kyk_keiyaku_date_from':
      case 'management.contract.kyk_keiyaku_date_to': {
        delete params['management.contract.kyk_keiyaku_date_from']
        delete params['management.contract.kyk_keiyaku_date_to']
        break
      }
      case 'management.contract.registered_at_from':
      case 'management.contract.registered_at_to': {
        delete params['management.contract.registered_at_from']
        delete params['management.contract.registered_at_to']
        break
      }
      case 'management.contract.last_login_at_from':
      case 'management.contract.last_login_at_to': {
        delete params['management.contract.last_login_at_from']
        delete params['management.contract.last_login_at_to']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    props.saveContractSearchCondition(params)
    setSearchCondition(params, setPageNumber(1), setOrderBy({}))
  }

  function isContainsManagementBySearchConditions() {
    const val = Object.keys(searchCondition).find(x => x.match(/^management.+/))
    if (val === undefined) {
      return Object.keys(searchCondition).indexOf('selectedStatus') !== -1
    }
    return true
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsManagementBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'management.contract.tenant_id': {
                    label = '入居者名: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.kky_knr_no': {
                    label = '顧客番号: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.contractor_id': {
                    label = '契約者名: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.kky_tel_02': {
                    label = '電話番号: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.TTY_NAME': {
                    label = '物件名: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.kyk_keiyaku_date_from': {
                    label = '契約日From: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.kyk_keiyaku_date_to': {
                    label = '契約日To: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.registered_at_from': {
                    label = 'アプリ利用開始日From: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.registered_at_to': {
                    label = 'アプリ利用開始日To: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.last_login_at_from': {
                    label = '最終ログイン日To: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.last_login_at_to': {
                    label = '最終ログイン日From: ' + searchCondition[key]
                    break
                  }
                  case 'management.contract.is_usage': {
                    switch (searchCondition[key]) {
                      case AppUsage.none.code + '':
                        label = 'アプリ利用: ' + AppUsage.none.value
                        break
                      case AppUsage.enabled.code + '':
                        label = 'アプリ利用: ' + AppUsage.enabled.value
                        break
                      case AppUsage.disabled.code + '':
                        label = 'アプリ利用: ' + AppUsage.disabled.value
                        break
                    }
                    break
                  }
                  case 'management.contract.kyk_status_div': {
                    label = '退去者を含む: ON'
                    break
                  }
                  case 'management.contract.memberFlag1': {
                    label = '会員フラグ1: ON'
                    break
                  }
                  case 'management.contract.memberFlag2': {
                    label = '会員フラグ2: ON'
                    break
                  }
                  case 'management.contract.tty_tnp': {
                    const value = searchCondition[key]
                    if (value && value !== 0 && ttyTnpList) {
                      const knrTnp = ttyTnpList.find(
                        item => item.value === Number(value)
                      )
                      if (knrTnp) {
                        label = `建物取扱店舗: ${knrTnp.label}`
                      }
                    }
                    break
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips}></Grid>
        <Grid item xs={5} className={classes.tableTips}></Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={contractList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              contractList={contractList.data}
              searchCondition={searchCondition}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={contractList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            ttyTnpList={ttyTnpList}
            searchCondition={searchCondition}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.managementContractList}
            searchConditions={searchCondition}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    contractSearchParams: state.saveSearchCondition.contract
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    saveContractSearchCondition: parameter => {
      dispatch(saveContractSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
