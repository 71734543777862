import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { handleClear, setLoadData } from 'actions/Actions'
import Left from './Left'
import Right from './Right'
import Footer from './Footer'
import { withSnackbar } from 'notistack'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import FormModalDialog from './FormModalDialog'
import ModalDialogFooter from './ModalDialogFooter'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  leftBottom: {
    backgroundColor: '#fff'
  },
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function EditScreen(props) {
  const classes = useStyles()

  const { id } = useParams()
  const { setLoadData } = props

  const [isErrorCategory, setIsErrorCategory] = useState(false)
  const [messageTemplate, setMessageTemplate] = useState({})
  const [enableCategoryForm, setEnableCategoryForm] = useState(true)
  const [enableMessageTemplateForm, setEnableMessageTemplateForm] = useState(
    true
  )
  const [isLoading, setIsLoading] = useState(false)

  const fetchMessageTemplate = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    const result = await QueryHelper('getMessageTemplate', { filter: params })
    if (!result.error) {
      setMessageTemplate(result)
    } else {
      props.enqueueSnackbar(`定型文を取得できませんでした。`)
    }
  }

  const handleCancel = async event => {
    event.preventDefault()
    props.handleClear()
    setMessageTemplate(Object.assign({}, messageTemplate))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (!enableCategoryForm || !enableMessageTemplateForm) {
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }
    if (Object.keys(props.parameters).length > 0) {
      setLoadData(true)
      const params = Object.assign({}, props.parameters)
      if (id !== undefined) {
        params['id'] = id
        await putMessageTemplate(params)
      } else {
        if (!props.parameters['category_id']) {
          setEnableCategoryForm(false)
          setIsErrorCategory(true)
          props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
            variant: 'warning'
          })
          setLoadData(false)
          return
        }
        await postMessageTemplate(params)
      }
      setLoadData(false)
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
    }
  }

  const postMessageTemplate = async params => {
    setLoadData(true)
    const result = await MutationHelper('createMessageTemplate', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('定型文を登録できませんでした。', {
        variant: 'error'
      })
      setLoadData(false)
    } else {
      props.handleClear()
      props.enqueueSnackbar('定型文を登録しました。', {
        variant: 'success'
      })
      setLoadData(false)
      props.history.goBack()
    }
  }

  const putMessageTemplate = async params => {
    setLoadData(true)
    const result = await MutationHelper('updateMessageTemplate', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('定型文を更新できませんでした。', {
        variant: 'error'
      })
      setLoadData(false)
    } else {
      props.handleClear()
      props.enqueueSnackbar('定型文を更新しました。', {
        variant: 'success'
      })
      setLoadData(false)
      props.history.goBack()
    }
  }

  const deleteMessageTemplate = async event => {
    event.preventDefault()
    props.handleClear()
    const params = {
      id: id,
      status: 3
    }
    await putMessageTemplate(params)
  }

  const fetchData = async templateId => {
    setIsLoading(true)
    await Promise.all([fetchMessageTemplate(templateId)])
    setIsLoading(false)
  }

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id)
    }
  }, [setMessageTemplate])

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.leftBody}>
              <Left
                isLoading={isLoading}
                messageTemplate={messageTemplate}
                setEnableCategoryForm={setEnableCategoryForm}
                setEnableMessageTemplateForm={setEnableMessageTemplateForm}
                isErrorCategory={isErrorCategory}
              />
            </div>
            <div className={classes.leftBottom}>
              <Footer
                messageTemplate={messageTemplate}
                handleCancel={handleCancel}
              />
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.rightBody}>
              <Right isLoading={isLoading} messageTemplate={messageTemplate} />
            </div>
          </div>
        </div>
      </form>
      <FormModalDialog
        title="定型文の削除"
        description="※削除すると、メッセージ作成に使用できなくなります。"
        footer={<ModalDialogFooter />}
        postData={deleteMessageTemplate}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(EditScreen)))
