import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { handleClear, handleChange } from 'actions/Actions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { MutationHelper } from 'utils/api.utils'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  dialogStyle: {
    minWidth: '700px'
  },
  dialogTitle: {
    padding: '16px 24px 0px'
  },
  dialogContent: {
    padding: '2px 24px 8px'
  },
  primaryButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    },
    padding: '6px 60px'
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    },
    padding: '6px 36px',
    marginRight: '10px'
  },
  templateTitleStyle: {
    fontFamily: 'Hiragino Sans W4',
    fontSize: '13px',
    textAlign: 'right',
    padding: '0px 24px'
  },
  templateTitleStyleTypeError: {
    position: 'relative',
    top: '-20px'
  }
}))

function CreateFormDialog(props) {
  const params = props.params
  const classes = useStyles()
  useEffect(() => {
    props.handleClear()
    props.handleClose()
  }, [props.handleClear])

  const handleChange = event => {
    props.handleChange({
      [event.target.name]: event.target.value
    })
  }

  const [title, setTitle] = useState(null)
  const history = useHistory()
  const [validateTitle, setValidateTitle] = useState(null)
  const [count, setCount] = useState(0)

  function handleTitleChange(event) {
    setCount(event.target.value.length)
    setTitle(event.target.value)
  }

  function validateForm() {
    let isError = false

    if (count == 0) {
      setValidateTitle({ hasError: true, message: '必須です' })
      isError = true
    } else if (count > 30) {
      setValidateTitle({ hasError: true, message: '30文字を超えています' })
      isError = true
    } else {
      setValidateTitle(null)
    }
    return isError
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (validateForm()) {
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    if (title) {
      params.title = title
    }

    let result

    result = await MutationHelper('createBulkDeliveryTemplate', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
      props.handleClose('succes')
    }
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle className={classes.dialogTitle}>
            作成した内容をテンプレートとして保存できます。
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText className={classes.dialogText}>
              テンプレートとして保存する場合には、テンプレートのタイトルを入力し、保存ボタンを押してください。
            </DialogContentText>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="テンプレートタイトル(必須)"
                fullWidth
                autoComplete="name"
                variant="filled"
                onChange={handleTitleChange}
                value={title}
              />
            </Grid>
            <FormHelperText
              className={classes.helperText}
              error={validateTitle && validateTitle.hasError}
            >
              {validateTitle && validateTitle.message
                ? validateTitle.message
                : ''}
            </FormHelperText>
          </DialogContent>
          <Typography
            className={
              validateTitle != null
                ? `${classes.templateTitleStyleTypeError} ${classes.templateTitleStyle}`
                : classes.templateTitleStyle
            }
          >
            最大30文字
          </Typography>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={props.handleClose}
              color="primary"
              className={classes.secondaryButton}
            >
              キャンセル
            </Button>
            <Button
              color="primary"
              type="submit"
              className={classes.primaryButton}
            >
              保存
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CreateFormDialog))
