import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Typography from '@material-ui/core/Typography'

import Circle from 'components/common/Circle'

import { range } from 'utils/utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '20px',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}))

function createPaginationNumbers({ pageNum, maxPageNumber }) {
  if (maxPageNumber <= 5) return range(1, maxPageNumber)

  if (pageNum >= maxPageNumber - 2)
    return range(maxPageNumber - 4, maxPageNumber)

  if (pageNum <= 3) return range(1, 5)

  return range(pageNum - 2, pageNum + 2)
}

export default function Pagination({
  handlePageNumber,
  pageNumber,
  rowsPerPage,
  count
}) {
  const classes = useStyles()
  const maxPageNumber = Math.ceil(count / rowsPerPage)
  const pageNum = Number(pageNumber)

  const paginationNumbers = createPaginationNumbers({
    pageNum,
    maxPageNumber
  })

  const handlePrevDisabled = () => pageNum === 1
  const handleNextDisabled = () => pageNum === maxPageNumber

  function CircleWithNumber(paginationNumber) {
    return paginationNumber === Number(pageNum) ? (
      <Circle text="" active key={paginationNumber}>
        {paginationNumber}
      </Circle>
    ) : (
      <Circle
        key={paginationNumber}
        onClick={e => handlePageNumber(paginationNumber)}
      >
        {paginationNumber}
      </Circle>
    )
  }

  function PaginationCaption({ pageNum, rowsPerPage, count }) {
    const start = (pageNum - 1) * rowsPerPage + 1
    const end = pageNum * rowsPerPage <= count ? pageNum * rowsPerPage : count
    return (
      <Typography variant="subtitle1" style={{ marginRight: 20 }}>
        {`${start}-${end}/${count}`}
      </Typography>
    )
  }

  function handleNextUpdate() {
    if (pageNum < maxPageNumber) {
      handlePageNumber(pageNum + 1)
    }
  }
  function handlePrevUpdate() {
    if (pageNum > 1) {
      handlePageNumber(pageNum - 1)
    }
  }

  return (
    <div className={classes.root}>
      <PaginationCaption
        count={count}
        rowsPerPage={rowsPerPage}
        pageNum={pageNum}
      />
      <Circle disabled={handlePrevDisabled()} onClick={handlePrevUpdate}>
        <KeyboardArrowLeftIcon
          style={{ color: handlePrevDisabled() ? '#eee' : '#000' }}
        />
      </Circle>
      {paginationNumbers.map(item => CircleWithNumber(item))}
      <Circle disabled={handleNextDisabled()} onClick={handleNextUpdate}>
        <KeyboardArrowRight
          style={{ color: handleNextDisabled() ? '#eee' : '#000' }}
        />
      </Circle>
    </div>
  )
}
