import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import { BicycleParkingStickerReason } from 'utils/enum'
import { ArrangedStateList } from '../../../utils/enum'
import { useLoginInformation } from 'hooks/useLoginInformation'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%'
  }
}))

const arrangedStateList = [
  { value: 0, label: '' },
  {
    value: ArrangedStateList.arranged.code,
    label: ArrangedStateList.arranged.value
  },
  {
    value: ArrangedStateList.notArranged.code,
    label: ArrangedStateList.notArranged.value
  }
]

function SearchFormContent(props) {
  const classes = useStyles()
  const { judgmentRoleId } = useLoginInformation()

  const {
    companyGroupList,
    companyUserList,
    fetchedCompanyGroupList,
    fetchedCompanyUserList,
    masterData,
    searchCondition,
    searchConditionErrors
  } = props

  const [deactivatedCompanyGroup, setDeactivatedCompanyGroup] = useState(null)
  const [deactivatedCompanyUser, setDeactivatedCompanyUser] = useState(null)

  const [reasonList] = useState([
    { value: '', label: '' },
    {
      value: BicycleParkingStickerReason.new.code,
      label: BicycleParkingStickerReason.new.value
    },
    {
      value: BicycleParkingStickerReason.change.code,
      label: BicycleParkingStickerReason.change.value
    },
    {
      value: BicycleParkingStickerReason.add.code,
      label: BicycleParkingStickerReason.add.value
    }
  ])

  const [createdAtFrom, setCreatedAtFrom] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.created_at_from' in searchCondition
      ? searchCondition['application.bicycleParkingSticker.created_at_from'] !=
        ''
        ? searchCondition['application.bicycleParkingSticker.created_at_from']
        : null
      : null
  )
  const [createdAtTo, setCreatedAtTo] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.created_at_to' in searchCondition
      ? searchCondition['application.bicycleParkingSticker.created_at_to'] != ''
        ? searchCondition['application.bicycleParkingSticker.created_at_to']
        : null
      : null
  )
  const [initialSelectedReason] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.reasonId' in searchCondition
      ? searchCondition['application.bicycleParkingSticker.reasonId']
      : ''
  )

  const [selectedArrangedState, setSelectedArrangedState] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.is_arranged' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.is_arranged'] != ''
      ? JSON.parse(
          searchCondition['application.bicycleParkingSticker.is_arranged']
        )
      : null
  )

  const [
    selectedAssignedCompanyGroup,
    setSelectedAssignedCompanyGroup
  ] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.company_group_id' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.company_group_id'] !=
        ''
      ? JSON.parse(
          searchCondition['application.bicycleParkingSticker.company_group_id']
        )
      : null
  )
  const [
    selectedAssignedCompanyUser,
    setSelectedAssignedCompanyUser
  ] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.company_user_id' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.company_user_id'] != ''
      ? JSON.parse(
          searchCondition['application.bicycleParkingSticker.company_user_id']
        )
      : null
  )
  const [assignedCompanyUserList, setAssignedCompanyUserList] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.company_group_id' in searchCondition &&
      companyUserList &&
      companyUserList.has(
        Number(
          searchCondition['application.bicycleParkingSticker.company_group_id']
        )
      )
      ? companyUserList.get(
          Number(
            searchCondition[
              'application.bicycleParkingSticker.company_group_id'
            ]
          )
        )
      : [
          {
            label: '担当グループを選択してください',
            value: ''
          }
        ]
  )

  const [isMember1, setIsMember1] = useState(
    Object.keys(searchCondition).length > 0 &&
      'application.bicycleParkingSticker.memberFlag1' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.memberFlag1']
      ? true
      : false
  )

  const [isMember2, setIsMember2] = useState(
    Object.keys(searchCondition).length > 0 &&
      'application.bicycleParkingSticker.memberFlag2' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.memberFlag2']
      ? true
      : false
  )

  const [selectedKnrPatternId, setSelectedKnrPatternId] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.knrPattern' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.knrPattern'] != ''
      ? searchCondition['application.bicycleParkingSticker.knrPattern']
      : undefined
  )

  const [selectedKnrTantoId, setSelectedKnrTantoId] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.knrTanto' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.knrTanto'] != ''
      ? searchCondition['application.bicycleParkingSticker.knrTanto']
      : undefined
  )

  const [selectedTtyTnpId, setSelectedTtyTnpId] = useState(
    Object.keys(searchCondition).length &&
      'application.bicycleParkingSticker.ttyTnp' in searchCondition &&
      searchCondition['application.bicycleParkingSticker.ttyTnp'] != ''
      ? searchCondition['application.bicycleParkingSticker.ttyTnp']
      : undefined
  )

  const handleChangeCreatedAtFrom = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setCreatedAtFrom(date)
  }

  const handleChangeCreatedAtTo = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setCreatedAtTo(date)
  }

  const handleChangeArrangedState = event => {
    setSelectedArrangedState(JSON.stringify(event.target.value))
  }

  const handleChangeAssignedCompanyGroup = event => {
    setSelectedAssignedCompanyGroup(JSON.stringify(event.target.value))
    setDeactivatedCompanyGroup(null)
    setDeactivatedCompanyUser(null)
    if (event.target.value == 0) {
      setAssignedCompanyUserList([
        {
          label: '担当グループを選択してください',
          value: ''
        }
      ])
    } else {
      setAssignedCompanyUserList(companyUserList.get(event.target.value))
    }
    setSelectedAssignedCompanyUser(null)
  }

  const handleChangeAssignedCompanyUser = event => {
    if (event.target.value != '') {
      setSelectedAssignedCompanyUser(JSON.stringify(event.target.value))
      setDeactivatedCompanyUser(null)
    }
  }

  const handleChangeIsMember1 = event => {
    setIsMember1(event.target.checked)
  }

  const handleChangeIsMember2 = event => {
    setIsMember2(event.target.checked)
  }

  const handleChangeKnrPatternId = event => {
    setSelectedKnrPatternId(event.target.value)
  }

  const handleChangeKnrTantoId = event => {
    setSelectedKnrTantoId(event.target.value)
  }

  const handleChangeTtyTnpId = event => {
    setSelectedTtyTnpId(event.target.value)
  }

  useEffect(() => {
    if (fetchedCompanyGroupList && fetchedCompanyUserList) {
      const assignedGroup = fetchedCompanyGroupList.find(
        item => item.status === 3 && selectedAssignedCompanyGroup === item.id
      )
      const assignedUser = fetchedCompanyUserList.find(
        item =>
          item.company_user.status !== 1 &&
          selectedAssignedCompanyUser === item.company_user.id
      )
      setDeactivatedCompanyGroup(
        assignedGroup &&
          getFormattedNameByStatus(assignedGroup.name, assignedGroup.status)
      )
      setDeactivatedCompanyUser(
        assignedUser &&
          getFormattedNameByStatus(
            assignedUser.company_user.name,
            assignedUser.company_user.status
          )
      )
    }
  }, [fetchedCompanyGroupList, fetchedCompanyUserList])

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="application.bicycleParkingSticker.created_at_from"
            name="application.bicycleParkingSticker.created_at_from"
            label="申請日(From)"
            value={createdAtFrom}
            onChange={handleChangeCreatedAtFrom}
            error={
              searchConditionErrors[
                'application.bicycleParkingSticker.created_at_from.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'application.bicycleParkingSticker.created_at_from.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="application.bicycleParkingSticker.created_at_to"
            name="application.bicycleParkingSticker.created_at_to"
            label="申請日(To)"
            value={createdAtTo}
            onChange={handleChangeCreatedAtTo}
            error={
              searchConditionErrors[
                'application.bicycleParkingSticker.created_at_to.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'application.bicycleParkingSticker.created_at_to.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="application.bicycleParkingSticker.id"
          label="ID"
          defaultValue={
            'application.bicycleParkingSticker.id' in searchCondition
              ? searchCondition['application.bicycleParkingSticker.id']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="application.bicycleParkingSticker.customer_id"
          label="入居者名"
          defaultValue={
            'application.bicycleParkingSticker.customer_id' in searchCondition
              ? searchCondition['application.bicycleParkingSticker.customer_id']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="application.bicycleParkingSticker.TTY_NAME"
          label="物件名(フリガナ)"
          defaultValue={
            'application.bicycleParkingSticker.TTY_NAME' in searchCondition
              ? searchCondition['application.bicycleParkingSticker.TTY_NAME']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="application.bicycleParkingSticker.reasonId"
            label="理由"
            defaultValue={initialSelectedReason}
            items={reasonList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name="application.bicycleParkingSticker.is_arranged"
            label={'案件手配'}
            onChange={handleChangeArrangedState}
            defaultValue={selectedArrangedState}
            items={arrangedStateList}
          />
        </FormControl>
      </Grid>
      {judgmentRoleId() !== 5 ? (
        <>
          <Grid item xs={6}>
            <FormControl
              required
              variant="outlined"
              className={classes.formControl}
            >
              <Dropdown
                name="application.bicycleParkingSticker.company_group_id"
                label={'担当グループ'}
                onChange={handleChangeAssignedCompanyGroup}
                defaultValue={selectedAssignedCompanyGroup}
                items={companyGroupList}
                deactivatedItem={deactivatedCompanyGroup}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              required
              variant="outlined"
              className={classes.formControl}
            >
              <Dropdown
                name="application.bicycleParkingSticker.company_user_id"
                label={'担当者'}
                onChange={handleChangeAssignedCompanyUser}
                defaultValue={selectedAssignedCompanyUser}
                items={assignedCompanyUserList}
                deactivatedItem={deactivatedCompanyUser}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name={'application.bicycleParkingSticker.memberFlag1'}
                  checked={isMember1}
                  onChange={handleChangeIsMember1}
                />
              }
              label="会員フラグ1"
              value={isMember1}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name={'application.bicycleParkingSticker.memberFlag2'}
                  checked={isMember2}
                  onChange={handleChangeIsMember2}
                />
              }
              label="会員フラグ2"
              value={isMember2}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name={'application.bicycleParkingSticker.knrPattern'}
              label={'管理パターン'}
              onChange={handleChangeKnrPatternId}
              defaultValue={selectedKnrPatternId}
              items={masterData.ttyKnrPatternList}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name={'application.bicycleParkingSticker.knrTanto'}
              label={'管理担当者'}
              onChange={handleChangeKnrTantoId}
              defaultValue={selectedKnrTantoId}
              items={masterData.ttyKnrTantoList}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name={'application.bicycleParkingSticker.ttyTnp'}
              label={'建物取扱店舗'}
              onChange={handleChangeTtyTnpId}
              defaultValue={selectedTtyTnpId}
              items={masterData.ttyTnpList}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  )
}

export default withSnackbar(SearchFormContent)
