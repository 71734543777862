import React from 'react'
import { useHistory } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import UseOwnerLabel from 'components/common/UseOwnerLabel'
import FormModalDialog from 'components/common/FormModalDialog'
import FormContent from './FormContent'
import { connect } from 'react-redux'
import { openModalDialog, setLoadData } from 'actions/Actions'
import { handleOpenPage } from 'actions/Actions'
import { MutationHelper } from 'utils/api.utils'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const useStyles = makeStyles(() => ({
  labelButton: {
    width: '100%',
    marginTop: 10,
    padding: '15px 20px'
  },
  tableContainer: {
    maxHeight: '880px',
    overflowY: 'auto'
  }
}))

const columns = [
  { id: 'status', label: '状態', minWidth: 80 },
  { id: 'id', label: 'ID', minWidth: 100 },
  { id: 'name', label: 'オーナー名', minWidth: 100 },
  { id: 'owner_furigana', label: 'フリガナ', minWidth: 100 },
  {
    id: 'login_id',
    label: 'ログインID',
    minWidth: 100,
    hasSort: false
  },
  { id: 'delete', label: '', minWidth: 80 }
]

const OwnerRegistration = props => {
  const {
    buildingOwnerList,
    openModalDialog,
    building,
    setLoadData,
    fetchOwnerList
  } = props
  const classes = useStyles()
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const handleOpenModal = () => {
    openModalDialog()
  }

  const handleDelete = async (e, ownerId) => {
    e.stopPropagation()
    if (!window.confirm(`削除します。よろしいですか？`)) return

    setLoadData(true)
    const params = {
      owner_id: ownerId,
      building_id: building.id
    }
    const result = await MutationHelper('deleteOwnerBuilding', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('削除できませんでした', {
        variant: 'error'
      })
      setLoadData(false)
      return
    }
    props.enqueueSnackbar('削除しました', {
      variant: 'success'
    })
    fetchOwnerList(building.id)
    setLoadData(false)
  }

  const navigateToDetailPage = (e, ownerId, buildingId) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/owner/detail/${ownerId}/building/${buildingId}`,
      e,
      history
    )
  }

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, width: column.width }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {buildingOwnerList.length
              ? buildingOwnerList.map(row => {
                  return (
                    <TableRow
                      key={row.id}
                      onClick={e =>
                        navigateToDetailPage(
                          e,
                          row.owner.id,
                          row.owner_buildings[0].building_id
                        )
                      }
                      hover
                    >
                      <TableCell>
                        <UseOwnerLabel
                          is_usage={row.owner_buildings[0].is_usage}
                        />
                      </TableCell>
                      <TableCell>{row.owner.id}</TableCell>
                      <TableCell>{row.owner.name}</TableCell>
                      <TableCell>{row.owner.name_kana}</TableCell>
                      <TableCell>{row.app_user.username}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="削除"
                          color="primary"
                          onClick={e => handleDelete(e, row.owner.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.labelButton}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              {`オーナーを追加`}
            </Button>
          </div>
        </Grid>
        <FormModalDialog
          title="オーナーを追加"
          content={
            <FormContent building={building} fetchOwnerList={fetchOwnerList} />
          }
        />
      </Grid>
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    settingBuildingSearchParams: state.saveSearchCondition.settingBuilding
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalDialog: () => {
      dispatch(openModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(OwnerRegistration))
