import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import {
  ReplyState,
  ThreadState,
  FirstTransceiverState,
  ArrangedStateList,
  OnlySearchBulkDelivery
} from 'utils/enum'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { useLoginInformation } from 'hooks/useLoginInformation'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { useCustomerOrOwner } from 'hooks/useCustomerOrOwner'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dateMargin: {
    '& > .MuiFormControl-marginNormal ': {
      marginTop: '2px',
      marginBottom: '2px'
    }
  },
  img: {
    width: 25,
    height: 20,
    verticalAlign: 'middle'
  }
}))

const threadStateList = [
  {
    id: ThreadState.open.code,
    value: ThreadState.open.code,
    label: ThreadState.open.value
  },
  {
    id: ThreadState.inProgress.code,
    value: ThreadState.inProgress.code,
    label: ThreadState.inProgress.value
  },
  {
    id: ThreadState.pending.code,
    value: ThreadState.pending.code,
    label: ThreadState.pending.value
  },
  {
    id: ThreadState.complete.code,
    value: ThreadState.complete.code,
    label: ThreadState.complete.value
  }
]

const replyStateList = [
  { value: 0, label: '' },
  { value: ReplyState.noReply.code, label: ReplyState.noReply.value },
  { value: ReplyState.Replied.code, label: ReplyState.Replied.value }
]

const firstTransceiverList = [
  { value: 0, label: '' },
  {
    value: FirstTransceiverState.sent.code,
    label: FirstTransceiverState.sent.value
  },
  {
    value: FirstTransceiverState.received.code,
    label: FirstTransceiverState.received.value
  }
]

const arrangedStateList = [
  { value: 0, label: '' },
  {
    value: ArrangedStateList.arranged.code,
    label: ArrangedStateList.arranged.value
  },
  {
    value: ArrangedStateList.notArranged.code,
    label: ArrangedStateList.notArranged.value
  }
]

const onlySearchBulkDeliveryList = [
  { value: 0, label: '' },
  {
    value: OnlySearchBulkDelivery.bulkDelivery.code,
    label: OnlySearchBulkDelivery.bulkDelivery.value
  },
  {
    value: OnlySearchBulkDelivery.notBulkDelivery.code,
    label: OnlySearchBulkDelivery.notBulkDelivery.value
  }
]

function createInitSelectedThreadState(selectedThreadStates) {
  const threadStates = []
  if (selectedThreadStates) {
    for (let i of selectedThreadStates) {
      for (let threadState of threadStateList) {
        if (threadState.value === i) {
          threadStates.push(threadState)
          break
        }
      }
    }
  }
  return threadStates
}

function SearchFormContent(props) {
  const classes = useStyles()
  const { judgmentRoleId } = useLoginInformation()
  const { useCheckOwnerPath, useChangeStorageKey } = useCustomerOrOwner()
  const storageKeyList = useChangeStorageKey()
  const isOwnerPath = useCheckOwnerPath()
  const {
    parentCategoryList,
    childCategoryList,
    companyGroupList,
    companyUserList,
    fetchedCompanyGroupList,
    fetchedCompanyUserList,
    companyUserMap,
    bulkDeliveryListName,
    masterData,
    searchConditions,
    searchConditionErrors
  } = props

  const [deactivatedCompanyGroup, setDeactivatedCompanyGroup] = useState(null)
  const [deactivatedCompanyUser, setDeactivatedCompanyUser] = useState(null)

  const [initSelectedThreadState] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.threadState in searchConditions
      ? createInitSelectedThreadState(
          searchConditions[storageKeyList.threadState]
        )
      : []
  )
  const [selectedThreadState, setSelectedThreadState] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.threadState in searchConditions
      ? JSON.stringify(
          createInitSelectedThreadState(
            searchConditions[storageKeyList.threadState]
          )
        )
      : []
  )

  const [selectedReplyState, setSelectedReplyState] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.replyState in searchConditions &&
      searchConditions[storageKeyList.replyState] != ''
      ? JSON.parse(searchConditions[storageKeyList.replyState])
      : 0
  )

  const [residentName, setResidentName] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.residentName in searchConditions
      ? searchConditions[storageKeyList.residentName]
      : ''
  )

  const [id, setId] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.id in searchConditions
      ? searchConditions[storageKeyList.id]
      : ''
  )
  const [selectedParentCategory, setSelectedParentCategory] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.parentCategory in searchConditions &&
      searchConditions[storageKeyList.parentCategory] != ''
      ? JSON.parse(searchConditions[storageKeyList.parentCategory])
      : 0
  )
  const [selectedChildCategory, setSelectedChildCategory] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.parentCategory in searchConditions &&
      searchConditions[storageKeyList.parentCategory] != ''
      ? JSON.parse(searchConditions[storageKeyList.parentCategory])
      : 0
  )
  const [selectChildCategoryList, setSelectChildCategoryList] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.parentCategory in searchConditions &&
      searchConditions[storageKeyList.parentCategory] != ''
      ? childCategoryList[searchConditions[storageKeyList.parentCategory]]
      : [
          {
            label: '大カテゴリを選択してください',
            value: ''
          }
        ]
  )

  const [
    selectedFirstTransceiverState,
    setSelectedFirstTransceiverState
  ] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.firstTransceiverState in searchConditions &&
      searchConditions[storageKeyList.firstTransceiverState] != ''
      ? JSON.parse(searchConditions[storageKeyList.firstTransceiverState])
      : null
  )

  const [selectedArrangedState, setSelectedArrangedState] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.isArranged in searchConditions &&
      searchConditions[storageKeyList.isArranged] != ''
      ? JSON.parse(searchConditions[storageKeyList.isArranged])
      : null
  )

  const [createdAtStrDateFrom, setCreatedAtStrDateFrom] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.createdAtStrDateFrom in searchConditions &&
      searchConditions[storageKeyList.createdAtStrDateFrom] != ''
      ? searchConditions[storageKeyList.createdAtStrDateFrom]
      : null
  )
  const [createdAtStrDateTo, setCreatedAtStrDateTo] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.createdAtStrDateTo in searchConditions &&
      searchConditions[storageKeyList.createdAtStrDateTo] != ''
      ? searchConditions[storageKeyList.createdAtStrDateTo]
      : null
  )

  const [exchangedAtStrDateFrom, setExchangedAtAtStrDateFrom] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.exchangedAtStrDateFrom in searchConditions
      ? searchConditions[storageKeyList.exchangedAtStrDateFrom] != ''
        ? searchConditions[storageKeyList.exchangedAtStrDateFrom]
        : null
      : null
  )
  const [exchangedAtStrDateTo, setExchangedAtAtStrDateTo] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.exchangedAtStrDateTo in searchConditions &&
      searchConditions[storageKeyList.exchangedAtStrDateTo] != ''
      ? searchConditions[storageKeyList.exchangedAtStrDateTo]
      : null
  )

  const [
    selectedAssignedCompanyGroup,
    setSelectedAssignedCompanyGroup
  ] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.assignedCompanyGroup in searchConditions &&
      searchConditions[storageKeyList.assignedCompanyGroup] != ''
      ? JSON.parse(searchConditions[storageKeyList.assignedCompanyGroup])
      : 0
  )
  const [
    selectedAssignedCompanyUser,
    setSelectedAssignedCompanyUser
  ] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.assignedCompanyUser in searchConditions &&
      searchConditions[storageKeyList.assignedCompanyUser] != ''
      ? JSON.parse(searchConditions[storageKeyList.assignedCompanyUser])
      : 0
  )
  const [assignedCompanyUserList, setAssignedCompanyUserList] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.assignedCompanyGroup in searchConditions &&
      companyUserMap &&
      companyUserMap.has(
        Number(searchConditions[storageKeyList.assignedCompanyGroup])
      )
      ? companyUserMap.get(
          Number(searchConditions[storageKeyList.assignedCompanyGroup])
        )
      : [
          {
            label: '担当グループを選択してください',
            value: ''
          }
        ]
  )
  const [
    selectedExchangedCompanyUser,
    setSelectedExchangedCompanyUser
  ] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.exchangedCompanyUser in searchConditions &&
      searchConditions[storageKeyList.exchangedCompanyUser] != ''
      ? JSON.parse(searchConditions[storageKeyList.exchangedCompanyUser])
      : 0
  )

  const [selectedBulkDeliveryState, setSelectedBulkDeliveryState] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.isBulkDelivery in searchConditions &&
      searchConditions[storageKeyList.isBulkDelivery] != ''
      ? JSON.parse(searchConditions[storageKeyList.isBulkDelivery])
      : null
  )

  const [isMember1, setIsMember1] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.memberFlag1 in searchConditions &&
      searchConditions[storageKeyList.memberFlag1]
      ? true
      : false
  )

  const [isMember2, setIsMember2] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.memberFlag2 in searchConditions &&
      searchConditions[storageKeyList.memberFlag2]
      ? true
      : false
  )

  const [selectedKnrPatternId, setSelectedKnrPatternId] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.knrPattern in searchConditions &&
      searchConditions[storageKeyList.knrPattern] != ''
      ? searchConditions[storageKeyList.knrPattern]
      : undefined
  )

  const [selectedKnrTantoId, setSelectedKnrTantoId] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.knrTanto in searchConditions &&
      searchConditions[storageKeyList.knrTanto] != ''
      ? searchConditions[storageKeyList.knrTanto]
      : undefined
  )

  const [selectedTtyTnpId, setSelectedTtyTnpId] = useState(
    Object.keys(searchConditions).length &&
      storageKeyList.ttyTnp in searchConditions &&
      searchConditions[storageKeyList.ttyTnp] != ''
      ? searchConditions[storageKeyList.ttyTnp]
      : undefined
  )

  const handleChangeThreadState = (event, value) => {
    setSelectedThreadState(JSON.stringify(value))
  }

  const handleChangeReplyState = (event, value) => {
    setSelectedReplyState(JSON.stringify(event.target.value))
  }

  const handleChangeResidentName = (event, value) => {
    setResidentName(JSON.stringify(event.target.value))
  }

  const handleChangeId = (event, value) => {
    setId(JSON.stringify(event.target.value))
  }

  const handleChangeParentCategory = (event, value) => {
    if (value == 0) {
      setSelectChildCategoryList([])
    } else {
      setSelectChildCategoryList(childCategoryList[event.target.value])
    }
    setSelectedParentCategory(JSON.stringify(event.target.value))
    setSelectedChildCategory(0)
  }

  const handleChangeChildCategory = (event, value) => {
    setSelectedChildCategory(JSON.stringify(event.target.value))
  }

  const handleChangeFirstTransceiverState = (event, value) => {
    setSelectedFirstTransceiverState(JSON.stringify(event.target.value))
  }

  const handleChangeArrangedState = event => {
    setSelectedArrangedState(JSON.stringify(event.target.value))
  }

  const handleChangeCreatedAtStrDateFrom = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setCreatedAtStrDateFrom(date)
  }
  const handleChangeCreatedAtStrDateTo = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setCreatedAtStrDateTo(date)
  }

  const handleChangeExchangeAtStrDateFrom = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setExchangedAtAtStrDateFrom(date)
  }
  const handleChangeExchangeAtStrDateTo = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setExchangedAtAtStrDateTo(date)
  }

  const handleChangeAssignedCompanyGroup = (event, value) => {
    setSelectedAssignedCompanyGroup(JSON.stringify(event.target.value))
    setDeactivatedCompanyGroup(null)
    setDeactivatedCompanyUser(null)
    if (event.target.value == 0) {
      setAssignedCompanyUserList([
        {
          label: '担当グループを選択してください',
          value: ''
        }
      ])
    } else {
      setAssignedCompanyUserList(companyUserMap.get(event.target.value))
    }
    setSelectedAssignedCompanyUser(0)
  }

  const handleChangeAssignedCompanyUser = (event, value) => {
    if (event.target.value != '') {
      setSelectedAssignedCompanyUser(JSON.stringify(event.target.value))
      setDeactivatedCompanyUser(null)
    }
  }

  const handleChangeExchangedCompanyUser = (event, value) => {
    setSelectedExchangedCompanyUser(JSON.stringify(event.target.value))
  }

  const handleChangeBulkDeliveryState = event => {
    setSelectedBulkDeliveryState(JSON.stringify(event.target.value))
  }

  const handleChangeMemberFlag1 = () => {
    setIsMember1(!isMember1)
  }

  const handleChangeMemberFlag2 = () => {
    setIsMember2(!isMember2)
  }

  const handleChangeKnrPatternId = event => {
    setSelectedKnrPatternId(event.target.value)
  }

  const handleChangeKnrTantoId = event => {
    setSelectedKnrTantoId(event.target.value)
  }

  const handleChangeTtyTnpId = event => {
    setSelectedTtyTnpId(event.target.value)
  }

  function SendAndReceiveLabel() {
    return (
      <Grid container>
        <Grid>
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/soushin.png`}
            alt={'初回送信'}
          />
        </Grid>
        <Grid>
          <Typography>初回送信/</Typography>
        </Grid>
        <Grid>
          <img
            className={classes.img}
            src={`${process.env.PUBLIC_URL}/jyushin.png`}
            alt={'受信'}
          />
        </Grid>
        <Grid>
          <Typography>受信</Typography>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    if (fetchedCompanyGroupList && fetchedCompanyUserList) {
      const assignedGroup = fetchedCompanyGroupList.find(
        item => item.status === 3 && selectedAssignedCompanyGroup === item.id
      )
      const assignedUser = fetchedCompanyUserList.find(
        item =>
          item.company_user.status !== 1 &&
          selectedAssignedCompanyUser === item.company_user.id
      )
      setDeactivatedCompanyGroup(
        assignedGroup &&
          getFormattedNameByStatus(assignedGroup.name, assignedGroup.status)
      )
      setDeactivatedCompanyUser(
        assignedUser &&
          getFormattedNameByStatus(
            assignedUser.company_user.name,
            assignedUser.company_user.status
          )
      )
    }
  }, [fetchedCompanyGroupList, fetchedCompanyUserList])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={threadStateList}
          getOptionLabel={option => option.label}
          defaultValue={initSelectedThreadState}
          filterSelectedOptions
          filterOptions={(options, state) => {
            return options.filter(
              option => option.label.indexOf(state.inputValue) !== -1
            )
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="filled"
              label="状態"
              autoComplete="off"
            />
          )}
          onChange={handleChangeThreadState}
        />
        <input
          type="hidden"
          name={storageKeyList.threadState}
          value={selectedThreadState}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name={storageKeyList.replyState}
            label={'返信'}
            onChange={handleChangeReplyState}
            defaultValue={selectedReplyState}
            items={replyStateList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        {isOwnerPath ? (
          <TextInput
            name={storageKeyList.residentName}
            label="オーナー名"
            defaultValue={
              storageKeyList.residentName in searchConditions
                ? searchConditions[storageKeyList.residentName]
                : ''
            }
            value={residentName}
            fullWidth
            variant="filled"
            onChange={handleChangeResidentName}
          />
        ) : (
          <TextInput
            name={storageKeyList.residentName}
            label="入居者名"
            defaultValue={
              storageKeyList.residentName in searchConditions
                ? searchConditions[storageKeyList.residentName]
                : ''
            }
            value={residentName}
            fullWidth
            variant="filled"
            onChange={handleChangeResidentName}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <TextInput
          name={storageKeyList.id}
          label="ID"
          defaultValue={
            [storageKeyList.id] in searchConditions
              ? searchConditions[storageKeyList.id]
              : ''
          }
          value={id}
          fullWidth
          variant="filled"
          type="number"
          onChange={handleChangeId}
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          name={storageKeyList.ttyName}
          label="物件名(フリガナ)"
          defaultValue={
            [storageKeyList.ttyName] in searchConditions
              ? searchConditions[storageKeyList.ttyName]
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name={storageKeyList.parentCategory}
            label={isOwnerPath ? 'カテゴリ' : '大カテゴリ'}
            onChange={handleChangeParentCategory}
            defaultValue={selectedParentCategory}
            items={parentCategoryList}
          />
        </FormControl>
      </Grid>
      {!isOwnerPath && (
        <Grid item xs={6}>
          <FormControl
            required
            variant="outlined"
            className={classes.formControl}
          >
            <Dropdown
              name={storageKeyList.childCategory}
              label={'中カテゴリ'}
              onChange={handleChangeChildCategory}
              defaultValue={selectedChildCategory}
              items={selectChildCategoryList}
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={6}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name={storageKeyList.firstTransceiverState}
            label={<SendAndReceiveLabel />}
            onChange={handleChangeFirstTransceiverState}
            defaultValue={selectedFirstTransceiverState}
            items={firstTransceiverList}
          />
        </FormControl>
      </Grid>

      {!isOwnerPath && (
        <Grid item xs={6}>
          <FormControl
            required
            variant="outlined"
            className={classes.formControl}
          >
            <Dropdown
              name={storageKeyList.isArranged}
              label={'案件手配'}
              onChange={handleChangeArrangedState}
              defaultValue={selectedArrangedState}
              items={arrangedStateList}
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id={storageKeyList.createdAtStrDateFrom}
            name={storageKeyList.createdAtStrDateFrom}
            label="初回送信日(From)"
            value={createdAtStrDateFrom}
            onChange={handleChangeCreatedAtStrDateFrom}
            error={
              searchConditionErrors[storageKeyList.createdAtStrDateFromMessage]
            }
            helperText={
              searchConditionErrors[storageKeyList.createdAtStrDateFromMessage]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id={storageKeyList.createdAtStrDateTo}
            name={storageKeyList.createdAtStrDateTo}
            label="初回送信日(To)"
            value={createdAtStrDateTo}
            onChange={handleChangeCreatedAtStrDateTo}
            error={
              searchConditionErrors[storageKeyList.createdAtStrDateToMessage]
            }
            helperText={
              searchConditionErrors[storageKeyList.createdAtStrDateToMessage]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id={storageKeyList.exchangedAtStrDateFrom}
            name={storageKeyList.exchangedAtStrDateFrom}
            label="最終送信日(From)"
            value={exchangedAtStrDateFrom}
            onChange={handleChangeExchangeAtStrDateFrom}
            error={
              searchConditionErrors[
                storageKeyList.exchangedAtStrDateFromMessage
              ]
            }
            helperText={
              searchConditionErrors[
                storageKeyList.exchangedAtStrDateFromMessage
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="message."
            name={storageKeyList.exchangedAtStrDateTo}
            label="最終送信日(To)"
            value={exchangedAtStrDateTo}
            onChange={handleChangeExchangeAtStrDateTo}
            error={
              searchConditionErrors[storageKeyList.exchangedAtStrDateToMessage]
            }
            helperText={
              searchConditionErrors[storageKeyList.exchangedAtStrDateToMessage]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      {judgmentRoleId() !== 5 ? (
        <>
          <Grid item xs={6}>
            <FormControl
              required
              variant="outlined"
              className={classes.formControl}
            >
              <Dropdown
                name={storageKeyList.assignedCompanyGroup}
                label={'担当グループ'}
                onChange={handleChangeAssignedCompanyGroup}
                defaultValue={selectedAssignedCompanyGroup}
                items={companyGroupList}
                deactivatedItem={deactivatedCompanyGroup}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              required
              variant="outlined"
              className={classes.formControl}
            >
              <Dropdown
                name={storageKeyList.assignedCompanyUser}
                label={'担当者'}
                onChange={handleChangeAssignedCompanyUser}
                defaultValue={selectedAssignedCompanyUser}
                items={assignedCompanyUserList}
                deactivatedItem={deactivatedCompanyUser}
              />
            </FormControl>
          </Grid>
        </>
      ) : null}
      <Grid item xs={12}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name={storageKeyList.exchangedCompanyUser}
            label={'返信者'}
            onChange={handleChangeExchangedCompanyUser}
            defaultValue={selectedExchangedCompanyUser}
            items={companyUserList}
          />
        </FormControl>
      </Grid>

      {!isOwnerPath ? (
        <>
          <Grid item xs={12}>
            <TextInput
              name={storageKeyList.bulkDeliveryListName}
              label="配信リスト名"
              defaultValue={
                [storageKeyList.bulkDeliveryListName] in searchConditions
                  ? searchConditions[storageKeyList.bulkDeliveryListName]
                  : bulkDeliveryListName
                  ? bulkDeliveryListName
                  : ''
              }
              fullWidth
              variant="filled"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              required
              variant="outlined"
              className={classes.formControl}
            >
              <Dropdown
                name={storageKeyList.isBulkDelivery}
                label={'一括配信'}
                onChange={handleChangeBulkDeliveryState}
                defaultValue={selectedBulkDeliveryState}
                items={onlySearchBulkDeliveryList}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name={storageKeyList.memberFlag1}
                  checked={isMember1}
                  onChange={handleChangeMemberFlag1}
                />
              }
              label="会員フラグ1"
              value={isMember1}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name={storageKeyList.memberFlag2}
                  checked={isMember2}
                  onChange={handleChangeMemberFlag2}
                />
              }
              label="会員フラグ2"
              value={isMember2}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name={storageKeyList.knrPattern}
              label={'管理パターン'}
              onChange={handleChangeKnrPatternId}
              defaultValue={selectedKnrPatternId}
              items={masterData.ttyKnrPatternList}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name={storageKeyList.knrTanto}
              label={'管理担当者'}
              onChange={handleChangeKnrTantoId}
              defaultValue={selectedKnrTantoId}
              items={masterData.ttyKnrTantoList}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name={storageKeyList.ttyTnp}
              label={'建物取扱店舗'}
              onChange={handleChangeTtyTnpId}
              defaultValue={selectedTtyTnpId}
              items={masterData.ttyTnpList}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SearchFormContent))
