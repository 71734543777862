import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useDisplayUserInformationHeader } from '../../../../../hooks/useDisplayUserInformationHeader'
import Loading from 'components/common/Loading'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  button: {
    marginLeft: '16px',
    color: '#fff'
  }
}))

function Body(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const {
    outputApplicationUserInformation,
    formatResidentInfo,
    isLoading
  } = useDisplayUserInformationHeader(
    'getResidentCancellationApplication',
    'application',
    props.enqueueSnackbar,
    props.title
  )

  useEffect(() => {
    outputApplicationUserInformation()
  }, [props])

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={e =>
          history.push({
            pathname: `/application/residentCancellation/list/${
              location.state !== undefined && 'pageNumber' in location.state
                ? location.state.pageNumber
                : 1
            }`,
            state: location.state
          })
        }
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>
        {!isLoading ? (
          formatResidentInfo
        ) : (
          <Loading isLoading={true} marginTop={20} marginBottom={20} />
        )}
      </Typography>
    </>
  )
}

export default withSnackbar(Body)
