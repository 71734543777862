import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import FormLayout from 'components/common/FormLayout'
import Loading from 'components/common/Loading'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useRole } from 'hooks/useRole'
import { AttachmentType } from 'utils/enum'
import TitleImageDisplay from './TitleImageDisplay'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%',
    marginTop: 10
  },
  card: {
    width: 120,
    height: 80
  },
  cover: {
    width: 120,
    height: 80
  }
}))

const TitleImage = props => {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { isLoading, titleImage, setTitleImage, errors } = props

  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = AttachmentType.image
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setTitleImage(file)
      props.handleChange({ titleImage: file })
    }
    reader.readAsDataURL(file)
  }

  const handleDelete = () => {
    if (titleImage && titleImage.id) {
      props.handleChange({ deletedTitleImage: { ...titleImage } })
    }
    setTitleImage(null)
    props.handleChange({ titleImage: null })
  }

  return (
    <FormLayout title="タイトル画像">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {titleImage && (
            <TitleImageDisplay
              attachment={titleImage}
              handleDelete={handleDelete}
              style={{ marginBottom: 10 }}
              disabledIcon={!isUnrestrictedRole}
            />
          )}
          {titleImage == null && (
            <Grid container>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-title-image"
                  type="file"
                  onChange={handleChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <label
                htmlFor="contained-button-title-image"
                className={classes.labelButton}
              >
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                  disabled={!isUnrestrictedRole}
                >
                  画像追加*
                </Button>
                <FormHelperText>
                  ※画像推奨サイズは横1080px × 縦830px以上です
                </FormHelperText>
                {errors && errors.titleImage && (
                  <FormHelperText error>{errors.titleImage}</FormHelperText>
                )}
              </label>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

export default TitleImage
