import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { formatYYYYMMDD, formatTime } from 'utils/date.utils'
import { handleOpenPage } from 'actions/Actions'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  titleWrapper: {
    whiteSpace: 'nowrap',
    minWidth: 210,
    width: 210,
    maxWidth: 210,
    height: 80,
    maxHeight: 80
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_purple.dark,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  }
}))

const columns = [
  { id: 'id', label: 'No', minWidth: 50, hasSort: true },
  { id: 'title', label: 'テンプレート名', minWidth: 200, hasSort: false },
  { id: 'category', label: 'カテゴリ', minWidth: 150, hasSort: false },
  { id: 'answer_button', label: '回答ボタン', minWidth: 100, hasSort: false },
  { id: 'attachments', label: '添付ファイル', minWidth: 100, hasSort: false },
  { id: 'updated_at', label: '更新日時', minWidth: 100, hasSort: true },
  { id: 'updater', label: '更新者', minWidth: 100, hasSort: false },
  { id: '', label: '', minWidth: 80, hasSort: false }
]

function EditButton(props) {
  const classes = useStyles()
  const history = useHistory()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={e =>
        handleOpenPage(
          `/setting/bulk-delivery/template/edit/${props.id}`,
          e,
          history
        )
      }
    >
      {isUnrestrictedRole ? '編集' : '詳細'}
    </Button>
  )
}

function List(props) {
  const classes = useStyles()
  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => event => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                width: column.width
              }}
              sortDirection={
                column.hasSort && orderBy === column.id ? order : false
              }
            >
              {column.hasSort ? (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  className={classes.tableSort}
                  onClick={handleSort(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{column.label}</>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.bulkDeliveryTemplates.length
          ? props.bulkDeliveryTemplates.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    <Grid>{row.large_category.name}</Grid>
                    <Grid>{row.medium_category.name}</Grid>
                  </TableCell>
                  <TableCell>
                    {row.answer_button_type !== 'NOTHING' ? 'あり' : ''}
                  </TableCell>
                  <TableCell>
                    {row.attachments !== null ? 'あり' : ''}
                  </TableCell>
                  <TableCell>
                    <Grid>{formatYYYYMMDD(new Date(row.updated_at))}</Grid>
                    <Grid>{formatTime(new Date(row.updated_at))}</Grid>
                  </TableCell>
                  <TableCell>
                    {row.updater_user &&
                      getFormattedNameByStatus(
                        row.updater_user.name,
                        row.updater_user.status
                      )}
                  </TableCell>
                  <TableCell>
                    <EditButton id={row.id} />
                  </TableCell>
                </TableRow>
              )
            })
          : null}
      </TableBody>
    </Table>
  )
}

export default List
