import React, { useEffect, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import RowWithFile from 'components/common/RowWithFile'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

function Image(props) {
  const attachment = props.attachment ? props.attachment : null
  const [image, setImage] = useState(attachment)
  const [isDeleteButtonViewState, setIsDeleteButtonViewState] = useState(true)

  useEffect(() => {
    // buttonStateが渡って来た時のみ変更する
    props.buttonState
      ? setIsDeleteButtonViewState(true)
      : setIsDeleteButtonViewState(false)
  }, [props.buttonState])

  useEffect(() => {
    if (attachment) {
      setImage(attachment)
      Auth.currentCredentials().then(async creds => {
        // keyがない時は画像アップロード前
        if (!attachment.key || attachment.key === '') {
          return
        }
        const key = attachment.key
        console.log(key)
        Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            console.log(result)
            const object = Object.assign({}, attachment, { body: result })
            setImage(object)
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }, [attachment, setImage])
  return (
    <>
      {image && image.body && (
        <>
          <RowWithFile
            image={image.body}
            title={image.filename}
            handleDelete={props.handleDelete}
            isDeleteButtonView={isDeleteButtonViewState}
            thumbnailWidth={props.thumbnailWidth}
            thumbnailHeight={props.thumbnailHeight}
          />
        </>
      )}
    </>
  )
}

export default withSnackbar(withRouter(Image))
