import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import {
  changeSearchCondition,
  openSearchModalDialog,
  saveBulletinBoardSearchCondition
} from 'actions/Actions'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '12px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function SearchTextField({ text, ...props }) {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (
      location.state !== undefined &&
      'bulletinBoard.title' in location.state
    ) {
      setInputValue(location.state['bulletinBoard.title'])
    }
  }, [setInputValue])

  const handleClick = async () => {
    props.openSearchModalDialog()
  }

  const handleSearch = event => {
    event.preventDefault()
    props.saveBulletinBoardSearchCondition(
      inputValue != '' ? { 'bulletinBoard.title': inputValue } : {}
    )
    history.push({
      pathname: `/bulletin-board/list/`,
      state: inputValue != '' ? { 'bulletinBoard.title': inputValue } : {}
    })
  }

  return (
    <Paper component="form" className={classes.root}>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={handleSearch}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={text}
        inputProps={{ 'aria-label': { text } }}
        onChange={event => {
          setInputValue(event.target.value)
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleSearch(event)
          }
        }}
        value={inputValue}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="directions"
        onClick={handleClick}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Paper>
  )
}

const mapStateToProps = state => {
  return {
    conditions: state.searchCondition
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openSearchModalDialog: () => {
      dispatch(openSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    },
    saveBulletinBoardSearchCondition: parameter => {
      dispatch(saveBulletinBoardSearchCondition(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchTextField)
