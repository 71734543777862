import React, { useEffect, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import RowWithPdf from 'components/common/RowWithPdf'

function Pdf(props) {
  const attachment = props.attachment ? props.attachment : null
  const [pdf, setPdf] = useState(null)

  useEffect(() => {
    if (attachment) {
      Auth.currentCredentials().then(async creds => {
        await Promise.all([fetchPdf(attachment)])
      })
    }
  }, [attachment, setPdf])

  async function fetchPdf(attachment) {
    const key = attachment.key
    console.log(key)
    const result = await getStorage(key)
    const object = Object.assign({}, attachment, { body: result })
    setPdf(object)
  }

  async function getStorage(key) {
    return Storage.get(key, {
      level: 'protected',
      identityId: attachment.company_id,
      expires: 60
    })
  }

  function handleClick() {
    window.open(pdf.body)
  }

  return (
    <>
      {pdf && (
        <RowWithPdf
          attachment={attachment}
          handleClick={handleClick}
          title={attachment.filename}
          handleDelete={props.handleDelete}
          style={{ marginBottom: 10 }}
          disabledIcon={props.disabledIcon}
        />
      )}
    </>
  )
}

export default withSnackbar(withRouter(Pdf))
