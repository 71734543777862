import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { formatYMD } from 'utils/date.utils'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  contentName: {
    height: '48px',
    width: '420px'
  },
  contentTitle: {
    height: '12px',
    width: '324.34px',
    color: '#72809D',
    backgroundColor: '#FAFBFD',
    fontFamily: 'Hiragino Sans',
    fontSize: '12px',
    lineHeight: '18px'
  }
}))
const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function CellWithBirthDate({ birthdate }) {
  if (birthdate) {
    const date = new Date(birthdate)
    return <>{formatYMD(date)}</>
  }
  return <></>
}

function ResidentInfoChangeApplicationDetail({ isLoading, application }) {
  const classes = useStyles()
  const content =
    application && application.content ? parseJson(application.content) : {}

  return (
    <>
      <TableContainer component="Paper">
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.root} colSpan="2">
                申請内容
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="2"
                >
                  <Loading isLoading={isLoading} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    入居者名
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    {content &&
                      content.changes &&
                      content.changes.resident &&
                      content.changes.resident.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    フリガナ
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    {content &&
                      content.changes &&
                      content.changes.resident &&
                      content.changes.resident.nameKana}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    生年月日
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    {content && content.changes && content.changes.resident && (
                      <CellWithBirthDate
                        birthdate={content.changes.resident.birthDay}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    携帯電話番号
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    {content &&
                      content.changes &&
                      content.changes.resident &&
                      content.changes.resident.tel}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    職業
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    {content &&
                      content.changes &&
                      content.changes.resident &&
                      content.changes.resident.jobName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.contentTitle}
                    colSpan="2"
                  >
                    勤務先
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    社名
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    {content &&
                      content.changes &&
                      content.changes.company &&
                      content.changes.company.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    電話番号
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    {content &&
                      content.changes &&
                      content.changes.company &&
                      content.changes.company.tel}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ResidentInfoChangeApplicationDetail
