import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Auth, Storage } from 'aws-amplify'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(n+2)': {
      marginTop: 10
    },
    width: '100%',
    minWidth: '250px',
    maxWidth: '100%'
  },
  title: {
    display: 'flex',
    marginLeft: '-20px'
  },
  content: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    display: 'inline-block',
    position: 'relative',
    margin: '-5px 10px 0 0',
    padding: '13px',
    borderRadius: '12px',
    fontSize: '15px'
  },
  leftPdfMessage: {
    background: '#FFFFFF'
  },
  rightPdfMessage: {
    background: '#A7E521'
  }
}))

function ChatPdf(props) {
  const classes = useStyles()
  const { messageFrom } = props
  const attachment = props.attachment ? props.attachment : null

  const handleClickPdfDownload = attachment => async event => {
    event.preventDefault()
    if (attachment && attachment.key) {
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        await Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            fetch(result).then(response => {
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = attachment.filename
                a.click()
              })
            })
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }

  return (
    <>
      {attachment && attachment.filename && (
        <>
          <Grid
            container
            xs={12}
            className={classes.imageContainer}
            onClick={handleClickPdfDownload(attachment)}
          >
            <Grid item xs={2} className={classes.icon}>
              <p>
                <PictureAsPdfIcon />
              </p>
            </Grid>
            <Grid item className={classes.title} xs={10}>
              <p
                className={`${classes.content} ${
                  messageFrom === 'left'
                    ? classes.leftMessage
                    : classes.rightMessage
                }`}
              >
                {attachment && attachment.filename}
              </p>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default withSnackbar(withRouter(ChatPdf))
