import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PackageForm from './PackageForm'
import TopDisplayForm from './TopDisplayForm'
import TitleImage from './TitleImage'
import ImageForm from './ImageForm'
import FileForm from './FileForm'
import VideoForm from './VideoForm'
import UrlForm from './UrlForm'
import Footer from './Footer'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { setLoadData } from 'actions/Actions'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'
import { useHistory } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { FormProvider, useForm } from 'react-hook-form'
import { ErrorMessages } from 'utils/errorMessages'
import { AttachmentStatus, AttachmentType } from 'utils/enum'
import { useParams } from 'react-router-dom'
import { Regexes } from 'utils/regexes'

const useStyles = makeStyles(theme => ({
  top: {},
  body: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  bottom: {
    backgroundColor: '#fff'
  },
  contentRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  contentTop: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentBody: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentBottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  contentDivider: {
    width: '100%',
    height: '24px'
  },
  topContainer: {
    padding: '15px 20px'
  }
}))

function Left(props) {
  const classes = useStyles()
  const history = useHistory()
  const methods = useForm()
  const { packageId } = useParams()
  const {
    isLoading,
    packageData,
    setLoadData,
    status,
    setStatus,
    setIsDisplayTop,
    fetchPackage
  } = props

  const [parameters, setParameters] = useState({})
  const [enablePackageForm, setEnablePackageForm] = useState(true)
  const [titleImage, setTitleImage] = useState()
  const [image, setImage] = useState(null)
  const [video, setVideo] = useState(null)
  const [pdf, setPdf] = useState(null)
  const [errors, setErrors] = useState(null)
  const [packageSize, setPackageSize] = useState(null)
  const [topPageDisplaySize, setTopPageDisplaySize] = useState(null)
  const [availableSlotSize, setAvailableSlotSize] = useState(null)
  const [packageDataList, setPackageDataList] = useState(null)
  const [checkError, setCheckError] = useState({
    body: ''
  })

  useEffect(() => {
    if (packageData && packageData.attachments) {
      const images = []
      const videos = []
      const pdfs = []

      for (let attachment of packageData.attachments) {
        if (attachment.status !== AttachmentStatus.disabled) {
          if (attachment.attachment_type_id === AttachmentType.image) {
            images.push(attachment)
          } else if (attachment.attachment_type_id === AttachmentType.video) {
            videos.push(attachment)
          } else if (attachment.attachment_type_id === AttachmentType.pdf) {
            pdfs.push(attachment)
          }
        }
      }

      setImage(images)
      setVideo(videos)
      setPdf(pdfs)
    }
    if (packageData && packageData.title_attachment) {
      setTitleImage(packageData.title_attachment)
    }
    fetchData()
  }, [packageData])

  const fetchData = async () => {
    await Promise.all([packageSize === null ? fetchPackageList() : ''])
  }

  const fetchPackageList = async () => {
    let result = await QueryHelper('getPackageList', {
      filter: {},
      offset: 0,
      limit: 0
    })
    if (!result.error) {
      setPackageSize(result.paging.count)
      setTopPageDisplaySize(result.package_size.top_page_display_size)
      setAvailableSlotSize(result.package_size.available_slot_size)
      setPackageDataList(result.data)
    } else {
      props.enqueueSnackbar(`入居者サービスを取得できませんでした。`)
    }
  }

  const handleChange = async parameter => {
    setParameters(Object.assign(parameters, parameter))
  }

  const handleDelete = async event => {
    event.preventDefault()
    if (!window.confirm(`削除します。よろしいですか？`)) {
      return
    }

    props.setLoadData(true)
    const params = {}
    params.id = packageData.package.id
    const result = await MutationHelper('deletePackage', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('削除できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('削除しました', {
        variant: 'success'
      })
      history.push(`/management/package/list`)
    }
    props.setLoadData(false)
  }

  const handleCancel = async event => {
    event.preventDefault()
    setParameters({})
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (Object.keys(parameters).length > 0) {
      setLoadData(true)

      if (topPageDisplaySize === 0 && parameters.is_display_top) {
        props.enqueueSnackbar('本環境はオプションサービス未加入です', {
          variant: 'error'
        })
        setLoadData(false)
        return
      }

      let currentTopDisplayCount = packageDataList.filter(
        item => item.package.status === 1 && item.package.is_display_top
      ).length
      if (parameters.is_display_top) {
        currentTopDisplayCount++
      }
      if (
        ('package' in packageData &&
          packageData.package &&
          parameters.is_display_top &&
          status === 1 &&
          currentTopDisplayCount > topPageDisplaySize) ||
        (!('package' in packageData && packageData.package) &&
          parameters.is_display_top &&
          status === 1 &&
          currentTopDisplayCount > topPageDisplaySize)
      ) {
        props.enqueueSnackbar('TOPページ表示が既に上限数登録されています。', {
          variant: 'error'
        })
        setLoadData(false)
        return
      }

      let currentUsingNumber = packageDataList.filter(
        item => item.package.status === 1
      ).length
      if (!('package' in packageData && packageData.package) && status === 1) {
        currentUsingNumber++
      }
      if (
        ('package' in packageData &&
          packageData.package &&
          parameters.is_display_top &&
          status === 1 &&
          currentUsingNumber > availableSlotSize) ||
        (!('package' in packageData && packageData.package) &&
          status === 1 &&
          currentUsingNumber > availableSlotSize)
      ) {
        props.enqueueSnackbar('アプリ表示可能数が上限に達しています。', {
          variant: 'error'
        })
        setLoadData(false)
        return
      }

      if (validateForm(parameters)) {
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        setLoadData(false)
        return
      }

      if (!enablePackageForm) {
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        setLoadData(false)
        return
      }

      const params = Object.assign({}, parameters)
      if (params.titleImage) {
        const creds = await Auth.currentCredentials()
        let titleAttachment
        if (!params.titleImage.id) {
          const result = await fileupload(creds, 'images', params.titleImage)
          titleAttachment = {
            attachment_type_id: AttachmentType.image,
            mime_type: params.titleImage.type,
            filename: params.titleImage.name,
            key: result.key
          }
        }
        if (titleAttachment) {
          params.title_attachment = titleAttachment
        }
      } else if (params.deletedTitleImage && params.deletedTitleImage.id) {
        params.title_attachment = {
          id: params.deletedTitleImage.id,
          key: params.deletedTitleImage.key,
          status: AttachmentStatus.disabled
        }
      }
      delete params['titleImage']
      delete params['deletedTitleImage']

      const attachments = []
      if (params.files) {
        const creds = await Auth.currentCredentials()
        for (let file of params.files) {
          if ('id' in file) {
            attachments.push(file)
            continue
          }
          const result = await fileupload(creds, 'files', file)
          attachments.push({
            attachment_type_id: AttachmentType.pdf,
            mime_type: file.type,
            filename: file.name,
            key: result.key
          })
        }
        delete params['files']
      }
      if (params.images) {
        const creds = await Auth.currentCredentials()
        for (let image of params.images) {
          if ('id' in image) {
            attachments.push(image)
            continue
          }
          const result = await fileupload(creds, 'images', image)
          attachments.push({
            attachment_type_id: AttachmentType.image,
            mime_type: image.type,
            filename: image.name,
            key: result.key
          })
        }
        delete params['images']
      }
      if (params.videos) {
        const creds = await Auth.currentCredentials()
        for (let video of params.videos) {
          if ('id' in video) {
            attachments.push(video)
            continue
          }
          const result = await fileupload(creds, 'videos', video)
          attachments.push({
            attachment_type_id: AttachmentType.video,
            mime_type: video.type,
            filename: video.name,
            key: result.key
          })
        }
        delete params['videos']
      }
      if (attachments.length > 0) {
        params['attachments'] = attachments
      }
      if ('package' in packageData && packageData.package) {
        params['id'] = packageData.package.id
        await putPackageData(params)
      } else {
        params['status'] = status
        await postPackageData(params)
      }
      setLoadData(false)
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
    }
  }

  function validateForm(parameters) {
    let isError = false
    let errObj = {}

    const bodyValidation = () => {
      const bodyParts =
        parameters.body &&
        parameters.body
          .split(Regexes.NewlineAndSpaces)
          .filter(part => part.trim() !== '')
      if (!parameters.body || bodyParts.length === 0) {
        setCheckError(prevCheckError => ({
          ...prevCheckError,
          body: ErrorMessages.Required
        }))
        isError = true
      }
    }

    if (!packageData.package || !packageData.package.id) {
      // 新規登録時
      if (!parameters.images || parameters.images.length === 0) {
        errObj = Object.assign(errObj, { images: '画像は必須です' })
        isError = true
      }
      bodyValidation()
      // defaultValueで指定したテキストサイズにはfont-sizeが記載されないため、一番外を囲んで指定がないものを全て15pxにしてデータを登録する
      parameters.body = `<span style="font-size: 15px">${parameters.body}</span>`
    } else {
      // 編集時
      const existingImageCount = packageData.attachments
        ? packageData.attachments.filter(
            attachment =>
              attachment.attachment_type_id === AttachmentType.image &&
              attachment.status === AttachmentStatus.enabled
          ).length
        : 0

      const disabledCount = parameters.images
        ? parameters.images.filter(image => image.status === 3).length
        : 0

      const existsImage =
        parameters.images &&
        disabledCount === existingImageCount &&
        parameters.images.length <= existingImageCount
      if (existsImage) {
        errObj.images = '画像は必須です'
        isError = true
      }
      if (parameters.body) {
        bodyValidation()
        // defaultValueで指定したテキストサイズにはfont-sizeが記載されないため、一番外を囲んで指定がないものを全て15pxにしてデータを登録する
        parameters.body = `<span style="font-size: 15px">${parameters.body}</span>`
      }
    }
    setErrors(errObj)
    return isError
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    return result
  }

  const postPackageData = async params => {
    const result = await MutationHelper('createPackage', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('入居者サービスを登録できませんでした。', {
        variant: 'error'
      })
    } else {
      setParameters({})
      props.enqueueSnackbar('入居者サービスを登録しました。', {
        variant: 'success'
      })
      history.push(`/management/package/edit/${result.id}`)
    }
  }

  const putPackageData = async params => {
    const result = await MutationHelper('updatePackage', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('入居者サービスを更新できませんでした。', {
        variant: 'error'
      })
    } else {
      setParameters({})
      props.enqueueSnackbar('入居者サービスを更新しました。', {
        variant: 'success'
      })
      setIsDisplayTop(result.is_display_top)
      setStatus(result.status)
    }
    fetchPackage(packageId)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} className={classes.contentRoot}>
          <div className={classes.top}></div>
          <div className={classes.body}>
            <Card>
              <div className={classes.contentBody}>
                <TitleImage
                  isLoading={isLoading}
                  titleImage={titleImage}
                  setTitleImage={setTitleImage}
                  handleChange={handleChange}
                  errors={errors}
                />
              </div>
            </Card>
            <div className={classes.contentDivider} />
            <Card>
              <div className={classes.contentTop}>
                <PackageForm
                  isLoading={isLoading}
                  packageData={packageData}
                  handleChange={handleChange}
                  setEnableForm={setEnablePackageForm}
                  checkError={checkError}
                  setCheckError={setCheckError}
                />
              </div>
            </Card>
            <div className={classes.contentDivider} />
            <Card>
              <div className={classes.topContainer}>
                <TopDisplayForm
                  isLoading={isLoading}
                  packageData={packageData}
                  handleChange={handleChange}
                />
              </div>
            </Card>
            <div className={classes.contentDivider} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card>
                  <div className={classes.contentBody}>
                    <ImageForm
                      isLoading={isLoading}
                      image={image}
                      handleChange={handleChange}
                      errors={errors}
                    />
                  </div>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <div className={classes.contentBody}>
                    <FileForm
                      isLoading={isLoading}
                      pdf={pdf}
                      handleChange={handleChange}
                    />
                  </div>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <div className={classes.contentBody}>
                    <VideoForm
                      isLoading={isLoading}
                      video={video}
                      handleChange={handleChange}
                    />
                  </div>
                </Card>
              </Grid>
            </Grid>
            <div className={classes.contentDivider} />
            <Card>
              <div className={classes.contentBody}>
                <UrlForm
                  isLoading={isLoading}
                  packageData={packageData}
                  handleChange={handleChange}
                  setEnableForm={setEnablePackageForm}
                />
              </div>
            </Card>
            <div className={classes.contentBottom}></div>
          </div>
          <div className={classes.bottom}>
            <Footer
              packageData={packageData}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
            />
          </div>
        </form>
      </FormProvider>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Left))
