import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import RateReviewIcon from '@material-ui/icons/RateReview'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import HelpIcon from '@material-ui/icons/Help'
import FaceIcon from '@material-ui/icons/Face'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded'
import IconWithText from 'components/common/IconWithText'
import { ThreadState } from 'utils/enum'
import DateLabel from 'components/common/DateLabel'
import Grid from '@material-ui/core/Grid'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { useCustomerOrOwner } from '../../hooks/useCustomerOrOwner'

const useStyles = makeStyles(() => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  arrangementImg: {
    position: 'relative',
    top: 3,
    width: 30,
    height: 'auto',
    alignItems: 'center'
  }
}))

export const ListColumns = isOwnerPath => {
  const columns = [
    { id: 'id', label: 'ID', minWidth: 80, width: 80, hasSort: true },
    { id: 'status', label: '状態', minWidth: 40, width: 40 },
    { id: 'reply', label: '返信', minWidth: 40, width: 40 },
    {
      id: 'created_at',
      label: '初回日付',
      minWidth: 90,
      width: 90,
      hasSort: true
    },
    {
      id: 'updated_at',
      label: '最終返信',
      minWidth: 90,
      width: 90,
      hasSort: true
    },
    !isOwnerPath
      ? { id: 'applicant_name', label: '入居者名', minWidth: 140, width: 140 }
      : {
          id: 'applicant_name',
          label: 'オーナー名',
          minWidth: 140,
          width: 140
        },
    { id: 'thread_name', label: '物件名', minWidth: 210, width: 210 },
    { id: 'category', label: 'カテゴリ', minWidth: 210, width: 210 },
    !isOwnerPath
      ? { id: 'arrangement', label: '手配', minWidth: 40, width: 40 }
      : null,
    {
      id: 'assigned_user',
      label: '担当者',
      minWidth: 80,
      width: 80,
      hasSort: true
    },
    {
      id: 'exchanged_user',
      label: '返信者',
      minWidth: 80,
      width: 80,
      hasSort: true
    }
  ].filter(column => column !== null)

  return columns
}

function CellWithStatus({ status }) {
  switch (status) {
    case ThreadState.open.code:
      return (
        <IconWithText
          text={ThreadState.open.value}
          icon={<NewReleasesIcon />}
          color={'error'}
        />
      )
    case ThreadState.inProgress.code:
      return (
        <IconWithText
          text={ThreadState.inProgress.value}
          icon={<RateReviewIcon />}
          color={'c_yellow'}
        />
      )
    case ThreadState.pending.code:
    case ThreadState.resolve.code:
      return (
        <IconWithText
          text={ThreadState.pending.value}
          icon={<BeenhereIcon />}
          color={'c_blue2'}
        />
      )
    case ThreadState.complete.code:
      return (
        <IconWithText
          text={ThreadState.complete.value}
          icon={<EmojiEmotionsIcon />}
          color={'c_thirdly'}
        />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithReply({ isReply }) {
  if (isReply) {
    return (
      <IconWithText
        text={'返信済'}
        icon={<BusinessRoundedIcon />}
        color={'c_thirdly'}
      />
    )
  } else {
    return <IconWithText text={'未返信'} icon={<FaceIcon />} color={'error'} />
  }
}

function List(props) {
  const history = useHistory()
  const classes = useStyles()
  const isTextSelected = useIsTextSelected()

  const { searchCondition } = props
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()
  const columns = ListColumns(isOwnerPath)
  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  const navigateToDetailPage = (e, threadId) => {
    if (isTextSelected()) return

    handleOpenPage(
      isOwnerPath
        ? `/ownerMessage/detail/${threadId}`
        : `/message/detail/${threadId}`,
      e,
      history,
      searchCondition
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
                sortDirection={
                  column.hasSort && orderBy === column.id ? order : false
                }
              >
                {column.hasSort ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    className={classes.tableSort}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <>{column.label}</>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.threadList.length
            ? props.threadList.map(row => {
                return (
                  <TableRow
                    key={row.thread.id}
                    onClick={e => navigateToDetailPage(e, row.thread.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell>{row.thread.id}</TableCell>
                    <TableCell>
                      <CellWithStatus status={row.thread.thread_state_id} />
                    </TableCell>
                    <TableCell>
                      <CellWithReply isReply={row.thread.is_reply} />
                    </TableCell>
                    <TableCell>
                      {row.threadParams && (
                        <DateLabel
                          timestamp={row.thread.created_at}
                          firstSent={row.threadParams['first_sent']}
                          type={'managementMessageDisplay'}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.thread.updated_at && row.threadParams && (
                        <DateLabel
                          timestamp={row.thread.updated_at}
                          firstSent={row.threadParams['first_sent']}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          {isOwnerPath
                            ? row.owner.name
                            : row.mstkokyaku.KKY_NAME}
                        </Grid>
                        <Grid item xs={12} className={classes.nameKana}>
                          {isOwnerPath
                            ? row.owner.name_kana
                            : row.mstkokyaku.KKY_NAME_KANA}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          {row.msttateya.TTY_NAME}
                        </Grid>
                        {!isOwnerPath && (
                          <Grid item xs={12}>
                            {row.mstheya.HYA_ROOM_NO}
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          {row.largeCategory && row.largeCategory.name}
                        </Grid>
                        {!isOwnerPath && (
                          <Grid item xs={12}>
                            {row.mediumCategory.name}
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                    {!isOwnerPath && (
                      <TableCell>
                        {row.isArranged &&
                          row.arrangementStatus !== 'CANCEL_OR_RELEASE' && (
                            <img
                              className={classes.arrangementImg}
                              src={`${process.env.PUBLIC_URL}/wrench-check.svg`}
                              alt={'wrenchCheck'}
                            />
                          )}
                      </TableCell>
                    )}
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          {'assignedGroup' in row &&
                            row.assignedGroup &&
                            getFormattedNameByStatus(
                              row.assignedGroup.name,
                              row.assignedGroup.status
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          {'assignedUser' in row &&
                            row.assignedUser &&
                            getFormattedNameByStatus(
                              row.assignedUser.name,
                              row.assignedUser.status
                            )}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {'exchangedUser' in row && row.exchangedUser
                        ? row.exchangedUser.name
                        : ''}
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
