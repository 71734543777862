import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { openSearchSaveModalDialog } from 'actions/Actions'

const useStyles = makeStyles(theme => ({
  svg: {
    width: 25,
    height: 20,
    verticalAlign: 'middle',
    fill: '#FFFFFF'
  },
  tableTipsButton: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'right'
  },
  transitionButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_purple.dark,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  }
}))

function SearchSaveAndCallButton(props) {
  const classes = useStyles()
  const xs = props.xs ? props.xs : 2

  const handleClick = async () => {
    props.openSearchSaveModalDialog()
  }

  return (
    <Grid item xs={xs} className={classes.tableTipsButton}>
      <Button
        className={classes.transitionButton}
        variant="contained"
        onClick={handleClick}
      >
        <svg className={classes.svg} viewBox="0 0 48 48" height={48} width={48}>
          <path d="M22 40q-.85 0-1.425-.575Q20 38.85 20 38V26L8.05 10.75q-.7-.85-.2-1.8Q8.35 8 9.4 8h29.2q1.05 0 1.55.95t-.2 1.8L28 26v12q0 .85-.575 1.425Q26.85 40 26 40Zm2-13.8L36 11H12Zm0 0Z" />
        </svg>
        検索保存・呼出
      </Button>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    openSearchSaveModalDialog: () => {
      dispatch(openSearchSaveModalDialog())
    }
  }
}

export default connect(null, mapDispatchToProps)(SearchSaveAndCallButton)
