import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import { Typography } from '@material-ui/core'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'
import { ErrorMessages } from 'utils/errorMessages'

const useStyles = makeStyles(() => ({
  typography: {
    marginLeft: '12px',
    height: '18px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#72809d',
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

const UrlForm = props => {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { isLoading, packageData, setEnableForm } = props

  const isUrl = str => {
    const reg = new RegExp(
      /^(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/g
    )
    return reg.test(str)
  }

  const [values, setValues] = useState({
    url: '',
    url_err: '',
    link_text: '',
    link_text_err: ''
  })

  const checkErr = objects => {
    setEnableForm(!(objects.url_err || objects.link_text_err))
  }

  const handleInputChange = (event, name) => {
    const value = event.target.value
    const newValues = { ...values, [name]: value }

    if (!value) {
      newValues[`${name}_err`] = ''
    } else if (name === 'url' && !isUrl(value)) {
      newValues[`${name}_err`] = ErrorMessages.UrlError
    } else {
      newValues[`${name}_err`] = ''
    }

    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    if ('package' in packageData && packageData.package) {
      const { url, link_text } = packageData.package
      const initializeValues = {
        url: url || '',
        url_err: url ? '' : '',
        link_text: link_text || '',
        link_text_err: link_text ? '' : ''
      }
      setValues(initializeValues)
      checkErr(initializeValues)
    }
  }, [packageData, setValues])

  return (
    <FormLayout title="サービスサイトURL">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              error={!!values.url_err}
              helperText={values.url_err}
              id="url"
              name="url"
              label="サービスサイトURL"
              fullWidth
              variant="filled"
              defaultValue={values.url}
              onChange={e => handleInputChange(e, 'url')}
              disabled={!isUnrestrictedRole}
            />
            <Typography className={classes.typography}>
              ※アプリ画面の「詳しく見る」にサイトURLを設定することができます。
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              error={!!values.link_text_err}
              helperText={values.link_text_err}
              id="link_text"
              name="link_text"
              label="URLに表示するテキスト"
              fullWidth
              variant="filled"
              defaultValue={values.link_text}
              onChange={e => handleInputChange(e, 'link_text')}
              inputProps={{ maxLength: 100 }}
              disabled={!isUnrestrictedRole}
            />
            <Typography className={classes.typography}>100文字以内</Typography>
          </Grid>
        </Grid>
      )}
    </FormLayout>
  )
}

export default UrlForm
