import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton } from '@material-ui/core'
import SearchTextField from './SearchTextField'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  backButton: {
    marginLeft: '16px',
    color: '#fff'
  },
  primaryButton: {
    marginLeft: '16px',
    marginRight: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  }
}))

function Body(props) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.backButton}
        onClick={() =>
          history.push({
            pathname: `/message/bulk-delivery/list`
          })
        }
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>{props.title}</Typography>
      <SearchTextField text="入居者検索" />
    </>
  )
}

export default withSnackbar(Body)
