import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const labelStyles = makeStyles(theme => {
  const colorData = {
    purple: theme.palette.c_purple,
    blue: theme.palette.c_blue,
    gray: theme.palette.c_gray,
    liteBlue: theme.palette.c_lite_blue,
    lightGray: theme.palette.c_light_gray,
    black: theme.palette.c_black,
    red: theme.palette.c_red,
    green: theme.palette.c_green
  }
  return {
    label: {
      display: 'inline-block',
      padding: '0.3em 0.5em',
      lineHeight: '19px',
      fontWeight: 'bold',
      fontSize: '13px',
      textDecoration: 'none',
      minWidth: '55px',
      textAlign: 'center',
      color: props =>
        colorData[props.color]
          ? colorData[props.color].contrastText
          : theme.palette.c_light_gray.contrastText,
      backgroundColor: props =>
        colorData[props.color]
          ? colorData[props.color].main
          : theme.palette.c_light_gray.main,
      borderRadius: '4px'
    }
  }
})

export default function Label({ color, text, children, ...props }) {
  const classes = labelStyles({ color })
  return (
    <span {...props} className={classes.label}>
      {text ? text : children}
    </span>
  )
}
