import React from 'react'
import { connect } from 'react-redux'
import { closeModalDialog } from 'actions/Actions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formContent: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  contentDescription: {
    flex: 1,
    display: 'flex',
    marginBottom: '20px',
    paddingLeft: '18px',
    paddingRight: '18px',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#FEF7E0',
    alignItems: 'center'
  },
  descriptionIcon: {
    color: '#FFD422'
  },
  descriptionText: {
    marginLeft: '14px'
  },
  formFooter: {
    margin: '8px 16px'
  }
}))

function FormModalDialog(props) {
  const classes = useStyles()

  const {
    title,
    description,
    content,
    footer,
    postData,
    confirmDelete,
    open,
    close
  } = props

  return (
    <>
      <Dialog
        open={confirmDelete ? open : props.isOpen}
        onClose={confirmDelete ? close : props.closeModalDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <form onSubmit={postData}>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <Divider />
          <DialogContent className={classes.formContent}>
            {description && (
              <DialogContentText className={classes.contentDescription}>
                <ReportProblemIcon className={classes.descriptionIcon} />
                <Typography className={classes.descriptionText}>
                  {description}
                </Typography>
              </DialogContentText>
            )}
            {content}
          </DialogContent>
          <Divider />
          <DialogActions className={classes.formFooter}>{footer}</DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormModalDialog)
