import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Left from './Left'
import Right from './Right'
import { QueryHelper } from 'utils/api.utils'
import { useParams } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { PackageEnabled } from 'utils/enum'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function EditContent(props) {
  const classes = useStyles()
  const { packageId } = useParams()

  const [packageData, setPackageData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(
    packageData && 'package' in packageData && packageData.package
      ? packageData.package.status
      : PackageEnabled.disabled.code
  )
  const [isDisplayTop, setIsDisplayTop] = useState()

  const fetchPackage = async id => {
    const params = {
      id: { eq: id }
    }
    const result = await QueryHelper('getPackage', { id: id })
    if (!result.error) {
      setPackageData(result)
      setStatus(result.package.status)
      setIsDisplayTop(result.package.is_display_top)
    } else {
      props.enqueueSnackbar(`入居者サービスを取得できませんでした。`)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([packageId ? fetchPackage(packageId) : ''])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [setPackageData, setStatus, setIsDisplayTop])

  return (
    <>
      <div className={classes.left}>
        <Left
          isLoading={isLoading}
          packageData={packageData}
          status={status}
          setStatus={setStatus}
          setIsDisplayTop={setIsDisplayTop}
          fetchPackage={fetchPackage}
        />
      </div>
      <div className={classes.right}>
        <Right
          isLoading={isLoading}
          packageData={packageData}
          setStatus={setStatus}
          isDisplayTop={isDisplayTop}
          setIsDisplayTop={setIsDisplayTop}
        />
      </div>
    </>
  )
}

export default withSnackbar(EditContent)
