import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import { Dialog } from '@material-ui/core'
import { Auth, Storage } from 'aws-amplify'
import IconButton from '@material-ui/core/IconButton'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    width: 160,
    height: 120
  },
  cover: {
    width: 160,
    height: 120
  },
  playArrow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    webkitTransform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    color: theme.palette.c_button.main,
    backgroundColor: theme.palette.c_background.white,
    opacity: '0.55'
  }
}))

export default function ThumbnailMovie({
  attachment,
  movie,
  thumbnailImage,
  title,
  ...props
}) {
  const classes = useStyles()

  const [video, setVideo] = useState(movie ? movie : null)
  const [isOpen, setOpen] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    var element = document.createElement('video')

    element.onloadedmetadata = function() {
      var width = element.videoWidth
      var height = element.videoHeight
      if (width > height) {
        let ratio = height / width
        let maxWidth = window.innerWidth - 84
        let value = width > maxWidth ? maxWidth : width
        setWidth(value)
        setHeight(value * ratio)
      } else {
        let ratio = width / height
        let maxHeight = window.innerHeight - 84
        let value = height > maxHeight ? maxHeight : height
        setWidth(value * ratio)
        setHeight(value)
      }
    }
    element.src = movie
    element.load()
  }, [setWidth, setHeight])

  useEffect(() => {
    if (isOpen) {
      if (attachment) {
        Auth.currentCredentials().then(async creds => {
          await Promise.all([fetchVideo(attachment)])
        })
      }
    }
  }, [isOpen])

  async function fetchVideo(movie) {
    const key = movie.key
    console.log(key)
    const result = await getStorage(key)
    console.log(result)
    const object = Object.assign({}, movie, { body: result })
    setVideo(object.body)
  }

  async function getStorage(key) {
    return Storage.get(key, {
      level: 'protected',
      identityId: attachment.company_id,
      expires: 60
    })
  }

  return (
    <div {...props}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cover}
          image={thumbnailImage}
          title={title}
          onClick={() => setOpen(true)}
        />
        <IconButton className={classes.playArrow} onClick={() => setOpen(true)}>
          <PlayArrowIcon fontSize="large" />
        </IconButton>
      </Card>
      <Dialog
        onClose={() => setOpen(false)}
        open={isOpen}
        maxWidth={false}
        scroll={'body'}
      >
        <Card>
          {video && (
            <CardMedia
              component="video"
              style={{
                width: width,
                height: height,
                maxWidth: width,
                maxHeight: height
              }}
              src={video}
              controls
            />
          )}
        </Card>
      </Dialog>
    </div>
  )
}
