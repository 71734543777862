import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Loading from 'components/common/Loading'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { openModalDialog } from 'actions/Actions'
import OwnerFormContent from './OwnerFormContent'
import FormModalDialog from './FormModalDialog'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: theme.palette.c_thirdly.main,
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: theme.palette.c_thirdly.main,
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: theme.palette.c_thirdly.main,
    fontSize: '14px',
    lineHeight: '21px'
  },
  textRight: {
    textAlign: 'right'
  }
}))

const OwnerInfo = props => {
  const classes = useStyles()
  const { owner, isLoading, openModalDialog, fetchOwner } = props

  const handleClick = () => {
    openModalDialog()
  }

  return (
    <>
      <TableContainer component="Paper">
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.root} colSpan="3">
                オーナー情報
              </TableCell>
              <TableCell className={classes.textRight} colSpan="1">
                <Button
                  onClick={handleClick}
                  variant="contained"
                  disableElevation
                >
                  編集
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && owner ? (
              <>
                <TableRow>
                  <TableCell className={classes.columnName}>
                    オーナーID
                  </TableCell>
                  <TableCell className={classes.columnValue} colSpan="3">
                    {owner.owner.id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.columnName}>
                    オーナー名
                  </TableCell>
                  <TableCell className={classes.columnValue}>
                    {owner.owner.name}
                  </TableCell>
                  <TableCell className={classes.columnName}>フリガナ</TableCell>
                  <TableCell className={classes.columnValue}>
                    {owner.owner.name_kana}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.columnName}>
                    ログインID(メールアドレス)
                  </TableCell>
                  <TableCell className={classes.columnValue}>
                    {owner.app_user_and_attachment.username}
                  </TableCell>
                  <TableCell className={classes.columnName}>
                    パスワード
                  </TableCell>
                  <TableCell className={classes.columnValue}>
                    ＊＊＊＊＊＊＊
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell className={classes.columnName}>
                  <Loading isLoading={props.isLoading} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FormModalDialog
        title={'オーナー情報を編集'}
        content={<OwnerFormContent owner={owner} fetchOwner={fetchOwner} />}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    openModalDialog: () => {
      dispatch(openModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(OwnerInfo))
