import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Auth, Storage } from 'aws-amplify'
import RowWithFile from 'components/common/RowWithFile'
import Loading from 'components/common/Loading'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  contentName: {
    height: '48px',
    width: '420px'
  },
  contentTitle: {
    height: '12px',
    width: '324.34px',
    color: '#72809D',
    backgroundColor: '#FAFBFD',
    fontFamily: 'Hiragino Sans',
    fontSize: '12px',
    lineHeight: '18px'
  }
}))
const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function ResidentInfoChangeApplicationDetail({
  isLoading,
  application,
  attachments
}) {
  const classes = useStyles()
  const content =
    application && application.content ? parseJson(application.content) : {}
  const [images, setImages] = useState([])

  useEffect(() => {
    fetchData()
  }, [attachments, setImages])

  const fetchData = async () => {
    await Promise.all([fetchAttachment()])
  }

  async function fetchAttachment() {
    if (attachments) {
      const imageList = []
      for (let attachment of attachments) {
        await Auth.currentCredentials().then(async creds => {
          const key = attachment.key
          console.log(key)
          await Storage.get(key, {
            level: 'protected',
            identityId: attachment.company_id,
            expires: 60
          })
            .then(result => {
              const object = Object.assign({}, attachment, { body: result })
              imageList.push(object)
            })
            .catch(err => {
              console.log('error: ', err)
            })
        })
      }
      setImages(imageList)
    }
  }

  console.log('images', images)

  return (
    <>
      <TableContainer component="Paper">
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow colSpan="3">
              <TableCell className={classes.root} colSpan="3">
                変更理由
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow colSpan="3">
                <TableCell
                  align="left"
                  className={classes.columnName2}
                  colSpan="3"
                >
                  <Loading isLoading={isLoading} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                <TableRow colSpan="3">
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    理由
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="2"
                  >
                    {content && content.reason && content.reason}
                  </TableCell>
                </TableRow>
                <TableRow colSpan="3">
                  <TableCell
                    align="left"
                    className={classes.columnName2}
                    colSpan="1"
                  >
                    添付ファイル
                  </TableCell>
                  <TableCell className={classes.columnName2} colSpan="2">
                    <Grid container>
                      {!isLoading &&
                        images.length > 0 &&
                        images.map(({ status, filename, body }, index) => (
                          <>
                            {status === 1 && (
                              <Grid item xs={6}>
                                <RowWithFile
                                  key={index}
                                  image={body}
                                  isDeleteButtonView={false}
                                  style={{ marginBottom: 10 }}
                                />
                              </Grid>
                            )}
                          </>
                        ))}
                    </Grid>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ResidentInfoChangeApplicationDetail
