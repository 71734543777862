import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { formatTime, formatYYYYMMDD } from 'utils/date.utils'

const useStyles = makeStyles(theme => ({
  time: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: props => (props.color ? props.color : '#2c3f58')
  }
}))

export default function DateLabel3({ date, ...props }) {
  const classes = useStyles(props)

  const yyyymmdd = formatYYYYMMDD(date)
  const hhmm = formatTime(date)

  return (
    <Grid container xs={8} direction="column">
      <Grid item xs={8} className={classes.time}>
        <span {...props}>
          {yyyymmdd} {hhmm}
        </span>
      </Grid>
    </Grid>
  )
}
