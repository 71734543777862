import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { QueryHelper } from 'utils/api.utils'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  buttonWrapper: {
    marginLeft: '16px',
    width: 48,
    height: 48
  },
  button: {
    color: '#fff'
  }
}))

function Body(props) {
  const classes = useStyles()
  const history = useHistory()
  const [companyGroup, setCompanyGroup] = useState()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await Promise.all([fetchUserData()])
  }

  const fetchUserData = async () => {
    const item = sessionStorage.getItem('totono-advertiser-session')
      ? sessionStorage.getItem('totono-advertiser-session')
      : localStorage.getItem('totono-advertiser-session')
    const data = JSON.parse(item)
    const result = await QueryHelper(
      'getCompanyUser',
      { filter: { id: { eq: data.user_id } } },
      false
    )

    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('ユーザ情報が取得できませんでした', {
        variant: 'error'
      })
      return
    }
    setCompanyGroup(result.company_group)
  }

  return (
    <>
      <div className={classes.buttonWrapper}>
        {companyGroup && (
          <IconButton
            aria-label="back"
            className={classes.button}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </div>

      <Typography className={classes.text}>{props.title}</Typography>
    </>
  )
}

export default withRouter(Body)
