import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessages } from 'utils/errorMessages'

export const useRoomCheckListForm = application_option => {
  const schema = yup.object().shape(
    {
      is_enabled: yup.boolean(),
      is_checked_url: yup.boolean(),
      application_url: yup
        .string()
        .max(256, ErrorMessages.CharLimit256)
        .when(['is_enabled', 'is_checked_url'], {
          is: (is_enabled, is_checked_url) => is_enabled && is_checked_url,
          then: yup.string().required(ErrorMessages.Required)
        })
        .url(ErrorMessages.UrlError),
      caution_body: yup.string().when(['is_enabled', 'is_checked_url'], {
        is: (is_enabled, is_checked_url) => is_enabled && !is_checked_url,
        then: yup.string().required(ErrorMessages.Required)
      }),
      caution_url_text: yup
        .string()
        .max(30, ErrorMessages.CharLimit30)
        .when('caution_url', {
          is: value => value !== '',
          then: yup.string().required(ErrorMessages.Required)
        }),
      caution_url: yup
        .string()
        .max(256, ErrorMessages.CharLimit256)
        .when('caution_url_text', {
          is: value => value !== '',
          then: yup.string().required(ErrorMessages.Required)
        })
        .url(ErrorMessages.UrlError),
      complete_title: yup
        .string()
        .when(['is_enabled', 'is_checked_url'], {
          is: (is_enabled, is_checked_url) => is_enabled && !is_checked_url,
          then: yup.string().required(ErrorMessages.Required)
        })
        .max(30, ErrorMessages.CharLimit30),
      complete_body: yup.string().when(['is_enabled', 'is_checked_url'], {
        is: (is_enabled, is_checked_url) => is_enabled && !is_checked_url,
        then: yup.string().required(ErrorMessages.Required)
      }),
      complete_url_text: yup
        .string()
        .max(30, ErrorMessages.CharLimit30)
        .when('complete_url', {
          is: value => value !== '',
          then: yup.string().required(ErrorMessages.Required)
        }),
      complete_url: yup
        .string()
        .max(256, ErrorMessages.CharLimit256)
        .when('complete_url_text', {
          is: value => value !== '',
          then: yup.string().required(ErrorMessages.Required)
        })
        .url(ErrorMessages.UrlError),
      reckoning_date_type_id: yup
        .number()
        .when(['is_enabled', 'is_checked_url'], {
          is: (is_enabled, is_checked_url) => is_enabled && !is_checked_url,
          then: yup.number().required(ErrorMessages.Required)
        })
        .oneOf(
          [1, 2, 3],
          '入居日、契約日、アプリ利用開始日いずれかを選択してください'
        ),
      display_period: yup
        .number()
        .when(['is_enabled', 'is_checked_url'], {
          is: (is_enabled, is_checked_url) => is_enabled && !is_checked_url,
          then: yup.number().required(ErrorMessages.Required)
        })
        .min(1, ErrorMessages.DayExcessInput)
        .max(365, ErrorMessages.DayExcessInput),
      reception_period: yup
        .number()
        .when(['is_enabled', 'is_checked_url'], {
          is: (is_enabled, is_checked_url) => is_enabled && !is_checked_url,
          then: yup.number().required(ErrorMessages.Required)
        })
        .min(1, ErrorMessages.DayExcessInput)
        .max(365, ErrorMessages.DayExcessInput)
    },
    [
      ['caution_url_text', 'caution_url'],
      ['complete_url_text', 'complete_url']
    ]
  )

  const useFormMethods = useForm({
    defaultValues: {
      is_enabled: application_option.is_enabled,
      is_checked_url: application_option.is_checked_url,
      application_url: application_option.application_url,
      caution_body: application_option.caution_body,
      caution_url_text: application_option.caution_url_text,
      caution_url: application_option.caution_url,
      complete_title: application_option.complete_title,
      complete_body: application_option.complete_body,
      complete_url_text: application_option.complete_url_text,
      complete_url: application_option.complete_url,
      reckoning_date_type_id: application_option.reckoning_date_type_id,
      display_period: application_option.display_period,
      reception_period: application_option.reception_period
    },
    mode: 'all',
    resolver: yupResolver(schema)
  })

  return useFormMethods
}
