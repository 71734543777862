import React from 'react'
import DetailLayout from 'components/common/DetailLayout'
import Detail from './Detail'

function DetailContent(props) {
  const { room } = props
  // console.log(JSON.stringify(room));

  return <DetailLayout title="部屋詳細" children={<Detail room={room} />} />
}

export default DetailContent
