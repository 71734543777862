import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import SearchTextField from './SearchTextField'
import { Auth, Storage } from 'aws-amplify'
import { QueryHelper } from 'utils/api.utils'
import { SearchConditionContext } from 'context/SearchConditionContext'
import { withSnackbar } from 'notistack'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    marginLeft: '32px',
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  primaryButton: {
    marginLeft: '16px',
    marginRight: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.text.disabled
    }
  }
}))

function Body(props) {
  const classes = useStyles()
  const { role6 } = useRole()
  const isUnrestrictedRole = role6()

  const [searchConditionContext] = React.useContext(SearchConditionContext)

  const csvDownLoad = async event => {
    let params = {}
    if (
      searchConditionContext &&
      searchConditionContext.searchCondition &&
      searchConditionContext.searchCondition
        .applicationResidentCancellationSearchCondition
    ) {
      params =
        searchConditionContext.searchCondition
          .applicationResidentCancellationSearchCondition
    }
    const csvData = await getCsv(0, null, params)
    if (csvData.error) {
      props.enqueueSnackbar('CSVのDLに失敗しました', {
        variant: 'error'
      })
      return
    }
    await downloadFile(csvData)
  }

  async function getCsv(offset, limit, params) {
    let result = await QueryHelper(
      'getResidentCancellationApplicationCsv',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('入居者情報を取得できませんでした', {
        variant: 'error'
      })
      return result
    } else {
      return result
    }
  }

  async function downloadFile(csvData) {
    await Auth.currentCredentials().then(async creds => {
      const key = csvData.key
      Storage.get(key, {
        level: 'protected',
        identityId: csvData.companyId,
        expires: 60
      })
        .then(result => {
          const link = document.createElement('a')
          link.href = result
          link.click()
        })
        .catch(err => {
          console.log('error: ', err)
        })
    })
  }

  return (
    <>
      <Typography className={classes.root}>{props.title}</Typography>
      <SearchTextField text="入居者名で検索" />
      <Button
        variant="contained"
        className={classes.primaryButton}
        onClick={csvDownLoad}
        disabled={isUnrestrictedRole}
      >
        CSV出力
      </Button>
    </>
  )
}

export default withSnackbar(Body)
