import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { connect } from 'react-redux'
import FormLayout from 'components/common/FormLayout'
import { withSnackbar } from 'notistack'
import Loading from 'components/common/Loading'
import DateLabel from 'components/common/DateLabel'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  }
}))

function Right(props) {
  const classes = useStyles()

  const { isLoading, company } = props
  return (
    <>
      <FormLayout title="概要">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  最終更新
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="right"
                    className={classes.content}
                  >
                    {'company' in company && company.company ? (
                      <DateLabel timestamp={company.company.updated_at} />
                    ) : (
                      ''
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  更新者
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  align="right"
                  className={classes.content}
                >
                  {company.updated_by &&
                    getFormattedNameByStatus(
                      company.updated_by.name,
                      company.updated_by.status
                    )}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </FormLayout>
      <Divider />
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Right))
