import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormLayout from 'components/common/FormLayout'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useFormContext } from 'react-hook-form'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '10px 0'
  },
  section: {
    padding: '15px 20px'
  },
  contentTitle: {
    height: '24px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '20px'
  },
  contentMessage: {
    height: '24px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '50px',
    marginBottom: '30px'
  },
  selectEmpty: {
    minWidth: '200px',
    marginLeft: '15px',
    marginRight: '15px'
  },
  selectEmptyPeriod: {
    minWidth: '100px',
    marginLeft: '15px',
    marginRight: '15px'
  },
  errorMessage: {
    color: 'red',
    fontSize: 12,
    textAlign: 'left'
  }
}))

const SendablePeriodSettingForm = () => {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()
  const { setValue, watch, formState } = useFormContext()

  const handleChange = event => {
    setValue(event.target.name, event.target.value)
  }

  return (
    <FormLayout title={'入居時室内点検表  送信可能期間設定'}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.contentTitle}>
            {'画面上の表示期間'}
          </Typography>
        </Grid>
        {formState.errors.display_period && (
          <Grid item xs={12}>
            <Typography className={classes.errorMessage}>
              {formState.errors.display_period.message}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography className={classes.contentMessage}>
            入居から
            <Select
              variant="filled"
              hiddenLabel
              error={Boolean(formState.errors.display_period)}
              labelId="display_period"
              id="display_period"
              name="display_period"
              value={watch('display_period')}
              onChange={handleChange}
              className={classes.selectEmptyPeriod}
              disabled={!isUnrestrictedRole}
            >
              {Array.from({ length: 365 }, (v, k) => k + 1).map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            日以内に申請してください
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.contentTitle}>
            {'実際の受付期間'}
          </Typography>
        </Grid>
        {formState.errors.reckoning_date_type_id && (
          <Grid item xs={12}>
            <Typography className={classes.errorMessage}>
              {formState.errors.reckoning_date_type_id.message}
            </Typography>
          </Grid>
        )}
        {formState.errors.reception_period && (
          <Grid item xs={12}>
            <Typography className={classes.errorMessage}>
              {formState.errors.reception_period.message}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography className={classes.contentMessage}>
            起算日
            <Select
              variant="filled"
              hiddenLabel
              error={formState.errors.reckoning_date_type_id}
              labelId="reckoning_date_type_id"
              id="reckoning_date_type_id"
              name="reckoning_date_type_id"
              value={watch('reckoning_date_type_id')}
              onChange={handleChange}
              className={classes.selectEmpty}
              disabled={!isUnrestrictedRole}
            >
              <MenuItem key={1} value={1}>
                入居日
              </MenuItem>
              <MenuItem key={2} value={2}>
                契約日
              </MenuItem>
              <MenuItem key={3} value={3}>
                アプリ利用開始日
              </MenuItem>
            </Select>
            から
            <Select
              variant="filled"
              hiddenLabel
              error={formState.errors.reception_period}
              labelId="reception_period"
              id="reception_period"
              name="reception_period"
              value={watch('reception_period')}
              onChange={handleChange}
              className={classes.selectEmptyPeriod}
              disabled={!isUnrestrictedRole}
            >
              {Array.from({ length: 365 }, (v, k) => k + 1).map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            日まで受付表示
          </Typography>
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default SendablePeriodSettingForm
