import React from 'react'
import { makeStyles } from '@material-ui/core'
import BuildingDetailContent from './BuildingDetailContent'
import OwnerInfo from './OwnerInfo'
import OwnerDocumentFileForm from './OwnerDocumentFileForm'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  section: {
    flex: 1,
    backgroundColor: theme.palette.c_white.main
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

const Left = props => {
  const classes = useStyles()
  const {
    isLoading,
    owner,
    building,
    knrDivList,
    knrkeitaiDivList,
    fetchOwner
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <BuildingDetailContent
          isLoading={isLoading}
          building={building}
          knrDivList={knrDivList}
          knrkeitaiDivList={knrkeitaiDivList}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.section}>
        <OwnerInfo
          isLoading={isLoading}
          owner={owner}
          fetchOwner={fetchOwner}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.section}>
        <OwnerDocumentFileForm title={'オーナードキュメント管理'} />
      </div>
    </div>
  )
}
export default Left
