import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import { HYA_STATUS_LIST, HYA_HYA_SYSTEM_DIV_LIST } from 'utils/const'
import { getKnrDiv } from 'utils/building.utils'

const useStyles = makeStyles(() => ({
  columnBase: {
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName: {
    width: '18%'
  },
  columnValue1: {
    width: '82%'
  },
  columnValue2: {
    width: '32%'
  }
}))

const fullFloor = mst_heya => {
  // return mst_heya.HYA_FLS_NUM + " " + mst_heya.HYA_FLS_CD;
  return mst_heya.HYA_FLS_NAME + mst_heya.HYA_FLS_NUM + '階'
}

const fullLayout = mst_heya => {
  // return mst_heya.HYA_MDR_NUM + " " + mst_heya.HYA_MDR_CD;
  return (mst_heya.HYA_MDR_NUM || '') + ' ' + mst_heya.HYA_MDR_NAME
}

function getStatus(status) {
  const found = HYA_STATUS_LIST.find(element => element.value == status)
  return found ? found.label : ''
}

function getHyaSystemDiv(hyaSystemDiv) {
  if (!hyaSystemDiv) {
    return ''
  }
  const found = HYA_HYA_SYSTEM_DIV_LIST.find(
    element => element.value == hyaSystemDiv
  )
  return found ? found.label : ''
}

const Detail = props => {
  const classes = useStyles()

  const { room } = props

  const [building, setBuilding] = useState({})
  const [knrDivList, setKnrDivList] = useState({})
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState({})

  const fetchBuilding = async buildingId => {
    const params = {
      id: {
        eq: buildingId
      }
    }
    // console.log(JSON.stringify(params));
    const result = await QueryHelper('getBuilding', { filter: params })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`建物を取得できませんでした。`)
    } else {
      setBuilding(result)
      await Promise.all([
        fetchMstTateyaKnrDivList(),
        fetchMstTateyaKnrkeitaiDivList()
      ])
    }
  }

  const fetchMstTateyaKnrDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKNRDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKNRDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knr_cd] = item.knr_name
      })
      setKnrDivList(l)
    }
  }

  const fetchMstTateyaKnrkeitaiDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKnrkeitaiDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knrkeitai_cd] = item.knrkeitai_name
      })
      setKnrkeitaiDivList(l)
    }
  }

  useEffect(() => {
    if (room && room.room) {
      fetchBuilding(room.room.building_id)
    }
  }, [room, setBuilding])

  const rows =
    building.mst_tateya && room.mst_heya
      ? [
          { label: '建物名', value: building.mst_tateya.TTY_NAME, colSpan: 3 },
          {
            label: '建物名フリガナ',
            value: building.mst_tateya.TTY_NAME_KANA,
            colSpan: 3
          },
          {
            label1: '部屋番号',
            value1: room.mst_heya.HYA_ROOM_NO,
            label2: 'システム区分',
            value2: getHyaSystemDiv(room.mst_heya.HYA_SYSTEM_DIV)
          },
          {
            label1: '所属階',
            value1: fullFloor(room.mst_heya),
            label2: '間取り',
            value2: fullLayout(room.mst_heya)
          },
          {
            label1: '面積',
            value1: `${room.mst_heya.HYA_SENYU_MENSEKI}㎡`,
            label2: '方位',
            value2: room.mst_heya.HYA_DCN_NAME
          },
          {
            label1: '管理区分',
            value1: getKnrDiv(building.mst_tateya, knrDivList),
            label2: '管理パターン',
            value2: undefined
          },
          {
            label1: '種別',
            value1: room.mst_heya.HYA_TYS_NAME,
            label2: '空室区分',
            value2: getStatus(room.mst_heya.HYA_STATUS_DIV)
          },
          {
            label1: '建物取扱店舗',
            value1: building.mst_tateya.TTY_TNP_NAME,
            label2: 'メールボックス番号',
            value2: room.mst_heya.HYA_BOX_NUMBER
          }
        ]
      : []

  const renderSingleRow = ({ label, value, colSpan }) => (
    <TableRow>
      <TableCell className={`${classes.columnBase} ${classes.columnName}`}>
        {label}
      </TableCell>
      <TableCell
        className={`${classes.columnBase} ${classes.columnValue1}`}
        colSpan={colSpan}
      >
        {value}
      </TableCell>
    </TableRow>
  )

  const renderDoubleRow = ({ label1, value1, label2, value2 }) => (
    <TableRow>
      <TableCell className={`${classes.columnBase} ${classes.columnName}`}>
        {label1}
      </TableCell>
      <TableCell className={`${classes.columnBase} ${classes.columnValue2}`}>
        {value1}
      </TableCell>
      <TableCell className={`${classes.columnBase} ${classes.columnName}`}>
        {label2}
      </TableCell>
      <TableCell className={`${classes.columnBase} ${classes.columnValue2}`}>
        {value2}
      </TableCell>
    </TableRow>
  )

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          {building.mst_tateya && room.mst_heya && (
            <>
              {rows.map(row =>
                row.label ? renderSingleRow(row) : renderDoubleRow(row)
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withSnackbar(Detail)
