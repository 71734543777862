import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog } from 'actions/Actions'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  left: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  body: {},
  right: {
    marginLeft: '16px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  tertiaryButton: {
    color: theme.palette.c_gray.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px'
  },
  note: {
    color: theme.palette.c_red.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px'
  }
}))

function FormFooter(props) {
  const classes = useStyles()

  const { companyGroup } = props

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {0 !== Object.keys(companyGroup).length && (
          <>
            <Button
              className={classes.tertiaryButton}
              startIcon={<DeleteIcon />}
              onClick={props.handleDelete}
            >
              削除
            </Button>
            <Typography className={classes.note}>
              ※所属する利用者がいる場合削除できません
            </Typography>
          </>
        )}
      </div>
      <div className={classes.body}>
        <Button
          className={classes.secondaryButton}
          onClick={props.closeModalDialog}
          color="primary"
          variant="contained"
        >
          キャンセル
        </Button>
      </div>
      <div className={classes.right}>
        <Button
          className={classes.primaryButton}
          color="primary"
          type="submit"
          variant="contained"
        >
          保存
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFooter)
