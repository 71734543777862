import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { handleClear } from 'actions/Actions'
import FooterButtons from '../../../components/General/FooterButtons'
import Loading from 'components/common/Loading'
import { withSnackbar } from 'notistack'
import { QueryHelper, MutationHelper } from 'utils/api.utils'
import { NoticeMessages } from 'utils/noticeMessages'
import { ErrorMessages } from 'utils/errorMessages'
import ApplicationOptionsUseSettings from 'components/Form/ApplicationOptionsUseSettings'
import ApplicationOptionsPrecautionsSettings from 'components/Form/ApplicationOptionsPrecautionsSettings'
import ApplicationOptionsMessageSettings from 'components/Form/ApplicationOptionsMessageSettings'
import { FormProvider } from 'react-hook-form'
import { useResidentCancellationForm } from '../../../hooks/useResidentCancellationForm'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  },
  message: {
    fontSize: '12px',
    marginBottom: '8px'
  }
}))

// 設定がない場合の初期値
const ApplicationOption = {
  status: 1,
  is_enabled: false,
  is_checked_url: false,
  application_type_id: 2,
  application_url: '',
  cancellation_body: '',
  caution_body: '',
  caution_url_text: '',
  caution_url: '',
  complete_title: '',
  complete_body: '',
  complete_url_text: '',
  complete_url: ''
}

function EditScreen(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [checkError, setCheckError] = useState({
    cancellation_body: '',
    caution_body: '',
    complete_body: ''
  })
  const [applicationOption, setApplicationOption] = useState({
    application_option: ApplicationOption,
    attachments: null
  })
  const [noChangeCheck, setNoChangeCheck] = useState({
    application_option: ApplicationOption,
    attachments: null
  })
  const useFormMethods = useResidentCancellationForm(ApplicationOption)
  const { handleSubmit, reset } = useFormMethods

  useEffect(() => {
    props.handleClear()
    fetchData()
  }, [])

  useEffect(() => {
    reset(applicationOption.application_option)
  }, [applicationOption])

  const fetchData = async () => {
    setIsLoading(true)
    await getApplicationOption()
    setIsLoading(false)
  }

  const getApplicationOption = async () => {
    const result = await QueryHelper(
      'getApplicationOption',
      {
        filter: {
          application_type_id: 2
        }
      },
      true
    )
    if (result.error) {
      props.enqueueSnackbar(ErrorMessages.FailedToRetrieveCategory, {
        variant: 'error'
      })
    } else {
      if (result.application_option) {
        setData(result.application_option, result.attachments)
      }
    }
  }

  const setData = (data, attachments) => {
    setNoChangeCheck({
      ...noChangeCheck,
      application_option: {
        id: data.id,
        status: data.status,
        is_enabled: data.is_enabled || false,
        is_checked_url: data.is_checked_url || false,
        application_type_id: data.application_type_id,
        application_url: data.application_url || '',
        cancellation_body: data.cancellation_body || '',
        caution_body: data.caution_body || '',
        caution_url_text: data.caution_url_text || '',
        caution_url: data.caution_url || '',
        complete_title: data.complete_title || '',
        complete_body: data.complete_body || '',
        complete_url_text: data.complete_url_text || '',
        complete_url: data.complete_url || ''
      }
    })
    setApplicationOption({
      ...applicationOption,
      application_option: {
        id: data.id,
        status: data.status,
        is_enabled: data.is_enabled || false,
        is_checked_url: data.is_checked_url || false,
        application_type_id: data.application_type_id,
        application_url: data.application_url || '',
        cancellation_body: data.cancellation_body || '',
        caution_body: data.caution_body || '',
        caution_url_text: data.caution_url_text || '',
        caution_url: data.caution_url || '',
        complete_title: data.complete_title || '',
        complete_body: data.complete_body || '',
        complete_url_text: data.complete_url_text || '',
        complete_url: data.complete_url || ''
      },
      attachments: attachments ? attachments : []
    })
  }

  const upsertApplicationOption = async data => {
    if (
      checkError.cancellation_body ||
      checkError.caution_body ||
      checkError.complete_body
    )
      return

    let { pdfs } = props.parameters
    const params = {
      application_option: data,
      attachments: []
    }
    // defaultValueで指定したテキストサイズにはfont-sizeが記載されないため、一番外を囲んで指定がないものを全て15pxにしてデータを登録する
    params.application_option.cancellation_body = `<span style="font-size: 15px">${params.application_option.cancellation_body}</span>`
    params.application_option.caution_body = `<span style="font-size: 15px">${params.application_option.caution_body}</span>`
    params.application_option.complete_body = `<span style="font-size: 15px">${params.application_option.complete_body}</span>`
    if (
      (!pdfs || pdfs.length === 0) &&
      JSON.stringify(Object.entries(noChangeCheck.application_option).sort()) ==
        JSON.stringify(Object.entries(params.application_option).sort())
    ) {
      props.enqueueSnackbar(NoticeMessages.NoChange, {
        variant: 'warning'
      })
      return
    }
    if (
      noChangeCheck.application_option.is_enabled !==
      params.application_option.is_enabled
    ) {
      if (
        !params.application_option.is_checked_url &&
        params.application_option.is_enabled
      ) {
        if (
          !window.confirm(
            `受付機能をONにすると、totonoで解約申請を受付します。\n保存しますか？`
          )
        ) {
          return
        }
      } else if (
        !params.application_option.is_checked_url &&
        !params.application_option.is_enabled
      ) {
        if (
          !window.confirm(
            `受付機能をOFFにすると、totonoで解約申請の受付を停止します。\n保存しますか？`
          )
        ) {
          return
        }
      }
    }
    if (
      noChangeCheck.application_option.is_checked_url !==
      params.application_option.is_checked_url
    ) {
      if (
        params.application_option.is_enabled &&
        params.application_option.is_checked_url
      ) {
        if (
          !window.confirm(
            `リンクの設定をONにすると、totonoで解約申請の受付を停止します。\n※参照先URLに記載した設定がアプリに反映されます。\n保存しますか？`
          )
        ) {
          return
        }
      } else if (
        params.application_option.is_enabled &&
        !params.application_option.is_checked_url
      ) {
        if (
          !window.confirm(
            `リンクの設定をOFFにすると、totonoで解約申請を受付します。\n保存しますか？`
          )
        ) {
          return
        }
      }
    }

    const attachments = await putAttachment(pdfs)
    params.attachments = attachments
    const result = await MutationHelper('upsertApplicationOption', {
      input: params
    })
    if (!result.error) {
      props.handleClear()
      props.enqueueSnackbar(NoticeMessages.SuccessToSave, {
        variant: 'success'
      })
      fetchData()
    } else {
      props.enqueueSnackbar(ErrorMessages.FailedToSave, {
        variant: 'error'
      })
    }
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    return result
  }

  const putAttachment = async pdfs => {
    let attachments = []
    if (pdfs) {
      const creds = await Auth.currentCredentials()
      for (let file of pdfs) {
        if ('id' in file) {
          attachments.push(file)
          continue
        }
        const result = await fileupload(creds, 'pdfs', file)
        attachments.push({
          attachment_type_id: 3,
          mime_type: file.type,
          filename: file.name,
          key: result.key
        })
      }
    }
    return attachments
  }

  const handleCancel = () => {
    if (
      !window.confirm(`編集内容が破棄されますがキャンセルしてよろしいですか？`)
    ) {
      return
    }
    reset()
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <FormProvider {...useFormMethods}>
            <form
              onSubmit={handleSubmit(upsertApplicationOption)}
              className={classes.left}
            >
              <div className={classes.body}>
                <ApplicationOptionsUseSettings title={'解約'} />
              </div>
              <div className={classes.divider} />
              <div className={classes.body}>
                <ApplicationOptionsPrecautionsSettings
                  name={'解約申請'}
                  attachments={applicationOption.attachments}
                  setCheckError={setCheckError}
                  checkError={checkError}
                />
              </div>
              <div className={classes.divider} />
              <div className={classes.body}>
                <ApplicationOptionsMessageSettings
                  setCheckError={setCheckError}
                  checkError={checkError}
                />
              </div>
              <FooterButtons handleCancel={handleCancel} />
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditScreen))
