import React from 'react'
import { connect } from 'react-redux'
import { openModalDialog } from 'actions/Actions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withSnackbar } from 'notistack'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { useRole } from 'hooks/useRole'

const columns = [
  { id: 'name', label: 'グループ名', minWidth: 170 },
  { id: 'role', label: '権限', minWidth: 170 },
  { id: 'count', label: '所属人数', minWidth: 120 },
  { id: 'valid', label: '利用中', minWidth: 80 },
  { id: 'invalid', label: '無効', minWidth: 80 }
]

function List(props) {
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const isTextSelected = useIsTextSelected()

  const handleClick = companyGroup => {
    if (isTextSelected()) return
    props.setCompanyGroup(companyGroup)
    props.openModalDialog()
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead style={{ backgroundColor: '#fafbfd' }}>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.companyGroupList.length
            ? props.companyGroupList.map(row => {
                const role = props.roleList.filter(v => v.id == row.role_id)
                const userList = props.companyUserList.filter(
                  user => user.company_user.company_group_id == row.id
                )
                const userCount = userList.length

                const useStatus = userList.filter(
                  status => status.company_user.status == 1
                )
                const validUserCount = useStatus.length
                const invalidUserCount = userList.length - validUserCount

                return (
                  <TableRow
                    onClick={() => handleClick(row)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    style={{ pointerEvents: !isUnrestrictedRole && 'none' }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{role.length > 0 ? role[0].name : ''}</TableCell>
                    <TableCell>{userCount}</TableCell>
                    <TableCell>{validUserCount}</TableCell>
                    <TableCell>{invalidUserCount}</TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    openModalDialog: () => {
      dispatch(openModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(List))
