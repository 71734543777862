import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import SearchTextField from './SearchTextField'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    marginLeft: '32px',
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  textField: {
    marginRight: '16px'
  }
}))

function Body(props) {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.root}>{props.title}</Typography>
      <SearchTextField
        text="定型文タイトルで検索"
        className={classes.textField}
      />
    </>
  )
}

export default Body
