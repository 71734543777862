import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

export default function UsePageTracking() {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      return null
    } else if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.initialize('UA-168691195-2', {
        debug: true // react-gaのログ出力ON
      })
      setInitialized(true)
    } else {
      ReactGA.initialize('UA-168691195-1', {
        debug: true // react-gaのログ出力ON
      })
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location])

  return null
}
