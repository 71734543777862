import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(() => ({
  '@global': {
    '.MuiTypography-h6': {
      height: '24px',
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  formContent: {
    padding: '20px'
  },
  formFooter: {
    margin: '8px 16px'
  }
}))

const FormModalDialog = props => {
  const classes = useStyles()
  const { title, content, footer, postData, open, handleCancel } = props

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <form onSubmit={postData}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <Divider />
        <DialogContent className={classes.formContent}>{content}</DialogContent>
        <Divider />
        <DialogActions className={classes.formFooter}>{footer}</DialogActions>
      </form>
    </Dialog>
  )
}

export default FormModalDialog
