import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import { MutationHelper } from 'utils/api.utils'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import DateLabel3 from 'components/common/DateLabel3'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  footer: {
    flex: 1,
    display: 'flex',
    height: '68px',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '20px'
  },
  footerLeft: {
    flex: 1,
    display: 'flex'
  },
  lastUpdated: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#72809D'
  },
  lastUpdatedTitle: {
    float: 'left',
    marginRight: '10px'
  },
  footerBody: {},
  footerRight: {
    marginLeft: '16px'
    // marginRight: "32px"
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function formatDateLabel(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr)
    return <DateLabel3 date={date} color={'#72809D'} />
  }
  return ''
}

function RoomForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const { room } = props
  // console.log(JSON.stringify(room));

  const [parameters, setParameters] = useState({})

  const handleChange = event => {
    setParameters({
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    // console.log(JSON.stringify(parameters));
    if (Object.keys(parameters).length > 0) {
      const params = Object.assign({}, parameters)
      params['id'] = room.room.id
      putRoom(params)
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
    }
  }

  const putRoom = async params => {
    const result = await MutationHelper('updateRoom', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('部屋備考を更新できませんでした。', {
        variant: 'error'
      })
    } else {
      // console.log(JSON.stringify(result));
      setParameters({})
      props.enqueueSnackbar('部屋備考を更新しました。', {
        variant: 'success'
      })
    }
  }

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <FormLayout title="部屋備考">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              id="remark"
              name="remark"
              label="備考を記載"
              fullWidth
              multiline={true}
              rows={8}
              autoComplete="remark"
              variant="filled"
              defaultValue={'room' in room && room.room ? room.room.remark : ''}
              onChange={handleChange}
              disabled={isRestrictedRole}
            />
          </Grid>
        </Grid>
      </FormLayout>
      <Divider />
      <div className={classes.footer}>
        <div className={classes.footerLeft}>
          {room.mst_heya &&
            (room.mst_heya.HYA_UPDATED_DATE ||
              room.mst_heya.HYA_INSERTED_DATE) && (
              <Grid className={classes.lastUpdated} container xs={8}>
                <Grid item className={classes.lastUpdatedTitle}>
                  最終更新
                </Grid>
                {room.mst_heya.HYA_UPDATED_DATE
                  ? formatDateLabel(room.mst_heya.HYA_UPDATED_DATE)
                  : formatDateLabel(room.mst_heya.HYA_INSERTED_DATE)}
              </Grid>
            )}
        </div>
        <div className={classes.footerBody}>
          <Button
            variant="contained"
            className={classes.secondaryButton}
            disabled={isRestrictedRole}
          >
            キャンセル
          </Button>
        </div>
        <div className={classes.footerRight}>
          <Button
            variant="contained"
            type="submit"
            className={classes.primaryButton}
            disabled={isRestrictedRole}
          >
            保存
          </Button>
        </div>
      </div>
    </form>
  )
}

export default withSnackbar(RoomForm)
