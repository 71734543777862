import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormLayout from 'components/common/FormLayout'
import Dropdown from 'components/common/Form/Dropdown'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Loading from 'components/common/Loading'
import DateLabel from 'components/common/DateLabel'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import API, { graphqlOperation } from '@aws-amplify/api'
import { setLoadData } from 'actions/Actions'
import * as mutations from 'graphql/mutations'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyle = makeStyles(theme => {
  return {
    dataset: {
      marginBottom: 8,
      '&:nth-child(n+2)': {
        marginTop: 8
      }
    },
    content: {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '14px'
    }
  }
})

const items = [
  {
    label: '更新日時',
    value: '4時間前'
  },
  {
    label: '物件名',
    value: 'エクセルハイツ赤坂'
  },
  {
    label: '更新日時',
    value: '4時間前'
  },
  {
    label: '更新日時',
    value: '4時間前'
  }
]

function Right(props) {
  const classes = useStyle()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const handleChange = event => {
    props.setLoadData(true)
    const params = { status: event.target.value, id: props.faq.faq.id }
    API.graphql(
      graphqlOperation(mutations.updateFaqAndAttachments, { input: params })
    )
      .then(response => {
        const errors = response.errors
        if (!errors) {
          props.enqueueSnackbar('状態を更新しました', {
            variant: 'success'
          })
          props.setLoadData(false)
        } else {
          console.log(JSON.stringify(errors))
          props.enqueueSnackbar('状態を更新できませんでした', {
            variant: 'error'
          })
          props.setLoadData(false)
        }
      })
      .catch(error => {
        const errors = error.errors
        console.log(JSON.stringify(errors))
        props.enqueueSnackbar('状態を更新できませんでした', {
          variant: 'error'
        })
        props.setLoadData(false)
      })
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#fff'
      }}
    >
      <div style={{ flex: 1, height: '100%' }}>
        <FormLayout title="記事の状態">
          {props.isLoading ? (
            <Loading isLoading={props.isLoading} />
          ) : (
            <>
              <Dropdown
                label="アプリ利用"
                name="status"
                onChange={handleChange}
                defaultValue={
                  props.faq && props.faq.faq ? props.faq.faq.status : 1
                }
                items={[
                  { value: 1, label: '利用中' },
                  { value: 2, label: '停止' },
                  { value: 3, label: '削除' }
                ]}
                color="primary"
                disabled={
                  !(props.faq && props.faq.faq && props.faq.faq.id) ||
                  isRestrictedRole
                }
              />
            </>
          )}
        </FormLayout>
        <Divider style={{ height: 5 }} />
        <FormLayout title="概要">
          {props.isLoading ? (
            <Loading isLoading={props.isLoading} />
          ) : (
            <>
              <React.Fragment key={1}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                  className={classes.dataset}
                >
                  <Grid item xs={4}>
                    <Typography variant="body1" className={classes.content}>
                      更新日時
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        align="right"
                        className={classes.content}
                      >
                        {props.faq && props.faq.faq && (
                          <DateLabel timestamp={props.faq.faq.updated_at} />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
              <React.Fragment key={2}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                  className={classes.dataset}
                >
                  <Grid item xs={4}>
                    <Typography variant="body1" className={classes.content}>
                      更新者
                    </Typography>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography
                      variant="body1"
                      align="right"
                      className={classes.content}
                    >
                      {props.faq.updated_by &&
                        getFormattedNameByStatus(
                          props.faq.updated_by.name,
                          props.faq.updated_by.status
                        )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
              <React.Fragment key={3}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                  className={classes.dataset}
                >
                  <Grid item xs={4}>
                    <Typography variant="body1" className={classes.content}>
                      参照数
                    </Typography>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography
                      variant="body1"
                      align="right"
                      className={classes.content}
                    >
                      {0}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            </>
          )}
        </FormLayout>
        <Divider style={{ marginTop: '-15px' }} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(Right)))
