import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'

import FormLayout from 'components/common/FormLayout'
import RowWithPdf from 'components/common/RowWithPdf'

import { Auth, Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const MAXIMUM_FILE_LENGTH = 1

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%',
    marginTop: 10
  }
}))

const assignAcceptType = type => {
  if (type === 'pdf') return 'application/pdf'
  if (type === 'video') return 'video/mp4,video/x-m4v,video/*'
  if (type === 'image') return 'image/*'
}

function FileForm({ type, label, ...props }) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const [images, setImages] = useState([])
  const [params, setParams] = useState([])
  const [isLoading2, setIsLoading2] = useState(false)

  const { isLoading, faqAttachment } = props

  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const object = {
        status: 1,
        attachment_type_id: 3,
        mime_type: file.type,
        filename: file.name,
        body: reader.result
      }

      let copy1 = []
      Object.assign(copy1, images)
      copy1.push(object)
      let copy2 = []
      Object.assign(copy2, params)
      copy2.push(file)

      setImages([...copy1])
      setParams([...copy2])
      const splicedParams = [
        ...copy2.filter(
          item =>
            item instanceof File ||
            (Object.keys(item).indexOf('status') !== -1 && item.status == 3)
        )
      ]
      props.handleChange({ pdfs: [...splicedParams] })
    }
    reader.readAsDataURL(file)
  }

  const handleClick = async (attachment, event) => {
    event.preventDefault()
    if (attachment && attachment.key) {
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        await Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            fetch(result).then(response => {
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = attachment.filename
                a.click()
              })
            })
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }

  const handleDelete = index => {
    let copy1 = []
    Object.assign(copy1, images)
    let target1 = copy1[index]
    target1.status = 3
    copy1.splice(index, 1, target1)
    let copy2 = []
    Object.assign(copy2, params)
    let target2 = copy2[index]
    if (target2 instanceof File) {
      copy2.splice(index, 1, {})
    } else {
      target2.status = 3
      copy2.splice(index, 1, target2)
    }

    setImages([...copy1])
    setParams([...copy2])
    const splicedParams = [
      ...copy2.filter(
        item =>
          item instanceof File ||
          (Object.keys(item).indexOf('status') !== -1 && item.status == 3)
      )
    ]
    props.handleChange({ pdfs: [...splicedParams] })
  }

  useEffect(() => {
    if (
      faqAttachment &&
      'pdf_attachment' in faqAttachment &&
      faqAttachment.pdf_attachment
    ) {
      setIsLoading2(true)
      const attachment = faqAttachment.pdf_attachment[0]
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        Storage.get(key, {
          level: 'protected',
          identityId: creds.params.User.CompanyId,
          expires: 60
        })
          .then(result => {
            const object = Object.assign({}, attachment, { body: result })
            setImages([object])
            setParams([object])
            setIsLoading2(false)
          })
          .catch(err => {
            console.log('error: ', err)
            setIsLoading2(false)
          })
      })
    } else {
      setImages([])
      setParams([])
    }
  }, [faqAttachment, setImages, setParams])

  return (
    <FormLayout title={label} caption={`※${label}は一つのみ添付可`}>
      {isLoading || isLoading2 ? (
        <Loading isLoading={isLoading || isLoading2} />
      ) : (
        <>
          {images.length > 0 &&
            images.map(({ status, filename, body }, index) => {
              return status == 1 ? (
                <RowWithPdf
                  key={index}
                  title={filename}
                  handleDelete={e => handleDelete(index)}
                  style={{ marginBottom: 10 }}
                  attachment={images[index]}
                  handleClick={handleClick}
                  disabledIcon={isRestrictedRole}
                ></RowWithPdf>
              ) : (
                <></>
              )
            })}
          {images.filter(item => item.status == 1).length <
            MAXIMUM_FILE_LENGTH && (
            <Grid container>
              <Grid item xs={12}>
                <input
                  accept="application/pdf"
                  className={classes.input}
                  id={`contained-button-${type}`}
                  type="file"
                  onChange={handleChange}
                  disabled={isRestrictedRole}
                />
              </Grid>
              <label
                htmlFor={`contained-button-${type}`}
                className={classes.labelButton}
              >
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                  disabled={isRestrictedRole}
                >
                  PDFを追加
                </Button>
              </label>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileForm)
