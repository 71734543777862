import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormLayout from 'components/common/FormLayout'
import Typography from '@material-ui/core/Typography'
import { FormControlLabel, Switch } from '@material-ui/core'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: 'unset'
  },
  switch: {
    '& .Mui-checked': {
      color: theme.palette.c_blue.main
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.c_blue.main
    }
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left'
  }
}))

function ForceAutoReplyButton(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { forceAutoReplyEnabled, setForceAutoReplyEnabled } = props

  const handleChangeSwitch = () => {
    setForceAutoReplyEnabled(!forceAutoReplyEnabled)
  }

  return (
    <>
      <Paper>
        <FormLayout title="即時自動返信" className={classes.form}>
          <Grid container xs={12} alignItems="center">
            <Grid item xs={4} alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={forceAutoReplyEnabled}
                    onChange={handleChangeSwitch}
                    className={classes.switch}
                    disabled={!isUnrestrictedRole}
                  />
                }
                label={
                  forceAutoReplyEnabled
                    ? '現在自動返信はONになっています。'
                    : '現在自動返信はOFFになっています。'
                }
              />
            </Grid>
            <Grid item xs={8} alignItems="center">
              <Typography variant="body1" className={classes.content}>
                ※以下の設定に関わらず、「自動返信ON」の場合は常に自動返信状態となります。
              </Typography>
            </Grid>
          </Grid>
        </FormLayout>
      </Paper>
    </>
  )
}

export default ForceAutoReplyButton
