import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(theme => {
  console.log()
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '50px',
      '& svg': {
        color: props =>
          props.color ? theme.palette[props.color].main : 'inherited'
      }
    },
    label: {
      fontSize: '10px',
      fontWeight: 'bold',
      color: props =>
        props.color ? theme.palette[props.color].main : 'inherited'
    }
  }
})

export default function IconWithText({ color, icon, text, ...props }) {
  const classes = useStyle({ color })
  return (
    <div className={classes.root} {...props}>
      {icon}
      <span className={classes.label} color={color}>
        {text}
      </span>
    </div>
  )
}
