import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListContent from './ListContent'

const useStyles = makeStyles({
  wrapBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '32px'
  }
})

const ListScreen = () => {
  const classes = useStyles()

  return (
    <div className={classes.wrapBody}>
      <ListContent />
    </div>
  )
}

export default ListScreen
