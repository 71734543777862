import React from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchTextField from './SearchTextField'

const useStyles = makeStyles(() => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  button: {
    marginLeft: '16px',
    color: '#fff'
  }
}))

const Body = props => {
  const classes = useStyles()
  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={() => props.history.goBack()}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>{props.title}</Typography>
      <SearchTextField text="オーナー名で検索" className={classes.textField} />
    </>
  )
}

export default withRouter(Body)
