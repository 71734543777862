import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setLoadData } from 'actions/Actions'
import { withSnackbar } from 'notistack'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import Grid from '@material-ui/core/Grid'
import Loading from 'components/common/Loading'
import ForceAutoReplyButton from './ForceAutoReplyButton'
import AutoReplyTimeAndWeek from './AutoReplyTimeAndWeek'
import CompanyHoliday from './CompanyHoliday'
import AutoReplyMessageBody from './AutoReplyMessageBody'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  wrapContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '32px'
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  }
}))

function EditScreen(props) {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [messageAutoReply, setMessageAutoReply] = useState({})
  const [forceAutoReplyEnabled, setForceAutoReplyEnabled] = useState(false)
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [messageBody, setMessageBody] = useState('')

  const [orgWeekState, setOrgWeekState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false
  })
  const [weekState, setWeekState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false
  })
  const [companyCloseWeekList, setCompanyCloseWeekList] = useState([])

  const [orgCompanyHolidayList, setOrgCompanyHolidayList] = useState([])
  const [companyHolidayList, setCompanyHolidayList] = useState([])
  const [deleteCompanyHolidayList, setDeleteCompanyHolidayList] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([
      fetchMessageAutoReply(),
      fetchCompanyCloseWeek(),
      fetchCompanyHoliday()
    ])
    setIsLoading(false)
  }

  async function fetchMessageAutoReply() {
    let result = await QueryHelper('getMessageAutoReply', {}, false)
    if (result.error) {
      props.enqueueSnackbar('自動返信設定情報が取得できませんでした', {
        variant: 'error'
      })
    } else {
      setMessageAutoReply(result)
      setStart(result.start)
      setEnd(result.end)
      setMessageBody(result.message_body)
      setForceAutoReplyEnabled(result.force_auto_reply)
    }
  }

  async function fetchCompanyCloseWeek() {
    let result = await QueryHelper(
      'getCompanyCloseWeekList',
      {
        filter: {},
        offset: 0,
        limit: 0
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('休業日が取得できませんでした', {
        variant: 'error'
      })
    } else {
      const week = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false
      }
      if (result.data && result.data.length > 0) {
        for (let i of result.data) {
          week[i.week_id] = true
        }
        setCompanyCloseWeekList(result.data)
      } else {
        setCompanyCloseWeekList([])
      }

      setWeekState(week)
      setOrgWeekState(week)
    }
  }

  async function fetchCompanyHoliday() {
    let result = await QueryHelper(
      'getCompanyHolidayList',
      {
        filter: {},
        offset: 0,
        limit: 0
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('特別休業日が取得できませんでした', {
        variant: 'error'
      })
    } else {
      if (result.data && result.data.length > 0) {
        setCompanyHolidayList(result.data)
        setOrgCompanyHolidayList(Array.from(result.data))
      } else {
        setCompanyHolidayList([])
        setOrgCompanyHolidayList([])
      }
    }
  }

  const handleCancel = async event => {
    setStart(messageAutoReply.start)
    setEnd(messageAutoReply.end)
    setMessageBody(messageAutoReply.message_body)
    setForceAutoReplyEnabled(messageAutoReply.force_auto_reply)
    setWeekState(orgWeekState)
    setCompanyHolidayList([...orgCompanyHolidayList])
    setDeleteCompanyHolidayList([])
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)

    const paramMessageAutoReply = {}
    if (messageAutoReply.id) {
      paramMessageAutoReply['id'] = messageAutoReply.id
    }
    paramMessageAutoReply['status'] = 1
    paramMessageAutoReply['force_auto_reply'] = forceAutoReplyEnabled
    paramMessageAutoReply['start'] = start
    paramMessageAutoReply['end'] = end
    paramMessageAutoReply['message_body'] = messageBody

    const paramInsertCompanyCloseWeekList = []
    const paramUpdateCompanyCloseWeekList = []
    Object.keys(weekState).map(v => {
      let isExists = false
      let isDelete = false
      if (companyCloseWeekList && companyCloseWeekList.length > 0) {
        for (let i of companyCloseWeekList) {
          if (Number(v) === Number(i.week_id)) {
            isExists = true
            if (!weekState[v]) {
              i.status = 3
              paramUpdateCompanyCloseWeekList.push(i)
              isDelete = true
            }
            break
          }
        }
      }
      // データが存在しない かつ 削除じゃない かつ true
      if (!isExists && !isDelete && weekState[v]) {
        paramInsertCompanyCloseWeekList.push({ status: 1, week_id: v })
      }
    })

    const paramInsertCompanyHolidayList = []
    let paramUpdateCompanyHolidayList = []
    for (let i of companyHolidayList) {
      if (i.id) {
        if (i.isChange) {
          delete i['isChange']
          paramUpdateCompanyHolidayList.push(i)
        }
      } else {
        delete i['isChange']
        paramInsertCompanyHolidayList.push(i)
      }
    }

    if (deleteCompanyHolidayList.length > 0) {
      paramUpdateCompanyHolidayList = paramUpdateCompanyHolidayList.concat(
        deleteCompanyHolidayList
      )
    }

    await Promise.all([
      paramMessageAutoReply['id']
        ? updateMessageAutoReply(paramMessageAutoReply)
        : insertMessageAutoReply(paramMessageAutoReply),
      paramInsertCompanyCloseWeekList.length > 0
        ? insertAllCompanyCloseWeek(paramInsertCompanyCloseWeekList)
        : '',
      paramUpdateCompanyCloseWeekList.length > 0
        ? updateAllCompanyCloseWeek(paramUpdateCompanyCloseWeekList)
        : '',
      paramInsertCompanyHolidayList.length > 0
        ? insertAllCompanyHolidayList(paramInsertCompanyHolidayList)
        : '',
      paramUpdateCompanyHolidayList.length > 0
        ? updateAllCompanyHolidayList(paramUpdateCompanyHolidayList)
        : ''
    ])

    await fetchData()
    setIsLoading(false)
  }

  const insertMessageAutoReply = async params => {
    const result = await MutationHelper('createMessageAutoReply', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('保存に失敗しました', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
  }

  const updateMessageAutoReply = async params => {
    const result = await MutationHelper('updateMessageAutoReply', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('保存に失敗しました', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
  }

  const insertAllCompanyCloseWeek = async params => {
    const result = await MutationHelper('createAllCompanyCloseWeek', {
      input: { companyCloseWeeks: params }
    })
    if (result.error) {
      props.enqueueSnackbar('休業日の保存に失敗しました', {
        variant: 'error'
      })
    } else {
    }
  }

  const updateAllCompanyCloseWeek = async params => {
    const result = await MutationHelper('updateAllCompanyCloseWeek', {
      input: { companyCloseWeeks: params }
    })
    if (result.error) {
      props.enqueueSnackbar('休業日の保存に失敗しました', {
        variant: 'error'
      })
    } else {
    }
  }

  const insertAllCompanyHolidayList = async params => {
    const result = await MutationHelper('createAllCompanyHoliday', {
      input: { companyHolidays: params }
    })
    if (result.error) {
      props.enqueueSnackbar('特別休業日の保存に失敗しました', {
        variant: 'error'
      })
    } else {
    }
  }

  const updateAllCompanyHolidayList = async params => {
    const result = await MutationHelper('updateAllCompanyHoliday', {
      input: { companyHolidays: params }
    })
    if (result.error) {
      props.enqueueSnackbar('特別休業日の保存に失敗しました', {
        variant: 'error'
      })
    } else {
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className={classes.wrapContent}>
          {isLoading ? (
            <Loading isLoading={isLoading} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ForceAutoReplyButton
                  forceAutoReplyEnabled={forceAutoReplyEnabled}
                  setForceAutoReplyEnabled={setForceAutoReplyEnabled}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoReplyTimeAndWeek
                  start={start}
                  setStart={setStart}
                  end={end}
                  setEnd={setEnd}
                  weekState={weekState}
                  setWeekState={setWeekState}
                />
              </Grid>
              <Grid item xs={12}>
                <CompanyHoliday
                  companyHolidayList={companyHolidayList}
                  setCompanyHolidayList={setCompanyHolidayList}
                  deleteCompanyHolidayList={deleteCompanyHolidayList}
                  setDeleteCompanyHolidayList={setDeleteCompanyHolidayList}
                />
              </Grid>

              <Grid item xs={12}>
                <AutoReplyMessageBody
                  messageBody={messageBody}
                  setMessageBody={setMessageBody}
                  handleCancel={handleCancel}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </form>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditScreen))
