import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BuildingDetailContent from './BuildingDetailContent'
import BulletinBoardListContent from './BulletinBoardListContent'
import RoomListContent from './RoomListContent'
import BuildingForm from './BuildingForm'
import BuildingFileForm from 'containers/management/Building/Detail/BuildingFileForm'
import OwnerListContent from './OwnerListContent'
import { useOwnerFunctionEnabled } from 'hooks/useOwnerFunctionEnabled'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left(props) {
  const classes = useStyles()
  const { isOwnerFunctionEnabled } = useOwnerFunctionEnabled()
  const { role12 } = useRole()

  const {
    isLoading,
    building,
    knrDivList,
    knrkeitaiDivList,
    buildingAttachments,
    setTitleError,
    titleError,
    buildingOwnerList,
    fetchOwnerList
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <BuildingDetailContent
          isLoading={isLoading}
          building={building}
          knrDivList={knrDivList}
          knrkeitaiDivList={knrkeitaiDivList}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <BulletinBoardListContent isLoading={isLoading} building={building} />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <RoomListContent isLoading={isLoading} building={building} />
      </div>
      <div className={classes.divider} />
      {isOwnerFunctionEnabled && role12() && (
        <>
          <div className={classes.body}>
            <OwnerListContent
              isLoading={isLoading}
              building={building}
              buildingOwnerList={buildingOwnerList}
              fetchOwnerList={fetchOwnerList}
            />
          </div>
          <div className={classes.divider} />
        </>
      )}
      <div className={classes.bottom}>
        <BuildingFileForm
          attachment={buildingAttachments}
          maxFileLength={20}
          title={'建物ドキュメント管理'}
          subTitle={'※最大20ファイル'}
          setTitleError={setTitleError}
          titleError={titleError}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.bottom}>
        <BuildingForm isLoading={isLoading} building={building} />
      </div>
    </div>
  )
}

export default Left
