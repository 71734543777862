import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'

function SearchFormContent(props) {
  const { searchCondition, masterData } = props

  const [tysList, setTysList] = useState([])
  const [prefectureList, setPrefectureList] = useState([])
  const [cityList, setCityList] = useState([
    {
      label: '都道府県を選択してください',
      value: ''
    }
  ])
  const [knrDivList, setKnrDivList] = useState([])
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState([])

  const handleChange = event => {
    if (event.target.value) {
      fetchCityList(event.target.value)
    } else {
      setCityList([])
    }
  }

  useEffect(() => {
    fetchData()
  }, [
    setTysList,
    setPrefectureList,
    setKnrDivList,
    setKnrkeitaiDivList,
    searchCondition
  ])

  const fetchData = async () => {
    await Promise.all([
      fetchPrefectureList(),
      fetchMstTateyaTysList(),
      fetchgetMstTateyaKnrDivList(),
      fetchgetMstTateyaKnrkeitaiDivList(),
      searchCondition && 'setting.building.TTY_PRF_CD' in searchCondition
        ? fetchCityList(searchCondition['setting.building.TTY_PRF_CD'])
        : ''
    ])
  }

  async function fetchPrefectureList() {
    API.graphql(graphqlOperation(queries.getPrefectureList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getPrefectureList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.prf_cd, label: item.name })
          })
          setPrefectureList(l)
        } else {
          props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchCityList(prfCd) {
    API.graphql(
      graphqlOperation(queries.getCityList, {
        filter: { prf_cd: { eq: prfCd } }
      })
    )
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getCityList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.cty_cd, label: item.name })
          })
          setCityList(l)
        } else {
          props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchMstTateyaTysList() {
    API.graphql(graphqlOperation(queries.getMSTTateyaTysList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaTysList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.tys_cd, label: item.tys_name })
          })
          setTysList(l)
        } else {
          props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchgetMstTateyaKnrDivList() {
    API.graphql(graphqlOperation(queries.getMSTTateyaKNRDivList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaKNRDivList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.knr_cd, label: item.knr_name })
          })
          setKnrDivList(l)
        } else {
          props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchgetMstTateyaKnrkeitaiDivList() {
    API.graphql(graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaKnrkeitaiDivList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.knrkeitai_cd, label: item.knrkeitai_name })
          })
          setKnrkeitaiDivList(l)
        } else {
          props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextInput
          name="setting.building.TTY_NAME"
          label="物件名(フリガナ)"
          defaultValue={
            'setting.building.TTY_NAME' in searchCondition
              ? searchCondition['setting.building.TTY_NAME']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="setting.building.TTY_ID"
          label="ID"
          defaultValue={
            'setting.building.TTY_ID' in searchCondition
              ? searchCondition['setting.building.TTY_ID']
              : ''
          }
          fullWidth
          variant="filled"
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Dropdown
            name="setting.building.TTY_TYS_CD"
            label="種別"
            defaultValue={
              'setting.building.TTY_TYS_CD' in searchCondition
                ? searchCondition['setting.building.TTY_TYS_CD']
                : ''
            }
            items={tysList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="setting.building.TTY_PRF_CD"
            label="都道府県"
            defaultValue={
              'setting.building.TTY_PRF_CD' in searchCondition
                ? searchCondition['setting.building.TTY_PRF_CD']
                : ''
            }
            onChange={handleChange}
            items={prefectureList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="setting.building.TTY_CTY_CD"
            label="市区町村"
            defaultValue={
              'setting.building.TTY_CTY_CD' in searchCondition
                ? searchCondition['setting.building.TTY_CTY_CD']
                : ''
            }
            items={cityList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="setting.building.TTY_KNR_DIV"
            label="管理区分"
            defaultValue={
              'setting.building.TTY_KNR_DIV' in searchCondition
                ? searchCondition['setting.building.TTY_KNR_DIV']
                : ''
            }
            items={knrDivList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="setting.building.TTY_KNRKEITAI_DIV"
            label="管理形態"
            defaultValue={
              'setting.building.TTY_KNRKEITAI_DIV' in searchCondition
                ? searchCondition['setting.building.TTY_KNRKEITAI_DIV']
                : ''
            }
            items={knrkeitaiDivList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          name={'setting.building.TTY_KNRPATTERN_ID'}
          label={'管理パターン'}
          defaultValue={
            searchCondition &&
            'setting.building.TTY_KNRPATTERN_ID' in searchCondition
              ? searchCondition['setting.building.TTY_KNRPATTERN_ID']
              : ''
          }
          items={masterData.ttyKnrPatternList}
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          name={'setting.building.TTY_KNR_TANTO_SHN_ID'}
          label={'管理担当者'}
          defaultValue={
            searchCondition &&
            'setting.building.TTY_KNR_TANTO_SHN_ID' in searchCondition
              ? searchCondition['setting.building.TTY_KNR_TANTO_SHN_ID']
              : ''
          }
          items={masterData.ttyKnrTantoList}
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          name={'setting.building.TTY_TNP_ID'}
          label={'建物取扱店舗'}
          defaultValue={
            searchCondition && 'setting.building.TTY_TNP_ID' in searchCondition
              ? searchCondition['setting.building.TTY_TNP_ID']
              : ''
          }
          items={masterData.ttyTnpList}
        />
      </Grid>
    </Grid>
  )
}

export default withSnackbar(SearchFormContent)
