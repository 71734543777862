import React, { useEffect, useState } from 'react'
import { MutationHelper } from 'utils/api.utils'
import { makeStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DateLabel3 from 'components/common/DateLabel3'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  lastUpdated: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#72809D'
  },
  lastUpdatedTitle: {
    float: 'left',
    marginLeft: '20px',
    marginRight: '10px'
  },
  inputForm: {
    padding: '20px'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.lightest
    }
  },
  subButton: {
    color: theme.palette.c_black.main,
    backgroundColor: theme.palette.c_white.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.lightest
    }
  },
  wrapperButton: {
    justifyContent: 'flex-end'
  }
}))

function formatDateLabel(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr)
    return <DateLabel3 date={date} color={'#72809D'} />
  }
  return ''
}

function CustomerRemarkForm(props) {
  const classes = useStyles()
  const [remark, setRemark] = useState(props.customer.remark)
  const [orgRemark, setOrgRemark] = useState(props.customer.remark)
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  useEffect(() => {
    if (props.customer) {
      const customer = props.customer
      setRemark(customer.remark)
      setOrgRemark(customer.remark)
    }
  }, [props])

  const handleChangeRemark = event => {
    setRemark(event.target.value)
  }

  const handleCancel = event => {
    setRemark(orgRemark)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const params = { id: props.customer.id, remark: remark }
    const result = await MutationHelper('updateCustomer', { input: params })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.inputForm}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput
              id="remark"
              name="remark"
              label="備考を記載"
              fullWidth
              multiline={true}
              rows={8}
              autoComplete="remark"
              variant="filled"
              defaultValue={remark}
              value={remark}
              onChange={handleChangeRemark}
              disabled={isRestrictedRole}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={3} className={classes.wrapperButton}>
          {props.knrKeiyaku &&
            (props.knrKeiyaku.KYK_UPDATED_DATE ||
              props.knrKeiyaku.KYK_INSERTED_DATE) && (
              <Grid className={classes.lastUpdated} item xs={8}>
                <Grid item className={classes.lastUpdatedTitle}>
                  最終更新
                </Grid>
                {props.knrKeiyaku.KYK_UPDATED_DATE
                  ? formatDateLabel(props.knrKeiyaku.KYK_UPDATED_DATE)
                  : formatDateLabel(props.knrKeiyaku.KYK_INSERTED_DATE)}
              </Grid>
            )}
          <Grid item xs={2}>
            <Button
              color="primary"
              variant="contained"
              className={classes.subButton}
              onClick={handleCancel}
              disabled={isRestrictedRole}
            >
              キャンセル
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={isRestrictedRole}
            >
              保存
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  )
}

export default withSnackbar(CustomerRemarkForm)
