import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  subTitle: {
    marginLeft: '10px',
    height: '18px',
    color: '#72809D',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

function FormLayout({ title, subTitle, children, ...props }) {
  const classes = useStyles()
  return (
    <div className={classes.root} {...props}>
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
          {subTitle && (
            <Grid>
              <Typography className={classes.subTitle}>{subTitle}</Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <Divider />
      {children && <div className={classes.section}>{children}</div>}
    </div>
  )
}

export default FormLayout
