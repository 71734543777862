// statusによって(停止)または(削除済)の表示をする関数
export function getFormattedNameByStatus(name, status) {
  switch (status) {
    case 2:
      return `${name}(停止)`
    case 3:
      return `${name}(削除済)`
    default:
      return name
  }
}

export const getFormattedNameByEnumStatus = (name, status) => {
  switch (status) {
    case 'INACTIVE':
      return `${name}(停止)`
    case 'DELETED':
      return `${name}(削除済)`
    default:
      return name
  }
}
