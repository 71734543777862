import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyle = makeStyles(theme => {
  return {
    label: {
      fontSize: props => (props.fontSize ? props.fontSize : '18px'),
      color: props =>
        props.color === 'primary' ? theme.palette.c_primary.lighter : ''
    },
    deactivatedItemLabel: {
      position: 'absolute',
      left: '4%',
      top: '45%',
      fontSize: props => (props.itemFontSize ? props.itemFontSize : '16px'),
      color: props =>
        props.color === 'primary' ? theme.palette.c_primary.main : 'inherit',
      fontWeight: 'bold',
      pointerEvents: 'none',
      zIndex: '1'
    },
    select: {
      fontWeight: 'bold',
      color: props =>
        props.color === 'primary' ? theme.palette.c_primary.main : 'inherit',
      backgroundColor: props => props.backgroundColor
    },
    item: {
      fontSize: props => (props.itemFontSize ? props.itemFontSize : '16px')
    }
  }
})
export default function Dropdown({
  name,
  label,
  defaultValue = '',
  onChange = () => {},
  items,
  deactivatedItem,
  color = '',
  backgroundColor = '',
  fontSize = '',
  itemFontSize = '',
  readOnly = false,
  disabled = false,
  required = false,
  hasError = false,
  errorMessage = '',
  size,
  ...props
}) {
  const classes = useStyle({ color, backgroundColor, fontSize, itemFontSize })
  const [selectedVal, setSelectedVal] = React.useState('')

  const handleSelectedItem = e => {
    onChange(e)
    setSelectedVal(e.target.value)
  }

  const deactivatedItemLabel = deactivatedItem ? (
    <label className={classes.deactivatedItemLabel}>{deactivatedItem}</label>
  ) : null
  React.useEffect(() => {
    setSelectedVal(defaultValue)
  }, [defaultValue])

  return (
    <FormControl
      variant="filled"
      fullWidth
      required={required}
      error={hasError}
      disabled={disabled}
      size={size}
    >
      <InputLabel htmlFor={`${name}-select`} className={classes.label}>
        {label}
      </InputLabel>
      {deactivatedItemLabel}
      <Select
        {...props}
        variant="filled"
        onChange={handleSelectedItem}
        className={classes.select}
        value={selectedVal}
        inputProps={{
          name: name,
          id: `${name}-select`,
          readOnly: readOnly
        }}
      >
        {items &&
          items.map(({ value, label }) => (
            <MenuItem value={value} key={value} className={classes.item}>
              <div className={classes.item}>{label}</div>
            </MenuItem>
          ))}
      </Select>
      {hasError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}
