import React, { useEffect, useState } from 'react'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import DetailLayout from 'components/common/DetailLayout'
import ContractList from './ContractList'

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: 5, page: 1 }
}

function ContractListContent(props) {
  const { room } = props
  // console.log(JSON.stringify(room));

  const [contractList, setContractList] = useState(initialState)

  // const fetchContractList = async (hyaId) => {
  //   const params = {
  //       "KYK_HYA_ID": {
  //           "eq": hyaId,
  //       }
  //   };
  const fetchContractList = async id => {
    const params = {
      room_id: {
        eq: id
      }
    }
    // console.log(JSON.stringify(params));
    const result = await QueryHelper('getContractList2', { filter: params })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`入居者一覧を取得できませんでした。`)
    } else {
      result.data.sort((a, b) => {
        if (a.knr_keiyaku.KYK_KEIYAKU_DATE > b.knr_keiyaku.KYK_KEIYAKU_DATE)
          return 1
        if (a.knr_keiyaku.KYK_KEIYAKU_DATE < b.knr_keiyaku.KYK_KEIYAKU_DATE)
          return -1
      })
      setContractList(result)
    }
  }

  useEffect(() => {
    if ('room' in room && room.room) {
      fetchContractList(room.room.id)
    }
  }, [room, setContractList])

  return (
    <DetailLayout
      title="入居者一覧"
      children={
        <ContractList
          contractList={contractList.data}
          paging={contractList.paging}
        />
      }
    />
  )
}

export default withSnackbar(ContractListContent)
