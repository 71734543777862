import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Thumbnail from 'components/common/Thumbnail'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(n+2)': {
      marginTop: 10
    }
  },
  imageRow: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 'auto',
    marginLeft: 20,
    fontWeight: 'bold',
    fontSize: '16px'
  }
}))

export default function RowWithFile({
  image,
  title,
  handleDelete,
  isDeleteButtonView = true,
  thumbnailWidth = null,
  thumbnailHeight = null,
  disabledIcon
}) {
  const classes = useStyles()
  return (
    <Grid container className={classes.imageContainer}>
      <Grid xs={11} item>
        <Grid container className={classes.imageRow}>
          <Grid item>
            <Thumbnail
              image={image}
              title={title}
              thumbnailWidth={thumbnailWidth}
              thumbnailHeight={thumbnailHeight}
            />
          </Grid>
          <Grid item xs>
            <span className={classes.title} style={{}}>
              {title}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {isDeleteButtonView && (
        <Grid xs={1} item>
          <Grid container justify="flex-end">
            <Grid item zeroMinWidth>
              <IconButton
                aria-label="削除"
                color="primary"
                onClick={handleDelete}
                disabled={disabledIcon}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
