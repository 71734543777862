import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog, setLoadData } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { MutationHelper } from 'utils/api.utils'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormFooter from './FormFooter'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { FinancialInstitutionType } from 'utils/enum'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  left: {
    flex: 1,
    display: 'flex'
  },
  textRight: {
    textAlign: 'right'
  },
  textLeft: {
    textAlign: 'left'
  },
  body: {},

  center: {
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  formControl: {
    width: '100%'
  },
  centerGray: {
    textAlign: 'center',
    color: 'gray'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '15px 20px'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function FinancialInstitutionFormContent(props) {
  const classes = useStyles()
  const content =
    props.application && props.application.content
      ? parseJson(props.application.content)
      : {}

  const [financialInstitution, setFinancialInstitution] = useState({})

  const orgFinancialInstitutionName =
    content &&
    content.financialInstitution &&
    content.financialInstitution.financialInstitutionName
      ? content.financialInstitution.financialInstitutionName
      : null
  const [
    financialInstitutionName,
    setFinancialInstitutionName
  ] = React.useState(orgFinancialInstitutionName)
  const orgStoreName =
    content &&
    content.financialInstitution &&
    content.financialInstitution.storeName
      ? content.financialInstitution.storeName
      : null
  const [storeName, setStoreName] = React.useState(orgStoreName)
  const orgTypeId =
    content &&
    content.financialInstitution &&
    content.financialInstitution.typeId
      ? content.financialInstitution.typeId
      : null
  const [typeId, setTypeId] = React.useState(orgTypeId)
  const handleChangeTypeId = event => {
    setTypeId(event.target.value)
    setFinancialInstitution(
      Object.assign({}, financialInstitution, {
        [event.target.name]: event.target.value
      })
    )
  }

  const orgNumber =
    content &&
    content.financialInstitution &&
    content.financialInstitution.number
      ? content.financialInstitution.number
      : null
  const [number, setNumber] = React.useState(orgNumber)
  const orgAccountName =
    content &&
    content.financialInstitution &&
    content.financialInstitution.accountName
      ? content.financialInstitution.accountName
      : null
  const [accountName, setAccountName] = React.useState(orgAccountName)
  const handleChange = event => {
    setFinancialInstitution(
      Object.assign({}, financialInstitution, {
        [event.target.name]: event.target.value
      })
    )
  }

  const handleSubmit = async event => {
    event.preventDefault()
    props.closeModalDialog()
    props.setLoadData(true)
    const params = { id: props.application.id }
    if (Object.keys(financialInstitution).length > 0) {
      params['financialInstitution'] = financialInstitution
    }
    const result = await MutationHelper(
      'updateResidentCancellationApplication',
      { input: params }
    )
    if (result.error) {
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      await props.fetchResidentCancellationApplication(props.application.id)
      setFinancialInstitution({})
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
    props.setLoadData(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={4} xs>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <TextField
              id="financialInstitutionName"
              name="financialInstitutionName"
              label="金融機関名"
              defaultValue={financialInstitutionName}
              fullWidth
              required
              variant="filled"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2} sm={6} spacing={2} className={classes.left}>
            <TextField
              id="storeName"
              name="storeName"
              label="店名"
              defaultValue={storeName}
              fullWidth
              required
              variant="filled"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} xs>
          <Grid item xs={6} sm={6} spacing={2} className={classes.left}>
            <FormControl
              required
              variant="filled"
              className={classes.formControl}
            >
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                口座種別
              </InputLabel>
              <Select
                labelId="typeId"
                id="typeId"
                name="typeId"
                value={typeId}
                onChange={handleChangeTypeId}
                className={classes.selectEmpty}
              >
                <MenuItem value={FinancialInstitutionType.savings.code}>
                  {FinancialInstitutionType.savings.value}
                </MenuItem>
                <MenuItem value={FinancialInstitutionType.current.code}>
                  {FinancialInstitutionType.current.value}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={12} spacing={2} className={classes.left}>
            <TextField
              id="number"
              name="number"
              label="口座番号"
              defaultValue={number}
              fullWidth
              required
              variant="filled"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} xs>
          <Grid item xs={6} sm={12} spacing={4} className={classes.left}>
            <TextField
              id="accountName"
              name="accountName"
              label="口座名義"
              defaultValue={accountName}
              fullWidth
              required
              variant="filled"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.footerWrapper}>
        <FormFooter />
      </DialogActions>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FinancialInstitutionFormContent))
