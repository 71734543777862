import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import RichTextEditor from 'components/General/RichTextEditor'
import AttachmentForm from 'components/General/AttachmentForm'
import { useFormContext } from 'react-hook-form'
import { useRole } from 'hooks/useRole'
import { Note } from 'utils/note'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '10px 0'
  },
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  subTitle: {
    marginLeft: '10px',
    height: '18px',
    color: '#72809D',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

const ApplicationOptionsPrecautionsSettings = props => {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()
  const { attachments, setCheckError, checkError } = props
  const { register, setValue, watch, formState } = useFormContext()
  const handleChange = event => {
    setValue(event.target.name, event.target.value)
  }

  return (
    <FormLayout title={'申請の注意事項設定'}>
      <Grid container spacing={3} alignItems="center">
        {props.name ? (
          <>
            <Grid container alignItems="center" className={classes.section}>
              <Grid>
                <Typography
                  className={classes.title}
                >{`${props.name}の流れについて`}</Typography>
              </Grid>
              <Grid>
                <Typography
                  className={classes.subTitle}
                >{`※${props.name}前の画面に表示されます。${Note.RichTextLinkAdditional}`}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                id="cancellation_body"
                maxLength={1000}
                placeholder="本文（1000文字まで）"
                defaultValue={watch('cancellation_body')}
                error={
                  formState.errors.cancellation_body
                    ? formState.errors.cancellation_body.message
                    : null
                }
                setCheckError={setCheckError}
                checkError={checkError}
                readOnly={!isUnrestrictedRole}
              />
            </Grid>
          </>
        ) : null}
        <Grid container alignItems="center" className={classes.section}>
          <Grid>
            <Typography className={classes.title}>
              {'申請の注意事項'}
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.subTitle}>
              {`※申請前の画面に表示されます。${Note.RichTextLinkAdditional}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            id="caution_body"
            maxLength={1000}
            placeholder="本文（1000文字まで）"
            defaultValue={watch('caution_body')}
            error={
              formState.errors.caution_body
                ? formState.errors.caution_body.message
                : null
            }
            setCheckError={setCheckError}
            checkError={checkError}
            readOnly={!isUnrestrictedRole}
          />
        </Grid>
        <Grid container alignItems="center" className={classes.section}>
          <Grid>
            <Typography className={classes.title}>{'参照先URL'}</Typography>
          </Grid>
          <Grid>
            <Typography className={classes.subTitle}>
              {'※参照先のURLがある場合は入力してください'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <TextInput
            {...register('caution_url_text')}
            error={Boolean(formState.errors.caution_url_text)}
            helperText={
              formState.errors.caution_url_text &&
              formState.errors.caution_url_text.message
            }
            id="caution_url_text"
            name="caution_url_text"
            label={
              <Typography className={classes.urlMessage}>
                {'URLに表示するテキスト'}
              </Typography>
            }
            fullWidth
            variant="filled"
            defaultValue={watch('caution_url_text')}
            onChange={handleChange}
            size="small"
            disabled={!isUnrestrictedRole}
          />
        </Grid>
        <Grid item xs={7}>
          <TextInput
            {...register('caution_url')}
            error={'caution_url' in formState.errors}
            helperText={
              formState.errors.caution_url &&
              formState.errors.caution_url.message
            }
            id="caution_url"
            name="caution_url"
            label={
              <Typography className={classes.urlMessage}>
                {'遷移先のURLがある場合はこちらに入力'}
              </Typography>
            }
            fullWidth
            variant="filled"
            defaultValue={watch('caution_url')}
            onChange={handleChange}
            size="small"
            disabled={!isUnrestrictedRole}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>{'添付PDF'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <AttachmentForm
            attachment={attachments}
            attachmentTypeID={3}
            maxFileLength={3}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ApplicationOptionsPrecautionsSettings
