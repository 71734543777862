import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setAdmin } from 'actions/Actions'

const AuthAdmin = props => {
  const [isAdmin, setIsAdmin] = useState(false)
  useEffect(() => {
    if (props.isAdmin) {
      return
    }
    let item = localStorage.getItem('totono-advertiser-session')
    if (!item) {
      item = sessionStorage.getItem('totono-advertiser-session')
    }
    if (
      !item ||
      item.is_administrator === undefined ||
      item.is_administrator === null
    ) {
      return
    }
    props.setAdmin(item.is_administrator === true)
    setIsAdmin(item.is_administrator === true)
  }, [isAdmin])

  console.log(props.isAdmin, isAdmin)

  if (props.isAdmin === true || isAdmin === true) {
    return props.children
  }

  return null
}

const mapStateToProps = state => {
  return {
    isAdmin: state.admin.isAdmin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAdmin: isAdmin => {
      dispatch(setAdmin(isAdmin))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthAdmin)
