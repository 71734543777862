import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ResidentInfoChangeApplicationDetail from './ResidentInfoChangeApplicationDetail'
import ResidentInfoChangeApplicationContent from './ResidentInfoChangeApplicationContent'
import ResidentInfoChangeApplicationReasonContent from './ResidentInfoChangeApplicationReasonContent'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  topWrapper: {
    flex: 1,
    display: 'flex'
  },
  top: {
    width: '320px',
    marginLeft: '32px',
    marginRight: '32px',
    backgroundColor: '#fff'
  },
  top2: {
    width: '320px',
    backgroundColor: '#fff'
  },
  formRow: {
    flex: 1,
    '&:nth-child(n+2)': {
      marginTop: 20
    }
  },
  body: {
    flex: 1,
    marginLeft: '32px',
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left({ isLoading, application, applicant, attachment }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper}>
        <Paper className={classes.top}>
          <ResidentInfoChangeApplicationDetail
            isLoading={isLoading}
            application={application}
            applicant={applicant}
          />
        </Paper>
        <Paper className={classes.top2}>
          <ResidentInfoChangeApplicationContent
            isLoading={isLoading}
            application={application}
          />
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <ResidentInfoChangeApplicationReasonContent
          isLoading={isLoading}
          application={application}
          attachments={attachment}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.bottom}></div>
    </div>
  )
}

export default Left
