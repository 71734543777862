import FormModalDialog from './FormModalDialog'
import CustomerHistoryMessageForm from './CustomerHistoryMessageForm'
import React from 'react'

const CustomerHistoryMessageDialogForm = ({
  open,
  handleClose,
  fetchCustomerHistoryThreadList,
  customerHistoryThread,
  offset,
  customerId
}) => {
  return (
    <FormModalDialog
      isOpen={open}
      handleClose={handleClose}
      title={'ステータス詳細'}
      body={
        <CustomerHistoryMessageForm
          handleClose={handleClose}
          fetchCustomerHistoryThreadList={fetchCustomerHistoryThreadList}
          customerHistoryThread={customerHistoryThread}
          offset={offset}
          customerId={customerId}
        />
      }
    />
  )
}

export default CustomerHistoryMessageDialogForm
