import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles' // 追加

const useStyles = makeStyles(theme => {
  return {
    loading: {
      textAlign: 'center',
      marginTop: props => (props.marginTop ? props.marginTop : '0'),
      marginBottom: props => (props.marginBottom ? props.marginBottom : '0')
    }
  }
})

function Loading(props) {
  const classes = useStyles(props)
  const { isLoading } = props

  return (
    <>
      {isLoading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </>
  )
}

export default Loading
