import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { formatDate } from 'utils/date.utils'
import Divider from '@material-ui/core/Divider'
import FormLayout from 'components/common/FormLayout'
import Dropdown from 'components/common/Form/Dropdown'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import FormModalDialog from './FormModalDialog'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { setLoadData } from 'actions/Actions'
import Loading from 'components/common/Loading'
import { PackageEnabled } from 'utils/enum'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  },
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  divider: {
    height: '8px',
    backgroundColor: '#f5f5f5'
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  }
}))

function Right(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const {
    isLoading,
    packageData,
    setLoadData,
    setStatus,
    isDisplayTop,
    setIsDisplayTop
  } = props
  const [packageDataList, setPackageDataList] = useState(null)
  const [topPageDisplaySize, setTopPageDisplaySize] = useState(null)
  const [availableSlotSize, setAvailableSlotSize] = useState(null)
  const [parameters, setParameters] = useState({})
  const [packageStatusList] = useState([
    { value: 1, label: '利用中' },
    { value: 2, label: '未使用' }
  ])
  const [packageStatus, setPackageStatus] = useState(
    'package' in packageData && packageData.package
      ? packageData.package.status
      : PackageEnabled.disabled.code
  )
  const [isOpen, setOpen] = useState(false)

  const handleChange = event => {
    if (!packageData || !packageData.package || !packageData.package.id) {
      setStatus(event.target.value)
      return
    }
    setPackageStatus(event.target.value)
    setParameters({
      [event.target.name]: event.target.value
    })
    setOpen(true)
  }

  const handleCancel = async () => {
    setPackageStatus(packageStatus == 1 ? 2 : 1)
    setParameters({ status: packageStatus })
    setOpen(false)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (topPageDisplaySize === 0 && isDisplayTop) {
      props.enqueueSnackbar('本環境はオプションサービス未加入です', {
        variant: 'error'
      })
      setLoadData(false)
      return
    }

    let currentTopDisplayCount = packageDataList.filter(
      item => item.package.status === 1 && item.package.is_display_top
    ).length
    if (isDisplayTop) {
      currentTopDisplayCount++
    }
    if (
      currentTopDisplayCount > topPageDisplaySize &&
      parameters.status === 1
    ) {
      props.enqueueSnackbar('TOPページ表示が既に上限数登録されています。', {
        variant: 'error'
      })
      setLoadData(false)
      return
    }

    let currentUsingNumber = packageDataList.filter(
      item => item.package.status === 1
    ).length
    if (parameters.status === 1) {
      currentUsingNumber++
    }
    if (parameters.status === 1 && currentUsingNumber > availableSlotSize) {
      props.enqueueSnackbar('アプリ表示可能数が上限に達しています。', {
        variant: 'error'
      })
      setLoadData(false)
      return
    }

    if (Object.keys(parameters).length > 0) {
      setLoadData(true)
      const params = Object.assign({}, parameters)
      if ('package' in packageData && packageData.package) {
        params['id'] = packageData.package.id
        await putPackageData(params)
        setLoadData(false)
      }
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
    }
  }

  const fetchPackageList = async () => {
    let result = await QueryHelper('getPackageList', {
      filter: {},
      offset: 0,
      limit: 0
    })
    if (!result.error) {
      setPackageDataList(result.data)
      setTopPageDisplaySize(result.package_size.top_page_display_size)
      setAvailableSlotSize(result.package_size.available_slot_size)
    } else {
      props.enqueueSnackbar(`入居者サービスを取得できませんでした。`)
    }
  }

  const putPackageData = async params => {
    setOpen(false)
    setLoadData(true)
    const result = await MutationHelper('updatePackage', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('入居者サービスを更新できませんでした。', {
        variant: 'error'
      })
    } else {
      setParameters({})
      setLoadData(false)
      props.enqueueSnackbar('入居者サービスを更新しました。', {
        variant: 'success'
      })
    }
    fetchPackageList()
    setIsDisplayTop(result.is_display_top)
    setStatus(result.status)
  }

  useEffect(() => {
    if (packageData && 'package' in packageData && packageData.package) {
      setPackageStatus(packageData.package.status)
    }
    fetchPackageList()
  }, [packageData])

  return (
    <div className={classes.root}>
      <FormLayout title="サービスの状態">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            <Dropdown
              label="アプリ利用"
              name="status"
              onChange={handleChange}
              defaultValue={packageStatus}
              // defaultValue={"package" in packageData && packageData.package ? packageData.package.status : 1}
              items={packageStatusList}
              color="primary"
              disabled={!isUnrestrictedRole}
            />
          </>
        )}
      </FormLayout>
      <Divider className={classes.divider} />
      <FormLayout title="概要">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  更新日時
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="right"
                    className={classes.content}
                  >
                    {'package' in packageData &&
                      packageData.package &&
                      formatDate({
                        type: 'hmi',
                        value: packageData.package.updated_at
                      })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="right"
                    className={classes.content}
                  >
                    {'package' in packageData &&
                      packageData.package &&
                      formatDate({
                        type: 'ago',
                        value: packageData.package.updated_at
                      })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className={classes.content}>
                  更新者
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  align="right"
                  className={classes.content}
                >
                  {packageData.updated_by &&
                    getFormattedNameByStatus(
                      packageData.updated_by.name,
                      packageData.updated_by.status
                    )}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </FormLayout>
      <Divider />
      <FormModalDialog
        open={isOpen}
        title="サービス状態の更新"
        description={
          packageStatus == 2
            ? '※更新すると、アプリ上で非表示になります。'
            : '※更新すると、アプリ上で表示になります。'
        }
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Right))
