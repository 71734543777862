import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Label from 'components/common/Label'
import { QueryHelper } from 'utils/api.utils'
import { AppUsage, ContractKykStatusDiv } from 'utils/enum'
import { formatYYYYMMDD } from 'utils/date.utils'
import { handleOpenPage } from 'actions/Actions'

const useStyles = makeStyles(theme => ({
  header: {
    padding: '16px 24px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '16px 16px'
  },
  columnName: {
    marginLeft: '8px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2C3F58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#696CFF',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#696CFF',
    fontSize: '12px',
    lineHeight: '18px'
  },
  columnValue3: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2C3F58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue4: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2C3F58',
    fontSize: '12px',
    lineHeight: '18px'
  },
  divider: {
    marginLeft: '16px'
  },
  gridLink: {
    cursor: 'pointer'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: 5, page: 1 }
}

function StatusLabel({ status }) {
  if (
    status == ContractKykStatusDiv.contract.code ||
    status == ContractKykStatusDiv.cancellation.code
  ) {
    return <Label color="blue" text="入居中" />
  }
  return <Label color="gray" text="空室" />
}

function KeiyakuStatusLabel({ kykStatusDiv }) {
  if (kykStatusDiv == ContractKykStatusDiv.application.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.application.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.contract.code) {
    return <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.cancellation.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.completed.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.completed.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.payOff.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.payOff.value} />
  } else {
    return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
  }
}

function UseUseLabel({ is_usage, is_management, app_user_id }) {
  if (is_usage && is_management) {
    if (app_user_id != null) {
      return <Label color={'blue'} text={AppUsage.enabled.value} />
    } else {
      return <Label color={'gray'} text={AppUsage.none.value} />
    }
  }
  return <Label color={'gray'} text={AppUsage.disabled.value} />
}

function Right(props) {
  const classes = useStyles()
  const history = useHistory()

  const { room } = props
  // console.log(JSON.stringify(room));

  const [contract, setContract] = useState({})

  // const fetchContractList = async (hyaId) => {
  //   const params = {
  //       "KYK_HYA_ID": {
  //           "eq": hyaId,
  //       }
  //   };
  const fetchContractList = async id => {
    const params = {
      room_id: {
        eq: id
      }
    }
    // console.log(JSON.stringify(params));
    const result = await QueryHelper('getContractList2', { filter: params })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`入居者一覧を取得できませんでした。`)
    } else {
      if (result.data.length > 0) {
        // console.log(result.data[result.data.length-1]);
        result.data.sort((a, b) => {
          if (a.knr_keiyaku.KYK_KEIYAKU_DATE > b.knr_keiyaku.KYK_KEIYAKU_DATE)
            return 1
          if (a.knr_keiyaku.KYK_KEIYAKU_DATE < b.knr_keiyaku.KYK_KEIYAKU_DATE)
            return -1
        })
        setContract(result.data[result.data.length - 1])
      }
    }
  }

  useEffect(() => {
    if ('room' in room && room.room) {
      fetchContractList(room.room.id)
    }
  }, [room, setContract])

  return (
    <>
      <div className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.title}>部屋の状態</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            {'contract' in contract &&
            contract.contract &&
            'kyk_status_div' in contract.contract &&
            contract.contract.kyk_status_div ? (
              <StatusLabel status={contract.contract.kyk_status_div} />
            ) : (
              <Label />
            )}
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.columnName}>
              アプリ利用状況
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.columnValue3}>
              {contract && contract.customer && contract.building ? (
                <UseUseLabel
                  is_usage={contract.customer.is_usage}
                  is_management={contract.building.is_management}
                  app_user_id={contract.customer.app_user_id}
                />
              ) : (
                <></>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={classes.section}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.columnName}>入居者名</Typography>
          </Grid>
          {'contract' in contract && contract.contract ? (
            <Grid
              item
              xs={6}
              className={classes.gridLink}
              onClick={e =>
                handleOpenPage(
                  `/management/contract/detail/${contract.contract.id}`,
                  e,
                  history
                )
              }
            >
              <Typography className={classes.columnValue1}>
                {'mst_kokyaku_kyk_nky' in contract &&
                contract.mst_kokyaku_kyk_nky
                  ? contract.mst_kokyaku_kyk_nky.KKY_NAME
                  : ''}
              </Typography>
              <Typography className={classes.columnValue2}>
                {'mst_kokyaku_kyk_nky' in contract &&
                contract.mst_kokyaku_kyk_nky
                  ? contract.mst_kokyaku_kyk_nky.KKY_NAME_KANA
                  : ''}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography className={classes.columnValue3}>
                {'mst_kokyaku_kyk_nky' in contract &&
                contract.mst_kokyaku_kyk_nky
                  ? contract.mst_kokyaku_kyk_nky.KKY_NAME
                  : ''}
              </Typography>
              <Typography className={classes.columnValue4}>
                {'mst_kokyaku_kyk_nky' in contract &&
                contract.mst_kokyaku_kyk_nky
                  ? contract.mst_kokyaku_kyk_nky.KKY_NAME_KANA
                  : ''}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.columnName}>契約者</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.columnValue3}>
              {'mst_kokyaku_kyk_kys' in contract && contract.mst_kokyaku_kyk_kys
                ? contract.mst_kokyaku_kyk_kys.KKY_NAME
                : ''}
            </Typography>
            <Typography className={classes.columnValue4}>
              {'mst_kokyaku_kyk_kys' in contract && contract.mst_kokyaku_kyk_kys
                ? contract.mst_kokyaku_kyk_kys.KKY_NAME_KANA
                : ''}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.columnName}>契約状況</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.columnValue3}>
              {contract.contract ? (
                <KeiyakuStatusLabel
                  kykStatusDiv={contract.contract.kyk_status_div}
                />
              ) : (
                <></>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.columnName}>契約日</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.columnValue3}>
              {contract.knr_keiyaku && contract.knr_keiyaku.KYK_KEIYAKU_DATE
                ? formatYYYYMMDD(
                    new Date(contract.knr_keiyaku.KYK_KEIYAKU_DATE)
                  )
                : null}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.columnName}>
              アプリ利用開始日
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.columnValue3}>
              {contract.appUser && contract.appUser.registered_at
                ? formatYYYYMMDD(new Date(contract.appUser.registered_at))
                : null}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.columnName}>
              最終ログイン日
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.columnValue3}>
              {contract.appUser && contract.appUser.last_login_at
                ? formatYYYYMMDD(new Date(contract.appUser.last_login_at))
                : null}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
    </>
  )
}

export default Right
