import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog } from 'actions/Actions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.c_red.contrastText,
    backgroundColor: theme.palette.c_red.main,
    '&:hover': {
      backgroundColor: theme.palette.c_red.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function ModalDialogFooter(props) {
  const classes = useStyles()

  return (
    <>
      <Button
        onClick={props.closeModalDialog}
        variant="contained"
        className={classes.secondaryButton}
      >
        キャンセル
      </Button>
      <Button
        type="submit"
        variant="contained"
        className={classes.deleteButton}
      >
        削除
      </Button>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDialogFooter)
