import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { handleChange } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import TextInput from 'components/common/Form/TextInput'
import { Regexes } from 'utils/regexes'
import { ErrorMessages } from 'utils/errorMessages'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { closeModalDialog, setLoadData } from 'actions/Actions'
import FooterButtons from 'components/General/FooterButtons'
import { MutationHelper } from 'utils/api.utils'

const useStyles = makeStyles(theme => ({
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    },
    marginRight: '30px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Hiragino Sans',
      fontSize: '16px'
    }
  }
}))

const OwnerFormContent = props => {
  const classes = useStyles()
  const {
    owner,
    enqueueSnackbar,
    closeModalDialog,
    setLoadData,
    fetchOwner
  } = props

  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)
  const [isEditingLoginInfo, setIsEditingLoginInfo] = useState(false)

  const [ownerInfo, setOwnerInfo] = useState({
    id: '',
    name: '',
    name_kana: '',
    username: '',
    password: '',
    confirm_password: ''
  })

  const [inputFormErrors, setInputFormErrors] = useState({
    name_err: '',
    name_kana_err: '',
    username_err: '',
    password_err: '',
    confirm_password_err: ''
  })

  const handleChangeShowPassword = () => {
    if (isEditingLoginInfo) {
      setIsShowPassword(!isShowPassword)
    }
  }

  const handleChangeShowConfirmPassword = () => {
    if (isEditingLoginInfo) {
      setIsShowConfirmPassword(!isShowConfirmPassword)
    }
  }

  const handleCheckboxChange = () => {
    setIsEditingLoginInfo(!isEditingLoginInfo)
  }

  const isKana = str => {
    var reg = new RegExp(Regexes.Kana)
    return reg.test(str)
  }

  const isValidEmail = str => {
    const reg = new RegExp(Regexes.EmailAddress)
    return reg.test(str)
  }

  const isValidPassword = str => {
    const reg = new RegExp(Regexes.Password)
    return reg.test(str)
  }

  const handleInputChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name
    const newValues = { ...ownerInfo, [name]: value }

    setOwnerInfo(newValues)
    handleChange({ [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoadData(true)
    if (validateForm()) {
      setLoadData(false)
      enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    const params = {}

    params.id = ownerInfo.id

    if (ownerInfo.name && ownerInfo.name !== owner.owner.name) {
      params.name = ownerInfo.name
    }
    if (ownerInfo.name_kana && ownerInfo.name_kana !== owner.owner.name_kana) {
      params.name_kana = ownerInfo.name_kana
    }

    if (isEditingLoginInfo) {
      if (
        ownerInfo.username &&
        ownerInfo.username !== owner.app_user_and_attachment.username
      ) {
        params.username = ownerInfo.username
      }
      if (ownerInfo.password) {
        params.password = ownerInfo.password
      }
    }

    const result = await MutationHelper('updateOwner', {
      input: params
    })
    if (result.error) {
      enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      enqueueSnackbar('保存しました', {
        variant: 'success'
      })
      closeModalDialog()
      fetchOwner(ownerInfo.id)
    }
    setLoadData(false)
  }

  const validateForm = () => {
    const errors = {
      name_err: '',
      name_kana_err: '',
      username_err: '',
      password_err: '',
      confirm_password_err: ''
    }

    if (!ownerInfo.name) {
      errors.name_err = ErrorMessages.Required
    } else if (ownerInfo.name.length > 100) {
      errors.name_err = ErrorMessages.CharLimit100
    }

    if (!ownerInfo.name_kana) {
      errors.name_kana_err = ErrorMessages.Required
    } else if (!isKana(ownerInfo.name_kana)) {
      errors.name_kana_err = ErrorMessages.IncorrectKanaFormat
    }

    if (!ownerInfo.username) {
      errors.username_err = ErrorMessages.Required
    } else if (!isValidEmail(ownerInfo.username)) {
      errors.username_err = ErrorMessages.IncorrectEmailAddressFormat
    }

    if (ownerInfo.password && !isValidPassword(ownerInfo.password)) {
      errors.password_err = ErrorMessages.IncorrectPasswordFormat
    }

    if (
      ownerInfo.confirm_password &&
      !isValidPassword(ownerInfo.confirm_password)
    ) {
      errors.confirm_password_err = ErrorMessages.IncorrectPasswordFormat
    } else if (ownerInfo.password !== ownerInfo.confirm_password) {
      errors.confirm_password_err = ErrorMessages.ConfirmPasswordMismatch
    }

    const isError = Object.values(errors).some(error => error !== '')
    setInputFormErrors(errors)
    return isError
  }

  useEffect(() => {
    if (owner) {
      const initializeValues = {
        id: owner.owner.id,
        name: owner.owner.name || '',
        name_kana: owner.owner.name_kana || '',
        username: owner.app_user_and_attachment.username || '',
        password: '',
        confirm_password: ''
      }
      setOwnerInfo(initializeValues)
    }
  }, [owner])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">オーナーID</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2">自動採番</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} />
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">オーナー名</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextInput
              id="name"
              name="name"
              placeholder="オーナー名を入力"
              hiddenLabel
              defaultValue={ownerInfo.name}
              onChange={handleInputChange}
              variant="filled"
              fullWidth
              error={!!inputFormErrors.name_err}
              helperText={inputFormErrors.name_err}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">フリガナ</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextInput
              id="name_kana"
              name="name_kana"
              placeholder="オーナー名(フリガナ)を入力"
              hiddenLabel
              defaultValue={ownerInfo.name_kana}
              onChange={handleInputChange}
              variant="filled"
              fullWidth
              error={!!inputFormErrors.name_kana_err}
              helperText={inputFormErrors.name_kana_err}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                name=""
                checked={isEditingLoginInfo}
                onChange={handleCheckboxChange}
                style={{
                  color: '#494bb2'
                }}
              />
            }
            label="ログイン情報を編集"
          />
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">ログインID(メールアドレス)</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextInput
              id="username"
              name="username"
              placeholder="メールアドレスを入力"
              hiddenLabel
              defaultValue={ownerInfo.username}
              onChange={handleInputChange}
              variant="filled"
              fullWidth
              error={!!inputFormErrors.username_err}
              helperText={inputFormErrors.username_err}
              disabled={!isEditingLoginInfo}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} />
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">パスワード</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextInput
              id="password"
              name="password"
              placeholder="パスワードを入力"
              hiddenLabel
              autoComplete="new-password"
              type={isShowPassword ? 'text ' : 'password'}
              defaultValue={ownerInfo.password}
              onChange={handleInputChange}
              variant="filled"
              fullWidth
              error={!!inputFormErrors.password_err}
              helperText={inputFormErrors.password_err}
              disabled={!isEditingLoginInfo}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleChangeShowPassword}
                    >
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">
              パスワード
              <br />
              (確認用)
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextInput
              id="confirm_password"
              name="confirm_password"
              placeholder="パスワードを入力"
              hiddenLabel
              autoComplete="new-password"
              type={isShowConfirmPassword ? 'text ' : 'password'}
              defaultValue={ownerInfo.confirm_password}
              onChange={handleInputChange}
              variant="filled"
              fullWidth
              error={!!inputFormErrors.confirm_password_err}
              helperText={inputFormErrors.confirm_password_err}
              disabled={!isEditingLoginInfo}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleChangeShowConfirmPassword}
                    >
                      {isShowConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <FooterButtons handleCancel={closeModalDialog} />
    </form>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(OwnerFormContent))
