import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormLayout from 'components/common/FormLayout'
import AutoReplyTime from './AutoReplyTime'
import AutoReplyWeek from './AutoReplyWeek'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: 'unset'
  }
}))

function AutoReplyTimeAndWeek(props) {
  const classes = useStyles()

  const { start, setStart, end, setEnd, weekState, setWeekState } = props

  return (
    <>
      <Paper>
        <FormLayout title="自動返信時間設定" className={classes.form}>
          <Grid container xs={12} alignItems="center" spacing={2}>
            <Grid item xs={5} alignItems="left">
              <AutoReplyTime
                start={start}
                setStart={setStart}
                end={end}
                setEnd={setEnd}
              />
            </Grid>
            <Grid item xs={7} alignItems="left">
              <AutoReplyWeek
                weekState={weekState}
                setWeekState={setWeekState}
              />
            </Grid>
          </Grid>
        </FormLayout>
      </Paper>
    </>
  )
}

export default AutoReplyTimeAndWeek
