import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import { STATUS_LIST } from 'utils/const'
import FormControl from '@material-ui/core/FormControl'
import Dropdown from 'components/common/Form/Dropdown'
import { QueryHelper } from 'utils/api.utils'
import Loading from 'components/common/Loading'
import { FormHelperText, TextField, Typography } from '@material-ui/core'
import { Regexes } from 'utils/regexes'
import MenuItem from '@material-ui/core/MenuItem'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(() => ({
  formHelperText: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#72809D',
    marginTop: 2
  },
  icon: {
    position: 'relative',
    width: 30,
    height: 'auto',
    alignItems: 'center'
  },
  description: {
    fontFamily: 'Hiragino Sans',
    fontSize: '16px'
  }
}))

function CompanyUserForm(props) {
  const classes = useStyles()
  const { role1, role4 } = useRole()
  const isUnrestrictedRole1 = role1()
  const isUnrestrictedRole4 = role4()

  const item = sessionStorage.getItem('totono-advertiser-session')
    ? sessionStorage.getItem('totono-advertiser-session')
    : localStorage.getItem('totono-advertiser-session')
  const data = JSON.parse(item)
  const currentUserId = data.user_id

  const { isLoading, companyUser, setEnableForm, isError } = props

  const [companyGroupList, setCompanyGroupList] = useState([])
  const [roleList, setRoleList] = useState([])
  const [statusList] = useState(
    STATUS_LIST.filter(function(element) {
      return element.value != 3
    })
  )

  const [userDetails, setUserDetails] = useState({
    id: '',
    status: '',
    status_err: '',
    name: '',
    name_err: '',
    display_name: '',
    display_name_err: '',
    company_group_id: '',
    company_group_id_err: '',
    company_group_role_id: '',
    username: '',
    username_err: '',
    password: '',
    password_err: '',
    password_confirm: '',
    password_confirm_err: '',
    mail_address: '',
    mail_address_err: ''
  })

  const checkErr = objects => {
    if (
      objects.status_err ||
      objects.name_err ||
      objects.display_name_err ||
      objects.company_group_id_err ||
      objects.username_err ||
      objects.password_err ||
      objects.password_confirm_err ||
      objects.mail_address_err
    ) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const isMail = str => {
    var reg = new RegExp(Regexes.EmailAddress)
    var res = reg.test(str)
    return res
  }

  const handleSelectRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...userDetails, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '選択してください'
    }

    // 所属グループ選択時に対応するグループ権限を表示
    if (name === 'company_group_id') {
      const selectedGroup = companyGroupList.find(item => item.value === value)
      const selectedGroupRole = roleList.find(
        item => item.value === selectedGroup.role_id
      )
      const selectedGroupRoleId = selectedGroupRole
        ? selectedGroupRole.value
        : ''
      newValues['company_group_role_id'] = selectedGroupRoleId
    }

    setUserDetails(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...userDetails, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setUserDetails(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputMailRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...userDetails, [name]: value }
    if (value) {
      if (isMail(value)) {
        newValues[name + '_err'] = ''
      } else {
        newValues[name + '_err'] = 'メールアドレス形式で入力してください'
      }
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setUserDetails(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputPasswordMatchChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...userDetails, [name]: value }
    if (value) {
      if (value == newValues.password) {
        newValues[name + '_err'] = ''
      } else {
        newValues[name + '_err'] = 'パスワードが一致しません'
      }
    } else {
      newValues[name + '_err'] = ''
    }
    setUserDetails(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputMailAddressRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...userDetails, [name]: value }
    if (value) {
      if (isMail(value)) {
        newValues[name + '_err'] = ''
      } else {
        newValues[name + '_err'] = 'メールアドレス形式で入力してください'
      }
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setUserDetails(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  function handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name
    setUserDetails({ ...userDetails, [name]: value })
    props.handleChange({ [name]: value })
  }

  const fetchCompanyGroup = async () => {
    const result = await QueryHelper('getCompanyGroupList')
    if (!result.error) {
      let list = []
      for (let row of result.data) {
        list.push({
          value: row.id,
          label: row.name,
          role_id: row.role_id
        })
      }
      setCompanyGroupList(list)
    } else {
      props.enqueueSnackbar(`グループを取得できませんでした。`)
    }
  }

  async function fetchRoleList() {
    let result = await QueryHelper('getRoleList')
    if (result.error) {
      props.enqueueSnackbar('権限一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const roleItem = [{ value: '', label: '' }]
      result.data.forEach(item => {
        roleItem.push({ value: item.id, label: item.name })
      })
      setRoleList(roleItem)
    }
  }

  useEffect(() => {
    const initializeValues = {
      id:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.id
          : '',
      status:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.status
          : '',
      status_err:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.status
            ? ''
            : '選択してください'
          : '',
      name:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.name
          : '',
      name_err:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.name
            ? ''
            : '入力してください'
          : '',
      display_name:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.display_name
          : '',
      display_name_err:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.display_name
            ? ''
            : ''
          : '',
      company_group_id:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.company_group_id
          : '',
      company_group_id_err:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.company_group_id
            ? ''
            : ''
          : '',
      company_group_role_id:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_group.role_id
          : '',
      username:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.username
          : '',
      username_err:
        'company_user' in companyUser && companyUser.company_user
          ? isMail(companyUser.company_user.username)
            ? ''
            : ''
          : '',
      password: '',
      password_err:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.id
            ? ''
            : '入力してください'
          : '',
      password_confirm: '',
      password_confirm_err:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.id
            ? ''
            : '入力してください'
          : '',
      mail_address:
        'company_user' in companyUser && companyUser.company_user
          ? companyUser.company_user.mail_address
          : '',
      mail_address_err:
        'company_user' in companyUser && companyUser.company_user
          ? isMail(companyUser.company_user.mail_address)
            ? ''
            : ''
          : ''
    }
    if (isError) {
      if (userDetails.status == '') {
        initializeValues.status_err = '選択してください'
      }
      if (userDetails.company_group_id == '') {
        initializeValues.company_group_id_err = '選択してください'
      }
      if (userDetails.name != '') {
        initializeValues.name = userDetails.name
      }
      if (userDetails.display_name != '') {
        initializeValues.display_name = userDetails.display_name
      }
      if (userDetails.username != '') {
        initializeValues.username = userDetails.username
      }
      if (userDetails.password != '') {
        initializeValues.password = userDetails.password
      }
      if (userDetails.password_confirm != '') {
        initializeValues.password_confirm = userDetails.password_confirm
      }
      if (userDetails.mail_address != '') {
        initializeValues.mail_address = userDetails.mail_address
      }
    }
    setUserDetails(initializeValues)
    checkErr(initializeValues)
    fetchCompanyGroup()
    fetchRoleList()
  }, [companyUser, setUserDetails, setCompanyGroupList, isError])

  return (
    <FormLayout title="内容">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item container xs={6} alignItems="center">
              {'company_user' in companyUser && (
                <>
                  <Grid item xs={3}>
                    <Typography>利用者ID</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{userDetails.id}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth>
                <Dropdown
                  hasError={userDetails.status_err != '' ? true : false}
                  errorMessage={userDetails.status_err}
                  required
                  name="status"
                  label="状態"
                  defaultValue={userDetails.status}
                  onChange={handleSelectRequiredChange}
                  items={statusList}
                  disabled={!isUnrestrictedRole1}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth>
                <Dropdown
                  hasError={
                    userDetails.company_group_id_err != '' ? true : false
                  }
                  errorMessage={userDetails.company_group_id_err}
                  required
                  name="company_group_id"
                  label="所属グループ"
                  defaultValue={userDetails.company_group_id}
                  onChange={handleSelectRequiredChange}
                  items={companyGroupList}
                  disabled={!isUnrestrictedRole1}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth>
                <TextField
                  id="company_group_role_id"
                  label="グループ権限"
                  select
                  value={userDetails.company_group_role_id}
                  InputProps={{
                    readOnly: true
                  }}
                  SelectProps={{
                    IconComponent: () => null
                  }}
                  variant="filled"
                  disabled={!isUnrestrictedRole1}
                >
                  {roleList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                error={userDetails.name_err != '' ? true : false}
                helperText={userDetails.name_err}
                required
                id="name"
                name="name"
                label="氏名"
                fullWidth
                variant="filled"
                defaultValue={userDetails.name}
                onChange={handleInputRequiredChange}
                disabled={!isUnrestrictedRole1}
              />
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <Typography className={classes.description}>
                ※管理画面内で使用される氏名です。
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                error={userDetails.display_name_err != '' ? true : false}
                helperText={userDetails.display_name_err}
                required
                id="display_name"
                name="display_name"
                label="表示名"
                fullWidth
                variant="filled"
                defaultValue={userDetails.display_name}
                onChange={handleInputRequiredChange}
                disabled={!isUnrestrictedRole1}
              />
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <Typography className={classes.description}>
                ※チャット返信等でユーザー側に表示される氏名です。
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                error={userDetails.username_err != '' ? true : false}
                helperText={userDetails.username_err}
                required
                id="username"
                name="username"
                label="ログインID (メールアドレス)"
                fullWidth
                variant="filled"
                defaultValue={userDetails.username}
                onChange={handleInputMailRequiredChange}
                disabled={
                  userDetails.id !== currentUserId &&
                  !(
                    (isUnrestrictedRole4 &&
                      companyUser.company_group !== null &&
                      companyUser.company_group !== undefined &&
                      companyUser.company_group.role_id === 6) ||
                    isUnrestrictedRole1
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                error={userDetails.password_err != '' ? true : false}
                helperText={userDetails.password_err}
                autoComplete="new-password"
                required={userDetails.id ? false : true}
                id="password"
                type="password"
                name="password"
                label="ログインパスワード"
                fullWidth
                variant="filled"
                defaultValue={userDetails.password}
                onChange={
                  userDetails.id ? handleInputChange : handleInputRequiredChange
                }
                disabled={
                  userDetails.id !== currentUserId &&
                  !(
                    (isUnrestrictedRole4 &&
                      companyUser.company_group !== null &&
                      companyUser.company_group !== undefined &&
                      companyUser.company_group.role_id === 6) ||
                    isUnrestrictedRole1
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                required={userDetails.id ? false : true}
                error={userDetails.password_confirm_err != '' ? true : false}
                helperText={userDetails.password_confirm_err}
                id="password_confirm"
                type="password"
                name="password_confirm"
                label="ログインパスワード※確認用"
                fullWidth
                variant="filled"
                defaultValue={userDetails.password_confirm}
                onChange={handleInputPasswordMatchChange}
                disabled={
                  userDetails.id !== currentUserId &&
                  !(
                    (isUnrestrictedRole4 &&
                      companyUser.company_group !== null &&
                      companyUser.company_group !== undefined &&
                      companyUser.company_group.role_id === 6) ||
                    isUnrestrictedRole1
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                error={userDetails.mail_address_err != '' ? true : false}
                helperText={userDetails.mail_address_err}
                required
                id="mail_address"
                name="mail_address"
                label="通知先メールアドレス"
                fullWidth
                variant="filled"
                defaultValue={userDetails.mail_address}
                onChange={handleInputMailAddressRequiredChange}
                inputProps={{ maxLength: 256 }}
                disabled={
                  userDetails.id !== currentUserId &&
                  !(
                    (isUnrestrictedRole4 &&
                      companyUser.company_group !== null &&
                      companyUser.company_group !== undefined &&
                      companyUser.company_group.role_id === 6) ||
                    isUnrestrictedRole1
                  )
                }
              />
              <FormHelperText className={classes.formHelperText}>
                ※ここに設定したメールアドレスに通知されます。
              </FormHelperText>
            </Grid>
          </Grid>
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUserForm)
