import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import DomainIcon from '@material-ui/icons/Domain'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import SettingsCellIcon from '@material-ui/icons/SettingsCell'
import MobileOffIcon from '@material-ui/icons/MobileOff'
import SmsIcon from '@material-ui/icons/Sms'
import { withSnackbar } from 'notistack'
import Dropdown from 'components/common/Form/Dropdown'
import { ContractKykStatusDiv, ThreadState } from 'utils/enum'
import { HyaTysCd } from 'utils/enum'
import { QueryHelper, MutationHelper } from 'utils/api.utils'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  header: {
    padding: '16px 24px'
  },
  title: {
    color: theme.palette.c_thirdly.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '16px 16px'
  },
  columnName: {
    marginLeft: '8px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: theme.palette.c_thirdly.main,
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: theme.palette.c_thirdly.main,
    fontSize: '16px',
    lineHeight: '24px'
  },
  divider: {
    marginLeft: '16px'
  }
}))

const Right = props => {
  const classes = useStyles()
  const { id, buildingId } = useParams()
  const {
    ownerBuilding,
    building,
    isLoading,
    enqueueSnackbar,
    fetchOwnerBuilding
  } = props

  const [threadList, setThreadList] = useState()
  const [enabledAppUserCount, setEnabledAppUserCount] = useState()
  const [disabledAppUserCount, setDisabledAppUserCount] = useState()
  const [dwellingUnitCount, setDwellingUnitCount] = useState()
  const [otherDwellingUnitCount, setOtherDwellingUnitCount] = useState()
  const [totalCount, setTotalCount] = useState()
  const [isLoad, setIsLoad] = useState(false)

  const ownerIsUsageList = [
    {
      value: true,
      label: '有効'
    },
    {
      value: false,
      label: '無効'
    }
  ]

  const handleChangeIsUsage = async e => {
    const selectedValue = e.target.value

    if (
      !window.confirm(
        `状態を${selectedValue ? '有効' : '無効'}にします。よろしいですか？`
      )
    ) {
      e.target.value = ownerBuilding.is_usage
      return
    }
    const params = {
      id: ownerBuilding.id,
      is_usage: selectedValue
    }
    const result = await MutationHelper('updateOwnerBuilding', {
      input: params
    })
    if (result.error) {
      enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      enqueueSnackbar('保存しました', {
        variant: 'success'
      })
      fetchOwnerBuilding(id, buildingId)
    }
  }

  const fetchThreadList = async buildingId => {
    const params = {
      thread_state_id: { eq: ThreadState.open.code },
      building_id: { eq: buildingId },
      is_owner: true
    }
    const result = await QueryHelper('getThreadListBySearchCondition', {
      filter: params
    })
    if (result.error) {
      props.enqueueSnackbar('メッセージ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setThreadList(result)
    }
  }

  const fetchContractList = async (
    buildingId,
    isUseApp,
    kykStatusDiv,
    setCount
  ) => {
    const params = {
      building_id: {
        eq: buildingId
      },
      is_use_app: {
        eq: isUseApp
      }
    }
    if (kykStatusDiv) {
      params.kyk_status_div = {
        eq: kykStatusDiv
      }
    }

    const result = await QueryHelper('getContractList2', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`入居者一覧を取得できませんでした。`)
    } else {
      if (result.paging) {
        setCount(result.paging.count)
      }
    }
  }

  const fetchContractListByDisabledAppUser = async buildingId => {
    await fetchContractList(
      buildingId,
      false,
      [
        ContractKykStatusDiv.application.code,
        ContractKykStatusDiv.contract.code,
        ContractKykStatusDiv.cancellation.code
      ],
      setDisabledAppUserCount
    )
  }

  const fetchContractListByEnabledAppUser = async buildingId => {
    await fetchContractList(buildingId, true, null, setEnabledAppUserCount)
  }

  const fetchRoomList = async buildingId => {
    const params = {
      building_id: {
        eq: buildingId
      }
    }
    const result = await QueryHelper('getRoomList2', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`部屋一覧を取得できませんでした。`)
      return
    }

    let dwellingUnitCnt = 0
    for (let i of result.data) {
      if (
        i.mst_heya &&
        (i.mst_heya.HYA_TYS_CD === HyaTysCd.house.code ||
          i.mst_heya.HYA_TYS_CD === HyaTysCd.financeCorporation.code)
      ) {
        dwellingUnitCnt++
      }
    }
    setDwellingUnitCount(dwellingUnitCnt)
    setOtherDwellingUnitCount(result.data.length - dwellingUnitCnt)
    setTotalCount(result.data.length)
  }

  const fetchData = async buildingId => {
    setIsLoad(true)
    await Promise.all([
      fetchThreadList(buildingId),
      fetchContractListByDisabledAppUser(buildingId),
      fetchContractListByEnabledAppUser(buildingId),
      fetchRoomList(buildingId)
    ])
    setIsLoad(false)
  }

  useEffect(() => {
    if (building && building.building) {
      fetchData(building.building.id)
    }
  }, [building, setThreadList])

  return (
    <>
      <div className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.title}>
              オーナーアプリの状態
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Dropdown
              label="状態"
              name="status"
              items={ownerIsUsageList}
              defaultValue={ownerBuilding && ownerBuilding.is_usage}
              onChange={handleChangeIsUsage}
            />
          </Grid>
        </Grid>
      </div>
      <Divider />
      {!isLoading && !isLoad ? (
        <>
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <DomainIcon className={classes.icon} />
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.columnName}>総戸数</Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {totalCount && totalCount}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <Typography className={classes.columnName}>(住戸)</Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {dwellingUnitCount && dwellingUnitCount}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={5}>
                <Typography className={classes.columnName}>(その他)</Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {otherDwellingUnitCount && otherDwellingUnitCount}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <DomainIcon className={classes.icon} />
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.columnName}>対象戸数</Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {building && building.building
                    ? building.building.room_cnt
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <AccessibilityNewIcon className={classes.icon} />
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.columnName}>入居人数</Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {building && building.building
                    ? building.building.contract_cnt
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <SettingsCellIcon className={classes.icon} />
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.columnName}>
                  アプリ使用者
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {enabledAppUserCount && enabledAppUserCount}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <MobileOffIcon className={classes.icon} />
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.columnName}>
                  アプリ無効者
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {disabledAppUserCount && disabledAppUserCount}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <SmsIcon className={classes.icon} />
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.columnName}>
                  未対応メッセージ
                </Typography>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Typography className={classes.columnValue}>
                  {threadList && threadList.data.length}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Loading isLoading={isLoading || isLoad} />
      )}
      <Divider />
    </>
  )
}

export default withSnackbar(Right)
