import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import LinearProgress from '@material-ui/core/LinearProgress'
import DateLabel from 'components/common/DateLabel'
import { Grid } from '@material-ui/core'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const useStyles = makeStyles(theme => ({
  '.MuiDrawer-paper': {
    maxHeight: '600px'
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
  list: {
    width: '400px'
  },
  container: {
    maxHeight: 540,
    maxWidth: '100%'
  },
  large: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  medium: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  text: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    textAlign: 'left'
  }
}))

function CellWithBody({ body }) {
  if (body.length < 20) {
    return <>{body}</>
  }
  const firstRow = body.slice(0, 20)
  return <>{`${firstRow}...`}</>
}

export default function NoticeList({ toggleDrawer, items, isLoading }) {
  const history = useHistory()
  const classes = useStyles()

  const [openRowId, setOpenRowId] = useState('')

  const handleClick = url => {
    if (!url) {
      return
    }
    window.open(url)
  }

  return (
    <div className={classes.list} role="presentation">
      <Toolbar>
        <span style={{ marginRight: '10px', color: 'red' }}>
          <NewReleasesIcon />
        </span>
        <Typography className={classes.title} variant="subtitle1">
          totonoからのお知らせ
          <br />
          <Typography variant="caption" display="block" gutterBottom>
            最新{items.length}件
          </Typography>
        </Typography>

        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                key={'createdAt'}
                style={{
                  width: '30%'
                }}
              >
                受信日時
              </TableCell>
              <TableCell
                key={'category'}
                style={{
                  width: '70%'
                }}
              >
                内容
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {items.map(row => {
                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.companyNotice.id}
                      onClick={() =>
                        setOpenRowId(
                          openRowId == row.companyNotice.id
                            ? ''
                            : row.companyNotice.id
                        )
                      }
                    >
                      <TableCell>
                        <DateLabel timestamp={row.companyNotice.created_at} />
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={12}>
                            <CellWithBody body={row.companyNotice.body} />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            setOpenRowId(
                              openRowId == row.companyNotice.id
                                ? ''
                                : row.companyNotice.id
                            )
                          }
                        >
                          {openRowId == row.companyNotice.id ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          height: 'auto'
                        }}
                        colSpan={3}
                      >
                        <Collapse
                          in={openRowId == row.companyNotice.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid
                            container
                            xs={12}
                            spacing={4}
                            alignItems={'flex-start'}
                            style={{ paddingBottom: 10, paddingTop: 10 }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.text}
                              >
                                {row.companyNotice.body}
                              </Typography>
                            </Grid>
                            {row.companyNotice.url && (
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  className={classes.text}
                                >
                                  関連URL
                                </Typography>
                                <Divider />
                                <Typography
                                  variant="body2"
                                  className={classes.text}
                                >
                                  <Link
                                    color="blue"
                                    href={row.companyNotice.url}
                                    target="_blank"
                                  >
                                    {row.companyNotice.url}
                                    <OpenInNewIcon
                                      style={{ verticalAlign: 'middle' }}
                                      fontSize={'small'}
                                    />
                                  </Link>
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                )
              })}
            </TableBody>
          )}
        </Table>
        {isLoading && <LinearProgress style={{ width: '100%' }} />}
      </TableContainer>
    </div>
  )
}
