import React from 'react'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ImageIcon from '@material-ui/icons/Image'
import TheatersIcon from '@material-ui/icons/Theaters'
import { AttachmentType } from 'utils/enum'

const AttachmentIcons = ({ attachments, id }) => {
  if (!attachments) return null
  const icons = attachments.map((attachment, index) => {
    let icon = null
    switch (attachment.attachment_type_id) {
      case AttachmentType.image:
        icon = <ImageIcon key={`${id}-画像-${index}`} />
        break
      case AttachmentType.video:
        icon = <TheatersIcon key={`${id}-動画-${index}`} />
        break
      case AttachmentType.pdf:
        icon = <PictureAsPdfIcon key={`${id}-PDF-${index}`} />
        break
      default:
        break
    }
    return icon
  })
  return <>{icons.length > 0 && icons}</>
}

export default AttachmentIcons
