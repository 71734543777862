import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { handleChange, setLoadData } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import FormLayout from 'components/common/FormLayout'
import RowWithPdf from 'components/common/RowWithPdf'
import { Auth, Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'
import Dropdown from 'components/common/Form/Dropdown'
import { AttachmentType, OwnerDocumentCategory } from 'utils/enum'
import uuid from 'uuid/v4'
import { QueryHelper, MutationHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import { ErrorMessages } from 'utils/errorMessages'
import { PAGE_LIMIT } from 'utils/const'

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%',
    marginTop: 10
  },
  category: {
    padding: '0 10px'
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  scrollableContainer: {
    maxHeight: '400px',
    overflowY: 'auto'
  }
}))

const OwnerDocumentFileForm = props => {
  const classes = useStyles()
  const { id, buildingId } = useParams()
  const { title, enqueueSnackbar, setLoadData } = props
  const [isLoading, setIsLoading] = useState(true)
  const [ownerAttachments, setOwnerAttachments] = useState([])
  const [formattedAttachments, setFormattedAttachments] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [formErrors, setFormErrors] = useState({
    titles: [],
    categories: []
  })

  const containerRef = useRef(null)
  const [offset, setOffset] = useState(0)

  const ownerDocumentCategory = [
    {
      value: OwnerDocumentCategory.balanceReport.code,
      label: OwnerDocumentCategory.balanceReport.value
    },
    {
      value: OwnerDocumentCategory.quotation.code,
      label: OwnerDocumentCategory.quotation.value
    },
    {
      value: OwnerDocumentCategory.contract.code,
      label: OwnerDocumentCategory.contract.value
    },
    {
      value: OwnerDocumentCategory.other.code,
      label: OwnerDocumentCategory.other.value
    }
  ]

  const attachmentType = {
    key: 'pdfs',
    name: 'PDF',
    accept: 'application/pdf'
  }

  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = () => {
      const object = {
        attachment: {
          status: 1,
          attachment_type_id: AttachmentType.pdf,
          mime_type: file.type,
          filename: file.name,
          body: reader.result
        }
      }
      setFormattedAttachments(prevState => [object, ...prevState])
      setUploadedFiles(prevState => [{ attachment: file }, ...prevState])
    }
    reader.readAsDataURL(file)

    if (containerRef.current) {
      containerRef.current.scrollTop = 0
    }
  }

  const handleChangeTitle = (e, index) => {
    const copy1 = []
    Object.assign(copy1, formattedAttachments)
    copy1[index].attachment.title = e.target.value
    const copy2 = []
    Object.assign(copy2, uploadedFiles)
    copy2[index].attachment.title = e.target.value

    setFormattedAttachments([...copy1])
    setUploadedFiles([...copy2])
  }

  const handleChangeCategory = (e, index) => {
    const copy1 = []
    Object.assign(copy1, formattedAttachments)
    copy1[index].category_id = e.target.value
    const copy2 = []
    Object.assign(copy2, uploadedFiles)
    copy2[index].category_id = e.target.value

    setFormattedAttachments([...copy1])
    setUploadedFiles([...copy2])
  }

  const handleClick = async (attachment, event) => {
    event.preventDefault()
    if (attachment && attachment.key) {
      Auth.currentCredentials().then(async () => {
        const key = attachment.key
        await Storage.get(key, {
          level: 'protected',
          identityId: attachment.company_id,
          expires: 60
        })
          .then(result => {
            fetch(result).then(response => {
              response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = attachment.filename
                a.click()
              })
            })
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }

  const handleDelete = index => {
    const copy1 = []
    Object.assign(copy1, formattedAttachments)
    const target1 = copy1[index]
    target1.attachment.status = 3
    copy1.splice(index, 1, target1)
    const copy2 = []
    Object.assign(copy2, uploadedFiles)
    const target2 = copy2[index]
    if (target2 instanceof File) {
      copy2.splice(index, 1, {})
    } else {
      target2.attachment.status = 3
      copy2.splice(index, 1, target2)
    }

    setFormattedAttachments([...copy1])
    setUploadedFiles([...copy2])
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoadData(true)

    const errors = validateForm()
    if (errors.titles.length > 0 || errors.categories.length > 0) {
      setFormErrors(errors)
      enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      setLoadData(false)
      return
    }
    setFormErrors({
      titles: [],
      categories: []
    })

    const attachmentParams = {
      attachment_list: [],
      owner_id: parseInt(id),
      building_id: parseInt(buildingId)
    }

    for (let param of uploadedFiles) {
      const { attachment, category_id } = param

      const correspondingAttachment =
        ownerAttachments &&
        ownerAttachments.find(
          item =>
            item.attachment.id === attachment.id &&
            item.attachment.title === attachment.title &&
            item.attachment.status === attachment.status &&
            item.category_id === category_id
        )

      if (correspondingAttachment) continue

      let attachmentData = {}

      if ('id' in attachment) {
        attachmentData = attachment
      } else {
        const creds = await Auth.currentCredentials()
        const result = await fileUpload(creds, 'pdfs', attachment)

        attachmentData = {
          attachment_type_id: AttachmentType.pdf,
          mime_type: attachment.type,
          filename: attachment.name,
          key: result.key,
          title: attachment.title
        }
      }

      attachmentParams.attachment_list.push({
        attachment: attachmentData,
        attachment_category_id: category_id
      })
    }

    if (attachmentParams.attachment_list.length === 0) {
      enqueueSnackbar('変更がありません', {
        variant: 'warning'
      })
      setLoadData(false)
      return
    }

    const result = await MutationHelper('upsertOwnerAttachment', {
      input: attachmentParams
    })
    if (result.error) {
      enqueueSnackbar('オーナードキュメントを更新できませんでした。', {
        variant: 'error'
      })
      setLoadData(false)
    } else {
      enqueueSnackbar('オーナードキュメントを更新しました。', {
        variant: 'success'
      })
      // ドキュメント更新後、初期値にリセットし、新たに最新のデータを取得
      setOwnerAttachments([])
      setFormattedAttachments([])
      setUploadedFiles([])
      setOffset(PAGE_LIMIT)
      fetchOwnerAttachments(id, 0, PAGE_LIMIT)
      setLoadData(false)
    }
  }

  const validateForm = () => {
    const errors = {
      titles: [],
      categories: []
    }

    formattedAttachments.forEach((param, index) => {
      if (!param.attachment.title || param.attachment.title.trim() === '') {
        errors.titles[index] = ErrorMessages.Required
      }
      if (!param.category_id) {
        errors.categories[index] = ErrorMessages.Required
      }
    })

    return errors
  }

  const fileUpload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    return result
  }

  const fetchFile = async attachments => {
    if (attachments.length === 0) {
      return
    }
    const objects = []
    for (const attachment of attachments) {
      const key = attachment.key
      const result = await getStorage(key, attachment.attachment.company_id)
      const modifiedAttachment = {
        ...attachment,
        attachment: {
          ...attachment.attachment,
          body: result
        }
      }
      objects.push(modifiedAttachment)
    }

    // スクロールした際に新しく追加するファイルが消えてしまうため、ownerAttachmentsにない場合追加する
    const newAttachments = objects.filter(
      newAttachment =>
        !formattedAttachments.some(
          existingAttachment =>
            newAttachment.attachment.id === existingAttachment.attachment.id
        )
    )
    setFormattedAttachments(prevAttachments => [
      ...prevAttachments,
      ...newAttachments
    ])
    setUploadedFiles(prevAttachments => [...prevAttachments, ...newAttachments])
  }

  const getStorage = async (key, companyId) => {
    return Storage.get(key, {
      level: 'protected',
      identityId: companyId,
      expires: 60
    })
  }

  const fetchOwnerAttachments = async (id, offset, limit) => {
    const result = await QueryHelper('getOwnerAttachments', {
      owner_id: id,
      building_id: buildingId,
      limit: limit,
      offset: offset
    })
    if (result.error) {
      props.enqueueSnackbar(
        'オーナー建物ドキュメント情報を取得できませんでした',
        {
          variant: 'error'
        }
      )
    } else {
      if (result.owner_attachment && result.owner_attachment.length > 0) {
        // OwnerAttachmentsを新しく取得したデータで更新
        setOwnerAttachments(prevAttachments => [
          ...prevAttachments,
          ...result.owner_attachment
        ])
      }
    }
  }

  useEffect(() => {
    if (ownerAttachments && ownerAttachments.length > 0) {
      ;(async () => {
        await fetchFile(ownerAttachments)
      })()
    }
    setIsLoading(false)
  }, [ownerAttachments])

  // 下までスクロールされるごとにデータを取得
  const handleScroll = () => {
    const positionWithAdjustmentValue =
      containerRef.current.clientHeight + containerRef.current.scrollTop
    if (positionWithAdjustmentValue >= containerRef.current.scrollHeight) {
      setOffset(prevOffset => prevOffset + PAGE_LIMIT)
      fetchOwnerAttachments(id, offset, PAGE_LIMIT)
    }
  }

  useEffect(() => {
    fetchOwnerAttachments(id, offset, PAGE_LIMIT)
    setOffset(prevOffset => prevOffset + PAGE_LIMIT)
  }, [])

  return (
    <FormLayout title={title}>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <form onSubmit={handleSubmit}>
          <div
            ref={containerRef}
            className={classes.scrollableContainer}
            onScroll={() => handleScroll()}
          >
            {formattedAttachments.length > 0 &&
              formattedAttachments.map((item, index) => {
                return item.attachment.status === 1 ? (
                  <Grid
                    container
                    className={classes.fileForm}
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <RowWithPdf
                        key={index}
                        title={item.attachment.filename}
                        handleDelete={() => handleDelete(index)}
                        style={{ marginBottom: 10 }}
                        attachment={formattedAttachments[index]}
                        handleClick={handleClick}
                      ></RowWithPdf>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={4} className={classes.textField}>
                      <TextField
                        fullWidth
                        label="オーナードキュメント名"
                        variant="filled"
                        value={
                          item.attachment.title ? item.attachment.title : ''
                        }
                        onChange={e => handleChangeTitle(e, index)}
                        size="small"
                        error={
                          formErrors.titles && formErrors.titles[index]
                            ? true
                            : false
                        }
                        helperText={
                          formErrors.titles && formErrors.titles[index]
                            ? formErrors.titles[index]
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.category}>
                      <Dropdown
                        label="カテゴリ"
                        name="category"
                        size="small"
                        defaultValue={item.category_id}
                        items={ownerDocumentCategory}
                        onChange={e => handleChangeCategory(e, index)}
                        hasError={
                          formErrors.categories && formErrors.categories[index]
                            ? true
                            : false
                        }
                        errorMessage={
                          formErrors.categories && formErrors.categories[index]
                            ? formErrors.categories[index]
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )
              })}
          </div>
          <Grid container>
            <Grid item xs={12}>
              <input
                accept={attachmentType.accept}
                className={classes.input}
                id={`contained-button-${attachmentType.key}`}
                type="file"
                onChange={handleChange}
              />
            </Grid>
            <label
              htmlFor={`contained-button-${attachmentType.key}`}
              className={classes.labelButton}
            >
              <Button
                fullWidth
                size="large"
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<AddIcon />}
              >
                {`${attachmentType.name}を追加`}
              </Button>
            </label>
          </Grid>
          <div className={classes.saveButton}>
            <Button
              variant="contained"
              type="submit"
              className={classes.primaryButton}
            >
              保存
            </Button>
          </div>
        </form>
      )}
    </FormLayout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(OwnerDocumentFileForm))
