import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import {
  changeSearchCondition,
  openSearchModalDialog,
  saveMessageSearchCondition,
  saveOwnerMessageSearchCondition
} from 'actions/Actions'
import { useHistory, useLocation } from 'react-router-dom'
import { useCustomerOrOwner } from '../../../hooks/useCustomerOrOwner'
import { ScreenUrl } from 'utils/enum'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '12px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function SearchTextField({ text, ...props }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [inputValue, setInputValue] = useState('')
  const [inputOwnerValue, setInputOwnerValue] = useState('')
  const { useCheckOwnerPath, useChangeStorageKey } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()
  const storageKeyList = useChangeStorageKey()

  useEffect(() => {
    if (
      location.state !== undefined &&
      storageKeyList.residentName in location.state
    ) {
      if (isOwnerPath)
        setInputOwnerValue(location.state[storageKeyList.residentName])
      if (!isOwnerPath)
        setInputValue(location.state[storageKeyList.residentName])
    }
  }, [isOwnerPath ? setInputOwnerValue : setInputValue])

  const handleClick = async () => {
    props.openSearchModalDialog()
  }

  const handleSearch = event => {
    const setValue = isOwnerPath ? inputOwnerValue : inputValue
    event.preventDefault()
    if (isOwnerPath) {
      props.saveOwnerMessageSearchCondition(
        setValue != '' ? { [storageKeyList.residentName]: setValue } : {}
      )
    } else {
      props.saveMessageSearchCondition(
        setValue != '' ? { [storageKeyList.residentName]: setValue } : {}
      )
    }
    history.push({
      pathname: isOwnerPath
        ? `${ScreenUrl.ownerMessageList}`
        : `${ScreenUrl.messageList}`,
      state: setValue != '' ? { [storageKeyList.residentName]: setValue } : {}
    })
  }

  return (
    <Paper component="form" className={classes.root}>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={handleSearch}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={text}
        inputProps={{ 'aria-label': { text } }}
        onChange={event => {
          isOwnerPath
            ? setInputOwnerValue(event.target.value)
            : setInputValue(event.target.value)
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleSearch(event)
          }
        }}
        value={isOwnerPath ? inputOwnerValue : inputValue}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="directions"
        onClick={handleClick}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Paper>
  )
}

const mapStateToProps = state => {
  return {
    conditions: state.searchCondition
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openSearchModalDialog: () => {
      dispatch(openSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    },
    saveMessageSearchCondition: parameter => {
      dispatch(saveMessageSearchCondition(parameter))
    },
    saveOwnerMessageSearchCondition: parameter => {
      dispatch(saveOwnerMessageSearchCondition(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchTextField)
