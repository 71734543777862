import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EditContent from './EditContent'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles({
  wrapBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  wrapContent: {
    flex: 1,
    display: 'flex',
    margin: '32px'
  }
})

function EditScreen(props) {
  const classes = useStyles()

  return (
    <div className={classes.wrapBody}>
      <div className={classes.wrapContent}>
        <EditContent />
      </div>
    </div>
  )
}
export default withSnackbar(EditScreen)
