import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '12px 32px 0'
  },
  primaryButton: {
    marginLeft: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function FooterButtons(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  return (
    <Typography align="right" className={classes.root}>
      <Button
        variant="contained"
        className={classes.secondaryButton}
        onClick={props.handleCancel}
        disabled={!isUnrestrictedRole}
      >
        キャンセル
      </Button>
      <Button
        variant="contained"
        type="submit"
        className={classes.primaryButton}
        disabled={!isUnrestrictedRole}
      >
        保存
      </Button>
    </Typography>
  )
}

export default FooterButtons
