import React, { useEffect, useState } from 'react'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import DetailLayout from 'components/common/DetailLayout'
import RoomList from './RoomList'

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: 5, page: 1 }
}

function RoomListContent(props) {
  const { isLoading, building } = props
  const [roomList, setRoomList] = useState(initialState)

  const fetchRoomList = async building_id => {
    const params = {
      building_id: {
        eq: building_id
      },
      orderBy: [
        {
          sorted: 'asc',
          column: 'hya_room_no'
        }
      ]
    }
    const result = await QueryHelper('getRoomList2', {
      filter: params,
      limit: 5
    })
    if (!result.error) {
      setRoomList(result)
    } else {
      props.enqueueSnackbar(`部屋一覧を取得できませんでした。`)
    }
  }

  useEffect(() => {
    if ('building' in building && building.building) {
      fetchRoomList(building.building.id)
    }
  }, [building, setRoomList])

  return (
    <DetailLayout
      title="部屋一覧"
      children={<RoomList roomList={roomList.data} paging={roomList.paging} />}
      isLoading={isLoading}
    />
  )
}

export default withSnackbar(RoomListContent)
