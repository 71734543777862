// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const env = process.env.REACT_APP_ENV
let awsmobile = {}
if (env === 'production') {
  awsmobile = {
    aws_project_region: 'ap-northeast-1',
    aws_appsync_graphqlEndpoint:
      'https://wklow7maxnb5xmocsbsjcc45vy.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    aws_appsync_region: 'ap-northeast-1',
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_cognito_identity_pool_id:
      'ap-northeast-1:04293330-56ce-4843-9909-61680c790675',
    aws_cognito_region: 'ap-northeast-1',
    aws_user_files_s3_bucket: 'totono-dev',
    aws_user_files_s3_bucket_region: 'ap-northeast-1'
  }
} else {
  awsmobile = {
    aws_project_region: 'ap-northeast-1',
    aws_appsync_graphqlEndpoint:
      'https://37inzu6ttravhadzigehyiukou.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    aws_appsync_region: 'ap-northeast-1',
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_cognito_identity_pool_id:
      'ap-northeast-1:4686fe46-0874-43e5-a29f-3de1d802a8e9',
    aws_cognito_region: 'ap-northeast-1',
    aws_user_files_s3_bucket: 'totono-prod',
    aws_user_files_s3_bucket_region: 'ap-northeast-1'
  }
}

export default awsmobile
