/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import { combineReducers } from 'redux'
// import * as Reducers from './Reducers';
import { connectRouter } from 'connected-react-router'
import {
  admin,
  authorize,
  handle,
  headerBody,
  loadData,
  modalDialog,
  roleData,
  saveSearchCondition,
  searchCondition,
  searchModalDialog,
  searchSaveModalDialog,
  csvInformation,
  matchCsvValue,
  targetIdList,
  csvHeader,
  matchContractIdList,
  selectDeliveryListId,
  selectContractList,
  filterCsvInformation,
  bulkDeliveryDetailList,
  imageList,
  pdfList
} from './Reducers'

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    headerBody: headerBody,
    authorize: authorize,
    admin: admin,
    handle: handle,
    loadData: loadData,
    modalDialog: modalDialog,
    roleData: roleData,
    searchCondition: searchCondition,
    searchModalDialog: searchModalDialog,
    searchSaveModalDialog: searchSaveModalDialog,
    csvInformation: csvInformation,
    matchCsvValue: matchCsvValue,
    targetIdList: targetIdList,
    csvHeader: csvHeader,
    matchContractIdList: matchContractIdList,
    selectDeliveryListId: selectDeliveryListId,
    selectContractList: selectContractList,
    filterCsvInformation: filterCsvInformation,
    bulkDeliveryDetailList: bulkDeliveryDetailList,
    imageList: imageList,
    pdfList: pdfList,
    saveSearchCondition: saveSearchCondition
  })
export default rootReducer
