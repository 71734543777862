import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MessageDetail from './MessageDetail'
import MessageDetailForm from './MessageDetailForm'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  top: {
    flex: 1,
    height: '75%',
    maxHeight: window.innerHeight * 0.725
  },
  formRow: {
    flex: 1,
    '&:nth-child(n+2)': {
      marginTop: 20
    }
  },
  body: {
    height: '25%',
    maxHeight: 180,
    flex: 1,
    bottom: 20
  },
  bottom: {
    flex: 1
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left({
  isLoading,
  thread,
  messageAndAttachmentList,
  companyUserList,
  fetchThread,
  fetchMessageAndAttachmentList,
  body,
  setBody
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {!isLoading && thread && messageAndAttachmentList ? (
        <>
          <div className={classes.top}>
            <MessageDetail
              thread={thread}
              messageAndAttachmentList={messageAndAttachmentList}
              companyUserList={companyUserList}
              fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
            />
          </div>
          <div className={classes.body}>
            <MessageDetailForm
              thread={thread}
              companyUserList={companyUserList}
              fetchThread={fetchThread}
              fetchMessageAndAttachmentList={fetchMessageAndAttachmentList}
              body={body}
              setBody={setBody}
            />
          </div>
        </>
      ) : (
        <Loading isLoading={true} marginTop={20} marginBottom={20} />
      )}
    </div>
  )
}

export default Left
