import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { LastPathContext } from 'context/LastPathContext'

var AWS = require('aws-sdk')
AWS.config.region = process.env.REACT_APP_AWS_REGION
// var roleArn = process.env.REACT_APP_AWS_COGNITO_ROLE_ARN;

const Auth = props => {
  const history = useHistory()
  let [{ lastPath }, dispatch] = React.useContext(LastPathContext)

  if (props.isSignin && !AWS.config.credentials.needsRefresh()) {
    return props.children
  }
  ;(() =>
    dispatch({
      type: 'SET_PATH',
      payload: history.location.pathname
    }))()

  return <Redirect to={'/authorize/signin'} />
}

const mapStateToProps = state => {
  return {
    isSignin: state.authorize.isSignin
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
