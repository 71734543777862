import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeSearchModalDialog } from 'actions/Actions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function SearchModalDialogFooter(props) {
  const classes = useStyles()

  const handleCancel = event => {
    props.setSearchConditionErrors({})
    props.closeSearchModalDialog()
  }

  return (
    <>
      <Button
        onClick={handleCancel}
        variant="contained"
        className={classes.secondaryButton}
      >
        キャンセル
      </Button>
      <Button
        type="submit"
        variant="contained"
        className={classes.primaryButton}
      >
        詳細検索
      </Button>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchModalDialogFooter)
