import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Left from './Left'
import Right from './Right'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { QueryHelper } from 'utils/api.utils'
import { ErrorMessages } from 'utils/errorMessages'

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: theme.palette.c_background.gray,
    height: '100%'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    margin: '32px'
  },
  right: {
    width: '300px',
    backgroundColor: theme.palette.c_white.main
  }
}))

const EditContent = props => {
  const { enqueueSnackbar } = props
  const classes = useStyles()
  const { id, buildingId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [owner, setOwner] = useState(null)
  const [building, setBuilding] = useState(null)
  const [knrDivList, setKnrDivList] = useState({})
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState({})
  const [ownerBuilding, setOwnerBuilding] = useState()

  const fetchOwner = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    const result = await QueryHelper('getOwner', { filter: params })
    if (result.error) {
      enqueueSnackbar('オーナーを取得できませんでした。', {
        variant: 'error'
      })
    } else {
      setOwner(result)
    }
  }

  const fetchBuilding = async buildingId => {
    const params = {
      id: {
        eq: buildingId
      }
    }
    const result = await QueryHelper('getBuilding', { filter: params })
    if (result.error) {
      enqueueSnackbar('建物を取得できませんでした。', {
        variant: 'error'
      })
    } else {
      setBuilding(result)
    }
  }

  const fetchMstTateyaKnrDivList = async () => {
    const result = await QueryHelper('getMSTTateyaKNRDivList', {})
    if (result.error) {
      props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const l = {}
      result.data.forEach(item => {
        l[item.knr_cd] = item.knr_name
      })
      setKnrDivList(l)
    }
  }

  const fetchMstTateyaKnrkeitaiDivList = async () => {
    const result = await QueryHelper('getMSTTateyaKnrkeitaiDivList', {})
    if (result.error) {
      props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const l = {}
      result.data.forEach(item => {
        l[item.knrkeitai_cd] = item.knrkeitai_name
      })
      setKnrkeitaiDivList(l)
    }
  }

  const fetchOwnerBuilding = async (ownerId, buildingId) => {
    const result = await QueryHelper('getOwnerBuilding', {
      owner_id: ownerId,
      building_id: buildingId
    })
    if (result.error) {
      enqueueSnackbar(ErrorMessages.FailedToRetrieveOwnerInfo, {
        variant: 'error'
      })
    } else {
      setOwnerBuilding(result)
    }
  }

  const fetchData = async (OwnerId, BuildingId) => {
    setIsLoading(true)
    await Promise.all([
      fetchOwner(OwnerId),
      fetchBuilding(BuildingId),
      fetchOwnerBuilding(OwnerId, BuildingId),
      fetchMstTateyaKnrDivList(),
      fetchMstTateyaKnrkeitaiDivList()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (id === undefined || buildingId === undefined) return
    fetchData(id, buildingId)
  }, [setOwner])

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div className={classes.leftBody}>
          <Left
            isLoading={isLoading}
            owner={owner}
            building={building}
            knrDivList={knrDivList}
            knrkeitaiDivList={knrkeitaiDivList}
            fetchOwner={fetchOwner}
          />
        </div>
      </div>
      <div className={classes.right}>
        <Right
          isLoading={isLoading}
          ownerBuilding={ownerBuilding}
          building={building}
          fetchOwnerBuilding={fetchOwnerBuilding}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(EditContent)))
