import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RoomCheckListApplicationDetail from './RoomCheckListApplicationDetail'
import Loading from 'components/common/Loading'
import RoomCheckListApplicationForm from './RoomCheckListApplicationForm'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  formRow: {
    flex: 1,
    '&:nth-child(n+2)': {
      marginTop: 20
    }
  }
}))

function Left(props) {
  const classes = useStyles()

  const [content, setContent] = useState({})

  let { isLoading, application, fetchData } = props

  useEffect(() => {
    if (application !== undefined) {
      console.log(application)
      setContent(JSON.parse(application.content))
    }
  }, [application])

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <div className={classes.top}>
            <RoomCheckListApplicationDetail
              roomCheckListJsons={content}
              attachments={props.attachments}
            />
          </div>
          <div className={classes.top}>
            <RoomCheckListApplicationForm
              application={application}
              attachments={props.attachments}
              fetchData={fetchData}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Left
