import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import FormLayout from 'components/common/FormLayout'
import RowWithMovie from 'components/common/RowWithMovie'

import { Auth, Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'
import { AttachmentStatus } from 'utils/enum'

const MAXIMUM_FILE_LENGTH = 1

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%',
    marginTop: 10
  },
  card: {
    width: 120,
    height: 80
  },
  cover: {
    width: 120,
    height: 80
  }
}))

function VideoForm(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const [files, setFiles] = useState([])
  const [params, setParams] = useState([])

  const { isLoading, video } = props

  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const object = {
        status: 1,
        attachment_type_id: 2,
        mime_type: file.type,
        filename: file.name,
        body: reader.result
      }

      let copy1 = []
      Object.assign(copy1, files)
      copy1.push(object)
      let copy2 = []
      Object.assign(copy2, params)
      copy2.push(file)

      setFiles([...copy1])
      setParams([...copy2])
      const splicedParams = [
        ...copy2.filter(
          item =>
            item instanceof File ||
            (Object.keys(item).indexOf('status') !== -1 && item.status == 3)
        )
      ]
      props.handleChange({ videos: [...splicedParams] })
    }
    reader.readAsDataURL(file)
  }

  const handleDelete = index => {
    let copy1 = []
    Object.assign(copy1, files)
    let target1 = copy1[index]
    target1.status = 3
    copy1.splice(index, 1, target1)
    let copy2 = []
    Object.assign(copy2, params)
    let target2 = copy2[index]
    if (target2 instanceof File) {
      copy2.splice(index, 1, {})
    } else {
      if (target2 && target2.attachment) {
        target2 = target2.attachment
      }
      target2.status = 3
      copy2.splice(index, 1, target2)
    }

    setFiles([...copy1])
    setParams([...copy2])
    const splicedParams = [
      ...copy2.filter(
        item =>
          item instanceof File ||
          (Object.keys(item).indexOf('status') !== -1 &&
            item.status == AttachmentStatus.disabled)
      )
    ]
    props.handleChange({ videos: [...splicedParams] })
  }

  useEffect(() => {
    if (video) {
      Auth.currentCredentials().then(async creds => {
        await Promise.all([fetchVideo(video)])
      })
    } else {
      setFiles([])
      setParams([])
    }
  }, [video, setFiles, setParams])

  const fetchVideo = async attachments => {
    const videoObjects = []
    for (let attachment of attachments) {
      const key = attachment.key
      const videoResult = await getStorage(key, attachment.company_id)
      const videoObject = Object.assign(
        {},
        attachment,
        { body: videoResult },
        { attachment: attachment }
      )

      const thumbnailKey = attachment.thumbnail_key
        ? attachment.thumbnail_key
        : attachment.convert_key
      const thumbnailResult = await getStorage(
        thumbnailKey,
        attachment.company_id
      )

      const completeVideoObject = Object.assign(videoObject, attachment, {
        thumbnailBody: thumbnailResult
      })
      videoObjects.push(completeVideoObject)
    }
    setFiles(videoObjects)
    setParams(videoObjects)
  }

  const getStorage = async (key, companyId) => {
    return Storage.get(key, {
      level: 'protected',
      identityId: companyId,
      expires: 60
    })
  }

  return (
    <FormLayout title="添付動画">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {files.length > 0 &&
            files.map(
              (
                { status, filename, body, thumbnailBody, attachment },
                index
              ) => {
                return status == AttachmentStatus.enabled ? (
                  <RowWithMovie
                    key={index}
                    title={filename}
                    movie={body}
                    thumbnailImage={thumbnailBody}
                    handleDelete={() => handleDelete(index)}
                    style={{ marginBottom: 10 }}
                    attachment={attachment}
                    disabledIcon={!isUnrestrictedRole}
                  />
                ) : (
                  <></>
                )
              }
            )}
          {files.filter(item => item.status == AttachmentStatus.enabled)
            .length < MAXIMUM_FILE_LENGTH && (
            <Grid container>
              <Grid item xs={12}>
                <input
                  accept="video/*"
                  className={classes.input}
                  id="contained-button-movie"
                  type="file"
                  onChange={handleChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <label
                htmlFor="contained-button-movie"
                className={classes.labelButton}
              >
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                  disabled={!isUnrestrictedRole}
                >
                  動画追加
                </Button>
              </label>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

export default VideoForm
