import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

function InquireForm(props) {
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const { isLoading, company, setEnableForm } = props
  const handleChange = event => {
    props.handleChange({
      [event.target.name]: event.target.value
    })
  }

  const [values, setValues] = useState({
    business_hours_begin: '',
    business_hours_begin_err: '',
    business_hours_end: '',
    business_hours_end_err: '',
    regular_holiday_display: '',
    regular_holiday_display_err: '',
    url: '',
    url_err: '',
    emergency_tel: '',
    emergency_tel_err: ''
  })

  const checkErr = objects => {
    if (
      objects.business_hours_begin_err ||
      objects.business_hours_end_err ||
      objects.regular_holiday_display_err ||
      objects.url_err ||
      objects.emergency_tel_err
    ) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const isUrl = str => {
    var reg = new RegExp(
      /^(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/g
    )
    var res = reg.test(str)
    return res
  }

  const isNumber = str => {
    var reg = new RegExp(/^[0-9]*$/)
    var res = reg.test(str)
    return res
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputUrlChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      if (isUrl(value)) {
        newValues[name + '_err'] = ''
      } else {
        newValues[name + '_err'] = 'URL形式で入力してください'
      }
    } else {
      newValues[name + '_err'] = ''
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputPhoneChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      if (isNumber(value)) {
        newValues[name + '_err'] = ''
      } else {
        newValues[name + '_err'] = '半角数字で入力してください'
      }
    } else {
      newValues[name + '_err'] = ''
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    const business_hours =
      'company' in company && company.company
        ? JSON.parse(company.company.business_hours)
        : {
            begin: '00:00',
            end: '00:00'
          }
    const regular_holiday =
      'company' in company && company.company
        ? JSON.parse(company.company.regular_holiday)
        : { display: '' }
    const initializeValues = {
      business_hours_begin: business_hours.begin,
      business_hours_begin_err: '',
      business_hours_end: business_hours.end,
      business_hours_end_err: '',
      regular_holiday_display: regular_holiday.display,
      regular_holiday_display_err: '',
      url: 'company' in company && company.company ? company.company.url : '',
      url_err:
        'company' in company && company.company
          ? isUrl(company.company.url)
            ? ''
            : 'URL形式で入力してください'
          : '',
      emergency_tel:
        'company' in company && company.company
          ? company.company.emergency_tel
          : '',
      emergency_tel_err:
        'company' in company && company.company
          ? isNumber(company.company.emergency_tel)
            ? ''
            : '半角数字で入力してください'
          : ''
    }
    setValues(initializeValues)
    checkErr(initializeValues)
  }, [company, setValues])

  return (
    <FormLayout title="お問い合わせ">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {company && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  error={values.business_hours_begin_err != '' ? true : false}
                  helperText={values.business_hours_begin_err}
                  required
                  id="business_hours_begin"
                  name="business_hours_begin"
                  label="営業開始時間"
                  type="time"
                  fullWidth
                  variant="filled"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    step: 600
                  }}
                  defaultValue={values.business_hours_begin}
                  onChange={handleInputRequiredChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  error={values.business_hours_end_err != '' ? true : false}
                  helperText={values.business_hours_end_err}
                  required
                  id="business_hours_end"
                  name="business_hours_end"
                  label="営業終了時間"
                  type="time"
                  fullWidth
                  variant="filled"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    step: 600
                  }}
                  defaultValue={values.business_hours_end}
                  onChange={handleInputRequiredChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  error={
                    values.regular_holiday_display_err != '' ? true : false
                  }
                  helperText={values.regular_holiday_display_err}
                  required
                  id="regular_holiday_display"
                  name="regular_holiday_display"
                  label="定休日"
                  fullWidth
                  variant="filled"
                  defaultValue={values.regular_holiday_display}
                  onChange={handleInputRequiredChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  error={values.url_err != '' ? true : false}
                  helperText={values.url_err}
                  id="url"
                  name="url"
                  label="Webサイト"
                  fullWidth
                  variant="filled"
                  defaultValue={values.url}
                  onChange={handleInputUrlChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="emergency_name"
                  name="emergency_name"
                  label="緊急連絡先名"
                  fullWidth
                  autoComplete="emergency_name"
                  variant="filled"
                  defaultValue={
                    'company' in company && company.company
                      ? company.company.emergency_name
                      : ''
                  }
                  onChange={handleChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  error={values.emergency_tel_err != '' ? true : false}
                  helperText={values.emergency_tel_err}
                  id="emergency_tel"
                  name="emergency_tel"
                  label="緊急連絡先電話番号"
                  fullWidth
                  variant="filled"
                  defaultValue={values.emergency_tel}
                  onChange={handleInputPhoneChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InquireForm)
