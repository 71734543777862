import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//   },
//   header: {
//     height: "100px",
//     backgroundColor: "#888",
//     color: "#fff",
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

function Footer(props) {
  // const classes = useStyles();

  return (
    // <footer style={{height: "100px", backgroundColor: "#fff", color: "#000"}}>
    //   <p>フッター</p>
    // </footer>
    <></>
  )
}

export default Footer
