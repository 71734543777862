import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import { AppUsage } from 'utils/enum'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function SearchFormContent(props) {
  const { ttyTnpList, searchCondition, searchConditionErrors } = props

  const [usageList] = useState([
    { value: AppUsage.none.code, label: AppUsage.none.value },
    { value: AppUsage.enabled.code, label: AppUsage.enabled.value },
    { value: AppUsage.disabled.code, label: AppUsage.disabled.value }
  ])
  const [kykKeiyakuDateFrom, setKykKeiyakuDateFrom] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.kyk_keiyaku_date_from' in searchCondition
      ? searchCondition['management.contract.kyk_keiyaku_date_from'] != ''
        ? searchCondition['management.contract.kyk_keiyaku_date_from']
        : null
      : null
  )
  const [kykKeiyakuDateTo, setKykKeiyakuDateTo] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.kyk_keiyaku_date_to' in searchCondition
      ? searchCondition['management.contract.kyk_keiyaku_date_to'] != ''
        ? searchCondition['management.contract.kyk_keiyaku_date_to']
        : null
      : null
  )
  const [registeredAtFrom, setRegisteredAtFrom] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.registered_at_from' in searchCondition
      ? searchCondition['management.contract.registered_at_from'] != ''
        ? searchCondition['management.contract.registered_at_from']
        : null
      : null
  )
  const [registeredAtTo, setRegisteredAtTo] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.registered_at_to' in searchCondition
      ? searchCondition['management.contract.registered_at_to'] != ''
        ? searchCondition['management.contract.registered_at_to']
        : null
      : null
  )
  const [lastLoginAtFrom, setLastLoginAtFrom] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.last_login_at_from' in searchCondition
      ? searchCondition['management.contract.last_login_at_from'] != ''
        ? searchCondition['management.contract.last_login_at_from']
        : null
      : null
  )
  const [lastLoginAtTo, setLastLoginAtTo] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.last_login_at_to' in searchCondition
      ? searchCondition['management.contract.last_login_at_to'] != ''
        ? searchCondition['management.contract.last_login_at_to']
        : null
      : null
  )

  const [hasDefect, setHasDefect] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.kyk_status_div' in searchCondition
      ? true
      : false
  )

  const [isMember1, setIsMember1] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.memberFlag1' in searchCondition &&
      searchCondition['management.contract.memberFlag1']
      ? true
      : false
  )

  const [isMember2, setIsMember2] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.memberFlag2' in searchCondition &&
      searchCondition['management.contract.memberFlag2']
      ? true
      : false
  )

  const [selectedTtyTnpId, setSelectedTtyTnpId] = useState(
    Object.keys(searchCondition).length &&
      'management.contract.tty_tnp' in searchCondition &&
      searchCondition['management.contract.tty_tnp'] != ''
      ? searchCondition['management.contract.tty_tnp']
      : null
  )

  const handleChangeKykKeiyakuDateFrom = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setKykKeiyakuDateFrom(date)
  }

  const handleChangeKykKeiyakuDateTo = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setKykKeiyakuDateTo(date)
  }

  const handleChangeRegisteredAtFrom = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    setRegisteredAtFrom(date)
  }

  const handleChangeRegisteredAtTo = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setRegisteredAtTo(date)
  }

  const handleChangeLastLoginAtFrom = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    setLastLoginAtFrom(date)
  }

  const handleChangeLastLoginAtTo = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setLastLoginAtTo(date)
  }

  const handleChangeMemberFlag1 = () => {
    setIsMember1(!isMember1)
  }

  const handleChangeMemberFlag2 = () => {
    setIsMember2(!isMember2)
  }

  const handleChangeTtyTnpId = event => {
    setSelectedTtyTnpId(event.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextInput
          name="management.contract.kky_knr_no"
          label="顧客番号"
          defaultValue={
            'management.contract.kky_knr_no' in searchCondition
              ? searchCondition['management.contract.kky_knr_no']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.contract.tenant_id"
          label="入居者名（フリガナ）"
          defaultValue={
            'management.contract.tenant_id' in searchCondition
              ? searchCondition['management.contract.tenant_id']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.contract.contractor_id"
          label="契約者名（フリガナ）"
          defaultValue={
            'management.contract.contractor_id' in searchCondition
              ? searchCondition['management.contract.contractor_id']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.contract.TTY_NAME"
          label="物件名(フリガナ)"
          defaultValue={
            'management.contract.TTY_NAME' in searchCondition
              ? searchCondition['management.contract.TTY_NAME']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.contract.kky_tel_02"
          label="電話番号"
          defaultValue={
            'management.contract.kky_tel_02' in searchCondition
              ? searchCondition['management.contract.kky_tel_02']
              : ''
          }
          fullWidth
          variant="filled"
          helperText={'ハイフン抜きで入力してください'}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="management.contract.kyk_keiyaku_date_from"
            name="management.contract.kyk_keiyaku_date_from"
            label="契約日（成約日）From"
            value={kykKeiyakuDateFrom}
            onChange={handleChangeKykKeiyakuDateFrom}
            error={
              searchConditionErrors[
                'management.contract.kyk_keiyaku_date_from.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'management.contract.kyk_keiyaku_date_from.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="management.contract.kyk_keiyaku_date_to"
            name="management.contract.kyk_keiyaku_date_to"
            label="契約日（成約日）To"
            value={kykKeiyakuDateTo}
            onChange={handleChangeKykKeiyakuDateTo}
            error={
              searchConditionErrors[
                'management.contract.kyk_keiyaku_date_to.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'management.contract.kyk_keiyaku_date_to.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="management.contract.registered_at_from"
            name="management.contract.registered_at_from"
            label="アプリ利用開始日From"
            value={registeredAtFrom}
            onChange={handleChangeRegisteredAtFrom}
            error={
              searchConditionErrors[
                'management.contract.registered_at_from.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'management.contract.registered_at_from.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="management.contract.registered_at_to"
            name="management.contract.registered_at_to"
            label="アプリ利用開始日To"
            value={registeredAtTo}
            onChange={handleChangeRegisteredAtTo}
            error={
              searchConditionErrors[
                'management.contract.registered_at_to.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'management.contract.registered_at_to.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="management.contract.last_login_at_from"
            name="management.contract.last_login_at_from"
            label="最終ログイン日From"
            value={lastLoginAtFrom}
            onChange={handleChangeLastLoginAtFrom}
            error={
              searchConditionErrors[
                'management.contract.last_login_at_from.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'management.contract.last_login_at_from.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="management.contract.last_login_at_to"
            name="management.contract.last_login_at_to"
            label="最終ログイン日To"
            value={lastLoginAtTo}
            onChange={handleChangeLastLoginAtTo}
            error={
              searchConditionErrors[
                'management.contract.last_login_at_to.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'management.contract.last_login_at_to.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.contract.is_usage"
            label="アプリ利用"
            defaultValue={
              'management.contract.is_usage' in searchCondition
                ? searchCondition['management.contract.is_usage']
                : ''
            }
            items={usageList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              name="management.contract.kyk_status_div"
              checked={hasDefect}
              onChange={checked => setHasDefect(!hasDefect)}
              value={4}
            />
          }
          label="退去者(解約・精算完了)を含む"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              name={'management.contract.memberFlag1'}
              checked={isMember1}
              onChange={handleChangeMemberFlag1}
            />
          }
          label="会員フラグ1"
          value={isMember1}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              name={'management.contract.memberFlag2'}
              checked={isMember2}
              onChange={handleChangeMemberFlag2}
            />
          }
          label="会員フラグ2"
          value={isMember2}
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          name="management.contract.tty_tnp"
          label={'建物取扱店舗'}
          onChange={handleChangeTtyTnpId}
          defaultValue={selectedTtyTnpId}
          items={ttyTnpList}
        />
      </Grid>
    </Grid>
  )
}

export default withSnackbar(SearchFormContent)
