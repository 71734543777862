import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import {
  FormControl,
  TableContainer,
  TablePagination,
  Typography
} from '@material-ui/core'
import TextInput from 'components/common/Form/TextInput'
import Button from '@material-ui/core/Button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { closeModalDialog, setLoadData } from 'actions/Actions'
import { QueryHelper, MutationHelper } from 'utils/api.utils'

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  tableContainer: {
    maxHeight: '300px',
    overflowY: 'auto'
  }
}))

const PAGE_LIMIT = 10

const columns = [
  { id: 'id', label: 'ID', minWidth: 80 },
  { id: 'name', label: 'オーナー名', minWidth: 100 },
  { id: 'owner_furigana', label: 'フリガナ', minWidth: 100 },
  {
    id: 'login_id',
    label: 'ログインID(メールアドレス)',
    minWidth: 100,
    hasSort: false
  },
  { id: 'buildings', label: '物件', minWidth: 80 },
  { id: 'select', label: '', minWidth: 80 }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

const ExistingOwner = props => {
  const {
    building,
    closeModalDialog,
    setLoadData,
    enqueueSnackbar,
    fetchOwnerList
  } = props
  const { id } = useParams()
  const classes = useStyles()
  const location = useLocation()
  const { pageId } = useParams()
  const [ownerList, setOwnerList] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [searchConditions, setSearchConditions] = useState(
    location.state !== undefined ? location.state : {}
  )

  const [searchValue, setSearchValue] = useState(
    'owner.name' in searchConditions ? searchConditions['owner.name'] : ''
  )

  const handleSearchInputChange = e => {
    setSearchValue(e.target.value)
  }

  const handleSearchClick = () => {
    const newSearchConditions = Object.assign({}, searchConditions, {
      'owner.name': searchValue
    })
    setSearchConditions(newSearchConditions)
    setPageNumber(1)
  }

  const handleChangePage = (event, newPage) => {
    var params = Object.assign({}, searchConditions, {
      pageNumber: newPage + 1
    })
    setSearchConditions(params, setPageNumber(newPage + 1))
    setPageNumber(newPage + 1)
  }

  const fetchUnlinkedOwnerList = async (offset, limit) => {
    const filter = {
      building_id: {
        ne: id
      }
    }

    Object.keys(searchConditions).forEach(function(key) {
      if (key === 'owner.name' && searchConditions[key]) {
        filter.name = { contains: searchConditions[key] }
      }
    })

    const result = await QueryHelper(
      'getOwnerListByBuilding',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset === 0
    )

    if (result.error) {
      enqueueSnackbar('オーナー情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setOwnerList(result)
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await fetchUnlinkedOwnerList(offset, PAGE_LIMIT)
    setIsLoading(false)
  }

  const handleSelectOwner = async ownerId => {
    setLoadData(true)

    const params = {}

    if (ownerId) {
      params.owner_id = ownerId
    }

    if (building.id) {
      params.building_id = building.id
    }

    if (!window.confirm(`オーナーを登録しますか？`)) {
      setLoadData(false)
      return
    }

    const result = await MutationHelper('createOwnerBuilding', {
      input: params
    })

    if (result.error) {
      enqueueSnackbar('オーナーを登録できませんでした', {
        variant: 'error'
      })
      setLoadData(false)
      return
    }

    enqueueSnackbar('オーナーを登録しました。', {
      variant: 'success'
    })
    fetchOwnerList(building.id)
    closeModalDialog()
    setLoadData(false)
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    fetchData(offset)
  }, [setOwnerList, searchConditions, pageNumber])

  return (
    <>
      {!isLoading && ownerList && ownerList.data ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>既存オーナーを選択</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextInput
                id="owner.name"
                name="owner.name"
                placeholder="オーナー名（フリガナ）で検索"
                defaultValue={
                  'owner.name' in searchConditions
                    ? searchConditions['owner.name']
                    : ''
                }
                onChange={handleSearchInputChange}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleSearchClick()
                  }
                }}
                variant="filled"
                hiddenLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} className={classes.button}>
            <Button
              variant="contained"
              className={classes.primaryButton}
              onClick={handleSearchClick}
            >
              検索
            </Button>
          </Grid>
          <Grid item xs={12} style={{ padding: 0 }}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={ownerList.paging.count}
              rowsPerPage={PAGE_LIMIT}
              page={pageNumber - 1}
              onChangePage={handleChangePage}
            />
          </Grid>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ownerList.data.length
                  ? ownerList.data.map(row => {
                      return (
                        <TableRow key={row.owner.id}>
                          <TableCell>{row.owner.id}</TableCell>
                          <TableCell>{row.owner.name}</TableCell>
                          <TableCell>{row.owner.name_kana}</TableCell>
                          <TableCell>{row.app_user.username}</TableCell>
                          <TableCell>{row.owner_buildings.length}件</TableCell>
                          <TableCell>
                            <Button
                              className={classes.primaryButton}
                              onClick={() => handleSelectOwner(row.owner.id)}
                            >
                              選択
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ExistingOwner))
