import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(n+2)': {
      marginTop: 10
    }
  },
  imageRow: {
    display: 'flex',
    alignItems: 'center'
  },
  iconRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 56,
    backgroundColor: '#EDF1F5'
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 'auto',
    marginLeft: 20,
    fontWeight: 'bold',
    fontSize: '16px'
  }
}))

export default function RowWithPdf({
  attachment,
  handleClick,
  title,
  handleDelete,
  isDeleteButtonView = true,
  display,
  buttonState,
  disabledIcon
}) {
  const classes = useStyles()
  return (
    <Grid container className={classes.imageContainer}>
      <Grid xs={11} item>
        <Grid
          container
          className={classes.imageRow}
          onClick={event => handleClick(attachment, event)}
        >
          <Grid item className={classes.iconRow}>
            <PictureAsPdfIcon />
          </Grid>
          <Grid item xs>
            <span className={classes.title} style={{}}>
              {title}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {isDeleteButtonView && (
        <Grid xs={1} item>
          <Grid container justify="flex-end">
            <Grid item zeroMinWidth>
              {display == undefined ? (
                <IconButton
                  aria-label="削除"
                  color="primary"
                  onClick={handleDelete}
                  disabled={disabledIcon}
                >
                  <DeleteIcon />
                </IconButton>
              ) : buttonState ? (
                <IconButton
                  aria-label="削除"
                  color="primary"
                  onClick={handleDelete}
                  disabled={disabledIcon}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
