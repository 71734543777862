import React from 'react'
import { connect } from 'react-redux'
import { openModalDialog } from 'actions/Actions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Label from 'components/common/Label'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { useRole } from 'hooks/useRole'
import { getKnrDiv } from 'utils/building.utils'

const columns = [
  { id: 'is_management', label: 'アプリ', minWidth: 80 },
  { id: 'ID', label: 'ID', minWidth: 68 },
  { id: 'TTY_NAME', label: '物件名', minWidth: 177 },
  { id: 'TTY_PRF_NAME', label: '所在地', minWidth: 218 },
  { id: 'TTY_TYS_NAME', label: '種別', minWidth: 68 },
  { id: 'TTY_KNR_DIV', label: '区分', minWidth: 88 },
  { id: 'TTY_KNR_KIND', label: '形態', minWidth: 68 },
  { id: 'TTY_KNRPATTERN_NAME', label: '管理パターン', minWidth: 100 },
  { id: 'TTY_TNP_NAME', label: '建物取扱店舗', minWidth: 100 },
  { id: 'TTY_TOTAL_COUNT', label: '総戸数', minWidth: 73 },
  { id: 'room_cnt', label: '対象戸数', minWidth: 73 },
  { id: 'contract_cnt', label: '入居者数', minWidth: 73 },
  { id: 'customer_cnt', label: '利用者', minWidth: 72 }
]

const useStyles = makeStyles(theme => ({
  name: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2C3F58'
  },
  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809D'
  }
}))

function ManagementLabel({ is_management }) {
  const found = is_management
    ? { label: '有効', color: 'blue' }
    : { label: '無効', color: 'gray' }
  return <Label color={found.color} text={found.label} />
}

function List(props) {
  const classes = useStyles()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const isTextSelected = useIsTextSelected()

  const handleClick = building => {
    if (isTextSelected()) return

    props.setBuilding(building)
    props.openModalDialog()
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.buildingList.length > 0 &&
            props.buildingList.map(row => {
              return (
                <TableRow
                  onClick={() => handleClick(row)}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.building.id}
                  style={{ pointerEvents: !isUnrestrictedRole && 'none' }}
                >
                  <TableCell>
                    <ManagementLabel
                      is_management={row.building.is_management}
                    />
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.mst_tateya.TTY_ID}
                  </TableCell>
                  <TableCell>
                    <Grid container xs={12} alignItems="left">
                      <Grid
                        item
                        xs={12}
                        className={classes.name}
                        alignItems="left"
                      >
                        {row.mst_tateya.TTY_NAME}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.nameKana}
                        alignItems="left"
                      >
                        {row.mst_tateya.TTY_NAME_KANA}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.mst_tateya.TTY_PRF_NAME} {row.mst_tateya.TTY_CTY_NAME}{' '}
                    {row.mst_tateya.TTY_TWN_NAME} {row.mst_tateya.TTY_ADDR_01}{' '}
                    {row.mst_tateya.TTY_ADDR_02}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.mst_tateya.TTY_TYS_NAME}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {getKnrDiv(row.mst_tateya, props.knrDivList)}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.mst_tateya.TTY_KNRKEITAI_DIV in props.knrkeitaiDivList
                      ? props.knrkeitaiDivList[row.mst_tateya.TTY_KNRKEITAI_DIV]
                      : ''}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.mst_tateya.TTY_KNRPATTERN_NAME}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.mst_tateya.TTY_TNP_NAME}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.mst_tateya.TTY_TOTAL_COUNT}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.building.room_cnt}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.building.contract_cnt}
                  </TableCell>
                  <TableCell className={classes.name}>
                    {row.building.customer_cnt}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    openModalDialog: () => {
      dispatch(openModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(List))
