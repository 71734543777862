import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import { Dialog } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  card: {
    width: props => (props.thumbnailWidth ? props.thumbnailWidth : 160),
    height: props => (props.thumbnailHeight ? props.thumbnailHeight : 120)
  },
  cover: {
    width: props => (props.thumbnailWidth ? props.thumbnailWidth : 160),
    height: props => (props.thumbnailHeight ? props.thumbnailHeight : 120)
  }
}))

export default function Thumbnail({ image, title, ...props }) {
  const classes = useStyles(props)

  const [isOpen, setOpen] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    let img = new Image()
    img.onload = e => {
      if (img.width > img.height) {
        let ratio = img.height / img.width
        let maxWidth = window.innerWidth - 84
        let value = img.width > maxWidth ? maxWidth : img.width
        setWidth(value)
        setHeight(value * ratio)
      } else {
        let ratio = img.width / img.height
        let maxHeight = window.innerHeight - 84
        let value = img.height > maxHeight ? maxHeight : img.height
        setWidth(value * ratio)
        setHeight(value)
      }
    }
    img.src = image
  }, [setWidth, setHeight])

  return (
    <div {...props}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cover}
          image={image}
          title={title}
          onClick={() => setOpen(true)}
        />
      </Card>
      <Dialog
        onClose={() => setOpen(false)}
        open={isOpen}
        maxWidth={false}
        scroll={'body'}
      >
        <Card>
          <CardMedia
            component="img"
            image={image}
            // title={title}
            style={{
              width: width,
              height: height,
              maxWidth: width,
              maxHeight: height
            }}
          />
        </Card>
      </Dialog>
    </div>
  )
}
