import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormLayout from 'components/common/FormLayout'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: 'unset'
  },
  iconButton: {
    color: '#778699'
  },
  labelButtonTextColor: {
    color: theme.palette.c_light_blue.main
  },
  divider: {
    margin: '20px -20px 20px -20px'
  }
}))

function CompanyHoliday(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const {
    companyHolidayList,
    setCompanyHolidayList,
    deleteCompanyHolidayList,
    setDeleteCompanyHolidayList
  } = props

  const handleChangeAddHoliday = event => {
    const newDate = new Date()
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    companyHolidayList.push({ status: 1, holiday: newDate })
    setCompanyHolidayList([...companyHolidayList])
  }

  const handleChange = (date, row, index) => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    const companyHoliday = companyHolidayList[index]
    companyHolidayList[index] = Object.assign({}, companyHoliday, {
      holiday: date,
      isChange: true
    })
    setCompanyHolidayList([...companyHolidayList])
  }

  const handleDelete = (date, row, index) => {
    const companyHoliday = companyHolidayList[index]
    if (companyHoliday && companyHoliday.id) {
      const deleteCompanyHoliday = Object.assign({}, companyHoliday, {
        status: 3
      })
      deleteCompanyHolidayList.push(deleteCompanyHoliday)
      setDeleteCompanyHolidayList([...deleteCompanyHolidayList])
    }
    companyHolidayList.splice(index, 1)
    setCompanyHolidayList([...companyHolidayList])
  }

  return (
    <>
      <Paper>
        <FormLayout title="特別休業日" className={classes.form}>
          {companyHolidayList.map((row, index) => {
            return (
              <Grid container xs={12} alignItems="center" key={index}>
                <Grid item xs={11} alignItems="left">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeJa}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      inputVariant="filled"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="holiday"
                      name="holiday"
                      label="特別休業日"
                      value={row.holiday}
                      onChange={event => handleChange(event, row, index)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      disabled={!isUnrestrictedRole}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={1} alignItems="center">
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={date => handleDelete(date, row, index)}
                    disabled={!isUnrestrictedRole}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )
          })}
          <Divider className={classes.divider} />
          <Grid container xs={12} alignItems="center">
            <Grid item xs={12} alignItems="left">
              <Button
                fullWidth
                size="large"
                variant="outlined"
                startIcon={<AddIcon />}
                className={classes.labelButtonTextColor}
                onClick={handleChangeAddHoliday}
                disabled={!isUnrestrictedRole}
              >
                特別休業日を追加
              </Button>
            </Grid>
          </Grid>
        </FormLayout>
      </Paper>
    </>
  )
}

export default CompanyHoliday
