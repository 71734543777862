import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FileForm from './FileForm'
import BookmarkForm from './BookmarkForm'
import { connect } from 'react-redux'
import { handleClear } from 'actions/Actions'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  top: {
    backgroundColor: '#fff'
  },
  body: {
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left(props) {
  const classes = useStyles()

  const [data, setData] = useState({})
  const { isLoading, bookmark, errors } = props
  useEffect(() => {
    props.handleClear()
    if (0 !== Object.keys(bookmark).length) {
      fetchBookmark(bookmark.bookmark.id)
    } else {
      setData({})
    }
  }, [bookmark, setData])

  const fetchBookmark = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    const result = await QueryHelper('getBookmark', { filter: params })
    if (!result.error) {
      setData(result)
    } else {
      props.enqueueSnackbar(`しおり情報を取得できませんでした。`)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <BookmarkForm isLoading={isLoading} bookmark={data} errors={errors} />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <FileForm isLoading={isLoading} bookmark={data} errors={errors} />
      </div>
      <div className={classes.bottom}></div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Left))
