import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FormLayout from 'components/common/FormLayout'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: 'unset'
  },
  divider: {
    margin: '20px -20px 20px -20px'
  },
  wrapperButton: {
    textAlign: 'end'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    marginRight: '20px',
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  body: {
    width: '100%',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '16px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W3',
    lineHeight: '24px',
    border: 'none',
    backgroundColor: '#f7f8fa'
  },
  disabledTextField: {
    backgroundColor: '#e0e0e0',
    color: '#9e9e9e'
  }
}))

function AutoReplyMessageBody(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { messageBody, setMessageBody, handleCancel } = props

  const handleChange = event => {
    setMessageBody(event.target.value)
  }

  return (
    <>
      <Paper>
        <FormLayout title="返信内容" className={classes.form}>
          <Grid container xs={12} alignItems="center">
            <Grid item xs={12} alignItems="center">
              <TextareaAutosize
                required
                label={'返信内容を記載'}
                id="body"
                name="body"
                fullWidth
                rowsMin="6"
                className={`${classes.body} ${!isUnrestrictedRole &&
                  classes.disabledTextField}`}
                defaultValue={messageBody}
                value={messageBody}
                onChange={handleChange}
                placeholder={'返信内容を記載*'}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container xs={12} alignItems="center">
            <Grid item xs={9} alignItems="center" />
            <Grid
              item
              xs={3}
              alignItems="right"
              className={classes.wrapperButton}
            >
              <Button
                variant="contained"
                className={classes.secondaryButton}
                onClick={handleCancel}
                disabled={!isUnrestrictedRole}
              >
                キャンセル
              </Button>
              <Button
                variant="contained"
                type="submit"
                className={classes.primaryButton}
                disabled={!isUnrestrictedRole}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </FormLayout>
      </Paper>
    </>
  )
}

export default AutoReplyMessageBody
