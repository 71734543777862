import React from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  button: {
    marginLeft: '16px',
    color: '#fff'
  }
}))

function Body(props) {
  const classes = useStyles()
  // const history = useHistory();
  // console.log(props.history);

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={() => props.history.goBack()}
      >
        {/* <IconButton
        onClick={() => props.history.push(`/management/message-template`)}
        aria-label="back"
        className={classes.button}
      > */}
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>{props.title}</Typography>
    </>
  )
}

export default withRouter(Body)
