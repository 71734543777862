import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setSignin } from 'actions/Actions'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import MessageList from 'components/common/Header/MessageList'
import ApplicationList from 'components/common/Header/ApplicationList'
import NoticeList from 'components/common/Header/NoticeList'
import Left from './Left'
import DefaultBody from './DefaultBody'
import Right from './Right'
import { Divider } from '@material-ui/core'
import { ThreadState } from 'utils/enum'
import { QueryHelper } from 'utils/api.utils'
import UserMenu from './UserMenu'
import { NotificationContext } from 'context/NotificationContext'
import { AuthIpAddress } from 'components/common/AuthIpAddress'
import { ErrorMessages } from 'utils/errorMessages'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiAppBar-root': {
      height: '64px'
    },
    '.MuiAppBar-colorPrimary': {
      backgroundColor: '#35425B'
    },
    '.MuiDrawer-paper': {
      maxHeight: '600px'
    }
  },
  divider: {
    backgroundColor: '#778699'
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
  list: {
    width: '400px'
  },
  container: {
    maxHeight: 540,
    maxWidth: '100%'
  }
}))

function Header(props) {
  const history = useHistory()
  const classes = useStyles()
  const [selectedColumn, setSelectedColumn] = useState('')
  const [drawerType, setDrawerType] = useState(0)
  const [drawerState, setDrawerState] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openMessages, setOpenMessages] = useState()
  const [totalMessageCount, setTotalMessageCount] = useState(0)
  const [application, setApplication] = useState({})
  const [applicationCount, setApplicationCount] = useState(0)
  const [notice, setNotice] = useState({})
  const [noticeCount, setNoticeCount] = useState(0)
  const anchorRef = React.useRef(null)

  const [notificationContext, dispatchNotification] = React.useContext(
    NotificationContext
  )
  // dispatchNotification({
  //   type: 'SET',
  //   payload: false
  // });
  const isReload = notificationContext && notificationContext.isReload

  useEffect(() => {
    ;(async () => {
      const result = await AuthIpAddress(history)
      if (result) {
        fetchData()
      } else if (result === false) {
        props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
          variant: 'error'
        })
      }
    })()
  }, [props, isReload])

  const fetchData = async () => {
    const userMessages = await fetchMessage()
    const ownerMessages = await fetchOwnerMessage()
    await Promise.all([fetchApplication(), fetchNotice()])

    const mergedResult = mergeAndSortMessages(userMessages, ownerMessages, 20)
    setOpenMessages(mergedResult)

    const totalMessageCount =
      userMessages.paging.count + ownerMessages.paging.count
    setTotalMessageCount(totalMessageCount)
  }

  const mergeAndSortMessages = (userMessages, ownerMessages, limit) => {
    const mergedMessages = [...userMessages.data, ...ownerMessages.data]
    mergedMessages.sort(
      (a, b) => new Date(b.thread.created_at) - new Date(a.thread.created_at)
    )
    const limitedMessages = mergedMessages.slice(0, limit)
    return {
      data: limitedMessages,
      paging: {
        count: limitedMessages.length
      }
    }
  }

  const fetchThreadList = async params => {
    const result = await QueryHelper('getThreadListBySearchCondition', {
      filter: params,
      offset: 0,
      limit: 20
    })
    return result
  }

  const fetchMessage = async () => {
    const params = {
      thread_state_id: { eq: ThreadState.open.code }
    }
    return await fetchThreadList(params)
  }

  const fetchOwnerMessage = async () => {
    const params = {
      thread_state_id: { eq: ThreadState.open.code },
      is_owner: true
    }
    return await fetchThreadList(params)
  }

  const fetchApplication = async () => {
    const params = {}
    params.application_state_id = { eq: 1 }
    const result = await QueryHelper('getApplicationList', {
      filter: params,
      offset: 0,
      limit: 20
    })
    setApplication(result)
    setApplicationCount(result.paging.count)
  }

  const fetchNotice = async () => {
    const params = {}
    const result = await QueryHelper('getCompanyNoticeList', {
      filter: params,
      offset: 0,
      limit: 20
    })
    const count = getNoticeCount(result)
    setNotice(result)
    setNoticeCount(count)
  }

  const getNoticeCount = result => {
    if (result == null) {
      return null
    }
    const expirationDate = new Date()
    const SUBTRACT_DATE = 14
    expirationDate.setDate(expirationDate.getDate() - SUBTRACT_DATE)
    const notice = result.data.filter(
      notice => new Date(notice.companyNotice.updated_at) > expirationDate
    )
    const count = notice.length
    return count
  }

  const toggleDrawer = (type, side, open) => async event => {
    setDrawerType(type)
    // setIsLoading(true);
    setSelectedColumn(side)
    setDrawerState(open)
  }

  return (
    <AppBar
      position="fixed"
      style={{ width: '100%', boxShadow: 'none', zIndex: 999 }}
    >
      <Toolbar style={{ flex: 1 }}>
        <Left style={{ width: '231px', display: 'flex' }} />
        <Divider orientation="vertical" className={classes.divider} />
        {props.headerBody ? props.headerBody : <DefaultBody />}
        <Right
          toggleDrawer={toggleDrawer}
          messageCount={totalMessageCount}
          applicationCount={applicationCount}
          notificationCount={noticeCount}
          anchorRef={anchorRef}
        />
      </Toolbar>
      {drawerType === 1 && (
        <Drawer
          open={drawerState}
          onClose={toggleDrawer(selectedColumn, false)}
          anchor="right"
        >
          <MessageList
            toggleDrawer={toggleDrawer(0, selectedColumn, false)}
            items={openMessages.data}
            isLoading={isLoading}
          />
        </Drawer>
      )}
      {drawerType === 2 && (
        <Drawer
          open={drawerState}
          onClose={toggleDrawer(selectedColumn, false)}
          anchor="right"
        >
          <ApplicationList
            toggleDrawer={toggleDrawer(0, selectedColumn, false)}
            items={application.data}
            isLoading={isLoading}
          />
        </Drawer>
      )}
      {drawerType === 3 && (
        <Drawer
          open={drawerState}
          onClose={toggleDrawer(selectedColumn, false)}
          anchor="right"
        >
          <NoticeList
            toggleDrawer={toggleDrawer(0, selectedColumn, false)}
            items={notice.data}
            isLoading={isLoading}
          />
        </Drawer>
      )}
      {drawerType === 4 && (
        <UserMenu
          drawerState={drawerState}
          toggleDrawer={toggleDrawer(0, selectedColumn, false)}
          anchorRef={anchorRef}
          setSignin={setSignin}
        />
      )}
    </AppBar>
  )
}

const mapStateToProps = state => {
  return {
    headerBody: state.headerBody.component
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setSignin: isSignin => {
      dispatch(setSignin(isSignin))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
