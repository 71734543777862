import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ResidentCancellationApplicationDetail from './ResidentCancellationApplicationDetail'
import ResidentCancellationApplicationForm from './ResidentCancellationApplicationForm'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  formRow: {
    flex: 1,
    '&:nth-child(n+2)': {
      marginTop: 20
    }
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left({
  isLoading,
  application,
  fetchResidentCancellationApplication
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <ResidentCancellationApplicationDetail
          isLoading={isLoading}
          application={application}
          fetchResidentCancellationApplication={
            fetchResidentCancellationApplication
          }
        />
      </div>
      <div className={classes.formRow}>
        {application && (
          <ResidentCancellationApplicationForm
            isLoading={isLoading}
            application={application}
            fetchResidentCancellationApplication={
              fetchResidentCancellationApplication
            }
          />
        )}
      </div>
      <div className={classes.divider} />
      <div className={classes.body}></div>
      <div className={classes.divider} />
      <div className={classes.bottom}></div>
    </div>
  )
}

export default Left
