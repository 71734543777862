import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog } from 'actions/Actions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  textRight: {
    textAlign: 'right'
  },
  center: {
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  centerGray: {
    textAlign: 'center',
    color: 'gray'
  }
}))

function FormFooter(props) {
  const classes = useStyles()
  return (
    <>
      <Grid container spacing={4} className={classes.center}>
        <Grid item xs={4} sm={4} spacing={2} className={classes.textRight}>
          <Button
            onClick={props.closeModalDialog}
            color="primary"
            variant="contained"
            className={classes.secondaryButton}
          >
            キャンセル
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} spacing={2}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            className={classes.primaryButton}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFooter)
