import React from 'react'
import DetailLayout from 'components/common/DetailLayout'
import BuildingDetail from './BuildingDetail'

const BuildingDetailContent = props => {
  const { building, knrDivList, knrkeitaiDivList, isLoading } = props

  return (
    <DetailLayout title="建物詳細" isLoading={isLoading}>
      <BuildingDetail
        building={building}
        knrDivList={knrDivList}
        knrkeitaiDivList={knrkeitaiDivList}
      />
    </DetailLayout>
  )
}

export default BuildingDetailContent
