import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { setSignin } from 'actions/Actions'
import { Cache } from 'aws-amplify'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import TextsmsIcon from '@material-ui/icons/Textsms'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import EventNoteIcon from '@material-ui/icons/EventNote'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

function Header(props) {
  // const classes = useStyles();
  const history = useHistory()

  const handleSignout = () => {
    localStorage.removeItem('totono-advertiser-session')
    sessionStorage.removeItem('totono-advertiser-session')
    Cache.clear()
    props.setSignin(false)
    history.push('/Signin')
  }

  return (
    <>
      <IconButton
        aria-label="メッセージ"
        color="primary"
        onClick={props.toggleDrawer(1, 'messages', true)}
      >
        {props.messageCount > 0 ? (
          <Badge badgeContent={props.messageCount} color="secondary">
            <TextsmsIcon />
          </Badge>
        ) : (
          <Badge color="secondary">
            <TextsmsIcon />
          </Badge>
        )}
      </IconButton>

      <IconButton
        aria-label="申請"
        color="primary"
        onClick={props.toggleDrawer(2, 'requests', true)}
      >
        {props.applicationCount > 0 ? (
          <Badge badgeContent={props.applicationCount} color="secondary">
            <ImportContactsIcon />
          </Badge>
        ) : (
          <Badge color="secondary">
            <ImportContactsIcon />
          </Badge>
        )}
      </IconButton>

      <IconButton
        aria-label="お知らせ"
        color="primary"
        onClick={props.toggleDrawer(3, 'announces', true)}
      >
        {props.notificationCount > 0 ? (
          <Badge badgeContent={props.notificationCount} color="secondary">
            <EventNoteIcon />
          </Badge>
        ) : (
          <Badge color="secondary">
            <EventNoteIcon />
          </Badge>
        )}
      </IconButton>

      <IconButton
        aria-label="ログアウト"
        color="primary"
        onClick={props.toggleDrawer(4, 'user', true)}
        ref={props.anchorRef}
      >
        <AccountCircleIcon />
      </IconButton>
    </>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setSignin: isSignin => {
      dispatch(setSignin(isSignin))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
