import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { BulkDeliveryState } from 'utils/enum'
import RadioButtons from 'components/common/RadioButtons'
import List from './List'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import { QueryHelper } from 'utils/api.utils'
import CommonChip from 'components/common/CommonChip'
import { fromCode } from 'utils/enum.utils'
import { closeSearchModalDialog } from 'actions/Actions'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { Format } from 'utils/enum'
import Loading from 'components/common/Loading'
import {
  setCsvInformation,
  setMatchCsvValue,
  setCsvHeader,
  setSelectContractList,
  setTargetIdList,
  setFilterCsvInformation,
  setMatchContractIdList,
  setBulkDeliveryDetailList
} from 'actions/Actions'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  },
  csv: {
    marginRight: '10px'
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5)
  }
}))

const buttonsData = [
  { value: BulkDeliveryState.all.code, label: BulkDeliveryState.all.value },
  {
    value: BulkDeliveryState.reserved.code,
    label: BulkDeliveryState.reserved.value
  },
  { value: BulkDeliveryState.sent.code, label: BulkDeliveryState.sent.value },
  { value: BulkDeliveryState.draft.code, label: BulkDeliveryState.draft.value },
  { value: BulkDeliveryState.error.code, label: BulkDeliveryState.error.value }
]

const formatList = [
  { value: 0, label: '' },
  { value: Format.csv.code, label: Format.csv.value },
  { value: Format.search.code, label: Format.search.value }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const { pageId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = newPage => {
    setPageNumber(newPage)
  }

  const [parentCategoryList, setParentCategoryList] = useState([])
  const [childCategoryList, setChildCategoryList] = useState([])
  const [companyUserList, setCompanyUserList] = useState(null)
  const [fetchedCompanyUserList, setFetchedCompanyUserList] = useState(null)
  const [deliveryList, setDeliveryList] = useState([])
  const [deliveryListCount, setDeliveryListCount] = useState(0)
  const [searchConditions, setSearchConditions] = useState(
    location.state !== undefined ? location.state : {}
  )
  const [isLoad, setIsLoad] = useState(false)

  const [selectedStatus, setSelectedStatus] = useState(
    searchConditions['bulkDelivery.thread_state']
      ? searchConditions['bulkDelivery.thread_state']
      : BulkDeliveryState.all.code
  )
  const [isReply, setIsReply] = useState(
    location.state !== undefined &&
      location.state['bulkDelivery.reply_state'] === 1
      ? true
      : false
  )
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )

  const fetchCategoryList = async () => {
    const result = await QueryHelper('getLargeCategoryAndMediumCategory')
    if (!result.error) {
      let parentsList = result.map(({ large_categories }) => ({
        value: large_categories.id,
        label: large_categories.name
      }))

      let childList = {}
      result.map(({ medium_categories }) => {
        medium_categories.map(({ id, parent_id, name }) => {
          if (!childList[parent_id]) {
            childList[parent_id] = [{ value: '', label: '' }]
          }
          childList[parent_id] = [
            ...childList[parent_id],
            { value: id, label: name }
          ]
        })
      })

      setParentCategoryList(parentsList)
      setChildCategoryList(childList)
    } else {
      props.enqueueSnackbar('カテゴリ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.company_user.status === 1) {
            const companyUserItem = {
              value: item.company_user.id,
              label: item.company_user.name
            }
            companyUserItems.push(companyUserItem)
          }
        }
      }
      setCompanyUserList(companyUserItems)
      setFetchedCompanyUserList(result.data)
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (let entry of formData.entries()) {
      if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    props.closeSearchModalDialog()
    params[pageNumber] = 1
    setSearchConditions(params, setPageNumber(1))
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchConditions, {
      'bulkDelivery.thread_state': selectedStatus
    })
    if (selectedStatus) {
      searchConditions['bulkDelivery.thread_state'] = selectedStatus
    } else {
      delete searchConditions['bulkDelivery.thread_state']
      delete params['bulkDelivery.thread_state']
    }
    params['pageNumber'] = 1
    setSearchConditions(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  const fetchData = async offset => {
    setIsLoad(true)
    await Promise.all([fetchThreadList(selectedStatus, offset, PAGE_LIMIT)])
    setIsLoad(false)
  }

  async function fetchThreadList(threadStateId, offset, limit) {
    const params = {}
    Object.keys(searchConditions).forEach(function(key) {
      if (searchConditions[key]) {
        switch (key) {
          case 'bulkDelivery.delivery_list_id': {
            params.id = { eq: searchConditions[key] }
            break
          }
          case 'bulkDelivery.thread_state': {
            setSelectedStatus(Number(searchConditions[key]))
            let value = ''
            if (searchConditions[key] == 0) {
              break
            }
            if (searchConditions[key] == 1) {
              value = 'RESERVE'
            } else if (searchConditions[key] == 2) {
              value = 'SENT'
            } else if (searchConditions[key] == 3) {
              value = 'DRAFT'
            } else if (searchConditions[key] == 4) {
              value = 'ERROR'
            }
            params.status = value
            break
          }
          case 'bulkDelivery.delivery_list_name': {
            params.name = { contains: searchConditions[key] }
            break
          }
          case 'bulkDelivery.parent_category': {
            params.large_category_id = { eq: searchConditions[key] }
            break
          }
          case 'bulkDelivery.child_category': {
            if (
              childCategoryList &&
              searchConditions[key] != 0 &&
              searchConditions[key] != '' &&
              searchConditions['message.parent_category'] != 0 &&
              searchConditions['message.parent_category'] != ''
            ) {
              for (let category of childCategoryList[
                searchConditions['bulkDelivery.parent_category']
              ]) {
                if (category.value === Number(searchConditions[key])) {
                  params.medium_category_id = { eq: searchConditions[key] }
                  break
                }
              }
            }
            break
          }
          case 'bulkDelivery.format': {
            if (searchConditions[key] != 0) {
              let value = ''
              if (searchConditions[key] == 1) {
                value = 'CSV'
              } else if (searchConditions[key] == 2) {
                value = 'SEARCH'
              }
              params.type = value
            }
            break
          }
          case 'bulkDelivery.updated_company_user': {
            if (searchConditions[key] != 0) {
              params.company_user_id = { eq: searchConditions[key] }
            }
            break
          }
          case 'bulkDelivery.transmission_date_time': {
            const d = new Date(searchConditions[key])
            if (!isNaN(d.getTime())) {
              params.sent_date_from = { ge: searchConditions[key] }
            }
            break
          }
          case 'bulkDelivery.last_date_and_time': {
            const d = new Date(searchConditions[key])
            if (!isNaN(d.getTime())) {
              params.sent_date_to = { le: searchConditions[key] }
            }
            break
          }
          default:
        }
      }
    })

    if (orderBy && Object.keys(orderBy).length > 0) {
      params.orderBy = [orderBy]
    }

    let result = await QueryHelper(
      'getBulkDeliveryList',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )
    if (result.error) {
      props.enqueueSnackbar('メッセージ配信リスト情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setDeliveryListCount(result.paging.count)
      setDeliveryList(result.data)
    }
  }

  const handleDeleteSearchConditions = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchConditions))
    switch (target) {
      case 'bulkDelivery.delivery_list_id': {
        delete params[target]
        break
      }
      case 'bulkDelivery.thread_state': {
        delete params[target]
        setSelectedStatus(0)
        break
      }
      case 'bulkDelivery.delivery_list_name': {
        delete params[target]
        break
      }
      case 'bulkDelivery.parent_category': {
        delete params[target]
        delete params['bulkDelivery.child_category']
        break
      }
      case 'bulkDelivery.child_category': {
        delete params[target]
        break
      }
      case 'bulkDelivery.format': {
        delete params[target]
        break
      }
      case 'bulkDelivery.updated_company_user': {
        delete params[target]
        break
      }
      case 'bulkDelivery.transmission_date_time': {
        delete params[target]
        break
      }
      case 'bulkDelivery.last_date_and_time': {
        delete params[target]
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    setSearchConditions(params, setPageNumber(1), setOrderBy({}))
  }

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchConditions['pageNumber'] = 1
    setPageNumber(1)
  }

  const goToTransition = display => {
    emptyRedux()
    switch (display) {
      case 'csv':
        return history.push(`/message/bulk-delivery/import`)
      case 'search':
        return history.push(`/message/bulk-delivery/search/:id`)
      default:
        return history.push(`/message/bulk-delivery/list/1`)
    }
  }

  const emptyRedux = () => {
    if (props.csvInformationList != undefined) {
      props.setCsvInformation({
        importCsvInformation: null
      })
    }
    if (props.matchCsvValue != undefined) {
      props.setMatchCsvValue({
        matchCsvValue: null
      })
    }
    if (props.csvHeader) {
      props.setCsvHeader({
        csvHeader: []
      })
    }
    if (props.selectContractList != undefined) {
      props.setSelectContractList({
        selectContractList: null
      })
    }
    if (props.targetIdList != undefined) {
      props.setTargetIdList({
        targetIdList: null
      })
    }
    if (props.filterCsvInformation != undefined) {
      props.setFilterCsvInformation({
        filterCsvInformation: null
      })
    }
    if (props.matchContractIdList != undefined) {
      props.setMatchContractIdList({
        matchContractIdList: null
      })
    }
    if (props.bulkDeliveryDetailList != undefined) {
      props.setBulkDeliveryDetailList({
        bulkDeliveryDetailList: null
      })
    }
  }

  useEffect(() => {
    fetchCategoryList()
    fetchCompanyUserList()
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `/message/bulk-delivery/list/${pageNumber}`,
      state: searchConditions
    })
    fetchData(offset)
  }, [
    pageNumber,
    setPageNumber,
    selectedStatus,
    isReply,
    searchConditions,
    orderBy
  ])

  function isContainsMessageBySearchConditions() {
    const val = Object.keys(searchConditions).find(x =>
      x.match(/^bulkDelivery.+/)
    )
    return val !== null && val !== undefined
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Grid item xs={12}>
        {searchConditions &&
          Object.keys(searchConditions).length > 0 &&
          isContainsMessageBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchConditions).map(key => {
                let label = ''
                switch (key) {
                  case 'bulkDelivery.thread_state': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != undefined
                    ) {
                      const statuses = searchConditions[key]
                      return (
                        <>
                          <CommonChip
                            label={
                              'ステータス: ' +
                              fromCode(BulkDeliveryState, Number(statuses))
                                .value
                            }
                            onDelete={handleDeleteSearchConditions(
                              key,
                              statuses
                            )}
                          />
                        </>
                      )
                    }
                    break
                  }
                  case 'bulkDelivery.delivery_list_id': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = 'ID: ' + searchConditions[key]
                    }
                    break
                  }
                  case 'bulkDelivery.delivery_list_name': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '配信リスト名: ' + searchConditions[key]
                    }
                    break
                  }
                  case 'bulkDelivery.parent_category': {
                    if (
                      parentCategoryList &&
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      for (let category of parentCategoryList) {
                        if (category.value === Number(searchConditions[key])) {
                          label = '大カテゴリ: ' + category.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'bulkDelivery.child_category': {
                    if (
                      childCategoryList &&
                      searchConditions[key] != 0 &&
                      searchConditions[key] != '' &&
                      searchConditions['message.parent_category'] != 0 &&
                      searchConditions['message.parent_category'] != ''
                    ) {
                      for (let category of childCategoryList[
                        searchConditions['bulkDelivery.parent_category']
                      ]) {
                        if (category.value === Number(searchConditions[key])) {
                          label = '中カテゴリ: ' + category.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'bulkDelivery.format': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      for (let format of formatList) {
                        if (format.value === Number(searchConditions[key])) {
                          label = 'リスト形式: ' + format.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'bulkDelivery.updated_company_user': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      for (let user of fetchedCompanyUserList) {
                        if (
                          user.company_user.id === Number(searchConditions[key])
                        ) {
                          const formattedName = getFormattedNameByStatus(
                            user.company_user.name,
                            user.company_user.status
                          )
                          label = '担当者: ' + formattedName
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'bulkDelivery.transmission_date_time': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '送信日時From: ' + searchConditions[key]
                    }
                    break
                  }
                  case 'bulkDelivery.last_date_and_time': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '最終日時To: ' + searchConditions[key]
                    }
                    break
                  }
                  default:
                }
                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchConditions(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>

      <Grid item xs={6} className={classes.tableTips}>
        <RadioButtons
          current={selectedStatus}
          handleSelectedButton={handleSelectedButton}
          buttonsData={buttonsData}
        />
      </Grid>
      <Grid container>
        <Grid className={classes.tableTips}>
          <Button
            onClick={() => goToTransition('csv')}
            variant="contained"
            startIcon={<AddIcon />}
            className={`${classes.button} ${classes.csv}`}
            disabled={isRestrictedRole}
          >
            新規作成 （CSV)
          </Button>
        </Grid>
        <Grid className={classes.tableTips}>
          <Button
            onClick={() => goToTransition('search')}
            variant="contained"
            startIcon={<AddIcon />}
            className={classes.button}
            disabled={isRestrictedRole}
          >
            新規作成 （検索)
          </Button>
        </Grid>
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={deliveryListCount}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {deliveryList && !isLoad ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              deliveryList={deliveryList}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoad} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={deliveryListCount}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            parentCategoryList={parentCategoryList}
            childCategoryList={childCategoryList}
            companyUserList={companyUserList}
            fetchedCompanyUserList={fetchedCompanyUserList}
            searchConditions={searchConditions}
            formatList={formatList}
            deliveryList={deliveryList}
          />
        }
        footer={<SearchModalDialogFooter />}
        postData={searchPostData}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    csvInformationList: state.csvInformation.list,
    matchCsvValue: state.matchCsvValue.value,
    csvHeader: state.csvHeader.value,
    selectContractList: state.selectContractList.list,
    targetIdList: state.targetIdList.list,
    filterCsvInformation: state.filterCsvInformation.list,
    bulkDeliveryDetailList: state.bulkDeliveryDetailList.list
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    setCsvInformation: parameter => {
      dispatch(setCsvInformation(parameter))
    },
    setMatchCsvValue: parameter => {
      dispatch(setMatchCsvValue(parameter))
    },
    setCsvHeader: parameter => {
      dispatch(setCsvHeader(parameter))
    },
    setSelectContractList: list => {
      dispatch(setSelectContractList(list))
    },
    setTargetIdList: parameter => {
      dispatch(setTargetIdList(parameter))
    },
    setFilterCsvInformation: parameter => {
      dispatch(setFilterCsvInformation(parameter))
    },
    setMatchContractIdList: parameter => {
      dispatch(setMatchContractIdList(parameter))
    },
    setBulkDeliveryDetailList: parameter => {
      dispatch(setBulkDeliveryDetailList(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
