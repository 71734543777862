import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { QueryHelper } from 'utils/api.utils'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { blue, grey } from '@material-ui/core/colors'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: 'unset'
  },
  button: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_light_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_blue.dark
    }
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    fontWeight: 'bold',
    marginRight: 10
  },
  subContent: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#72809D',
    marginTop: 2
  }
}))

const BlueCheckbox = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: blue[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />)

function AutoReplyWeek(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { weekState, setWeekState } = props

  const [isLoading, setIsLoading] = useState(false)
  const [week, setWeek] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([fetchWeek()])
    setIsLoading(false)
  }

  async function fetchWeek() {
    let result = await QueryHelper('getWeekList', {}, false)
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('週を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setWeek(result.data)
    }
  }

  const handleChangeCheckBox = event => {
    setWeekState({ ...weekState, [event.target.name]: event.target.checked })
  }

  return (
    <>
      <Grid container xs={12} alignItems="left">
        <Typography variant="body1" className={classes.content}>
          休業日
        </Typography>
        <Typography variant="body1" className={classes.subContent}>
          ※チェックを入れた曜日は終日自動返信を行います。
        </Typography>
      </Grid>
      <Grid container xs={12} alignItems="left">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            {week.map(row => {
              return (
                <>
                  {weekState.hasOwnProperty(row.id) && (
                    <FormControlLabel
                      control={
                        <BlueCheckbox
                          color="primary"
                          id={row.id}
                          name={row.id}
                          checked={weekState[row.id]}
                          onChange={handleChangeCheckBox}
                          disabled={!isUnrestrictedRole}
                        />
                      }
                      label={row.name}
                    />
                  )}
                </>
              )
            })}
          </>
        )}
      </Grid>
    </>
  )
}

export default AutoReplyWeek
