/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany {
    getCompany {
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      company {
        id
        status
        name
        name_kana
        stock_signage
        zip_code
        prefecture_id
        address
        tel
        fax
        mail
        url
        business_hours
        owner_function_enabled
        regular_holiday
        emergency_name
        emergency_tel
        emergency_time
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
        business_hours_text
        regular_holiday_text
        emergency_time_text
        privacy_policy_url
      }
      company_external_system {
        company_id
        external_system_id
      }
      contact_information {
        id
        is_enabled
        contact_label_id
        contact_type_id
        title
        chat_title
        phone_number
        url
        url_text
        reception_start_time
        reception_end_time
        all_day_reception
        description
        created_at
        updated_at
        deleted_at
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const getCompanyGroupList = /* GraphQL */ `
  query GetCompanyGroupList(
    $filter: TableCompanyGroupFilterInput
    $offset: Int
    $limit: Int
  ) {
    getCompanyGroupList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCompanyGroup = /* GraphQL */ `
  query GetCompanyGroup($filter: TableCompanyGroupFilterInput) {
    getCompanyGroup(filter: $filter) {
      id
      status
      company_id
      role_id
      name
      display_order
      created_at
      updated_at
      deleted_at
    }
  }
`
export const getCompanyUserList = /* GraphQL */ `
  query GetCompanyUserList(
    $filter: TableCompanyUserFilterInput
    $offset: Int
    $limit: Int
  ) {
    getCompanyUserList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        company_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        company_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCompanyUser = /* GraphQL */ `
  query GetCompanyUser($filter: TableCompanyUserFilterInput) {
    getCompanyUser(filter: $filter) {
      company_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      company_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getExternalSystemList = /* GraphQL */ `
  query GetExternalSystemList($offset: Int, $limit: Int) {
    getExternalSystemList(offset: $offset, limit: $limit) {
      data {
        id
        status
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getRoleList = /* GraphQL */ `
  query GetRoleList {
    getRoleList {
      data {
        id
        status
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBuildingList = /* GraphQL */ `
  query GetBuildingList(
    $filter: TableBuildingFilterInput
    $offset: Int
    $limit: Int
  ) {
    getBuildingList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        attachments {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        building {
          id
          status
          company_id
          tty_id
          tty_isp_tty_id
          is_management
          remark
          room_cnt
          contract_cnt
          customer_cnt
          display_order
          created_at
          updated_at
          deleted_at
          mst_tateya {
            TTY_ID
            TTY_ISP_TTY_ID
            TTY_ISP_KRM_ID
            TTY_INSERTED_DATE
            TTY_UPDATED_DATE
            TTY_DEL_FLG
            TTY_KNR_NO
            TTY_NAME
            TTY_NAME_KANA
            TTY_COMPLETION_DATE
            TTY_GROUND_FLOOR_NUM
            TTY_UGGROUND_FLOOR_NUM
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_TOTI_MENSEKI
            TTY_NOBEYUKA_MENSEKI
            TTY_ZIPCODE
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_TOTAL_COUNT
            TTY_REMARK
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_TIME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNR_DIV
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_SOG_ID
            TTY_SOG_DAY
            TTY_SOG_NAME
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_TNP_NAME
            TTY_GRP_NAME
            TTY_KNRPATTERN_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBuilding = /* GraphQL */ `
  query GetBuilding($filter: TableBuildingFilterInput) {
    getBuilding(filter: $filter) {
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      building {
        id
        status
        company_id
        tty_id
        tty_isp_tty_id
        is_management
        remark
        room_cnt
        contract_cnt
        customer_cnt
        display_order
        created_at
        updated_at
        deleted_at
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
      }
    }
  }
`
export const getBuildingManagement = /* GraphQL */ `
  query GetBuildingManagement($filter: TableMstTateyaMergeFilterInput) {
    getBuildingManagement(filter: $filter) {
      TTY_ISP_TTY_ID
      TTY_ID
      TTY_KNR_DIV
      is_management
    }
  }
`
export const getRoomList = /* GraphQL */ `
  query GetRoomList($filter: TableRoomFilterInput, $offset: Int, $limit: Int) {
    getRoomList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        knr_keiyaku {
          KYK_ID
          KYK_HYA_ID
          KYK_MSK_ID
          KYK_ISP_KYK_ID
          KYK_INSERTED_DATE
          KYK_UPDATED_DATE
          KYK_DEL_FLG
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_KEIYAKU_DATE
          KYK_NYUKYO_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KEIYAKU_END_DATE
          KYK_REMARK
          KYK_FREERENT_FLG
          KYK_TINRYO_HASSEI_DATE
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHINRYO_MN
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_YR
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_DATE
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
        }
        room {
          id
          status
          company_id
          building_id
          hya_id
          remark
          display_order
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getRoomList2 = /* GraphQL */ `
  query GetRoomList2($filter: TableRoomFilterInput, $offset: Int, $limit: Int) {
    getRoomList2(filter: $filter, offset: $offset, limit: $limit) {
      data {
        building {
          id
          status
          company_id
          tty_id
          tty_isp_tty_id
          is_management
          remark
          room_cnt
          contract_cnt
          customer_cnt
          display_order
          created_at
          updated_at
          deleted_at
          mst_tateya {
            TTY_ID
            TTY_ISP_TTY_ID
            TTY_ISP_KRM_ID
            TTY_INSERTED_DATE
            TTY_UPDATED_DATE
            TTY_DEL_FLG
            TTY_KNR_NO
            TTY_NAME
            TTY_NAME_KANA
            TTY_COMPLETION_DATE
            TTY_GROUND_FLOOR_NUM
            TTY_UGGROUND_FLOOR_NUM
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_TOTI_MENSEKI
            TTY_NOBEYUKA_MENSEKI
            TTY_ZIPCODE
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_TOTAL_COUNT
            TTY_REMARK
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_TIME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNR_DIV
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_SOG_ID
            TTY_SOG_DAY
            TTY_SOG_NAME
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_TNP_NAME
            TTY_GRP_NAME
            TTY_KNRPATTERN_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
        contractAndTenant {
          contract {
            id
            status
            company_id
            building_id
            room_id
            contractor_id
            tenant_id
            kyk_id
            kyk_status_div
            kyk_keiyaku_str_date
            kyk_keiyaku_end_date
            created_at
            updated_at
            deleted_at
          }
          tenant {
            id
            status
            company_id
            kky_id
            app_user_id
            username
            password
            iot_provider_id
            is_usage
            is_activation
            registered_at
            remark
            created_at
            updated_at
            deleted_at
          }
        }
        knr_keiyaku {
          KYK_ID
          KYK_HYA_ID
          KYK_MSK_ID
          KYK_ISP_KYK_ID
          KYK_INSERTED_DATE
          KYK_UPDATED_DATE
          KYK_DEL_FLG
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_KEIYAKU_DATE
          KYK_NYUKYO_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KEIYAKU_END_DATE
          KYK_REMARK
          KYK_FREERENT_FLG
          KYK_TINRYO_HASSEI_DATE
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHINRYO_MN
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_YR
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_DATE
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
        }
        nky_mst_kokyaku {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        kys_mst_kokyaku {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        room {
          id
          status
          company_id
          building_id
          hya_id
          remark
          display_order
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getRoom = /* GraphQL */ `
  query GetRoom($filter: TableRoomFilterInput) {
    getRoom(filter: $filter) {
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      knr_keiyaku {
        KYK_ID
        KYK_HYA_ID
        KYK_MSK_ID
        KYK_ISP_KYK_ID
        KYK_INSERTED_DATE
        KYK_UPDATED_DATE
        KYK_DEL_FLG
        KYK_KYS_KKY_ID
        KYK_KYS_KKY_NAME
        KYK_NKY_KKY_ID
        KYK_NKY_KKY_NAME
        KYK_NKY_ZKG_CD
        KYK_NKY_ZKG_NAME
        KYK_HSN_01_KKY_ID
        KYK_HSN_01_KKY_NAME
        KYK_HSN_01_ZKG_CD
        KYK_HSN_01_ZKG_NAME
        KYK_HSN_02_KKY_ID
        KYK_HSN_02_KKY_NAME
        KYK_HSN_02_ZKG_CD
        KYK_HSN_02_ZKG_NAME
        KYK_SDK_KKY_ID
        KYK_SDK_KKY_NAME
        KYK_GYS_KKY_ID
        KYK_GYS_KKY_NAME
        KYK_GYS_SHN_NAME
        KYK_STATUS_DIV
        KYK_STATUS_DIV_NAME
        KYK_KEIYAKU_DATE
        KYK_NYUKYO_DATE
        KYK_KEIYAKU_STR_DATE
        KYK_KEIYAKU_END_DATE
        KYK_REMARK
        KYK_FREERENT_FLG
        KYK_TINRYO_HASSEI_DATE
        KYK_KOSHIN_AUTO_FLG
        KYK_KOSHIN_YR
        KYK_KOSHINYOKOKU_DIV
        KYK_KOSHINYOKOKU_DIV_NAME
        KYK_KOSHINYOKOKU_NUM
        KYK_KOSHINRYO_MN
        KYK_KOSHINRYO_KINGAKU
        KYK_KOSHINRYO_KINGAKU_TAX
        KYK_KOSHINRYO_CALC_DIV
        KYK_KOSHINRYO_CALC_DIV_NAME
        KYK_KOSHINJIMURYO_MN
        KYK_KOSHINJIMURYO_KINGAKU
        KYK_KOSHINJIMURYO_KINGAKU_TAX
        KYK_KOSHINJIMURYO_CALC_DIV
        KYK_KOSHINJIMURYO_CALC_DIV_NAME
        KYK_KOSHINTESURYO_PER
        KYK_KOSHINTESURYO_KINGAKU
        KYK_KOSHINTESURYO_KINGAKU_TAX
        KYK_TEISYAKU_FLG
        KYK_TEISYAKU_TERM_DIV
        KYK_TEISYAKU_TERM_DIV_NAME
        KYK_TEISYAKU_TERM_YR
        KYK_TEISYAKU_TERM_MN
        KYK_TEISYAKU_TERM_DATE
        KYK_TAIKYOYOKOKU_DIV
        KYK_TAIKYOYOKOKU_DIV_NAME
        KYK_TAIKYOYOKOKU_NUM
        KYK_SEK_DIV_NAME
        KYK_SEK_LIMIT_DATE
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_ZIJ_SEK_TSY_KKY_ID
        KYK_ZIJ_SEK_TSY_KKY_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_01_TKM_CD
        KYK_TAIKYO_SEIKYU_01_TKM_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU
        KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_02_TKM_CD
        KYK_TAIKYO_SEIKYU_02_TKM_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU
        KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_03_TKM_CD
        KYK_TAIKYO_SEIKYU_03_TKM_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU
        KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
        KYK_HOKEN_GYS_KKY_ID
        KYK_HOKEN_GYS_KKY_NAME
        KYK_HOKEN_KEIYAKU_STR_DATE
        KYK_HOKEN_KEIYAKU_END_DATE
        KYK_HOKEN_KOSHIN_YR
        KYK_HOKEN_SYOKEN_NO
        KYK_HOKEN_KINGAKU
        KYK_HOKEN_KINGAKU_TAX
        KYK_HOSYO_GYS_KKY_ID
        KYK_HOSYO_GYS_KKY_NAME
        KYK_HOSYO_KEIYAKU_STR_DATE
        KYK_HOSYO_KEIYAKU_END_DATE
        KYK_HOSYO_KOSHIN_YR
        KYK_HOSYO_SYOKEN_NO
        KYK_HOSYO_KINGAKU
        KYK_HOSYO_KINGAKU_TAX
        KYK_KAIYAKU_TSUCHI_DATE
        KYK_KAIYAKU_YOTEI_DATE
        KYK_KAIYAKU_DATE
        KYK_KAIYAKU_TAIKYO_YOTEI_DATE
        KYK_KAIYAKU_REMARK
        KYK_KAIYAKU_TACHIAI_YOTEI_DATE
        KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
        KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
        KYK_KAIYAKU_SEK_END_YR
        KYK_KAIYAKU_SEK_END_MN
        KYK_KAIYAKU_TKG_ATENA
        KYK_KAIYAKU_TKG_ZIPCODE
        KYK_KAIYAKU_TKG_PRF_CD
        KYK_KAIYAKU_TKG_PRF_NAME
        KYK_KAIYAKU_TKG_CTY_CD
        KYK_KAIYAKU_TKG_CTY_NAME
        KYK_KAIYAKU_TKG_TWN_CD
        KYK_KAIYAKU_TKG_TWN_NAME
        KYK_KAIYAKU_TKG_ADDR_01
        KYK_KAIYAKU_TKG_ADDR_02
        KYK_KAIYAKU_TKG_TEL
        KYK_KAIYAKU_TKG_TKR_CD
        KYK_KAIYAKU_TKG_TKR_NAME
        KYK_KAIYAKU_KNK_FB_FLG
        KYK_KAIYAKU_BNK_CD
        KYK_KAIYAKU_BNK_NAME
        KYK_KAIYAKU_BNB_CD
        KYK_KAIYAKU_BNB_NAME
        KYK_KAIYAKU_ADV_CD
        KYK_KAIYAKU_ADV_NAME
        KYK_KAIYAKU_MEIGI
        KYK_KAIYAKU_MEIGI_KANA
        KYK_KAIYAKU_NUMBER
        KYK_KAIYAKU_SSN_HAKKO_DATE
        KYK_KAIYAKU_HENKIN_YOTEI_DATE
        KYK_MEMBER_CD
        KYK_MEMBER_CD_2
        KYK_MEMBER_FLG
        KYK_MEMBER_FLG_2
        KYK_MEMBER_NAME
        KYK_MEMBER_NAME_2
      }
      room {
        id
        status
        company_id
        building_id
        hya_id
        remark
        display_order
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getBookmarkList = /* GraphQL */ `
  query GetBookmarkList(
    $filter: TableBookmarkFilterInput
    $offset: Int
    $limit: Int
  ) {
    getBookmarkList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        bookmark {
          id
          status
          company_id
          attachment_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBookmark = /* GraphQL */ `
  query GetBookmark($filter: TableBookmarkFilterInput) {
    getBookmark(filter: $filter) {
      bookmark {
        id
        status
        company_id
        attachment_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getMessageTemplateList = /* GraphQL */ `
  query GetMessageTemplateList(
    $filter: TableMessageTemplateFilterInput
    $offset: Int
    $limit: Int
  ) {
    getMessageTemplateList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        message_template {
          id
          status
          company_id
          category_id
          title
          body
          display_order
          created_at
          updated_at
          deleted_at
        }
        category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getMessageTemplate = /* GraphQL */ `
  query GetMessageTemplate($filter: TableMessageTemplateFilterInput) {
    getMessageTemplate(filter: $filter) {
      message_template {
        id
        status
        company_id
        category_id
        title
        body
        display_order
        created_at
        updated_at
        deleted_at
      }
      category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const getPackageList = /* GraphQL */ `
  query GetPackageList(
    $filter: TablePackageFilterInput
    $offset: Int
    $limit: Int
  ) {
    getPackageList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        package {
          id
          status
          company_id
          name
          description
          body
          title_attachment_id
          url
          link_text
          display_order
          is_display_top
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
        }
        title_attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        attachments {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        updated_by {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
      }
      package_size {
        available_slot_size
        top_page_display_size
        remark
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getOriginalUrl = /* GraphQL */ `
  query GetOriginalUrl {
    getOriginalUrl {
      companyId
      originalUrl
    }
  }
`
export const getCategory = /* GraphQL */ `
  query GetCategory($filter: TableCategoryFilterInput, $category_type: Int) {
    getCategory(filter: $filter, category_type: $category_type) {
      category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      parent {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getPackage = /* GraphQL */ `
  query GetPackage($id: Int) {
    getPackage(id: $id) {
      package {
        id
        status
        company_id
        name
        description
        body
        title_attachment_id
        url
        link_text
        display_order
        is_display_top
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
      }
      title_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const getContractList = /* GraphQL */ `
  query GetContractList(
    $filter: TableContractFilterInput
    $offset: Int
    $limit: Int
  ) {
    getContractList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        contract {
          id
          status
          company_id
          building_id
          room_id
          contractor_id
          tenant_id
          kyk_id
          kyk_status_div
          kyk_keiyaku_str_date
          kyk_keiyaku_end_date
          created_at
          updated_at
          deleted_at
        }
        customer {
          id
          status
          company_id
          kky_id
          app_user_id
          username
          password
          iot_provider_id
          is_usage
          is_activation
          registered_at
          remark
          created_at
          updated_at
          deleted_at
        }
        building {
          id
          status
          company_id
          tty_id
          tty_isp_tty_id
          is_management
          remark
          room_cnt
          contract_cnt
          customer_cnt
          display_order
          created_at
          updated_at
          deleted_at
          mst_tateya {
            TTY_ID
            TTY_ISP_TTY_ID
            TTY_ISP_KRM_ID
            TTY_INSERTED_DATE
            TTY_UPDATED_DATE
            TTY_DEL_FLG
            TTY_KNR_NO
            TTY_NAME
            TTY_NAME_KANA
            TTY_COMPLETION_DATE
            TTY_GROUND_FLOOR_NUM
            TTY_UGGROUND_FLOOR_NUM
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_TOTI_MENSEKI
            TTY_NOBEYUKA_MENSEKI
            TTY_ZIPCODE
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_TOTAL_COUNT
            TTY_REMARK
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_TIME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNR_DIV
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_SOG_ID
            TTY_SOG_DAY
            TTY_SOG_NAME
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_TNP_NAME
            TTY_GRP_NAME
            TTY_KNRPATTERN_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
        knr_keiyaku {
          KYK_ID
          KYK_HYA_ID
          KYK_MSK_ID
          KYK_ISP_KYK_ID
          KYK_INSERTED_DATE
          KYK_UPDATED_DATE
          KYK_DEL_FLG
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_KEIYAKU_DATE
          KYK_NYUKYO_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KEIYAKU_END_DATE
          KYK_REMARK
          KYK_FREERENT_FLG
          KYK_TINRYO_HASSEI_DATE
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHINRYO_MN
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_YR
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_DATE
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kyk_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_kyk_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_kyk_hsn_01 {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_kyk_hsn_02 {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        knr_keiyaku_r_getsuji {
          KYK_GTJ_ID
          KYK_GTJ_KYK_ID
          KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
          KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
          KYK_GTJ_INSERTED_DATE
          KYK_GTJ_UPDATED_DATE
          KYK_GTJ_DEL_FLG
          KYK_GTJ_TKM_CD
          KYK_GTJ_TKM_NAME
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GTJ_SEK_DIV
          KYK_GTJ_SEK_DIV_NAME
          KYK_GTJ_KINGAKU
          KYK_GTJ_KINGAKU_TAX
          KYK_GTJ_TAX_DIV
          KYK_GTJ_TAX_DIV_NAME
          total_amount
          tax_flg
          KYK_GTJ_ZIJ_FLG
        }
        mst_kokyaku_copy {
          kky_id
          company_id
          status
          kky_name
          kky_name_kana
          kky_tel_01
          kky_tel_02
          kky_legalperson_div
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getContractList2 = /* GraphQL */ `
  query GetContractList2(
    $filter: TableContractFilterInput
    $offset: Int
    $limit: Int
  ) {
    getContractList2(filter: $filter, offset: $offset, limit: $limit) {
      data {
        contract {
          id
          status
          company_id
          building_id
          room_id
          contractor_id
          tenant_id
          kyk_id
          kyk_status_div
          kyk_keiyaku_str_date
          kyk_keiyaku_end_date
          created_at
          updated_at
          deleted_at
        }
        customer {
          id
          status
          company_id
          kky_id
          app_user_id
          username
          password
          iot_provider_id
          is_usage
          is_activation
          registered_at
          remark
          created_at
          updated_at
          deleted_at
        }
        building {
          id
          status
          company_id
          tty_id
          tty_isp_tty_id
          is_management
          remark
          room_cnt
          contract_cnt
          customer_cnt
          display_order
          created_at
          updated_at
          deleted_at
          mst_tateya {
            TTY_ID
            TTY_ISP_TTY_ID
            TTY_ISP_KRM_ID
            TTY_INSERTED_DATE
            TTY_UPDATED_DATE
            TTY_DEL_FLG
            TTY_KNR_NO
            TTY_NAME
            TTY_NAME_KANA
            TTY_COMPLETION_DATE
            TTY_GROUND_FLOOR_NUM
            TTY_UGGROUND_FLOOR_NUM
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_TOTI_MENSEKI
            TTY_NOBEYUKA_MENSEKI
            TTY_ZIPCODE
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_TOTAL_COUNT
            TTY_REMARK
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_TIME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNR_DIV
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_SOG_ID
            TTY_SOG_DAY
            TTY_SOG_NAME
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_TNP_NAME
            TTY_GRP_NAME
            TTY_KNRPATTERN_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
        knr_keiyaku {
          KYK_ID
          KYK_HYA_ID
          KYK_MSK_ID
          KYK_ISP_KYK_ID
          KYK_INSERTED_DATE
          KYK_UPDATED_DATE
          KYK_DEL_FLG
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_KEIYAKU_DATE
          KYK_NYUKYO_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KEIYAKU_END_DATE
          KYK_REMARK
          KYK_FREERENT_FLG
          KYK_TINRYO_HASSEI_DATE
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHINRYO_MN
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_YR
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_DATE
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kyk_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_kyk_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_kyk_hsn_01 {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_kyk_hsn_02 {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        knr_keiyaku_r_getsuji {
          KYK_GTJ_ID
          KYK_GTJ_KYK_ID
          KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
          KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
          KYK_GTJ_INSERTED_DATE
          KYK_GTJ_UPDATED_DATE
          KYK_GTJ_DEL_FLG
          KYK_GTJ_TKM_CD
          KYK_GTJ_TKM_NAME
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_GTJ_SEK_DIV
          KYK_GTJ_SEK_DIV_NAME
          KYK_GTJ_KINGAKU
          KYK_GTJ_KINGAKU_TAX
          KYK_GTJ_TAX_DIV
          KYK_GTJ_TAX_DIV_NAME
          total_amount
          tax_flg
          KYK_GTJ_ZIJ_FLG
        }
        mst_kokyaku_copy {
          kky_id
          company_id
          status
          kky_name
          kky_name_kana
          kky_tel_01
          kky_tel_02
          kky_legalperson_div
        }
        appUser {
          id
          status
          username
          password
          is_activation
          registered_at
          attachment_id
          last_login_at
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getContractIdList = /* GraphQL */ `
  query GetContractIdList(
    $filter: TableContractIdListFilterInput
    $limit: Int
    $offset: Int
  ) {
    getContractIdList(filter: $filter, limit: $limit, offset: $offset) {
      id
    }
  }
`
export const getContract = /* GraphQL */ `
  query GetContract($filter: TableContractFilterInput) {
    getContract(filter: $filter) {
      contract {
        id
        status
        company_id
        building_id
        room_id
        contractor_id
        tenant_id
        kyk_id
        kyk_status_div
        kyk_keiyaku_str_date
        kyk_keiyaku_end_date
        created_at
        updated_at
        deleted_at
      }
      customer {
        id
        status
        company_id
        kky_id
        app_user_id
        username
        password
        iot_provider_id
        is_usage
        is_activation
        registered_at
        remark
        created_at
        updated_at
        deleted_at
      }
      building {
        id
        status
        company_id
        tty_id
        tty_isp_tty_id
        is_management
        remark
        room_cnt
        contract_cnt
        customer_cnt
        display_order
        created_at
        updated_at
        deleted_at
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
      }
      knr_keiyaku {
        KYK_ID
        KYK_HYA_ID
        KYK_MSK_ID
        KYK_ISP_KYK_ID
        KYK_INSERTED_DATE
        KYK_UPDATED_DATE
        KYK_DEL_FLG
        KYK_KYS_KKY_ID
        KYK_KYS_KKY_NAME
        KYK_NKY_KKY_ID
        KYK_NKY_KKY_NAME
        KYK_NKY_ZKG_CD
        KYK_NKY_ZKG_NAME
        KYK_HSN_01_KKY_ID
        KYK_HSN_01_KKY_NAME
        KYK_HSN_01_ZKG_CD
        KYK_HSN_01_ZKG_NAME
        KYK_HSN_02_KKY_ID
        KYK_HSN_02_KKY_NAME
        KYK_HSN_02_ZKG_CD
        KYK_HSN_02_ZKG_NAME
        KYK_SDK_KKY_ID
        KYK_SDK_KKY_NAME
        KYK_GYS_KKY_ID
        KYK_GYS_KKY_NAME
        KYK_GYS_SHN_NAME
        KYK_STATUS_DIV
        KYK_STATUS_DIV_NAME
        KYK_KEIYAKU_DATE
        KYK_NYUKYO_DATE
        KYK_KEIYAKU_STR_DATE
        KYK_KEIYAKU_END_DATE
        KYK_REMARK
        KYK_FREERENT_FLG
        KYK_TINRYO_HASSEI_DATE
        KYK_KOSHIN_AUTO_FLG
        KYK_KOSHIN_YR
        KYK_KOSHINYOKOKU_DIV
        KYK_KOSHINYOKOKU_DIV_NAME
        KYK_KOSHINYOKOKU_NUM
        KYK_KOSHINRYO_MN
        KYK_KOSHINRYO_KINGAKU
        KYK_KOSHINRYO_KINGAKU_TAX
        KYK_KOSHINRYO_CALC_DIV
        KYK_KOSHINRYO_CALC_DIV_NAME
        KYK_KOSHINJIMURYO_MN
        KYK_KOSHINJIMURYO_KINGAKU
        KYK_KOSHINJIMURYO_KINGAKU_TAX
        KYK_KOSHINJIMURYO_CALC_DIV
        KYK_KOSHINJIMURYO_CALC_DIV_NAME
        KYK_KOSHINTESURYO_PER
        KYK_KOSHINTESURYO_KINGAKU
        KYK_KOSHINTESURYO_KINGAKU_TAX
        KYK_TEISYAKU_FLG
        KYK_TEISYAKU_TERM_DIV
        KYK_TEISYAKU_TERM_DIV_NAME
        KYK_TEISYAKU_TERM_YR
        KYK_TEISYAKU_TERM_MN
        KYK_TEISYAKU_TERM_DATE
        KYK_TAIKYOYOKOKU_DIV
        KYK_TAIKYOYOKOKU_DIV_NAME
        KYK_TAIKYOYOKOKU_NUM
        KYK_SEK_DIV_NAME
        KYK_SEK_LIMIT_DATE
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_ZIJ_SEK_TSY_KKY_ID
        KYK_ZIJ_SEK_TSY_KKY_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_01_TKM_CD
        KYK_TAIKYO_SEIKYU_01_TKM_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU
        KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_02_TKM_CD
        KYK_TAIKYO_SEIKYU_02_TKM_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU
        KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_03_TKM_CD
        KYK_TAIKYO_SEIKYU_03_TKM_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU
        KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
        KYK_HOKEN_GYS_KKY_ID
        KYK_HOKEN_GYS_KKY_NAME
        KYK_HOKEN_KEIYAKU_STR_DATE
        KYK_HOKEN_KEIYAKU_END_DATE
        KYK_HOKEN_KOSHIN_YR
        KYK_HOKEN_SYOKEN_NO
        KYK_HOKEN_KINGAKU
        KYK_HOKEN_KINGAKU_TAX
        KYK_HOSYO_GYS_KKY_ID
        KYK_HOSYO_GYS_KKY_NAME
        KYK_HOSYO_KEIYAKU_STR_DATE
        KYK_HOSYO_KEIYAKU_END_DATE
        KYK_HOSYO_KOSHIN_YR
        KYK_HOSYO_SYOKEN_NO
        KYK_HOSYO_KINGAKU
        KYK_HOSYO_KINGAKU_TAX
        KYK_KAIYAKU_TSUCHI_DATE
        KYK_KAIYAKU_YOTEI_DATE
        KYK_KAIYAKU_DATE
        KYK_KAIYAKU_TAIKYO_YOTEI_DATE
        KYK_KAIYAKU_REMARK
        KYK_KAIYAKU_TACHIAI_YOTEI_DATE
        KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
        KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
        KYK_KAIYAKU_SEK_END_YR
        KYK_KAIYAKU_SEK_END_MN
        KYK_KAIYAKU_TKG_ATENA
        KYK_KAIYAKU_TKG_ZIPCODE
        KYK_KAIYAKU_TKG_PRF_CD
        KYK_KAIYAKU_TKG_PRF_NAME
        KYK_KAIYAKU_TKG_CTY_CD
        KYK_KAIYAKU_TKG_CTY_NAME
        KYK_KAIYAKU_TKG_TWN_CD
        KYK_KAIYAKU_TKG_TWN_NAME
        KYK_KAIYAKU_TKG_ADDR_01
        KYK_KAIYAKU_TKG_ADDR_02
        KYK_KAIYAKU_TKG_TEL
        KYK_KAIYAKU_TKG_TKR_CD
        KYK_KAIYAKU_TKG_TKR_NAME
        KYK_KAIYAKU_KNK_FB_FLG
        KYK_KAIYAKU_BNK_CD
        KYK_KAIYAKU_BNK_NAME
        KYK_KAIYAKU_BNB_CD
        KYK_KAIYAKU_BNB_NAME
        KYK_KAIYAKU_ADV_CD
        KYK_KAIYAKU_ADV_NAME
        KYK_KAIYAKU_MEIGI
        KYK_KAIYAKU_MEIGI_KANA
        KYK_KAIYAKU_NUMBER
        KYK_KAIYAKU_SSN_HAKKO_DATE
        KYK_KAIYAKU_HENKIN_YOTEI_DATE
        KYK_MEMBER_CD
        KYK_MEMBER_CD_2
        KYK_MEMBER_FLG
        KYK_MEMBER_FLG_2
        KYK_MEMBER_NAME
        KYK_MEMBER_NAME_2
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kyk_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_kyk_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_kyk_hsn_01 {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_kyk_hsn_02 {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      knr_keiyaku_r_getsuji {
        KYK_GTJ_ID
        KYK_GTJ_KYK_ID
        KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
        KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
        KYK_GTJ_INSERTED_DATE
        KYK_GTJ_UPDATED_DATE
        KYK_GTJ_DEL_FLG
        KYK_GTJ_TKM_CD
        KYK_GTJ_TKM_NAME
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_GTJ_SEK_DIV
        KYK_GTJ_SEK_DIV_NAME
        KYK_GTJ_KINGAKU
        KYK_GTJ_KINGAKU_TAX
        KYK_GTJ_TAX_DIV
        KYK_GTJ_TAX_DIV_NAME
        total_amount
        tax_flg
        KYK_GTJ_ZIJ_FLG
      }
      mst_kokyaku_copy {
        kky_id
        company_id
        status
        kky_name
        kky_name_kana
        kky_tel_01
        kky_tel_02
        kky_legalperson_div
      }
    }
  }
`
export const getContract2 = /* GraphQL */ `
  query GetContract2($filter: TableContractFilterInput) {
    getContract2(filter: $filter) {
      contract {
        id
        status
        company_id
        building_id
        room_id
        contractor_id
        tenant_id
        kyk_id
        kyk_status_div
        kyk_keiyaku_str_date
        kyk_keiyaku_end_date
        created_at
        updated_at
        deleted_at
      }
      customer {
        id
        status
        company_id
        kky_id
        app_user_id
        username
        password
        iot_provider_id
        is_usage
        is_activation
        registered_at
        remark
        created_at
        updated_at
        deleted_at
      }
      building {
        id
        status
        company_id
        tty_id
        tty_isp_tty_id
        is_management
        remark
        room_cnt
        contract_cnt
        customer_cnt
        display_order
        created_at
        updated_at
        deleted_at
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
      }
      knr_keiyaku {
        KYK_ID
        KYK_HYA_ID
        KYK_MSK_ID
        KYK_ISP_KYK_ID
        KYK_INSERTED_DATE
        KYK_UPDATED_DATE
        KYK_DEL_FLG
        KYK_KYS_KKY_ID
        KYK_KYS_KKY_NAME
        KYK_NKY_KKY_ID
        KYK_NKY_KKY_NAME
        KYK_NKY_ZKG_CD
        KYK_NKY_ZKG_NAME
        KYK_HSN_01_KKY_ID
        KYK_HSN_01_KKY_NAME
        KYK_HSN_01_ZKG_CD
        KYK_HSN_01_ZKG_NAME
        KYK_HSN_02_KKY_ID
        KYK_HSN_02_KKY_NAME
        KYK_HSN_02_ZKG_CD
        KYK_HSN_02_ZKG_NAME
        KYK_SDK_KKY_ID
        KYK_SDK_KKY_NAME
        KYK_GYS_KKY_ID
        KYK_GYS_KKY_NAME
        KYK_GYS_SHN_NAME
        KYK_STATUS_DIV
        KYK_STATUS_DIV_NAME
        KYK_KEIYAKU_DATE
        KYK_NYUKYO_DATE
        KYK_KEIYAKU_STR_DATE
        KYK_KEIYAKU_END_DATE
        KYK_REMARK
        KYK_FREERENT_FLG
        KYK_TINRYO_HASSEI_DATE
        KYK_KOSHIN_AUTO_FLG
        KYK_KOSHIN_YR
        KYK_KOSHINYOKOKU_DIV
        KYK_KOSHINYOKOKU_DIV_NAME
        KYK_KOSHINYOKOKU_NUM
        KYK_KOSHINRYO_MN
        KYK_KOSHINRYO_KINGAKU
        KYK_KOSHINRYO_KINGAKU_TAX
        KYK_KOSHINRYO_CALC_DIV
        KYK_KOSHINRYO_CALC_DIV_NAME
        KYK_KOSHINJIMURYO_MN
        KYK_KOSHINJIMURYO_KINGAKU
        KYK_KOSHINJIMURYO_KINGAKU_TAX
        KYK_KOSHINJIMURYO_CALC_DIV
        KYK_KOSHINJIMURYO_CALC_DIV_NAME
        KYK_KOSHINTESURYO_PER
        KYK_KOSHINTESURYO_KINGAKU
        KYK_KOSHINTESURYO_KINGAKU_TAX
        KYK_TEISYAKU_FLG
        KYK_TEISYAKU_TERM_DIV
        KYK_TEISYAKU_TERM_DIV_NAME
        KYK_TEISYAKU_TERM_YR
        KYK_TEISYAKU_TERM_MN
        KYK_TEISYAKU_TERM_DATE
        KYK_TAIKYOYOKOKU_DIV
        KYK_TAIKYOYOKOKU_DIV_NAME
        KYK_TAIKYOYOKOKU_NUM
        KYK_SEK_DIV_NAME
        KYK_SEK_LIMIT_DATE
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_ZIJ_SEK_TSY_KKY_ID
        KYK_ZIJ_SEK_TSY_KKY_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_01_TKM_CD
        KYK_TAIKYO_SEIKYU_01_TKM_NAME
        KYK_TAIKYO_SEIKYU_01_KINGAKU
        KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_02_TKM_CD
        KYK_TAIKYO_SEIKYU_02_TKM_NAME
        KYK_TAIKYO_SEIKYU_02_KINGAKU
        KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
        KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
        KYK_TAIKYO_SEIKYU_03_TKM_CD
        KYK_TAIKYO_SEIKYU_03_TKM_NAME
        KYK_TAIKYO_SEIKYU_03_KINGAKU
        KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
        KYK_HOKEN_GYS_KKY_ID
        KYK_HOKEN_GYS_KKY_NAME
        KYK_HOKEN_KEIYAKU_STR_DATE
        KYK_HOKEN_KEIYAKU_END_DATE
        KYK_HOKEN_KOSHIN_YR
        KYK_HOKEN_SYOKEN_NO
        KYK_HOKEN_KINGAKU
        KYK_HOKEN_KINGAKU_TAX
        KYK_HOSYO_GYS_KKY_ID
        KYK_HOSYO_GYS_KKY_NAME
        KYK_HOSYO_KEIYAKU_STR_DATE
        KYK_HOSYO_KEIYAKU_END_DATE
        KYK_HOSYO_KOSHIN_YR
        KYK_HOSYO_SYOKEN_NO
        KYK_HOSYO_KINGAKU
        KYK_HOSYO_KINGAKU_TAX
        KYK_KAIYAKU_TSUCHI_DATE
        KYK_KAIYAKU_YOTEI_DATE
        KYK_KAIYAKU_DATE
        KYK_KAIYAKU_TAIKYO_YOTEI_DATE
        KYK_KAIYAKU_REMARK
        KYK_KAIYAKU_TACHIAI_YOTEI_DATE
        KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
        KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
        KYK_KAIYAKU_SEK_END_YR
        KYK_KAIYAKU_SEK_END_MN
        KYK_KAIYAKU_TKG_ATENA
        KYK_KAIYAKU_TKG_ZIPCODE
        KYK_KAIYAKU_TKG_PRF_CD
        KYK_KAIYAKU_TKG_PRF_NAME
        KYK_KAIYAKU_TKG_CTY_CD
        KYK_KAIYAKU_TKG_CTY_NAME
        KYK_KAIYAKU_TKG_TWN_CD
        KYK_KAIYAKU_TKG_TWN_NAME
        KYK_KAIYAKU_TKG_ADDR_01
        KYK_KAIYAKU_TKG_ADDR_02
        KYK_KAIYAKU_TKG_TEL
        KYK_KAIYAKU_TKG_TKR_CD
        KYK_KAIYAKU_TKG_TKR_NAME
        KYK_KAIYAKU_KNK_FB_FLG
        KYK_KAIYAKU_BNK_CD
        KYK_KAIYAKU_BNK_NAME
        KYK_KAIYAKU_BNB_CD
        KYK_KAIYAKU_BNB_NAME
        KYK_KAIYAKU_ADV_CD
        KYK_KAIYAKU_ADV_NAME
        KYK_KAIYAKU_MEIGI
        KYK_KAIYAKU_MEIGI_KANA
        KYK_KAIYAKU_NUMBER
        KYK_KAIYAKU_SSN_HAKKO_DATE
        KYK_KAIYAKU_HENKIN_YOTEI_DATE
        KYK_MEMBER_CD
        KYK_MEMBER_CD_2
        KYK_MEMBER_FLG
        KYK_MEMBER_FLG_2
        KYK_MEMBER_NAME
        KYK_MEMBER_NAME_2
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kyk_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_kyk_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_kyk_hsn_01 {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_kyk_hsn_02 {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      knr_keiyaku_r_getsuji {
        KYK_GTJ_ID
        KYK_GTJ_KYK_ID
        KYK_GTJ_ISP_KYK_RKM_01_KYK_ID
        KYK_GTJ_ISP_KYK_RKM_01_SORT_NO
        KYK_GTJ_INSERTED_DATE
        KYK_GTJ_UPDATED_DATE
        KYK_GTJ_DEL_FLG
        KYK_GTJ_TKM_CD
        KYK_GTJ_TKM_NAME
        KYK_GTJ_SEK_TSY_KKY_ID
        KYK_GTJ_SEK_TSY_KKY_NAME
        KYK_GTJ_SEK_DIV
        KYK_GTJ_SEK_DIV_NAME
        KYK_GTJ_KINGAKU
        KYK_GTJ_KINGAKU_TAX
        KYK_GTJ_TAX_DIV
        KYK_GTJ_TAX_DIV_NAME
        total_amount
        tax_flg
        KYK_GTJ_ZIJ_FLG
      }
      mst_kokyaku_copy {
        kky_id
        company_id
        status
        kky_name
        kky_name_kana
        kky_tel_01
        kky_tel_02
        kky_legalperson_div
      }
      appUser {
        id
        status
        username
        password
        is_activation
        registered_at
        attachment_id
        last_login_at
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getContractCsv = /* GraphQL */ `
  query GetContractCsv(
    $filter: TableContractFilterInput
    $offset: Int
    $limit: Int
  ) {
    getContractCsv(filter: $filter, offset: $offset, limit: $limit) {
      key
      companyId
    }
  }
`
export const getContractCsv2 = /* GraphQL */ `
  query GetContractCsv2(
    $filter: TableContractFilterInput
    $offset: Int
    $limit: Int
  ) {
    getContractCsv2(filter: $filter, offset: $offset, limit: $limit) {
      key
      companyId
    }
  }
`
export const getCustomerList = /* GraphQL */ `
  query GetCustomerList(
    $filter: TableCustomerFilterInput
    $offset: Int
    $limit: Int
  ) {
    getCustomerList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        customer {
          id
          status
          company_id
          kky_id
          app_user_id
          username
          password
          iot_provider_id
          is_usage
          is_activation
          registered_at
          remark
          created_at
          updated_at
          deleted_at
        }
        tenantContracts {
          id
          status
          company_id
          building_id
          room_id
          contractor_id
          tenant_id
          kyk_id
          kyk_status_div
          kyk_keiyaku_str_date
          kyk_keiyaku_end_date
          created_at
          updated_at
          deleted_at
        }
        mstkokyaku {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        appUser {
          id
          status
          username
          password
          is_activation
          registered_at
          attachment_id
          last_login_at
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        kYKNKYKKYIDKNRKEIYAKUS {
          KYK_ID
          KYK_HYA_ID
          KYK_MSK_ID
          KYK_ISP_KYK_ID
          KYK_INSERTED_DATE
          KYK_UPDATED_DATE
          KYK_DEL_FLG
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_KEIYAKU_DATE
          KYK_NYUKYO_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KEIYAKU_END_DATE
          KYK_REMARK
          KYK_FREERENT_FLG
          KYK_TINRYO_HASSEI_DATE
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHINRYO_MN
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_YR
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_DATE
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCustomerHistoryTypeList = /* GraphQL */ `
  query GetCustomerHistoryTypeList(
    $filter: TableCustomerHistoryTypeFilterInput
    $offset: Int
    $limit: Int
  ) {
    getCustomerHistoryTypeList(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      data {
        id
        status
        name
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCustomerHistoryThreadList = /* GraphQL */ `
  query GetCustomerHistoryThreadList(
    $filter: TableCustomerHistoryThreadFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCustomerHistoryThreadList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      data {
        customer_history_thread {
          id
          original_data_id
          created_at
          status
          large_category {
            id
            status
            company_id
            parent_id
            name
            display_order
            created_at
            updated_at
            deleted_at
          }
          medium_category {
            id
            status
            company_id
            parent_id
            name
            display_order
            created_at
            updated_at
            deleted_at
          }
          application_type {
            id
            status
            name
            created_at
            updated_at
            deleted_at
          }
          other_activity_type {
            id
            name
            created_at
            updated_at
            deleted_at
          }
          type
          updated_at
          updated_user {
            attachment_id
            company_group_id
            company_id
            created_at
            deleted_at
            display_name
            id
            is_administrator
            mail_address
            name
            password
            status
            type_id
            updated_at
            username
          }
        }
        customer_history_messages {
          customer_history_thread_id
          customer_history_message_type_id
          message
          created_at
          updated_user
          updated_user_status
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCustomerHistoryMessage = /* GraphQL */ `
  query GetCustomerHistoryMessage(
    $filter: TableCustomerHistoryMessageFilterInput
  ) {
    getCustomerHistoryMessage(filter: $filter) {
      customer_history_thread_id
      customer_history_message_type_id
      message
      created_at
      updated_user
      updated_user_status
    }
  }
`
export const getBulletinBoardTypeList = /* GraphQL */ `
  query GetBulletinBoardTypeList(
    $filter: TableBulletinBoardTypeFilterInput
    $offset: Int
    $limit: Int
  ) {
    getBulletinBoardTypeList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        status
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBulletinBoardList = /* GraphQL */ `
  query GetBulletinBoardList(
    $filter: TableBulletinBoardFilterInput
    $offset: Int
    $limit: Int
  ) {
    getBulletinBoardList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        bulletin_board {
          id
          status
          company_id
          bulletin_board_type_id
          is_important
          title
          body
          url
          display_start_at
          display_end_at
          is_assign
          display_order
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
          with_owner
          url_text
        }
        bulletin_board_type {
          id
          status
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        building {
          id
          status
          company_id
          tty_id
          tty_isp_tty_id
          is_management
          remark
          room_cnt
          contract_cnt
          customer_cnt
          display_order
          created_at
          updated_at
          deleted_at
          mst_tateya {
            TTY_ID
            TTY_ISP_TTY_ID
            TTY_ISP_KRM_ID
            TTY_INSERTED_DATE
            TTY_UPDATED_DATE
            TTY_DEL_FLG
            TTY_KNR_NO
            TTY_NAME
            TTY_NAME_KANA
            TTY_COMPLETION_DATE
            TTY_GROUND_FLOOR_NUM
            TTY_UGGROUND_FLOOR_NUM
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_TOTI_MENSEKI
            TTY_NOBEYUKA_MENSEKI
            TTY_ZIPCODE
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_TOTAL_COUNT
            TTY_REMARK
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_TIME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNR_DIV
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_SOG_ID
            TTY_SOG_DAY
            TTY_SOG_NAME
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_TNP_NAME
            TTY_GRP_NAME
            TTY_KNRPATTERN_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        title_attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBulletinBoard = /* GraphQL */ `
  query GetBulletinBoard($filter: TableBulletinBoardFilterInput) {
    getBulletinBoard(filter: $filter) {
      bulletin_board {
        id
        status
        company_id
        bulletin_board_type_id
        is_important
        title
        body
        url
        display_start_at
        display_end_at
        is_assign
        display_order
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
        with_owner
        url_text
      }
      bulletin_board_type {
        id
        status
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      building {
        id
        status
        company_id
        tty_id
        tty_isp_tty_id
        is_management
        remark
        room_cnt
        contract_cnt
        customer_cnt
        display_order
        created_at
        updated_at
        deleted_at
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      title_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getThreadList = /* GraphQL */ `
  query GetThreadList(
    $filter: TableThreadFilterInput
    $offset: Int
    $limit: Int
  ) {
    getThreadList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        status
        company_id
        category_id
        application_id
        customer_id
        building_id
        room_id
        thread_state_id
        is_reply
        assigned_group_by
        assigned_by
        exchanged_at
        exchanged_by
        contract_id
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getThreadListBySearchCondition = /* GraphQL */ `
  query GetThreadListBySearchCondition(
    $filter: ThreadSearchCondition
    $offset: Int
    $limit: Int
  ) {
    getThreadListBySearchCondition(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      data {
        assignedGroup {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assignedUser {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        arrangementStatus
        exchangedUser {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        owner {
          id
          name
          name_kana
          app_user_id
          company_id
          created_at
          updated_at
          deleted_at
        }
        isArranged
        largeCategory {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        mediumCategory {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        mstheya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mstkokyaku {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        msttateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        thread {
          id
          status
          company_id
          category_id
          application_id
          customer_id
          building_id
          room_id
          thread_state_id
          is_reply
          assigned_group_by
          assigned_by
          exchanged_at
          exchanged_by
          contract_id
          created_at
          updated_at
          deleted_at
        }
        threadParams {
          first_sent
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getThread = /* GraphQL */ `
  query GetThread($filter: TableThreadFilterInput, $is_owner: Boolean) {
    getThread(filter: $filter, is_owner: $is_owner) {
      thread {
        id
        status
        company_id
        category_id
        application_id
        customer_id
        building_id
        room_id
        thread_state_id
        is_reply
        assigned_group_by
        assigned_by
        exchanged_at
        exchanged_by
        contract_id
        created_at
        updated_at
        deleted_at
      }
      thread_memos {
        id
        thread_id
        memo
        updater {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getThreadCsv = /* GraphQL */ `
  query GetThreadCsv(
    $filter: ThreadSearchCondition
    $offset: Int
    $limit: Int
  ) {
    getThreadCsv(filter: $filter, offset: $offset, limit: $limit) {
      key
      companyId
    }
  }
`
export const getMessageTypeList = /* GraphQL */ `
  query GetMessageTypeList($filter: TableMessageTypeFilterInput) {
    getMessageTypeList(filter: $filter) {
      data {
        id
        status
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getMessageList = /* GraphQL */ `
  query GetMessageList(
    $filter: TableMessageFilterInput
    $offset: Int
    $limit: Int
    $is_owner: Boolean
  ) {
    getMessageList(
      filter: $filter
      offset: $offset
      limit: $limit
      is_owner: $is_owner
    ) {
      data {
        message {
          id
          status
          company_id
          thread_id
          customer_id
          company_user_id
          message_type_id
          body
          is_read
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getMessage = /* GraphQL */ `
  query GetMessage($filter: TableMessageFilterInput, $owner_id: Int) {
    getMessage(filter: $filter, owner_id: $owner_id) {
      message {
        id
        status
        company_id
        thread_id
        customer_id
        company_user_id
        message_type_id
        body
        is_read
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getOwner = /* GraphQL */ `
  query GetOwner($filter: TableOwnerFilterInput, $limit: Int, $offset: Int) {
    getOwner(filter: $filter, limit: $limit, offset: $offset) {
      owner {
        id
        name
        name_kana
        app_user_id
        company_id
        created_at
        updated_at
        deleted_at
      }
      app_user_and_attachment {
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        created_at
        deleted_at
        id
        is_activation
        last_login_at
        password
        registered_at
        status
        updated_at
        username
      }
      mst_tateya_and_building {
        attachments {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        building {
          id
          status
          company_id
          tty_id
          tty_isp_tty_id
          is_management
          remark
          room_cnt
          contract_cnt
          customer_cnt
          display_order
          created_at
          updated_at
          deleted_at
          mst_tateya {
            TTY_ID
            TTY_ISP_TTY_ID
            TTY_ISP_KRM_ID
            TTY_INSERTED_DATE
            TTY_UPDATED_DATE
            TTY_DEL_FLG
            TTY_KNR_NO
            TTY_NAME
            TTY_NAME_KANA
            TTY_COMPLETION_DATE
            TTY_GROUND_FLOOR_NUM
            TTY_UGGROUND_FLOOR_NUM
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_TOTI_MENSEKI
            TTY_NOBEYUKA_MENSEKI
            TTY_ZIPCODE
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_TOTAL_COUNT
            TTY_REMARK
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_TIME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNR_DIV
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_SOG_ID
            TTY_SOG_DAY
            TTY_SOG_NAME
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_TNP_NAME
            TTY_GRP_NAME
            TTY_KNRPATTERN_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
      }
    }
  }
`
export const getOwnerAttachments = /* GraphQL */ `
  query GetOwnerAttachments(
    $limit: Int
    $offset: Int
    $owner_id: Int
    $building_id: Int
  ) {
    getOwnerAttachments(
      limit: $limit
      offset: $offset
      owner_id: $owner_id
      building_id: $building_id
    ) {
      owner_attachment {
        category_id
        category_name
        owner_attachment_id
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      category {
        id
        name
      }
    }
  }
`
export const getOwnerBuilding = /* GraphQL */ `
  query GetOwnerBuilding($owner_id: Int, $building_id: Int) {
    getOwnerBuilding(owner_id: $owner_id, building_id: $building_id) {
      id
      owner_id
      building_id
      is_usage
      created_at
      updated_at
      deleted_at
    }
  }
`
export const getOwnerList = /* GraphQL */ `
  query GetOwnerList(
    $filter: TableOwnerFilterInput
    $offset: Int
    $limit: Int
  ) {
    getOwnerList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        owner {
          id
          name
          name_kana
          app_user_id
          company_id
          created_at
          updated_at
          deleted_at
        }
        app_user {
          id
          status
          username
          password
          is_activation
          registered_at
          attachment_id
          last_login_at
          created_at
          updated_at
          deleted_at
        }
        owner_building_and_building_and_tty {
          owner_building {
            id
            owner_id
            building_id
            is_usage
            created_at
            updated_at
            deleted_at
          }
          building {
            id
            status
            company_id
            tty_id
            tty_isp_tty_id
            is_management
            remark
            room_cnt
            contract_cnt
            customer_cnt
            display_order
            created_at
            updated_at
            deleted_at
            mst_tateya {
              TTY_ID
              TTY_ISP_TTY_ID
              TTY_ISP_KRM_ID
              TTY_INSERTED_DATE
              TTY_UPDATED_DATE
              TTY_DEL_FLG
              TTY_KNR_NO
              TTY_NAME
              TTY_NAME_KANA
              TTY_COMPLETION_DATE
              TTY_GROUND_FLOOR_NUM
              TTY_UGGROUND_FLOOR_NUM
              TTY_TYS_CD
              TTY_TYS_NAME
              TTY_STS_CD
              TTY_STS_NAME
              TTY_TYK_CD
              TTY_TYK_NAME
              TTY_YAR_CD
              TTY_YAR_NAME
              TTY_TOTI_MENSEKI
              TTY_NOBEYUKA_MENSEKI
              TTY_ZIPCODE
              TTY_PRF_CD
              TTY_PRF_NAME
              TTY_CTY_CD
              TTY_CTY_NAME
              TTY_TWN_CD
              TTY_TWN_NAME
              TTY_ADDR_01
              TTY_ADDR_02
              TTY_TOTAL_COUNT
              TTY_REMARK
              TTY_NEAREST_TRAIN_01_RAILWAY
              TTY_NEAREST_TRAIN_01_STATION
              TTY_NEAREST_TRAIN_01_TIME
              TTY_NEAREST_TRAIN_02_RAILWAY
              TTY_NEAREST_TRAIN_02_STATION
              TTY_NEAREST_TRAIN_02_TIME
              TTY_NEAREST_TRAIN_03_RAILWAY
              TTY_NEAREST_TRAIN_03_STATION
              TTY_NEAREST_TRAIN_03_TIME
              TTY_NEAREST_BUS_01_RAILWAY
              TTY_NEAREST_BUS_01_STATION
              TTY_NEAREST_BUS_01_RIDE_TIME
              TTY_NEAREST_BUS_01_TIME
              TTY_NEAREST_BUS_02_RAILWAY
              TTY_NEAREST_BUS_02_STATION
              TTY_NEAREST_BUS_02_RIDE_TIME
              TTY_NEAREST_BUS_02_TIME
              TTY_NEAREST_BUS_03_RAILWAY
              TTY_NEAREST_BUS_03_STATION
              TTY_NEAREST_BUS_03_RIDE_TIME
              TTY_NEAREST_BUS_03_TIME
              TTY_PUBLIC_ELC_KKY_NAME
              TTY_PUBLIC_ELC_KKY_TEL
              TTY_PUBLIC_WAT_KYO_CD
              TTY_PUBLIC_WAT_KYO_NAME
              TTY_PUBLIC_WAT_KKY_NAME
              TTY_PUBLIC_WAT_KKY_TEL
              TTY_PUBLIC_DRA_KYO_CD
              TTY_PUBLIC_DRA_KYO_NAME
              TTY_PUBLIC_DRA_KKY_NAME
              TTY_PUBLIC_DRA_KKY_TEL
              TTY_PUBLIC_GUS_KKY_NAME
              TTY_PUBLIC_GUS_KKY_TEL
              TTY_PUBLIC_HOTW_KKY_NAME
              TTY_PUBLIC_HOTW_KKY_TEL
              TTY_OWN_01_KKY_ID
              TTY_OWN_01_KKY_NAME
              TTY_OWN_02_KKY_ID
              TTY_OWN_02_KKY_NAME
              TTY_OWN_03_KKY_ID
              TTY_OWN_03_KKY_NAME
              TTY_LESSOR_KKY_ID
              TTY_LESSOR_KKY_NAME
              TTY_KNR_ORG_KEIYAKU_DATE
              TTY_KNR_KEIYAKU_STR_DATE
              TTY_KNR_KEIYAKU_END_DATE
              TTY_KNR_NEWEST_KSN_DATE
              TTY_KNR_KANRIGIRE_FLG
              TTY_KNR_TANTO_SHN_ID
              TTY_KNR_TANTO_SHN_NAME
              TTY_KNR_DIV
              TTY_IKT_LEASE_KINGAKU
              TTY_IKT_LEASE_KINGAKU_TAX
              TTY_SOG_ID
              TTY_SOG_DAY
              TTY_SOG_NAME
              TTY_KNRKEITAI_DIV
              TTY_KNRKEITAI_DIV_NAME
              TTY_TNP_NAME
              TTY_GRP_NAME
              TTY_KNRPATTERN_NAME
              TTY_BSH_TANTO_SHN_NAME
              TTY_GET_TANTO_SHN_NAME
            }
          }
          mst_tateya {
            TTY_ID
            TTY_ISP_TTY_ID
            TTY_ISP_KRM_ID
            TTY_INSERTED_DATE
            TTY_UPDATED_DATE
            TTY_DEL_FLG
            TTY_KNR_NO
            TTY_NAME
            TTY_NAME_KANA
            TTY_COMPLETION_DATE
            TTY_GROUND_FLOOR_NUM
            TTY_UGGROUND_FLOOR_NUM
            TTY_TYS_CD
            TTY_TYS_NAME
            TTY_STS_CD
            TTY_STS_NAME
            TTY_TYK_CD
            TTY_TYK_NAME
            TTY_YAR_CD
            TTY_YAR_NAME
            TTY_TOTI_MENSEKI
            TTY_NOBEYUKA_MENSEKI
            TTY_ZIPCODE
            TTY_PRF_CD
            TTY_PRF_NAME
            TTY_CTY_CD
            TTY_CTY_NAME
            TTY_TWN_CD
            TTY_TWN_NAME
            TTY_ADDR_01
            TTY_ADDR_02
            TTY_TOTAL_COUNT
            TTY_REMARK
            TTY_NEAREST_TRAIN_01_RAILWAY
            TTY_NEAREST_TRAIN_01_STATION
            TTY_NEAREST_TRAIN_01_TIME
            TTY_NEAREST_TRAIN_02_RAILWAY
            TTY_NEAREST_TRAIN_02_STATION
            TTY_NEAREST_TRAIN_02_TIME
            TTY_NEAREST_TRAIN_03_RAILWAY
            TTY_NEAREST_TRAIN_03_STATION
            TTY_NEAREST_TRAIN_03_TIME
            TTY_NEAREST_BUS_01_RAILWAY
            TTY_NEAREST_BUS_01_STATION
            TTY_NEAREST_BUS_01_RIDE_TIME
            TTY_NEAREST_BUS_01_TIME
            TTY_NEAREST_BUS_02_RAILWAY
            TTY_NEAREST_BUS_02_STATION
            TTY_NEAREST_BUS_02_RIDE_TIME
            TTY_NEAREST_BUS_02_TIME
            TTY_NEAREST_BUS_03_RAILWAY
            TTY_NEAREST_BUS_03_STATION
            TTY_NEAREST_BUS_03_RIDE_TIME
            TTY_NEAREST_BUS_03_TIME
            TTY_PUBLIC_ELC_KKY_NAME
            TTY_PUBLIC_ELC_KKY_TEL
            TTY_PUBLIC_WAT_KYO_CD
            TTY_PUBLIC_WAT_KYO_NAME
            TTY_PUBLIC_WAT_KKY_NAME
            TTY_PUBLIC_WAT_KKY_TEL
            TTY_PUBLIC_DRA_KYO_CD
            TTY_PUBLIC_DRA_KYO_NAME
            TTY_PUBLIC_DRA_KKY_NAME
            TTY_PUBLIC_DRA_KKY_TEL
            TTY_PUBLIC_GUS_KKY_NAME
            TTY_PUBLIC_GUS_KKY_TEL
            TTY_PUBLIC_HOTW_KKY_NAME
            TTY_PUBLIC_HOTW_KKY_TEL
            TTY_OWN_01_KKY_ID
            TTY_OWN_01_KKY_NAME
            TTY_OWN_02_KKY_ID
            TTY_OWN_02_KKY_NAME
            TTY_OWN_03_KKY_ID
            TTY_OWN_03_KKY_NAME
            TTY_LESSOR_KKY_ID
            TTY_LESSOR_KKY_NAME
            TTY_KNR_ORG_KEIYAKU_DATE
            TTY_KNR_KEIYAKU_STR_DATE
            TTY_KNR_KEIYAKU_END_DATE
            TTY_KNR_NEWEST_KSN_DATE
            TTY_KNR_KANRIGIRE_FLG
            TTY_KNR_TANTO_SHN_ID
            TTY_KNR_TANTO_SHN_NAME
            TTY_KNR_DIV
            TTY_IKT_LEASE_KINGAKU
            TTY_IKT_LEASE_KINGAKU_TAX
            TTY_SOG_ID
            TTY_SOG_DAY
            TTY_SOG_NAME
            TTY_KNRKEITAI_DIV
            TTY_KNRKEITAI_DIV_NAME
            TTY_TNP_NAME
            TTY_GRP_NAME
            TTY_KNRPATTERN_NAME
            TTY_BSH_TANTO_SHN_NAME
            TTY_GET_TANTO_SHN_NAME
          }
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
      is_latest
    }
  }
`
export const getOwnerListByBuilding = /* GraphQL */ `
  query GetOwnerListByBuilding(
    $filter: TableOwnerByBuildingFilterInput
    $limit: Int
    $offset: Int
  ) {
    getOwnerListByBuilding(filter: $filter, limit: $limit, offset: $offset) {
      data {
        owner {
          id
          name
          name_kana
          app_user_id
          company_id
          created_at
          updated_at
          deleted_at
        }
        app_user {
          id
          status
          username
          password
          is_activation
          registered_at
          attachment_id
          last_login_at
          created_at
          updated_at
          deleted_at
        }
        owner_buildings {
          id
          owner_id
          building_id
          is_usage
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
      is_latest
    }
  }
`
export const getDataLinkTypeList = /* GraphQL */ `
  query GetDataLinkTypeList($filter: TableDataLinkTypeFilterInput) {
    getDataLinkTypeList(filter: $filter) {
      data {
        id
        status
        name
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getDataLinkHistoryList = /* GraphQL */ `
  query GetDataLinkHistoryList(
    $filter: TableDataLinkHistoryFilterInput
    $offset: Int
    $limit: Int
  ) {
    getDataLinkHistoryList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        data_link_history {
          id
          status
          company_id
          data_link_type_id
          data_link_state_id
          data_type_id
          attachment_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        error_attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getTotonoSessionDev = /* GraphQL */ `
  query GetTotonoSessionDev($token: String!) {
    getTotonoSessionDev(token: $token) {
      token
      type
      user
      company
    }
  }
`
export const listTotonoSessionDevs = /* GraphQL */ `
  query ListTotonoSessionDevs(
    $filter: TableTotonoSessionDevFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTotonoSessionDevs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        token
        type
        user
        company
      }
      nextToken
    }
  }
`
export const getFaqAttachment = /* GraphQL */ `
  query GetFaqAttachment($faqId: Int) {
    getFaqAttachment(faqId: $faqId) {
      img_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      movie_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      pdf_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getFaqAttachment2 = /* GraphQL */ `
  query GetFaqAttachment2($faqId: Int) {
    getFaqAttachment2(faqId: $faqId) {
      img_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      movie_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      pdf_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getLargeCategoryAndMediumCategory = /* GraphQL */ `
  query GetLargeCategoryAndMediumCategory($category_type: Int) {
    getLargeCategoryAndMediumCategory(category_type: $category_type) {
      large_categories {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      medium_categories {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getFaq = /* GraphQL */ `
  query GetFaq($faqId: Int) {
    getFaq(faqId: $faqId) {
      faq {
        id
        status
        company_id
        category_id
        question
        answer
        display_order
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
      }
      medium_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      large_category {
        id
        status
        company_id
        parent_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const getFaqListBySearchCondition = /* GraphQL */ `
  query GetFaqListBySearchCondition(
    $filter: FaqAndMediumCategoryAndLargeCategoryAndAttachmentTypeSearchConditon
  ) {
    getFaqListBySearchCondition(filter: $filter) {
      data {
        faq {
          id
          status
          company_id
          category_id
          question
          answer
          display_order
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
        }
        medium_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        large_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        faq_attachment_type {
          faq_id
          attachment_type_names
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getFaqList = /* GraphQL */ `
  query GetFaqList($filter: TableFaqFilterInput, $offset: Int, $limit: Int) {
    getFaqList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        faq {
          id
          status
          company_id
          category_id
          question
          answer
          display_order
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
        }
        large_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        medium_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getPrefectureList = /* GraphQL */ `
  query GetPrefectureList {
    getPrefectureList {
      data {
        id
        status
        prf_cd
        name
        name_kana
        display_order
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCityList = /* GraphQL */ `
  query GetCityList($filter: TableCityFilterInput) {
    getCityList(filter: $filter) {
      data {
        id
        status
        prefecture_id
        cty_cd
        name
        name_kana
        display_order
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getMSTTateyaKNRDivList = /* GraphQL */ `
  query GetMSTTateyaKNRDivList {
    getMSTTateyaKNRDivList {
      data {
        id
        knr_cd
        knr_name
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getMSTTateyaKnrkeitaiDivList = /* GraphQL */ `
  query GetMSTTateyaKnrkeitaiDivList {
    getMSTTateyaKnrkeitaiDivList {
      data {
        id
        knrkeitai_cd
        knrkeitai_name
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getMSTTateyaTysList = /* GraphQL */ `
  query GetMSTTateyaTysList {
    getMSTTateyaTysList {
      data {
        id
        tys_cd
        tys_name
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getMSTKokyakuSKGList = /* GraphQL */ `
  query GetMSTKokyakuSKGList {
    getMSTKokyakuSKGList {
      data {
        id
        skg_cd
        skg_name
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getResidentInfoChangeApplicationList = /* GraphQL */ `
  query GetResidentInfoChangeApplicationList(
    $filter: TableResidentInfoChangeApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getResidentInfoChangeApplicationList(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      data {
        application {
          id
          status
          company_id
          customer_id
          building_id
          room_id
          application_type_id
          application_state_id
          content
          remark
          contract_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getResidentInfoChangeApplication = /* GraphQL */ `
  query GetResidentInfoChangeApplication(
    $filter: TableResidentInfoChangeApplicationFilterInput
  ) {
    getResidentInfoChangeApplication(filter: $filter) {
      application {
        id
        status
        company_id
        customer_id
        building_id
        room_id
        application_type_id
        application_state_id
        content
        remark
        contract_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getResidentCancellationApplicationList = /* GraphQL */ `
  query GetResidentCancellationApplicationList(
    $filter: TableResidentCancellationApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getResidentCancellationApplicationList(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      data {
        application {
          id
          status
          company_id
          customer_id
          building_id
          room_id
          application_type_id
          application_state_id
          content
          remark
          contract_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getResidentCancellationApplication = /* GraphQL */ `
  query GetResidentCancellationApplication(
    $filter: TableResidentCancellationApplicationFilterInput
  ) {
    getResidentCancellationApplication(filter: $filter) {
      application {
        id
        status
        company_id
        customer_id
        building_id
        room_id
        application_type_id
        application_state_id
        content
        remark
        contract_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getResidentCancellationApplicationCsv = /* GraphQL */ `
  query GetResidentCancellationApplicationCsv(
    $filter: TableResidentCancellationApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getResidentCancellationApplicationCsv(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      key
      companyId
    }
  }
`
export const getRoomCheckListApplicationList = /* GraphQL */ `
  query GetRoomCheckListApplicationList(
    $filter: TableRoomCheckListApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getRoomCheckListApplicationList(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      data {
        application {
          id
          status
          company_id
          customer_id
          building_id
          room_id
          application_type_id
          application_state_id
          content
          remark
          contract_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getRoomCheckListApplication = /* GraphQL */ `
  query GetRoomCheckListApplication(
    $filter: TableRoomCheckListApplicationFilterInput
  ) {
    getRoomCheckListApplication(filter: $filter) {
      application {
        id
        status
        company_id
        customer_id
        building_id
        room_id
        application_type_id
        application_state_id
        content
        remark
        contract_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getBicycleParkingStickerApplicationList = /* GraphQL */ `
  query GetBicycleParkingStickerApplicationList(
    $filter: TableBicycleParkingStickerApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getBicycleParkingStickerApplicationList(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      data {
        application {
          id
          status
          company_id
          customer_id
          building_id
          room_id
          application_type_id
          application_state_id
          content
          remark
          contract_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBicycleParkingStickerApplication = /* GraphQL */ `
  query GetBicycleParkingStickerApplication(
    $filter: TableBicycleParkingStickerApplicationFilterInput
  ) {
    getBicycleParkingStickerApplication(filter: $filter) {
      application {
        id
        status
        company_id
        customer_id
        building_id
        room_id
        application_type_id
        application_state_id
        content
        remark
        contract_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getCoc2UseParkingApplicationList = /* GraphQL */ `
  query GetCoc2UseParkingApplicationList(
    $filter: TableCoc2UseParkingApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getCoc2UseParkingApplicationList(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      data {
        application {
          id
          status
          company_id
          customer_id
          building_id
          room_id
          application_type_id
          application_state_id
          content
          remark
          contract_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCoc2UseParkingApplication = /* GraphQL */ `
  query GetCoc2UseParkingApplication(
    $filter: TableCoc2UseParkingApplicationFilterInput
  ) {
    getCoc2UseParkingApplication(filter: $filter) {
      application {
        id
        status
        company_id
        customer_id
        building_id
        room_id
        application_type_id
        application_state_id
        content
        remark
        contract_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getPetApplicationList = /* GraphQL */ `
  query GetPetApplicationList(
    $filter: TablePetApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getPetApplicationList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        application {
          id
          status
          company_id
          customer_id
          building_id
          room_id
          application_type_id
          application_state_id
          content
          remark
          contract_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        arrangement_status
        is_arranged
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        mst_kokyaku_kys {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_kokyaku_nky {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        assigned_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        application_memos {
          id
          application_id
          memo
          company_user_id
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getPetApplication = /* GraphQL */ `
  query GetPetApplication($filter: TablePetApplicationFilterInput) {
    getPetApplication(filter: $filter) {
      application {
        id
        status
        company_id
        customer_id
        building_id
        room_id
        application_type_id
        application_state_id
        content
        remark
        contract_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      arrangement_status
      is_arranged
      mst_heya {
        HYA_ID
        HYA_TTY_ID
        HYA_ISP_KRS_ID
        HYA_INSERTED_DATE
        HYA_UPDATED_DATE
        HYA_DEL_FLG
        HYA_ROOM_NO
        HYA_FLS_CD
        HYA_FLS_NAME
        HYA_FLS_NUM
        HYA_MDR_NUM
        HYA_MDR_CD
        HYA_MDR_NAME
        HYA_SENYU_MENSEKI
        HYA_BALCONY_MENSEKI
        HYA_TYS_CD
        HYA_TYS_NAME
        HYA_STS_CD
        HYA_STS_NAME
        HYA_DCN_CD
        HYA_DCN_NAME
        HYA_STATUS_DIV
        HYA_STATUS_DIV_NAME
        HYA_THT_CD
        HYA_THT_NAME
        HYA_BSH_START_DATE
        HYA_NYUKYOKANO_DATE
        HYA_KAIYAKU_DATE
        HYA_TAIKYO_DATE
        HYA_HOSYO_DIV
        HYA_HOSYO_DIV_NAME
        HYA_HOSYO_GYS_KKY_ID
        HYA_HOSYO_GYS_KKY_NAME
        HYA_HOSYO_REMARK
        HYA_IKT_LEASE_KINGAKU
        HYA_IKT_LEASE_KINGAKU_TAX
        HYA_INET_KOKAI_DIV
        HYA_INET_KOKAI_NAME
        HYA_NEW_BUILD_FLG
        HYA_NEW_BUILD_FLG_NAME
        HYA_PARKING_DIV
        HYA_PARKING_DIV_NAME
        HYA_PARKING_KINGAKU
        HYA_PARKING_KINGAKU_TAX
        HYA_PARKING_KYORI
        HYA_FREERENT_MN
        HYA_FREERENT_REMARK
        HYA_TEISYAKU_KEIYAKU_DIV
        HYA_TEISYAKU_KEIYAKU_NAME
        HYA_TEISYAKU_KEIYAKU_DATE
        HYA_HOKEN_DIV
        HYA_HOKEN_DIV_NAME
        HYA_HOKEN_YR
        HYA_HOKEN_KINGAKU
        HYA_KOUKOKU_PR
        HYA_KOUKOKU_PR_COMMENT
        HYA_GYS_KOUKOKU_PR
        HYA_GYS_KOUKOKU_PR_COMMENT
        HYA_KEY_PLACE
        HYA_BOX_NUMBER
        HYA_REMARK
        HYA_CYUKAI_TKM_CD
        HYA_CYUKAI_TKM_NAME
        HYA_CYUKAI_CALC_DIV
        HYA_CYUKAI_CALC_DIV_NAME
        HYA_CYUKAI_CALC_RATE
        HYA_CYUKAI_KARI_RATE
        HYA_CYUKAI_KASHI_RATE
        HYA_CYUKAI_MOTO_RATE
        HYA_CYUKAI_KYAKU_RATE
        HYA_CYUKAI_KINGAKU
        HYA_CYUKAI_KINGAKU_TAX
        HYA_JIMU_TKM_CD
        HYA_JIMU_TKM_NAME
        HYA_JIMU_CALC_DIV
        HYA_JIMU_CALC_DIV_NAME
        HYA_JIMU_CALC_RATE
        HYA_JIMU_KARI_RATE
        HYA_JIMU_KASHI_RATE
        HYA_JIMU_MOTO_RATE
        HYA_JIMU_KYAKU_RATE
        HYA_JIMU_KINGAKU
        HYA_JIMU_KINGAKU_TAX
        HYA_KOUKOKU_TKM_CD
        HYA_KOUKOKU_TKM_NAME
        HYA_KOUKOKU_CALC_DIV
        HYA_KOUKOKU_CALC_DIV_NAME
        HYA_KOUKOKU_CALC_RATE
        HYA_KOUKOKU_KARI_RATE
        HYA_KOUKOKU_KASHI_RATE
        HYA_KOUKOKU_MOTO_RATE
        HYA_KOUKOKU_KYAKU_RATE
        HYA_KOUKOKU_KINGAKU
        HYA_KOUKOKU_KINGAKU_TAX
        HYA_KOSHINRYO_MN
        HYA_KOSHINRYO_KINGAKU
        HYA_KOSHINRYO_KINGAKU_TAX
        HYA_KOSHINRYO_CALC_DIV
        HYA_KOSHINRYO_CALC_DIV_NAME
        HYA_KOSHIN_YR
        HYA_SYSTEM_DIV
        HYA_SYSTEM_DIV_NAME
      }
      mst_kokyaku_kys {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_kokyaku_nky {
        KKY_ID
        KKY_ISP_KKY_ID
        KKY_INSERTED_DATE
        KKY_UPDATED_DATE
        KKY_DEL_FLG
        KKY_DIV
        KKY_KNR_NO
        KKY_NAME
        KKY_NAME_KANA
        KKY_LEGALPERSON_DIV
        KKY_LEGALPERSON_DIV_NAME
        KKY_BOSSNAME
        KKY_ZIPCODE
        KKY_PRF_CD
        KKY_PRF_NAME
        KKY_CTY_CD
        KKY_CTY_NAME
        KKY_TWN_CD
        KKY_TWN_NAME
        KKY_ADDR_01
        KKY_ADDR_02
        KKY_TEL_01
        KKY_TEL_02
        KKY_FAX
        KKY_EMAILADDR
        KKY_SEX_DIV
        KKY_SEX_DIV_NAME
        KKY_BIRTHDAY
        KKY_NATIONARITY
        KKY_SKG_CD
        KKY_SKG_NAME
        KKY_YKS_CD
        KKY_YKS_NAME
        KKY_INCOME
        KKY_REMARK
        KKY_STAFF_SHN_ID
        KKY_STAFF_SHN_NAME
        KKY_WORKPLACE
        KKY_WORKPLACE_ATENA
        KKY_WORKPLACE_ZIPCODE
        KKY_WORKPLACE_PRF_CD
        KKY_WORKPLACE_PRF_NAME
        KKY_WORKPLACE_CTY_CD
        KKY_WORKPLACE_CTY_NAME
        KKY_WORKPLACE_TWN_CD
        KKY_WORKPLACE_TWN_NAME
        KKY_WORKPLACE_ADDR_01
        KKY_WORKPLACE_ADDR_02
        KKY_WORKPLACE_TEL_01
        KKY_WORKPLACE_TEL_02
        KKY_WORKPLACE_FAX
        KKY_CONTACT_ATENA
        KKY_CONTACT_ZIPCODE
        KKY_CONTACT_PRF_CD
        KKY_CONTACT_PRF_NAME
        KKY_CONTACT_CTY_CD
        KKY_CONTACT_CTY_NAME
        KKY_CONTACT_TWN_CD
        KKY_CONTACT_TWN_NAME
        KKY_CONTACT_ADDR_01
        KKY_CONTACT_ADDR_02
        KKY_CONTACT_TEL_01
        KKY_CONTACT_TEL_02
        KKY_CONTACT_FAX
        KKY_EMARGENCY_ATENA
        KKY_EMARGENCY_ZIPCODE
        KKY_EMARGENCY_PRF_CD
        KKY_EMARGENCY_PRF_NAME
        KKY_EMARGENCY_CTY_CD
        KKY_EMARGENCY_CTY_NAME
        KKY_EMARGENCY_TWN_CD
        KKY_EMARGENCY_TWN_NAME
        KKY_EMARGENCY_ADDR_01
        KKY_EMARGENCY_ADDR_02
        KKY_EMARGENCY_TEL_01
        KKY_EMARGENCY_TEL_02
        KKY_EMARGENCY_FAX
      }
      mst_tateya {
        TTY_ID
        TTY_ISP_TTY_ID
        TTY_ISP_KRM_ID
        TTY_INSERTED_DATE
        TTY_UPDATED_DATE
        TTY_DEL_FLG
        TTY_KNR_NO
        TTY_NAME
        TTY_NAME_KANA
        TTY_COMPLETION_DATE
        TTY_GROUND_FLOOR_NUM
        TTY_UGGROUND_FLOOR_NUM
        TTY_TYS_CD
        TTY_TYS_NAME
        TTY_STS_CD
        TTY_STS_NAME
        TTY_TYK_CD
        TTY_TYK_NAME
        TTY_YAR_CD
        TTY_YAR_NAME
        TTY_TOTI_MENSEKI
        TTY_NOBEYUKA_MENSEKI
        TTY_ZIPCODE
        TTY_PRF_CD
        TTY_PRF_NAME
        TTY_CTY_CD
        TTY_CTY_NAME
        TTY_TWN_CD
        TTY_TWN_NAME
        TTY_ADDR_01
        TTY_ADDR_02
        TTY_TOTAL_COUNT
        TTY_REMARK
        TTY_NEAREST_TRAIN_01_RAILWAY
        TTY_NEAREST_TRAIN_01_STATION
        TTY_NEAREST_TRAIN_01_TIME
        TTY_NEAREST_TRAIN_02_RAILWAY
        TTY_NEAREST_TRAIN_02_STATION
        TTY_NEAREST_TRAIN_02_TIME
        TTY_NEAREST_TRAIN_03_RAILWAY
        TTY_NEAREST_TRAIN_03_STATION
        TTY_NEAREST_TRAIN_03_TIME
        TTY_NEAREST_BUS_01_RAILWAY
        TTY_NEAREST_BUS_01_STATION
        TTY_NEAREST_BUS_01_RIDE_TIME
        TTY_NEAREST_BUS_01_TIME
        TTY_NEAREST_BUS_02_RAILWAY
        TTY_NEAREST_BUS_02_STATION
        TTY_NEAREST_BUS_02_RIDE_TIME
        TTY_NEAREST_BUS_02_TIME
        TTY_NEAREST_BUS_03_RAILWAY
        TTY_NEAREST_BUS_03_STATION
        TTY_NEAREST_BUS_03_RIDE_TIME
        TTY_NEAREST_BUS_03_TIME
        TTY_PUBLIC_ELC_KKY_NAME
        TTY_PUBLIC_ELC_KKY_TEL
        TTY_PUBLIC_WAT_KYO_CD
        TTY_PUBLIC_WAT_KYO_NAME
        TTY_PUBLIC_WAT_KKY_NAME
        TTY_PUBLIC_WAT_KKY_TEL
        TTY_PUBLIC_DRA_KYO_CD
        TTY_PUBLIC_DRA_KYO_NAME
        TTY_PUBLIC_DRA_KKY_NAME
        TTY_PUBLIC_DRA_KKY_TEL
        TTY_PUBLIC_GUS_KKY_NAME
        TTY_PUBLIC_GUS_KKY_TEL
        TTY_PUBLIC_HOTW_KKY_NAME
        TTY_PUBLIC_HOTW_KKY_TEL
        TTY_OWN_01_KKY_ID
        TTY_OWN_01_KKY_NAME
        TTY_OWN_02_KKY_ID
        TTY_OWN_02_KKY_NAME
        TTY_OWN_03_KKY_ID
        TTY_OWN_03_KKY_NAME
        TTY_LESSOR_KKY_ID
        TTY_LESSOR_KKY_NAME
        TTY_KNR_ORG_KEIYAKU_DATE
        TTY_KNR_KEIYAKU_STR_DATE
        TTY_KNR_KEIYAKU_END_DATE
        TTY_KNR_NEWEST_KSN_DATE
        TTY_KNR_KANRIGIRE_FLG
        TTY_KNR_TANTO_SHN_ID
        TTY_KNR_TANTO_SHN_NAME
        TTY_KNR_DIV
        TTY_IKT_LEASE_KINGAKU
        TTY_IKT_LEASE_KINGAKU_TAX
        TTY_SOG_ID
        TTY_SOG_DAY
        TTY_SOG_NAME
        TTY_KNRKEITAI_DIV
        TTY_KNRKEITAI_DIV_NAME
        TTY_TNP_NAME
        TTY_GRP_NAME
        TTY_KNRPATTERN_NAME
        TTY_BSH_TANTO_SHN_NAME
        TTY_GET_TANTO_SHN_NAME
      }
      assigned_user_group {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      assigned_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      application_memos {
        id
        application_id
        memo
        company_user_id
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getApplicationList = /* GraphQL */ `
  query GetApplicationList(
    $filter: TableApplicationFilterInput
    $offset: Int
    $limit: Int
  ) {
    getApplicationList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        status
        company_id
        customer_id
        building_id
        room_id
        application_type_id
        application_state_id
        content
        remark
        contract_id
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getNoticeList = /* GraphQL */ `
  query GetNoticeList(
    $filter: TableNoticeFilterInput
    $offset: Int
    $limit: Int
  ) {
    getNoticeList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        status
        body
        url
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getDashboard = /* GraphQL */ `
  query GetDashboard {
    getDashboard {
      pk
      sk
      totalBuildings
      totalAvailableBuildings
      totalRooms
      totalAvailableRooms
      totalContracts
      totalResidents
      totalAppUsers
      dailyAppUsers
      messageOpen
      messageInProgress
      messagePending
      messageComplete
      applicationResidentCancellationOpen
      applicationResidentCancellationInProgress
      applicationResidentCancellationPending
      applicationResidentInfoChangeOpen
      applicationRoomCheckOpen
      applicationBicycleParkingStickerOpen
      applicationCertificateOfConsentToUseParkingSpaceOpen
      applicationPetOpen
      appUserMonthly
      appUserWeekly
      appUserDaily
    }
  }
`
export const getBuildingNameList = /* GraphQL */ `
  query GetBuildingNameList($offset: Int, $limit: Int) {
    getBuildingNameList(offset: $offset, limit: $limit) {
      data {
        TTY_NAME
        TTY_NAME_KANA
        id
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBuildingCsv = /* GraphQL */ `
  query GetBuildingCsv($filter: TableBuildingFilterInput) {
    getBuildingCsv(filter: $filter) {
      key
      companyId
    }
  }
`
export const getApplicationConvertList = /* GraphQL */ `
  query GetApplicationConvertList(
    $filter: TableApplicationConvertFilterInput
    $offset: Int
    $limit: Int
  ) {
    getApplicationConvertList(filter: $filter, offset: $offset, limit: $limit) {
      data {
        applicationConvert {
          id
          status
          company_id
          convert_status
          convert_type
          application_id
          created_at
          updated_at
          deleted_at
        }
        attachment {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getMessageAutoReply = /* GraphQL */ `
  query GetMessageAutoReply($filter: TableMessageAutoReplyFilterInput) {
    getMessageAutoReply(filter: $filter) {
      id
      status
      company_id
      start
      end
      message_body
      force_auto_reply
      created_at
      updated_at
      deleted_at
    }
  }
`
export const getWeekList = /* GraphQL */ `
  query GetWeekList($filter: TableWeekFilterInput) {
    getWeekList(filter: $filter) {
      data {
        id
        status
        name
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCompanyCloseWeekList = /* GraphQL */ `
  query GetCompanyCloseWeekList($filter: TableCompanyCloseWeekFilterInput) {
    getCompanyCloseWeekList(filter: $filter) {
      data {
        id
        status
        company_id
        week_id
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCompanyHolidayList = /* GraphQL */ `
  query GetCompanyHolidayList($filter: TableCompanyHolidayFilterInput) {
    getCompanyHolidayList(filter: $filter) {
      data {
        id
        status
        company_id
        holiday
        created_at
        updated_at
        deleted_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCompanyNoticeList = /* GraphQL */ `
  query GetCompanyNoticeList(
    $filter: TableCompanyNoticeFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCompanyNoticeList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        companyNotice {
          body
          created_at
          created_by
          deleted_at
          deleted_by
          id
          status
          updated_at
          updated_by
          url
        }
        createdUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
        updatedUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
        deletedUser {
          id
          status
          username
          password
          name
          created_at
          updated_at
          deleted_at
        }
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCancellationApplicationOption = /* GraphQL */ `
  query GetCancellationApplicationOption(
    $filter: TableCancellationApplicationOptionFilterInput
  ) {
    getCancellationApplicationOption(filter: $filter) {
      cancellationApplicationOption {
        id
        status
        company_id
        content
        url
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
      }
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      created_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      updated_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      deleted_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
    }
  }
`
export const verifyLoginIpAddress = /* GraphQL */ `
  query VerifyLoginIpAddress {
    verifyLoginIpAddress {
      company
      expire
      role
      token
      type
      user
    }
  }
`
export const getApplicationType = /* GraphQL */ `
  query GetApplicationType {
    getApplicationType {
      application_types {
        id
        status
        name
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getCompanyNotificationSettings = /* GraphQL */ `
  query GetCompanyNotificationSettings {
    getCompanyNotificationSettings {
      categories {
        category_id
        company_id
        mail_address
      }
      applications {
        application_type_id
        company_id
        mail_address
      }
    }
  }
`
export const getBulkDeliveryList = /* GraphQL */ `
  query GetBulkDeliveryList(
    $filter: TableBulkDeliveryFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryList(filter: $filter, limit: $limit, offset: $offset) {
      data {
        id
        name
        send_counts
        type
        status
        large_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        medium_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        assigned_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        send_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        send_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        answer_button_type
        send_date_setting
        send_date
        sent_status
        updated_user_group {
          id
          status
          company_id
          role_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        updated_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        created_at
        updated_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBulkDeliveryListCsv = /* GraphQL */ `
  query GetBulkDeliveryListCsv(
    $filter: TableSelected
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryListCsv(filter: $filter, limit: $limit, offset: $offset) {
      key
      companyId
    }
  }
`
export const getBulkDeliveryMessages = /* GraphQL */ `
  query GetBulkDeliveryMessages(
    $filter: TableBulkDeliveryMessagesFilterInput!
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryMessages(filter: $filter, limit: $limit, offset: $offset) {
      data {
        id
        kyk_status_div
        mst_kokyaku {
          KKY_ID
          KKY_ISP_KKY_ID
          KKY_INSERTED_DATE
          KKY_UPDATED_DATE
          KKY_DEL_FLG
          KKY_DIV
          KKY_KNR_NO
          KKY_NAME
          KKY_NAME_KANA
          KKY_LEGALPERSON_DIV
          KKY_LEGALPERSON_DIV_NAME
          KKY_BOSSNAME
          KKY_ZIPCODE
          KKY_PRF_CD
          KKY_PRF_NAME
          KKY_CTY_CD
          KKY_CTY_NAME
          KKY_TWN_CD
          KKY_TWN_NAME
          KKY_ADDR_01
          KKY_ADDR_02
          KKY_TEL_01
          KKY_TEL_02
          KKY_FAX
          KKY_EMAILADDR
          KKY_SEX_DIV
          KKY_SEX_DIV_NAME
          KKY_BIRTHDAY
          KKY_NATIONARITY
          KKY_SKG_CD
          KKY_SKG_NAME
          KKY_YKS_CD
          KKY_YKS_NAME
          KKY_INCOME
          KKY_REMARK
          KKY_STAFF_SHN_ID
          KKY_STAFF_SHN_NAME
          KKY_WORKPLACE
          KKY_WORKPLACE_ATENA
          KKY_WORKPLACE_ZIPCODE
          KKY_WORKPLACE_PRF_CD
          KKY_WORKPLACE_PRF_NAME
          KKY_WORKPLACE_CTY_CD
          KKY_WORKPLACE_CTY_NAME
          KKY_WORKPLACE_TWN_CD
          KKY_WORKPLACE_TWN_NAME
          KKY_WORKPLACE_ADDR_01
          KKY_WORKPLACE_ADDR_02
          KKY_WORKPLACE_TEL_01
          KKY_WORKPLACE_TEL_02
          KKY_WORKPLACE_FAX
          KKY_CONTACT_ATENA
          KKY_CONTACT_ZIPCODE
          KKY_CONTACT_PRF_CD
          KKY_CONTACT_PRF_NAME
          KKY_CONTACT_CTY_CD
          KKY_CONTACT_CTY_NAME
          KKY_CONTACT_TWN_CD
          KKY_CONTACT_TWN_NAME
          KKY_CONTACT_ADDR_01
          KKY_CONTACT_ADDR_02
          KKY_CONTACT_TEL_01
          KKY_CONTACT_TEL_02
          KKY_CONTACT_FAX
          KKY_EMARGENCY_ATENA
          KKY_EMARGENCY_ZIPCODE
          KKY_EMARGENCY_PRF_CD
          KKY_EMARGENCY_PRF_NAME
          KKY_EMARGENCY_CTY_CD
          KKY_EMARGENCY_CTY_NAME
          KKY_EMARGENCY_TWN_CD
          KKY_EMARGENCY_TWN_NAME
          KKY_EMARGENCY_ADDR_01
          KKY_EMARGENCY_ADDR_02
          KKY_EMARGENCY_TEL_01
          KKY_EMARGENCY_TEL_02
          KKY_EMARGENCY_FAX
        }
        mst_tateya {
          TTY_ID
          TTY_ISP_TTY_ID
          TTY_ISP_KRM_ID
          TTY_INSERTED_DATE
          TTY_UPDATED_DATE
          TTY_DEL_FLG
          TTY_KNR_NO
          TTY_NAME
          TTY_NAME_KANA
          TTY_COMPLETION_DATE
          TTY_GROUND_FLOOR_NUM
          TTY_UGGROUND_FLOOR_NUM
          TTY_TYS_CD
          TTY_TYS_NAME
          TTY_STS_CD
          TTY_STS_NAME
          TTY_TYK_CD
          TTY_TYK_NAME
          TTY_YAR_CD
          TTY_YAR_NAME
          TTY_TOTI_MENSEKI
          TTY_NOBEYUKA_MENSEKI
          TTY_ZIPCODE
          TTY_PRF_CD
          TTY_PRF_NAME
          TTY_CTY_CD
          TTY_CTY_NAME
          TTY_TWN_CD
          TTY_TWN_NAME
          TTY_ADDR_01
          TTY_ADDR_02
          TTY_TOTAL_COUNT
          TTY_REMARK
          TTY_NEAREST_TRAIN_01_RAILWAY
          TTY_NEAREST_TRAIN_01_STATION
          TTY_NEAREST_TRAIN_01_TIME
          TTY_NEAREST_TRAIN_02_RAILWAY
          TTY_NEAREST_TRAIN_02_STATION
          TTY_NEAREST_TRAIN_02_TIME
          TTY_NEAREST_TRAIN_03_RAILWAY
          TTY_NEAREST_TRAIN_03_STATION
          TTY_NEAREST_TRAIN_03_TIME
          TTY_NEAREST_BUS_01_RAILWAY
          TTY_NEAREST_BUS_01_STATION
          TTY_NEAREST_BUS_01_RIDE_TIME
          TTY_NEAREST_BUS_01_TIME
          TTY_NEAREST_BUS_02_RAILWAY
          TTY_NEAREST_BUS_02_STATION
          TTY_NEAREST_BUS_02_RIDE_TIME
          TTY_NEAREST_BUS_02_TIME
          TTY_NEAREST_BUS_03_RAILWAY
          TTY_NEAREST_BUS_03_STATION
          TTY_NEAREST_BUS_03_RIDE_TIME
          TTY_NEAREST_BUS_03_TIME
          TTY_PUBLIC_ELC_KKY_NAME
          TTY_PUBLIC_ELC_KKY_TEL
          TTY_PUBLIC_WAT_KYO_CD
          TTY_PUBLIC_WAT_KYO_NAME
          TTY_PUBLIC_WAT_KKY_NAME
          TTY_PUBLIC_WAT_KKY_TEL
          TTY_PUBLIC_DRA_KYO_CD
          TTY_PUBLIC_DRA_KYO_NAME
          TTY_PUBLIC_DRA_KKY_NAME
          TTY_PUBLIC_DRA_KKY_TEL
          TTY_PUBLIC_GUS_KKY_NAME
          TTY_PUBLIC_GUS_KKY_TEL
          TTY_PUBLIC_HOTW_KKY_NAME
          TTY_PUBLIC_HOTW_KKY_TEL
          TTY_OWN_01_KKY_ID
          TTY_OWN_01_KKY_NAME
          TTY_OWN_02_KKY_ID
          TTY_OWN_02_KKY_NAME
          TTY_OWN_03_KKY_ID
          TTY_OWN_03_KKY_NAME
          TTY_LESSOR_KKY_ID
          TTY_LESSOR_KKY_NAME
          TTY_KNR_ORG_KEIYAKU_DATE
          TTY_KNR_KEIYAKU_STR_DATE
          TTY_KNR_KEIYAKU_END_DATE
          TTY_KNR_NEWEST_KSN_DATE
          TTY_KNR_KANRIGIRE_FLG
          TTY_KNR_TANTO_SHN_ID
          TTY_KNR_TANTO_SHN_NAME
          TTY_KNR_DIV
          TTY_IKT_LEASE_KINGAKU
          TTY_IKT_LEASE_KINGAKU_TAX
          TTY_SOG_ID
          TTY_SOG_DAY
          TTY_SOG_NAME
          TTY_KNRKEITAI_DIV
          TTY_KNRKEITAI_DIV_NAME
          TTY_TNP_NAME
          TTY_GRP_NAME
          TTY_KNRPATTERN_NAME
          TTY_BSH_TANTO_SHN_NAME
          TTY_GET_TANTO_SHN_NAME
        }
        mst_heya {
          HYA_ID
          HYA_TTY_ID
          HYA_ISP_KRS_ID
          HYA_INSERTED_DATE
          HYA_UPDATED_DATE
          HYA_DEL_FLG
          HYA_ROOM_NO
          HYA_FLS_CD
          HYA_FLS_NAME
          HYA_FLS_NUM
          HYA_MDR_NUM
          HYA_MDR_CD
          HYA_MDR_NAME
          HYA_SENYU_MENSEKI
          HYA_BALCONY_MENSEKI
          HYA_TYS_CD
          HYA_TYS_NAME
          HYA_STS_CD
          HYA_STS_NAME
          HYA_DCN_CD
          HYA_DCN_NAME
          HYA_STATUS_DIV
          HYA_STATUS_DIV_NAME
          HYA_THT_CD
          HYA_THT_NAME
          HYA_BSH_START_DATE
          HYA_NYUKYOKANO_DATE
          HYA_KAIYAKU_DATE
          HYA_TAIKYO_DATE
          HYA_HOSYO_DIV
          HYA_HOSYO_DIV_NAME
          HYA_HOSYO_GYS_KKY_ID
          HYA_HOSYO_GYS_KKY_NAME
          HYA_HOSYO_REMARK
          HYA_IKT_LEASE_KINGAKU
          HYA_IKT_LEASE_KINGAKU_TAX
          HYA_INET_KOKAI_DIV
          HYA_INET_KOKAI_NAME
          HYA_NEW_BUILD_FLG
          HYA_NEW_BUILD_FLG_NAME
          HYA_PARKING_DIV
          HYA_PARKING_DIV_NAME
          HYA_PARKING_KINGAKU
          HYA_PARKING_KINGAKU_TAX
          HYA_PARKING_KYORI
          HYA_FREERENT_MN
          HYA_FREERENT_REMARK
          HYA_TEISYAKU_KEIYAKU_DIV
          HYA_TEISYAKU_KEIYAKU_NAME
          HYA_TEISYAKU_KEIYAKU_DATE
          HYA_HOKEN_DIV
          HYA_HOKEN_DIV_NAME
          HYA_HOKEN_YR
          HYA_HOKEN_KINGAKU
          HYA_KOUKOKU_PR
          HYA_KOUKOKU_PR_COMMENT
          HYA_GYS_KOUKOKU_PR
          HYA_GYS_KOUKOKU_PR_COMMENT
          HYA_KEY_PLACE
          HYA_BOX_NUMBER
          HYA_REMARK
          HYA_CYUKAI_TKM_CD
          HYA_CYUKAI_TKM_NAME
          HYA_CYUKAI_CALC_DIV
          HYA_CYUKAI_CALC_DIV_NAME
          HYA_CYUKAI_CALC_RATE
          HYA_CYUKAI_KARI_RATE
          HYA_CYUKAI_KASHI_RATE
          HYA_CYUKAI_MOTO_RATE
          HYA_CYUKAI_KYAKU_RATE
          HYA_CYUKAI_KINGAKU
          HYA_CYUKAI_KINGAKU_TAX
          HYA_JIMU_TKM_CD
          HYA_JIMU_TKM_NAME
          HYA_JIMU_CALC_DIV
          HYA_JIMU_CALC_DIV_NAME
          HYA_JIMU_CALC_RATE
          HYA_JIMU_KARI_RATE
          HYA_JIMU_KASHI_RATE
          HYA_JIMU_MOTO_RATE
          HYA_JIMU_KYAKU_RATE
          HYA_JIMU_KINGAKU
          HYA_JIMU_KINGAKU_TAX
          HYA_KOUKOKU_TKM_CD
          HYA_KOUKOKU_TKM_NAME
          HYA_KOUKOKU_CALC_DIV
          HYA_KOUKOKU_CALC_DIV_NAME
          HYA_KOUKOKU_CALC_RATE
          HYA_KOUKOKU_KARI_RATE
          HYA_KOUKOKU_KASHI_RATE
          HYA_KOUKOKU_MOTO_RATE
          HYA_KOUKOKU_KYAKU_RATE
          HYA_KOUKOKU_KINGAKU
          HYA_KOUKOKU_KINGAKU_TAX
          HYA_KOSHINRYO_MN
          HYA_KOSHINRYO_KINGAKU
          HYA_KOSHINRYO_KINGAKU_TAX
          HYA_KOSHINRYO_CALC_DIV
          HYA_KOSHINRYO_CALC_DIV_NAME
          HYA_KOSHIN_YR
          HYA_SYSTEM_DIV
          HYA_SYSTEM_DIV_NAME
        }
        knr_keiyaku {
          KYK_ID
          KYK_HYA_ID
          KYK_MSK_ID
          KYK_ISP_KYK_ID
          KYK_INSERTED_DATE
          KYK_UPDATED_DATE
          KYK_DEL_FLG
          KYK_KYS_KKY_ID
          KYK_KYS_KKY_NAME
          KYK_NKY_KKY_ID
          KYK_NKY_KKY_NAME
          KYK_NKY_ZKG_CD
          KYK_NKY_ZKG_NAME
          KYK_HSN_01_KKY_ID
          KYK_HSN_01_KKY_NAME
          KYK_HSN_01_ZKG_CD
          KYK_HSN_01_ZKG_NAME
          KYK_HSN_02_KKY_ID
          KYK_HSN_02_KKY_NAME
          KYK_HSN_02_ZKG_CD
          KYK_HSN_02_ZKG_NAME
          KYK_SDK_KKY_ID
          KYK_SDK_KKY_NAME
          KYK_GYS_KKY_ID
          KYK_GYS_KKY_NAME
          KYK_GYS_SHN_NAME
          KYK_STATUS_DIV
          KYK_STATUS_DIV_NAME
          KYK_KEIYAKU_DATE
          KYK_NYUKYO_DATE
          KYK_KEIYAKU_STR_DATE
          KYK_KEIYAKU_END_DATE
          KYK_REMARK
          KYK_FREERENT_FLG
          KYK_TINRYO_HASSEI_DATE
          KYK_KOSHIN_AUTO_FLG
          KYK_KOSHIN_YR
          KYK_KOSHINYOKOKU_DIV
          KYK_KOSHINYOKOKU_DIV_NAME
          KYK_KOSHINYOKOKU_NUM
          KYK_KOSHINRYO_MN
          KYK_KOSHINRYO_KINGAKU
          KYK_KOSHINRYO_KINGAKU_TAX
          KYK_KOSHINRYO_CALC_DIV
          KYK_KOSHINRYO_CALC_DIV_NAME
          KYK_KOSHINJIMURYO_MN
          KYK_KOSHINJIMURYO_KINGAKU
          KYK_KOSHINJIMURYO_KINGAKU_TAX
          KYK_KOSHINJIMURYO_CALC_DIV
          KYK_KOSHINJIMURYO_CALC_DIV_NAME
          KYK_KOSHINTESURYO_PER
          KYK_KOSHINTESURYO_KINGAKU
          KYK_KOSHINTESURYO_KINGAKU_TAX
          KYK_TEISYAKU_FLG
          KYK_TEISYAKU_TERM_DIV
          KYK_TEISYAKU_TERM_DIV_NAME
          KYK_TEISYAKU_TERM_YR
          KYK_TEISYAKU_TERM_MN
          KYK_TEISYAKU_TERM_DATE
          KYK_TAIKYOYOKOKU_DIV
          KYK_TAIKYOYOKOKU_DIV_NAME
          KYK_TAIKYOYOKOKU_NUM
          KYK_SEK_DIV_NAME
          KYK_SEK_LIMIT_DATE
          KYK_GTJ_SEK_TSY_KKY_ID
          KYK_GTJ_SEK_TSY_KKY_NAME
          KYK_ZIJ_SEK_TSY_KKY_ID
          KYK_ZIJ_SEK_TSY_KKY_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_01_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_01_TKM_CD
          KYK_TAIKYO_SEIKYU_01_TKM_NAME
          KYK_TAIKYO_SEIKYU_01_KINGAKU
          KYK_TAIKYO_SEIKYU_01_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_02_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_02_TKM_CD
          KYK_TAIKYO_SEIKYU_02_TKM_NAME
          KYK_TAIKYO_SEIKYU_02_KINGAKU
          KYK_TAIKYO_SEIKYU_02_KINGAKU_TAX
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV
          KYK_TAIKYO_SEIKYU_03_KINGAKU_DIV_NAME
          KYK_TAIKYO_SEIKYU_03_TKM_CD
          KYK_TAIKYO_SEIKYU_03_TKM_NAME
          KYK_TAIKYO_SEIKYU_03_KINGAKU
          KYK_TAIKYO_SEIKYU_03_KINGAKU_TAX
          KYK_HOKEN_GYS_KKY_ID
          KYK_HOKEN_GYS_KKY_NAME
          KYK_HOKEN_KEIYAKU_STR_DATE
          KYK_HOKEN_KEIYAKU_END_DATE
          KYK_HOKEN_KOSHIN_YR
          KYK_HOKEN_SYOKEN_NO
          KYK_HOKEN_KINGAKU
          KYK_HOKEN_KINGAKU_TAX
          KYK_HOSYO_GYS_KKY_ID
          KYK_HOSYO_GYS_KKY_NAME
          KYK_HOSYO_KEIYAKU_STR_DATE
          KYK_HOSYO_KEIYAKU_END_DATE
          KYK_HOSYO_KOSHIN_YR
          KYK_HOSYO_SYOKEN_NO
          KYK_HOSYO_KINGAKU
          KYK_HOSYO_KINGAKU_TAX
          KYK_KAIYAKU_TSUCHI_DATE
          KYK_KAIYAKU_YOTEI_DATE
          KYK_KAIYAKU_DATE
          KYK_KAIYAKU_TAIKYO_YOTEI_DATE
          KYK_KAIYAKU_REMARK
          KYK_KAIYAKU_TACHIAI_YOTEI_DATE
          KYK_KAIYAKU_TACHIAI_GYS_KKY_ID
          KYK_KAIYAKU_TACHIAI_GYS_KKY_NAME
          KYK_KAIYAKU_SEK_END_YR
          KYK_KAIYAKU_SEK_END_MN
          KYK_KAIYAKU_TKG_ATENA
          KYK_KAIYAKU_TKG_ZIPCODE
          KYK_KAIYAKU_TKG_PRF_CD
          KYK_KAIYAKU_TKG_PRF_NAME
          KYK_KAIYAKU_TKG_CTY_CD
          KYK_KAIYAKU_TKG_CTY_NAME
          KYK_KAIYAKU_TKG_TWN_CD
          KYK_KAIYAKU_TKG_TWN_NAME
          KYK_KAIYAKU_TKG_ADDR_01
          KYK_KAIYAKU_TKG_ADDR_02
          KYK_KAIYAKU_TKG_TEL
          KYK_KAIYAKU_TKG_TKR_CD
          KYK_KAIYAKU_TKG_TKR_NAME
          KYK_KAIYAKU_KNK_FB_FLG
          KYK_KAIYAKU_BNK_CD
          KYK_KAIYAKU_BNK_NAME
          KYK_KAIYAKU_BNB_CD
          KYK_KAIYAKU_BNB_NAME
          KYK_KAIYAKU_ADV_CD
          KYK_KAIYAKU_ADV_NAME
          KYK_KAIYAKU_MEIGI
          KYK_KAIYAKU_MEIGI_KANA
          KYK_KAIYAKU_NUMBER
          KYK_KAIYAKU_SSN_HAKKO_DATE
          KYK_KAIYAKU_HENKIN_YOTEI_DATE
          KYK_MEMBER_CD
          KYK_MEMBER_CD_2
          KYK_MEMBER_FLG
          KYK_MEMBER_FLG_2
          KYK_MEMBER_NAME
          KYK_MEMBER_NAME_2
        }
        body
        attachments {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        is_edited
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getBulkDeliveryTemplates = /* GraphQL */ `
  query GetBulkDeliveryTemplates(
    $filter: TableBulkDeliveryTemplateFilterInput
    $limit: Int
    $offset: Int
  ) {
    getBulkDeliveryTemplates(filter: $filter, limit: $limit, offset: $offset) {
      data {
        attachments {
          id
          status
          company_id
          attachment_type_id
          mime_type
          filename
          bucket
          key
          title
          convert_key
          thumbnail_key
          created_at
          updated_at
          deleted_at
        }
        id
        title
        body
        large_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        medium_category {
          id
          status
          company_id
          parent_id
          name
          display_order
          created_at
          updated_at
          deleted_at
        }
        answer_button_type
        sent_status
        updater_user {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        updated_at
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getArrangement = /* GraphQL */ `
  query GetArrangement($filter: TableArrangementFilterInput) {
    getArrangement(filter: $filter) {
      id
      external_assigned_group_by {
        id
        status
        company_id
        role_id
        name
        display_order
        created_at
        updated_at
        deleted_at
      }
      external_assigned_by {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_status
      body
      arrangement_memo_updated_user {
        attachment_id
        company_group_id
        company_id
        created_at
        deleted_at
        display_name
        id
        is_administrator
        mail_address
        name
        password
        status
        type_id
        updated_at
        username
      }
      arrangement_memo_updated_at
    }
  }
`
export const getApplicationMemos = /* GraphQL */ `
  query GetApplicationMemos($filter: TableApplicationMemoFilterInput) {
    getApplicationMemos(filter: $filter) {
      data {
        id
        memo
        updater {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getCustomerHistoryMessageList = /* GraphQL */ `
  query GetCustomerHistoryMessageList(
    $filter: TableCustomerHistoryMessageFilterInput
    $limit: Int
    $offset: Int
  ) {
    getCustomerHistoryMessageList(
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      customer_history_messages {
        customer_history_thread_id
        customer_history_message_type_id
        message
        created_at
        updated_user
        updated_user_status
      }
      paging {
        count
        rowsPerPage
        page
      }
    }
  }
`
export const getCustomerHistoryCsv = /* GraphQL */ `
  query GetCustomerHistoryCsv($filter: TableCustomerHistoryCsvFilterInput) {
    getCustomerHistoryCsv(filter: $filter) {
      key
      companyId
    }
  }
`
export const getOtherCustomerActivityMemos = /* GraphQL */ `
  query GetOtherCustomerActivityMemos(
    $filter: TableOtherCustomerActivityMemoFilterInput
  ) {
    getOtherCustomerActivityMemos(filter: $filter) {
      data {
        id
        memo
        updater {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
      }
    }
  }
`
export const getApplicationOption = /* GraphQL */ `
  query GetApplicationOption($filter: TableApplicationOptionFilterInput) {
    getApplicationOption(filter: $filter) {
      application_option {
        application_type_id
        application_url
        cancellation_body
        caution_body
        caution_url
        caution_url_text
        company_id
        complete_body
        complete_title
        complete_url
        complete_url_text
        created_at
        deleted_at
        id
        is_checked_url
        is_enabled
        status
        updated_at
      }
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      room_check_application_period {
        id
        status
        company_id
        reckoning_date_type_id
        display_period
        reception_period
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getBuildingAttachments = /* GraphQL */ `
  query GetBuildingAttachments($buildingId: Int) {
    getBuildingAttachments(buildingId: $buildingId) {
      attachments {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        title
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const getSearchConditionList = /* GraphQL */ `
  query GetSearchConditionList($screen: String!) {
    getSearchConditionList(screen: $screen) {
      data {
        id
        status
        company {
          id
          status
          name
          name_kana
          stock_signage
          zip_code
          prefecture_id
          address
          tel
          fax
          mail
          url
          business_hours
          owner_function_enabled
          regular_holiday
          emergency_name
          emergency_tel
          emergency_time
          created_at
          created_by
          updated_at
          updated_by
          deleted_at
          deleted_by
          business_hours_text
          regular_holiday_text
          emergency_time_text
          privacy_policy_url
        }
        createdBy {
          attachment_id
          company_group_id
          company_id
          created_at
          deleted_at
          display_name
          id
          is_administrator
          mail_address
          name
          password
          status
          type_id
          updated_at
          username
        }
        name
        isDefault
        screen
        condition
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`
export const getCompanyIotProviders = /* GraphQL */ `
  query GetCompanyIotProviders {
    getCompanyIotProviders {
      data {
        body
        created_at
        deleted_at
        id
        name
        option_text
        status
        title
        url
        updated_at
      }
    }
  }
`
export const getTateyaIndividualMaster = /* GraphQL */ `
  query GetTateyaIndividualMaster {
    getTateyaIndividualMaster {
      KnrTanto {
        TTY_TANTO_SHN_ID
        TTY_TANTO_SHN_NAME
      }
      KnrPattern {
        TTY_KNRPATTERN_ID
        TTY_KNRPATTERN_NAME
      }
      KnrTenpo {
        TTY_TNP_ID
        TTY_TNP_NAME
      }
    }
  }
`
