import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {
  closeModalDialog,
  handleClear,
  setLoadData,
  setSignin,
  deleteSearchConditions
} from 'actions/Actions'
import Left from './Left'
import Footer from './Footer'
import { withSnackbar } from 'notistack'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import FormModalDialog from './FormModalDialog'
import ModalDialogFooter from './ModalDialogFooter'
import { Auth, Cache, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  leftBottom: {
    backgroundColor: '#fff'
  },
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function EditScreen(props) {
  const classes = useStyles()

  const { id } = useParams()

  const [companyUser, setCompanyUser] = useState({})
  const [enableCompanyUserForm, setEnableCompanyUserForm] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [passParams, setPassParams] = useState()

  const item = sessionStorage.getItem('totono-advertiser-session')
    ? sessionStorage.getItem('totono-advertiser-session')
    : localStorage.getItem('totono-advertiser-session')
  const data = JSON.parse(item)
  const currentUserId = data && data.user_id

  const fetchCompanyUser = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    const result = await QueryHelper('getCompanyUser', { filter: params })
    if (!result.error) {
      setCompanyUser(result)
    } else {
      props.enqueueSnackbar(`利用者を取得できませんでした。`)
    }
  }

  const handleCancel = async event => {
    event.preventDefault()
    props.handleClear()
    fetchCompanyUser(id)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (Object.keys(props.parameters).length > 0) {
      if (
        id === undefined &&
        (!props.parameters['status'] || !props.parameters['company_group_id'])
      ) {
        setIsError(true)
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        return
      }
      if (!enableCompanyUserForm) {
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        return
      }
      props.setLoadData(true)
      const params = Object.assign({}, props.parameters)
      if (params.images) {
        const creds = await Auth.currentCredentials()
        const attachments = []
        for (let file of params.images) {
          if ('id' in file) {
            attachments.push(file)
            continue
          }
          const result = await fileupload(creds, 'images', file)
          attachments.push({
            attachment_type_id: 1,
            mime_type: file.type,
            filename: file.name,
            key: result.key
          })
        }
        delete params['images']
        params['attachments'] = attachments
      }
      if (params.password) {
        if (!params.password_confirm) {
          props.enqueueSnackbar('パスワード確認用を入力してください。', {
            variant: 'warning'
          })
          props.setLoadData(false)
          return
        }
        if (params.password != params.password_confirm) {
          props.enqueueSnackbar('パスワードが一致しません。', {
            variant: 'warning'
          })
          props.setLoadData(false)
          return
        }
        delete params.password_confirm
      }
      if (id !== undefined) {
        params['id'] = id
        if (props.parameters['status'] === 2) {
          setIsOpen(true)
          setPassParams(params)
          return
        }
        await putCompanyUser(params)
      } else {
        await postCompanyUser(params)
      }
      props.setLoadData(false)
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
    }
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    console.log(JSON.stringify(result))
    return result
  }

  const postCompanyUser = async params => {
    const result = await MutationHelper('createCompanyUser', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('利用者を登録できませんでした。', {
        variant: 'error'
      })
    } else {
      props.handleClear()
      props.enqueueSnackbar('利用者を登録しました。', {
        variant: 'success'
      })
      props.history.goBack()
    }
  }

  const putCompanyUser = async params => {
    props.setLoadData(true)
    const result = await MutationHelper('updateCompanyUser', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('利用者を更新できませんでした。', {
        variant: 'error'
      })
      props.setLoadData(false)
    } else {
      props.closeModalDialog()
      props.handleClear()
      var message = '利用者を更新しました。'
      if (params.status == 2) {
        message = '利用者を無効にしました。'
      }
      if (params.status == 3) {
        message = '利用者を削除しました。'
        if (currentUserId == params.id) {
          handleSignOut()
          props.enqueueSnackbar(message, {
            variant: 'success'
          })
          return
        }
      }
      props.enqueueSnackbar(message, {
        variant: 'success'
      })
      props.setLoadData(false)
      if (params.status === 3) {
        if (currentUserId != params.id) {
          props.history.push('/setting/company-user/list')
        }
      } else {
        props.history.goBack()
      }
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    props.setLoadData(false)
  }

  const handleSignOut = event => {
    setSignin(false)
    localStorage.removeItem('totono-advertiser-session')
    sessionStorage.removeItem('totono-advertiser-session')
    deleteSearchConditions()
    Cache.clear()
    props.history.push('/authorize/signin')
  }

  const deleteCompanyUser = async event => {
    event.preventDefault()
    props.closeModalDialog()
    props.handleClear()
    props.setLoadData(true)
    const params = {
      id: id,
      status: 3
    }
    await putCompanyUser(params)
    props.setLoadData(false)
  }

  const fetchData = async companyUserId => {
    setIsLoading(true)
    await Promise.all([fetchCompanyUser(companyUserId)])
    setIsLoading(false)
  }

  useEffect(() => {
    props.handleClear()
    if (id !== undefined) {
      fetchData(id)
    }
  }, [setCompanyUser])

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.leftBody}>
              <Left
                isLoading={isLoading}
                companyUser={companyUser}
                setEnableCompanyUserForm={setEnableCompanyUserForm}
                isError={isError}
              />
            </div>
            <div className={classes.leftBottom}>
              <Footer companyUser={companyUser} handleCancel={handleCancel} />
            </div>
          </div>
        </div>
      </form>
      <FormModalDialog
        open={isOpen}
        title="利用者の停止"
        description="※停止すると停止アカウントとして画面上に表示され、停止された利用者はログイン不可となりすべての機能が使用できなくなります。"
        handleSubmit={() => putCompanyUser(passParams)}
        handleClose={handleClose}
      />
      <FormModalDialog
        title="利用者の削除"
        description="※削除すると削除済みアカウントとして画面上に表示され、削除された利用者はログイン不可となり、表示されている箇所の氏名を変更、またすべての機能が使用できなくなります。"
        footer={<ModalDialogFooter />}
        postData={deleteCompanyUser}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    },
    setSignin: isSignin => {
      dispatch(setSignin(isSignin))
    },
    deleteSearchConditions: () => {
      dispatch(deleteSearchConditions())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(EditScreen)))
