import React from 'react'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import ImageIcon from '@material-ui/icons/Image'
import TheatersIcon from '@material-ui/icons/Theaters'

function FaqAttachmentIcon({ attachments, id }) {
  if (!attachments) return null
  let icons = []
  attachments.forEach(attachment => {
    if (attachment == null) {
      return
    }
    if (attachment.attachment_type_id == 1)
      icons.push(<ImageIcon key={`${id}-画像`} />)
    if (attachment.attachment_type_id == 2)
      icons.push(<TheatersIcon key={`${id}-動画`} />)
    if (attachment.attachment_type_id == 3)
      icons.push(<PictureAsPdfIcon key={`${id}-PDF`} />)
  })
  return <>{icons.length && icons.map(item => item)}</>
}
export default FaqAttachmentIcon
