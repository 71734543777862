import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Header from 'components/common/Header'
import Footer from './Footer'
import Menu from './Menu'

function Layout(props) {
  return (
    <>
      <CssBaseline />
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Header />
        <div
          style={{
            flex: 1,
            display: 'flex',
            paddingTop: '64px',
            backgroundColor: '#f5f5f5'
          }}
        >
          <div
            style={{
              boxSizing: 'border-box',
              position: 'fixed',
              width: '256px',
              height: '93%',
              overflow: 'auto',
              paddingTop: '8px',
              paddingBottom: '70px',
              backgroundColor: '#fff'
            }}
          >
            <Menu />
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              // marginLeft: '-256px',
              paddingLeft: '256px'
            }}
          >
            <div style={{ flex: 1, overflow: 'scroll' }}>{props.children}</div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
