import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import { BulkDeliveryState } from 'utils/enum'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  dateMargin: {
    '& > .MuiFormControl-marginNormal ': {
      marginTop: '2px',
      marginBottom: '2px'
    }
  }
}))

const bulletinBoardStateList = [
  {
    value: BulkDeliveryState.all.code,
    label: BulkDeliveryState.all.value
  },
  {
    value: BulkDeliveryState.reserved.code,
    label: BulkDeliveryState.reserved.value
  },
  {
    value: BulkDeliveryState.sent.code,
    label: BulkDeliveryState.sent.value
  },
  {
    value: BulkDeliveryState.draft.code,
    label: BulkDeliveryState.draft.value
  },
  {
    value: BulkDeliveryState.error.code,
    label: BulkDeliveryState.error.value
  }
]

function SearchFormContent(props) {
  const classes = useStyles()

  const {
    parentCategoryList,
    childCategoryList,
    companyUserList,
    fetchedCompanyUserList,
    searchConditions,
    formatList,
    deliveryList
  } = props

  const [deactivatedCompanyUser, setDeactivatedCompanyUser] = useState(null)

  const [selectedThreadState, setSelectedThreadState] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.thread_state' in searchConditions
      ? searchConditions['bulkDelivery.thread_state']
      : ''
  )

  const [selectedParentCategory, setSelectedParentCategory] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.parent_category' in searchConditions
      ? searchConditions['bulkDelivery.parent_category']
      : []
  )

  const [selectedChildCategory, setSelectedChildCategory] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.child_category' in searchConditions
      ? searchConditions['bulkDelivery.child_category']
      : []
  )

  const [selectChildCategoryList, setSelectChildCategoryList] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.parent_category' in searchConditions &&
      searchConditions['bulkDelivery.parent_category'] != ''
      ? childCategoryList[searchConditions['bulkDelivery.parent_category']]
      : [
          {
            label: '大カテゴリを選択してください',
            value: ''
          }
        ]
  )

  const [selectedFormat, setSelectedFormat] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.format' in searchConditions
      ? searchConditions['bulkDelivery.format']
      : []
  )

  const [selectedUpdatedCompanyUser, setSelectedUpdatedCompanyUser] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.updated_company_user' in searchConditions
      ? searchConditions['bulkDelivery.updated_company_user']
      : []
  )

  const [deliveryListName, setDeliveryListName] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.delivery_list_name' in searchConditions
      ? searchConditions['bulkDelivery.delivery_list_name']
      : ''
  )

  const [deliveryListId, setDeliveryListId] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.delivery_list_id' in searchConditions
      ? searchConditions['bulkDelivery.delivery_list_id']
      : ''
  )

  const [transmissionDateTime, setTransmissionDateTime] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.transmission_date_time' in searchConditions
      ? searchConditions['bulkDelivery.transmission_date_time']
      : null
  )

  const [lastDateAndTime, setLastDateAndTime] = useState(
    Object.keys(searchConditions).length &&
      'bulkDelivery.last_date_and_time' in searchConditions
      ? searchConditions['bulkDelivery.last_date_and_time']
      : null
  )

  const [companyUserNumberList, setCompanyUserNumberList] = useState([])
  const [distributionNameList, setDistributionNameList] = useState([])

  const handleChangeState = (event, value) => {
    setSelectedThreadState(JSON.stringify(value))
  }

  const handleChangeParentCategory = (event, value) => {
    if (value == 0) {
      setSelectChildCategoryList([])
    } else {
      setSelectChildCategoryList(childCategoryList[event.target.value])
    }
    setSelectedParentCategory(JSON.stringify(event.target.value))
    setSelectedChildCategory(0)
  }

  const handleChangeChildCategory = (event, value) => {
    setSelectedChildCategory(JSON.stringify(event.target.value))
  }

  const handleChangeSelectedUpdatedCompanyUser = event => {
    setSelectedUpdatedCompanyUser(JSON.stringify(event.target.value))
    setDeactivatedCompanyUser(null)
  }

  const handleChangeFormat = event => {
    setSelectedFormat(JSON.stringify(event.target.value))
  }

  const handleChangeDeliveryListName = (event, value) => {
    setDeliveryListName(JSON.stringify(event.target.value))
  }

  const handleChangeDeliveryListId = (event, value) => {
    setDeliveryListId(JSON.stringify(event.target.value))
  }

  const handleChangeTransmissionDateTime = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setTransmissionDateTime(date)
  }

  const handleChangeLastDateAndTime = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setLastDateAndTime(date)
  }

  const divideDeliveryList = () => {
    const companyUserNumberList = []
    const distributionList = []
    for (let item of deliveryList) {
      const companyUserNumber = {
        value: item.id,
        label: item.id
      }
      companyUserNumberList.push(companyUserNumber)
    }
    for (let item of deliveryList) {
      const distributionName = {
        value: item.name,
        label: item.name
      }
      distributionList.push(distributionName)
    }
    setCompanyUserNumberList(companyUserNumberList)
    setDistributionNameList(distributionList)
  }

  useEffect(() => {
    divideDeliveryList()
  }, [])

  useEffect(() => {
    if (fetchedCompanyUserList) {
      const assignedUser = fetchedCompanyUserList.find(
        item =>
          item.company_user.status !== 1 &&
          selectedUpdatedCompanyUser === item.company_user.id
      )
      setDeactivatedCompanyUser(
        assignedUser &&
          getFormattedNameByStatus(
            assignedUser.company_user.name,
            assignedUser.company_user.status
          )
      )
    }
  }, [fetchedCompanyUserList])

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextInput
          name="bulkDelivery.delivery_list_id"
          label="ID"
          defaultValue={
            'bulkDelivery.delivery_list_id' in searchConditions
              ? searchConditions['bulkDelivery.delivery_list_id']
              : ''
          }
          fullWidth
          variant="filled"
          value={deliveryListId}
          onChange={handleChangeDeliveryListId}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Dropdown
            name="bulkDelivery.thread_state"
            label={'ステータス'}
            onChange={handleChangeState}
            defaultValue={
              'bulkDelivery.thread_state' in searchConditions
                ? searchConditions['bulkDelivery.thread_state']
                : ''
            }
            items={bulletinBoardStateList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="bulkDelivery.delivery_list_name"
          label="配信リスト名"
          defaultValue={
            'bulkDelivery.delivery_list_name' in searchConditions
              ? searchConditions['bulkDelivery.delivery_list_name']
              : ''
          }
          value={deliveryListName}
          fullWidth
          variant="filled"
          onChange={handleChangeDeliveryListName}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Dropdown
            name="bulkDelivery.parent_category"
            label={'大カテゴリ'}
            onChange={handleChangeParentCategory}
            defaultValue={selectedParentCategory}
            items={parentCategoryList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Dropdown
            name="bulkDelivery.child_category"
            label={'中カテゴリ'}
            onChange={handleChangeChildCategory}
            defaultValue={selectedChildCategory}
            items={selectChildCategoryList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Dropdown
            name="bulkDelivery.format"
            label={'リスト形式'}
            onChange={handleChangeFormat}
            defaultValue={selectedFormat}
            items={formatList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Dropdown
            name="bulkDelivery.updated_company_user"
            label={'更新者'}
            onChange={handleChangeSelectedUpdatedCompanyUser}
            defaultValue={selectedUpdatedCompanyUser}
            items={companyUserList}
            deactivatedItem={deactivatedCompanyUser}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulkDelivery.transmission_date_time"
            name="bulkDelivery.transmission_date_time"
            label="送信日時From"
            value={transmissionDateTime}
            onChange={handleChangeTransmissionDateTime}
            error={''}
            helperText={''}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulkDelivery.last_date_and_time"
            name="bulkDelivery.last_date_and_time"
            label="送信日時To"
            value={lastDateAndTime}
            onChange={handleChangeLastDateAndTime}
            error={''}
            helperText={''}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default SearchFormContent
