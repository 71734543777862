import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import FormLayout from 'components/common/FormLayout'
import RowWithCsv from 'components/common/RowWithCsv'
import { useRole } from 'hooks/useRole'

const MAXIMUM_FILE_LENGTH = 1

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%'
  },
  card: {
    width: 120,
    height: 80
  },
  cover: {
    width: 120,
    height: 80
  },
  primaryButton: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.c_blue.main,
    marginTop: '8px',
    marginBottom: '8px'
  }
}))

function FileForm(props) {
  const classes = useStyles()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()

  const { files, setFiles } = props
  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const object = {
        mime_type: file.type,
        filename: file.name,
        body: reader.result
      }
      setFiles([object])
    }
    reader.readAsText(file)
  }

  const handleDelete = index => {
    setFiles([])
  }

  return (
    <FormLayout title="ファイルをアップロード">
      {files.length > 0 &&
        files.map(({ filename, body }, index) => (
          <RowWithCsv
            key={index}
            title={filename}
            handleDelete={e => handleDelete(index)}
            style={{ marginBottom: 10 }}
          ></RowWithCsv>
        ))}
      {files.length < MAXIMUM_FILE_LENGTH && (
        <Grid container>
          <Grid item xs={12}>
            <input
              accept="text/csv"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleChange}
              disabled={!isUnrestrictedRole}
            />
          </Grid>
          <label
            htmlFor="contained-button-file"
            className={classes.labelButton}
          >
            <Button
              fullWidth
              size="large"
              variant="outlined"
              component="span"
              startIcon={<AddIcon />}
              className={classes.primaryButton}
              disabled={!isUnrestrictedRole}
            >
              ファイルを追加
            </Button>
          </label>
        </Grid>
      )}
    </FormLayout>
  )
}

export default FileForm
