import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAdmin, setLoadData, setSignin } from 'actions/Actions'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as mutations from 'graphql/mutations'
import Amplify from 'aws-amplify'
import { withSnackbar } from 'notistack'
import { AuthIpAddress } from 'components/common/AuthIpAddress'
import { ErrorMessages } from 'utils/errorMessages'

var AWS = require('aws-sdk')
AWS.config.region = process.env.REACT_APP_AWS_REGION
var roleArn = process.env.REACT_APP_AWS_COGNITO_ROLE_ARN
var customHeaderName = process.env.REACT_APP_HEADER_WEB_IDENTITY_TOKEN

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function CcProxySignin(props) {
  const history = useHistory()
  const query = useQuery()

  const refreshToken = params => {
    API.graphql(graphqlOperation(mutations.tokenRefresh, params))
      .then(response => {
        const data = response.data.tokenRefresh
        props.setAdmin(data.is_administrator)
        AWS.config.credentials = new AWS.WebIdentityCredentials({
          RoleArn: roleArn,
          WebIdentityToken: data.token,
          User: {
            Id: data.user_id,
            CompanyId: data.company_id
          },
          DurationSeconds: 43200
        })
        AWS.config.credentials.get(async err => {
          if (err) {
            console.log(JSON.stringify(err))
          } else {
            Amplify.configure({
              API: {
                graphql_headers: async () => ({
                  [customHeaderName]: data.token
                })
              }
            })
            sessionStorage.setItem(
              'totono-advertiser-session',
              JSON.stringify(data)
            )
            props.setSignin(true)
            const result = await AuthIpAddress(history)
            switch (query.get('page')) {
              case 'contractList':
                if (result) {
                  history.push({
                    pathname: `/management/contract/list/`,
                    state: {
                      'management.contract.tenant_id': query.get('name')
                    }
                  })
                } else if (result === false) {
                  props.enqueueSnackbar(
                    ErrorMessages.FailedToAuthenticateIPAddress,
                    {
                      variant: 'error'
                    }
                  )
                  props.setLoadData(false)
                  history.push('/')
                }
                break
              case 'messageList':
                if (result) {
                  history.push({
                    pathname: `/message/list/`,
                    state: {
                      'message.resident_name': query.get('name')
                    }
                  })
                } else if (result === false) {
                  props.enqueueSnackbar(
                    ErrorMessages.FailedToAuthenticateIPAddress,
                    {
                      variant: 'error'
                    }
                  )
                  props.setLoadData(false)
                  history.push('/')
                }
                break
              default:
                props.enqueueSnackbar(`画面遷移先を特定できませんでした`, {
                  variant: 'error'
                })
                props.setLoadData(false)
                history.push('/')
                break
            }
          }
        })
      })
      .catch(() => {
        props.setLoadData(false)
        props.enqueueSnackbar(`認証エラーのためアクセスに失敗しました`, {
          variant: 'error'
        })
        history.push('/')
      })
  }

  useEffect(() => {
    props.setLoadData(true)
    if (
      !query.get('apiKey') ||
      !query.get('userId') ||
      !query.get('page') ||
      !query.get('name')
    ) {
      props.setLoadData(false)
      props.enqueueSnackbar(`パラメータが不正のためアクセスに失敗しました`, {
        variant: 'error'
      })
      history.push('/')
      return
    }

    if (
      query.get('page') !== 'contractList' &&
      query.get('page') !== 'messageList'
    ) {
      props.setLoadData(false)
      props.enqueueSnackbar(`ページが特定できないためアクセスに失敗しました`, {
        variant: 'error'
      })
      history.push('/')
      return
    }

    const params = {
      apiKey: query.get('apiKey'),
      referer: document.referrer,
      userId: query.get('userId')
    }
    API.graphql(graphqlOperation(mutations.ccProxySignin, { input: params }))
      .then(response => {
        const data = response.data.ccProxySignin
        refreshToken(data)
        props.setLoadData(false)
      })
      .catch(() => {
        props.setLoadData(false)
        props.enqueueSnackbar(`認証エラーのためアクセスに失敗しました`, {
          variant: 'error'
        })
        history.push('/')
      })
  }, [])

  return <></>
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setSignin: isSignin => {
      dispatch(setSignin(isSignin))
    },
    setAdmin: isAdmin => {
      dispatch(setAdmin(isAdmin))
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CcProxySignin))
