import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import DomainIcon from '@material-ui/icons/Domain'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import SettingsCellIcon from '@material-ui/icons/SettingsCell'
import MobileOffIcon from '@material-ui/icons/MobileOff'
import SmsIcon from '@material-ui/icons/Sms'
import Label from 'components/common/Label'
import { withSnackbar } from 'notistack'
import { QueryHelper } from 'utils/api.utils'
import Loading from 'components/common/Loading'
import { HyaTysCd } from 'utils/enum'

const useStyles = makeStyles(theme => ({
  header: {
    padding: '16px 24px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '16px 16px'
  },
  columnName: {
    marginLeft: '8px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2C3F58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#696CFF',
    fontSize: '16px',
    lineHeight: '24px'
  },
  divider: {
    marginLeft: '16px'
  }
}))

function Right(props) {
  const classes = useStyles()
  const [isLoad, setIsLoad] = useState(false)

  const { buildingId } = useParams()

  const [building, setBuilding] = useState({})
  const [threadList, setThreadList] = useState(null)
  const [enabledAppUserCount, setEnabledAppUserCount] = useState(null)
  const [disabledAppUserCount, setDisabledAppUserCount] = useState(null)
  const [dwellingUnitCount, setDwellingUnitCount] = useState(null)
  const [otherDwellingUnitCount, setOtherDwellingUnitCount] = useState(null)
  const [totalCount, setTotalCount] = useState(null)

  const fetchBuilding = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    // console.log(JSON.stringify(params));
    const result = await QueryHelper('getBuilding', { filter: params })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`建物を取得できませんでした。`)
    } else {
      setBuilding(result)
    }
  }

  async function fetchThreadList(buildingId) {
    const params = {
      thread_state_id: { eq: 1 },
      building_id: { eq: buildingId }
    }
    let result = await QueryHelper(
      'getThreadListBySearchCondition',
      {
        filter: params
      },
      false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('メッセージ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setThreadList(result.data)
    }
  }

  const fetchContractListByDisabledAppUser = async buildingId => {
    let disabledAppUserTotalCount = 0
    const params = {
      building_id: {
        eq: buildingId
      },
      is_use_app: {
        eq: false
      },
      kyk_status_div: {
        eq: [1, 2, 3]
      }
    }
    const result = await QueryHelper('getContractList2', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`入居者一覧を取得できませんでした。`)
    } else {
      if (result.paging) {
        disabledAppUserTotalCount += result.paging.count
      }
    }

    setDisabledAppUserCount(disabledAppUserTotalCount)
  }

  const fetchContractListByEnabledAppUser = async buildingId => {
    const params = {
      building_id: {
        eq: buildingId
      },
      is_use_app: {
        eq: true
      },
      kyk_status_div: {
        eq: [1, 2, 3]
      }
    }
    const result = await QueryHelper('getContractList2', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`入居者一覧を取得できませんでした。`)
    } else {
      if (result.paging) {
        setEnabledAppUserCount(result.paging.count)
      }
    }
    for (let i of result.data) {
      console.log(i.mst_kokyaku_kyk_nky.KKY_NAME)
    }
  }

  const fetchRoomList = async buildingId => {
    const params = {
      building_id: {
        eq: buildingId
      }
    }
    const result = await QueryHelper('getRoomList2', { filter: params })
    if (!result.error) {
      let dwellingUnitCnt = 0
      let otherDwellingUnitCnt = 0
      for (let i of result.data) {
        if (i.mst_heya) {
          if (
            i.mst_heya.HYA_TYS_CD === HyaTysCd.house.code ||
            i.mst_heya.HYA_TYS_CD === HyaTysCd.financeCorporation.code
          ) {
            dwellingUnitCnt++
          } else {
            otherDwellingUnitCnt++
          }
        }
      }
      setDwellingUnitCount(dwellingUnitCnt)
      setOtherDwellingUnitCount(otherDwellingUnitCnt)
      setTotalCount(dwellingUnitCnt + otherDwellingUnitCnt)
    } else {
      props.enqueueSnackbar(`部屋一覧を取得できませんでした。`)
    }
  }

  useEffect(() => {
    if (buildingId !== undefined) {
      fetchData(buildingId)
    }
  }, [setBuilding])

  const fetchData = async buildingId => {
    setIsLoad(true)
    await Promise.all([
      fetchBuilding(buildingId),
      fetchThreadList(buildingId),
      fetchContractListByDisabledAppUser(buildingId),
      fetchContractListByEnabledAppUser(buildingId),
      fetchRoomList(buildingId)
    ])
    setIsLoad(false)
  }

  return (
    <>
      <div className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.title}>物件の状態</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Label color="blue" text="管理中" />
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <DomainIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>総戸数</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad && totalCount === null ? (
                <Loading isLoading={isLoad} />
              ) : (
                <>{totalCount !== null ? totalCount : ''}</>
              )}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography className={classes.columnName}>(住戸)</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad && dwellingUnitCount === null ? (
                <Loading isLoading={isLoad} />
              ) : (
                <>{dwellingUnitCount !== null ? dwellingUnitCount : ''}</>
              )}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography className={classes.columnName}>(その他)</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad && otherDwellingUnitCount === null ? (
                <Loading isLoading={isLoad} />
              ) : (
                <>
                  {otherDwellingUnitCount !== null
                    ? otherDwellingUnitCount
                    : ''}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <AccessibilityNewIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>入居人数</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad || !building ? (
                <Loading isLoading={isLoad || !building} />
              ) : (
                <>
                  {'building' in building && building.building
                    ? building.building.contract_cnt
                    : ''}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <SettingsCellIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>アプリ使用者</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad || enabledAppUserCount === null ? (
                <Loading isLoading={isLoad || enabledAppUserCount === null} />
              ) : (
                <>{enabledAppUserCount !== null ? enabledAppUserCount : ''}</>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <MobileOffIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>アプリ無効者</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad || disabledAppUserCount === null ? (
                <Loading isLoading={isLoad || disabledAppUserCount === null} />
              ) : (
                <>{disabledAppUserCount !== null ? disabledAppUserCount : ''}</>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <SmsIcon className={classes.icon} />
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.columnName}>
              未対応メッセージ
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Typography className={classes.columnValue}>
              {isLoad || !threadList ? (
                <Loading isLoading={isLoad || !threadList} />
              ) : (
                <>
                  {threadList && threadList.paging
                    ? threadList.paging.count
                    : 0}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
    </>
  )
}

export default withSnackbar(Right)
