import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import Pagination from 'components/common/Pagination'
import RadioButtons from 'components/common/RadioButtons'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  changeSearchCondition,
  closeSearchModalDialog,
  handleClear
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import { PackageEnabled } from 'utils/enum'
import List from './List'
import Loading from 'components/common/Loading'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '14px'
    }
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  }
}))

const buttonsData = [
  { value: PackageEnabled.all.code, label: PackageEnabled.all.value },
  { value: PackageEnabled.enabled.code, label: PackageEnabled.enabled.value },
  { value: PackageEnabled.disabled.code, label: PackageEnabled.disabled.value }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pageId } = useParams()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const [searchConditions, setSearchConditions] = useState(
    location.state !== undefined ? location.state : {}
  )
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const handleChangePage = (event, newPage) => {
    searchConditions['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = newPage => {
    searchConditions['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  const [packageList, setPackageList] = useState(initialState)
  const [selectedStatus, setSelectedStatus] = useState(
    searchConditions['package.package_status']
      ? searchConditions['package.package_status']
      : PackageEnabled.all.code
  )
  const handleSelectedButton = selectedStatus => {
    if (selectedStatus) {
      searchConditions['package.package_status'] = selectedStatus
    } else {
      delete searchConditions['package.package_status']
    }
    searchConditions['pageNumber'] = 1
    setSelectedStatus(selectedStatus, setPageNumber(1))
  }
  const [isLoad, setIsLoad] = useState(false)

  async function fetchPackageList(status, offset, limit) {
    const params = {}
    Object.keys(searchConditions).forEach(function(key) {
      if (searchConditions[key]) {
        switch (key) {
          case 'package.package_status': {
            if (searchConditions[key] != 0) {
              setSelectedStatus(Number(searchConditions[key]))
              if (searchConditions[key] == 1) {
                params.status = { eq: 1 }
              } else {
                params.status = { eq: 2 }
              }
            }
            break
          }
          default: {
          }
        }
      }
    })

    let result = await QueryHelper(
      'getPackageList',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('入居者サービスを取得できませんでした', {
        variant: 'error'
      })
    } else {
      setPackageList(result)
    }
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `/management/package/list/${pageNumber}`,
      state: searchConditions
    })
    fetchData(offset)
  }, [
    pageNumber,
    setPageNumber,
    selectedStatus,
    setPackageList,
    searchConditions
  ])

  const fetchData = async offset => {
    setIsLoad(true)
    await Promise.all([fetchPackageList(selectedStatus, offset, PAGE_LIMIT)])
    setIsLoad(false)
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs className={classes.tableTips}>
          {packageList && !isLoad ? (
            <Button
              onClick={e =>
                history.push({
                  pathname: `/management/package/new`,
                  state: searchConditions
                })
              }
              variant="contained"
              startIcon={<AddIcon />}
              disableElevation
              className={classes.primaryButton}
              disabled={!isUnrestrictedRole}
            >
              サービスを追加
            </Button>
          ) : (
            <Loading isLoading={isLoad} />
          )}
        </Grid>
        <Grid item xs={6} className={classes.tableTips}>
          <RadioButtons
            current={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={packageList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {packageList && !isLoad ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              packageList={packageList.data}
              conditions={searchConditions}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoad} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={packageList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
