import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { CancellationReason, FinancialInstitutionType } from 'utils/enum'
import { fromCode } from 'utils/enum.utils'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import { closeModalDialog, handleClear, openModalDialog } from 'actions/Actions'
import FormModalDialog from './FormModalDialog'
import CancellationDateFormContent from './CancellationDateFormContent'
import CancellationInfoFormContent from './CancellationInfoFormContent'
import FinancialInstitutionFormContent from './FinancialInstitutionFormContent'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  divider: {
    width: '100%',
    height: '24px',
    backgroundColor: theme.palette.c_light_gray.main
  },
  textRight: {
    textAlign: 'right'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function CellWithDepartureDesiredDate({ json }) {
  if (
    json.cancellation &&
    json.cancellation.cancellationDates &&
    json.cancellation.cancellationDates.departureDesiredDate
  ) {
    return <>{json.cancellation.cancellationDates.departureDesiredDate}</>
  }
  return <></>
}

function CellWithDepartureFixedDate({ json }) {
  if (
    json.cancellation &&
    json.cancellation.cancellationDates &&
    json.cancellation.cancellationDates.departureFixedDate
  ) {
    return <>{json.cancellation.cancellationDates.departureFixedDate}</>
  }
  return <></>
}

function CellWithCancellationDesiredDate({ json }) {
  if (
    json.cancellation &&
    json.cancellation.cancellationDates &&
    json.cancellation.cancellationDates.cancellationDesiredDate
  ) {
    return <>{json.cancellation.cancellationDates.cancellationDesiredDate}</>
  }
  return <></>
}

function CellWithCancellationFixedDate({ json }) {
  if (
    json.cancellation &&
    json.cancellation.cancellationDates &&
    json.cancellation.cancellationDates.cancellationFixedDate
  ) {
    return <>{json.cancellation.cancellationDates.cancellationFixedDate}</>
  }
  return <></>
}

function CellWithFinancialInstitutionType({ typeId }) {
  const financialInstitutionType = fromCode(FinancialInstitutionType, typeId)
  return <>{financialInstitutionType.value}</>
}

function CellWithCancellationReason({ reasonId }) {
  const cancellationReason = fromCode(CancellationReason, reasonId)
  return <>{cancellationReason.value}</>
}

function ResidentCancellationApplicationDetail(props) {
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const application = props.application ? props.application : {}
  const classes = useStyles()
  const content =
    application && application.content ? parseJson(application.content) : {}
  const [dialogPattern, setDialogPattern] = React.useState(0)

  const handleClickCancellationDate = event => {
    setDialogPattern(1)
    props.openModalDialog()
  }

  const handleClickCancellationInfo = event => {
    setDialogPattern(2)
    props.openModalDialog()
  }

  const handleClickFinancialInstitution = event => {
    setDialogPattern(3)
    props.openModalDialog()
  }

  return (
    <>
      <TableContainer component="Paper">
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.root} colSpan="3">
                解約に関わる日付
              </TableCell>
              <TableCell className={classes.textRight} colSpan="1">
                <Button
                  onClick={event => handleClickCancellationDate(event)}
                  variant="contained"
                  disableElevation
                  className={classes.primaryButton}
                  disabled={isRestrictedRole}
                >
                  編集
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.isLoading && application ? (
              <>
                <TableRow>
                  <TableCell className={classes.columnName2}>
                    解約希望日
                  </TableCell>
                  <TableCell className={classes.columnValue2}>
                    <CellWithCancellationDesiredDate json={content} />
                  </TableCell>
                  <TableCell className={classes.columnName2}>
                    退去希望日
                  </TableCell>
                  <TableCell className={classes.columnValue2}>
                    <CellWithDepartureDesiredDate json={content} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.columnName2}>解約日</TableCell>
                  <TableCell className={classes.columnValue2}>
                    <CellWithCancellationFixedDate json={content} />
                  </TableCell>
                  <TableCell className={classes.columnName2}>退去日</TableCell>
                  <TableCell className={classes.columnValue2}>
                    <CellWithDepartureFixedDate json={content} />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell className={classes.columnName2}>
                  <Loading isLoading={props.isLoading} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className={classes.divider} />
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.root} colSpan="3">
                解約情報
              </TableCell>
              <TableCell className={classes.textRight} colSpan="1">
                <Button
                  onClick={event => handleClickCancellationInfo(event)}
                  variant="contained"
                  disableElevation
                  className={classes.primaryButton}
                  disabled={isRestrictedRole}
                >
                  編集
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.isLoading && application ? (
              <>
                <TableRow>
                  <TableCell className={classes.columnName2} colSpan="1">
                    解約理由
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="1">
                    {content &&
                    content.cancellationInfo &&
                    content.cancellationInfo.reasonId ? (
                      <CellWithCancellationReason
                        reasonId={content.cancellationInfo.reasonId}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell className={classes.columnName2} colSpan="1">
                    解約後連絡先
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="1">
                    {content &&
                    content.cancellationInfo &&
                    content.cancellationInfo.tel
                      ? content.cancellationInfo.tel
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.columnName2} colSpan="1">
                    転移先住所
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="3">
                    {content &&
                    content.cancellationInfo &&
                    content.cancellationInfo.address
                      ? content.cancellationInfo.address
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.columnName2} colSpan="1">
                    その他
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="3">
                    {content &&
                    content.cancellationInfo &&
                    content.cancellationInfo.other
                      ? content.cancellationInfo.other
                      : null}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell className={classes.columnName2}>
                  <Loading isLoading={props.isLoading} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className={classes.divider} />
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.root} colSpan="3">
                口座情報
              </TableCell>
              <TableCell className={classes.textRight} colSpan="1">
                <Button
                  onClick={event => handleClickFinancialInstitution(event)}
                  variant="contained"
                  disableElevation
                  className={classes.primaryButton}
                  disabled={isRestrictedRole}
                >
                  編集
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.isLoading && application ? (
              <>
                <TableRow>
                  <TableCell className={classes.columnName2} colSpan="1">
                    銀行名
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="1">
                    {content &&
                    'financialInstitution' in content &&
                    'financialInstitutionName' in content.financialInstitution
                      ? content.financialInstitution.financialInstitutionName
                      : null}
                  </TableCell>
                  <TableCell className={classes.columnName2} colSpan="1">
                    支店名
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="1">
                    {content &&
                    'financialInstitution' in content &&
                    'storeName' in content.financialInstitution
                      ? content.financialInstitution.storeName
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.columnName2} colSpan="1">
                    口座
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="1">
                    {content &&
                    'financialInstitution' in content &&
                    'typeId' in content.financialInstitution ? (
                      <CellWithFinancialInstitutionType
                        typeId={content.financialInstitution.typeId}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell className={classes.columnName2} colSpan="1">
                    番号
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="1">
                    {content &&
                    'financialInstitution' in content &&
                    'number' in content.financialInstitution
                      ? content.financialInstitution.number
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.columnName2} colSpan="1">
                    口座名義
                  </TableCell>
                  <TableCell className={classes.columnValue2} colSpan="3">
                    {content &&
                    'financialInstitution' in content &&
                    'accountName' in content.financialInstitution
                      ? content.financialInstitution.accountName
                      : null}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell className={classes.columnName2}>
                  <Loading isLoading={props.isLoading} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {dialogPattern === 1 && (
        <FormModalDialog
          title={'解約に関わる日付の編集'}
          body={
            <CancellationDateFormContent
              application={props.application}
              fetchResidentCancellationApplication={
                props.fetchResidentCancellationApplication
              }
            />
          }
        />
      )}
      {dialogPattern === 2 && (
        <FormModalDialog
          title={'解約情報'}
          body={
            <CancellationInfoFormContent
              application={props.application}
              fetchResidentCancellationApplication={
                props.fetchResidentCancellationApplication
              }
            />
          }
        />
      )}
      {dialogPattern === 3 && (
        <FormModalDialog
          title={'口座情報'}
          body={
            <FinancialInstitutionFormContent
              application={props.application}
              fetchResidentCancellationApplication={
                props.fetchResidentCancellationApplication
              }
            />
          }
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    openModalDialog: () => {
      dispatch(openModalDialog())
    },
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ResidentCancellationApplicationDetail))
