import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import { QueryHelper } from 'utils/api.utils'
import { CompanyUserStatusEnum } from 'utils/enum'

function CompanyGroupForm(props) {
  const { searchCondition, roleItemList } = props

  const [companyGroupList, setCompanyGroupList] = useState([])
  const companyUserStatus = [
    { value: CompanyUserStatusEnum.all.code, label: '' },
    {
      value: CompanyUserStatusEnum.active.code,
      label: CompanyUserStatusEnum.active.value
    },
    {
      value: CompanyUserStatusEnum.inactive.code,
      label: CompanyUserStatusEnum.inactive.value
    }
  ]

  useEffect(() => {
    fetchCompanyGroupList()
  }, [setCompanyGroupList])

  async function fetchCompanyGroupList() {
    var filter = {}
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        filter: filter
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('所属グループ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const l = [{ value: '', label: '' }]
      result.data.forEach(item => {
        l.push({ value: item.id, label: item.name })
      })
      setCompanyGroupList(l)
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl required fullWidth>
          <Dropdown
            name="status"
            label="状態"
            defaultValue={
              'status' in searchCondition ? searchCondition['status'] : 0
            }
            items={companyUserStatus}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="setting.company_user.name"
          label="氏名"
          defaultValue={
            'setting.company_user.name' in searchCondition
              ? searchCondition['setting.company_user.name']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="setting.company_user.display_name"
          label="表示名"
          defaultValue={
            'setting.company_user.display_name' in searchCondition
              ? searchCondition['setting.company_user.display_name']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="setting.company_user.username"
          label="メールアドレス"
          defaultValue={
            'setting.company_user.username' in searchCondition
              ? searchCondition['setting.company_user.username']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl required fullWidth>
          <Dropdown
            name="setting.company_user.company_group_id"
            label="所属グループ"
            defaultValue={
              'setting.company_user.company_group_id' in searchCondition
                ? searchCondition['setting.company_user.company_group_id']
                : 0
            }
            items={companyGroupList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl required fullWidth>
          <Dropdown
            name="setting.company_user.role_id"
            label="グループ権限"
            defaultValue={
              'setting.company_user.role_id' in searchCondition
                ? searchCondition['setting.company_user.role_id']
                : 0
            }
            items={roleItemList}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default withSnackbar(CompanyGroupForm)
