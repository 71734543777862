import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Divider from '@material-ui/core/Divider'
import MessageTemplateDetail from './MessageTemplateDetail'

const useStyles = makeStyles(theme => ({
  section: {
    width: '250px',
    height: '54px',
    padding: '15px 0px 15px 20px'
  },
  wrapperTitle: {
    width: '180px',
    padding: '15px 0px 15px 0px'
  },
  title: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  listItem: {
    paddingLeft: '0px',
    height: '70px'
  },
  listItemText: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    '& > .MuiListItemText-primary': {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '13px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      maxHeight: '3em'
    }
  }
}))

function createMessageTemplateDataList(messageTemplateList, mediumCategoryId) {
  const messageTemplateDataList = []
  for (let data of messageTemplateList) {
    if (mediumCategoryId === data.message_template.category_id) {
      messageTemplateDataList.push(data)
    }
  }
  return messageTemplateDataList
}

function MessageTemplateList(props) {
  const classes = useStyles()
  const mediumCategory =
    props && props.mediumCategory ? props.mediumCategory : null
  const messageTemplateDataList =
    props &&
    props.messageTemplateList &&
    props.messageTemplateList.length > 0 &&
    mediumCategory
      ? createMessageTemplateDataList(
          props.messageTemplateList,
          mediumCategory.id
        )
      : null
  const [messageTemplate, setMessageTemplate] = useState(null)

  return (
    <>
      <div className={classes.section}>
        <Grid container spacing={4}>
          {mediumCategory && (
            <>
              {messageTemplate ? (
                <>
                  <IconButton
                    aria-label="new"
                    onClick={() => setMessageTemplate(null)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Grid alignItems="center" className={classes.wrapperTitle}>
                    <Typography className={classes.title}>
                      {messageTemplate.title}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <IconButton
                    aria-label="new"
                    onClick={() => props.handleSelectedMediumCategory(null)}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Grid alignItems="center" className={classes.wrapperTitle}>
                    <Typography className={classes.title}>
                      {mediumCategory.name}
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </div>
      {messageTemplate ? (
        <MessageTemplateDetail
          messageTemplate={messageTemplate}
          joinBody={props.joinBody}
        />
      ) : (
        <>
          {messageTemplateDataList &&
            messageTemplateDataList.length > 0 &&
            messageTemplateDataList.map(data => (
              <>
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => setMessageTemplate(data.message_template)}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={data.message_template.title}
                  />
                  <ListItemIcon>
                    <ArrowForwardIosIcon />
                  </ListItemIcon>
                </ListItem>
                <Divider />
              </>
            ))}
        </>
      )}
    </>
  )
}

export default withSnackbar(withRouter(MessageTemplateList))
