import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  textRight: {
    textAlign: 'right'
  },
  center: {
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  }
}))

function FormFooter(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  return (
    <>
      <Grid container spacing={4} className={classes.center}>
        <Grid item xs={4} sm={4} spacing={2} className={classes.textRight}>
          <Button
            onClick={props.onClose}
            color="primary"
            variant="contained"
            className={classes.secondaryButton}
            disabled={isRestrictedRole}
          >
            キャンセル
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} spacing={2}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            className={classes.primaryButton}
            disabled={isRestrictedRole}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default FormFooter
