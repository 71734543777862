import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { handleClear, handleChange } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import Dropdown from 'components/common/Form/Dropdown'
import { QueryHelper } from 'utils/api.utils'
import { MANAGEMENT_LIST } from 'utils/const'

function FormContent(props) {
  const [data, setData] = useState({})
  const { building } = props

  useEffect(() => {
    props.handleClear()
    fetchBuilding(building.building.id)
  }, [setData])

  async function fetchBuilding(id) {
    const params = {
      id: {
        eq: id
      }
    }
    let result = await QueryHelper('getBuilding', { filter: params }, false)
    if (result.error) {
      props.enqueueSnackbar('物件情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setData(result)
    }
  }

  const handleChange = event => {
    props.handleChange({
      [event.target.name]: event.target.value
    })
  }

  let is_management = 0
  if (0 !== Object.keys(data).length) {
    is_management = data.building.is_management ? 1 : 0
  } else if (0 !== Object.keys(building).length) {
    is_management = building.building.is_management ? 1 : 0
  }

  return (
    <Grid container spacing={3}>
      {0 !== Object.keys(data).length && (
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <Dropdown
              required
              name="is_management"
              label="アプリ利用"
              defaultValue={is_management}
              onChange={handleChange}
              items={MANAGEMENT_LIST}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FormContent))
