import React, { useEffect, useState } from 'react'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { useHistory, useLocation } from 'react-router-dom'
import { closeModalDialog, openModalDialog } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import { Button, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import FormModalDialog from './FormModalDialog'
import FormFooter from './FormFooter'
import Dropdown from 'components/common/Form/Dropdown'
import FormControl from '@material-ui/core/FormControl'
import { ThreadState } from 'utils/enum'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  primaryButton: {
    marginLeft: '16px',
    marginRight: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.text.disabled
    }
  },
  formControl: {
    width: '100%'
  },
  button: {
    marginLeft: '16px',
    color: '#fff'
  }
}))

function Body(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [submitting, setSubmitting] = useState(false)
  const [parentCategoryList, setParentCategoryList] = useState(null)
  const [selectedParentCategory, setSelectedParentCategory] = useState(null)
  const [validateParentCategory, setValidateParentCategory] = useState({})
  const [childCategoryList, setChildCategoryList] = useState(null)
  const [selectChildCategoryList, setSelectChildCategoryList] = useState(null)
  const [selectedChildCategory, setSelectedChildCategory] = useState(null)
  const [validateChildCategory, setValidateChildCategory] = useState({})
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const handleCreateMessage = event => {
    props.openModalDialog()
  }

  const handleChangeParentCategory = event => {
    console.log(event.target.value)
    if (event.target.value === 0) {
      setSelectChildCategoryList([])
    } else {
      setSelectChildCategoryList(childCategoryList[event.target.value])
    }
    setSelectedParentCategory(event.target.value)
  }

  const handleChangeChildCategory = event => {
    setSelectedChildCategory(event.target.value)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await Promise.all([fetchCategoryList()])
  }

  const createThread = async event => {
    event.preventDefault()
    if (
      !window.confirm(
        'スレッドを作成すると削除が出来ません。\n※入居者アプリにもスレッドが表示されます。'
      )
    ) {
      return
    }
    if (validateForm()) {
      return
    }
    if (submitting) {
      return
    }
    setSubmitting(true)
    let contractId = null
    if (location.state && location.state.id) {
      contractId = location.state && location.state.id
    } else {
      const path = window.location.pathname.split('/')
      contractId = path[path.length - 1]
    }

    const contract = await QueryHelper('getContract', {
      filter: { id: { eq: contractId } }
    })
    if (contract.error) {
      console.log(JSON.stringify(contract.error))
      props.enqueueSnackbar('入居者情報が取得できませんでした', {
        variant: 'error'
      })
      return
    }

    const params = {}
    params.status = 1
    params.category_id = selectedChildCategory
    params.contract_id = contract.contract.id
    params.customer_id = contract.customer.id
    params.building_id = contract.contract.building_id
    params.room_id = contract.contract.room_id
    params.thread_state_id = ThreadState.open.code
    params.is_reply = true
    const result = await MutationHelper('createThread', { input: params })
    if (result.error) {
      console.log(JSON.stringify(result.error))
      props.enqueueSnackbar('メッセージ作成に失敗しました', {
        variant: 'error'
      })
      setSubmitting(false)
      return
    }
    setSubmitting(false)
    console.log(result.id)
    history.push(`/message/detail/${result.id}`)
  }

  function validateForm() {
    let isError = false
    if (selectedParentCategory == null || selectedParentCategory == 0) {
      isError = true
      setValidateParentCategory({ hasError: true, message: '必須です' })
    } else {
      setValidateParentCategory({})
    }
    if (selectedChildCategory == null || selectedChildCategory == 0) {
      isError = true
      setValidateChildCategory({ hasError: true, message: '必須です' })
    } else {
      setValidateChildCategory({})
    }
    return isError
  }

  const fetchCategoryList = async () => {
    const result = await QueryHelper('getLargeCategoryAndMediumCategory')
    if (!result.error) {
      const parents = [{ value: 0, label: '' }]
      const children = { 0: [] }
      for (let row1 of result) {
        parents.push({
          value: row1.large_categories.id,
          label: row1.large_categories.name
        })
        const elements = [{ value: 0, label: '' }]
        for (let row2 of row1.medium_categories) {
          elements.push({
            value: row2.id,
            label: row2.name
          })
        }
        children[row1.large_categories.id] = elements
      }
      setParentCategoryList(parents)
      setChildCategoryList(children)
    } else {
      props.enqueueSnackbar(`カテゴリーを取得できませんでした。`)
    }
  }

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={() => history.goBack()}
      >
        {/*<IconButton*/}
        {/*  aria-label="back"*/}
        {/*  className={classes.button}*/}
        {/*  onClick={e =>*/}
        {/*    history.push({*/}
        {/*      pathname: `/management/contract/list/${location.state !== undefined && "pageNumber" in location.state ? location.state.pageNumber : 1}`,*/}
        {/*      state: location.state,*/}
        {/*    })*/}
        {/*  }*/}
        {/*>*/}
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>{props.title}</Typography>
      <Button
        variant="contained"
        className={classes.primaryButton}
        onClick={handleCreateMessage}
        disabled={isRestrictedRole}
      >
        メッセージ作成
      </Button>
      <FormModalDialog
        title={'スレッドを作成'}
        isOpen={props.isOpen}
        content={
          <Grid container spacing={2} xs>
            <Grid item xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <Dropdown
                  required={true}
                  name="parent_category"
                  label={'大カテゴリ'}
                  onChange={handleChangeParentCategory}
                  defaultValue={selectedParentCategory}
                  items={parentCategoryList}
                  hasError={validateParentCategory.hasError}
                  errorMessage={validateParentCategory.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <Dropdown
                  required={true}
                  name="child_category"
                  label={'中カテゴリ'}
                  onChange={handleChangeChildCategory}
                  defaultValue={selectedChildCategory}
                  items={selectChildCategoryList}
                  hasError={validateChildCategory.hasError}
                  errorMessage={validateChildCategory.message}
                />
              </FormControl>
            </Grid>
          </Grid>
        }
        footer={<FormFooter />}
        postData={createThread}
      ></FormModalDialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalDialog: () => {
      dispatch(openModalDialog())
    },
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Body))
