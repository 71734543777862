import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { TableContainer } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { AppUsage, ContractKykStatusDiv } from 'utils/enum'
import Label from 'components/common/Label'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { formatYYYYMMDD } from 'utils/date.utils'
import UseUseLabel from 'components/common/UseUseLabel'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    maxHeight: '75vh',
    overflow: 'auto'
  },

  tableRowTypeBlue: {
    backgroundColor: theme.overrides.MuiTableCell.stickyHeader.backgroundColor
  },

  tableLeftInteraval: {
    paddingLeft: '30px'
  },

  tableVertical: {
    verticalAlign: 'top'
  },

  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },

  name: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },

  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },

  tableRowTypeDefault: {
    backgroundColor: theme.palette.c_background.gray
  }
}))

function List(props) {
  const classes = useStyles()
  const {
    customerInformationList,
    changeTargetIdList,
    targetIdList,
    invalidTargetIdList
  } = props
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )
  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const handleSort = columnId => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  function ContractStatusLabel({ contractStatus }) {
    if (contractStatus == ContractKykStatusDiv.application.code) {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.application.value} />
      )
    } else if (contractStatus == ContractKykStatusDiv.contract.code) {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
      )
    } else if (contractStatus == ContractKykStatusDiv.cancellation.code) {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
      )
    } else if (contractStatus == ContractKykStatusDiv.completed.code) {
      return (
        <Label color={'gray'} text={ContractKykStatusDiv.completed.value} />
      )
    } else if (contractStatus == ContractKykStatusDiv.payOff.code) {
      return <Label color={'gray'} text={ContractKykStatusDiv.payOff.value} />
    } else {
      return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
    }
  }

  // データがまだないのでidを空にしています。
  const columns = [
    { id: 'send', label: '送信', minWidth: 100 },
    { id: 'app', label: 'アプリ', minWidth: 100 },
    { id: 'situation', label: '状態', minWidth: 110 },
    { id: 'kyk_id', label: '契約ID', minWidth: 120, hasSort: true },
    { id: 'kky_knr_no', label: '顧客番号', minWidth: 120, hasSort: true },
    { id: 'residentNumber', label: '入居者名', minWidth: 170 },
    { id: 'contractorName', label: '契約者名', minWidth: 170 },
    { id: 'propertyName', label: '物件名', minWidth: 170 },
    { id: 'contractDate', label: '契約日', sabLabel: '(成約日)', minWidth: 90 },
    {
      id: 'registered_at',
      label: 'アプリ利用開始日',
      minWidth: 180,
      hasSort: true
    },
    {
      id: 'last_login_at',
      label: '最終ログイン日',
      minWidth: 180,
      hasSort: true
    }
  ]

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.tableRowTypeBlue}>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width
                  }}
                  sortDirection={
                    column.hasSort && orderBy === column.id ? order : false
                  }
                >
                  {column.hasSort ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      className={classes.tableSort}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <>
                      {column.label}
                      <br />
                      {column.sabLabel}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customerInformationList.length
              ? customerInformationList.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={
                        invalidTargetIdList.indexOf(row.knr_keiyaku.KYK_ID) ==
                        -1
                          ? classes.tableRowTypeDefault
                          : null
                      }
                    >
                      <TableCell>
                        <Checkbox
                          onClick={() =>
                            changeTargetIdList(row.knr_keiyaku.KYK_ID)
                          }
                          checked={
                            targetIdList.indexOf(row.knr_keiyaku.KYK_ID) != -1
                          }
                          disabled={
                            invalidTargetIdList.indexOf(
                              row.knr_keiyaku.KYK_ID
                            ) == -1
                          }
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          color="default"
                        />
                      </TableCell>
                      <TableCell>
                        <UseUseLabel
                          is_usage={row.customer.is_usage}
                          is_management={row.building.is_management}
                          app_user_id={row.customer.app_user_id}
                          kykStatusDiv={row.knr_keiyaku.KYK_STATUS_DIV}
                        />
                      </TableCell>
                      <TableCell>
                        <ContractStatusLabel
                          contractStatus={row.contract.kyk_status_div}
                        />
                      </TableCell>
                      <TableCell>{row.knr_keiyaku.KYK_ID}</TableCell>
                      <TableCell>
                        {row.mst_kokyaku_kyk_kys.KKY_KNR_NO}
                      </TableCell>
                      <TableCell>
                        <Grid container direction="column">
                          <Grid item className={classes.name}>
                            <span>{row.mst_kokyaku_kyk_nky.KKY_NAME}</span>
                          </Grid>
                          <Grid item className={classes.nameKana}>
                            <span>{row.mst_kokyaku_kyk_nky.KKY_NAME_KANA}</span>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container direction="column">
                          <Grid item className={classes.name}>
                            <span>{row.mst_kokyaku_kyk_kys.KKY_NAME}</span>
                          </Grid>
                          <Grid item className={classes.nameKana}>
                            <span>{row.mst_kokyaku_kyk_kys.KKY_NAME_KANA}</span>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid>{row.mst_tateya.TTY_NAME}</Grid>
                        <Grid>{row.mst_heya.HYA_ROOM_NO}</Grid>
                      </TableCell>
                      <TableCell>
                        {row.knr_keiyaku && row.knr_keiyaku.KYK_KEIYAKU_DATE
                          ? formatYYYYMMDD(
                              new Date(row.knr_keiyaku.KYK_KEIYAKU_DATE)
                            )
                          : null}
                      </TableCell>
                      <TableCell>
                        {row.appUser && row.appUser.registered_at
                          ? formatYYYYMMDD(new Date(row.appUser.registered_at))
                          : null}
                      </TableCell>
                      <TableCell>
                        {row.appUser && row.appUser.last_login_at
                          ? formatYYYYMMDD(new Date(row.appUser.last_login_at))
                          : null}
                      </TableCell>
                    </TableRow>
                  )
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default List
