import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

const FormFooter = props => {
  const classes = useStyles()

  return (
    <>
      <Button
        onClick={props.handleCancel}
        variant="contained"
        className={classes.secondaryButton}
      >
        キャンセル
      </Button>
      <Button
        type="submit"
        variant="contained"
        className={classes.primaryButton}
      >
        作成
      </Button>
    </>
  )
}

export default FormFooter
