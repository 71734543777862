import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { connect } from 'react-redux';
// import { setLoadData } from 'actions/Actions';
import { connect } from 'react-redux'
import { clearSearchCondition } from 'actions/Actions'
import ListContent from './ListContent'

const useStyles = makeStyles({
  wrapBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  wrapContent: {
    flex: 1,
    display: 'flex',
    margin: '32px'
  }
})

function ListScreen(props) {
  const classes = useStyles()

  useEffect(() => {
    //   props.setLoadData(true);
    if (props.location.state == undefined) {
      props.clearSearchCondition()
    }
  })

  return (
    <div className={classes.wrapBody}>
      <div className={classes.wrapContent}>
        <ListContent />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    // setLoadData: (flag) => {
    //   dispatch(setLoadData(flag))
    // },
    clearSearchCondition: () => {
      dispatch(clearSearchCondition())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListScreen)
