import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import Loading from 'components/common/Loading'
import { Switch } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  showTopPageSwitch: {
    '& .Mui-checked': {
      color: theme.palette.c_blue.main
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.c_blue.main
    }
  },
  typography: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#72809d',
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

const TopDisplayForm = props => {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()
  const { isLoading, packageData } = props
  const [shouldShowTop, setShouldShowTop] = useState()

  const handleChange = event => {
    const target = event.target
    const value = target.checked
    const name = target.name

    setShouldShowTop(event.target.checked)
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    if (packageData.package != undefined) {
      setShouldShowTop(packageData.package.is_display_top)
    }
  }, [packageData])

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  id="is_display_top"
                  name="is_display_top"
                  className={classes.showTopPageSwitch}
                  checked={shouldShowTop}
                  onChange={handleChange}
                  disabled={!isUnrestrictedRole}
                />
              }
              label={<Typography variant="body2">TOPページ表示</Typography>}
            />
          </Grid>
          <Grid item>ー</Grid>
          <Grid item>
            <Typography className={classes.typography}>
              ONの場合、対象の入居者サービスがTOTONOアプリのTOPページにも表示されるようになります。
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default TopDisplayForm
