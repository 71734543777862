import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Left from './Left'
import Right from './Right'
import { withSnackbar } from 'notistack'
import { QueryHelper } from 'utils/api.utils'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    margin: '32px'
  },
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function DetailScreen(props) {
  const classes = useStyles()

  const { id } = useParams()

  const [room, setRoom] = useState({})

  const fetchRoom = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    // console.log(JSON.stringify(params));
    const result = await QueryHelper('getRoom', { filter: params })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`部屋を取得できませんでした。`)
    } else {
      setRoom(result)
    }
  }

  useEffect(() => {
    if (id !== undefined) {
      fetchRoom(id)
    }
  }, [setRoom])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.leftBody}>
            <Left room={room} />
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.rightBody}>
            <Right room={room} />
          </div>
        </div>
      </div>
    </>
  )
}

export default withSnackbar(DetailScreen)
