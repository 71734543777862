import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import RichTextEditor from 'components/General/RichTextEditor'
import { useFormContext } from 'react-hook-form'
import { useRole } from 'hooks/useRole'
import { Note } from 'utils/note'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '10px 0'
  },
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  subTitle: {
    marginLeft: '10px',
    height: '18px',
    color: '#72809D',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

const ApplicationOptionsPrecautionsSettings = props => {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()
  const { setCheckError, checkError } = props
  const { register, setValue, watch, formState } = useFormContext()

  const handleChange = event => {
    setValue(event.target.name, event.target.value)
  }

  return (
    <FormLayout title={'申請送信完了時の文言設定'}>
      <Grid container spacing={3} alignItems="center">
        <Grid container alignItems="center" className={classes.section}>
          <Grid>
            <Typography className={classes.title}>
              {'完了画面タイトル'}
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.subTitle}>
              {'※送信完了の画面に表示されます'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <TextInput
            {...register('complete_title')}
            error={Boolean(formState.errors.complete_title)}
            helperText={
              formState.errors.complete_title &&
              formState.errors.complete_title.message
            }
            id="complete_title"
            name="complete_title"
            label={<Typography>{'完了画面タイトル（30文字まで）'}</Typography>}
            fullWidth
            variant="filled"
            defaultValue={watch('complete_title')}
            onChange={handleChange}
            size="small"
            disabled={!isUnrestrictedRole}
          />
        </Grid>
        <Grid container alignItems="center" className={classes.section}>
          <Grid>
            <Typography className={classes.title}>{'完了画面本文'}</Typography>
          </Grid>
          <Grid>
            <Typography className={classes.subTitle}>
              {`※送信完了の画面に表示されます。${Note.RichTextLinkAdditional}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            id="complete_body"
            maxLength={1000}
            placeholder="本文（1000文字まで）"
            defaultValue={watch('complete_body')}
            error={
              formState.errors.complete_body
                ? formState.errors.complete_body.message
                : null
            }
            setCheckError={setCheckError}
            checkError={checkError}
            readOnly={!isUnrestrictedRole}
          />
        </Grid>
        <Grid container alignItems="center" className={classes.section}>
          <Grid>
            <Typography className={classes.title}>{'参照先URL'}</Typography>
          </Grid>
          <Grid>
            <Typography className={classes.subTitle}>
              {'※参照先のURLがある場合は入力してください'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <TextInput
            {...register('complete_url_text')}
            error={Boolean(formState.errors.complete_url_text)}
            helperText={
              formState.errors.complete_url_text &&
              formState.errors.complete_url_text.message
            }
            id="complete_url_text"
            name="complete_url_text"
            label={
              <Typography className={classes.urlMessage}>
                {'URLに表示するテキスト'}
              </Typography>
            }
            fullWidth
            variant="filled"
            defaultValue={watch('complete_url_text')}
            onChange={handleChange}
            size="small"
            disabled={!isUnrestrictedRole}
          />
        </Grid>
        <Grid item xs={7}>
          <TextInput
            {...register('complete_url')}
            error={Boolean(formState.errors.complete_url)}
            helperText={
              formState.errors.complete_url &&
              formState.errors.complete_url.message
            }
            id="complete_url"
            name="complete_url"
            label={
              <Typography className={classes.urlMessage}>
                {'遷移先のURLがある場合はこちらに入力'}
              </Typography>
            }
            fullWidth
            variant="filled"
            defaultValue={watch('complete_url')}
            onChange={handleChange}
            size="small"
            disabled={!isUnrestrictedRole}
          />
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ApplicationOptionsPrecautionsSettings
