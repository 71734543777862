import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  footerWrapper: {
    marginTop: '8px',
    marginLeft: '16px',
    marginBottom: '8px',
    marginRight: '16px'
  }
}))

function FormModalDialog(props) {
  const classes = useStyles()
  const { title, body } = props

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <Divider />
        {body}
      </Dialog>
    </>
  )
}

export default withSnackbar(FormModalDialog)
