import React from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const columns = [
  { id: 'title', label: 'タイトル', minWidth: 342 },
  { id: 'category_id', label: 'カテゴリー', minWidth: 181 },
  { id: 'body', label: '本文', minWidth: 523 }
]

function List(props) {
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const { searchCondition } = props

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/management/message-template/edit/${id}`,
      e,
      history,
      searchCondition
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead style={{ backgroundColor: '#fafbfd' }}>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.messageTemplateList.length
            ? props.messageTemplateList.map(row => {
                return (
                  <TableRow
                    onClick={e =>
                      navigateToDetailPage(e, row.message_template.id)
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.message_template.id}
                  >
                    <TableCell>{row.message_template.title}</TableCell>
                    <TableCell>{row.category.name}</TableCell>
                    <TableCell>{row.message_template.body}</TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
