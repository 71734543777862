import { useEffect, useState } from 'react'
import { QueryHelper } from 'utils/api.utils'

export const useOwnerFunctionEnabled = () => {
  const [isOwnerFunctionEnabled, setIsOwnerFunctionEnabled] = useState(false)

  const fetchCompany = async () => {
    const result = await QueryHelper('getCompany', {})
    if (result.error) return
    setIsOwnerFunctionEnabled(result.company.owner_function_enabled)
  }

  useEffect(() => {
    fetchCompany()
  }, [])

  return {
    isOwnerFunctionEnabled
  }
}
