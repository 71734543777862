import React from 'react'
import { withRouter } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Label from 'components/common/Label'
import { ContractKykStatusDiv } from 'utils/enum'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const columns = [
  { id: 'KYK_NKY_KKY_NAME', label: '契約状況', minWidth: 60 },
  { id: 'KYK_NKY_KKY_NAME', label: '入居者名', minWidth: 117 },
  { id: 'KYK_KYS_KKY_NAME', label: '契約者名', minWidth: 148 },
  { id: 'registered_at', label: 'アプリ利用開始日', minWidth: 156 },
  { id: 'last_login_at', label: '最終ログイン日', minWidth: 180 }
]

function formatDate(date, format) {
  format = format.replace(/yyyy/g, date.getFullYear())
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))
  return format
}

function parseDate(dateStr) {
  return new Date(dateStr) //.toLocaleString({ timeZone: 'Asia/Tokyo' })
}

function KeiyakuStatusLabel({ kykStatusDiv }) {
  if (kykStatusDiv == ContractKykStatusDiv.application.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.application.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.contract.code) {
    return <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.cancellation.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
    )
  } else if (kykStatusDiv == ContractKykStatusDiv.completed.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.completed.value} />
  } else if (kykStatusDiv == ContractKykStatusDiv.payOff.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.payOff.value} />
  } else {
    return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
  }
}

function ContractList(props) {
  const isTextSelected = useIsTextSelected()

  const { contractList } = props

  const navigateToDetailPage = id => {
    if (isTextSelected()) return

    props.history.push(`/management/contract/detail/${id}`)
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {contractList.length
            ? contractList.map(row => {
                return (
                  <TableRow
                    onClick={() => navigateToDetailPage(row.contract.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.customer.id}
                  >
                    <TableCell>
                      {row.contract ? (
                        <KeiyakuStatusLabel
                          kykStatusDiv={row.contract.kyk_status_div}
                        />
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell>
                      {'mst_kokyaku_kyk_nky' in row && row.mst_kokyaku_kyk_nky
                        ? row.mst_kokyaku_kyk_nky.KKY_NAME
                        : ''}
                    </TableCell>
                    <TableCell>
                      {'mst_kokyaku_kyk_kys' in row && row.mst_kokyaku_kyk_kys
                        ? row.mst_kokyaku_kyk_kys.KKY_NAME
                        : ''}
                    </TableCell>
                    <TableCell>
                      {row.appUser && row.appUser.registered_at
                        ? formatDate(
                            parseDate(row.appUser.registered_at),
                            'yyyy/MM/dd'
                          )
                        : ''}
                    </TableCell>
                    <TableCell>
                      {row.appUser && row.appUser.last_login_at
                        ? formatDate(
                            parseDate(row.appUser.last_login_at),
                            'yyyy/MM/dd'
                          )
                        : ''}
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
        {/* {paging.count > 5 && (
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={5} >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  // component={Link}
                  // to={`/management/building/${row.room.building_id}/room`}
                  onClick={() => props.history.push(`/management/building/${roomList[0].room.building_id}/room`)}
                >
                  <Grid item>
                    <ArrowForwardIcon style={{color: "#696CFF"}}/>
                  </Grid>
                  <Grid item style={{
                    fontFamily: "Hiragino Sans",
                    fontWeight: 600,
                    color: "#696CFF",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}>
                    すべて見る
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableFooter>
        )} */}
      </Table>
    </>
  )
}

export default withRouter(ContractList)
