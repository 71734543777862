import React, { useEffect, useState } from 'react'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import DetailLayout from 'components/common/DetailLayout'
import BulletinBoardList from './BulletinBoardList'

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: 5, page: 1 }
}

function BulletinBoardListContent(props) {
  const { isLoading, building } = props
  const [bulletinBoardList, setBulletinBoardList] = useState(initialState)

  const fetchBulletinBoardList = async building_id => {
    const params = {
      building_id: {
        eq: [building_id]
      }
    }
    const result = await QueryHelper('getBulletinBoardList', {
      filter: params,
      limit: 5
    })
    if (!result.error) {
      setBulletinBoardList(result)
    } else {
      props.enqueueSnackbar(`お知らせ一覧を取得できませんでした。`)
    }
  }

  useEffect(() => {
    if ('building' in building && building.building) {
      fetchBulletinBoardList(building.building.id)
    }
  }, [building, setBulletinBoardList])

  return (
    <DetailLayout
      title="お知らせ一覧"
      children={
        <BulletinBoardList
          building={building}
          bulletinBoardList={bulletinBoardList.data}
          paging={bulletinBoardList.paging}
        />
      }
      isLoading={isLoading}
    />
  )
}

export default withSnackbar(BulletinBoardListContent)
