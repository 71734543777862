import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Buttons from 'components/common/Buttons'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { ThreadState } from 'utils/enum'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  tableCell: {
    padding: '10px 8px'
  },
  link: {
    cursor: 'pointer'
  },
  img: {
    width: 25,
    height: 'auto',
    verticalAlign: 'middle'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  count: {
    display: 'flex',
    justifyContent: 'right',
    fontSize: '10px'
  },
  radio: {
    margin: 'auto'
  }
}))

const columns = [
  { id: 'name', label: '', minWidth: 480 },
  { id: 'Update', label: '', minWidth: 80 },
  { id: 'isDefault', label: '初期表示', minWidth: 70 },
  { id: 'delete', label: '', minWidth: 70 }
]

function SearchSaveContent(props) {
  const classes = useStyles()
  const [searchNameCreate, setSearchNameCreate] = useState({
    value: '',
    count: 0
  })
  const [searchNameUpdate, setSearchNameUpdate] = useState({
    value: '',
    count: 0
  })
  const [searchSaveData, setSearchSaveData] = useState([])
  const [errorMessageCreate, setErrorMessageCreate] = useState('')
  const [errorMessageUpdate, setErrorMessageUpdate] = useState('')
  const [isLoad, setIsLoad] = useState(false)
  const [updateInfo, setUpdateInfo] = useState({
    id: 0,
    isUpdate: false
  })

  useEffect(() => {
    fetchSearchConditionList()
  }, [])

  const fetchSearchConditionList = async () => {
    setIsLoad(true)
    const result = await QueryHelper('getSearchConditionList', {
      screen: props.screen
    })
    if (result.error) {
      props.enqueueSnackbar('検索条件一覧を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const isDefault = result.data.some(item => {
        return item.isDefault
      })
      result.data.unshift({
        id: 0,
        name: '検索条件を設定せずに表示する',
        isDefault: !isDefault,
        condition: {}
      })
      setSearchSaveData(result.data)
    }
    setIsLoad(false)
  }

  const postSearchCondition = async params => {
    if (errorMessageCreate) return
    if (searchSaveData.length >= 20) {
      setErrorMessageCreate('検索条件の保存は20件までです')
      return
    }
    const errorMessage = validateName(params.name)
    if (errorMessage) {
      setErrorMessageCreate(errorMessage)
      return
    }
    const result = await MutationHelper('createSearchCondition', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('検索条件を保存できませんでした', {
        variant: 'error'
      })
    } else {
      resetSearchName()
      fetchSearchConditionList()
      props.enqueueSnackbar('検索条件を保存しました。', {
        variant: 'success'
      })
    }
  }

  const putSearchCondition = async (target, params) => {
    if (errorMessageUpdate) return
    if (target === 'isDefault') {
      const isDefault = searchSaveData.some(item => {
        return item.id === params.id && item.isDefault
      })
      if (isDefault) {
        return
      }
      if (params.id === 0) {
        params = {
          screen: props.screen
        }
      }
    } else if (target === 'name') {
      const isChange = searchSaveData.some(item => {
        return item.id === params.id && item.name === params.name
      })
      if (isChange) {
        setErrorMessageUpdate('変更がありません')
        return
      }
      const errorMessage = validateName(params.name)
      if (errorMessage) {
        setErrorMessageUpdate(errorMessage)
        return
      }
    }
    const result = await MutationHelper('updateSearchCondition', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('検索条件を更新できませんでした', {
        variant: 'error'
      })
    } else {
      resetSearchName()
      if (target === 'name') {
        setUpdateInfo({ id: 0, isUpdate: false })
      }
      fetchSearchConditionList()
      props.enqueueSnackbar('検索条件を更新しました', {
        variant: 'success'
      })
    }
  }

  const validateName = name => {
    if (name.length === 0) {
      return '必須です'
    }
    const isDuplicate = searchSaveData.some(item => {
      return item.name === name
    })
    if (isDuplicate) {
      return '同じ名前の検索条件が存在します'
    }
    return ''
  }

  const deleteSearchCondition = async params => {
    if (!window.confirm(`削除します。よろしいですか？`)) {
      return
    }
    const result = await MutationHelper('deleteSearchCondition', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('検索条件を削除できませんでした', {
        variant: 'error'
      })
    } else {
      resetSearchName()
      fetchSearchConditionList()
      props.enqueueSnackbar('検索条件を削除しました', {
        variant: 'success'
      })
    }
  }

  const handleSearchNameChange = event => {
    const validateLength = length => {
      if (length > 30) {
        return '30文字以内で入力してください'
      } else if (length == 0) {
        return '検索条件名を入力してください'
      } else {
        return ''
      }
    }
    const errorMessage = validateLength(event.target.value.length)
    if (event.target.id === 'create') {
      setSearchNameCreate({
        value: event.target.value,
        count: event.target.value.length
      })
      setErrorMessageCreate(errorMessage)
    }
    if (event.target.id === 'update') {
      setSearchNameUpdate({
        value: event.target.value,
        count: event.target.value.length
      })
      setErrorMessageUpdate(errorMessage)
    }
  }

  const handleDefaultChange = (event, index) => {
    const newData = searchSaveData.map((item, i) => {
      if (i == index) {
        item.isDefault = true
      } else {
        item.isDefault = false
      }
      return item
    })
    setSearchSaveData(newData)
  }

  const resetSearchName = () => {
    setSearchNameCreate({ value: '', count: 0 })
    setSearchNameUpdate({ value: '', count: 0 })
    setErrorMessageCreate('')
    setErrorMessageUpdate('')
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">検索条件を保存</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            現在の検索条件が保存されます。
            <br />
            保存すると検索条件設定の「検索条件一覧」からアクセスできます。
          </Typography>
        </Grid>
        <Grid item xs={10} style={{ paddingBottom: 0 }}>
          <FormControl className={classes.formControl}>
            <TextInput
              id="create"
              placeholder="検索条件の名前を入力"
              defaultValue={searchNameCreate.value}
              value={searchNameCreate.value}
              onChange={handleSearchNameChange}
              onClick={() => {
                setUpdateInfo({ id: 0, isUpdate: false })
              }}
              required
              variant="filled"
              hiddenLabel
              error={errorMessageCreate ? true : false}
              helperText={errorMessageCreate}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} className={classes.button}>
          <Button
            type="submit"
            variant="contained"
            className={classes.primaryButton}
            onClick={() =>
              postSearchCondition({
                name: searchNameCreate.value,
                screen: props.screen,
                condition: JSON.stringify(props.searchConditions)
              })
            }
          >
            保存
          </Button>
        </Grid>
        <Grid item xs={10} style={{ paddingTop: 0 }} className={classes.count}>
          {`${searchNameCreate.count} / 30`}
        </Grid>
        <RadioGroup
          aria-label="isDefault"
          name="isDefault"
          onChange={(e, index) => handleDefaultChange(e, index)}
          style={{ width: '100%' }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ backgroundColor: '#fafbfd' }}>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.tableCell}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!isLoad ? (
              <TableBody>
                {searchSaveData && searchSaveData.length
                  ? searchSaveData.map((row, index) => {
                      return index == 0 ? (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row}>
                          <TableCell className={classes.tableCell}>
                            <Grid
                              className={classes.link}
                              onClick={() => {
                                // 検索条件が{}(空)の場合はisDefaultがtrueの条件で表示してしまうためダミーの値で回避
                                props.setSearchConditions({
                                  dummy: ''
                                })
                                if (props.stateName) {
                                  if (
                                    props.stateName === 'message.thread_state'
                                  ) {
                                    props.setSelectedStatus([
                                      ThreadState.all.code
                                    ])
                                  } else {
                                    props.setSelectedStatus(0)
                                  }
                                }
                                props.closeSearchSaveModalDialog()
                              }}
                            >
                              {row.name}
                            </Grid>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {null}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: 'center'
                            }}
                            className={classes.tableCell}
                          >
                            <FormControlLabel
                              className={classes.radio}
                              value={index}
                              checked={row.isDefault}
                              control={
                                <Radio
                                  color="primary"
                                  onClick={() => {
                                    putSearchCondition('isDefault', {
                                      id: row.id,
                                      isDefault: row.isDefault,
                                      screen: props.screen
                                    })
                                    setUpdateInfo({ id: 0, isUpdate: false })
                                  }}
                                />
                              }
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      ) : (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row}
                          >
                            <TableCell className={classes.tableCell}>
                              <Grid
                                className={classes.link}
                                onClick={() => {
                                  props.setSearchConditions(
                                    JSON.parse(row.condition)
                                  )
                                  if (props.stateName) {
                                    if (!row.condition[props.stateName]) {
                                      if (
                                        props.stateName ===
                                        'message.thread_state'
                                      ) {
                                        props.setSelectedStatus([
                                          ThreadState.all.code
                                        ])
                                      } else {
                                        props.setSelectedStatus(0)
                                      }
                                    }
                                  }
                                  props.closeSearchSaveModalDialog()
                                }}
                              >
                                <img
                                  className={classes.img}
                                  src={`${process.env.PUBLIC_URL}/manage_search.svg`}
                                  alt={'検索条件'}
                                />
                                {row.name}
                              </Grid>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Grid
                                className={classes.link}
                                onClick={() => {
                                  resetSearchName()
                                  setUpdateInfo({ id: row.id, isUpdate: true })
                                }}
                              >
                                名前を変更
                              </Grid>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: 'center'
                              }}
                              className={classes.tableCell}
                            >
                              <FormControlLabel
                                className={classes.radio}
                                value={index}
                                checked={row.isDefault}
                                control={
                                  <Radio
                                    color="primary"
                                    onClick={() => {
                                      putSearchCondition('isDefault', {
                                        id: row.id,
                                        isDefault: true,
                                        screen: props.screen
                                      })
                                      setUpdateInfo({
                                        id: row.id,
                                        isUpdate: false
                                      })
                                    }}
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <IconButton
                                aria-label="削除"
                                color="primary"
                                onClick={() =>
                                  deleteSearchCondition({ id: row.id })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          {updateInfo.isUpdate && updateInfo.id === row.id ? (
                            <>
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingBottom: 0 }}
                                  >
                                    <FormControl
                                      className={classes.formControl}
                                    >
                                      <TextInput
                                        id="update"
                                        placeholder="検索条件の名前を入力"
                                        defaultValue={searchNameUpdate.value}
                                        value={searchNameUpdate.value}
                                        onChange={handleSearchNameChange}
                                        required
                                        variant="filled"
                                        hiddenLabel
                                        error={
                                          errorMessageUpdate ? true : false
                                        }
                                        helperText={errorMessageUpdate}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: 0 }}
                                    className={classes.count}
                                  >
                                    {`${searchNameUpdate.count} / 30`}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Buttons
                                      handleSubmit={() =>
                                        putSearchCondition('name', {
                                          id: row.id,
                                          name: searchNameUpdate.value,
                                          screen: props.screen
                                        })
                                      }
                                      handleCancel={() =>
                                        setUpdateInfo({
                                          id: row.id,
                                          isUpdate: false
                                        })
                                      }
                                    />
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            </>
                          ) : null}
                        </>
                      )
                    })
                  : null}
              </TableBody>
            ) : (
              <Loading isLoading={isLoad} marginTop={20} />
            )}
          </Table>
        </RadioGroup>
      </Grid>
    </>
  )
}

export default withSnackbar(SearchSaveContent)
