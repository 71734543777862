import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

const useStyle = makeStyles(theme => {
  return {
    button: {
      width: '100px',
      backgroundColor: 'white',
      border: '1px solid #e5e5e5',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.c_primary.lightest
      }
    },
    active: {
      color: 'white',
      backgroundColor: theme.palette.c_primary.main
    }
  }
})

export default function CheckButtons({
  currents,
  handleSelectedButton,
  buttonsData,
  ...props
}) {
  const classes = useStyle()

  const [targetSet, setTargetSet] = useState(new Set())

  useEffect(() => {
    if (currents && currents.length > 0) {
      const currentSet = new Set()
      for (let i of currents) {
        currentSet.add(i)
      }
      setTargetSet(currentSet)
    } else {
      setTargetSet(new Set())
    }
  }, [currents])

  return (
    <div style={{ textAlign: 'center' }} {...props}>
      <ButtonGroup aria-label="large button group">
        {buttonsData.map(({ label, value }) =>
          targetSet.has(value) ? (
            <Button
              className={`${classes.button} ${classes.active}`}
              key={value}
              styled={{ backgroundColor: '#abcdef' }}
              onClick={e => handleSelectedButton(value)}
            >
              {label}
            </Button>
          ) : (
            <Button
              className={`${classes.button} ${classes.alert}`}
              key={value}
              onClick={e => handleSelectedButton(value)}
            >
              {label}
            </Button>
          )
        )}
      </ButtonGroup>
    </div>
  )
}
