import React from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Label from 'components/common/Label'
import UseAppLabel from 'components/common/UseAppLabel'
import KeiyakuStatusDivLabel from 'components/common/KeiyakuStatusDivLabel'
import { ContractKykStatusDiv } from 'utils/enum'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const columns = [
  { id: 'USE_APP', label: 'アプリ', minWidth: 60 },
  { id: 'KYK_STATUS_DIV_NAME', label: '契約状況', minWidth: 100 },
  { id: 'HYA_STATUS_DIV_NAME', label: '空室区分', minWidth: 110 },
  { id: 'HYA_ROOM_NO', label: '部屋番号', minWidth: 100 },
  { id: 'HYA_MDR_NAME', label: '間取り', minWidth: 100 },
  { id: 'KYK_NKY_KKY_NAME', label: '入居者', minWidth: 145 },
  { id: 'KYK_KYS_KKY_NAME', label: '契約者', minWidth: 145 }
]

function GetUseAppLabel({ contractAndTenant, building, knrKeiyaku }) {
  if (!contractAndTenant || !knrKeiyaku) {
    return <Label color={'gray'} text={'対象外'} />
  }

  if (
    contractAndTenant.contract &&
    contractAndTenant.contract.kyk_id === knrKeiyaku.KYK_ID
  ) {
    return (
      <UseAppLabel
        is_usage={contractAndTenant.tenant.is_usage}
        is_management={building.is_management}
        app_user_id={contractAndTenant.tenant.app_user_id}
        kykStatusDiv={knrKeiyaku.KYK_STATUS_DIV}
      />
    )
  }
  return <Label color={'gray'} text={'対象外'} />
}

function KeiyakuStatusLabel({ status, name }) {
  return <KeiyakuStatusDivLabel kykStatusDiv={status} kykStatusDivName={name} />
}

function RoomStatusLabel({ status }) {
  if (
    status == ContractKykStatusDiv.contract.code ||
    status == ContractKykStatusDiv.cancellation.code
  ) {
    return <Label color="blue" text="入居中" />
  }
  return <Label color="gray" text="空室" />
}

function List(props) {
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const { roomList } = props

  const navigateToDetailPage = (e, buildingId, roomId) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/management/building/${buildingId}/room/${roomId}`,
      e,
      history
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {roomList.length
            ? roomList.map(row => {
                return (
                  <TableRow
                    onClick={e =>
                      navigateToDetailPage(e, row.room.building_id, row.room.id)
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.room.id}
                  >
                    <TableCell>
                      <GetUseAppLabel
                        contractAndTenant={row.contractAndTenant}
                        building={row.building}
                        knrKeiyaku={row.knr_keiyaku}
                      />
                    </TableCell>
                    <TableCell>
                      {'knr_keiyaku' in row && row.knr_keiyaku ? (
                        <KeiyakuStatusLabel
                          status={row.knr_keiyaku.KYK_STATUS_DIV}
                          name={row.knr_keiyaku.KYK_STATUS_DIV_NAME}
                        />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>
                      {'knr_keiyaku' in row && row.knr_keiyaku ? (
                        <RoomStatusLabel
                          status={row.knr_keiyaku.KYK_STATUS_DIV}
                        />
                      ) : (
                        <Label color="gray" text="空室" />
                      )}
                    </TableCell>
                    <TableCell>{row.mst_heya.HYA_ROOM_NO}</TableCell>
                    <TableCell>
                      {row.mst_heya.HYA_MDR_NUM || ''}
                      {row.mst_heya.HYA_MDR_NAME}
                    </TableCell>
                    <TableCell>
                      {'knr_keiyaku' in row &&
                      row.knr_keiyaku &&
                      row.nky_mst_kokyaku &&
                      row.knr_keiyaku.KYK_STATUS_DIV !==
                        ContractKykStatusDiv.completed.code &&
                      row.knr_keiyaku.KYK_STATUS_DIV !==
                        ContractKykStatusDiv.payOff.code &&
                      row.knr_keiyaku.KYK_STATUS_DIV !==
                        ContractKykStatusDiv.cancel.code
                        ? row.nky_mst_kokyaku.KKY_NAME
                        : ''}
                    </TableCell>
                    <TableCell>
                      {'knr_keiyaku' in row &&
                      row.knr_keiyaku &&
                      row.kys_mst_kokyaku &&
                      row.knr_keiyaku.KYK_STATUS_DIV !==
                        ContractKykStatusDiv.completed.code &&
                      row.knr_keiyaku.KYK_STATUS_DIV !==
                        ContractKykStatusDiv.payOff.code &&
                      row.knr_keiyaku.KYK_STATUS_DIV !==
                        ContractKykStatusDiv.cancel.code
                        ? row.kys_mst_kokyaku.KKY_NAME
                        : ''}
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
