import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import Loading from './Loading'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

function BackDropLoading({ isLoad }) {
  const classes = useStyles()

  return (
    <>
      {isLoad && (
        <Backdrop className={classes.backdrop} open={isLoad}>
          <Loading isLoading={isLoad} />
        </Backdrop>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    isLoad: state.loadData.flag
  }
}

export default connect(mapStateToProps)(BackDropLoading)
