import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { clearSearchCondition } from 'actions/Actions'
import ListContent from './ListContent'

const useStyles = makeStyles({
  wrapBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  wrapContent: {
    flex: 1,
    display: 'flex',
    margin: '32px'
  }
})

function ListScreen(props) {
  const classes = useStyles()

  return (
    <div className={classes.wrapBody}>
      <div className={classes.wrapContent}>
        <ListContent />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    clearSearchCondition: () => {
      dispatch(clearSearchCondition())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListScreen)
