import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { formatDate } from 'utils/date.utils'

const useStyles = makeStyles(theme => ({
  ago: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  hmi: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  img: {
    position: 'relative',
    top: 6,
    width: 30,
    height: 'auto',
    alignItems: 'center'
  }
}))

export default function DateLabel({ timestamp, firstSent, type, ...props }) {
  const classes = useStyles()

  const ago = formatDate({ type: 'ago', value: timestamp })
  const hmi = formatDate({ type: 'hmi', value: timestamp })

  return (
    <Grid container direction="column">
      <Grid item className={classes.ago}>
        <span {...props}>{ago}</span>
        {type == 'managementMessageDisplay' ? (
          firstSent == 1 ? (
            <img
              className={classes.img}
              src={`${process.env.PUBLIC_URL}/soushin.png`}
              alt={'totono'}
            />
          ) : (
            <img
              className={classes.img}
              src={`${process.env.PUBLIC_URL}/jyushin.png`}
              alt={'totono'}
            />
          )
        ) : null}
      </Grid>
      <Grid item className={classes.hmi}>
        <span {...props}>{hmi}</span>
      </Grid>
    </Grid>
  )
}
