import { BaseEnum } from './BaseEnum'

export const AttachmentType = { image: 1, video: 2, pdf: 3, csv: 4 }
export const AttachmentStatus = { enabled: 1, disabled: 3 }

export const CompanyUserStatusEnum = {
  all: new BaseEnum(0, 'すべて'),
  active: new BaseEnum(1, '利用中'),
  inactive: new BaseEnum(2, '停止')
}

export const BuildingStatusEnum = { all: -1, active: 1, inactive: 0 }

export const ThreadState = {
  all: { key: 'ALL', code: 0, value: 'すべて' },
  open: { key: 'OPEN', code: 1, value: '未対応' },
  inProgress: { key: 'IN_PROGRESS', code: 2, value: '対応中' },
  pending: { key: 'PENDING', code: 3, value: '対応済み' },
  complete: { key: 'COMPLETE', code: 4, value: '完了' },
  resolve: { key: 'RESOLVE', code: 5, value: '解決' },

  fromValue: function(value) {
    return this.values().find(element => value == element.value)
  },
  fromKey: function(key) {
    return this.values().find(element => key == element.key)
  },
  values: function() {
    return [
      ThreadState.all,
      ThreadState.open,
      ThreadState.inProgress,
      ThreadState.pending,
      ThreadState.complete,
      ThreadState.resolve
    ]
  }
}

export const ReplyState = {
  noReply: new BaseEnum(1, '未返信'),
  Replied: new BaseEnum(2, '返信済')
}

export const FirstTransceiverState = {
  sent: new BaseEnum(1, '初回送信'),
  received: new BaseEnum(2, '初回受信')
}

export const ArrangedStateList = {
  arranged: new BaseEnum(1, '案件手配のみを表示'),
  notArranged: new BaseEnum(2, '案件手配を含まない')
}

export const OnlySearchBulkDelivery = {
  bulkDelivery: new BaseEnum(1, '一括配信メッセージのみを表示'),
  notBulkDelivery: new BaseEnum(2, '一括配信メッセージを含まない')
}

export const BulletinBoardStatus = {
  reservation: new BaseEnum(1, '掲載'),
  draft: new BaseEnum(2, '下書き')
}

export const BulletinBoardState = {
  all: new BaseEnum(0, 'すべて'),
  draft: new BaseEnum(1, '下書き'),
  reservation: new BaseEnum(2, '予約'),
  posting: new BaseEnum(3, '掲載中'),
  complete: new BaseEnum(4, '終了')
}

export const BulletinBoardType = {
  notify: new BaseEnum(1, 'お知らせ'),
  facility: new BaseEnum(2, '設備について'),
  resident: new BaseEnum(4, '入居について'),
  contract: new BaseEnum(5, '契約について'),
  campaign: new BaseEnum(3, 'お得情報')
}

export const CustomerHistoryType = {
  chat: new BaseEnum(1, 'チャット'),
  application: new BaseEnum(2, '申請'),
  create: new BaseEnum(3, '作成')
}

export const CustomerHistoryMessageCategory = {
  tel: { key: 'TEL', code: 1, value: '電話' },
  mail: { key: 'MAIL', code: 2, value: 'メール' },
  delivery: { key: 'DELIVERY', code: 3, value: '郵便' },
  other: { key: 'OTHER', code: 4, value: 'その他' },

  fromValue: function(value) {
    return this.values().find(element => value == element.value)
  },
  fromKey: function(key) {
    return this.values().find(element => key == element.key)
  },
  values: function() {
    return [
      CustomerHistoryMessageCategory.tel,
      CustomerHistoryMessageCategory.mail,
      CustomerHistoryMessageCategory.delivery,
      CustomerHistoryMessageCategory.other
    ]
  }
}

export const AppIsUsage = {
  enabled: new BaseEnum(1, '有効'),
  disabled: new BaseEnum(0, '無効')
}

export const BulkDelivery = {
  all: new BaseEnum(0, 'すべて'),
  appUser: new BaseEnum(1, '使用中'),
  unused: new BaseEnum(2, '未使用'),
  disabled: new BaseEnum(3, '無効')
}

export const ChatAnswerList = {
  nothing: { key: 'NOTHING', label: 'なし', value: 1 },
  confirm: { key: 'CONFIRM', label: '確認しました', value: 2 },
  agree: { key: 'AGREE', label: '同意します', value: 3 },

  fromValue: function(value) {
    return this.values().find(element => value == element.value)
  },
  fromKey: function(key) {
    return this.values().find(element => key == element.key)
  },
  values: function() {
    return [
      ChatAnswerList.nothing,
      ChatAnswerList.confirm,
      ChatAnswerList.agree
    ]
  }
}

export const BulkDeliverySentStatus = {
  notCompatible: { key: 'NOT_COMPATIBLE', label: '未対応', value: 1 },
  responding: { key: 'RESPONDING', label: '対応中', value: 2 },
  supported: { key: 'SUPPORTED', label: '対応済み', value: 3 },
  complete: { key: 'COMPLETE', label: '完了', value: 4 },

  fromValue: function(value) {
    return this.values().find(element => value == element.value)
  },
  fromKey: function(key) {
    return this.values().find(element => key == element.key)
  },
  values: function() {
    return [
      BulkDeliverySentStatus.notCompatible,
      BulkDeliverySentStatus.responding,
      BulkDeliverySentStatus.supported,
      BulkDeliverySentStatus.complete
    ]
  }
}

// 申請系 #########################
export const ApplicationTypes = {
  residentInfoChange: new BaseEnum(1, '入居者情報変更'),
  residentCancellation: new BaseEnum(2, '入居者解約'),
  roomCheckList: new BaseEnum(3, '入居者室内点検表'),
  bicycleParkingStickers: new BaseEnum(4, '駐輪ステッカー'),
  certificateOfConsentToUseParkingSpace: new BaseEnum(
    5,
    '駐車場保管場所使用承諾証明書'
  ),
  pet: new BaseEnum(6, 'ペット飼育')
}

// 入居者情報変更 ここから --------------------
export const ResidentInfoChangeApplicationState = {
  all: new BaseEnum(0, 'すべて'),
  open: new BaseEnum(1, '未対応'),
  inProgress: new BaseEnum(2, '対応中'),
  complete: new BaseEnum(3, '対応済')
}
// 入居者情報変更 ここまで---------------------

// 入居解約 ここから --------------------
export const ResidentCancellationApplicationState = {
  all: new BaseEnum(0, 'すべて'),
  open: new BaseEnum(1, '受付完了'),
  inProgress: new BaseEnum(2, '条件確認'),
  pending: new BaseEnum(3, '退去待ち'),
  complete: new BaseEnum(4, '完了'),
  cancel: new BaseEnum(5, '取り消し'),
  adjust: new BaseEnum(6, '立会い調整')
}

export const LegalPersonDiv = {
  savings: new BaseEnum(0, '個人'),
  current: new BaseEnum(1, '法人')
}

export const FinancialInstitutionType = {
  savings: new BaseEnum('1', '普通'),
  current: new BaseEnum('2', '当座')
}

export const AttendCompanyType = {
  none: new BaseEnum('0', '立ち会いなし'),
  own: new BaseEnum('1', '自社'),
  other: new BaseEnum('2', '業者')
}

export const AttendDateType = {
  morning: new BaseEnum(1, '午前中'),
  afternoon: new BaseEnum(2, '12時〜15時'),
  earlyEvening: new BaseEnum(3, '15時〜18時')
}

export const CancellationReason = {
  employment: new BaseEnum('1', '就職'),
  retirement: new BaseEnum('2', '退職'),
  transfer: new BaseEnum('3', '転勤'),
  changeJobs: new BaseEnum('4', '転職'),
  admission: new BaseEnum('5', '入学'),
  graduate: new BaseEnum('6', '卒業'),
  moveCampus: new BaseEnum('7', 'キャンパス移動'),
  returnToCountry: new BaseEnum('8', '帰国'),
  marriage: new BaseEnum('9', '結婚'),
  divorce: new BaseEnum('10', '離婚'),
  addFamily: new BaseEnum('11', '家族が増えた'),
  subtractFamily: new BaseEnum('12', '家族が減った'),
  parentsHome: new BaseEnum('13', '実家'),
  buyingHouse: new BaseEnum('14', '住宅購入'),
  changeOfPace: new BaseEnum('15', '気分転換'),
  equipmentDissatisfaction: new BaseEnum('16', '設備の不満'),
  environmentalDissatisfaction: new BaseEnum('17', '環境の不満'),
  buildingDissatisfaction: new BaseEnum('18', '建物の不満'),
  otherResidentsDissatisfaction: new BaseEnum('19', '他入居者への不満'),
  otherDissatisfaction: new BaseEnum('20', 'その他不満'),
  refusal: new BaseEnum('21', '申告拒否'),
  other: new BaseEnum('22', 'その他')
}
// 入居解約 ここまで---------------------

// 室内点検 ここから --------------------
export const RoomCheckListApplicationState = {
  all: new BaseEnum(0, 'すべて'),
  open: new BaseEnum(1, '未対応'),
  complete: new BaseEnum(2, '対応済'),
  inProgress: new BaseEnum(3, '対応中')
}
// 室内点検 ここまで --------------------

// 駐輪ステッカー ここから --------------------
export const BicycleParkingStickerApplicationState = {
  all: new BaseEnum(0, 'すべて'),
  open: new BaseEnum(1, '未対応'),
  inProgress: new BaseEnum(2, '対応中'),
  complete: new BaseEnum(3, '対応済')
}

export const BicycleParkingStickerReason = {
  new: new BaseEnum('1', '新規'),
  change: new BaseEnum('2', '買い替え'),
  add: new BaseEnum('3', '追加')
}
// 駐輪ステッカー ここまで--------------------

// 駐車場保管場所使用承諾証明書系 ここから --------------------
export const CertificateOfConsentToUseParkingSpaceState = {
  all: new BaseEnum(0, 'すべて'),
  open: new BaseEnum(1, '未対応'),
  inProgress: new BaseEnum(2, '対応中'),
  complete: new BaseEnum(3, '対応済')
}

export const ParkingSpacePattern = {
  ParkingInTheBuilding: new BaseEnum('1', '建物内駐車場'),
  NearbyParking: new BaseEnum('2', '近隣駐車場')
}
// 駐車場保管場所使用承諾証明書ここまで--------------------

// Pet申請系 ここから --------------------
export const PetApplicationState = {
  all: new BaseEnum(0, 'すべて'),
  open: new BaseEnum(1, '未対応'),
  inProgress: new BaseEnum(2, '対応中'),
  complete: new BaseEnum(3, '対応済')
}

export const PetApplicationCategory = {
  largeDog: new BaseEnum('1', '大型犬'),
  smallDog: new BaseEnum('2', '小型犬'),
  cat: new BaseEnum('3', '猫'),
  bird: new BaseEnum('4', '鳥'),
  smallAnimal: new BaseEnum('5', '小動物'),
  other: new BaseEnum('6', 'その他')
}

export const PetApplicationType = {
  new: new BaseEnum('1', '新規'),
  add: new BaseEnum('2', '追加')
}

export const PetGender = {
  male: new BaseEnum('1', 'オス'),
  female: new BaseEnum('2', 'メス'),
  unknown: new BaseEnum('9', '不明')
}

export const PetSpay = {
  notSpayed: new BaseEnum('1', '未'),
  spayed: new BaseEnum('2', '済'),
  unknown: new BaseEnum('9', '不明')
}
// Pet申請系 ここまで--------------------

export const AppUsage = {
  none: new BaseEnum(1, '未使用'),
  enabled: new BaseEnum(2, '使用中'),
  disabled: new BaseEnum(3, '無効'),
  ineligible: new BaseEnum(4, '対象外')
}

export const ContractKykStatusDiv = {
  application: new BaseEnum(1, '申込'),
  contract: new BaseEnum(2, '契約中'),
  cancellation: new BaseEnum(3, '解約予定'),
  completed: new BaseEnum(4, '解約完了'),
  payOff: new BaseEnum(5, '精算完了'),
  cancel: new BaseEnum(99, 'キャンセル')
}

export const PackageEnabled = {
  all: new BaseEnum(0, 'すべて'),
  enabled: new BaseEnum(1, '利用中'),
  disabled: new BaseEnum(2, '未使用')
}

export const FaqManagementState = {
  all: new BaseEnum(0, 'すべて'),
  liested: new BaseEnum(1, '掲載中'),
  draft: new BaseEnum(2, '下書き')
}

export const HyaTysCd = {
  none: new BaseEnum(0, '--'),
  house: new BaseEnum(1, '住宅'),
  financeCorporation: new BaseEnum(2, '公庫'),
  parking: new BaseEnum(3, '駐車場'),
  business: new BaseEnum(4, '事業'),
  specialPreferentialRent: new BaseEnum(5, '特優賃')
}

export const BulkDeliveryState = {
  all: new BaseEnum(0, 'すべて'),
  reserved: new BaseEnum(1, '予約'),
  sent: new BaseEnum(2, '送信済'),
  draft: new BaseEnum(3, '下書き'),
  error: new BaseEnum(4, '失敗')
}

export const Format = {
  csv: new BaseEnum(1, 'CSV'),
  search: new BaseEnum(2, '検索')
}

export const CompanyGroupRemark = {
  adminUser: new BaseEnum(1, '※全機能利用可'),
  generalUser: new BaseEnum(2, '※マスタ設定以外利用可'),
  readUser: new BaseEnum(3, '※閲覧のみ'),
  supportCenterAdminUser: new BaseEnum(
    4,
    '※マスタ設定以外利用可能で、サポートセンター(一般)の利用者設定をご利用いただけます。'
  ),
  externalUser: new BaseEnum(5, '※手配案件のみ利用可'),
  supportCenterGeneralUser: new BaseEnum(
    6,
    '※マスタ設定以外利用可能で、CSV出力ができない制御がかかっています。'
  )
}

export const CustomerHistoryCategory = {
  all: new BaseEnum(0, 'すべて'),
  chat: new BaseEnum(1, 'チャット'),
  application: new BaseEnum(2, '各種申請'),
  other: new BaseEnum(3, 'その他')
}

export const ProjectStatus = {
  none: new BaseEnum(0, ''),
  order: new BaseEnum(1, '依頼中'),
  preparingSupported: new BaseEnum(2, '対応準備中'),
  supported: new BaseEnum(3, '対応中'),
  supportedComplete: new BaseEnum(4, '対応完了'),
  suspension: new BaseEnum(5, '中止 or 手配解除')
}

export const OwnerState = {
  all: new BaseEnum(0, 'すべて'),
  enabled: new BaseEnum(1, '有効'),
  disabled: new BaseEnum(2, '無効')
}

export const OwnerDocumentCategory = {
  balanceReport: new BaseEnum(1, '収支報告書'),
  quotation: new BaseEnum(2, '見積書'),
  contract: new BaseEnum(3, '契約書'),
  other: new BaseEnum(4, 'その他')
}

export const MessageCategoryType = {
  customer: new BaseEnum(1, '顧客'),
  owner: new BaseEnum(2, 'オーナー')
}

export const ContactTimeType = {
  daytime: new BaseEnum(1, '日中連絡先'),
  nighttime: new BaseEnum(2, '夜間連絡先'),
  emergency: new BaseEnum(3, '緊急連絡先'),
  contact: new BaseEnum(4, '連絡先'),
  other: new BaseEnum(5, 'その他')
}

export const ContactWayType = {
  chat: new BaseEnum(1, 'チャット'),
  phone: new BaseEnum(2, '電話'),
  web: new BaseEnum(3, 'WEB')
}

export const MembersFlag = {
  nonMember: new BaseEnum(0, '非会員'),
  member: new BaseEnum(1, '会員')
}

// screenのURLを定義
export const ScreenUrl = {
  messageList: '/message/list/',
  ownerMessageList: '/ownerMessage/list/',
  bulletinBoardList: '/bulletin-board/list/',
  bulletinBoardEdit: '/bulletin-board/edit/',
  applicationResidentInfoChangeList: '/application/residentInfoChange/list/',
  applicationResidentCancellationList:
    '/application/residentCancellation/list/',
  applicationRoomCheckListList: '/application/roomCheckList/list/',
  applicationBicycleParkingStickerList:
    '/application/bicycleParkingSticker/list/',
  applicationCOC2UseParkingList: '/application/cOC2UseParking/list/',
  applicationPetList: '/application/pet/list/',
  managementBuildingList: '/management/building/list/',
  managementContractList: '/management/contract/list/',
  managementFaqList: '/management/faq/list/',
  managementMessageTemplateList: '/management/message-template/list/',
  settingCompanyUserList: '/setting/company-user/list/',
  settingBuildingList: '/setting/building/list/'
}
