import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Left from './Left'
import RightColumn from '../../../../components/rightColumn/rightColumn'
import { withSnackbar } from 'notistack'
import { QueryHelper } from 'utils/api.utils'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  leftBottom: {
    backgroundColor: '#fff'
  },
  right: {
    width: '400px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function DetailScreen(props) {
  const classes = useStyles()
  const importScreen = { kinds: 'apply', display: 'cancellationDetails' }
  const { id } = useParams()
  const [
    residentCancellationApplication,
    setResidentCancellationApplication
  ] = useState({})
  const [companyUserList, setCompanyUserList] = useState({})

  const [isLoading, setIsLoading] = useState({})

  const fetchResidentCancellationApplication = async id => {
    const params = {
      id: { eq: id }
    }
    const result = await QueryHelper('getResidentCancellationApplication', {
      filter: params
    })
    if (!result.error) {
      setResidentCancellationApplication(result)
    } else {
      props.enqueueSnackbar(`入居者解約申請を取得できませんでした。`, {
        variant: 'error'
      })
    }
  }

  async function fetchCompanyUserList(offset, limit) {
    // 右カラムの表示で使用するためcontainsで削除を取得する
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper('getCompanyUserList', {
      filter: filter,
      offset: offset,
      limit: limit
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCompanyUserList(result.data)
    }
  }

  const fetchData = async applicationId => {
    setIsLoading(true)
    await Promise.all([
      fetchResidentCancellationApplication(applicationId),
      fetchCompanyUserList(0, null)
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id)
    }
  }, [setResidentCancellationApplication, id])

  return (
    <>
      <div className={classes.container}>
        <div className={classes.left}>
          <div className={classes.leftBody}>
            <Left
              isLoading={isLoading}
              application={residentCancellationApplication.application}
              fetchResidentCancellationApplication={
                fetchResidentCancellationApplication
              }
            />
          </div>
          <div className={classes.leftBottom}></div>
        </div>
        <div className={classes.right}>
          <div className={classes.rightBody}>
            <RightColumn
              isLoading={isLoading}
              companyUserList={companyUserList}
              fetchData={fetchData}
              residentInfoChangeApplication={residentCancellationApplication}
              display={importScreen}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(DetailScreen)))
