import React from 'react'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import RateReviewIcon from '@material-ui/icons/RateReview'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import HelpIcon from '@material-ui/icons/Help'
import IconWithText from 'components/common/IconWithText'
import DateLabel from 'components/common/DateLabel'
import { ResidentInfoChangeApplicationState } from 'utils/enum'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  name: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  arrangementImg: {
    position: 'relative',
    top: 3,
    width: 30,
    height: 'auto',
    alignItems: 'center'
  }
}))

const columns = [
  { id: 'application_id', label: 'ID', minWidth: 100, width: 100 },
  { id: 'application_status', label: '状態', minWidth: 80, width: 80 },
  {
    id: 'application_start_date',
    label: '申請日時',
    minWidth: 120,
    width: 120
  },
  { id: 'applicant_name', label: '入居者名', minWidth: 170 },
  { id: 'building_name', label: '物件名', minWidth: 170 },
  { id: 'resident_info_change_content', label: '変更内容', minWidth: 170 },
  { id: 'arrangement', label: '手配', minWidth: 60, width: 60 },
  { id: 'assigned_user', label: '担当者', minWidth: 170 }
]

function CellWithStatus({ status }) {
  switch (status) {
    case ResidentInfoChangeApplicationState.open.code:
      return (
        <IconWithText
          text={ResidentInfoChangeApplicationState.open.value}
          icon={<NewReleasesIcon />}
          color={'error'}
        />
      )
    case ResidentInfoChangeApplicationState.inProgress.code:
      return (
        <IconWithText
          text={ResidentInfoChangeApplicationState.inProgress.value}
          icon={<RateReviewIcon />}
          color={'c_thirdly'}
        />
      )
    case ResidentInfoChangeApplicationState.complete.code:
      return (
        <IconWithText
          text={ResidentInfoChangeApplicationState.complete.value}
          icon={<BeenhereIcon />}
          color={'c_thirdly'}
        />
      )
    default:
      return <IconWithText text="不明" icon={<HelpIcon />} color="primary" />
  }
}

function CellWithResidentInfoChangeContent({ json }) {
  let parseValue
  try {
    parseValue = JSON.parse(json)
  } catch (e) {
    return { e }
  }
  let changeContent = ''
  if ('diff' in parseValue) {
    if ('resident' in parseValue.diff) {
      const resident = parseValue.diff.resident
      if (resident.name) {
        changeContent += '入居者名、'
      }
      if (resident.nameKana) {
        changeContent += '入居者名カナ、'
      }
      if (resident.birthDay) {
        changeContent += '入居者誕生日、'
      }
      if (resident.tel) {
        changeContent += '入居者名電話番号、'
      }
      if (resident.jobName) {
        changeContent += '入居者仕事名、'
      }
    }
    if ('company' in parseValue.diff) {
      const company = parseValue.diff.company
      if (company.name) {
        changeContent += '会社名、'
      }
      if (company.tel) {
        changeContent += '会社電話番号、'
      }
    }
    if ('emergencyContact' in parseValue.diff) {
      const emergencyContact = parseValue.diff.emergencyContact
      if (emergencyContact.name) {
        changeContent += ' 緊急連絡先氏名、'
      }
      if (emergencyContact.nameKana) {
        changeContent += '緊急連絡先氏名カナ、'
      }
      if (emergencyContact.tel) {
        changeContent += '緊急連絡先固定電話、'
      }
      if (emergencyContact.phoneTel) {
        changeContent += '緊急連絡先携帯電話、'
      }
    }
  } else if ('changes' in parseValue) {
    if ('resident' in parseValue.changes) {
      const resident = parseValue.changes.resident
      if (resident.name) {
        changeContent += '入居者名、'
      }
      if (resident.nameKana) {
        changeContent += '入居者名カナ、'
      }
      if (resident.birthDay) {
        changeContent += '入居者誕生日、'
      }
      if (resident.tel) {
        changeContent += '入居者名電話番号、'
      }
      if (resident.jobName) {
        changeContent += '入居者仕事名、'
      }
    }
    if ('company' in parseValue.changes) {
      const company = parseValue.changes.company
      if (company.name) {
        changeContent += '会社名、'
      }
      if (company.tel) {
        changeContent += '会社電話番号、'
      }
    }
    if ('emergencyContact' in parseValue.changes) {
      const emergencyContact = parseValue.changes.emergencyContact
      if (emergencyContact.name) {
        changeContent += ' 緊急連絡先氏名、'
      }
      if (emergencyContact.nameKana) {
        changeContent += '緊急連絡先氏名カナ、'
      }
      if (emergencyContact.tel) {
        changeContent += '緊急連絡先固定電話、'
      }
      if (emergencyContact.phoneTel) {
        changeContent += '緊急連絡先携帯電話、'
      }
    }
  } else {
    return <></>
  }
  if (changeContent.length !== 0) {
    changeContent = changeContent.slice(0, -1)
  }
  return <>{changeContent}</>
}

function List(props) {
  const classes = useStyles()
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const { searchCondition } = props

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/application/residentInfoChange/detail/${id}`,
      e,
      history,
      searchCondition
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.residentInfoChangeApplicationList.length
            ? props.residentInfoChangeApplicationList.map(row => {
                return (
                  <TableRow
                    onClick={e => navigateToDetailPage(e, row.application.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.application.id}
                  >
                    <TableCell>{row.application.id}</TableCell>
                    <TableCell>
                      <CellWithStatus
                        status={row.application.application_state_id}
                      />
                    </TableCell>
                    <TableCell>
                      <DateLabel timestamp={row.application.created_at} />
                    </TableCell>
                    <TableCell>
                      {/* {row.mst_kokyaku_nky.KKY_NAME} */}
                      <Grid container direction="column">
                        <Grid item className={classes.name}>
                          <span>{row.mst_kokyaku_nky.KKY_NAME}</span>
                        </Grid>
                        <Grid item className={classes.nameKana}>
                          <span>{row.mst_kokyaku_nky.KKY_NAME_KANA}</span>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {/* {row.mst_tateya.TTY_NAME} */}
                      <Grid container direction="column">
                        <Grid item className={classes.name}>
                          <span>{row.mst_tateya.TTY_NAME}</span>
                        </Grid>
                        <Grid item className={classes.name}>
                          <span>{row.mst_heya.HYA_ROOM_NO}</span>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <CellWithResidentInfoChangeContent
                        json={row.application.content}
                      />
                    </TableCell>
                    <TableCell>
                      {row.is_arranged &&
                        row.arrangement_status !== 'CANCEL_OR_RELEASE' && (
                          <img
                            className={classes.arrangementImg}
                            src={`${process.env.PUBLIC_URL}/wrench-check.svg`}
                            alt={'wrenchCheck'}
                          />
                        )}
                    </TableCell>
                    <TableCell>
                      <Grid item className={classes.name}>
                        <span>
                          {row.assigned_user_group &&
                            getFormattedNameByStatus(
                              row.assigned_user_group.name,
                              row.assigned_user_group.status
                            )}
                        </span>
                      </Grid>
                      <Grid item className={classes.name}>
                        <span>
                          {row.assigned_user &&
                            getFormattedNameByStatus(
                              row.assigned_user.name,
                              row.assigned_user.status
                            )}
                        </span>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default List
