import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Footer from './Footer'
import SelectTabs from '../../../components/General/SelectTabs'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { Controller } from 'react-hook-form'
import { useCompanyNotificationCategoryForm } from '../../../hooks/useCompanyNotificationCategoryForm'
import { useCompanyNotificationApplicationTypeForm } from '../../../hooks/useCompanyNotificationApplicationTypeForm'
import Loading from 'components/common/Loading'
import { withSnackbar } from 'notistack'
import { NoticeMessages } from 'utils/noticeMessages'
import { ErrorMessages } from 'utils/errorMessages'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(() => ({
  wrapBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '32px'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  message: {
    fontSize: '12px',
    marginBottom: '8px'
  }
}))

function EditScreen(props) {
  const classes = useStyles()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const [isLoading, setIsLoading] = useState(false)
  const [categoryTabs, setCategoriesTabs] = useState()
  const [applicationTabs, setApplicationsTabs] = useState()
  const {
    handleSubmit: handleSubmitCategory,
    reset: resetCategory,
    control: controlCategory,
    getValues: getCategoryValus
  } = useCompanyNotificationCategoryForm()
  const {
    handleSubmit: handleSubmitApplicationType,
    reset: resetApplicationType,
    control: controlApplicationType,
    getValues: getApplicationTypeValues
  } = useCompanyNotificationApplicationTypeForm()
  const [SelectedCategoryTab, setSelectedCategoryTab] = useState(0)
  const [SelectedApplicationTypeTab, setSelectedApplicationTypeTab] = useState(
    0
  )

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([getTabs()])
    setIsLoading(false)
  }

  const getTabs = async () => {
    let categoryTabs = []
    let applicationTabs = []

    const resultCategory = await QueryHelper(
      'getLargeCategoryAndMediumCategory',
      {},
      true
    )
    if (!resultCategory.error) {
      resultCategory.map(({ large_categories }) => {
        categoryTabs.push({
          value: large_categories.id,
          label: large_categories.name
        })
      })
    } else {
      props.enqueueSnackbar(ErrorMessages.FailedToRetrieveCategory)
    }

    const resultApplicationType = await QueryHelper(
      'getApplicationType',
      {},
      true
    )
    if (!resultApplicationType.error) {
      resultApplicationType.application_types.map(application_type => {
        if (application_type.status === 1) {
          applicationTabs.push({
            value: application_type.id,
            label: application_type.name
          })
        }
      })
    } else {
      props.enqueueSnackbar(ErrorMessages.FailedToRetrieveCategory)
    }

    const resultCompanyNotificationSettings = await QueryHelper(
      'getCompanyNotificationSettings',
      {},
      true
    )
    if (!resultCompanyNotificationSettings.error) {
      setCategoriesTabs(() => {
        return (
          <SelectTabs
            title={'メッセージチャット '}
            subTitle={'※各最大5件'}
            labels={categoryTabs}
            handleChange={(selectTab, beforeSelectedTab) => {
              let data = getCategoryValus(`category.[${beforeSelectedTab}]`)
              let result = []
              for (const [, value] of Object.entries(data)) {
                result.push(value)
              }

              if (
                result.filter(Boolean).toString() ==
                categoryTabs[beforeSelectedTab].mail_address
                  .filter(Boolean)
                  .toString()
              ) {
                setSelectedCategoryTab(selectTab)
                resetCategory()
                return true
              }

              if (!window.confirm(NoticeMessages.DiscardContentBeingEdited)) {
                return false
              }

              setSelectedCategoryTab(selectTab)
              resetCategory()
              return true
            }}
          >
            {categoryTabs.map((category, index) => {
              let result = resultCompanyNotificationSettings.categories.map(
                data => {
                  if (category.value === data.category_id) {
                    Object.assign(categoryTabs[index], {
                      mail_address: data.mail_address
                    })
                    return (
                      <div key={data.category_id}>
                        <div>{getCategoryMailAddressList(data, index)}</div>
                        <div>
                          <Footer handleCancel={() => resetCategory()} />
                        </div>
                      </div>
                    )
                  }
                }
              )
              result = result.filter(Boolean)
              if (!result.length) {
                Object.assign(categoryTabs[index], {
                  mail_address: []
                })
                return (
                  <div key={category.category_id}>
                    <div>
                      {getCategoryMailAddressList(
                        {
                          category_id: category.value,
                          mail_address: []
                        },
                        index
                      )}
                    </div>
                    <div>
                      <Footer handleCancel={() => resetCategory()} />
                    </div>
                  </div>
                )
              } else {
                return result
              }
            })}
          </SelectTabs>
        )
      })

      setApplicationsTabs(() => {
        return (
          <SelectTabs
            title={'各種申請 '}
            subTitle={'※各最大5件'}
            labels={applicationTabs}
            handleChange={(selectTab, beforeSelectedTab) => {
              let data = getApplicationTypeValues(
                `application_type.[${beforeSelectedTab}]`
              )
              let result = []
              for (const [, value] of Object.entries(data)) {
                result.push(value)
              }

              if (
                result.filter(Boolean).toString() ==
                applicationTabs[beforeSelectedTab].mail_address
                  .filter(Boolean)
                  .toString()
              ) {
                setSelectedApplicationTypeTab(selectTab)
                resetApplicationType()
                return true
              }

              if (!window.confirm(NoticeMessages.DiscardContentBeingEdited)) {
                return false
              }

              setSelectedApplicationTypeTab(selectTab)
              resetApplicationType()
              return true
            }}
          >
            {applicationTabs.map((application, index) => {
              let result = resultCompanyNotificationSettings.applications.map(
                data => {
                  if (application.value === data.application_type_id) {
                    Object.assign(applicationTabs[index], {
                      mail_address: data.mail_address
                    })
                    return (
                      <div key={data.application_type_id}>
                        <div>
                          {getApplicationTypeMailAddressList(data, index)}
                        </div>
                        <div>
                          <Footer handleCancel={() => resetApplicationType()} />
                        </div>
                      </div>
                    )
                  }
                }
              )
              result = result.filter(Boolean)
              if (!result.length) {
                Object.assign(applicationTabs[index], {
                  mail_address: []
                })
                return (
                  <div key={application.application_type_id}>
                    <div>
                      {getApplicationTypeMailAddressList(
                        {
                          application_type_id: application.value,
                          mail_address: []
                        },
                        index
                      )}
                    </div>
                    <div>
                      <Footer handleCancel={() => resetApplicationType()} />
                    </div>
                  </div>
                )
              } else {
                return result
              }
            })}
          </SelectTabs>
        )
      })
    } else {
      props.enqueueSnackbar(`メール通知設定を取得できませんでした。`)
    }
  }

  const getCategoryMailAddressList = (data, index) => {
    const result = [...Array(5)].map((value, arrayIndex) => {
      return (
        <Grid item xs={3} key>
          <Controller
            name={`category.[${index}].mail_address${arrayIndex + 1}`}
            control={controlCategory}
            defaultValue={
              data.mail_address[arrayIndex] ? data.mail_address[arrayIndex] : ''
            }
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                placeholder={'***@example.jp'}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error ? fieldState.error.message : null}
                size="small"
                disabled={!isUnrestrictedRole}
              />
            )}
          />
        </Grid>
      )
    })

    return (
      <Grid container spacing={2} key>
        {result}
      </Grid>
    )
  }

  const getApplicationTypeMailAddressList = (data, index) => {
    const result = [...Array(5)].map((value, arrayIndex) => {
      return (
        <Grid item xs={3} key>
          <Controller
            name={`application_type.[${index}].mail_address${arrayIndex + 1}`}
            control={controlApplicationType}
            defaultValue={
              data.mail_address[arrayIndex] ? data.mail_address[arrayIndex] : ''
            }
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                placeholder={'***@example.jp'}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error ? fieldState.error.message : null}
                size="small"
                disabled={!isUnrestrictedRole}
              />
            )}
          />
        </Grid>
      )
    })

    return (
      <Grid container spacing={2} key>
        {result}
      </Grid>
    )
  }

  const registerCategoryCompanyNotification = async data => {
    const select_category = categoryTabs.props.labels[SelectedCategoryTab]
    const params = {
      category_id: 0,
      mail_address: []
    }

    for (const [, value] of Object.entries(
      data.category[SelectedCategoryTab]
    )) {
      params.category_id = select_category.value
      if (value) {
        params.mail_address.push(value)
      }
    }

    createCompanyNotificationSettings(params, select_category)
  }

  const registerApplicationTypeCompanyNotification = data => {
    const select_application_type =
      applicationTabs.props.labels[SelectedApplicationTypeTab]
    const params = {
      application_type_id: 0,
      mail_address: []
    }

    for (const [, value] of Object.entries(
      data.application_type[SelectedApplicationTypeTab]
    )) {
      params.application_type_id = select_application_type.value
      if (value) {
        params.mail_address.push(value)
      }
    }

    createCompanyNotificationSettings(params, select_application_type)
  }

  const createCompanyNotificationSettings = async (params, selectData) => {
    let checkMailAddress = false
    // メールアドレス重複チェック
    params.mail_address.forEach(mail_address => {
      const firstIndex = params.mail_address.indexOf(mail_address)
      const lastIndex = params.mail_address.lastIndexOf(mail_address)
      if (firstIndex !== lastIndex) {
        checkMailAddress = true
      }
    })

    if (selectData.mail_address == params.mail_address.toString()) {
      props.enqueueSnackbar(NoticeMessages.NoChange, {
        variant: 'warning'
      })
    } else if (checkMailAddress) {
      props.enqueueSnackbar('メールアドレスが重複しています', {
        variant: 'error'
      })
    } else {
      const result = await MutationHelper('createCompanyNotificationSettings', {
        input: params
      })
      if (result.error) {
        props.enqueueSnackbar('メール通知先を更新できませんでした。', {
          variant: 'error'
        })
      } else {
        props.enqueueSnackbar('メール通知先を更新しました。', {
          variant: 'success'
        })
        fetchData()
      }
    }
  }

  return (
    <div className={classes.wrapBody}>
      <Typography className={classes.title}>
        通知先メールアドレス設定
      </Typography>
      <Typography className={classes.message}>
        設定されたメールアドレスに受信通知が届きます。
      </Typography>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <form
            onSubmit={handleSubmitCategory(registerCategoryCompanyNotification)}
            className={classes.left}
          >
            {categoryTabs}
          </form>
          <form
            onSubmit={handleSubmitApplicationType(
              registerApplicationTypeCompanyNotification
            )}
            className={classes.left}
          >
            {applicationTabs}
          </form>
        </>
      )}
    </div>
  )
}

export default withSnackbar(EditScreen)
