import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import { PREFECTURE_LIST, STOCK_SIGNAGE_LIST } from 'utils/const'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

function CompanyForm(props) {
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const { isLoading, company, setEnableForm } = props
  const handleChange = event => {
    props.handleChange({
      [event.target.name]: event.target.value
    })
  }

  const [values, setValues] = useState({
    stock_signage: '',
    stock_signage_err: '',
    name: '',
    name_err: '',
    name_kana: '',
    name_kana_err: '',
    zip_code: '',
    zip_code_err: '',
    prefecture_id: '',
    prefecture_id_err: '',
    tel: '',
    tel_err: ''
  })

  const checkErr = objects => {
    if (
      objects.stock_signage_err ||
      objects.name_err ||
      objects.name_kana_err ||
      objects.zip_code_err ||
      objects.prefecture_id_err ||
      objects.tel_err
    ) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const isKana = str => {
    var reg = new RegExp(/^[\u30a0-\u30ff]+$/)
    var res = reg.test(str)
    return res
  }

  const isNumber = str => {
    var reg = new RegExp(/^[0-9]*$/)
    var res = reg.test(str)
    return res
  }

  const handleSelectRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '選択してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputRequiredKanaChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      if (isKana(value)) {
        newValues[name + '_err'] = ''
      } else {
        newValues[name + '_err'] = 'カタカナで入力してください'
      }
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const handleInputPhoneChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      if (isNumber(value)) {
        newValues[name + '_err'] = ''
      } else {
        newValues[name + '_err'] = '半角数字で入力してください'
      }
    } else {
      newValues[name + '_err'] = ''
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    const initializeValues = {
      stock_signage:
        'company' in company && company.company
          ? company.company.stock_signage
          : '',
      stock_signage_err:
        'company' in company && company.company
          ? company.company.stock_signage
            ? ''
            : '選択してください'
          : '選択してください',
      name: 'company' in company && company.company ? company.company.name : '',
      name_err:
        'company' in company && company.company
          ? company.company.name
            ? ''
            : '入力してください'
          : '入力してください',
      name_kana:
        'company' in company && company.company
          ? company.company.name_kana
          : '',
      name_kana_err:
        'company' in company && company.company
          ? isKana(company.company.name_kana)
            ? ''
            : 'カタカナで入力してください'
          : '入力してください',
      zip_code:
        'company' in company && company.company ? company.company.zip_code : '',
      zip_code_err:
        'company' in company && company.company
          ? company.company.zip_code
            ? ''
            : '入力してください'
          : '入力してください',
      prefecture_id:
        'company' in company && company.company
          ? company.company.prefecture_id
          : '',
      prefecture_id_err:
        'company' in company && company.company
          ? company.company.prefecture_id
            ? ''
            : '選択してください'
          : '選択してください',
      tel: 'company' in company && company.company ? company.company.tel : '',
      tel_err:
        'company' in company && company.company
          ? isNumber(company.company.tel)
            ? ''
            : '半角数字で入力してください'
          : ''
    }
    setValues(initializeValues)
    checkErr(initializeValues)
  }, [company, setValues])

  return (
    <FormLayout title="会社情報">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Dropdown
                error={values.stock_signage_err != '' ? true : false}
                helperText={values.stock_signage_err}
                required
                name="stock_signage"
                label={'株式会社表記'}
                onChange={handleSelectRequiredChange}
                defaultValue={values.stock_signage}
                items={STOCK_SIGNAGE_LIST}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
            <Grid item xs={9}>
              <TextInput
                error={values.name_err != '' ? true : false}
                helperText={values.name_err}
                required
                label="管理会社名"
                id="name"
                name="name"
                fullWidth
                variant="filled"
                defaultValue={values.name}
                onChange={handleInputRequiredChange}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                error={values.name_kana_err != '' ? true : false}
                helperText={values.name_kana_err}
                required
                id="name_kana"
                name="name_kana"
                label="フリガナ"
                fullWidth
                variant="filled"
                defaultValue={values.name_kana}
                onChange={handleInputRequiredKanaChange}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                error={values.zip_code_err != '' ? true : false}
                helperText={values.zip_code_err}
                required
                id="zip_code"
                name="zip_code"
                label="郵便番号"
                fullWidth
                variant="filled"
                defaultValue={values.zip_code}
                onChange={handleInputRequiredChange}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                error={values.prefecture_id_err != '' ? true : false}
                helperText={values.prefecture_id_err}
                required
                name="prefecture_id"
                label={'都道府県'}
                onChange={handleSelectRequiredChange}
                defaultValue={values.prefecture_id}
                items={PREFECTURE_LIST}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="address"
                name="address"
                label="市区町村以下住所"
                fullWidth
                variant="filled"
                defaultValue={
                  'company' in company && company.company
                    ? company.company.address
                    : ''
                }
                onChange={handleChange}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                error={values.tel_err != '' ? true : false}
                helperText={values.tel_err}
                id="tel"
                name="tel"
                label="電話番号"
                fullWidth
                variant="filled"
                defaultValue={values.tel}
                onChange={handleInputPhoneChange}
                disabled={!isUnrestrictedRole}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm)
