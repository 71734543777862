import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import ImageForm from './ImageForm'
import FaqForm from './FaqForm'
import FileForm from './FileForm'
import VideoForm from './VideoForm'
import CategoryForm from './CategoryForm'

const useStyle = makeStyles({
  formRow: {
    flex: 1,
    '&:nth-child(n+2)': {
      marginTop: 20
    }
  }
})

function Main(props) {
  const classes = useStyle()

  const { isLoading, faq, faqAttachment, checkError, setCheckError } = props
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div className={classes.formRow}>
        <CategoryForm isLoading={isLoading} faq={faq} />
      </div>
      <div className={classes.formRow}>
        <FaqForm
          isLoading={isLoading}
          faq={faq}
          checkError={checkError}
          setCheckError={setCheckError}
        />
      </div>
      <div className={classes.formRow}>
        <ImageForm isLoading={isLoading} faqAttachment={faqAttachment} />
      </div>
      <div className={classes.formRow}>
        <VideoForm
          isLoading={isLoading}
          type="video"
          label="動画"
          faqAttachment={faqAttachment}
        />
      </div>
      <div className={classes.formRow}>
        <FileForm
          isLoading={isLoading}
          type="pdf"
          label="PDF"
          faqAttachment={faqAttachment}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
