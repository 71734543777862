import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    height: '68px',
    alignItems: 'center'
  },
  left: {
    flex: 1,
    display: 'flex',
    marginLeft: '24px'
  },
  body: {},
  right: {
    marginLeft: '16px',
    marginRight: '32px'
  },
  primaryButton: {
    color: theme.palette.c_blue.contrastText,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  tertiaryButton: {
    color: theme.palette.c_gray.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

function Footer(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { packageData, handleDelete } = props

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {'package' in packageData && (
          <Button
            className={classes.tertiaryButton}
            startIcon={<DeleteIcon />}
            onClick={handleDelete}
            disabled={!isUnrestrictedRole}
          >
            削除
          </Button>
        )}
      </div>
      <div className={classes.body}>
        <Button
          variant="contained"
          className={classes.secondaryButton}
          onClick={props.handleCancel}
          disabled={!isUnrestrictedRole}
        >
          キャンセル
        </Button>
      </div>
      <div className={classes.right}>
        <Button
          variant="contained"
          type="submit"
          className={classes.primaryButton}
          disabled={!isUnrestrictedRole}
        >
          保存
        </Button>
      </div>
    </div>
  )
}

export default Footer
