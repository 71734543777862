import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import CategoryList from './CategoryList'
import MessageTemplateList from './MessageTemplateList'
import Loading from 'components/common/Loading'
import { useLoginInformation } from 'hooks/useLoginInformation'

const EXTERNAL_USER = 5

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    width: '350px',
    height: '54px',
    padding: '15px 0px 15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  }
}))

function MessageTemplateInfo(props) {
  const classes = useStyles()

  const thread = props && props.thread ? props.thread : null
  const [categories, setCategories] = useState(null)
  const [selectedMediumCategory, setSelectedMediumCategory] = useState(null)
  const [messageTemplateList, setMessageTemplateList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { judgmentRoleId } = useLoginInformation()

  async function fetchMessageTemplateList() {
    const result = await QueryHelper(
      'getMessageTemplateList',
      { limit: 0 },
      0,
      null
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar(
        'メッセージテンプレート一覧情報を取得できませんでした',
        {
          variant: 'error'
        }
      )
    } else {
      setMessageTemplateList(result.data)
    }
  }

  async function fetchLargeCategoriesAndMediumCategories() {
    if (!thread) {
      return
    }
    const result = await QueryHelper(
      'getLargeCategoryAndMediumCategory',
      {},
      true
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('カテゴリ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCategories(result)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([
      messageTemplateList ? '' : fetchMessageTemplateList(),
      categories ? '' : fetchLargeCategoriesAndMediumCategories()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [thread, setMessageTemplateList, setCategories])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={9} alignItems="center" spacing={3}>
              <Typography className={classes.title}>
                {'定型文リスト'}
              </Typography>
            </Grid>
            {judgmentRoleId() !== EXTERNAL_USER && (
              <IconButton
                aria-label="new"
                onClick={() =>
                  window.open(
                    `${window.location.origin}/management/message-template`
                  )
                }
              >
                <OpenInNewIcon />
              </IconButton>
            )}
          </Grid>
        </div>
        <Divider />
        {isLoading ? (
          <Loading isLoading={isLoading} marginTop={10} />
        ) : (
          <>
            {selectedMediumCategory ? (
              <MessageTemplateList
                messageTemplateList={messageTemplateList}
                mediumCategory={selectedMediumCategory}
                handleSelectedMediumCategory={setSelectedMediumCategory}
                joinBody={props.joinBody}
              />
            ) : (
              <CategoryList
                categories={categories}
                handleSelectedMediumCategory={setSelectedMediumCategory}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default withSnackbar(withRouter(MessageTemplateInfo))
