import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Customer from './Customer'
import CustomerHistory from './CustomerHistory'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RadioButtons from 'components/common/RadioButtons'
import { CustomerHistoryCategory } from 'utils/enum'
import CustomerHistoryThreadDialogForm from './CustomerHistoryThreadDialogForm'
import Paper from '@material-ui/core/Paper'
import CommonChip from 'components/common/CommonChip'
import { fromCode } from 'utils/enum.utils'
import { QueryHelper } from 'utils/api.utils'
import { Auth, Storage } from 'aws-amplify'
import { withSnackbar } from 'notistack'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  },
  tabs: {
    width: '320px',
    height: '56px',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    '& .Mui-selected': {
      color: theme.palette.c_blue.main
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.c_blue.main
    }
  },
  tabTitle: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: '600',
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  buttonWrapper: {
    display: 'flex'
  },
  createButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  csvButton: {
    marginLeft: '16px',
    marginRight: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  button: {
    whiteSpace: 'nowrap',
    padding: '5px 30px'
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5)
  }
}))

const buttonsData = [
  {
    value: CustomerHistoryCategory.all.code,
    label: CustomerHistoryCategory.all.value
  },
  {
    value: CustomerHistoryCategory.chat.code,
    label: CustomerHistoryCategory.chat.value
  },
  {
    value: CustomerHistoryCategory.application.code,
    label: CustomerHistoryCategory.application.value
  },
  {
    value: CustomerHistoryCategory.other.code,
    label: CustomerHistoryCategory.other.value
  }
]

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  )
}

function Left(props) {
  const classes = useStyles()
  const { id } = useParams()
  const { role3, role6 } = useRole()
  const isRestrictedRole3 = role3()
  const isRestrictedRole6 = role6()
  const [labelName, setLabelName] = React.useState(0)
  const [isShowing, setIsShowing] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [searchCondition, setSearchCondition] = useState({
    customerHistoryTypeId: 0
  })
  const handleChangeLabel = (event, newValue) => {
    setLabelName(newValue)
    setIsShowing(newValue)
    if (newValue === '基本情報') {
      setIsShowing(false)
    } else if (newValue === '活動履歴') {
      setIsShowing(true)
    }
  }

  const handleOpen = event => {
    setIsOpen(true)
  }

  const handleClose = (event, isSaving) => {
    event.preventDefault()
    if (!isSaving) {
      if (!window.confirm(`内容が破棄されますがよろしいですか？`)) {
        return
      }
    }
    setIsOpen(false)
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchCondition, {
      customerHistoryTypeId: selectedStatus
    })
    searchCondition['customerHistoryTypeId'] = selectedStatus
    setSearchCondition(params)
  }

  const handleDeleteSearchCondition = target => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    params['customerHistoryTypeId'] = 0
    setSearchCondition(params)
  }

  const exportCsv = async () => {
    let filter = {}
    const url = window.location.href
    const urlArray = url.split('/')
    const contractId = urlArray[urlArray.length - 1]
    filter['contract_id'] = contractId
    let csvData = await QueryHelper(
      'getCustomerHistoryCsv',
      {
        filter: filter
      },
      false
    )
    if (csvData.error || csvData.companyId == null || csvData.key == null) {
      console.log(csvData.error)
      props.enqueueSnackbar('CSVのDLに必要な情報取得に失敗しました', {
        variant: 'error'
      })
      return
    }
    downloadFile(csvData)
  }

  const downloadFile = async csvData => {
    await Auth.currentCredentials().then(async creds => {
      const key = csvData.key
      Storage.get(key, {
        level: 'protected',
        identityId: csvData.companyId,
        expires: 60
      })
        .then(result => {
          const link = document.createElement('a')
          link.href = result
          link.click()
        })
        .catch(err => {
          console.log('error: ', err)
          props.enqueueSnackbar('CSVのDLに失敗しました', {
            variant: 'error'
          })
        })
    })
  }

  useEffect(() => {
    if (localStorage.getItem('display') == 'customerHistory') {
      setLabelName(1)
      setIsShowing(true)
      localStorage.removeItem('display')
    }
  }, [])

  const SearchConditionChips = () => {
    if (searchCondition == null) return
    let label = ''
    label = `種別: ${
      fromCode(
        CustomerHistoryCategory,
        Number(searchCondition['customerHistoryTypeId'])
      ).value
    }`

    return (
      <Grid item xs={12}>
        {searchCondition['customerHistoryTypeId'] != 0 && (
          <Paper component="ul" className={classes.chips}>
            <>
              {label !== '' && (
                <CommonChip
                  label={label}
                  onDelete={() =>
                    handleDeleteSearchCondition('customerHistoryTypeId')
                  }
                />
              )}
            </>
          </Paper>
        )}
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <SearchConditionChips />
      <div className={classes.top}>
        {isShowing ? (
          <Grid item xs={12}>
            <RadioButtons
              current={searchCondition['customerHistoryTypeId']}
              handleSelectedButton={handleSelectedButton}
              buttonsData={buttonsData}
            />
          </Grid>
        ) : null}

        <Grid container alignItems="center">
          <Grid xs={9}>
            <Tabs
              value={labelName}
              onChange={handleChangeLabel}
              aria-label="simple tabs example"
              className={classes.tabs}
            >
              <Tab
                label="基本情報"
                {...a11yProps(0)}
                className={classes.tabTitle}
              />
              <Tab
                label="活動履歴"
                {...a11yProps(1)}
                className={classes.tabTitle}
              />
            </Tabs>
          </Grid>
          {isShowing ? (
            <Grid items xs={3} align="right">
              <Grid className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  className={`${classes.csvButton} ${classes.button}`}
                  onClick={exportCsv}
                  disabled={isRestrictedRole6}
                >
                  CSV出力
                </Button>
                <Button
                  variant="contained"
                  className={`${classes.createButton} ${classes.button}`}
                  onClick={handleOpen}
                  disabled={isRestrictedRole3}
                >
                  新規作成
                </Button>
              </Grid>
              {isOpen && (
                <CustomerHistoryThreadDialogForm
                  open={isOpen}
                  handleClose={handleClose}
                  contractId={id}
                />
              )}
            </Grid>
          ) : null}
        </Grid>
        <TabPanel value={labelName} index={0}>
          <Customer />
        </TabPanel>
        <TabPanel value={labelName} index={1}>
          <CustomerHistory searchCondition={searchCondition} />
        </TabPanel>
      </div>
      <div className={classes.divider} />
    </div>
  )
}

export default withSnackbar(Left)
