import { WEEK_LIST } from './const'

export function formatDate({ type, value }) {
  if (type === 'hmi') return formatDateTime(value)
  if (type === 'ago') return formatDateAgo(value)
  throw new Error('Please give a type')
}

function formatDateAgo(timestamp) {
  if (!timestamp) return '不明'
  const targetDatetime = new Date(timestamp)
  const currentDatetime = new Date()

  const diff = currentDatetime - targetDatetime

  const second = 1000
  const minute = 60 * second
  const hour = 60 * minute
  const day = 24 * hour
  const month = 30 * day
  const year = 365 * day

  if (diff > year) return `${Math.trunc(diff / year)}年前`
  if (diff > month) return `${Math.trunc(diff / month)}ヶ月前`
  if (diff > day) return `${Math.trunc(diff / day)}日前`
  if (diff > hour) return `${Math.trunc(diff / hour)}時間前`
  if (diff > minute) return `${Math.trunc(diff / minute)}分前`

  let result = Math.trunc(diff / second)
  if (result < 0) {
    result = 0
  }
  return `${result}秒前`
}

function formatDateTime(timestamp) {
  const targetDatetime = new Date(timestamp)
  const date = formatTwoDigitNumber(targetDatetime.getDate())
  const month = formatTwoDigitNumber(targetDatetime.getMonth() + 1)
  const hour = formatTwoDigitNumber(targetDatetime.getHours())
  const minute = formatTwoDigitNumber(targetDatetime.getMinutes())

  return `${month}/${date} ${hour}:${minute}`
}

function formatTwoDigitNumber(num) {
  const numText = typeof num === 'string' ? num : num.toString()
  return numText.length < 2 ? '0' + numText : numText
}

export function parseDate(dateStr) {
  const splitDate = dateStr.split('/')
  // monthIndex は 0 から始まります。 つまり 1 月 = 0、 12 月 = 11 です。なので -1 する必要がある
  const month = Number(splitDate[1]) - 1
  return new Date(Number(splitDate[0]), month, Number(splitDate[2]))
}

export function formatYMD(date) {
  let format = 'yyyy年MM月dd日'
  format = format.replace(/yyyy/g, date.getFullYear())
  // monthIndex は 0 から始まります。 つまり 1 月 = 0、 12 月 = 11 です。なので +1 する必要がある
  const month = date.getMonth() + 1
  format = format.replace(/MM/g, month)
  format = format.replace(/dd/g, date.getDate())
  return format
}

export function formatYYYYMMDD(date) {
  let format = 'yyyy/MM/dd'
  format = format.replace(/yyyy/g, date.getFullYear())
  // monthIndex は 0 から始まります。 つまり 1 月 = 0、 12 月 = 11 です。なので +1 する必要がある
  const month = date.getMonth() + 1
  format = format.replace(/MM/g, ('0' + month).slice(-2))
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
  return format
}

export function formatMMDD(date) {
  let format = 'MM/dd'
  const month = date.getMonth() + 1
  format = format.replace(/MM/g, ('0' + month).slice(-2))
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
  return format
}

export function formatHyphenYYYYMMDD(date) {
  let format = 'yyyy-MM-dd'
  format = format.replace(/yyyy/g, date.getFullYear())
  // monthIndex は 0 から始まります。 つまり 1 月 = 0、 12 月 = 11 です。なので +1 する必要がある
  const month = date.getMonth() + 1
  format = format.replace(/MM/g, ('0' + month).slice(-2))
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
  return format
}

export function formatTime(timestamp) {
  const targetDatetime = new Date(timestamp)
  const hour = formatTwoDigitNumber(targetDatetime.getHours())
  const minute = formatTwoDigitNumber(targetDatetime.getMinutes())

  return `${hour}:${minute}`
}

export function formatYYYYMMDDW(date) {
  let format = 'MM/dd week'
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  if (date.getFullYear() != currentYear) {
    format = 'yyyy/MM/dd week'
    format = format.replace(/yyyy/g, date.getFullYear())
  }
  // monthIndex は 0 から始まります。 つまり 1 月 = 0、 12 月 = 11 です。なので +1 する必要がある
  const month = date.getMonth() + 1
  format = format.replace(/MM/g, ('0' + month).slice(-2))
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
  format = format.replace(/week/g, WEEK_LIST[date.getDay()])
  return format
}

export function formatJST(date) {
  date.setHours(date.getHours() + 9)
  return date.toISOString().split(/\.\d+Z/)[0] + '+09:00'
}
