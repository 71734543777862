import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImageForm from './ImageForm'
import CompanyUserForm from './CompanyUserForm'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  top: {
    width: '144px',
    height: '144px'
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '24px',
    height: '100%'
  }
}))

function Left(props) {
  const classes = useStyles()

  const { isLoading, companyUser, setEnableCompanyUserForm, isError } = props
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <ImageForm isLoading={isLoading} companyUser={companyUser} />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <CompanyUserForm
          isLoading={isLoading}
          companyUser={companyUser}
          setEnableForm={setEnableCompanyUserForm}
          isError={isError}
        />
      </div>
    </div>
  )
}

export default Left
