import React from 'react'
import { AppUsage, ContractKykStatusDiv } from 'utils/enum'
import Label from './Label'

const UseUseLabel = ({
  is_usage,
  is_management,
  app_user_id,
  kykStatusDiv
}) => {
  if (
    kykStatusDiv === ContractKykStatusDiv.completed.code ||
    kykStatusDiv === ContractKykStatusDiv.payOff.code ||
    kykStatusDiv === ContractKykStatusDiv.cancel.code
  ) {
    return <Label color={'gray'} text={AppUsage.disabled.value} />
  }
  if (is_usage && is_management) {
    if (app_user_id != null) {
      return <Label color={'blue'} text={AppUsage.enabled.value} />
    } else {
      return <Label color={'gray'} text={AppUsage.none.value} />
    }
  }
  return <Label color={'gray'} text={AppUsage.disabled.value} />
}

export default UseUseLabel
