import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormLayout from 'components/common/FormLayout'
import Loading from 'components/common/Loading'
import DateLabel from 'components/common/DateLabel'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  content: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  }
}))

function Right(props) {
  const classes = useStyles()

  const { isLoading, messageTemplate } = props
  return (
    <>
      <FormLayout title="概要">
        <Grid item xs={12} container spacing={2} className={classes.row}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" className={classes.content}>
              更新日時
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container>
            <Grid item xs={12}>
              <Typography variant="body1" align="right">
                {isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <>
                    {'message_template' in messageTemplate &&
                      messageTemplate.message_template && (
                        <DateLabel
                          timestamp={
                            messageTemplate.message_template.created_at
                          }
                        />
                      )}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12} container spacing={2} className={classes.row}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" className={classes.content}>
              更新者
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="right"
              className={classes.content}
            >
              {isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <>
                  {messageTemplate.updated_by &&
                    getFormattedNameByStatus(
                      messageTemplate.updated_by.name,
                      messageTemplate.updated_by.status
                    )}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </FormLayout>
      <Divider />
    </>
  )
}

export default Right
