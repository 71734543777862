import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { handleChange, setLoadData } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormLayout from 'components/common/FormLayout'
import Button from '@material-ui/core/Button'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as mutations from 'graphql/mutations'
import { withSnackbar } from 'notistack'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { AttendCompanyType, AttendDateType } from 'utils/enum'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import localeJa from 'date-fns/locale/ja'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Dropdown from 'components/common/Form/Dropdown'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { SignalCellularNullSharp } from '@material-ui/icons'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  left: {
    flex: 1,
    display: 'flex'
  },
  textRight: {
    textAlign: 'right'
  },
  body: {},

  center: {
    justifyContent: 'center'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  formControl: {
    width: '100%'
  },
  centerGray: {
    textAlign: 'center',
    color: 'gray'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  section: {
    padding: '15px 20px'
  },
  attendDateTypeDropdown: {
    marginTop: 16
  },
  attendDateTypeLabel: {
    marginTop: 8
  },
  radioGroup: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14
    }
  },
  timeItem: {
    fontSize: 14,
    margin: '12px 4px 0 4px'
  },
  errorMessage: {
    marginTop: 2,
    color: theme.palette.c_alert,
    fontSize: 12
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

const AttendFixDateTypeList = [
  { value: AttendDateType.morning.code, label: AttendDateType.morning.value },
  {
    value: AttendDateType.afternoon.code,
    label: AttendDateType.afternoon.value
  },
  {
    value: AttendDateType.earlyEvening.code,
    label: AttendDateType.earlyEvening.value
  }
]

const AttendDateTypeList = [
  { value: null, label: '入居者からの連絡待ちです' },
  ...AttendFixDateTypeList
]

function ResidentCancellationApplicationForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const { isLoading, application, fetchResidentCancellationApplication } = props
  const NOT_SELECTED = '100'
  const [validate, setValidate] = useState({})
  const orgContent =
    props.application && props.application.content
      ? parseJson(props.application.content)
      : {}
  const [content, setContent] = useState(orgContent)

  const [cancellationDates, setCancellationDates] = useState({})
  const [attendInfo, setAttendInfo] = useState({})
  const [returnKeyInfo, setReturnKeyInfo] = useState({})
  const [remark, setRemark] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  function formatDate(date, format) {
    if (!date) {
      return null
    }
    format = format.replace(/yyyy/g, date.getFullYear())
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))
    return format
  }

  function parseDate(dateStr) {
    return new Date(dateStr).toLocaleString({ timeZone: 'Asia/Tokyo' })
  }

  const [cancellationFixedDate, setCancellationFixedDate] = useState(
    content &&
      content.cancellation &&
      content.cancellation.cancellationDates &&
      content.cancellation.cancellationDates.cancellationFixedDate
      ? parseDate(content.cancellation.cancellationDates.cancellationFixedDate)
      : null
  )
  const handleChangeCancellationFixedDate = date => {
    setCancellationFixedDate(date)
    setCancellationDates(
      Object.assign({}, cancellationDates, {
        cancellationFixedDate: formatDate(date, 'yyyy-MM-dd')
      })
    )
  }

  const orgAttendFlg =
    content && content.cancellation && content.cancellation.attendFlg != null
      ? content.cancellation.attendFlg
      : null
  const [attendFlg, setAttendFlg] = useState(
    content && content.cancellation && content.cancellation.attendFlg != null
      ? content.cancellation.attendFlg
      : null
  )
  const handleChangeAttendFlg = event => {
    setAttendFlg(event.target.value)
    if (event.target.value === 0) {
      setAttendCompanyTypeId(null)
      setAttendDesiredDate1(null)
      setAttendDesiredDateType1(null)
      setAttendDesiredDate2(null)
      setAttendDesiredDateType2(null)
      setAttendDesiredDate3(null)
      setAttendDesiredDateType3(null)
      setAttendFixedDate(null)
      setAttendFixedDateType(null)
      setNoContactEnabled(false)
    }
    if (event.target.value === 1) {
      setDepartureFixedDate(null)
      setReturnKeyInfoKey('')
      setReturnKeyInfoReturnLocation('')
      setReturnKeyInfoNumericKeypadEnabled(false)
      setReturnKeyInfoRemark('')
    }
  }

  const [attendCompanyTypeId, setAttendCompanyTypeId] = useState(
    content &&
      content.cancellation &&
      content.cancellation.attendInfo &&
      content.cancellation.attendInfo.attendCompanyTypeId
      ? content.cancellation.attendInfo.attendCompanyTypeId
      : null
  )
  const handleChangeAttendCompanyTypeId = event => {
    setAttendCompanyTypeId(event.target.value)
    setAttendInfo(
      Object.assign({}, attendInfo, { [event.target.name]: event.target.value })
    )
  }

  const [cancellationRemark, setCancellationRemark] = useState(
    content && content.cancellation && content.cancellation.remark
      ? content.cancellation.remark
      : ''
  )

  const [departureFixedDate, setDepartureFixedDate] = useState(
    content &&
      content.cancellation &&
      content.cancellation.cancellationDates &&
      content.cancellation.cancellationDates.departureFixedDate
      ? parseDate(content.cancellation.cancellationDates.departureFixedDate)
      : null
  )
  const handleChangeDepartureFixedDate = date => {
    setDepartureFixedDate(date)
    setCancellationDates(
      Object.assign({}, cancellationDates, {
        departureFixedDate: formatDate(date, 'yyyy-MM-dd')
      })
    )
  }

  const orgReturnKeyInfoReturnDate =
    content &&
    content.cancellation &&
    content.cancellation.returnKeyInfo &&
    content.cancellation.returnKeyInfo.returnDate
      ? parseDate(content.cancellation.returnKeyInfo.returnDate)
      : null
  const [returnKeyInfoReturnDate, setReturnKeyInfoReturnDate] = useState(
    orgReturnKeyInfoReturnDate
      ? orgReturnKeyInfoReturnDate
      : content &&
        content.cancellation &&
        content.cancellation.cancellationDates &&
        content.cancellation.cancellationDates.departureDesiredDate
      ? parseDate(content.cancellation.cancellationDates.departureDesiredDate)
      : null
  )
  const handleChangeReturnKeyInfoReturnDate = date => {
    setReturnKeyInfoReturnDate(date)
    setReturnKeyInfo(
      Object.assign({}, returnKeyInfo, {
        returnDate: formatDate(date, 'yyyy-MM-dd')
      })
    )
  }

  const [returnKeyInfoKey, setReturnKeyInfoKey] = useState(
    content &&
      content.cancellation &&
      content.cancellation.returnKeyInfo &&
      content.cancellation.returnKeyInfo.key
      ? content.cancellation.returnKeyInfo.key
      : ''
  )
  const orgReturnKeyInfoReturnLocation =
    content &&
    content.cancellation &&
    content.cancellation.returnKeyInfo &&
    content.cancellation.returnKeyInfo.returnLocation
      ? content.cancellation.returnKeyInfo.returnLocation
      : ''
  const [
    returnKeyInfoReturnLocation,
    setReturnKeyInfoReturnLocation
  ] = useState(orgReturnKeyInfoReturnLocation)
  const orgReturnKeyInfoNumericKeypadEnabled =
    content &&
    content.cancellation &&
    content.cancellation.returnKeyInfo &&
    content.cancellation.returnKeyInfo.numericKeypadFlg &&
    content.cancellation.returnKeyInfo.numericKeypadFlg === 1
  const [
    returnKeyInfoNumericKeypadEnabled,
    setReturnKeyInfoNumericKeypadEnabled
  ] = useState(orgReturnKeyInfoNumericKeypadEnabled)
  const orgReturnKeyInfoNumericKeypad =
    content &&
    content.cancellation &&
    content.cancellation.returnKeyInfo &&
    content.cancellation.returnKeyInfo.numericKeypad
      ? content.cancellation.returnKeyInfo.numericKeypad
      : ''
  const [returnKeyInfoNumericKeypad, setReturnKeyInfoNumericKeypad] = useState(
    orgReturnKeyInfoNumericKeypad
  )
  const orgReturnKeyInfoRemark =
    content &&
    content.cancellation &&
    content.cancellation.returnKeyInfo &&
    content.cancellation.returnKeyInfo.remark
      ? content.cancellation.returnKeyInfo.remark
      : ''
  const [returnKeyInfoRemark, setReturnKeyInfoRemark] = useState(
    orgReturnKeyInfoRemark
  )
  const orgAttendDesiredDate1 =
    content &&
    content.cancellation &&
    content.cancellation.attendInfo &&
    content.cancellation.attendInfo.attendDesiredDate1
      ? parseDate(content.cancellation.attendInfo.attendDesiredDate1)
      : null
  const [attendDesiredDate1, setAttendDesiredDate1] = useState(
    orgAttendDesiredDate1
  )
  const handleChangeAttendDesiredDate1 = date => {
    setAttendDesiredDate1(date)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendDesiredDate1: formatDate(date, 'yyyy-MM-ddTHH:mm:00+09:00')
      })
    )
  }
  const [orgAttendDesiredDateType1, setOrgAttendDesiredDateType1] = useState(
    null
  )
  const [attendDesiredDateType1, setAttendDesiredDateType1] = useState(null)
  const handleChangeAttendDesiredDateType1 = event => {
    setAttendDesiredDateType1(event.target.value)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendDesiredDateType1: event.target.value
      })
    )
  }

  const orgAttendDesiredDate2 =
    content &&
    content.cancellation &&
    content.cancellation.attendInfo &&
    content.cancellation.attendInfo.attendDesiredDate2
      ? parseDate(content.cancellation.attendInfo.attendDesiredDate2)
      : null
  const [attendDesiredDate2, setAttendDesiredDate2] = useState(
    orgAttendDesiredDate2
  )
  const handleChangeAttendDesiredDate2 = date => {
    setAttendDesiredDate2(date)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendDesiredDate2: formatDate(date, 'yyyy-MM-ddTHH:mm:00+09:00')
      })
    )
  }
  const [orgAttendDesiredDateType2, setOrgAttendDesiredDateType2] = useState(
    null
  )
  const [attendDesiredDateType2, setAttendDesiredDateType2] = useState(null)
  const handleChangeAttendDesiredDateType2 = event => {
    setAttendDesiredDateType2(event.target.value)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendDesiredDateType2: event.target.value
      })
    )
  }

  const orgAttendDesiredDate3 =
    content &&
    content.cancellation &&
    content.cancellation.attendInfo &&
    content.cancellation.attendInfo.attendDesiredDate3
      ? parseDate(content.cancellation.attendInfo.attendDesiredDate3)
      : null
  const [attendDesiredDate3, setAttendDesiredDate3] = useState(
    orgAttendDesiredDate3
  )
  const handleChangeAttendDesiredDate3 = date => {
    setAttendDesiredDate3(date)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendDesiredDate3: formatDate(date, 'yyyy-MM-ddTHH:mm:00+09:00')
      })
    )
  }
  const [orgAttendDesiredDateType3, setOrgAttendDesiredDateType3] = useState(
    null
  )
  const [attendDesiredDateType3, setAttendDesiredDateType3] = useState(null)
  const handleChangeAttendDesiredDateType3 = event => {
    setAttendDesiredDateType3(event.target.value)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendDesiredDateType3: event.target.value
      })
    )
  }

  const orgAttendFixedDate =
    content &&
    content.cancellation &&
    content.cancellation.attendInfo &&
    content.cancellation.attendInfo.attendFixedDate
      ? parseDate(content.cancellation.attendInfo.attendFixedDate)
      : null
  const [attendFixedDate, setAttendFixedDate] = useState(orgAttendFixedDate)
  const handleChangeAttendFixedDate = date => {
    setAttendFixedDate(date)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendFixedDate: formatDate(date, 'yyyy-MM-ddTHH:mm:00+09:00')
      })
    )
  }
  const handleChangeAttendFixedOnlyDate = date => {
    if (!date) {
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setAttendFixedDate(date)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        attendFixedDate: formatDate(date, 'yyyy-MM-ddTHH:mm:00+09:00')
      })
    )
  }
  const [orgAttendFixedDateType, setOrgAttendFixedDateType] = useState(null)
  const [attendFixedDateType, setAttendFixedDateType] = useState(null)
  const handleChangeAttendFixedDateType = event => {
    setAttendFixedDateType(event.target.value)
    setAttendInfo(
      Object.assign({}, attendInfo, { attendFixedDateType: event.target.value })
    )
  }

  const [attendType, setAttendType] = useState()
  const handleChangeAttendType = event => {
    setAttendType(event.target.value)
    // 1:時間帯指定、2:時間指定
    if (event.target.value === '1') {
      setAttendTime({
        hour: null,
        minute: SignalCellularNullSharp
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendType: Number(event.target.value),
          attendTime: ''
        })
      )
    } else if (event.target.value === '2') {
      setAttendStartTime({
        hour: null,
        minute: null
      })
      setAttendEndTime({
        hour: null,
        minute: null
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendType: Number(event.target.value),
          attendStartTime: '',
          attendEndTime: ''
        })
      )
    }
  }

  const [attendStartTime, setAttendStartTime] = useState({
    hour: null,
    minute: null
  })
  const handleChangeAttendStartTime = event => {
    if (event.target.name === 'attendStartTimeHour') {
      setAttendStartTime({
        ...attendStartTime,
        hour: event.target.value
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendStartTime: event.target.value + ':' + attendStartTime.minute
        })
      )
    } else if (event.target.name === 'attendStartTimeMinute') {
      setAttendStartTime({
        ...attendStartTime,
        minute: event.target.value
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendStartTime: attendStartTime.hour + ':' + event.target.value
        })
      )
    }
  }

  const [attendEndTime, setAttendEndTime] = useState({
    hour: null,
    minute: null
  })
  const handleChangeAttendEndTime = event => {
    if (event.target.name === 'attendEndTimeHour') {
      setAttendEndTime({
        ...attendEndTime,
        hour: event.target.value
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendEndTime: event.target.value + ':' + attendEndTime.minute
        })
      )
    } else if (event.target.name === 'attendEndTimeMinute') {
      setAttendEndTime({
        ...attendEndTime,
        minute: event.target.value
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendEndTime: attendEndTime.hour + ':' + event.target.value
        })
      )
    }
  }

  const [attendTime, setAttendTime] = useState({
    hour: null,
    minute: null
  })
  const handleChangeAttendTime = event => {
    if (event.target.name === 'attendTimeHour') {
      setAttendTime({
        ...attendTime,
        hour: event.target.value
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendTime: event.target.value + ':' + attendTime.minute
        })
      )
    } else if (event.target.name === 'attendTimeMinute') {
      setAttendTime({
        ...attendTime,
        minute: event.target.value
      })
      setAttendInfo(
        Object.assign({}, attendInfo, {
          attendTime: attendTime.hour + ':' + event.target.value
        })
      )
    }
  }

  const orgNoContactEnabled =
    content &&
    content.cancellation &&
    content.cancellation.attendInfo &&
    content.cancellation.attendInfo.noContactFlg &&
    content.cancellation.attendInfo.noContactFlg === 1
      ? true
      : false
  const [noContactEnabled, setNoContactEnabled] = useState(orgNoContactEnabled)
  const handleChangeNoContactEnabled = event => {
    setNoContactEnabled(event.target.checked)
    setAttendInfo(
      Object.assign({}, attendInfo, {
        noContactFlg: event.target.checked ? 1 : 0
      })
    )
  }

  const fatchData = async () => {
    if (props && props.application && props.application.content) {
      const contentBase = parseJson(props.application.content)
      if (
        contentBase &&
        contentBase.cancellation &&
        contentBase.cancellation.attendInfo
      ) {
        const attendInfoBase = contentBase.cancellation.attendInfo
        if (attendInfoBase.attendDesiredDateType1) {
          setOrgAttendDesiredDateType1(attendInfoBase.attendDesiredDateType1)
          setAttendDesiredDateType1(attendInfoBase.attendDesiredDateType1)
        }
        if (attendInfoBase.attendDesiredDateType2) {
          setOrgAttendDesiredDateType2(attendInfoBase.attendDesiredDateType2)
          setAttendDesiredDateType2(attendInfoBase.attendDesiredDateType2)
        }
        if (attendInfoBase.attendDesiredDateType3) {
          setOrgAttendDesiredDateType3(attendInfoBase.attendDesiredDateType3)
          setAttendDesiredDateType3(attendInfoBase.attendDesiredDateType3)
        }
        if (attendInfoBase.attendFixedDateType) {
          setOrgAttendFixedDateType(attendInfoBase.attendFixedDateType)
          setAttendFixedDateType(attendInfoBase.attendFixedDateType)
        }
        if (attendInfoBase.attendFixedDateType && !attendInfoBase.attendType) {
          setAttendType('1')
          switch (attendInfoBase.attendFixedDateType) {
            case 1:
              setAttendStartTime({
                hour: '9',
                minute: '0'
              })
              setAttendEndTime({
                hour: '12',
                minute: '0'
              })
              setAttendInfo(
                Object.assign({}, attendInfo, {
                  attendType: 1,
                  attendStartTime: '9:0',
                  attendEndTime: '12:0'
                })
              )
              break
            case 2:
              setAttendStartTime({
                hour: '12',
                minute: '0'
              })
              setAttendEndTime({
                hour: '15',
                minute: '0'
              })
              setAttendInfo(
                Object.assign({}, attendInfo, {
                  attendType: 1,
                  attendStartTime: '12:0',
                  attendEndTime: '15:0'
                })
              )
              break
            case 3:
              setAttendStartTime({
                hour: '15',
                minute: '0'
              })
              setAttendEndTime({
                hour: '18',
                minute: '0'
              })
              setAttendInfo(
                Object.assign({}, attendInfo, {
                  attendType: 1,
                  attendStartTime: '15:0',
                  attendEndTime: '18:0'
                })
              )
              break
          }
        }
        if (attendInfoBase.attendType) {
          setAttendType(String(attendInfoBase.attendType))
          setAttendInfo(
            Object.assign({}, attendInfo, {
              attendType: Number(attendInfoBase.attendType)
            })
          )
        }
        if (attendInfoBase.attendStartTime) {
          const attendStartTime = attendInfoBase.attendStartTime.split(':')
          setAttendStartTime({
            hour: attendStartTime[0],
            minute: attendStartTime[1]
          })
          setAttendInfo(
            Object.assign({}, attendInfo, {
              attendStartTime: attendInfoBase.attendStartTime
            })
          )
        }
        if (attendInfoBase.attendEndTime) {
          const attendEndTime = attendInfoBase.attendEndTime.split(':')
          setAttendEndTime({
            hour: attendEndTime[0],
            minute: attendEndTime[1]
          })
          setAttendInfo(
            Object.assign({}, attendInfo, {
              attendEndTime: attendInfoBase.attendEndTime
            })
          )
        }
        if (attendInfoBase.attendTime) {
          const attendTime = attendInfoBase.attendTime.split(':')
          setAttendTime({
            hour: attendTime[0],
            minute: attendTime[1]
          })
          setAttendInfo(
            Object.assign({}, attendInfo, {
              attendTime: attendInfoBase.attendTime
            })
          )
        }
      }
    }
  }

  useEffect(() => {
    fatchData()
  }, [])

  useEffect(() => {
    if (
      application.application_state_id === 4 ||
      application.application_state_id === 5 ||
      isRestrictedRole
    ) {
      setIsButtonDisabled(true)
    }
  }, [application])

  const handleCancel = () => {
    props.handleChange({
      ['attendFlg']: orgAttendFlg
    })
    fatchData()
  }

  const handleCancellationDatesChange = event => {
    setCancellationDates(
      Object.assign({}, cancellationDates, {
        [event.target.name]: event.target.value
      })
    )
  }

  const handleAttendInfoChange = event => {
    setAttendInfo(
      Object.assign({}, attendInfo, { [event.target.name]: event.target.value })
    )
  }

  const handleReturnKeyInfoChange = event => {
    if ([event.target.name] == 'numericKeypadFlg') {
      setReturnKeyInfo(
        Object.assign({}, returnKeyInfo, {
          [event.target.name]: event.target.checked ? 1 : 0
        })
      )
      setReturnKeyInfoNumericKeypadEnabled(event.target.checked)
    } else if ([event.target.name] == 'returnKeyInfoRemark') {
      setReturnKeyInfo(
        Object.assign({}, returnKeyInfo, { remark: event.target.value })
      )
    } else if ([event.target.name] == 'key') {
      setReturnKeyInfoKey(event.target.value)
      setReturnKeyInfo(
        Object.assign({}, returnKeyInfo, {
          [event.target.name]: event.target.value
        })
      )
    } else if ([event.target.name] == 'returnLocation') {
      setReturnKeyInfoReturnLocation(event.target.value)
      setReturnKeyInfo(
        Object.assign({}, returnKeyInfo, {
          [event.target.name]: event.target.value
        })
      )
    } else {
      setReturnKeyInfo(
        Object.assign({}, returnKeyInfo, {
          [event.target.name]: event.target.value
        })
      )
    }
  }

  const handleRemarkChange = event => {
    setRemark(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()
    props.setLoadData(true)
    if (validateForm()) {
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      props.setLoadData(false)
      return
    }
    const params = { id: props.application.id, noticeFlg: true }
    if (attendFlg !== undefined) {
      params['attendFlg'] = attendFlg
    }
    if (Object.keys(cancellationDates).length > 0) {
      params['cancellationDates'] = cancellationDates
    }
    if (Object.keys(attendInfo).length > 0) {
      params['attendInfo'] = attendInfo
    }
    if (Object.keys(returnKeyInfo).length > 0) {
      const returnKeyInfoData = returnKeyInfo
      if (!returnKeyInfoData.returnDate) {
        returnKeyInfoData.returnDate = formatDate(
          new Date(returnKeyInfoReturnDate),
          'yyyy-MM-dd'
        )
      }
      params['returnKeyInfo'] = returnKeyInfoData
    }
    if (remark) {
      params['remark'] = remark
    }
    API.graphql(
      graphqlOperation(mutations.updateResidentCancellationApplication, {
        input: params
      })
    )
      .then(response => {
        const errors = response.errors
        if (!errors) {
          props.enqueueSnackbar('保存しました', {
            variant: 'success'
          })
          setCancellationDates({})
          setAttendInfo({})
          setReturnKeyInfo({})
          setRemark('')
          props.fetchResidentCancellationApplication(props.application.id)
          props.setLoadData(false)
        } else {
          console.log(JSON.stringify(errors))
          props.enqueueSnackbar('保存できませんでした', {
            variant: 'error'
          })
          props.setLoadData(false)
        }
      })
      .catch(error => {
        const errors = error.errors
        console.log(JSON.stringify(errors))
        props.enqueueSnackbar('保存できませんでした', {
          variant: 'error'
        })
        props.setLoadData(false)
      })
  }

  function validateForm() {
    let isError = false
    const valid = {}

    valid.cancellationDates = {}
    if (!cancellationFixedDate) {
      valid.cancellationDates.cancellationFixedDate = {
        hasError: true,
        message: '解約日は必須です。'
      }
      isError = true
    }

    if (attendFlg === null || attendFlg === undefined) {
      valid.attendFlg = { hasError: true, message: '立会い有無は必須です。' }
      isError = true
    }

    if (attendFlg === 1) {
      valid.attendInfo = {}
      if (!attendCompanyTypeId) {
        valid.attendInfo.attendCompanyTypeId = {
          hasError: true,
          message: '立会い会社は必須です。'
        }
        isError = true
      }
      if (attendFixedDate || attendType) {
        if (attendFixedDate && !attendType) {
          valid.attendType = {
            hasError: true,
            message: '時間帯指定または時間指定は必須です'
          }
          isError = true
        }
      }
      if (attendType === '1') {
        if (
          !attendStartTime.hour ||
          !attendStartTime.minute ||
          attendStartTime.hour === NOT_SELECTED ||
          attendStartTime.minute === NOT_SELECTED ||
          !attendEndTime.hour ||
          !attendEndTime.minute ||
          attendEndTime.hour === NOT_SELECTED ||
          attendEndTime.minute === NOT_SELECTED
        ) {
          valid.attendStartEndTime = {
            hasError: true,
            message: '時間帯を選択してください'
          }
          isError = true
        }
      }
      if (attendType === '2') {
        if (
          !attendTime.hour ||
          attendTime.hour === NOT_SELECTED ||
          !attendTime.minute ||
          attendTime.minute === NOT_SELECTED
        ) {
          valid.attendTime = {
            hasError: true,
            message: '時間を選択してください'
          }
          isError = true
        }
      }
    }

    if (attendFlg === 0) {
      valid.returnKeyInfo = {}
      if (!returnKeyInfoReturnDate) {
        valid.returnKeyInfo.returnDate = {
          hasError: true,
          message: '退去日は必須です。'
        }
        isError = true
      }
      if (!returnKeyInfoKey) {
        valid.returnKeyInfo.key = {
          hasError: true,
          message: '鍵の本数は必須です。'
        }
        isError = true
      }
      if (!returnKeyInfoReturnLocation) {
        valid.returnKeyInfo.returnLocation = {
          hasError: true,
          message: '鍵返却場所は必須です。'
        }
        isError = true
      }
    }

    setValidate(valid)
    return isError
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormLayout title="解約条件">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
              <KeyboardDatePicker
                required
                disableToolbar
                inputVariant="filled"
                format="yyyy-MM-dd"
                margin="normal"
                id="cancellationFixedDate"
                name="cancellationFixedDate"
                label="解約日"
                value={cancellationFixedDate}
                onChange={handleChangeCancellationFixedDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                disabled={isRestrictedRole}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6} sm={6} spacing={4}>
            <Dropdown
              required
              name="attendFlg"
              label="立会い有無"
              onChange={handleChangeAttendFlg}
              defaultValue={attendFlg}
              items={[
                {
                  value: 0,
                  label: 'なし'
                },
                {
                  value: 1,
                  label: 'あり'
                }
              ]}
              hasError={
                validate && validate.attendFlg && validate.attendFlg.hasError
              }
              errorMessage={
                validate && validate.attendFlg && validate.attendFlg.message
              }
              readOnly={
                application &&
                application.application_state_id !== 1 &&
                application.application_state_id !== 2
              }
              disabled={isRestrictedRole}
            />
          </Grid>
          <Grid item xs={6} sm={6} spacing={4}>
            {attendFlg === 1 && (
              <>
                <Dropdown
                  required
                  name="attendCompanyTypeId"
                  label="立会い会社"
                  onChange={handleChangeAttendCompanyTypeId}
                  defaultValue={attendCompanyTypeId}
                  items={[
                    {
                      value: AttendCompanyType.own.code,
                      label: AttendCompanyType.own.value
                    },
                    {
                      value: AttendCompanyType.other.code,
                      label: AttendCompanyType.other.value
                    }
                  ]}
                  hasError={
                    validate &&
                    validate.attendInfo &&
                    validate.attendInfo.attendCompanyTypeId &&
                    validate.attendInfo.attendCompanyTypeId.hasError
                  }
                  errorMessage={
                    validate &&
                    validate.attendInfo &&
                    validate.attendInfo.attendCompanyTypeId &&
                    validate.attendInfo.attendCompanyTypeId.message
                  }
                  readOnly={
                    application &&
                    application.application_state_id !== 1 &&
                    application.application_state_id !== 2
                  }
                  disabled={isRestrictedRole}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              id="remark"
              name="remark"
              defaultValue={cancellationRemark}
              fullWidth
              multiline
              rows="4"
              variant="filled"
              onChange={handleRemarkChange}
              helperText="2000文字以内"
              disabled={isRestrictedRole}
            />
          </Grid>
        </Grid>
      </FormLayout>
      {attendFlg === 0 && (
        <FormLayout title="鍵返却">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} spacing={4} className={classes.left}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
                <KeyboardDatePicker
                  required
                  disableToolbar
                  inputVariant="filled"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="returnKeyInfoReturnDate"
                  name="returnKeyInfoReturnDate"
                  label="退去日"
                  value={returnKeyInfoReturnDate}
                  onChange={handleChangeReturnKeyInfoReturnDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  disabled={isRestrictedRole}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              spacing={4}
              className={classes.textRight}
            >
              <TextField
                required
                id="key"
                name="key"
                label="鍵の本数"
                defaultValue={returnKeyInfoKey}
                value={returnKeyInfoKey}
                fullWidth
                variant="filled"
                onChange={handleReturnKeyInfoChange}
                error={
                  validate &&
                  validate.returnKeyInfo &&
                  validate.returnKeyInfo.key &&
                  validate.returnKeyInfo.key.hasError
                }
                helperText={
                  validate &&
                  validate.returnKeyInfo &&
                  validate.returnKeyInfo.key &&
                  validate.returnKeyInfo.key.hasError
                    ? validate.returnKeyInfo.key.message
                    : '20文字以内'
                }
                disabled={isRestrictedRole}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              spacing={4}
              className={classes.textRight}
            >
              <TextField
                required
                id="returnLocation"
                name="returnLocation"
                label="鍵返却場所"
                defaultValue={returnKeyInfoReturnLocation}
                value={returnKeyInfoReturnLocation}
                fullWidth
                variant="filled"
                onChange={handleReturnKeyInfoChange}
                helperText={'20文字以内'}
                disabled={isRestrictedRole}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={6} spacing={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="numericKeypadFlg"
                    name="numericKeypadFlg"
                    checked={returnKeyInfoNumericKeypadEnabled}
                    onChange={handleReturnKeyInfoChange}
                  />
                }
                label="テンキーの暗証番号を尋ねる"
                disabled={isRestrictedRole}
              />
            </Grid>
            <Grid item xs={6} sm={6} spacing={4}>
              <TextField
                id="numericKeypad"
                name="numericKeypad"
                label="テンキーの暗証番号"
                defaultValue={returnKeyInfoNumericKeypad}
                fullWidth
                variant="filled"
                onChange={handleReturnKeyInfoChange}
                helperText="入居者から連絡のある暗証番号が入ります"
                InputProps={{
                  readOnly: true
                }}
                disabled={isRestrictedRole}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                id="returnKeyInfoRemark"
                name="returnKeyInfoRemark"
                defaultValue={returnKeyInfoRemark}
                fullWidth
                multiline
                rows="4"
                variant="filled"
                onChange={handleReturnKeyInfoChange}
                helperText="400文字以内"
                disabled={isRestrictedRole}
              />
            </Grid>
          </Grid>
        </FormLayout>
      )}

      {application &&
        application.application_state_id !== 1 &&
        attendFlg === 1 && (
          <div className={classes.root}>
            {attendCompanyTypeId == 1 && (
              <div className={classes.section}>
                <Divider />
                <div className={classes.section}>
                  <Grid container alignItems="center">
                    <Grid>
                      <Typography className={classes.title}>
                        立ち会い希望日時
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={4}>
                  {orgAttendDesiredDate1 && !orgAttendDesiredDateType1 ? (
                    <Grid item xs={4} sm={4} spacing={2}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeJa}
                      >
                        <KeyboardDateTimePicker
                          readOnly
                          disableToolbar
                          inputVariant="filled"
                          format="yyyy-MM-dd HH:mm"
                          margin="normal"
                          id="attendDesiredDate1"
                          name="attendDesiredDate1"
                          label="第1希望日時"
                          value={attendDesiredDate1}
                          onChange={handleChangeAttendDesiredDate1}
                          helperText={
                            attendDesiredDate1 ? '' : '入居者からの連絡待ちです'
                          }
                          disabled={isRestrictedRole}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={6} sm={6} spacing={2}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeJa}
                        >
                          <KeyboardDatePicker
                            readOnly
                            disableToolbar
                            fullWidth
                            inputVariant="filled"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="attendDesiredDate1"
                            name="attendDesiredDate1"
                            label="第1希望日時"
                            value={attendDesiredDate1}
                            onChange={handleChangeAttendDesiredDate1}
                            helperText={
                              attendDesiredDate1
                                ? ''
                                : '入居者からの連絡待ちです'
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            disabled={isRestrictedRole}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6} sm={6} spacing={2}>
                        <FormControl
                          fullWidth
                          className={classes.attendDateTypeDropdown}
                        >
                          <Dropdown
                            readOnly={true}
                            name="attendDesiredDateType1"
                            label="時間帯"
                            defaultValue={attendDesiredDateType1}
                            items={AttendDateTypeList}
                            onChange={handleChangeAttendDesiredDateType1}
                            disabled={isRestrictedRole}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {orgAttendDesiredDate2 && !orgAttendDesiredDateType2 ? (
                    <Grid item xs={4} sm={4} spacing={2}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeJa}
                      >
                        <KeyboardDateTimePicker
                          readOnly
                          disableToolbar
                          inputVariant="filled"
                          format="yyyy-MM-dd HH:mm"
                          margin="normal"
                          id="attendDesiredDate2"
                          name="attendDesiredDate2"
                          label="第2希望日時"
                          value={attendDesiredDate2}
                          onChange={handleChangeAttendDesiredDate2}
                          helperText={
                            attendDesiredDate2 ? '' : '入居者からの連絡待ちです'
                          }
                          disabled={isRestrictedRole}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={6} sm={6} spacing={2}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeJa}
                        >
                          <KeyboardDatePicker
                            readOnly
                            disableToolbar
                            fullWidth
                            inputVariant="filled"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="attendDesiredDate2"
                            name="attendDesiredDate2"
                            label="第2希望日"
                            value={attendDesiredDate2}
                            onChange={handleChangeAttendDesiredDate2}
                            helperText={
                              attendDesiredDate2
                                ? ''
                                : '入居者からの連絡待ちです'
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            disabled={isRestrictedRole}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6} sm={6} spacing={2}>
                        <FormControl
                          fullWidth
                          className={classes.attendDateTypeDropdown}
                        >
                          <Dropdown
                            readOnly={true}
                            name="attendDesiredDateType2"
                            label="時間帯"
                            defaultValue={attendDesiredDateType2}
                            items={AttendDateTypeList}
                            onChange={handleChangeAttendDesiredDateType2}
                            disabled={isRestrictedRole}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {orgAttendDesiredDate3 && !orgAttendDesiredDateType3 ? (
                    <Grid item xs={4} sm={4} spacing={2}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeJa}
                      >
                        <KeyboardDateTimePicker
                          readOnly
                          disableToolbar
                          inputVariant="filled"
                          format="yyyy-MM-dd HH:mm"
                          margin="normal"
                          id="attendDesiredDate3"
                          name="attendDesiredDate3"
                          label="第3希望日時"
                          value={attendDesiredDate3}
                          onChange={handleChangeAttendDesiredDate3}
                          helperText={
                            attendDesiredDate3 ? '' : '入居者からの連絡待ちです'
                          }
                          disabled={isRestrictedRole}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={6} sm={6} spacing={2}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeJa}
                        >
                          <KeyboardDatePicker
                            readOnly
                            disableToolbar
                            fullWidth
                            inputVariant="filled"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="attendDesiredDate3"
                            name="attendDesiredDate3"
                            label="第3希望日"
                            value={attendDesiredDate3}
                            onChange={handleChangeAttendDesiredDate3}
                            helperText={
                              attendDesiredDate3
                                ? ''
                                : '入居者からの連絡待ちです'
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            disabled={isRestrictedRole}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6} sm={6} spacing={2}>
                        <FormControl
                          fullWidth
                          className={classes.attendDateTypeDropdown}
                        >
                          <Dropdown
                            readOnly={true}
                            name="attendDesiredDateType3"
                            label="時間帯"
                            defaultValue={attendDesiredDateType3}
                            items={AttendDateTypeList}
                            onChange={handleChangeAttendDesiredDateType3}
                            disabled={isRestrictedRole}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} spacing={2} />
                    </>
                  )}
                </Grid>
              </div>
            )}
            <div className={classes.section}>
              <Divider />
              <div className={classes.section}>
                <Grid container alignItems="center">
                  <Grid>
                    <Typography className={classes.title}>
                      立ち会い日時
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={4}>
                {/* 2022.12.19 不要な条件分岐ですが、既存の実装が複雑なため一旦分岐されない条件にて修正 */}
                {orgAttendFixedDate && !attendType ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    spacing={4}
                    className={classes.left}
                  >
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={localeJa}
                    >
                      <KeyboardDateTimePicker
                        required={
                          (application.application_state_id !== 1 &&
                            application.application_state_id !== 2) ||
                          noContactEnabled
                        }
                        disableToolbar
                        ampm={false}
                        inputVariant="filled"
                        format="yyyy-MM-dd HH:mm"
                        margin="normal"
                        id="attendFixedDate"
                        name="attendFixedDate"
                        label="確定日時"
                        value={attendFixedDate}
                        onChange={handleChangeAttendFixedDate}
                        disabled={isRestrictedRole}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      spacing={4}
                      className={classes.left}
                    >
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeJa}
                      >
                        <KeyboardDatePicker
                          required={
                            (application.application_state_id !== 1 &&
                              application.application_state_id !== 2) ||
                            noContactEnabled
                          }
                          disableToolbar
                          fullWidth
                          inputVariant="filled"
                          format="yyyy-MM-dd"
                          id="attendFixedDate"
                          name="attendFixedDate"
                          label="確定日"
                          value={attendFixedDate}
                          onChange={handleChangeAttendFixedOnlyDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          // attendFixedDateの必須チェックはrequiredで行なっているため、
                          // attendTypeの必須チェック結果はここに表示する
                          error={
                            validate &&
                            validate.attendType &&
                            validate.attendType.hasError
                          }
                          helperText={
                            validate &&
                            validate.attendType &&
                            validate.attendType.message
                          }
                          disabled={isRestrictedRole}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <FormControl>
                      <RadioGroup
                        className={classes.radioGroup}
                        aria-label="attendType"
                        name="attendType"
                        value={attendType}
                        defaultValue={0}
                        onChange={handleChangeAttendType}
                        disabled={isRestrictedRole}
                      >
                        <Grid container alignItems="center" spacing={0}>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value={'1'}
                              control={<Radio color="primary" />}
                              label="時間帯指定"
                              className={classes.attendDateTypeLabel}
                              disabled={isRestrictedRole}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl
                              fullWidth
                              className={classes.attendDateTypeDropdown}
                            >
                              <Dropdown
                                items={[
                                  { value: NOT_SELECTED, label: '--' },
                                  ...Array.from(Array(24).keys()).map(i => ({
                                    value: i.toString(),
                                    label: i.toString()
                                  }))
                                ]}
                                fontSize={12}
                                itemFontSize={12}
                                name="attendStartTimeHour"
                                label="時"
                                defaultValue={() =>
                                  attendStartTime.hour
                                    ? attendStartTime.hour
                                    : NOT_SELECTED
                                }
                                onChange={handleChangeAttendStartTime}
                                disabled={isRestrictedRole}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl
                              fullWidth
                              className={classes.attendDateTypeDropdown}
                            >
                              <Dropdown
                                items={[
                                  { value: NOT_SELECTED, label: '--' },
                                  { value: '0', label: '0' },
                                  { value: '15', label: '15' },
                                  { value: '30', label: '30' },
                                  { value: '45', label: '45' }
                                ]}
                                fontSize={12}
                                itemFontSize={12}
                                name="attendStartTimeMinute"
                                label="分"
                                defaultValue={() =>
                                  attendStartTime.minute
                                    ? attendStartTime.minute
                                    : NOT_SELECTED
                                }
                                onChange={handleChangeAttendStartTime}
                                disabled={isRestrictedRole}
                              />
                            </FormControl>
                          </Grid>
                          <div className={classes.timeItem}>〜</div>
                          <Grid item xs={2}>
                            <FormControl
                              fullWidth
                              className={classes.attendDateTypeDropdown}
                            >
                              <Dropdown
                                items={[
                                  { value: NOT_SELECTED, label: '--' },
                                  ...Array.from(Array(24).keys()).map(i => ({
                                    value: i.toString(),
                                    label: i.toString()
                                  }))
                                ]}
                                fontSize={12}
                                itemFontSize={12}
                                name="attendEndTimeHour"
                                label="時"
                                defaultValue={() =>
                                  attendEndTime.hour
                                    ? attendEndTime.hour
                                    : NOT_SELECTED
                                }
                                onChange={handleChangeAttendEndTime}
                                disabled={isRestrictedRole}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl
                              fullWidth
                              className={classes.attendDateTypeDropdown}
                            >
                              <Dropdown
                                items={[
                                  { value: NOT_SELECTED, label: '--' },
                                  { value: '0', label: '0' },
                                  { value: '15', label: '15' },
                                  { value: '30', label: '30' },
                                  { value: '45', label: '45' }
                                ]}
                                fontSize={12}
                                itemFontSize={12}
                                name="attendEndTimeMinute"
                                label="分"
                                defaultValue={() =>
                                  attendEndTime.minute
                                    ? attendEndTime.minute
                                    : NOT_SELECTED
                                }
                                onChange={handleChangeAttendEndTime}
                                disabled={isRestrictedRole}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          spacing={0}
                          className={classes.errorMessage}
                        >
                          {validate &&
                            validate.attendStartEndTime &&
                            validate.attendStartEndTime.message && (
                              <Grid item>
                                {validate.attendStartEndTime.message}
                              </Grid>
                            )}
                        </Grid>
                        <Grid container alignItems="center" spacing={0}>
                          <Grid item xs={3}>
                            <FormControlLabel
                              value={'2'}
                              control={<Radio color="primary" />}
                              label={
                                <>
                                  時間
                                  <br />
                                  指定
                                </>
                              }
                              className={classes.attendDateTypeLabel}
                              disabled={isRestrictedRole}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl
                              fullWidth
                              className={classes.attendDateTypeDropdown}
                            >
                              <Dropdown
                                items={[
                                  { value: NOT_SELECTED, label: '--' },
                                  ...Array.from(Array(24).keys()).map(i => ({
                                    value: i.toString(),
                                    label: i.toString()
                                  }))
                                ]}
                                fontSize={12}
                                itemFontSize={12}
                                name="attendTimeHour"
                                label="時"
                                defaultValue={() =>
                                  attendTime.hour
                                    ? attendTime.hour
                                    : NOT_SELECTED
                                }
                                onChange={handleChangeAttendTime}
                                disabled={isRestrictedRole}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl
                              fullWidth
                              className={classes.attendDateTypeDropdown}
                            >
                              <Dropdown
                                items={[
                                  { value: NOT_SELECTED, label: '--' },
                                  { value: '0', label: '0' },
                                  { value: '15', label: '15' },
                                  { value: '30', label: '30' },
                                  { value: '45', label: '45' }
                                ]}
                                fontSize={12}
                                itemFontSize={12}
                                name="attendTimeMinute"
                                label="分"
                                defaultValue={() =>
                                  attendTime.minute
                                    ? attendTime.minute
                                    : NOT_SELECTED
                                }
                                onChange={handleChangeAttendTime}
                                disabled={isRestrictedRole}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          spacing={0}
                          className={classes.errorMessage}
                        >
                          {validate &&
                            validate.attendTime &&
                            validate.attendTime.message && (
                              <Grid item>{validate.attendTime.message}</Grid>
                            )}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} spacing={4} className={classes.left}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noContactEnabled}
                        onChange={handleChangeNoContactEnabled}
                        disabled={isRestrictedRole}
                      />
                    }
                    label="連絡を待たず確定日時を設定"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        )}

      <FormLayout>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} spacing={4} className={classes.textRight}>
            <div className={classes.centerGray}>
              「保存して通知」ボタンを押すと編集内容を保存し、入居者に通知します。
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.center}>
          <Grid item xs={3} sm={3} spacing={2} className={classes.textRight}>
            <Button
              variant="contained"
              className={classes.secondaryButton}
              onClick={handleCancel}
              disabled={isButtonDisabled}
            >
              キャンセル
            </Button>
          </Grid>
          <Grid item xs={3} sm={3} spacing={2}>
            <Button
              variant="contained"
              type="submit"
              className={classes.primaryButton}
              disabled={isButtonDisabled}
            >
              保存して通知
            </Button>
          </Grid>
        </Grid>
      </FormLayout>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ResidentCancellationApplicationForm))
