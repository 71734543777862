/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

export const CHANGE_HEADER_BODY = 'CHANGE_HEADER_BODY'

export const SIGNED = 'SIGNED'
export const ADMIN = 'ADMIN'

export const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const HANDLE_CLEAR = 'HANDLE_CLEAR'

export const SET_LOAD_DATA = 'SET_LOAD_DATA'

export const OPEN_MODAL_DIALOG = 'OPEN_MODAL_DIALOG'
export const CLOSE_MODAL_DIALOG = 'CLOSE_MODAL_DIALOG'

export const SET_ROLE_LIST = 'SET_ROLE_LIST'

export const CHANGE_SEARCH_CONDITION = 'CHANGE_SEARCH_CONDITION'
export const CLEAR_SEARCH_CONDITION = 'CLEAR_SEARCH_CONDITION'
export const OPEN_SEARCH_MODAL_DIALOG = 'OPEN_SEARCH_MODAL_DIALOG'
export const CLOSE_SEARCH_MODAL_DIALOG = 'CLOSE_SEARCH_MODAL_DIALOG'
export const OPEN_SEARCH_SAVE_MODAL_DIALOG = 'OPEN_SEARCH_SAVE_MODAL_DIALOG'
export const CLOSE_SEARCH_SAVE_MODAL_DIALOG = 'CLOSE_SEARCH_SAVE_MODAL_DIALOG'

export const SET_CSV_INFORMATION = 'SET_CSV_INFORMATION'
export const SET_MATCH_CSV_VALUE = 'SET_MATCH_CSV_VALUE'
export const SET_TARGET_ID_LIST = 'SET_TARGET_ID_LIST'
export const SET_CSV_HEADER = 'SET_CSV_HEADER'
export const SET_MATCH_CONTRACT_ID_LIST = 'SET_MATCH_CONTRACT_ID_LIST'
export const SET_SELECT_DELIVERY_LIST_ID = 'SET_SELECT_DELIVERY_LIST_ID'
export const SET_SELECT_CONTRACT_LIST = 'SET_SELECT_CONTRACT_LIST'
export const SET_FILTER_CSV_INFORMATION = 'SET_FILTER_CSV_INFORMATION'
export const SET_BULK_DELIVERY_DETAIL_LIST = 'SET_BULK_DELIVERY_DETAIL_LIST'
export const SET_IMAGE_LIST = 'SET_IMAGE_LIST'
export const SET_PDF_LIST = 'SET_PDF_LIST'

export const MESSAGE = 'MESSAGE'
export const OWNER_MESSAGE = 'OWNER_MESSAGE'
export const BULLETIN_BOARD = 'BULLETIN_BOARD'
export const RESIDENT_INFO_CHANGE = 'RESIDENT_INFO_CHANGE'
export const RESIDENT_CANCELLATION = 'RESIDENT_CANCELLATION'
export const ROOM_CHECK_LIST = 'ROOM_CHECK_LIST'
export const BICYCLE_PARKING_STICKER = 'BICYCLE_PARKING_STICKER'
export const COC2_USE_PARKING = 'COC2_USE_PARKING'
export const PET = 'PET'
export const BUILDING = 'BUILDING'
export const CONTRACT = 'CONTRACT'
export const FAQ = 'FAQ'
export const MESSAGE_TEMPLATE = 'MESSAGE_TEMPLATE'
export const COMPANY_USER = 'COMPANY_USER'
export const SETTING_BUILDING = 'SETTING_BUILDING'
export const DELETE_SEARCH_CONDITIONS = 'DELETE_SEARCH_CONDITIONS'
