import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import * as mutations from 'graphql/mutations'
import { Cache } from 'aws-amplify'
import { addBreadcrumb, captureException } from './sentry'

export const QueryHelper = async (method, params = {}, cache = false) => {
  try {
    addBreadcrumb('QueryHelper', method + ' call', params)
    const response = await API.graphql(
      graphqlOperation(queries[method], params)
    )
    if (response.errors) return { error: response.errors }
    const result = response.data[method]
    if (cache) Cache.setItem(`totono-advertiser-${method}`, result)
    return result
  } catch (error) {
    captureException(error)
    return { error }
  }
}

export const MutationHelper = async (method, params = {}, cache = false) => {
  addBreadcrumb('MutationHelper', method + ' call', params)
  try {
    const response = await API.graphql(
      graphqlOperation(mutations[method], { ...params })
    )
    if (response.errors) return { error: response.errors }
    const result = response.data[method]
    if (cache) Cache.setItem(`totono-advertiser-${method}`, result)
    return result
  } catch (error) {
    captureException(error)
    return { error }
  }
}
