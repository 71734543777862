import React from 'react'
import { ContractKykStatusDiv } from 'utils/enum'
import Label from './Label'

export default function KeiyakuStatusDivLabel({
  kykStatusDiv,
  kykStatusDivName
}) {
  if (kykStatusDiv === ContractKykStatusDiv.application.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.application.value} />
    )
  } else if (kykStatusDiv === ContractKykStatusDiv.contract.code) {
    return <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
  } else if (kykStatusDiv === ContractKykStatusDiv.cancellation.code) {
    return (
      <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
    )
  } else if (kykStatusDiv === ContractKykStatusDiv.completed.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.completed.value} />
  } else if (kykStatusDiv === ContractKykStatusDiv.payOff.code) {
    return <Label color={'gray'} text={ContractKykStatusDiv.payOff.value} />
  } else if (kykStatusDiv === ContractKykStatusDiv.cancel.value) {
    return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
  } else if (kykStatusDivName && kykStatusDivName !== '') {
    return <Label color={'green'} text={kykStatusDivName} />
  } else {
    return <></>
  }
}
