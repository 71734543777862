import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withSnackbar } from 'notistack'
import { Auth, Storage } from 'aws-amplify'
import { QueryHelper } from 'utils/api.utils'
import { SearchConditionContext } from 'context/SearchConditionContext'
import { useDisplayUserInformationHeader } from '../../../../../hooks/useDisplayUserInformationHeader'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  button: {
    marginLeft: '16px',
    color: '#fff'
  },
  primaryButton: {
    marginLeft: '16px',
    marginRight: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  }
}))

function Body(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [attachment, setAttachment] = useState(null)
  const [searchConditionContext] = React.useContext(SearchConditionContext)
  const {
    outputApplicationUserInformation,
    formatResidentInfo,
    isLoading
  } = useDisplayUserInformationHeader(
    'getRoomCheckListApplication',
    'application',
    props.enqueueSnackbar,
    props.title
  )

  useEffect(() => {
    if (
      searchConditionContext &&
      searchConditionContext.searchCondition &&
      searchConditionContext.searchCondition.roomCheckListApplicationId
    ) {
      fetchData(
        searchConditionContext.searchCondition.roomCheckListApplicationId
      )
    }
  }, [searchConditionContext])

  const fetchData = async applicationId => {
    await Promise.all([fetchApplicationConvertData(applicationId)])
  }

  const fetchApplicationConvertData = async applicationId => {
    const params = {
      application_id: {
        eq: applicationId
      },
      convert_status: {
        eq: 3
      }
    }
    const result = await QueryHelper('getApplicationConvertList', {
      filter: params
    })
    if (!result.error) {
      setAttachment(
        result.data && result.data.length > 0
          ? result.data[0].attachment[0]
          : null
      )
    }
  }

  const pdfDownLoad = async event => {
    await downloadFile()
  }

  async function downloadFile() {
    await Auth.currentCredentials().then(async creds => {
      const key = attachment.key
      Storage.get(key, {
        level: 'protected',
        identityId: attachment.company_id,
        expires: 60
      })
        .then(result => {
          const link = document.createElement('a')
          link.href = result
          link.click()
        })
        .catch(err => {
          console.log('error: ', err)
        })
    })
  }

  useEffect(() => {
    outputApplicationUserInformation()
  }, [props])

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={e =>
          history.push({
            pathname: `/application/roomCheckList/list/${
              location.state !== undefined && 'pageNumber' in location.state
                ? location.state.pageNumber
                : 1
            }`,
            state: location.state
          })
        }
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>
        {!isLoading ? (
          formatResidentInfo
        ) : (
          <Loading isLoading={true} marginTop={20} marginBottom={20} />
        )}
      </Typography>
      {attachment && (
        <Button
          variant="contained"
          className={classes.primaryButton}
          onClick={pdfDownLoad}
        >
          PDF出力
        </Button>
      )}
    </>
  )
}

export default withSnackbar(Body)
