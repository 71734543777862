import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import Typography from '@material-ui/core/Typography'
import Loading from 'components/common/Loading'
import { ErrorMessages } from 'utils/errorMessages'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '13px'
  },
  note: {
    fontSize: '14px',
    paddingTop: '5px'
  }
}))

const PrivacyPolicyForm = props => {
  const classes = useStyles()
  const { isLoading, company, setEnableForm } = props
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()

  const [values, setValues] = useState({
    privacy_policy_url: '',
    privacy_policy_url_err: ''
  })

  const isUrl = str => {
    const reg = new RegExp(
      /^(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/g
    )
    return reg.test(str)
  }

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }

    if (!value || isUrl(value)) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = ErrorMessages.UrlError
    }

    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  const checkErr = objects => {
    if (objects.privacy_policy_url_err) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  useEffect(() => {
    const companyExists = 'company' in company && company.company
    const privacyPolicyUrl = companyExists
      ? company.company.privacy_policy_url
      : ''

    const initializeValues = {
      privacy_policy_url: privacyPolicyUrl,
      privacy_policy_url_err:
        privacyPolicyUrl && !isUrl(privacyPolicyUrl)
          ? ErrorMessages.UrlError
          : ''
    }

    setValues(initializeValues)
    checkErr(initializeValues)
  }, [company, setValues])

  return (
    <FormLayout title="プライバシーポリシー設定">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {company && (
            <Grid container spacing={2} alignItems="center">
              <Grid container>
                <TextInput
                  error={values.privacy_policy_url_err != '' ? true : false}
                  helperText={values.privacy_policy_url_err}
                  id="privacy_policy_url"
                  name="privacy_policy_url"
                  label="プライバシーポリシーリンク"
                  fullWidth
                  variant="filled"
                  defaultValue={values.privacy_policy_url}
                  onChange={handleInputChange}
                  disabled={!isUnrestrictedRole}
                />
              </Grid>
              <Grid container>
                <Typography className={classes.note}>
                  ※プライバシーポリシーホームページURLを入力してください
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyForm)
