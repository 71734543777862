import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setAdmin, setLoadData, setSignin } from 'actions/Actions'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as mutations from 'graphql/mutations'
import Amplify, { Cache } from 'aws-amplify'
import { LastPathContext } from 'context/LastPathContext'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Divider from '@material-ui/core/Divider'
import { withSnackbar } from 'notistack'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { MutationHelper } from 'utils/api.utils'
import { getDecryptedString, getIvAndEncryptedString } from 'utils/crypto.utils'
import { AuthIpAddress } from 'components/common/AuthIpAddress'
import { ErrorMessages } from 'utils/errorMessages'

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY
const env = process.env.REACT_APP_ENV
var AWS = require('aws-sdk')
AWS.config.region = process.env.REACT_APP_AWS_REGION
var roleArn = process.env.REACT_APP_AWS_COGNITO_ROLE_ARN
var customHeaderName = process.env.REACT_APP_HEADER_WEB_IDENTITY_TOKEN

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const useStyles = makeStyles(theme => ({
  // root: {
  //   height: '465',
  // },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(5, 6)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.c_purple.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  border: {
    margin: theme.spacing(4, 0)
  }
}))

function Signin(props) {
  const classes = useStyles()
  const [{ lastPath }, dispatch] = React.useContext(LastPathContext)
  const history = useHistory()
  const query = useQuery()
  const [validate, setValidate] = useState({})
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isRemember, setRemember] = useState(false)
  const [isAuth, setIsAuth] = useState(false)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const handleChangeShowPassword = event => {
    setIsShowPassword(!isShowPassword)
  }

  const refreshToken = params => {
    setIsAuth(true)
    props.enqueueSnackbar('再接続を試みています。')
    API.graphql(graphqlOperation(mutations.tokenRefresh, params))
      .then(response => {
        const data = response.data.tokenRefresh
        console.log(JSON.stringify(data))
        props.setAdmin(data.is_administrator)
        AWS.config.credentials = new AWS.WebIdentityCredentials({
          RoleArn: roleArn,
          WebIdentityToken: data.token,
          User: {
            Id: data.user_id,
            CompanyId: data.company_id
          },
          DurationSeconds: 43200
        })
        AWS.config.credentials.get(async err => {
          if (err) {
            console.log(JSON.stringify(err))
            props.setLoadData(false)
            setIsAuth(false)
          } else {
            Amplify.configure({
              API: {
                graphql_headers: async () => ({
                  [customHeaderName]: data.token
                })
              }
            })
            isRemember
              ? localStorage.setItem(
                  'totono-advertiser-session',
                  JSON.stringify(data)
                )
              : sessionStorage.setItem(
                  'totono-advertiser-session',
                  JSON.stringify(data)
                )
            props.setSignin(true)
            props.setLoadData(false)
            const result = await AuthIpAddress(history)
            const savedPath = sessionStorage.getItem('lastPath') || lastPath
            if (result) {
              history.push(savedPath)
              sessionStorage.removeItem('lastPath')
            } else if (result === false) {
              props.enqueueSnackbar(
                ErrorMessages.FailedToAuthenticateIPAddress,
                {
                  variant: 'error'
                }
              )
              setIsAuth(false)
            } else {
              setIsAuth(false)
            }
          }
        })
      })
      .catch(err => {
        console.log(JSON.stringify(err))
        props.setLoadData(false)
        setIsAuth(false)
      })
  }

  const getSubDomain = () => {
    const subDomain = window.location.host.split('.').shift()
    if ('mng-web' != subDomain && env === 'production') {
      return subDomain
    }
    return ''
  }

  const authSubDomain = async () => {
    const subDomain = getSubDomain()
    if (!subDomain) {
      return
    }
    let result = await MutationHelper(
      'authSubDomain',
      { subDomain: subDomain ? subDomain : '' },
      false
    )
    if (result.error) {
      if (result.error.errors[0].errorType == 401) {
        setIsAuth(false)
        props.setSignin(false)
        localStorage.removeItem('totono-advertiser-session')
        sessionStorage.removeItem('totono-advertiser-session')
        Cache.clear()
        window.location.href = 'https://mng-web.totono.app/authorize/signin'
        return
      }
      props.enqueueSnackbar('サブドメインの認証に失敗しました。', {
        variant: 'error'
      })
    }
  }

  const proxySignin = () => {
    props.setLoadData(true)

    if (!query.get('iv') || !query.get('encrypted')) {
      props.setLoadData(false)
      return
    }

    let item
    try {
      item = getDecryptedString(
        {
          encryptedString: query.get('encrypted'),
          ivString: query.get('iv')
        },
        encryptionKey
      )
    } catch (e) {
      props.setLoadData(false)
      return
    }

    const data = JSON.parse(item)
    sessionStorage.setItem('totono-advertiser-session', JSON.stringify(data))

    AWS.config.credentials = new AWS.WebIdentityCredentials({
      RoleArn: roleArn,
      WebIdentityToken: data.token,
      User: {
        Id: data.user_id,
        CompanyId: data.company_id
      },
      DurationSeconds: 43200
    })
    AWS.config.credentials.get(async err => {
      if (err) {
        console.log(JSON.stringify(err))
        AWS.config.credentials = null
        // refreshする
        refreshToken(data)
      } else {
        if (AWS.config.credentials.needsRefresh()) {
          AWS.config.credentials.refresh(function(err) {
            if (err) {
              console.log(JSON.stringify(err))
              props.setLoadData(false)
              setIsAuth(false)
            } else {
              // refreshする
              refreshToken(data)
            }
          })
        } else {
          Amplify.configure({
            API: {
              graphql_headers: async () => ({
                [customHeaderName]: data.token
              })
            }
          })
          props.setSignin(true)
          props.setLoadData(false)
          const result = await AuthIpAddress(history)
          const savedPath = sessionStorage.getItem('lastPath') || lastPath
          if (result) {
            history.push(savedPath)
            sessionStorage.removeItem('lastPath')
          } else if (result === false) {
            props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
              variant: 'error'
            })
            setIsAuth(false)
          } else {
            setIsAuth(false)
          }
        }
      }
    })
  }

  useEffect(() => {
    // 共通URLからのログインで個別URLに変更する場合の処理
    if (query.get('iv') && query.get('encrypted')) {
      proxySignin()
      return
    }

    let item = localStorage.getItem('totono-advertiser-session')
    if (item) {
      setRemember(true)
    } else {
      item = sessionStorage.getItem('totono-advertiser-session')
    }
    if (!item) {
      authSubDomain()
      return
    }
    setIsAuth(true)
    props.setLoadData(true)

    const data = JSON.parse(item)
    AWS.config.credentials = new AWS.WebIdentityCredentials({
      RoleArn: roleArn,
      WebIdentityToken: data.token,
      User: {
        Id: data.user_id,
        CompanyId: data.company_id
      },
      DurationSeconds: 43200
    })
    AWS.config.credentials.get(async err => {
      authSubDomain()
      if (err) {
        console.log(JSON.stringify(err))
        AWS.config.credentials = null
        // refreshする
        refreshToken(data)
      } else {
        if (AWS.config.credentials.needsRefresh()) {
          AWS.config.credentials.refresh(function(err) {
            if (err) {
              console.log(JSON.stringify(err))
              props.setLoadData(false)
              setIsAuth(false)
            } else {
              // refreshする
              refreshToken(data)
            }
          })
        } else {
          Amplify.configure({
            API: {
              graphql_headers: async () => ({
                [customHeaderName]: data.token
              })
            }
          })
          props.setSignin(true)
          props.setLoadData(false)
          const result = await AuthIpAddress(history)
          if (result) {
            history.push(lastPath)
          } else if (result === false) {
            props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
              variant: 'error'
            })
            setIsAuth(false)
          } else {
            setIsAuth(false)
          }
        }
      }
    })
  }, [isRemember, props])

  const handleSubmit = e => {
    e.preventDefault()
    props.setLoadData(true)
    if (validateForm()) {
      props.setLoadData(false)
      return
    }
    const subDomain = getSubDomain()
    const params = {
      mail: email,
      password: password,
      subDomain: subDomain
    }
    API.graphql(graphqlOperation(mutations.authSignin, params))
      .then(response => {
        const data = response.data.authSignin
        console.log(JSON.stringify(data))
        if (data.sub_domain && data.sub_domain != subDomain) {
          const encrypted = getIvAndEncryptedString(
            encryptionKey,
            JSON.stringify(data)
          )
          // NOTE: メール通知記載のURLを押したとき、該当の詳細画面へ遷移させるためにurlを保存する
          sessionStorage.setItem('lastPath', lastPath)
          const origin = `https://${data.sub_domain}.mng-web.totono.app`
          window.location.href = `${origin}/authorize/signin?iv=${encrypted.ivString}&encrypted=${encrypted.encryptedString}`
          return
        }
        props.setAdmin(data.is_administrator)
        AWS.config.credentials = new AWS.WebIdentityCredentials({
          RoleArn: roleArn,
          WebIdentityToken: data.token,
          User: {
            Id: data.user_id,
            CompanyId: data.company_id
          },
          DurationSeconds: 43200
        })
        AWS.config.credentials.get(async () => {
          Amplify.configure({
            API: {
              graphql_headers: async () => ({
                [customHeaderName]: data.token
              })
            }
          })
          isRemember
            ? localStorage.setItem(
                'totono-advertiser-session',
                JSON.stringify(data)
              )
            : sessionStorage.setItem(
                'totono-advertiser-session',
                JSON.stringify(data)
              )
          props.setSignin(true)
          props.setLoadData(false)
          const result = await AuthIpAddress(history)
          if (result) {
            history.push(lastPath)
          } else if (result === false) {
            props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
              variant: 'error'
            })
            setIsAuth(false)
          } else {
            setIsAuth(false)
          }
        })
      })
      .catch(error => {
        if (
          error.errors[0].message == 'Client IPAddress is not Authenticated'
        ) {
          props.enqueueSnackbar(
            <div>
              ログインに失敗しました
              <br />
              許可されていないIPアドレスです
              <br />
              設定されたIPアドレスより再度ログインをお試しください
            </div>,
            {
              variant: 'error'
            }
          )
        } else {
          props.enqueueSnackbar(`ログインに失敗しました`, {
            variant: 'error'
          })
        }
        props.setLoadData(false)
      })
  }

  function validateForm() {
    const valid = {}
    if (!email || email === '') {
      valid.email = {}
      valid.email.message = 'メールアドレスは必須です'
    }
    if (!password || password === '') {
      valid.password = {}
      valid.password.message = 'パスワードは必須です'
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  return (
    <>
      {!isAuth && (
        <div
          style={{
            height: '100vh',
            backgroundColor: '#696cff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid container style={{ width: 465, height: 465 }}>
            <CssBaseline />
            <Grid
              item
              // sm={4}
              // md={6}
              style={{
                backgroundColor: '#ffffff'
              }}
            >
              <div>
                <div className={classes.paper}>
                  <Grid container>
                    <Grid item>
                      <Avatar variant="rounded" className={classes.avatar}>
                        <VpnKeyIcon />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography component="h2" variant="h6">
                        Login
                      </Typography>
                      <Typography
                        variant="body2"
                        component="h2"
                        color="primary"
                      >
                        totono管理画面へログイン
                      </Typography>
                    </Grid>
                  </Grid>
                  <form className={classes.form} noValidate>
                    <TextField
                      variant="filled"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="メールアドレス"
                      name="email"
                      autoFocus
                      onChange={event => setEmail(event.target.value)}
                      error={
                        validate && validate.email && validate.email.message
                      }
                      helperText={
                        validate && validate.email && validate.email.message
                          ? validate.email.message
                          : null
                      }
                    />
                    <TextField
                      variant="filled"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="パスワード"
                      type={isShowPassword ? 'text ' : 'password'}
                      id="password"
                      onChange={event => setPassword(event.target.value)}
                      error={
                        validate &&
                        validate.password &&
                        validate.password.message
                      }
                      helperText={
                        validate &&
                        validate.password &&
                        validate.password.message
                          ? validate.password.message
                          : null
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleChangeShowPassword}
                            >
                              {isShowPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                    >
                      ログイン
                    </Button>
                  </form>
                </div>
                <Divider className={classes.border} />
                <Grid container justify="center">
                  <Grid item>
                    <Link href="#" variant="body2">
                      パスワードを忘れた場合は管理者に連絡してください
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {/* <Grid
              item
              sm={4}
              md={6}
              style={{
                backgroundColor: "#f6f8fa"
              }}
            >
            </Grid> */}
          </Grid>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setSignin: isSignin => {
      dispatch(setSignin(isSignin))
    },
    setAdmin: isAdmin => {
      dispatch(setAdmin(isAdmin))
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Signin))
