import { useLoginInformation } from 'hooks/useLoginInformation'
import { CompanyGroupRemark } from 'utils/enum'

export const useRole = () => {
  const { judgmentRoleId } = useLoginInformation()
  const roleId = judgmentRoleId()

  const role1 = () => {
    return roleId === CompanyGroupRemark.adminUser.code
  }

  const role3 = () => {
    return roleId === CompanyGroupRemark.readUser.code
  }

  const role4 = () => {
    return roleId === CompanyGroupRemark.supportCenterAdminUser.code
  }

  const role6 = () => {
    return roleId === CompanyGroupRemark.supportCenterGeneralUser.code
  }

  const role12 = () => {
    return (
      roleId === CompanyGroupRemark.adminUser.code ||
      roleId === CompanyGroupRemark.generalUser.code
    )
  }

  const role124 = () => {
    return (
      roleId === CompanyGroupRemark.adminUser.code ||
      roleId === CompanyGroupRemark.generalUser.code ||
      roleId === CompanyGroupRemark.supportCenterAdminUser.code
    )
  }

  const role35 = () => {
    return (
      roleId === CompanyGroupRemark.readUser.code ||
      roleId === CompanyGroupRemark.externalUser.code
    )
  }

  return {
    role1,
    role3,
    role4,
    role6,
    role12,
    role124,
    role35
  }
}
