import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  confirm: {
    textAlign: 'center',
    height: '114px',
    backgroundColor: '#FFFFFF'
  },
  confirmText: {
    fontFamily: 'Hiragino Sans W3',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2C3F58',
    textAlign: 'center',
    paddingTop: '20px'
  },
  confirmButtonItem: {
    justifyContent: 'center'
  },
  confirmButton: {
    color: theme.palette.c_blue.main,
    backgroundColor: theme.palette.c_white.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.main
    }
  },
  clickedConfirmButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.main
    }
  }
}))

function MessageConfirmDetail(props) {
  const classes = useStyles()
  const message = props && props.message ? props.message : null

  function confirmedButtonClassName(messageTypeId) {
    if (messageTypeId === 9) {
      return classes.clickedConfirmButton
    } else {
      return classes.confirmButton
    }
  }

  return (
    <>
      {(message.message_type_id === 8 || message.message_type_id === 9) && (
        <div className={classes.confirm}>
          <p className={classes.confirmText}>回答ボタン</p>
          <Grid
            container
            alignItems={'center'}
            spacing={2}
            className={classes.confirmButtonItem}
          >
            <Grid item alignItems={'center'} xs={6} sm={3}>
              <Button
                variant="contained"
                className={confirmedButtonClassName(message.message_type_id)}
                disabled={message.message_type_id === 8}
              >
                {message.body}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default MessageConfirmDetail
