import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormLayout from 'components/common/FormLayout'
import { Divider } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { handleChange } from 'actions/Actions'
import TextField from '@material-ui/core/TextField'
import DateLabel3 from 'components/common/DateLabel3'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  footer: {
    flex: 1,
    display: 'flex',
    height: '68px',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '20px'
  },
  footerLeft: {
    flex: 1,
    display: 'flex'
  },
  lastUpdated: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#72809D'
  },
  lastUpdatedTitle: {
    float: 'left',
    marginRight: '10px'
  },
  footerRight: {
    marginLeft: '16px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  }
}))

function formatDateLabel(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr)
    return <DateLabel3 date={date} color={'#72809D'} />
  }
  return ''
}

function BuildingForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const { isLoading, building } = props
  const [remark, setRemark] = useState(
    'building' in building && building.building ? building.building.remark : ''
  )
  const [orgRemark, setOrgRemark] = useState(
    'building' in building && building.building ? building.building.remark : ''
  )

  useEffect(() => {
    if (props.building && props.building.building) {
      if (props.building.building.remark) {
        if (!orgRemark) {
          setOrgRemark(props.building.building.remark)
        }
        if (!remark || remark !== '') {
          setRemark(props.building.building.remark)
        }
      }
    }
  }, [props])

  const handleChange = event => {
    props.handleChange({
      [event.target.name]: event.target.value
    })
    setRemark(event.target.value)
  }

  return (
    <>
      <FormLayout title="建物備考">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {isLoading ? (
              <Loading isLoading={isLoading} />
            ) : (
              <TextField
                id="remark"
                name="remark"
                label="備考を記載"
                fullWidth
                multiline={true}
                rows={8}
                autoComplete="remark"
                variant="filled"
                defaultValue={
                  'building' in building && building.building
                    ? building.building.remark
                    : ''
                }
                value={remark}
                onChange={handleChange}
                disabled={isRestrictedRole}
              />
            )}
          </Grid>
        </Grid>
      </FormLayout>
      <Divider />
      <div className={classes.footer}>
        <div className={classes.footerLeft}>
          {building.mst_tateya &&
            (building.mst_tateya.TTY_UPDATED_DATE ||
              building.mst_tateya.TTY_INSERTED_DATE) && (
              <Grid className={classes.lastUpdated} container xs={8}>
                <Grid item className={classes.lastUpdatedTitle}>
                  最終更新
                </Grid>
                {building.mst_tateya.TTY_UPDATED_DATE
                  ? formatDateLabel(building.mst_tateya.TTY_UPDATED_DATE)
                  : formatDateLabel(building.mst_tateya.TTY_INSERTED_DATE)}
              </Grid>
            )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(BuildingForm)))
