import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { QueryHelper } from 'utils/api.utils'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Loading from '../Loading'
import { AuthIpAddress } from 'components/common/AuthIpAddress'
import { ErrorMessages } from 'utils/errorMessages'

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    marginLeft: 10
  },
  title: {
    color: '#fff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    alignItems: 'left'
  },
  img: {
    width: 40,
    height: 40,
    alignItems: 'center'
  }
}))

function Left({ text, ...props }) {
  const classes = useStyles()
  const [companyUser, setCompanyUser] = useState(null)
  const [company, setCompany] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      const result = await AuthIpAddress(history)
      if (result) {
        fetchData()
      } else if (result === false) {
        props.enqueueSnackbar(ErrorMessages.FailedToAuthenticateIPAddress, {
          variant: 'error'
        })
      }
    })()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([fetchUserData(), fetchCompany()])
    setIsLoading(false)
  }

  const fetchUserData = async () => {
    const item = sessionStorage.getItem('totono-advertiser-session')
      ? sessionStorage.getItem('totono-advertiser-session')
      : localStorage.getItem('totono-advertiser-session')

    const data = JSON.parse(item)
    const result = await QueryHelper(
      'getCompanyUser',
      { filter: { id: { eq: data.user_id } } },
      false
    )

    if (result.error) {
      props.enqueueSnackbar('ユーザ情報が取得できませんでした', {
        variant: 'error'
      })
      return
    }
    setCompanyUser(result.company_user)
  }

  const fetchCompany = async () => {
    const result = await QueryHelper('getCompany', {})
    if (!result.error) {
      setCompany(result.company)
    } else {
      props.enqueueSnackbar(`会社情報を取得できませんでした。`)
    }
  }

  const handleClick = async () => {
    history.push('/')
  }

  return (
    <div {...props}>
      <img
        className={classes.img}
        src={`${process.env.PUBLIC_URL}/favicon.png`}
        alt={'totono'}
      />
      <Grid container className={classes.titleWrapper}>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                {company && company.stock_signage === 1 && '株式会社'}
                {company && company.name}
                {company && company.stock_signage === 2 && '株式会社'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                {companyUser && companyUser.name}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default Left
