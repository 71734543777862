import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import List from './List'
import Pagination from 'components/common/Pagination'
import { withSnackbar } from 'notistack'
import { PAGE_LIMIT } from 'utils/const'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  closeModalDialog,
  handleClear,
  openModalDialog,
  setLoadData
} from 'actions/Actions'
import FormModalDialog from './FormModalDialog'
import FormContent from './FormContent'
import FormFooter from './FormFooter'
import Loading from 'components/common/Loading'
import ModalDialogFooter from './ModalDialogFooter'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  primaryButton: {
    color: theme.palette.c_blue.contrastText,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()

  const { pageId } = useParams()

  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [companyGroup, setCompanyGroup] = useState({})
  const [enableForm, setEnableForm] = useState(true)
  const [isErrorRole, setIsErrorRole] = useState(false)
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : {}
  )

  const [companyGroupList, setCompanyGroupList] = useState(initialState)
  const [companyUserList, setCompanyUserList] = useState(initialState)
  const [roleList, setRoleList] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleChangePage = (event, newPage) => {
    var params = Object.assign({}, searchCondition, { pageNumber: newPage + 1 })
    setSearchCondition(params, setPageNumber(newPage + 1))
  }

  const handleCustomChangePage = newPage => {
    var params = Object.assign({}, searchCondition, { pageNumber: newPage })
    setSearchCondition(params, setPageNumber(newPage))
  }

  const handleClick = event => {
    setCompanyGroup({})
    props.openModalDialog()
  }

  async function fetchRoleList() {
    let result = await QueryHelper('getRoleList')
    if (result.error) {
      props.enqueueSnackbar('権限一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setRoleList(result)
    }
  }

  async function fetchCompanyGroupList(offset, limit) {
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCompanyGroupList(result)
    }
  }

  async function fetchCompanyUserList(offset, limit) {
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
      return
    }
    setCompanyUserList(result)
  }

  async function postData(event) {
    event.preventDefault()
    if (Object.keys(props.parameters).length > 0) {
      if (!enableForm) {
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        return
      }
      let params = Object.assign({}, props.parameters)
      if (Object.keys(companyGroup).length) {
        props.closeModalDialog()
        props.setLoadData(true)
        params['id'] = companyGroup.id
        const result = await MutationHelper('updateCompanyGroup', {
          input: params
        })
        if (result.error) {
          props.enqueueSnackbar('グループを更新できませんでした', {
            variant: 'error'
          })
          props.setLoadData(false)
        } else {
          const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT + 1 : 0
          fetchCompanyGroupList(offset, PAGE_LIMIT)
          props.handleClear()
          props.enqueueSnackbar('グループを更新しました', {
            variant: 'success'
          })
          props.setLoadData(false)
        }
      } else {
        if (!props.parameters['role_id']) {
          setIsErrorRole(true)
          props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
            variant: 'warning'
          })
          return
        }
        props.closeModalDialog()
        props.setLoadData(true)
        const result = await MutationHelper('createCompanyGroup', {
          input: params
        })
        if (result.error) {
          props.enqueueSnackbar('グループを登録できませんでした', {
            variant: 'error'
          })
        } else {
          const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
          fetchCompanyGroupList(offset, PAGE_LIMIT)
          props.handleClear()
          props.enqueueSnackbar('グループを登録しました', {
            variant: 'success'
          })
          setIsErrorRole(false)
          props.setLoadData(false)
        }
      }
    } else {
      props.enqueueSnackbar('変更がありません', {
        variant: 'warning'
      })
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchRoleList(),
      fetchCompanyGroupList(offset, PAGE_LIMIT),
      fetchCompanyUserList(0, null)
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `/setting/company-group/list/${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, setRoleList, setCompanyGroupList])

  const handleDelete = async event => {
    const count = companyGroupUserCount()
    if (count > 0) {
      props.enqueueSnackbar(
        `${companyGroup.name}には${count}人所属しているため削除できません。`,
        {
          variant: 'error'
        }
      )
      return
    }
    setIsOpen(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    props.closeModalDialog()
    props.setLoadData(true)
    const params = {}
    params['id'] = companyGroup.id
    params['status'] = 3
    const result = await MutationHelper('updateCompanyGroup', { input: params })
    if (result.error) {
      props.enqueueSnackbar('グループを更新できませんでした', {
        variant: 'error'
      })
      props.setLoadData(false)
    } else {
      const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT + 1 : 0
      fetchCompanyGroupList(offset, PAGE_LIMIT)
      props.handleClear()
      if (params['status'] === 3) {
        props.enqueueSnackbar('グループを削除しました', {
          variant: 'success'
        })
      } else {
        props.enqueueSnackbar('グループを更新しました', {
          variant: 'success'
        })
      }
      props.setLoadData(false)
    }
    setIsOpen(false)
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const companyGroupUserCount = () => {
    const userList = companyUserList.data.filter(
      user => user.company_user.company_group_id == companyGroup.id
    )
    const count = userList.length
    return count
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs className={classes.tableTips}>
          <Button
            onClick={event => handleClick(event)}
            variant="contained"
            startIcon={<AddIcon />}
            disableElevation
            className={classes.primaryButton}
            disabled={!isUnrestrictedRole}
          >
            グループを追加
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.tableTips}></Grid>
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={companyGroupList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && companyGroupList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              roleList={roleList.data}
              companyGroupList={companyGroupList.data}
              companyUserList={companyUserList.data}
              setCompanyGroup={setCompanyGroup}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={companyGroupList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <FormModalDialog
        title={
          0 !== Object.keys(companyGroup).length
            ? 'グループを編集'
            : 'グループを追加'
        }
        content={
          <FormContent
            roleList={roleList.data}
            companyGroup={companyGroup}
            setEnableForm={setEnableForm}
            isErrorRole={isErrorRole}
          />
        }
        footer={
          <FormFooter companyGroup={companyGroup} handleDelete={handleDelete} />
        }
        postData={postData}
      />
      <FormModalDialog
        open={isOpen}
        close={handleCancel}
        title="グループの削除"
        description={'※削除すると削除済みグループとして画面上に表示されます。'}
        footer={<ModalDialogFooter handleCancel={handleCancel} />}
        postData={handleSubmit}
        confirmDelete={true}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen,
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    openModalDialog: () => {
      dispatch(openModalDialog())
    },
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
