import { MutationHelper } from 'utils/api.utils'

export const AuthIpAddress = async props => {
  const result = await MutationHelper('authIpAddress')
  if (result.error) {
    if (
      result.error.errors[0].message === 'Client IPAddress is not Authenticated'
    ) {
      props.push({
        pathname: '/error',
        state: {
          mainMessage: '許可されていないIPアドレスです',
          subMessage: '許可されたIPアドレスより再度ログインをお試しください'
        }
      })
      return
    } else {
      return false
    }
  } else {
    return true
  }
}
