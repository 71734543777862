import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import FormLayout from 'components/common/FormLayout'
import RowWithFile from 'components/common/RowWithFile'
import { Auth, Storage } from 'aws-amplify'
import LoadingWithBackground from 'components/common/LoadingWithBackground'
import { MutationHelper } from 'utils/api.utils'
import Divider from '@material-ui/core/Divider'
import uuid from 'uuid/v4'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import { setLoadData } from 'actions/Actions'
import { useRole } from 'hooks/useRole'
import { resizeImage } from 'utils/resizeImage'

const MAXIMUM_FILE_LENGTH = 9

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  input: {
    display: 'none'
  },
  right: {
    textAlign: 'right',
    marginTop: '15px',
    marginBottom: '15px'
  },
  primaryButton: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  secondaryButton: {
    marginRight: '15px',
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  imageTitle: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  imageAddButton: {
    marginTop: '15px',
    color: '#0F8CF0'
  },
  remarkRoot: {
    backgroundColor: theme.palette.background.paper,
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px'
  }
}))

async function getImages(application, attachments) {
  const contentImageIdSet = new Set()
  if (application && application.content) {
    const contents = JSON.parse(application.content)
    for (let content of contents) {
      if (content && content.body && Object.keys(content.body).length === 0) {
        continue
      }
      for (let body of content.body) {
        if (
          body &&
          content.images &&
          Object.keys(content.images).length === 0
        ) {
          continue
        }
        for (let image of body.images) {
          contentImageIdSet.add(image.id)
        }
      }
    }
    const images = []
    for (let attachment of attachments) {
      if (!contentImageIdSet.has(String(attachment.id))) {
        images.push(attachment)
      }
    }
    return images
  }
  return []
}

function RoomCheckListApplicationForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const [images, setImages] = useState([])
  const [orgImages, setOrgImages] = useState([])
  const [deletedImages, setDeletedImages] = useState([])

  const [remark, setRemark] = useState(
    props.application && props.application.remark
      ? props.application.remark
      : ''
  )
  const [orgRemark, setOrgRemark] = useState(
    props.application && props.application.remark
      ? props.application.remark
      : ''
  )

  const [isChange, setIsChange] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (props.application) {
      const application = props.application
      setRemark(application.remark)
      setOrgRemark(application.remark)
    }
    if (props.attachments && props.attachments.length > 0) {
      const attachments = props.attachments
      fetchImages(props.application, attachments)
    } else {
      setImages([])
      setOrgImages([])
    }
  }, [props, props.application, props.attachments])

  async function fetchImages(application, attachments) {
    const targetImages = await getImages(application, attachments)
    if (targetImages.length === 0) {
      setImages([])
      setOrgImages([])
      return
    }
    const data = []
    for (let imageData of targetImages) {
      data.push(await getImage(imageData))
    }
    setImages(data)
    setOrgImages(data)
  }

  async function getImage(image) {
    const key = image.key
    console.log(key)
    const result = await getStorage(key, image.company_id)
    console.log(result)
    return Object.assign({}, image, { body: result })
  }

  async function getStorage(key, companyId) {
    return Storage.get(key, {
      level: 'protected',
      identityId: companyId,
      expires: 60
    })
  }

  const handleChangeImage = async event => {
    const file = event.target.files[0]
    // 同じ画像を選択する場合を考慮し、リセットする
    event.target.value = ''
    if (!file) return
    setIsChange(true)
    setIsLoading(true)
    try {
      const resizedFile = await resizeImage(file, 1200, 1200)
      const reader = new FileReader()
      reader.onload = () => {
        resizedFile.status = 1
        resizedFile.attachment_type_id = 1
        resizedFile.mime_type = resizedFile.type
        resizedFile.filename = resizedFile.name
        resizedFile.body = reader.result
        setImages([...images, resizedFile])
      }
      reader.readAsDataURL(resizedFile)
    } catch (err) {
      props.enqueueSnackbar('画像を読み込めませんでした', {
        variant: 'error'
      })
      setIsChange(false)
    }
    setIsLoading(false)
  }

  const handleDeleteImage = index => event => {
    setIsChange(true)
    const splicedImages = [...images.filter((item, i) => i !== index)]
    document.getElementById('contained-button-image').value = null
    if (images && images.length > 0 && images[index].id) {
      const temp = [...deletedImages]
      temp.push(images[index])
      setDeletedImages([...temp])
    }
    setImages([...splicedImages])
  }

  function Image({ index, status, filename, body }) {
    if (status === 1) {
      return (
        <>
          <RowWithFile
            key={index}
            image={body}
            isDeleteButtonView={false}
            style={{ marginBottom: 10 }}
            disabled={isRestrictedRole}
          />
          <Grid container>
            <Grid item xs={8} className={classes.imageTitle}>
              {filename}
            </Grid>
            <Grid item xs={4} zeroMinWidth alignItems={'left'}>
              <IconButton
                aria-label="削除"
                color="primary"
                onClick={handleDeleteImage(index)}
                disabled={isRestrictedRole}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleCancel = event => {
    setRemark(orgRemark)
    setImages(orgImages)
    setIsChange(false)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    props.setLoadData(true)

    if (!isChange) {
      props.enqueueSnackbar('変更がありません', {
        variant: 'warning'
      })
      props.setLoadData(false)
      return
    }

    setIsLoading(true)

    const params = {}

    if (remark || remark === '') {
      params.remark = remark
    }

    if (images && images.length > 0) {
      const creds = await Auth.currentCredentials()
      params.attachments = {}
      if (images && images.length > 0) {
        const attachmentImages = []
        for (let image of images) {
          if (!image.id) {
            const result = await fileupload(creds, 'images', image)
            attachmentImages.push({
              attachment_type_id: 1,
              mime_type: image.type,
              filename: image.name,
              key: result.key
            })
          }
        }
        if (attachmentImages.length > 0) {
          params.attachments.images = attachmentImages
        }
      }
    }

    if (deletedImages && deletedImages.length > 0) {
      if (!params.attachments) {
        params.attachments = {}
      }
      if (deletedImages && deletedImages.length > 0) {
        if (!params.attachments.images) {
          params.attachments.images = []
        }
        for (let image of deletedImages) {
          if (image.id) {
            params.attachments.images.push({
              id: image.id,
              key: image.key,
              status: 3
            })
          }
        }
      }
    }

    params.id = props.application.id
    console.log('params', params)
    const result = await MutationHelper('updateRoomCheckListApplication', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      await props.fetchData(result.id)
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    }
    setIsLoading(false)
    props.setLoadData(false)
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    console.log(JSON.stringify(result))
    return result
  }

  return (
    <>
      <Paper className={classes.root}>
        <LoadingWithBackground isLoading={isLoading} />
        <form onSubmit={handleSubmit}>
          <FormLayout title="管理会社備考">
            <Grid container spacing={3}>
              {images &&
                images.length > 0 &&
                images.map((row, index) => (
                  <Grid key={index} item xs={4}>
                    <Image
                      index={index}
                      status={row.status}
                      filename={row.filename}
                      body={row.body}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-image"
                  type="file"
                  onChange={handleChangeImage}
                  disabled={isRestrictedRole}
                />
                {images.filter(item => item.status == 1).length <
                  MAXIMUM_FILE_LENGTH && (
                  <label
                    htmlFor="contained-button-image"
                    className={classes.labelButton}
                  >
                    <Button
                      className={classes.imageAddButton}
                      fullWidth
                      size="large"
                      variant="outlined"
                      color="primary"
                      component="span"
                      startIcon={<AddIcon />}
                      disabled={isRestrictedRole}
                    >
                      画像追加
                    </Button>
                  </label>
                )}
              </Grid>
            </Grid>
          </FormLayout>
          <Divider />
          <div className={classes.remarkRoot}>
            <Grid container>
              <Grid item xs={12} className={classes.right}>
                <Button
                  variant="contained"
                  className={classes.secondaryButton}
                  onClick={handleCancel}
                  disabled={isRestrictedRole}
                >
                  キャンセル
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.primaryButton}
                  disabled={isRestrictedRole}
                >
                  保存
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Paper>
    </>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(RoomCheckListApplicationForm)))
