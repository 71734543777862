import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import FormLayout from 'components/common/FormLayout'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '10px 0'
  }
}))

const TabPanel = props => {
  const { children, value, index } = props

  return (
    <div role="tabpanel">{value === index && <Box p={3}>{children}</Box>}</div>
  )
}

const SelectTabs = props => {
  const classes = useStyles()
  const [SelectedTab, setSelectedTab] = useState(0)
  const [beforeSelectedTab, setBeforeSelectedTab] = useState(0)

  const handleChange = (event, selectTab) => {
    if (props.handleChange(selectTab, beforeSelectedTab)) {
      setSelectedTab(selectTab)
      setBeforeSelectedTab(selectTab)
    }
  }

  return (
    <div>
      <Paper className={classes.root}>
        <FormLayout title={props.title} subTitle={props.subTitle}>
          <Tabs
            value={SelectedTab}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {props.labels.map(label => (
              <Tab label={label.label} key={label.value}></Tab>
            ))}{' '}
          </Tabs>
          {props.children.map((childContents, index) => (
            <TabPanel value={SelectedTab} index={index} key={index}>
              {childContents}
            </TabPanel>
          ))}
        </FormLayout>
      </Paper>
    </div>
  )
}

export default SelectTabs
