import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'

const useStyles = makeStyles(theme => ({
  parentListItem: {
    paddingLeft: '10px'
  },
  parentListItemText: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    '& > .MuiListItemText-primary': {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '13px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  childListItem: {
    paddingLeft: '10px',
    backgroundColor: '#FAFBFD'
  },
  childListItemText: {
    paddingLeft: theme.spacing(1),
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '21px',
    '& > .MuiListItemText-primary': {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '13px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  divider: {
    marginLeft: '16px'
  }
}))

function CategoryList(props) {
  const classes = useStyles()
  const categories = props && props.categories ? props.categories : null
  const [nowOpen, setNowOpen] = useState('')

  const handleClickCategory = name => event => {
    if (name === nowOpen) {
      setNowOpen('')
    } else {
      setNowOpen(name)
    }
  }

  return (
    <>
      {categories &&
        categories.length > 0 &&
        categories.map(category => (
          <>
            <ListItem
              className={classes.parentListItem}
              button
              onClick={handleClickCategory(category.large_categories.name)}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.parentListItemText}
                primary={category.large_categories.name}
              />
              {category.large_categories.name === nowOpen ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>
            <Divider />
            <Collapse
              in={category.large_categories.name === nowOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {category.medium_categories &&
                  category.medium_categories.length > 0 &&
                  category.medium_categories.map(medium_category => (
                    <>
                      <ListItem button className={classes.childListItem}>
                        <ListItemText
                          className={classes.childListItemText}
                          primary={medium_category.name}
                          onClick={() =>
                            props.handleSelectedMediumCategory(medium_category)
                          }
                        />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </>
                  ))}
              </List>
            </Collapse>
          </>
        ))}
    </>
  )
}

export default withSnackbar(withRouter(CategoryList))
