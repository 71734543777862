import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { fromCode } from 'utils/enum.utils'
import { ParkingSpacePattern } from 'utils/enum'
import Loading from 'components/common/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

const parseJson = content => {
  let parseValue
  try {
    parseValue = JSON.parse(content)
  } catch (e) {
    console.log(e)
    return { e }
  }
  return parseValue
}

function CellWithCertificateOfConsentToUseParkingSpacePattern({
  parkingSpacePatternId
}) {
  const certificateOfConsentToUseParkingSpacesState = fromCode(
    ParkingSpacePattern,
    parkingSpacePatternId
  )
  return <>{certificateOfConsentToUseParkingSpacesState.value}</>
}

function COC2UseParkingApplicationDetail(props) {
  const classes = useStyles()
  let { isLoading, content, attachment } = props

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.root} colSpan="4">
              申請内容
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell className={classes.columnName2}>
                <Loading isLoading={isLoading} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow>
                <TableCell className={classes.columnName2}>駐車場</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'parkingSpacePatternId' in content ? (
                    <CellWithCertificateOfConsentToUseParkingSpacePattern
                      parkingSpacePatternId={content.parkingSpacePatternId}
                    />
                  ) : null}
                </TableCell>
                <TableCell className={classes.columnName2}>駐車場名</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'parkingName' in content && content.parkingName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.columnName1}>区画番号</TableCell>
                <TableCell className={classes.columnValue1} colSpan="3">
                  {content &&
                    'parkingSpotNumber' in content &&
                    content.parkingSpotNumber}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.columnName2}>メーカー</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'maker' in content && content.maker}
                </TableCell>
                <TableCell className={classes.columnName2}>車種</TableCell>
                <TableCell className={classes.columnValue2}>
                  {content && 'model' in content && content.model}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default COC2UseParkingApplicationDetail
