import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import SettingsCellIcon from '@material-ui/icons/SettingsCell'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import ApartmentIcon from '@material-ui/icons/Apartment'
import BusinessIcon from '@material-ui/icons/Business'
import TextsmsIcon from '@material-ui/icons/Textsms'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import RateReviewIcon from '@material-ui/icons/RateReview'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import HomeIcon from '@material-ui/icons/Home'
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { QueryHelper } from 'utils/api.utils'
import Loading from 'components/common/Loading'
import Link from '@material-ui/core/Link'
import { useHistory } from 'react-router-dom'
import {
  BicycleParkingStickerApplicationState,
  CertificateOfConsentToUseParkingSpaceState,
  PetApplicationState,
  ResidentCancellationApplicationState,
  ResidentInfoChangeApplicationState,
  RoomCheckListApplicationState,
  ThreadState
} from 'utils/enum'
import { formatHyphenYYYYMMDD } from 'utils/date.utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: '50px',
    '& svg': {
      color: props =>
        props.color ? theme.palette[props.color].main : 'inherited'
    }
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: props =>
      props.color ? theme.palette[props.color].main : 'inherited'
  },
  link: {
    color: '#696CFF'
  },
  typography: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300
  }
}))

function Content(props) {
  const classes = useStyles()
  const history = useHistory()

  const [dashboard, setDashboard] = useState({})
  const [isLoad, setIsLoad] = useState(false)
  const [canShowDashboard, setCanShowDashboard] = useState(true)

  async function fetchDashboard() {
    let result = await QueryHelper('getDashboard', {}, false)
    if (result.error) {
      // console.log(JSON.stringify(result));
      props.enqueueSnackbar('集計情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setDashboard(result)
    }
  }

  const isExternalUser = () => {
    let item = sessionStorage.getItem('totono-advertiser-session')
      ? sessionStorage.getItem('totono-advertiser-session')
      : localStorage.getItem('totono-advertiser-session')
    const data = JSON.parse(item)
    if (data.role_id == 5) {
      setCanShowDashboard(false)
      return true
    }
    return false
  }

  const fetchData = async () => {
    if (isExternalUser()) {
      return
    }
    setIsLoad(true)
    await Promise.all([fetchDashboard()])
    setIsLoad(false)
  }

  useEffect(() => {
    fetchData()
  }, [setDashboard])

  const linkToThreadListToMonth = status => event => {
    const firstDay = new Date()
    firstDay.setHours(0)
    firstDay.setMinutes(0)
    firstDay.setSeconds(0)
    firstDay.setMilliseconds(0)
    firstDay.setDate(1)
    const lastDay = new Date()
    lastDay.setHours(0)
    lastDay.setMinutes(0)
    lastDay.setSeconds(0)
    lastDay.setMilliseconds(0)
    lastDay.setMonth(lastDay.getMonth() + 1)
    lastDay.setDate(0)
    history.push({
      pathname: `/message/list/`,
      state: {
        'message.thread_state': [status],
        'message.exchanged_at_str_date_from': formatHyphenYYYYMMDD(firstDay),
        'message.exchanged_at_str_date_to': formatHyphenYYYYMMDD(lastDay)
      }
    })
  }

  return (
    <>
      {canShowDashboard ? (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <SettingsCellIcon style={{ fontSize: '24px' }} />
                <Typography
                  style={{ fontFamily: 'Hiragino Sans W6', fontSize: '16px' }}
                >
                  アプリ利用
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={2} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      利用者
                    </Typography>
                    <AccessibilityNewIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      総数
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'totalAppUsers' in dashboard && !isLoad ? (
                          dashboard['totalAppUsers']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        人
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      今日
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'dailyAppUsers' in dashboard && !isLoad ? (
                          dashboard['dailyAppUsers']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        人
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={2} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      登録者
                    </Typography>
                    <AccessibilityNewIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      今月
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'appUserMonthly' in dashboard && !isLoad ? (
                          dashboard['appUserMonthly']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        人
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      今週
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'appUserWeekly' in dashboard && !isLoad ? (
                          dashboard['appUserWeekly']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        人
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      今日
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'appUserDaily' in dashboard && !isLoad ? (
                          dashboard['appUserDaily']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        人
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={3} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      棟数
                    </Typography>
                    <ApartmentIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      登録棟数
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'totalBuildings' in dashboard && !isLoad ? (
                          dashboard['totalBuildings']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        棟
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      対象棟数
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'totalAvailableBuildings' in dashboard && !isLoad ? (
                          dashboard['totalAvailableBuildings']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        棟
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={3} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      戸数
                    </Typography>
                    <BusinessIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      総戸数
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'totalRooms' in dashboard && !isLoad ? (
                          dashboard['totalRooms']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        戸
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      対象戸数
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'totalAvailableRooms' in dashboard && !isLoad ? (
                          dashboard['totalAvailableRooms']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        戸
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={3} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      入居者
                    </Typography>
                    <AccessibilityNewIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      総入居者数
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'totalContracts' in dashboard && !isLoad ? (
                          dashboard['totalContracts']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        人
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      対象入居者数
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'totalResidents' in dashboard && !isLoad ? (
                          dashboard['totalResidents']
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        人
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <TextsmsIcon style={{ fontSize: '24px' }} />
                  <Typography
                    style={{ fontFamily: 'Hiragino Sans W6', fontSize: '16px' }}
                  >
                    メッセージ
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={4} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      未対応
                    </Typography>
                    <NewReleasesIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      現在
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'messageOpen' in dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/message/list/`,
                                state: {
                                  'message.thread_state': [
                                    ThreadState.open.code
                                  ]
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {dashboard['messageOpen']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={4} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      対応中
                    </Typography>
                    <RateReviewIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      現在
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'messageInProgress' in dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/message/list/`,
                                state: {
                                  'message.thread_state': [
                                    ThreadState.inProgress.code
                                  ]
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {dashboard['messageInProgress']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={4} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      対応済
                    </Typography>
                    <BeenhereIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      今月
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'messagePending' in dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={linkToThreadListToMonth(
                              ThreadState.pending.code
                            )}
                            className={classes.link}
                          >
                            {dashboard['messagePending']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={4} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      完了
                    </Typography>
                    <EmojiEmotionsIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      今月
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'messageComplete' in dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={linkToThreadListToMonth(
                              ThreadState.complete.code
                            )}
                            className={classes.link}
                          >
                            {dashboard['messageComplete']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <HomeIcon style={{ fontSize: '24px' }} />
                  <Typography
                    style={{ fontFamily: 'Hiragino Sans W6', fontSize: '16px' }}
                  >
                    解約申請
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={3} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      受付
                    </Typography>
                    <MoveToInboxIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      現在
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationResidentCancellationOpen' in dashboard &&
                        !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/residentCancellation/list/`,
                                state: {
                                  application_state_id:
                                    ResidentCancellationApplicationState.open
                                      .code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {dashboard['applicationResidentCancellationOpen']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={3} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      確認
                    </Typography>
                    <BusinessIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      現在
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationResidentCancellationInProgress' in
                          dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/residentCancellation/list/`,
                                state: {
                                  application_state_id:
                                    ResidentCancellationApplicationState
                                      .inProgress.code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {
                              dashboard[
                                'applicationResidentCancellationInProgress'
                              ]
                            }
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={3} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      退去待
                    </Typography>
                    <AccessibilityNewIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      現在
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationResidentCancellationPending' in
                          dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/residentCancellation/list/`,
                                state: {
                                  application_state_id:
                                    ResidentCancellationApplicationState.pending
                                      .code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {
                              dashboard[
                                'applicationResidentCancellationPending'
                              ]
                            }
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <NewReleasesIcon style={{ fontSize: '24px' }} />
                  <Typography
                    style={{ fontFamily: 'Hiragino Sans W6', fontSize: '16px' }}
                  >
                    各種申請 未対応
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 108 }}
                >
                  <Grid item xs={1} align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W6',
                        fontSize: '16px'
                      }}
                    >
                      申請
                    </Typography>
                    <ImportContactsIcon style={{ fontSize: '36px' }} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      入居情報変更
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationResidentInfoChangeOpen' in dashboard &&
                        !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/residentInfoChange/list/`,
                                state: {
                                  application_state_id:
                                    ResidentInfoChangeApplicationState.open.code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {dashboard['applicationResidentInfoChangeOpen']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      室内点検表
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationRoomCheckOpen' in dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/roomCheckList/list/`,
                                state: {
                                  application_state_id:
                                    RoomCheckListApplicationState.open.code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {dashboard['applicationRoomCheckOpen']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      駐輪ステッカー
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationBicycleParkingStickerOpen' in dashboard &&
                        !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/bicycleParkingSticker/list/`,
                                state: {
                                  application_state_id:
                                    BicycleParkingStickerApplicationState.open
                                      .code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {dashboard['applicationBicycleParkingStickerOpen']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      駐車場保管場所使用承諾
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationCertificateOfConsentToUseParkingSpaceOpen' in
                          dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/cOC2UseParking/list/`,
                                state: {
                                  application_state_id:
                                    CertificateOfConsentToUseParkingSpaceState
                                      .open.code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {
                              dashboard[
                                'applicationCertificateOfConsentToUseParkingSpaceOpen'
                              ]
                            }
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs align="center">
                    <Typography
                      style={{
                        fontFamily: 'Hiragino Sans W3',
                        fontSize: '16px'
                      }}
                    >
                      ペット飼育
                    </Typography>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W6',
                          fontSize: '21px'
                        }}
                      >
                        {'applicationPetOpen' in dashboard && !isLoad ? (
                          <Link
                            href="javascript:void(0);"
                            onClick={() =>
                              history.push({
                                pathname: `/application/pet/list/`,
                                state: {
                                  application_state_id:
                                    PetApplicationState.open.code
                                }
                              })
                            }
                            className={classes.link}
                          >
                            {dashboard['applicationPetOpen']}
                          </Link>
                        ) : (
                          <Loading isLoading={isLoad} />
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Hiragino Sans W3',
                          fontSize: '16px'
                        }}
                      >
                        件
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Typography className={classes.typography}>
          この権限ではダッシュボードを利用できません
        </Typography>
      )}
    </>
  )
}

// const mapStateToProps = (state) => {
//   return {
//   }
// };

// const mapDispatchToProps = dispatch => {
//   return {
//   }
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withSnackbar(Content));
export default Content
