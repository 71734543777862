import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListContent from './ListContent'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* <div className={classes.top}>
      </div>
      <div className={classes.divider}/> */}
      <div className={classes.body}>
        <ListContent />
      </div>
      {/* <div className={classes.divider}/>
      <div className={classes.bottom}>
      </div> */}
    </div>
  )
}

export default Left
