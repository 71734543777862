import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog } from 'actions/Actions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(() => ({
  '@global': {
    '.MuiTypography-h6': {
      height: '24px',
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  formContent: {
    padding: '20px'
  },
  formFooter: {
    margin: '8px, 16px'
  }
}))

const FormModalDialog = props => {
  const classes = useStyles()

  const { title, content, postData } = props

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.closeModalDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <form onSubmit={postData}>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <Divider />
          <DialogContent className={classes.formContent}>
            {content}
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormModalDialog)
