import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import Loading from 'components/common/Loading'
import { withSnackbar } from 'notistack'
import { useDisplayUserInformationHeader } from '../../../../hooks/useDisplayUserInformationHeader'
import { useCustomerOrOwner } from '../../../../hooks/useCustomerOrOwner'

const useStyles = makeStyles(theme => ({
  text: {
    flex: 1,
    color: '#ffffff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px'
  },
  primaryButton: {
    marginLeft: '16px',
    marginRight: '16px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },
  button: {
    marginLeft: '16px',
    color: '#fff'
  }
}))

function Body(props) {
  const classes = useStyles()
  const history = useHistory()
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  const {
    outputApplicationUserInformation,
    formatResidentInfo,
    isLoading
  } = useDisplayUserInformationHeader(
    'getThread',
    'thread',
    props.enqueueSnackbar,
    props.title,
    isOwnerPath
  )

  useEffect(() => {
    outputApplicationUserInformation()
  }, [props])

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.button}
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.text}>
        {!isLoading ? (
          formatResidentInfo
        ) : (
          <Loading isLoading={true} marginTop={20} marginBottom={20} />
        )}
      </Typography>
    </>
  )
}

export default withSnackbar(Body)
